<template>
  <div
    id="defaultModal"
    tabindex="-1"
    aria-hidden="true"
    class="bgModal fixed top-0 left-0 right-0 z-50 w-full h-screen overflow-x-hidden overflow-y-auto md:inset-0 md:h-full justify-center items-center"
  >
    <div class="relative mt-10 m-auto w-full h-full max-w-xl p-4 md:h-auto">
      <div
        id="div"
        class="modal relative bg-white overflow-hidden rounded-lg shadow dark:bg-gray-700 animated modal_open"
      >
        <!-- modal header -->
        <div class="flex justify-between border-b py-4 px-4 bgColor">
          <h1 class="text-white text-sm pl-2">Remarks</h1>
          <button
            type="button"
            @click="closeModal"
            class="absolute top-1 right-2.5 text-white bg-transparent rounded-lg text-sm p-3 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="authentication-modal"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- modal content -->
        <div class="grid items-center">
          <div class="py-2 px-4 h-96 mx-2 my-2 rounded-md overflow-y-auto">
            <ol
              v-if="remarks.length == 0"
              class="relative border-l h-auto border-customGreen dark:border-gray-700"
            >
              <li
                class="mb-10 ml-4 border border-gray-300 px-4 py-1 rounded-md"
              >
                <span class="text-sm text-gray-600"> No remarks found. </span>
              </li>
            </ol>
            <ol
              v-else
              class="relative border-l border-customGreen dark:border-gray-700"
              :class="remarks.length <= 1 ? 'h-full' : ' h-auto'"
            >
              <li
                class="mb-10 ml-4 border border-gray-300 py-1 rounded-md"
                v-for="item in remarks"
                :key="item"
              >
                <div
                  class="absolute w-6 h-6 bg-white rounded-full border-customGreen -left-3 border-b-2 dark:border-gray-900 dark:bg-gray-700"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="white"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 ml-0.5 -mt-0.5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z"
                    />
                  </svg>
                </div>
                <div class="flex">
                  <div
                    class="m-auto w-1/6"
                    :class="
                      item.updatedAt == null ? 'text-FF0000 ' : 'text-green-500'
                    "
                  >
                    <!-- SVG CHECK -->
                    <svg
                      v-if="item.updatedAt != null"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-14 h-14 mx-auto"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <!-- SVG EXCLAMATION -->
                    <svg
                      v-if="item.updatedAt == null"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-14 h-14 mx-auto"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                      />
                    </svg>
                  </div>
                  <div class="w-5/6 px-3">
                    <time
                      class="mb-1 text-xs font-normal leading-none text-gray-400 dark:text-gray-500"
                    >
                      {{ formatDate(item.createdAt) }}</time
                    >

                    <p
                      class="mb-4 text-sm font-normal text-gray-500 dark:text-gray-400 text-justify"
                    >
                      {{ item.msg }}
                    </p>

                    <button
                      v-if="item.updatedAt == null && fromTable == 'myworks'"
                      @click="toUpdate(item)"
                      class="bg-white hover:bg-yellow-500 hover:text-white text-yellow-500 font-semibold py-1 px-3 text-xs border border-yellow-500 rounded shadow"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "PendingReasonModal",
  props: ["journal", "tableFrom"],
  data() {
    return {
      file: [],
      remarks: [],
      fromTable: null,
    };
  },
  mounted() {
    this.init();
    this.getRemarks();
  },
  methods: {
    init() {
      document.getElementById("div").classList.remove("modal_close");
      document.getElementById("div").classList.add("modal_open");
    },
    closeModal() {
      document.getElementById("div").classList.remove("modal_open");

      document.getElementById("div").classList.add("modal_close");

      setTimeout(() => this.$emit("closeModal"), 500);
    },

    getRemarks() {
      var fileID = this.file;
      // console.log(this.file);
      axios
        .get(process.env.VUE_APP_API_SERVER + "/remarks/" + fileID, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            // console.log(response.data);
            this.remarks = response.data;
          },
          (error) => {
            console.log(error);
          }
        );
    },

    toUpdate(item) {
      let items = {
        journal: this.journal,
        remarkItem: item,
      };

      this.$emit("toUpdate", items);
    },

    formatNumber(num) {
      if (num <= 9) {
        return "0" + num;
      } else {
        return num;
      }
    },

    // format date to display
    formatDate(date) {
      const d = new Date(date);

      const mn = d.getMonth() + 1;
      var day = d.getDate();
      const yr = d.getFullYear();
      const hh = d.getHours();
      const mm = d.getMinutes();

      return (
        this.formatNumber(mn) +
        "-" +
        this.formatNumber(day) +
        "-" +
        yr +
        " " +
        this.formatNumber(hh) +
        ":" +
        this.formatNumber(mm)
      );
    },
  },
  watch: {
    journal: {
      handler(val) {
        // console.log(val);
        this.file = val;
      },
      immediate: true,
    },

    tableFrom: {
      handler(val) {
        this.fromTable = val;
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
