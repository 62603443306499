<template>
    <div id="defaultModal" tabindex="-1" aria-hidden="true" class="
      bgModal
      fixed
      top-0
      left-0
      right-0
      z-50
      w-full
      overflow-x-hidden overflow-y-auto
      md:inset-0
      h-screen
      md:h-full
      justify-center
      items-center
    ">
        <div class="relative mt-10 m-auto w-full h-full max-w-md p-4 md:h-auto">
            <div class="
          modal
          relative
          bg-white
          overflow-hidden
          rounded-lg
          shadow
          dark:bg-gray-700
          animated
          modal_open
        ">
                <!-- modal header -->
                <div class="flex justify-between border-b py-3 px-4 bg-105005">
                    <h1 class="text-white text-sm font-bold">Update Journal Details</h1>
                    <button type="button" @click="closeModal" class="
              absolute
              top-1
              right-2.5
              text-white
              bg-transparent
              hover:bg-gray-200 hover:text-gray-900
              rounded-lg
              text-sm
              p-1.5
              ml-auto
              inline-flex
              items-center
              dark:hover:bg-gray-800 dark:hover:text-white
            " data-modal-toggle="authentication-modal">
                        <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"></path>
                        </svg>
                    </button>
                </div>
                <!-- modal content -->
                <div class="grid items-center">
                    <div class="w-full px-4 py-5">
                        <label class="block font-poppins text-gray-700 text-xs mb-2 font-semibold">
                            Type of Article 
                        </label>
                        <select v-model="typeOfArticle" 
                            class="font-poppins border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 text-xs w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option selected value="1">Thesis</option>
                            <option value="2">Capstone</option>
                            <option value="3">Dissertation</option>
                            <option value="4">Research</option>
                            <option value="5">Journal</option>
                            <option value="6">Others</option>
                        </select>
                    </div>

                    <!-- approval remarks textarea -->
                    <div class="m-auto py-5" v-if="isToUpdate">
                        <textarea v-model="updateRemark" class="
                border
                text-xs
                inputIndent
                py-2
                px-1
                rounded-md
                bg-gray-100
              " cols="60" rows="10" placeholder="Input remarks here"></textarea>
                    </div>
                </div>
                <!-- buttons -->
                <div class="
            flex
            justify-center
            p-4
            space-x-2
            border-t
            rounded-b
            dark:border-gray-600
          ">
                    <button type="button" @click="closeModal" class="
              text-sm text-FF0000
              border border-FF0000
              rounded
              hover:bg-FF0000
              hover:text-white
              hover:border-FF0000
              
              font-normal
              px-5
              py-1
              focus:z-10
              dark:bg-gray-700
              dark:text-gray-300
              dark:border-gray-500
              dark:hover:text-white
              dark:hover:bg-gray-600
              dark:focus:ring-gray-600
            ">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                class="w-5 h-5 inline">
                <path stroke-linecap="round" stroke-linejoin="round"
                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>

                        Close
                    </button>
                    <!-- button to toggle approval remarks textarea -->

                    <button v-if="!isToUpdate" @click="toUpdate()" type="button" class="
              font-normal
              text-105005 text-sm
              border border-105005
              rounded
              hover:bg-105005
              hover:text-white
              hover:border-105005
              px-5
              py-1
              text-center
              dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
            ">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                class="w-5 h-5 inline">
                <path stroke-linecap="round" stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>

                        Save changes
                    </button>
                    <!-- button to save remarks and approve journal -->
                    <!-- <button v-if="isToUpdate" @click="toUpdate()" type="button" class="
              text-sm text-105005
              font-normal
              px-5
              py-1
              text-center
              dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
            ">
                        Save Remarks
                    </button> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
export default {
    name: "UpdateJournalDetailsModal",
    props: ["journaldoc"],

    data() {
        return {
            journalId: this.journaldoc.id,
            typeOfArticle: null,
            journaldata: null,
            approvalRemark: null,
            isToUpdate: false,
            alreadyInUse: "No changes made.",
        };
    },
     //put database items into input box
    mounted() {
        this.typeOfArticle = this.journaldoc.typeOfArticle;
        document.getElementById("defaultModal").classList.add("modal_open");
        document.getElementById("defaultModal").classList.remove("modal_close");
    },

    methods: {
        closeModal() {
            this.$emit("closeModal");
        },

        refreshData() {
            this.$emit("refreshTable");
        },

        toUpdate() {
            if (this.typeOfArticle != this.journaldoc.typeOfArticle) {
                const toast = useToast();
                const fd = {
                    journalIdVal: this.journalId,
                    typeOfArticle: this.typeOfArticle,
                };
                axios
                    .post(
                        process.env.VUE_APP_API_SERVER + "/fileupload/updateDetails",
                        fd,
                        {
                            headers: {
                                Authorization: `Bearer ${this.$store.getters.getToken}`,
                            },
                        }
                    )
                    .then(
                        (response) => {
                            // console.log(response);
                            if (response.data.status == 200) {
                                document
                                    .getElementById("defaultModal")
                                    .classList.add("modal_close");
                                document
                                    .getElementById("defaultModal")
                                    .classList.remove("modal_open");
                                setTimeout(() => this.closeModal(), 500);
                                this.$emit("back");
                                this.$emit("refreshData");
                                toast.success(response.data.msg, {
                                    position: "top-right",
                                    timeout: 3000,
                                    closeOnClick: true,
                                    pauseOnFocusLoss: false,
                                    pauseOnHover: false,
                                    draggable: true,
                                    draggablePercent: 0.6,
                                    showCloseButtonOnHover: false,
                                    hideProgressBar: false,
                                    closeButton: "button",
                                    icon: true,
                                    rtl: false,
                                });
                                this.journaldoc.typeOfArticle = this.typeOfArticle;
                            } else if (response.data.status == 400) {
                                toast.error(response.data.msg, {
                                    position: "top-right",
                                    timeout: 3000,
                                    closeOnClick: true,
                                    pauseOnFocusLoss: false,
                                    pauseOnHover: false,
                                    draggable: true,
                                    draggablePercent: 0.6,
                                    showCloseButtonOnHover: false,
                                    hideProgressBar: false,
                                    closeButton: "button",
                                    icon: true,
                                    rtl: false,
                                });
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
            } else {
                const toast = useToast();
                toast.warning(this.alreadyInUse, {
                    position: "top-right",
                    timeout: 3000,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: false,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: false,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                });
            }
            
            
        },

        Endorse(doc) {
            const data = { remarks: this.endorsementRemark };
            const final = Object.assign(doc, data);

            axios
                .post(process.env.VUE_APP_API_SERVER + "/fileupload/endorse", final, {
                    headers: {
                        Authorization: `Bearer ${this.$store.getters.getToken}`,
                    },
                })
                .then(
                    (response) => {
                        if (response.data.affected == 1) {
                            // emit to ApprovalView
                            this.$emit("endorsed");
                        }
                        else {
                            // console.log(response);
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        },
    },
};
</script>

<style scoped>
.bgGreen {
    color: #105005;
}

.inputIndent {
    text-indent: 10px;
}
</style>