<template>
  <div class="w-full grid pb-6 mb-10">
    <div class="flex justify-between">
      <h1 class="text-l text-gray-600 font-semibold py-2 px-2">
        Activity Logs
      </h1>
    </div>
    <!-- <div class="absolute right-0 center">Back to top</div> -->

    <hr />
    <div class="mt-4">
      <div class="flex flex-col px-2">
        <div
          class="p-5 mb-10 border border-gray-100 rounded bg-gray-50 dark:bg-gray-800 dark:border-gray-700"
          v-for="(item, key) in filteredLogsData"
          :key="item"
        >
          <time class="text-lg font-semibold text-gray-900 dark:text-white">{{
            moment(key).format("LL")
          }}</time>
          <ol class="mt-3 divide-y divider-gray-200 dark:divide-gray-700">
            <li v-for="data in item" :key="data">
              <a
                href="javascript:void(0)"
                class="items-center block justify-stretch p-3 sm:flex hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                <!-- <svg
                  class="w-12 h-12 mb-3 mr-3 rounded-full sm:mb-0 text-105005"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M10 .5a9.5 9.5 0 1 0 0 19 9.5 9.5 0 0 0 0-19ZM8.374 17.4a7.6 7.6 0 0 1-5.9-7.4c0-.83.137-1.655.406-2.441l.239.019a3.887 3.887 0 0 1 2.082 2.5 4.1 4.1 0 0 0 2.441 2.8c1.148.522 1.389 2.007.732 4.522Zm3.6-8.829a.997.997 0 0 0-.027-.225 5.456 5.456 0 0 0-2.811-3.662c-.832-.527-1.347-.854-1.486-1.89a7.584 7.584 0 0 1 8.364 2.47c-1.387.208-2.14 2.237-2.14 3.307a1.187 1.187 0 0 1-1.9 0Zm1.626 8.053-.671-2.013a1.9 1.9 0 0 1 1.771-1.757l2.032.619a7.553 7.553 0 0 1-3.132 3.151Z"
                  />
                </svg> -->
                <div class="text-gray-600 dark:text-gray-400">
                  <div class="text-base font-normal">
                    <span
                      class="font-medium text-sm text-gray-900 dark:text-white"
                      >{{ data.description }}
                      <span class="text-xs text-gray-500 font-normal">{{
                        timeOnly(data.createdAt)
                      }}</span></span
                    >
                  </div>
                </div>
              </a>
            </li>
          </ol>
        </div>
        <h2 class="end_h2">
          <span class="end_span">End of activity logs</span>
        </h2>
      </div>
    </div>
  </div>
</template>
            
<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "Abouts",
  data() {
    return {
      isEdit: false,
      email: null,
      password: null,
      confirm_password: null,
      password_err: false,
      confirm_password_err: false,
      confirm_err_msg: "",
      logsData: [],
      logsLen: null,
    };
  },
  mounted() {
    this.init();
    this.getLogs();
  },
  computed: {
    filteredLogsData() {
      let g = {};
      this.logsData.forEach((item) => {
        let d = item.createdAt.substring(0, 10);
        if (g[d]) {
          g[d].push(item);
        } else {
          g[d] = [];
          g[d].push(item);
        }
      });
      return g;
      // return this.logsData.filter((a) => new Date(a.createdAt));
    },
  },
  created() {
    this.moment = moment;
  },

  methods: {
    //init method
    init() {
      this.email = this.$store.getters.getUserEmail;
    },

    timeOnly(date) {
      const d = new Date(date);
      var hours = d.getHours();
      var minutes = d.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
      // var m = ""; // var day = ""; // var h = ""; // var mi = ""; // var sc = ""; // var yy = d.getFullYear(); // var mm = d.getMonth() + 1; // if (mm <= 9) { //   m = "0" + mm.toString(); // } else { //   m = mm.toString(); // } // var dd = d.getDate(); // if (dd <= 9) { //   day = "0" + dd.toString(); // } else { //   day = dd.toString(); // } // var hh = d.getHours(); // if (hh <= 9) { //   h = "0" + hh.toString(); // } else { //   h = hh.toString(); // } // var min = d.getMinutes(); // if (min <= 9) { //   mi = "0" + min.toString(); // } else { //   mi = min.toString(); // } // var sec = d.getSeconds(); // if (sec <= 9) { //   sc = "0" + sec.toString(); // } else { //   sc = sec.toString(); // } // return h + ":" + mi;
    },

    getLogs() {
      axios
        .get(
          process.env.VUE_APP_API_SERVER +
            "/logs-data/getAllLogsByUserdetailID/" +
            this.$store.getters.getUserdetailID,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            this.logsData = response.data;
            this.logsLen = response.data.length;
          },
          (error) => {
            console.log(error);
          }
        );
    },

    // PASSWORD REGEX
    testPassword() {
      this.confirm_password = null;
      this.confirm_password_err = false;
      this.confirm_err_msg = null;

      const pass_regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/;
      if (pass_regex.test(this.password)) {
        this.password_err = false;
      } else {
        this.password_err = true;
      }
    },

    passwordMatch() {
      if (this.confirm_password == "" || this.confirm_password == null) {
        this.confirm_err_msg = "";
        return false;
      } else {
        if (this.confirm_password == this.password) {
          this.confirm_password_err = false;
          this.confirm_err_msg = "Password matched.";
        } else {
          this.confirm_password_err = true;
          this.confirm_err_msg = "Password do not match.";
        }
      }
    },

    // UPDATE PASSWORD
    updateSecurity() {
      var valid = true;
      if (this.password != null && this.password_err == false) {
        valid = true;
      } else {
        valid = false;
      }

      if (this.confirm_password != null && this.confirm_password_err == false) {
        valid = true;
      } else {
        valid = false;
      }
      if (valid) {
        const fd = {
          password: this.password,
        };

        axios
          .post(
            process.env.VUE_APP_API_SERVER + "/users-controller/usersecurity",
            fd,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
              },
            }
          )
          .then(
            (response) => {
              if (response.data.status == 200) {
                const alertMsg = { msg: response.data.msg };
                this.password = null;
                this.confirm_password = null;
                this.confirm_password_err = false;
                this.confirm_err_msg = "";
                // emit event to parent parent component
                this.$emit("users_details_updated");
                this.$parent.$parent.$emit("success_alert", alertMsg);
                this.isEdit = false;
              }
            },
            (error) => {
              console.log(error);
            }
          );
      } else {
        this.password_err = true;
        this.confirm_password_err = true;
        this.confirm_err_msg = "This is required";
      }
    },

    onEditBtn(val) {
      (this.password = null),
        (this.confirm_password = null),
        (this.password_err = false),
        (this.confirm_password_err = false),
        (this.confirm_err_msg = ""),
        (this.isEdit = val);
    },
  },
};
</script>
            
<style scoped>
.cursor_pointer:hover {
  cursor: pointer;
}
#scrollToTop {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  box-shadow: 0 0 14px -5px rgba(0, 0, 0, 0.5);
  border-radius: 100%;
  width: 80px;
  height: 80px;
  display: flex;
  font-family: "Arial";
  align-items: center;
  justify-content: center;
  margin-bottom: -12rem;
  transition: margin-bottom 0.2s;
}
.end_h2 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #b0b0b0;
  line-height: 0.1em;
  margin: 10px 0 20px;
}
.end_h2 .end_span {
  background: #fff;
  padding: 0 10px;
  color: darkgrey;
  font-style: italic;
}
</style>