<template>
    <div>
        <div class="text-center text-md font-semibold">Historical Chart for upload</div>
        <LineChart style="height:35rem;" v-bind:chartData="chartData" :chartOptions="chartOptions" />
    </div>
</template>

<script>
import LineChart from "../charts/LineChart.vue";
export default {
    name: "HistoricalChart",
    props: {
        chartDataprop: {
            type: Object,
            required: true,
        },
    },
    components: {
        LineChart,
    },
    data() {
        return {
            chartData: {},
            type: 'line',
            chartOptions: {
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                    legend: {
                        title: {
                            display: true,
                            text: 'Historical Line Graph'
                        },
                        position: 'top',
                    },
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                min: 0,
                                max: 100,
                                callback: function (value) {
                                    return ((value / this.max) * 100).toFixed(0); // convert it to percentage
                                },
                                beginAtZero: false,
                                stepSize: 10,
                            },
                        },
                    ],
                },
            },
        };
    },

    watch: {
        chartDataprop: {
            handler(val) {
                this.chartData = val;
                // console.log(this.chartData)
                // console.log(val)
            },
            immediate: true,
        },
    },
};
</script>

<style></style>