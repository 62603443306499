<template>
  <div id="defaultModal">
    <div
      tabindex="1"
      aria-hidden="true"
      class="bgModal fixed top-0 left-0 right-0 bottom-0 px-3 md:px-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full justify-center items-center"
    >
      <div
        v-if="
          (this.isToApprove == true && action == 'Add New Journal') ||
          action == 'Update Journal'
        "
        class="relative m-auto w-full h-screen py-8 max-w-6xl md:h-screen"
      >
        <div
          id="addModal"
          class="modal_hgt modal relative bg-white overflow-hidden rounded-lg shadow dark:bg-gray-700 animated modal_open"
        >
          <div
            class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600 bgColor"
          >
            <h3 class="text-lg ml-4 font-semibold text-white dark:text-white">
              My Works - {{ action }}
            </h3>
            <button
              @click="closeAddModal"
              type="button"
              class="text-white bg-transparent rounded-lg text-sm p-1 pr-4 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>

          <div
            class="grid md:flex md:justify-between max-h-96 md:max-h-full overflow-y-auto"
          >
            <div
              class="md:w-1/2 mobileModalheight md:h-screen px-4 py-4 items-center overflow-y-auto"
            >
              <form
                id="form"
                @submit.prevent
                class="w-auto md:mx-1 mt-4 max-w-2xl"
                enctype="multipart/form-data"
                method="POST"
              >
                <div class="flex justify-start -mt-5 mb-5">
                  <p class="text-xs text-red-600 italic font-semibold">
                    Fields with
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      class="w-4 h-4 inline"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    is required. Kindly hover the icon on each field for
                    additional information.
                  </p>
                </div>
                <div class="flex flex-wrap py-2 md:-mx-3 mb-3">
                  <div
                    class="flex ml-3 mr-3 py-2 w-full bg-gray-200"
                    v-if="action == 'Update Journal' && !reuploadJournal"
                  >
                    <p class="mx-2 text-sm text-gray-500">
                      Re-upload journal?
                      <button
                        @click="reupload"
                        class="text-white bg-blue-600 font-medium rounded-lg text-sm ml-1 px-2 py-0.5 hover:underline"
                      >
                        Yes
                      </button>
                    </p>
                  </div>

                  <div
                    class="w-full px-3 mb-2"
                    v-if="action == 'Add New Journal' || reuploadJournal"
                  >
                    <label
                      class="text-gray-700 text-sm font-semibold mb-2 flex justify-between"
                    >
                      <div>
                        Article
                        <span
                          class="text-red-600 inline-flex group relative"
                          role="tooltip"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            class="w-4 h-4"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <span
                            class="group-hover:opacity-100 transition-opacity ml-2 withArrow opacity-0 bg-red-100 text-red-700 rounded p-1 top-0 text-xs"
                            >This is required.</span
                          >
                        </span>
                      </div>
                      <div class="text-xs text-gray-300" @click="clearjournal">
                        Clear file/link
                      </div>
                    </label>
                    <input
                      :class="err_journalfile ? 'border-red-500' : ''"
                      class="form-control block w-full py-2 px-4 text-sm font-normal text-gray-700 bg-gray-200 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      id="inputPDF"
                      type="file"
                      @change="previewPDF"
                    />
                    <div class="separator text-xs text-gray-500 my-1.5">OR</div>
                    <input
                      type="text"
                      id="journalLink"
                      v-model="journalLink"
                      @change="previewPDFByLink"
                      :class="err_journalLink ? 'border-red-500' : ''"
                      class="form-control block w-full py-2 px-4 text-sm font-normal text-gray-700 bg-gray-200 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="sample link: https://link.springer.com/content/pdf/10.1023/A:1010933404324.pdf"
                    />
                    <span class="text-xs text-gray-500 pl-0.5"
                      >Note: Using a link format needs internet connection to
                      view the pdf.</span
                    >
                  </div>
                </div>

                <div class="flex flex-wrap md:-mx-3 mb-3">
                  <div class="w-full px-3">
                    <label
                      class="block text-gray-700 text-sm font-semibold mb-2"
                    >
                      Type of Article
                      <span
                        class="text-red-600 inline-flex group relative"
                        role="tooltip"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="w-4 h-4"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span
                          class="group-hover:opacity-100 transition-opacity ml-2 withArrow opacity-0 bg-red-100 text-red-700 rounded p-1 top-0 text-xs"
                          >This is required.</span
                        >
                      </span>
                    </label>
                    <select
                      v-model="typeOfArticle"
                      class="font-poppins border outline-none border-gray-300 text-gray-900 rounded-lg focus:ring-green-500 focus:border-green-500 text-xs w-full p-2.5 bg-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option selected value="1">Thesis</option>
                      <option value="2">Capstone Project</option>
                      <option value="3">Dissertation</option>
                      <option value="4">Research</option>
                      <option value="5">Journal</option>
                      <option value="6">Others</option>
                    </select>
                  </div>
                </div>

                <div class="flex flex-wrap md:-mx-3 mb-3">
                  <div class="w-full px-3 mt-3">
                    <label
                      class="block text-gray-700 text-sm font-semibold mb-2"
                    >
                      Article / Research Title
                      <span
                        class="text-red-600 inline-flex group relative"
                        role="tooltip"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="w-4 h-4"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span
                          class="group-hover:opacity-100 transition-opacity ml-2 withArrow opacity-0 bg-red-100 text-red-700 rounded p-1 top-0 text-xs"
                          >This is required.</span
                        >
                      </span>
                    </label>
                    <textarea
                      v-model="title"
                      :class="err_title ? 'border-red-500' : ''"
                      cols="30"
                      rows="10"
                      class="w-full text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      placeholder="Enter your Article Title"
                    >
                    </textarea>
                  </div>
                </div>

                <div class="flex flex-wrap md:-mx-3 mb-3">
                  <div class="w-full px-3">
                    <label
                      class="block text-gray-700 text-sm font-semibold mb-2"
                    >
                      Abstract
                      <span
                        class="text-red-600 inline-flex group relative"
                        role="tooltip"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="w-4 h-4"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span
                          class="group-hover:opacity-100 transition-opacity ml-2 withArrow opacity-0 bg-red-100 text-red-700 rounded p-1 top-0 text-xs"
                          >This is required.</span
                        >
                      </span>
                    </label>
                    <textarea
                      v-model="abstract"
                      :class="err_abstract ? 'border-red-500' : ''"
                      cols="30"
                      rows="10"
                      class="w-full text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      placeholder="Enter the Abstract here"
                    ></textarea>
                  </div>
                </div>

                <div class="flex flex-wrap md:-mx-3 mb-3">
                  <div class="w-full px-3">
                    <label
                      class="block text-gray-700 text-sm font-semibold mb-2"
                    >
                      Main Author
                      <span
                        class="text-yellow-600 inline-flex group relative"
                        role="tooltip"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="w-4 h-4"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span
                          class="group-hover:opacity-100 transition-opacity ml-2 withArrow1 opacity-0 bg-yellow-100 text-yellow-700 rounded p-1 top-0 text-xs"
                          >This is required. Middle name is optional.</span
                        >
                      </span>
                    </label>
                    <div class="flex">
                      <input
                        v-model="mainAuthorFname"
                        type="text"
                        :class="err_mainauthorfname ? 'border-red-600' : ''"
                        class="w-1/3 mx-1 text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        placeholder="First Name"
                        id="mFname"
                      />
                      <input
                        v-model="mainAuthorMname"
                        type="text"
                        class="w-1/3 mx-1 text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        placeholder="Middle Name"
                        id="mMname"
                      />
                      <input
                        v-model="mainAuthorLname"
                        :class="err_mainauthorlname ? 'border-red-600' : ''"
                        type="text"
                        class="w-1/3 mx-1 text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        placeholder="Last Name"
                        id="mLname"
                      />
                    </div>
                    <div class="flex justify-items-start">
                      <!-- <input v-model="mainAuthorMobPhone" :class="err_mainauthormobphone ? 'border-red-600' : ''"
                        @keyup="isPhoneNumber($event)" type="text"
                        class=" mobile_phone w-1/3 mx-1 text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 "
                        placeholder="Mobile phone" /> -->
                      <input
                        v-model="mainAuthorEmail"
                        :class="err_mainauthoremail ? 'border-red-600' : ''"
                        type="email"
                        @keyup="isEmail($event)"
                        class="mobile_phone w-full mx-1 text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        placeholder="Email"
                        id="mEmail"
                      />
                      <input
                        v-model="mainAuthorOrg"
                        :class="err_mainauthororg ? 'border-red-600' : ''"
                        type="text"
                        class="organization w-full mx-1 text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        placeholder="Organization"
                        id="mOrg"
                      />
                    </div>
                  </div>
                </div>

                <div class="flex flex-wrap md:-mx-3 mb-3">
                  <div class="w-full px-3">
                    <label
                      class="text-gray-700 text-sm font-semibold mb-2 flex justify-between"
                    >
                      <div>
                        Corresponding author
                        <span
                          class="text-red-600 inline-flex group relative"
                          role="tooltip"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            class="w-4 h-4"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <span
                            class="group-hover:opacity-100 transition-opacity ml-2 withArrow2 opacity-0 bg-red-100 text-red-700 rounded p-1 top-0 text-xs"
                            >Middle name is optional.</span
                          >
                        </span>
                      </div>
                      <div class="text-xs">
                        <input
                          type="checkbox"
                          id="sameAsAuthor"
                          class="h-3"
                          @click="sameAsMainAuthor"
                        />
                        <label for="sameAsAuthor">Same as main author</label>
                      </div>
                    </label>
                    <div class="flex">
                      <input
                        v-model="corFname"
                        type="text"
                        :class="err_corFname ? 'border-red-600' : ''"
                        class="w-1/3 mx-1 text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        placeholder="First Name"
                        id="corFname"
                      />
                      <input
                        v-model="corMname"
                        type="text"
                        class="w-1/3 mx-1 text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        placeholder="Middle Name"
                        id="corMname"
                      />
                      <input
                        v-model="corLname"
                        :class="err_corLname ? 'border-red-600' : ''"
                        type="text"
                        class="w-1/3 mx-1 text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        placeholder="Last Name"
                        id="corLname"
                      />
                    </div>
                    <div class="flex justify-items-start">
                      <!-- <input v-model="mainAuthorMobPhone" :class="err_mainauthormobphone ? 'border-red-600' : ''"
                        @keyup="isPhoneNumber($event)" type="text"
                        class=" mobile_phone w-1/3 mx-1 text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 "
                        placeholder="Mobile phone" /> -->
                      <input
                        v-model="corEmail"
                        :class="err_corEmail ? 'border-red-600' : ''"
                        type="email"
                        @keyup="isEmail($event)"
                        id="corEmail"
                        class="mobile_phone w-full mx-1 text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        placeholder="Email"
                      />
                      <input
                        v-model="corOrg"
                        :class="err_corOrg ? 'border-red-600' : ''"
                        type="text"
                        class="organization w-full mx-1 text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        placeholder="Organization"
                        id="corOrg"
                      />
                    </div>
                  </div>
                </div>

                <div class="flex flex-wrap md:-mx-3 mb-3">
                  <div class="w-full px-3">
                    <label
                      class="block text-gray-700 text-sm font-semibold mb-2"
                    >
                      Tag Authors
                      <!-- <span class="text-red-600">*</span> -->
                      <!-- <span v-if="err_abstract" class="text-red-500 font-thin"
                      >&nbsp;This is required</span
                    > -->
                    </label>
                    <div
                      @click="citationDiv = !citationDiv"
                      data-bs-toggle="collapse"
                      data-bs-target="#citationDiv"
                      aria-expanded="false"
                      aria-controls="citationDiv"
                      class="flex justify-between text-sm pb-3 pt-3 w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <h1 class="text-green text-sm">Select author:</h1>
                      <div
                        :class="
                          citationDiv
                            ? 'transform rotate-0 duration-150'
                            : 'transform rotate-180 duration-150'
                        "
                      >
                        <Icons :name="'chevron-up'" class="w-4 h-4"></Icons>
                      </div>
                    </div>
                    <div class="collapse mb-2" id="citationDiv">
                      <div class="mx-auto rounded-lg p-2 space-y-2">
                        <div id="app" class="checkbox-select">
                          <div
                            id="dropdown"
                            class="checkbox-select__dropdown"
                            :class="{ activeSearch: showLoader }"
                          >
                            <div
                              class="checkbox-select__search-wrapp relative outline my-2 border-b-2 focus:within:border-bg-105005 bg-transparent"
                            >
                              <div
                                class="checkbox-select__info float-right text-gray-500 text-sm"
                              >
                                {{ checkedFilters.length }} SELECTED
                              </div>
                              <!-- <input class="checkbox-select__select-all" v-model="selectAll" type="checkbox" /> -->
                              <!-- <div class="checkbox-select__col mb-2">
                                <input class="checkbox-select__select-all" v-model="selectAll" />
                                <input type="checkbox" id="selectAll" class="checkbox-select__select-all"
                                  @click="selectAll()" v-model="allSelected">&nbsp;
                                <label for="selectAll">
                                  {{ selectAllText }}
                                </label>
                              </div> -->
                              <input
                                type="text"
                                class="thisIsInput px-2 pb-2 block appearance-none focus:outline-none bg-transparent"
                                @focus="showLoader = true"
                                @blur="showLoader = false"
                                v-model="search"
                                placeholder="Type any name..."
                              />
                              <!-- <label for="username" class="absolute top-0 -z-1 duration-300 origin-0 thisIsLabel">Search author</label> -->
                            </div>
                            <!-- <div class="checkbox-select__col">
                            <input class="checkbox-select__select-all" v-model="selectAll" />
                            <input type="checkbox" id="selectAll" class="checkbox-select__select-all" @click="selectAll" v-model="allSelected">
                            <label for="selectAll">
                              {{ selectAllText }}
                            </label>
                          </div> -->
                            <ul
                              id="customScroll"
                              class="checkbox-select__filters-wrapp"
                              data-simplebar-auto-hide="false"
                            >
                              <li
                                v-for="(filter, index) in filteredList"
                                :key="filter"
                              >
                                <div class="checkbox-select__check-wrapp">
                                  <input
                                    :id="index"
                                    class="conditions-check"
                                    v-model="checkedFilters"
                                    :value="filter.userdetail.id"
                                    type="checkbox"
                                  />
                                  &nbsp;
                                  <label :for="index"
                                    >{{ filter.userdetail.fname }}
                                    {{ filter.userdetail.lname }}
                                    <span class="text-xs text-gray-400"
                                      >{{ filter.userdetail.designation }}
                                      <!-- , {{ filter.userdetail.name_of_school }} -->
                                    </span></label
                                  >
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div class="mt-4 text-center">
                            <span class="w-full text-xs text-yellow-500"
                              >*** Showing:{{
                                filteredList.length
                              }}
                              verified/unverified users in the system. ***</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <select multiple="true" class="w-full">
                      <option><input type="text" @focus="showLoader = true" @blur="showLoader = false"
                              placeholder="search filters..." v-model="search"></option>
                            <option v-for="(filter, index) in filteredList" :key="index">
                              <input :id="index" class="conditions-check" v-model="checkedFilters" :value="filter"
                                  type="checkbox">
                              <label :for="index">{{ filter }}</label>
                            </option>
                          </select> -->
                  </div>
                </div>

                <div class="separator text-gray-500">OR</div>

                <div class="flex flex-wrap md:-mx-3 mb-6 mt-2">
                  <div class="w-full px-3">
                    <label
                      class="block text-gray-700 text-sm font-semibold mb-2"
                    >
                      Co-Authors
                      <span
                        class="text-yellow-600 inline-flex group relative"
                        role="tooltip"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="w-4 h-4"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span
                          class="group-hover:opacity-100 transition-opacity ml-2 withArrow1 opacity-0 bg-yellow-100 text-yellow-700 rounded p-1 top-0 text-xs"
                          >This is not required. Middle name is optional.</span
                        >
                      </span>
                    </label>
                    <div class="border-b-2 mb-2">
                      <div class="flex justify-evenly">
                        <input
                          type="text"
                          class="fname_coauthor w-1/3 mx-1 text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          placeholder="First Name"
                        />
                        <input
                          type="text"
                          class="mname_coauthor w-1/3 mx-1 text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          placeholder="Middle Name"
                        />
                        <input
                          type="text"
                          class="lname_coauthor w-1/3 mx-1 text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          placeholder="Last Name"
                        />
                      </div>
                      <div class="flex justify-items-start">
                        <!-- <input type="text" @keyup="isPhoneNumber($event)"
                          class=" mobile_phone_coauthor w-1/3 mx-1 text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 "
                          placeholder="Mobile phone" /> -->
                        <input
                          type="text"
                          @keyup="isEmail($event)"
                          class="email_coauthor w-full mx-1 text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          placeholder="Email"
                        />
                        <input
                          type="text"
                          class="organization_coauthor w-full mx-1 text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          placeholder="Organization"
                        />
                      </div>
                      <div class="flex justify-end">
                        <button
                          @click="addCoAuthor()"
                          class="text-xs hover:underline text-blue-700"
                        >
                          Add co-authors
                        </button>
                      </div>
                      <div class="flex mt-6">
                        <table
                          class="w-full table-fixed border-collapse border border-slate-400"
                        >
                          <tr class="bg-gray-200">
                            <th class="text-xs border">First Name</th>
                            <th class="text-xs">Middle Name</th>
                            <th class="text-xs">Last Name</th>
                            <!-- <th class="text-xs">Mobile Number</th> -->
                            <th class="text-xs">Email</th>
                            <th class="text-xs">Org</th>
                            <th class="text-xs" width="10%">Action</th>
                          </tr>
                          <tbody class="py-5 bg-white" v-if="hasCoAuthors">
                            <tr v-for="item in coAuthors" :key="item">
                              <td class="text-xs break-all whitespace-normal">
                                {{ item.fname }}
                              </td>
                              <td class="text-xs break-all whitespace-normal">
                                {{ item.mname }}
                              </td>
                              <td class="text-xs break-all whitespace-normal">
                                {{ item.lname }}
                              </td>
                              <!-- <td class="text-xs break-all whitespace-normal">
                                {{ item.mobile_phone }}
                              </td> -->
                              <td class="text-xs break-all whitespace-normal">
                                {{ item.email }}
                              </td>
                              <td class="text-xs break-all whitespace-normal">
                                {{ item.organization }}
                              </td>
                              <td align="center">
                                <button
                                  @click="editCoAuthor(item)"
                                  class="text-xs text-yellow-500"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    class="w-4 h-4"
                                  >
                                    <path
                                      d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z"
                                    />
                                    <path
                                      d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z"
                                    />
                                  </svg>
                                </button>
                                <button
                                  @click="removeCoAuthor(item)"
                                  class="text-xs text-blue-600"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-4 w-4"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td
                                colspan="6"
                                class="text-xs text-center italic text-gray-500"
                              >
                                No co-authors added
                              </td>
                            </tr>
                            <!-- <tr v-else>
                            <td colspan="6" class="text-center text-xs italic">
                              No co-authors added
                            </td>
                          </tr> -->
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex flex-wrap md:-mx-3 mb-3">
                  <div class="w-full px-3">
                    <label
                      class="block text-gray-700 text-sm font-semibold mb-2"
                    >
                      Keywords
                      <span
                        class="text-red-600 inline-flex group relative"
                        role="tooltip"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="w-4 h-4"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span
                          class="group-hover:opacity-100 transition-opacity ml-2 withArrow opacity-0 bg-red-100 text-red-700 rounded p-1 top-0 text-xs"
                          >This is required.</span
                        >
                      </span>
                    </label>
                    <textarea
                      v-model="authorsKeywords"
                      cols="30"
                      rows="10"
                      :class="err_authorsKeywords ? 'border-red-500' : ''"
                      class="w-full text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      placeholder="Enter Authors' keywords here"
                    ></textarea>
                  </div>
                </div>

                <div class="flex flex-wrap md:-mx-3 py-5">
                  <div class="w-full px-3">
                    <label
                      class="block text-gray-700 text-sm font-semibold mb-2"
                    >
                      Article Category
                      <span
                        class="text-red-600 inline-flex group relative"
                        role="tooltip"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="w-4 h-4"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span
                          class="group-hover:opacity-100 transition-opacity ml-2 withArrow opacity-0 bg-red-100 text-red-700 rounded p-1 top-0 text-xs"
                          >This is required.</span
                        >
                      </span>
                    </label>
                    <div
                      class="flex flex-wrap w-full bg-gray-200 py-4 rounded-md border"
                      :class="err_journal_cat ? 'border-red-500' : ''"
                    >
                      <div
                        class="form-check mx-5 w-40 text-sm flex mb-2"
                        v-for="item in categories"
                        :key="item"
                      >
                        <input
                          class="form-check-input h-4 w-4 border border-gray-200 rounded-sm checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer checkboxes"
                          type="checkbox"
                          :id="item.categoryDesc"
                          v-bind:value="item.id"
                          v-model="checkedCategory"
                        />
                        <label
                          class="form-check-label inline-block text-gray-800 text-sm"
                          :for="item.categoryDesc"
                        >
                          {{ item.categoryDesc }}
                        </label>
                      </div>
                      <div class="form-check mx-5 w-40 text-sm flex mb-2">
                        <input
                          @click="removeTypeCat"
                          :checked="typedCategory"
                          class="form-check-input h-4 w-4 border border-gray-200 rounded-sm checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer checkboxes"
                          type="checkbox"
                          id="otherJournalCategory"
                        />
                        <label
                          class="form-check-label inline-block text-gray-800 text-sm"
                          for="otherJournalCategory"
                        >
                          Other
                        </label>
                        <div v-if="showInput" class="pl-2 inline-flex">
                          <input
                            type="text"
                            class="focus:border-105005 pl-1.5 mr-1 rounded"
                            :class="
                              err_newCat
                                ? 'border border-red-500'
                                : 'border border-gray-50'
                            "
                            placeholder="Type here..."
                            v-model="typedCategory"
                          />
                          <!-- <button
                            title="Add this category"
                            @click="savedNewCategory"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              class="w-5 h-5 text-105005"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-11.25a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </button> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex flex-wrap md:-mx-3 mb-3">
                  <div class="w-full px-3">
                    <label
                      class="block text-gray-700 text-sm font-semibold mb-2"
                    >
                      Events
                    </label>
                    <select
                      v-model="journalTaggedEvent"
                      class="font-poppins border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 text-xs w-full p-2.5 bg-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option
                        v-for="item in eventsList"
                        :key="item"
                        selected
                        :value="item.id"
                      >
                        {{ item.eventName }}
                      </option>
                      <option value="0">No event.</option>
                    </select>
                  </div>
                </div>

                <div class="flex flex-wrap md:-mx-3 mb-3">
                  <div class="w-full px-3">
                    <label
                      class="block text-gray-700 text-sm font-semibold mb-2"
                    >
                      Article Status
                    </label>
                    <div
                      class="flex flex-wrap justify-evenly bg-gray-200 py-2 rounded-md"
                    >
                      <div class="form-check md:mx-10 text-sm flex border">
                        <input
                          class="form-check-input rounded-full h-4 w-4 border border-gray-300 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                          type="radio"
                          name="flexRadioDefault"
                          id="radioPublished"
                          :checked="isPublished"
                          @click="publishedRadio"
                          value="published"
                        />
                        <label for="radioPublished">Published</label>
                      </div>
                      <div class="form-check md:mx-10 text-sm border">
                        <input
                          class="form-check-input rounded-full h-4 w-4 border border-gray-300 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                          type="radio"
                          :checked="!isPublished"
                          name="flexRadioDefault"
                          id="radioUnPublished"
                          @click="unpublishedRadio"
                          value="unpublished"
                        />
                        <label for="radioUnPublished">Unpublished</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="flex flex-wrap md:-mx-3 mb-3"
                  v-if="!journalStatusDiv"
                >
                  <div class="w-full md:w-full px-3 mb-6 md:mb-0">
                    <label class="block text-gray-700 text-sm font-bold mb-2">
                      Year submitted
                      <span
                        class="text-red-600 inline-flex group relative"
                        role="tooltip"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="w-4 h-4"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span
                          class="group-hover:opacity-100 transition-opacity ml-2 withArrow opacity-0 bg-red-100 text-red-700 rounded p-1 top-0 text-xs"
                          >This is required.</span
                        >
                      </span>
                    </label>
                  </div>

                  <div class="w-full md:w-full px-3 mb-6 md:mb-0">
                    <!-- <input
                      v-model="conferenceDate"
                      class="block w-full text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="date"
                    /> -->
                    <select
                      :class="err_conferenceDate ? 'border-red-500' : ''"
                      v-model="conferenceDate"
                      class="appearance-none text-sm block w-full bg-gray-200 text-gray-700 border rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    >
                      <option v-for="i in 125" :key="i" v-bind:value="2025 - i">
                        {{ 2025 - i }}
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  class="flex flex-wrap md:-mx-3 mb-3"
                  v-if="journalStatusDiv"
                >
                  <div class="w-full md:w-full px-3 mb-6 md:mb-0">
                    <label class="block text-gray-700 text-sm font-bold mb-2">
                      Year published
                      <span
                        class="text-red-600 inline-flex group relative"
                        role="tooltip"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="w-4 h-4"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span
                          class="group-hover:opacity-100 transition-opacity ml-2 withArrow opacity-0 bg-red-100 text-red-700 rounded p-1 top-0 text-xs"
                          >This is required.</span
                        >
                      </span>
                    </label>
                  </div>

                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <select
                      v-model="publishedDateMonth"
                      class="appearance-none text-sm block w-full bg-gray-200 text-gray-700 border rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    >
                      <option selected value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                  </div>
                  <div class="w-full md:w-1/2 px-3">
                    <!-- <label class="block text-gray-700 text-sm font-bold mb-2"
                    >Year <span class="text-red-600">*</span></label
                  > -->
                    <select
                      v-model="publishedDateYear"
                      class="appearance-none text-sm block w-full bg-gray-200 text-gray-700 border rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    >
                      <option v-for="i in 24" :key="i" v-bind:value="2024 - i">
                        {{ 2024 - i }}
                      </option>
                    </select>
                  </div>

                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label class="block text-gray-700 text-sm font-bold mb-2">
                      Volume
                      <!-- <span class="text-red-600">*</span> -->
                      <!-- <span v-if="err_volume" class="text-red-500 font-thin"
                      >&nbsp; This is required</span
                    > -->
                    </label>
                    <input
                      class="appearance-none text-sm block w-full bg-gray-200 text-gray-700 border rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      v-model="volume"
                      type="number"
                      placeholder="1"
                    />
                  </div>
                  <div class="w-full md:w-1/2 px-3">
                    <label
                      class="block text-sm tracking-wide text-gray-700 font-bold mb-2"
                    >
                      Issue
                      <!-- <span class="text-red-600">*</span> -->
                      <!-- <span v-if="err_issue" class="text-red-500 font-thin"
                      >&nbsp;This is required</span
                    > -->
                    </label>
                    <!-- :class="err_issue ? 'border-red-500' : ''" -->
                    <input
                      class="appearance-none block w-full text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      v-model="issue"
                      type="number"
                      placeholder="1"
                    />
                  </div>

                  <div class="w-full px-3">
                    <label class="block text-gray-700 text-sm font-bold mb-2">
                      Pages
                      <!-- <span class="text-red-600">*</span> -->
                      <!-- <span v-if="err_pages" class="text-red-500 font-thin"
                      >&nbsp; This is required</span
                    > -->
                    </label>
                    <!-- :class="err_pages ? 'border-red-500' : ''" -->

                    <input
                      class="block w-full text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      v-model="pages"
                      type="text"
                      placeholder="2001-2003"
                    />
                  </div>

                  <!-- <div class="w-full px-3">
                    <label class="block text-gray-700 text-sm font-bold mb-2">
                      URL
                      <span
                        class="text-red-600 inline-flex group relative"
                        role="tooltip"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="w-4 h-4"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span
                          class="group-hover:opacity-100 transition-opacity ml-2 withArrow opacity-0 bg-red-100 text-red-700 rounded p-1 top-0 text-xs"
                          >This is required</span
                        >
                      </span>
                    </label>
                    <input
                      v-model="url"
                      :class="err_url ? 'border-red-500' : ''"
                      class="block w-full text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      placeholder="https://doi.org/your_doi"
                    />
                  </div> -->

                  <div class="w-full px-3">
                    <label class="block text-gray-700 text-sm font-bold mb-2">
                      DOI
                      <span
                        class="text-red-600 inline-flex group relative"
                        role="tooltip"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="w-4 h-4"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span
                          class="group-hover:opacity-100 transition-opacity ml-2 withArrow opacity-0 bg-red-100 text-red-700 rounded p-1 top-0 text-xs"
                          >This is required</span
                        >
                      </span>
                    </label>
                    <input
                      v-model="doi"
                      :class="err_doi ? 'border-red-500' : ''"
                      class="block w-full text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      placeholder="10.11109/ICETAS4863902/123123123"
                    />
                  </div>

                  <div class="w-full px-3">
                    <label class="block text-gray-700 text-sm font-bold mb-2">
                      Publisher
                      <!-- <span class="text-red-600">*</span> -->
                      <!-- <span v-if="err_publisher" class="text-red-500 font-thin" >&nbsp; This is required</span > -->
                    </label>
                    <!-- :class="err_publisher ? 'border-red-500' : ''" -->
                    <input
                      v-model="publisher"
                      class="block w-full text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      placeholder="IEEE"
                    />
                  </div>

                  <!-- <div class="w-full px-3">
                    <label class="block text-gray-700 text-sm font-bold mb-2">
                      Published in
                      <span class="text-red-600">*</span>
                      <span v-if="err_publishedIn" class="text-red-500 font-thin"
                      >&nbsp; This is required</span
                    >
                    </label>
                    :class="err_publishedIn ? 'border-red-500' : ''"
                    <input
                      v-model="publishedIn"
                      class="block w-full text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      placeholder="Published in"
                    />
                  </div> -->

                  <!-- <div class="w-full px-3">
                    <label class="block text-gray-700 text-sm font-bold mb-2">
                      Date Conferred
                      <span class="text-red-600">*</span>
                      <span v-if="err_conferenceDate" class="text-red-500 font-thin" >&nbsp; This is required</span >
                    </label>
                    :class="err_conferenceDate ? 'border-red-500' : ''"
                    <input
                      v-model="conferenceDate"
                      class="block w-full text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="date"
                    />
                  </div> -->

                  <div class="w-full px-3">
                    <label class="block text-gray-700 text-sm font-bold mb-2">
                      <!-- convert accession number into ISSN -->
                      ISSN
                      <!-- <span class="text-red-600">*</span> -->
                      <!-- <span v-if="err_accessionNumber" class="text-red-500 font-thin" >&nbsp; This is required</span > -->
                    </label>
                    <!-- :class="err_accessionNumber ? 'border-red-500' : ''"     -->
                    <input
                      v-model="accessionNumber"
                      class="block w-full text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      placeholder="ISSN"
                    />
                  </div>

                  <div class="w-full px-3">
                    <label class="block text-gray-700 text-sm font-bold mb-2">
                      Conference Location
                      <!-- <span class="text-red-600">*</span> -->
                      <!-- <span v-if="err_conferenceLocation" class="text-red-500 font-thin" >&nbsp; This is required</span > -->
                    </label>
                    <!-- :class="err_conferenceLocation ? 'border-red-500' : ''" -->
                    <input
                      v-model="conferenceLocation"
                      class="block w-full text-sm bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      placeholder="Conference Location"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="hidden md:flex md:w-1/2 px-4 items-center">
              <iframe
                class="overflow-y-scroll w-full h-full border"
                :src="srcPDF"
                frameborder="0"
                >Opps something went wrong</iframe
              >
            </div>
          </div>

          <div
            class="flex py-4 mt-2 mx-4 space-x-4 border-t justify-end border-gray-200 rounded-b dark:border-gray-600"
          >
            <button
              type="button"
              @click="saveForLater"
              class="bg-FFB800 font-medium rounded-lg text-sm ml-3 px-5 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              <div class="flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 4.75C2 3.784 2.784 3 3.75 3h4.836c.464 0 .909.184 1.237.513l1.414 1.414a.25.25 0 00.177.073h4.836c.966 0 1.75.784 1.75 1.75v8.5A1.75 1.75 0 0116.25 17H3.75A1.75 1.75 0 012 15.25V4.75zm8.75 4a.75.75 0 00-1.5 0v2.546l-.943-1.048a.75.75 0 10-1.114 1.004l2.25 2.5a.75.75 0 001.114 0l2.25-2.5a.75.75 0 10-1.114-1.004l-.943 1.048V8.75z"
                    clip-rule="evenodd"
                  />
                </svg>
                <!-- <div class="btnloader h-5 w-5 mr-2" v-if="isUpdating"></div> -->
                &nbsp; Save for later
              </div>
            </button>
            <!-- UPLOAD BUTTON -->
            <button
              v-if="action == 'Add New Journal' && upOnly"
              @click="uploadJournal"
              type="button"
              :disabled="isUploading1"
              :class="isUploading1 ? 'text-gray-300' : ''"
              class="text-white bg-105005 font-medium rounded-lg text-sm ml-2 px-3 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              <div class="flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5 inline"
                  :class="isUploading1 ? 'hidden' : 'block'"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M4.5 12.75l6 6 9-13.5"
                  />
                </svg>
                <!-- <svg
                  :class="isUploading1 ? 'block' : 'hidden'"
                  class="w-5 h-5 animate-spin text-white"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 4.75V6.25"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M17.1266 6.87347L16.0659 7.93413"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M19.25 12L17.75 12"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M17.1266 17.1265L16.0659 16.0659"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M12 17.75V19.25"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M7.9342 16.0659L6.87354 17.1265"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M6.25 12L4.75 12"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M7.9342 7.93413L6.87354 6.87347"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg> -->
                <!-- <div class="btnloader h-5 w-5 mr-2" v-if="isUploading"></div> -->
                {{ this.isUploading1 ? "Uploading..." : "Upload" }}
              </div>
            </button>
            <!-- END UPLOAD BUTTON -->
            <!-- UPLOAD BUTTON -->
            <button
              v-if="action == 'Add New Journal' && upWithLink"
              @click="uploadJournalLink"
              type="button"
              :disabled="isUploading2"
              :class="isUploading2 ? 'text-gray-300' : ''"
              class="text-white bg-105005 font-medium rounded-lg text-sm ml-2 px-3 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              <div class="flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5 inline"
                  :class="isUploading2 ? 'hidden' : 'block'"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M4.5 12.75l6 6 9-13.5"
                  />
                </svg>
                <!-- <svg
                  :class="isUploading2 ? 'block' : 'hidden'"
                  class="w-5 h-5 animate-spin text-white"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 4.75V6.25"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M17.1266 6.87347L16.0659 7.93413"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M19.25 12L17.75 12"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M17.1266 17.1265L16.0659 16.0659"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M12 17.75V19.25"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M7.9342 16.0659L6.87354 17.1265"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M6.25 12L4.75 12"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M7.9342 7.93413L6.87354 6.87347"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg> -->
                <!-- <div class="btnloader h-5 w-5 mr-2" v-if="isUploading"></div> -->
                {{ this.isUploading2 ? "Uploading..." : "Upload" }}
              </div>
            </button>
            <!-- END UPLOAD BUTTON -->
            <!-- UPDATE -->
            <button
              v-if="action == 'Update Journal'"
              @click="updateJournal"
              type="button"
              :disabled="isUpdating"
              class="text-white bg-105005 font-medium rounded-lg text-sm ml-3 px-5 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              <div class="flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-4 h-5 inline"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                    clip-rule="evenodd"
                  />
                </svg>
                <!-- <div class="btnloader h-5 w-5 mr-2" v-if="isUpdating"></div> -->
                Update
              </div>
            </button>
            <!-- END UPDATE BUTTON -->

            <!-- CLOSE BUTTON -->
            <button
              @click="closeAddModal"
              type="button"
              class="text-gray-500 bg-white rounded-lg border border-gray-200 text-sm font-medium px-5 py-1 hover:text-gray-900"
            >
              Close
            </button>
            <!-- END CLOSE BTN -->
          </div>
        </div>
      </div>
      <div
        v-if="
          (this.isToApprove == false && action != 'Update Journal') ||
          action == 'Add new Journal'
        "
        class="relative m-auto w-full h-screen py-8 max-w-2xl md:h-screen"
      >
        <div
          id="addModal"
          class="modal_hgt modal relative bg-white overflow-hidden rounded-lg shadow dark:bg-gray-700 animated modal_open"
        >
          <div
            class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600 bgColor"
          >
            <h3 class="text-lg font-semibold text-white dark:text-white">
              Terms and Conditions
            </h3>
            <button
              @click="closeAddModal"
              type="button"
              class="text-white bg-transparent rounded-lg text-sm p-1 pr-4 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>

          <div
            class="grid md:flex md:justify-between max-h-96 md:max-h-full overflow-y-auto"
          >
            <div class="md:w-full px-5 pt-4 items-center">
              <ul class="leading-7">
                <li class="text-justify pb-3 text-gray-600">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      class="w-5 h-5 inline text-105005"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Numquam inventore iusto corrupti. Eius eveniet sed enim, atque
                  exercitationem nemo error ipsam eligendi labore sint magnam
                  deleniti aliquid, similique corrupti voluptate.
                </li>
                <li class="text-justify pb-3 text-gray-600">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      class="w-5 h-5 inline text-105005"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Molestiae ab et commodi minima quia hic ea totam, praesentium
                  expedita accusantium delectus, voluptate harum id vitae
                  architecto explicabo nesciunt quasi tempore?
                </li>
                <li class="text-justify pb-5 text-gray-600">
                  <span
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      class="w-5 h-5 inline text-105005"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Molestiae ab et commodi minima quia hic ea totam, praesentium
                  expedita accusantium delectus, voluptate harum id vitae
                  architecto explicabo nesciunt quasi tempore? Lorem ipsum dolor
                  sit amet consectetur adipisicing elit. Velit molestiae
                  eveniet, corrupti impedit nesciunt ea quibusdam deserunt illo
                  dolor quisquam, deleniti consequatur blanditiis libero itaque.
                  Sapiente eum provident delectus iure!
                </li>
              </ul>
            </div>
          </div>
          <div
            class="flex py-4 mx-4 space-x-4 border-t border-gray-200 rounded-b dark:border-gray-600 items-center"
          >
            <button
              v-if="action == 'Add New Journal'"
              @click="(isToApprove = true), showAuthors()"
              type="button"
              :disabled="isUploading1"
              class="text-white bgColor font-medium rounded-lg text-sm ml-2 px-3 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              <div class="flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5 inline"
                  :class="isUploading1 ? 'hidden' : 'flex'"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M4.5 12.75l6 6 9-13.5"
                  />
                </svg>
                <!-- <div class="btnloader h-5 w-5 mr-2" v-if="isUploading"></div> -->
                &nbsp; Accept and Add New Article
              </div>
            </button>
            <!-- Update -->
            <button
              @click="closeAddModal"
              type="button"
              class="text-gray-700 bg-white rounded-lg border hover:bg-gray-800 hover:text-white border-gray-200 text-sm font-medium px-3 py-2"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <AlertConfirmationModal
      v-on:confirmUnpublished="confirmSavedForLater"
      v-on:closeModal="closeConfirmationModal"
      :content="contentConfirmation"
      v-if="closeConfirmSFLModal"
    />
  </div>
</template>

<script>
import axios from "axios";
import AlertConfirmationModal from "./AlertConfirmationModal.vue";
import Icons from "../components/Icons.vue";
import { useToast } from "vue-toastification";
export default {
  name: "MyWorksModal",
  props: ["action", "itemToUpdate"],
  components: { Icons, AlertConfirmationModal },
  data() {
    return {
      filters_data: [
        // { name: "Lyca", lastname: "Ubay" },
        // { name: "John", lastname: "Doe" },
        // { name: "Jane", lastname: "Doe" },
        // { name: "Alex", lastname: "Calawag" },
        // { name: "Malou", lastname: "Junsay" },
        // { name: "Mark", lastname: "Manseguiao" },
        // { name: "Mark", lastname: "Buladaco" },
        // { name: "Jhay", lastname: "Eduarte" },
        // { name: "Ryan", lastname: "Rubiano" },
        // { name: "Aljon", lastname: "Garde" },
        // { name: "Jayson", lastname: "Momoa" },
      ],
      closeConfirmSFLModal: false,
      contentConfirmation: "",
      showInput: false,
      search: "",
      citationDiv: false,
      checkedFilters: [],
      checkedFiltersUserdetailID: [],
      allSelected: false,
      selectAllText: "Select All",
      activeTrigger: false,
      dropdown: false,
      showLoader: false,
      checked: true,
      srcPDF: null,
      isToApprove: false,
      isUploading1: false,
      isUploading2: false,
      isUpdating: false,
      journalID: null,
      journalStatusDiv: false,
      categories: null,
      eventsList: null,
      checkedCategory: [],
      journalfile: null,
      typedCategory: null,
      title: null,
      abstract: null,
      corFname: null,
      corLname: null,
      corMname: null,
      corEmail: null,
      corOrg: null,
      mainAuthorFname: null,
      mainAuthorMname: null,
      mainAuthorLname: null,
      mainAuthorMobPhone: null,
      mainAuthorEmail: null,
      mainAuthorOrg: null,
      journalTaggedEvent: 0,
      typeOfArticle: 1, // 1=thesis, 2=capstone, 3=dissertation, 4=research, 5=journal, 6=others
      authorsKeywords: null,
      isPublished: false,
      publishedDateMonth: 1,
      publishedDateYear: 2023,
      volume: null,
      issue: null,
      pages: null,
      url: null,
      doi: null,
      publisher: null,
      publishedIn: null,
      conferenceDate: null,
      conferenceLocation: null,
      accessionNumber: null,
      err_checkbox: false,
      err_journalfile: false,
      err_title: false,
      err_abstract: false,
      err_mainauthorfname: false,
      err_mainauthorlname: false,
      err_mainauthormobphone: false,
      err_mainauthoremail: false,
      err_mainauthororg: false,
      err_corFname: false,
      err_corLname: false,
      err_corOrg: false,
      err_corEmail: false,
      err_authorsKeywords: false,
      err_publishedDateMonth: false,
      err_publishedDateYear: false,
      err_volume: false,
      err_journal_cat: false,
      err_issue: false,
      err_pages: false,
      err_url: false,
      err_doi: false,
      err_publisher: false,
      err_publishedIn: false,
      err_conferenceDate: false,
      err_conferenceLocation: false,
      err_accessionNumber: false,
      err_journalLink: false,
      limit: 1,
      journalLink: null,
      coAuthors: [],
      journalCat: 1,
      remarkItem: null,
      reuploadJournal: false,
      upOnly: true,
      upWithLink: false,
      err_newCat: false,
      showInput: false,
    };
  },
  // created() {
  //   this.openAddModal();
  // },
  mounted() {
    this.openAddModal();
  },
  methods: {
    closeConfirmationModal() {
      this.closeConfirmSFLModal = false;
    },
    confirmSavedForLater() {
      const toast = useToast();

      const formdata = new FormData();
      if (this.journalfile) {
        const fd = JSON.stringify({
          userid: this.$store.getters.getUserdetailID,
          roleid: this.$store.getters.getUserRole,
          title: this.title,
          abstract: this.abstract,
          journalTaggedEvent: this.journalTaggedEvent,
          taggedAuthors:
            this.checkedComputed2.length != 0
              ? JSON.stringify(this.checkedComputed2)
              : this.checkedComputed2.length <= 0
              ? "[]"
              : "[]",
          typeOfArticle: this.typeOfArticle,
          mainauthorfname: this.mainAuthorFname,
          mainauthormname: this.mainAuthorMname,
          mainauthorlname: this.mainAuthorLname,
          mainauthormobphone: this.mainAuthorMobPhone,
          mainauthoremail: this.mainAuthorEmail,
          mainauthororg: this.mainAuthorOrg,
          journalLink: this.journalLink,
          corFname: this.corFname,
          corLname: this.corLname,
          corMname: this.corMname,
          corEmail: this.corEmail,
          corOrg: this.corOrg,
          coauthors: JSON.stringify(this.coAuthors),
          authorsKeywords: this.authorsKeywords,
          isPublished: this.isPublished,
          publishedDateMonth: this.publishedDateMonth,
          publishedDateYear: this.publishedDateYear,
          volume: this.volume,
          journalstatusID: 6,
          pages: this.pages,
          issue: this.issue,
          url: this.url,
          categories: JSON.stringify(this.checkedComputed),
          // categories: this.journalCat,
          doi: this.doi,
          publisher: this.publisher,
          publishedIn: this.publishedIn,
          conferenceDate: this.conferenceDate,
          conferenceLocation: this.conferenceLocation,
          accessionNumber: this.accessionNumber,
          articleID: null,
        });
        formdata.append("body", fd);
        formdata.append("file", this.journalfile);
        axios
          .post(
            process.env.VUE_APP_API_SERVER + "/fileupload/saveForLaterPDF",
            formdata,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
              },
            }
          )
          .then(
            (response) => {
              // console.log(response.data);
              if (response.data.status == 200 || response.data.status == 201) {
                toast.success(response.data.msg, {
                  position: "top-right",
                  timeout: 3000,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: false,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
                this.isUploading1 = false;
                // const alert_msg = {
                //   msg: response.data.msg,
                // };
                setTimeout(() => {
                  this.closeAddModal();
                  this.$emit("refreshTable");
                  // this.$parent.$emit("success_alert", alert_msg);
                }, 500);
              } else if (
                response.data.status != 200 ||
                response.data.status != 201
              ) {
                toast.error(response.data.msg, {
                  position: "top-right",
                  timeout: 3000,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: false,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
                setTimeout(() => {
                  this.closeAddModal();
                  this.$emit("refreshTable");
                }, 500);
              }
            },
            (error) => {
              console.log(error);
            }
          );
      } else {
        // console.log("confirmed_myworkmodal");
        const fd = {
          userid: this.$store.getters.getUserdetailID,
          roleid: this.$store.getters.getUserRole,
          title: this.title,
          abstract: this.abstract,
          journalTaggedEvent: this.journalTaggedEvent,
          taggedAuthors:
            this.checkedComputed2.length != 0
              ? JSON.stringify(this.checkedComputed2)
              : this.checkedComputed2.length <= 0
              ? "[]"
              : "[]",
          typeOfArticle: this.typeOfArticle,
          mainauthorfname: this.mainAuthorFname,
          mainauthormname: this.mainAuthorMname,
          mainauthorlname: this.mainAuthorLname,
          mainauthormobphone: this.mainAuthorMobPhone,
          mainauthoremail: this.mainAuthorEmail,
          mainauthororg: this.mainAuthorOrg,
          journalLink: this.journalLink,
          corFname: this.corFname,
          corLname: this.corLname,
          corMname: this.corMname,
          corEmail: this.corEmail,
          corOrg: this.corOrg,
          coauthors: JSON.stringify(this.coAuthors),
          authorsKeywords: this.authorsKeywords,
          isPublished: this.isPublished,
          publishedDateMonth: this.publishedDateMonth,
          publishedDateYear: this.publishedDateYear,
          volume: this.volume,
          journalstatusID: 6,
          pages: this.pages,
          issue: this.issue,
          url: this.url,
          categories: JSON.stringify(this.checkedComputed),
          // categories: this.journalCat,
          doi: this.doi,
          publisher: this.publisher,
          publishedIn: this.publishedIn,
          conferenceDate: this.conferenceDate,
          conferenceLocation: this.conferenceLocation,
          accessionNumber: this.accessionNumber,
          articleID: null,
        };
        axios
          .post(
            process.env.VUE_APP_API_SERVER + "/fileupload/saveForLater",
            fd,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
              },
            }
          )
          .then(
            (response) => {
              if (response.data.status == 200 || response.data.status == 201) {
                toast.success(response.data.msg, {
                  position: "top-right",
                  timeout: 3000,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: false,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
                this.isUploading1 = false;
                // const alert_msg = {
                //   msg: response.data.msg,
                // };
                setTimeout(() => {
                  this.closeAddModal();
                  this.$emit("refreshTable");
                  // this.$parent.$emit("success_alert", alert_msg);
                }, 500);
              } else if (
                response.data.status != 200 ||
                response.data.status != 201
              ) {
                toast.error(response.data.msg, {
                  position: "top-right",
                  timeout: 3000,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: false,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
                setTimeout(() => {
                  this.closeAddModal();
                  this.$emit("refreshTable");
                }, 500);
              }
            },
            (error) => {
              console.log(error);
            }
          );
      }
    },
    saveForLater() {
      const toast = useToast();
      if (this.title) {
        this.err_title = false;
      } else {
        this.err_title = true;
        toast.warning("Atleast put a title before saving...", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (!this.err_title) {
        this.closeConfirmSFLModal = true;
        this.contentConfirmation =
          "Are you sure to save this upload for later?";
      }
    },
    editCoAuthor(data) {
      document.querySelector(".fname_coauthor").value = data.fname;
      document.querySelector(".mname_coauthor").value = data.mname;
      document.querySelector(".lname_coauthor").value = data.lname;
      document.querySelector(".email_coauthor").value = data.email;
      document.querySelector(".organization_coauthor").value =
        data.organization;
      this.coAuthors = this.coAuthors.filter((s) => s.fname !== data.fname);
    },
    removeTypeCat() {
      this.showInput = !this.showInput;
      if (this.showInput == false) {
        this.typedCategory = null;
      }
    },
    savedNewCategory() {
      if (this.typedCategory) {
        const toast = useToast();
        this.err_newCat = false;
        const fd = { categoryDesc: this.typedCategory };
        axios
          .post(
            process.env.VUE_APP_API_SERVER +
              "/journalcategory/add-new-category",
            fd,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
              },
            }
          )
          .then(
            (response) => {
              if (response.data.status == 201) {
                this.getJournalCategories();
                this.showInput = false;
                this.typedCategory = null;
                toast.success(response.data.msg, {
                  position: "top-right",
                  timeout: 3000,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: false,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
              } else if (response.data.status == 400) {
                toast.error(response.data.msg, {
                  position: "top-right",
                  timeout: 3000,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: false,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
              }
              this.isLoading = false;
            },
            (error) => {
              console.log(error);
            }
          );
      } else {
        this.err_newCat = true;
      }
    },
    sameAsMainAuthor() {
      let mFname = document.getElementById("mFname").value;
      let mMname = document.getElementById("mMname").value;
      let mLname = document.getElementById("mLname").value;
      let mEmail = document.getElementById("mEmail").value;
      let mOrg = document.getElementById("mOrg").value;
      if (document.getElementById("sameAsAuthor").checked) {
        if (mFname != null || mFname != "") {
          document.getElementById("corFname").value = mFname;
          this.corFname = mFname;
        }

        if (mMname != null || mMname != "") {
          document.getElementById("corMname").value = mMname;
          this.corMname = mMname;
        }

        if (mLname != null || mLname != "") {
          document.getElementById("corLname").value = mLname;
          this.corLname = mLname;
        }

        if (mEmail != null || mEmail != "") {
          document.getElementById("corEmail").value = mEmail;
          this.corEmail = mEmail;
        }

        if (mOrg != null || mOrg != "") {
          document.getElementById("corOrg").value = mOrg;
          this.corOrg = mOrg;
        }
      } else {
        this.corFname = null;
        this.corMname = null;
        this.corLname = null;
        this.corEmail = null;
        this.corOrg = null;
        document.getElementById("corFname").value = null;
        document.getElementById("corMname").value = null;
        document.getElementById("corLname").value = null;
        document.getElementById("corEmail").value = null;
        document.getElementById("corOrg").value = null;
      }
    },
    // selects all user to tag on newly uploaded journal
    selectAll() {
      this.allSelected = !this.allSelected;
      this.checkedFilters = [];
      this.selectAllText =
        this.selectAllText == "Select All" ? "Clear All" : "Select All";
      if (this.allSelected == true) {
        for (var i = 0; i < this.filters_data.length; i++) {
          this.checkedFilters.push(this.filters_data[i]);
        }
      }

      // for (filter in this.filters_data) {
      //   this.checkedFilters.push(this.filters_data[filter].toString())
      //   // console.log(this.checkedFilters);
      // }
      // console.log(this.allSelected);
      // if (this.allSelected) {

      // }
    },

    showDropdown: function () {
      if (this.dropdown == false) {
        this.dropdown = true;
        this.activeTrigger = true;
        TweenMax.fromTo(
          "#dropdown",
          0.55,
          {
            autoAlpha: 0,
            y: -10,
          },
          {
            autoAlpha: 1,
            y: 0,
            ease: Power2.easeOut,
          }
        );
      } else {
        this.dropdown = false;
        this.activeTrigger = false;
        TweenMax.to("#dropdown", 0.2, {
          autoAlpha: 0,
          y: -10,
          ease: Power2.easeOut,
        });
      }
    },

    created: function () {
      const customScroll = new SimpleBar(
        document.getElementById("customScroll")
      );
    },
    showAuthors() {
      // console.log('click showauthors')
      axios
        .get(process.env.VUE_APP_API_SERVER + "/users-controller/getAllUser", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            this.filters_data = response.data;
            // console.log(response.data);
            // this.isPublished = true;
          },
          (error) => {
            console.log(error);
          }
        );
    },
    initModal() {
      document.getElementById("inputPDF").value = "";
      this.srcPDF = "";
      this.isUpdating = false;
      this.isToApprove = false;
      this.isUploading = false;
      this.journalStatusDiv = true;
      (this.checkedCategory = []), (this.categories = null);
      this.journalfile = null;
      this.title = null;
      this.abstract = null;
      this.authors = null;
      this.authorsKeywords = null;
      this.isPublished = true;
      this.publishedDateMonth = null;
      this.publishedDateYear = null;
      this.volume = null;
      this.issue = null;
      this.pages = null;
      this.url = null;
      this.doi = null;
      this.publisher = null;
      this.publishedIn = null;
      this.conferenceDate = null;
      this.conferenceLocation = null;
      this.accessionNumber = null;
      this.err_journalfile = false;
      this.err_title = false;
      this.err_abstract = false;

      this.err_authorsKeywords = false;
      this.err_publishedDate = false;
      this.err_volume = false;
      this.err_issue = false;
      this.err_pages = false;
      this.err_url = false;
      this.err_journal_cat = false;
      this.err_doi = false;
      this.err_publisher = false;
      this.err_publishedIn = false;
      this.err_conferenceDate = false;
      this.err_conferenceLocation = false;
      this.err_accessionNumber = false;

      this.getJournalCategories();
      this.getEvents();
    },
    countLines: function () {
      alert(this.text.split(/\r?\n|\r/).length.toLocaleString() + " lines");
    },

    previewPDF() {
      const toast = useToast();
      const uploadedpdf = document.getElementById("inputPDF").files[0];
      this.journalfile = uploadedpdf;

      const pdfUrl = URL.createObjectURL(uploadedpdf);
      if (uploadedpdf) {
        this.journalLink = null;
        document.getElementById("journalLink").value = null;
        if (uploadedpdf.type != "application/pdf") {
          // alert("file not accepted");
          toast.warning("File not accepted.", {
            position: "top-center",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          document.getElementById("inputPDF").value = null;
        } else {
          this.srcPDF = pdfUrl;
          this.upOnly = true;
          this.upWithLink = false;
        }
      } else {
      }
    },

    clearjournal() {
      document.getElementById("inputPDF").value = null;
      document.getElementById("journalLink").value = null;
      this.upOnly = true;
      this.upWithLink = false;
    },

    previewPDFByLink() {
      const toast = useToast();
      const uploadedpdf = document.getElementById("journalLink").value;
      if (uploadedpdf) {
        this.journalfile = uploadedpdf;
        this.srcPDF = uploadedpdf;
        document.getElementById("inputPDF").value = null;
        this.upOnly = false;
        this.upWithLink = true;
        this.isPublished = true;
        this.journalStatusDiv = true;
      }

      // this.journalfile = uploadedpdf;

      // const pdfUrl = URL.createObjectURL(uploadedpdf);
      // const formdata = new FormData();
    },
    // upload method with link
    uploadJournalLink() {
      const toast = useToast();

      var markedRadio = document.querySelectorAll(
        'input[type="radio"]:checked'
      );
      for (var radio of markedRadio) {
        if (radio.value == "published") {
          this.isPublished = true;
        } else if (radio.value == "unpublished") {
          this.isPublished = false;
        }
      }

      // form validation
      if (this.journalLink) {
        this.err_journalLink = false;
      } else {
        this.err_journalLink = true;
        toast.error("Please put article link.", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.title) {
        this.err_title = false;
      } else {
        this.err_title = true;
        toast.error("Journal title is required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.abstract) {
        this.err_abstract = false;
      } else {
        this.err_abstract = true;
        toast.error("Abstract is required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.mainAuthorFname) {
        this.err_mainauthorfname = false;
      } else {
        this.err_mainauthorfname = true;
        toast.error("Main author's first name is required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.mainAuthorLname) {
        this.err_mainauthorlname = false;
      } else {
        this.err_mainauthorlname = true;
        toast.error("Main author's last name is required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.mainAuthorEmail) {
        if (this.checkIsEmail(this.mainAuthorEmail)) {
          this.err_mainauthoremail = false;
        } else {
          this.err_mainauthoremail = true;
          toast.error("Invalid main author's email.", {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
      } else {
        this.err_mainauthoremail = true;
        toast.error("Email is required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.mainAuthorOrg) {
        this.err_mainauthororg = false;
      } else {
        this.err_mainauthororg = true;
        toast.error("Organization is required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.corFname) {
        this.err_corFname = false;
      } else {
        this.err_corFname = true;
        toast.error("Corresponding Author's first name is required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.corLname) {
        this.err_corLname = false;
      } else {
        this.err_corLname = true;
        toast.error("Corresponding Author's last name is required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.corEmail) {
        if (this.checkIsEmail(this.corEmail)) {
          this.err_corEmail = false;
        } else {
          this.err_corEmail = true;
          toast.error("Invalid corresponding author's email.", {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
      } else {
        this.err_corEmail = true;
        toast.error("Email is required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.corOrg) {
        this.err_corOrg = false;
      } else {
        this.err_corOrg = true;
        toast.error("Corresponding Author's organization is required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.authorsKeywords) {
        this.err_authorsKeywords = false;
      } else {
        this.err_authorsKeywords = true;
        toast.error("Keywords are required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.checkedComputed.length > 0) {
        this.err_journal_cat = false;
      } else {
        if (this.typedCategory) {
          this.err_journal_cat = false;
        } else {
          this.err_journal_cat = true;
          toast.error("Please check atleast one (1) journal category.", {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
      }

      if (this.isPublished) {
        if (this.publishedDateMonth) {
          this.err_publishedDateMonth = false;
        } else {
          this.err_publishedDateMonth = true;
          toast.error("Published month is required", {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }

        if (this.publishedDateYear) {
          this.err_publishedDateYear = false;
        } else {
          this.err_publishedDateYear = true;
          toast.error("Published year is required", {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }

        // if (this.url) {
        //   this.err_url = false;
        // } else {
        //   this.err_url = true;
        //   toast.error("URL is required", {
        //     position: "top-right",
        //     timeout: 3000,
        //     closeOnClick: true,
        //     pauseOnFocusLoss: false,
        //     pauseOnHover: false,
        //     draggable: true,
        //     draggablePercent: 0.6,
        //     showCloseButtonOnHover: false,
        //     hideProgressBar: false,
        //     closeButton: "button",
        //     icon: true,
        //     rtl: false,
        //   });
        // }

        if (this.doi) {
          this.err_doi = false;
        } else {
          this.err_doi = true;
          toast.error("DOI is required", {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
      } else {
        if (this.conferenceDate) {
          this.err_conferenceDate = false;
        } else {
          this.err_conferenceDate = true;
          toast.error("Year Submitted is required", {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
      }

      const formdata = new FormData();
      if (this.isPublished) {
        if (
          (!this.err_journalLink || !this.err_journalfile) &&
          !this.err_title &&
          !this.err_abstract &&
          !this.err_mainauthorfname &&
          !this.err_mainauthorlname &&
          // !this.err_mainauthormobphone &&
          !this.err_mainauthoremail &&
          !this.err_mainauthororg &&
          !this.err_corFname &&
          !this.err_corLname &&
          !this.err_corEmail &&
          !this.err_corOrg &&
          !this.err_authorsKeywords &&
          !this.err_publishedDate &&
          !this.err_journal_cat &&
          // !this.err_volume &&
          // !this.err_issue &&
          // !this.err_pages &&
          // !this.err_checkbox &&
          // !this.err_url &&
          !this.err_doi
          // !this.err_publisher &&
          // !this.err_publishedIn &&
          // !this.err_conferenceDate &&
          // !this.err_conferenceLocation &&
          // !this.err_accessionNumber
        ) {
          this.isUploading2 = true;
          if (this.checkedComputed2.length != 0) {
            const fd = {
              userid: this.$store.getters.getUserdetailID,
              roleid: this.$store.getters.getUserRole,
              title: this.title,
              abstract: this.abstract,
              journalTaggedEvent: this.journalTaggedEvent,
              taggedAuthors: JSON.stringify(this.checkedComputed2),
              typeOfArticle: this.typeOfArticle,
              mainauthorfname: this.mainAuthorFname,
              mainauthormname: this.mainAuthorMname,
              mainauthorlname: this.mainAuthorLname,
              mainauthormobphone: this.mainAuthorMobPhone,
              mainauthoremail: this.mainAuthorEmail,
              mainauthororg: this.mainAuthorOrg,
              journalLink: this.journalLink,
              corFname: this.corFname,
              corLname: this.corLname,
              corMname: this.corMname,
              corEmail: this.corEmail,
              corOrg: this.corOrg,
              coauthors: JSON.stringify(this.coAuthors),
              authorsKeywords: this.authorsKeywords,
              isPublished: this.isPublished,
              publishedDateMonth: this.publishedDateMonth,
              publishedDateYear: this.publishedDateYear,
              volume: this.volume,
              journalstatusID: 2,
              pages: this.pages,
              issue: this.issue,
              url: this.url,
              categories: JSON.stringify(this.checkedComputed),
              typedCategory: this.typedCategory,
              // categories: this.journalCat,
              doi: this.doi,
              publisher: this.publisher,
              publishedIn: this.publishedIn,
              conferenceDate: this.conferenceDate,
              conferenceLocation: this.conferenceLocation,
              accessionNumber: this.accessionNumber,
            };
            formdata.append("body", fd);
            // formdata.append("file", this.journalfile);
            // console.log(formdata);
            axios
              .post(
                process.env.VUE_APP_API_SERVER + "/fileupload/uploadWithLink",
                fd,
                {
                  headers: {
                    Authorization: `Bearer ${this.$store.getters.getToken}`,
                  },
                }
              )
              .then(
                (response) => {
                  // this.isUploading2 = false;
                  const alert_msg = {
                    msg: response.data.msg,
                  };
                  setTimeout(() => {
                    this.closeAddModal();
                    this.$emit("refreshTable");
                    // this.$parent.$emit("success_alert", alert_msg);
                  }, 500);
                },
                (error) => {
                  console.log(error);
                }
              );
          } else if (this.checkedComputed2.length <= 0) {
            const fd = {
              userid: this.$store.getters.getUserdetailID,
              roleid: this.$store.getters.getUserRole,
              title: this.title,
              abstract: this.abstract,
              journalTaggedEvent: this.journalTaggedEvent,
              taggedAuthors: null,
              typeOfArticle: this.typeOfArticle,
              mainauthorfname: this.mainAuthorFname,
              mainauthormname: this.mainAuthorMname,
              mainauthorlname: this.mainAuthorLname,
              mainauthormobphone: this.mainAuthorMobPhone,
              mainauthoremail: this.mainAuthorEmail,
              journalLink: this.journalLink,
              mainauthororg: this.mainAuthorOrg,
              corFname: this.corFname,
              corLname: this.corLname,
              corMname: this.corMname,
              corEmail: this.corEmail,
              corOrg: this.corOrg,
              coauthors: JSON.stringify(this.coAuthors),
              authorsKeywords: this.authorsKeywords,
              isPublished: this.isPublished,
              publishedDateMonth: this.publishedDateMonth,
              publishedDateYear: this.publishedDateYear,
              volume: this.volume,
              journalstatusID: 2,
              pages: this.pages,
              issue: this.issue,
              url: this.url,
              categories: JSON.stringify(this.checkedComputed),
              typedCategory: this.typedCategory,
              // categories: this.journalCat,
              doi: this.doi,
              publisher: this.publisher,
              publishedIn: this.publishedIn,
              conferenceDate: this.conferenceDate,
              conferenceLocation: this.conferenceLocation,
              accessionNumber: this.accessionNumber,
            };
            formdata.append("body", fd);

            // formdata.append("file", this.journalfile);

            axios
              .post(
                process.env.VUE_APP_API_SERVER + "/fileupload/uploadWithLink",
                fd,
                {
                  headers: {
                    Authorization: `Bearer ${this.$store.getters.getToken}`,
                  },
                }
              )
              .then(
                (response) => {
                  this.isUploading2 = false;
                  const alert_msg = {
                    msg: response.data.msg,
                  };
                  setTimeout(() => {
                    this.closeAddModal();
                    this.$emit("refreshTable");
                    // this.$parent.$emit("success_alert", alert_msg);
                  }, 500);
                },
                (error) => {
                  console.log(error);
                }
              );
          }
        } else {
          // this.isUploading2 = false;
          return;
        }
      } else if (!this.isPublished) {
        if (
          !this.err_journalLink &&
          !this.err_title &&
          !this.err_abstract &&
          !this.err_mainauthorfname &&
          !this.err_mainauthorlname &&
          // !this.err_mainauthormobphone &&
          !this.err_mainauthoremail &&
          !this.err_mainauthororg &&
          !this.err_corFname &&
          !this.err_corLname &&
          !this.err_corEmail &&
          !this.err_corOrg &&
          !this.err_journal_cat &&
          !this.err_authorsKeywords &&
          !this.err_conferenceDate
          // !this.err_volume &&
          // !this.err_issue &&
          // !this.err_pages &&
          // !this.err_checkbox
        ) {
          this.isUploading2 = true;
          if (this.checkedComputed2.length != 0) {
            const fd = {
              userid: this.$store.getters.getUserdetailID,
              roleid: this.$store.getters.getUserRole,
              title: this.title,
              abstract: this.abstract,
              taggedAuthors: JSON.stringify(this.checkedComputed2),
              journalTaggedEvent: this.journalTaggedEvent,
              typeOfArticle: this.typeOfArticle,
              mainauthorfname: this.mainAuthorFname,
              mainauthormname: this.mainAuthorMname,
              mainauthorlname: this.mainAuthorLname,
              mainauthormobphone: this.mainAuthorMobPhone,
              mainauthoremail: this.mainAuthorEmail,
              mainauthororg: this.mainAuthorOrg,
              journalLink: this.journalLink,
              corFname: this.corFname,
              corLname: this.corLname,
              corMname: this.corMname,
              corEmail: this.corEmail,
              corOrg: this.corOrg,
              coauthors: JSON.stringify(this.coAuthors),
              authorsKeywords: this.authorsKeywords,
              isPublished: this.isPublished,
              journalstatusID: 1,
              volume: this.volume,
              pages: this.pages,
              issue: this.issue,
              publishedDateMonth: null,
              publishedDateYear: null,
              url: null,
              categories: JSON.stringify(this.checkedComputed),
              typedCategory: this.typedCategory,
              //  categories: this.journalCat,
              doi: null,
              publisher: null,
              publishedIn: null,
              conferenceDate: null,
              conferenceLocation: null,
              accessionNumber: null,
            };
            formdata.append("body", fd);
            axios
              .post(
                process.env.VUE_APP_API_SERVER + "/fileupload/uploadWithLink",
                fd,
                {
                  headers: {
                    Authorization: `Bearer ${this.$store.getters.getToken}`,
                  },
                }
              )
              .then(
                (response) => {
                  // this.isUploading2 = false;
                  const alert_msg = {
                    msg: response.data.msg,
                  };
                  setTimeout(() => {
                    this.closeAddModal();
                    this.$emit("refreshTable");
                    // this.$parent.$emit("success_alert", alert_msg);
                  }, 500);
                },
                (error) => {
                  console.log(error);
                }
              );
          } else if (this.checkedComputed2.length <= 0) {
            const fd = {
              userid: this.$store.getters.getUserdetailID,
              roleid: this.$store.getters.getUserRole,
              title: this.title,
              abstract: this.abstract,
              journalTaggedEvent: this.journalTaggedEvent,
              taggedAuthors: null,
              typeOfArticle: this.typeOfArticle,
              mainauthorfname: this.mainAuthorFname,
              mainauthormname: this.mainAuthorMname,
              mainauthorlname: this.mainAuthorLname,
              mainauthormobphone: this.mainAuthorMobPhone,
              mainauthoremail: this.mainAuthorEmail,
              mainauthororg: this.mainAuthorOrg,
              journalLink: this.journalLink,
              corFname: this.corFname,
              corLname: this.corLname,
              corMname: this.corMname,
              corEmail: this.corEmail,
              corOrg: this.corOrg,
              coauthors: JSON.stringify(this.coAuthors),
              authorsKeywords: this.authorsKeywords,
              isPublished: this.isPublished,
              volume: this.volume,
              pages: this.pages,
              issue: this.issue,
              publishedDateMonth: null,
              publishedDateYear: null,
              url: null,
              categories: JSON.stringify(this.checkedComputed),
              typedCategory: this.typedCategory,
              //  categories: this.journalCat,
              doi: null,
              publisher: null,
              publishedIn: null,
              conferenceDate: null,
              conferenceLocation: null,
              accessionNumber: null,
              journalstatusID: 1,
            };
            formdata.append("body", fd);
            // formdata.append("file", this.journalfile);
            axios
              .post(
                process.env.VUE_APP_API_SERVER + "/fileupload/uploadWithLink",
                fd,
                {
                  headers: {
                    Authorization: `Bearer ${this.$store.getters.getToken}`,
                  },
                }
              )
              .then(
                (response) => {
                  // this.isUploading2 = false;
                  const alert_msg = {
                    msg: response.data.msg,
                  };
                  setTimeout(() => {
                    this.closeAddModal();
                    this.$emit("refreshTable");
                    this.$parent.$emit("success_alert", alert_msg);
                  }, 500);
                },
                (error) => {
                  console.log(error);
                }
              );
          }
          // const fd = JSON.stringify({
          //   userid: this.$store.getters.getUserID,
          //   title: this.title,
          //   abstract: this.abstract,
          //   taggedAuthors: JSON.stringify(this.checkedComputed2),
          //   journalTaggedEvent: this.journalTaggedEvent,
          //   typeOfArticle: this.typeOfArticle,
          //   mainauthorfname: this.mainAuthorFname,
          //   mainauthormname: this.mainAuthorMname,
          //   mainauthorlname: this.mainAuthorLname,
          //   mainauthormobphone: this.mainAuthorMobPhone,
          //   mainauthoremail: this.mainAuthorEmail,
          //   mainauthororg: this.mainAuthorOrg,
          //   coauthors: JSON.stringify(this.coAuthors),
          //   authorsKeywords: this.authorsKeywords,
          //   isPublished: this.isPublished,
          //   volume: this.volume,
          //   pages: this.pages,
          //   issue: this.issue,
          //   publishedDateMonth: null,
          //   publishedDateYear: null,
          //   url: null,
          //   categories: JSON.stringify(this.checkedComputed),
          //   //  categories: this.journalCat,
          //   doi: null,
          //   publisher: null,
          //   publishedIn: null,
          //   conferenceDate: null,
          //   conferenceLocation: null,
          //   accessionNumber: null,
          // });
          // console.log(fd);
        } else {
          // this.isUploading2 = false;
          return;
        }
      }
    },

    // upload method with pdf
    uploadJournal() {
      const toast = useToast();

      // get values for checked radio button for published or unpublished
      var markedRadio = document.querySelectorAll(
        'input[type="radio"]:checked'
      );
      for (var radio of markedRadio) {
        if (radio.value == "published") {
          this.isPublished = true;
        } else if (radio.value == "unpublished") {
          this.isPublished = false;
        }
      }

      // form validation
      if (this.journalfile || this.journalLink) {
        this.err_journalfile = false;
        this.err_journalLink = false;
      } else {
        this.err_journalfile = true;
        this.err_journalLink = true;
        toast.error("Please upload your file.", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.title) {
        this.err_title = false;
      } else {
        this.err_title = true;
        toast.error("Journal title is required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.abstract) {
        this.err_abstract = false;
      } else {
        this.err_abstract = true;
        toast.error("Abstract is required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.mainAuthorFname) {
        this.err_mainauthorfname = false;
      } else {
        this.err_mainauthorfname = true;
        toast.error("Main author's first name is required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.mainAuthorLname) {
        this.err_mainauthorlname = false;
      } else {
        this.err_mainauthorlname = true;
        toast.error("Main author's last name is required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      // if (this.mainAuthorMobPhone) {
      //   if (this.checkIsPhoneNumber(this.mainAuthorMobPhone)) {
      //     this.err_mainauthormobphone = false;
      //   } else {
      //     this.err_mainauthormobphone = true;
      //     toast.error("Invalid main author's mobile phone number.", {
      //       position: "top-right",
      //       timeout: 3000,
      //       closeOnClick: true,
      //       pauseOnFocusLoss: false,
      //       pauseOnHover: false,
      //       draggable: true,
      //       draggablePercent: 0.6,
      //       showCloseButtonOnHover: false,
      //       hideProgressBar: false,
      //       closeButton: "button",
      //       icon: true,
      //       rtl: false,
      //     });
      //   }
      // } else {
      //   this.err_mainauthormobphone = true;
      //   toast.error("Mobile phone number is required", {
      //     position: "top-right",
      //     timeout: 3000,
      //     closeOnClick: true,
      //     pauseOnFocusLoss: false,
      //     pauseOnHover: false,
      //     draggable: true,
      //     draggablePercent: 0.6,
      //     showCloseButtonOnHover: false,
      //     hideProgressBar: false,
      //     closeButton: "button",
      //     icon: true,
      //     rtl: false,
      //   });
      // }

      if (this.mainAuthorEmail) {
        if (this.checkIsEmail(this.mainAuthorEmail)) {
          this.err_mainauthoremail = false;
        } else {
          this.err_mainauthoremail = true;
          toast.error("Invalid main author's email.", {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
      } else {
        this.err_mainauthoremail = true;
        toast.error("Email is required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.mainAuthorOrg) {
        this.err_mainauthororg = false;
      } else {
        this.err_mainauthororg = true;
        toast.error("Organization is required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.corFname) {
        this.err_corFname = false;
      } else {
        this.err_corFname = true;
        toast.error("Corresponding Author's first name is required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.corLname) {
        this.err_corLname = false;
      } else {
        this.err_corLname = true;
        toast.error("Corresponding Author's last name is required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.corEmail) {
        if (this.checkIsEmail(this.corEmail)) {
          this.err_corEmail = false;
        } else {
          this.err_corEmail = true;
          toast.error("Invalid corresponding author's email.", {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
      } else {
        this.err_corEmail = true;
        toast.error("Email is required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.corOrg) {
        this.err_corOrg = false;
      } else {
        this.err_corOrg = true;
        toast.error("Corresponding Author's organization is required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.authorsKeywords) {
        this.err_authorsKeywords = false;
      } else {
        this.err_authorsKeywords = true;
        toast.error("Keywords are required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.checkedComputed.length > 0) {
        this.err_journal_cat = false;
      } else {
        if (this.typedCategory) {
          this.err_journal_cat = false;
        } else {
          this.err_journal_cat = true;
          toast.error("Please check atleast one (1) journal category.", {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
      }

      if (this.isPublished) {
        if (this.publishedDateMonth) {
          this.err_publishedDateMonth = false;
        } else {
          this.err_publishedDateMonth = true;
          toast.error("Published month is required", {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }

        if (this.publishedDateYear) {
          this.err_publishedDateYear = false;
        } else {
          this.err_publishedDateYear = true;
          toast.error("Published year is required", {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }

        // if (this.url) {
        //   this.err_url = false;
        // } else {
        //   this.err_url = true;
        //   toast.error("URL is required", {
        //     position: "top-right",
        //     timeout: 3000,
        //     closeOnClick: true,
        //     pauseOnFocusLoss: false,
        //     pauseOnHover: false,
        //     draggable: true,
        //     draggablePercent: 0.6,
        //     showCloseButtonOnHover: false,
        //     hideProgressBar: false,
        //     closeButton: "button",
        //     icon: true,
        //     rtl: false,
        //   });
        // }

        if (this.doi) {
          this.err_doi = false;
        } else {
          this.err_doi = true;
          toast.error("DOI is required", {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
      }

      if (!this.isPublished) {
        // console.log("unpublished");
        if (this.conferenceDate) {
          this.err_conferenceDate = false;
        } else {
          this.err_conferenceDate = true;
          toast.error("Year submitted are required.", {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
      }

      // console.log(this.checkedFiltersUserdetailID);
      const formdata = new FormData();

      if (this.isPublished) {
        if (
          (!this.err_journalLink || !this.err_journalfile) &&
          !this.err_title &&
          !this.err_abstract &&
          !this.err_mainauthorfname &&
          !this.err_mainauthorlname &&
          !this.err_mainauthoremail &&
          !this.err_mainauthororg &&
          !this.err_corFname &&
          !this.err_corLname &&
          !this.err_corEmail &&
          !this.err_corOrg &&
          !this.err_authorsKeywords &&
          !this.err_publishedDate &&
          !this.err_journal_cat &&
          // !this.err_url &&
          !this.err_doi
          // !this.err_mainauthormobphone &&
          // !this.err_volume &&
          // !this.err_issue &&
          // !this.err_pages &&
          // !this.err_checkbox &&
          // !this.err_publisher &&
          // !this.err_publishedIn &&
          // !this.err_conferenceDate &&
          // !this.err_conferenceLocation &&
          // !this.err_accessionNumber
        ) {
          // console.log("published");
          this.isUploading1 = true;
          if (this.checkedComputed2.length != 0) {
            const fd = JSON.stringify({
              userid: this.$store.getters.getUserdetailID,
              roleid: this.$store.getters.getUserRole,
              title: this.title,
              abstract: this.abstract,
              journalTaggedEvent: this.journalTaggedEvent,
              taggedAuthors: JSON.stringify(this.checkedComputed2),
              typeOfArticle: this.typeOfArticle,
              mainauthorfname: this.mainAuthorFname,
              mainauthormname: this.mainAuthorMname,
              mainauthorlname: this.mainAuthorLname,
              mainauthormobphone: this.mainAuthorMobPhone,
              mainauthoremail: this.mainAuthorEmail,
              mainauthororg: this.mainAuthorOrg,
              journalLink: this.journalLink,
              corFname: this.corFname,
              corLname: this.corLname,
              corMname: this.corMname,
              corEmail: this.corEmail,
              corOrg: this.corOrg,
              coauthors: JSON.stringify(this.coAuthors),
              authorsKeywords: this.authorsKeywords,
              isPublished: this.isPublished,
              publishedDateMonth: this.publishedDateMonth,
              publishedDateYear: this.publishedDateYear,
              volume: this.volume,
              journalstatusID: 2,
              pages: this.pages,
              issue: this.issue,
              url: this.url,
              categories: JSON.stringify(this.checkedComputed),
              // categories: this.journalCat,
              doi: this.doi,
              publisher: this.publisher,
              publishedIn: this.publishedIn,
              conferenceDate: this.conferenceDate,
              conferenceLocation: this.conferenceLocation,
              accessionNumber: this.accessionNumber,
            });
            formdata.append("body", fd);
            formdata.append("file", this.journalfile);
            // console.log(formdata);
            axios
              .post(
                process.env.VUE_APP_API_SERVER + "/fileupload/upload",
                formdata,
                {
                  headers: {
                    Authorization: `Bearer ${this.$store.getters.getToken}`,
                  },
                }
              )
              .then(
                (response) => {
                  // console.log(response.data);
                  if (
                    response.data.status == 200 ||
                    response.data.status == 201
                  ) {
                    toast.success(response.data.msg, {
                      position: "top-right",
                      timeout: 3000,
                      closeOnClick: true,
                      pauseOnFocusLoss: false,
                      pauseOnHover: false,
                      draggable: true,
                      draggablePercent: 0.6,
                      showCloseButtonOnHover: false,
                      hideProgressBar: false,
                      closeButton: "button",
                      icon: true,
                      rtl: false,
                    });
                    this.isUploading1 = false;
                    // const alert_msg = {
                    //   msg: response.data.msg,
                    // };
                    setTimeout(() => {
                      this.closeAddModal();
                      this.$emit("refreshTable");
                      // this.$parent.$emit("success_alert", alert_msg);
                    }, 500);
                  } else if (
                    response.data.status != 200 ||
                    response.data.status != 201
                  ) {
                    toast.error(response.data.msg, {
                      position: "top-right",
                      timeout: 3000,
                      closeOnClick: true,
                      pauseOnFocusLoss: false,
                      pauseOnHover: false,
                      draggable: true,
                      draggablePercent: 0.6,
                      showCloseButtonOnHover: false,
                      hideProgressBar: false,
                      closeButton: "button",
                      icon: true,
                      rtl: false,
                    });
                    setTimeout(() => {
                      this.closeAddModal();
                      this.$emit("refreshTable");
                    }, 500);
                  }
                },
                (error) => {
                  console.log(error);
                }
              );
          } else if (this.checkedComputed2.length <= 0) {
            const fd = JSON.stringify({
              userid: this.$store.getters.getUserdetailID,
              roleid: this.$store.getters.getUserRole,
              title: this.title,
              abstract: this.abstract,
              journalTaggedEvent: this.journalTaggedEvent,
              taggedAuthors: null,
              typeOfArticle: this.typeOfArticle,
              mainauthorfname: this.mainAuthorFname,
              mainauthormname: this.mainAuthorMname,
              mainauthorlname: this.mainAuthorLname,
              mainauthormobphone: this.mainAuthorMobPhone,
              mainauthoremail: this.mainAuthorEmail,
              journalLink: this.journalLink,
              mainauthororg: this.mainAuthorOrg,
              corFname: this.corFname,
              corLname: this.corLname,
              corMname: this.corMname,
              corEmail: this.corEmail,
              corOrg: this.corOrg,
              coauthors: JSON.stringify(this.coAuthors),
              authorsKeywords: this.authorsKeywords,
              isPublished: this.isPublished,
              publishedDateMonth: this.publishedDateMonth,
              publishedDateYear: this.publishedDateYear,
              volume: this.volume,
              journalstatusID: 2,
              pages: this.pages,
              issue: this.issue,
              url: this.url,
              categories: JSON.stringify(this.checkedComputed),
              // categories: this.journalCat,
              doi: this.doi,
              publisher: this.publisher,
              publishedIn: this.publishedIn,
              conferenceDate: this.conferenceDate,
              conferenceLocation: this.conferenceLocation,
              accessionNumber: this.accessionNumber,
            });
            formdata.append("body", fd);
            formdata.append("file", this.journalfile);

            axios
              .post(
                process.env.VUE_APP_API_SERVER + "/fileupload/upload",
                formdata,
                {
                  headers: {
                    Authorization: `Bearer ${this.$store.getters.getToken}`,
                  },
                }
              )
              .then(
                (response) => {
                  // console.log(response.data);
                  if (
                    response.data.status == 200 ||
                    response.data.status == 201
                  ) {
                    toast.success(response.data.msg, {
                      position: "top-right",
                      timeout: 3000,
                      closeOnClick: true,
                      pauseOnFocusLoss: false,
                      pauseOnHover: false,
                      draggable: true,
                      draggablePercent: 0.6,
                      showCloseButtonOnHover: false,
                      hideProgressBar: false,
                      closeButton: "button",
                      icon: true,
                      rtl: false,
                    });
                    this.isUploading1 = false;
                    // const alert_msg = {
                    //   msg: response.data.msg,
                    // };
                    setTimeout(() => {
                      this.closeAddModal();
                      this.$emit("refreshTable");
                      // this.$parent.$emit("success_alert", alert_msg);
                    }, 500);
                  } else if (
                    response.data.status != 200 ||
                    response.data.status != 201
                  ) {
                    toast.error(response.data.msg, {
                      position: "top-right",
                      timeout: 3000,
                      closeOnClick: true,
                      pauseOnFocusLoss: false,
                      pauseOnHover: false,
                      draggable: true,
                      draggablePercent: 0.6,
                      showCloseButtonOnHover: false,
                      hideProgressBar: false,
                      closeButton: "button",
                      icon: true,
                      rtl: false,
                    });
                    setTimeout(() => {
                      this.closeAddModal();
                      this.$emit("refreshTable");
                    }, 500);
                  }
                },
                (error) => {
                  console.log(error);
                }
              );
          }
        } else {
          this.isUploading1 = false;
          return;
        }
      } else if (!this.isPublished) {
        if (
          !this.err_journalfile &&
          !this.err_title &&
          !this.err_abstract &&
          !this.err_mainauthorfname &&
          !this.err_mainauthorlname &&
          // !this.err_mainauthormobphone &&
          !this.err_mainauthoremail &&
          !this.err_mainauthororg &&
          !this.err_corFname &&
          !this.err_corLname &&
          !this.err_corEmail &&
          !this.err_corOrg &&
          !this.err_journal_cat &&
          !this.err_conferenceDate &&
          !this.err_authorsKeywords
          // !this.err_volume &&
          // !this.err_issue &&
          // !this.err_pages &&
          // !this.err_checkbox
        ) {
          this.isUploading1 = true;
          if (this.checkedComputed2.length != 0) {
            const fd = JSON.stringify({
              userid: this.$store.getters.getUserdetailID,
              roleid: this.$store.getters.getUserRole,
              title: this.title,
              abstract: this.abstract,
              taggedAuthors: JSON.stringify(this.checkedComputed2),
              journalTaggedEvent: this.journalTaggedEvent,
              typeOfArticle: this.typeOfArticle,
              mainauthorfname: this.mainAuthorFname,
              mainauthormname: this.mainAuthorMname,
              mainauthorlname: this.mainAuthorLname,
              mainauthormobphone: this.mainAuthorMobPhone,
              mainauthoremail: this.mainAuthorEmail,
              mainauthororg: this.mainAuthorOrg,
              journalLink: this.journalLink,
              corFname: this.corFname,
              corLname: this.corLname,
              corMname: this.corMname,
              corEmail: this.corEmail,
              corOrg: this.corOrg,
              coauthors: JSON.stringify(this.coAuthors),
              authorsKeywords: this.authorsKeywords,
              isPublished: this.isPublished,
              journalstatusID: 1,
              volume: this.volume,
              pages: this.pages,
              issue: this.issue,
              publishedDateMonth: null,
              publishedDateYear: null,
              url: null,
              categories: JSON.stringify(this.checkedComputed),
              //  categories: this.journalCat,
              doi: null,
              publisher: null,
              publishedIn: null,
              conferenceDate: this.conferenceDate,
              conferenceLocation: null,
              accessionNumber: null,
            });
            formdata.append("body", fd);
            formdata.append("file", this.journalfile);
            axios
              .post(
                process.env.VUE_APP_API_SERVER + "/fileupload/upload",
                formdata,
                {
                  headers: {
                    Authorization: `Bearer ${this.$store.getters.getToken}`,
                  },
                }
              )
              .then(
                (response) => {
                  // console.log(response.data);
                  if (
                    response.data.status == 200 ||
                    response.data.status == 201
                  ) {
                    toast.success(response.data.msg, {
                      position: "top-right",
                      timeout: 3000,
                      closeOnClick: true,
                      pauseOnFocusLoss: false,
                      pauseOnHover: false,
                      draggable: true,
                      draggablePercent: 0.6,
                      showCloseButtonOnHover: false,
                      hideProgressBar: false,
                      closeButton: "button",
                      icon: true,
                      rtl: false,
                    });
                    this.isUploading1 = false;
                    // const alert_msg = {
                    //   msg: response.data.msg,
                    // };
                    setTimeout(() => {
                      this.closeAddModal();
                      this.$emit("refreshTable");
                      // this.$parent.$emit("success_alert", alert_msg);
                    }, 500);
                  } else if (
                    response.data.status != 200 ||
                    response.data.status != 201
                  ) {
                    toast.error(response.data.msg, {
                      position: "top-right",
                      timeout: 3000,
                      closeOnClick: true,
                      pauseOnFocusLoss: false,
                      pauseOnHover: false,
                      draggable: true,
                      draggablePercent: 0.6,
                      showCloseButtonOnHover: false,
                      hideProgressBar: false,
                      closeButton: "button",
                      icon: true,
                      rtl: false,
                    });
                    setTimeout(() => {
                      this.closeAddModal();
                      this.$emit("refreshTable");
                    }, 500);
                  }
                },
                (error) => {
                  console.log(error);
                }
              );
          } else if (this.checkedComputed2.length <= 0) {
            const fd = JSON.stringify({
              userid: this.$store.getters.getUserdetailID,
              roleid: this.$store.getters.getUserRole,
              title: this.title,
              abstract: this.abstract,
              journalTaggedEvent: this.journalTaggedEvent,
              taggedAuthors: null,
              typeOfArticle: this.typeOfArticle,
              mainauthorfname: this.mainAuthorFname,
              mainauthormname: this.mainAuthorMname,
              mainauthorlname: this.mainAuthorLname,
              mainauthormobphone: this.mainAuthorMobPhone,
              mainauthoremail: this.mainAuthorEmail,
              mainauthororg: this.mainAuthorOrg,
              journalLink: this.journalLink,
              corFname: this.corFname,
              corLname: this.corLname,
              corMname: this.corMname,
              corEmail: this.corEmail,
              corOrg: this.corOrg,
              coauthors: JSON.stringify(this.coAuthors),
              authorsKeywords: this.authorsKeywords,
              isPublished: this.isPublished,
              volume: this.volume,
              pages: this.pages,
              issue: this.issue,
              publishedDateMonth: null,
              publishedDateYear: null,
              url: null,
              categories: JSON.stringify(this.checkedComputed),
              //  categories: this.journalCat,
              doi: null,
              publisher: null,
              publishedIn: null,
              conferenceDate: this.conferenceDate,
              conferenceLocation: null,
              accessionNumber: null,
              journalstatusID: 1,
            });
            formdata.append("body", fd);
            formdata.append("file", this.journalfile);
            axios
              .post(
                process.env.VUE_APP_API_SERVER + "/fileupload/upload",
                formdata,
                {
                  headers: {
                    Authorization: `Bearer ${this.$store.getters.getToken}`,
                  },
                }
              )
              .then(
                (response) => {
                  // console.log(response.data);
                  if (
                    response.data.status == 200 ||
                    response.data.status == 201
                  ) {
                    toast.success(response.data.msg, {
                      position: "top-right",
                      timeout: 3000,
                      closeOnClick: true,
                      pauseOnFocusLoss: false,
                      pauseOnHover: false,
                      draggable: true,
                      draggablePercent: 0.6,
                      showCloseButtonOnHover: false,
                      hideProgressBar: false,
                      closeButton: "button",
                      icon: true,
                      rtl: false,
                    });
                    this.isUploading1 = false;
                    // const alert_msg = {
                    //   msg: response.data.msg,
                    // };
                    setTimeout(() => {
                      this.closeAddModal();
                      this.$emit("refreshTable");
                      // this.$parent.$emit("success_alert", alert_msg);
                    }, 500);
                  } else if (
                    response.data.status != 200 ||
                    response.data.status != 201
                  ) {
                    toast.error(response.data.msg, {
                      position: "top-right",
                      timeout: 3000,
                      closeOnClick: true,
                      pauseOnFocusLoss: false,
                      pauseOnHover: false,
                      draggable: true,
                      draggablePercent: 0.6,
                      showCloseButtonOnHover: false,
                      hideProgressBar: false,
                      closeButton: "button",
                      icon: true,
                      rtl: false,
                    });
                    setTimeout(() => {
                      this.closeAddModal();
                      this.$emit("refreshTable");
                    }, 500);
                  }
                },
                (error) => {
                  console.log(error);
                }
              );
          }
          // const fd = JSON.stringify({
          //   userid: this.$store.getters.getUserID,
          //   title: this.title,
          //   abstract: this.abstract,
          //   taggedAuthors: JSON.stringify(this.checkedComputed2),
          //   journalTaggedEvent: this.journalTaggedEvent,
          //   typeOfArticle: this.typeOfArticle,
          //   mainauthorfname: this.mainAuthorFname,
          //   mainauthormname: this.mainAuthorMname,
          //   mainauthorlname: this.mainAuthorLname,
          //   mainauthormobphone: this.mainAuthorMobPhone,
          //   mainauthoremail: this.mainAuthorEmail,
          //   mainauthororg: this.mainAuthorOrg,
          //   coauthors: JSON.stringify(this.coAuthors),
          //   authorsKeywords: this.authorsKeywords,
          //   isPublished: this.isPublished,
          //   volume: this.volume,
          //   pages: this.pages,
          //   issue: this.issue,
          //   publishedDateMonth: null,
          //   publishedDateYear: null,
          //   url: null,
          //   categories: JSON.stringify(this.checkedComputed),
          //   //  categories: this.journalCat,
          //   doi: null,
          //   publisher: null,
          //   publishedIn: null,
          //   conferenceDate: null,
          //   conferenceLocation: null,
          //   accessionNumber: null,
          // });
          // console.log(fd);
        } else {
          this.isUploading1 = false;
          return;
        }
      }
    },

    // get journal categories for the modal for uploading file
    async getJournalCategories() {
      axios
        .get(process.env.VUE_APP_API_SERVER + "/journalcategory/", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            this.categories = response.data;
            // this.isPublished = true;
          },
          (error) => {
            console.log(error);
          }
        );
    },

    // get all events
    async getEvents() {
      axios
        .get(process.env.VUE_APP_API_SERVER + "/journalEvents/getAllEvents", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            this.eventsList = response.data;
            // console.log(response.data);
            // this.isPublished = true;
          },
          (error) => {
            console.log(error);
          }
        );
    },

    // update the pending journal
    updateJournal() {
      const toast = useToast();

      const newForm = new FormData();
      this.isToApprove = false;
      this.isUploading1 = true;

      // get values for checked radio button for published or unpublished
      var markedRadio = document.querySelectorAll(
        'input[type="radio"]:checked'
      );
      for (var radio of markedRadio) {
        if (radio.value == "published") {
          this.isPublished = true;
        } else if (radio.value == "unpublished") {
          this.isPublished = false;
        }
      }

      // form validation
      if (this.reuploadJournal == true) {
        if (this.journalfile) {
          this.err_journalfile = false;
        } else {
          this.err_journalfile = true;
          toast.error("Please upload your file.", {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
      } else {
        this.err_journalfile = false;
      }

      if (this.title) {
        this.err_title = false;
      } else {
        this.err_title = true;
        toast.error("Title is required.", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.abstract) {
        this.err_abstract = false;
      } else {
        this.err_abstract = true;
        toast.error("Abstract is required.", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
      if (this.mainAuthorFname) {
        this.err_mainauthorfname = false;
      } else {
        this.err_mainauthorfname = true;
        toast.error("Main author's first name is required.", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.mainAuthorLname) {
        this.err_mainauthorlname = false;
      } else {
        this.err_mainauthorlname = true;
        toast.error("Main author's last name is required.", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.mainAuthorMobPhone) {
        if (this.checkIsPhoneNumber(this.mainAuthorMobPhone)) {
          this.err_mainauthormobphone = false;
        } else {
          this.err_mainauthormobphone = true;
          toast.error("Invalid mobile phone number.", {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
      } else {
        this.err_mainauthormobphone = true;
        toast.error("Main author's mobile phone number is required.", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      // if (this.mainAuthorEmail) {
      //   if (this.checkIsEmail(this.mainAuthorEmail)) {
      //     this.err_mainauthoremail = false;
      //   } else {
      //     this.err_mainauthoremail = true;
      //     toast.error("Invalid email.", {
      //       position: "top-right",
      //       timeout: 3000,
      //       closeOnClick: true,
      //       pauseOnFocusLoss: false,
      //       pauseOnHover: false,
      //       draggable: true,
      //       draggablePercent: 0.6,
      //       showCloseButtonOnHover: false,
      //       hideProgressBar: false,
      //       closeButton: "button",
      //       icon: true,
      //       rtl: false,
      //     });
      //   }
      // } else {
      //   this.err_mainauthoremail = true;
      //   toast.error("Main author's email is required.", {
      //     position: "top-right",
      //     timeout: 3000,
      //     closeOnClick: true,
      //     pauseOnFocusLoss: false,
      //     pauseOnHover: false,
      //     draggable: true,
      //     draggablePercent: 0.6,
      //     showCloseButtonOnHover: false,
      //     hideProgressBar: false,
      //     closeButton: "button",
      //     icon: true,
      //     rtl: false,
      //   });
      // }

      if (this.mainAuthorOrg) {
        this.err_mainauthororg = false;
      } else {
        this.err_mainauthororg = true;
        toast.error("Main author's organization is required.", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
      //CORRESPONDING AUTHOR
      if (this.corFname) {
        this.err_corFname = false;
      } else {
        this.err_corFname = true;
        toast.error("Corresponding author's first name is required.", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.corLname) {
        this.err_corLname = false;
      } else {
        this.err_corLname = true;
        toast.error("Corresponding author's last name is required.", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.corEmail) {
        this.err_corEmail = false;
      } else {
        this.err_corEmail = true;
        toast.error("Corresponding author's email add is required.", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.corOrg) {
        this.err_corOrg = false;
      } else {
        this.err_corOrg = true;
        toast.error("Corresponding author's organization is required.", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      //AUTHORS KEYWORDS
      if (this.authorsKeywords) {
        this.err_authorsKeywords = false;
      } else {
        this.err_authorsKeywords = true;
        toast.error("Keywords is required.", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.checkedComputed.length > 0) {
        this.err_journal_cat = false;
      } else {
        this.err_journal_cat = true;
        toast.error("Please check atleast one (1) journal category.", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (this.isPublished) {
        if (this.publishedDateMonth) {
          this.err_publishedDateMonth = false;
        } else {
          this.err_publishedDateMonth = true;
          toast.error("Published month is required.", {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }

        if (this.publishedDateYear) {
          this.err_publishedDateYear = false;
        } else {
          this.err_publishedDateYear = true;
          toast.error("Published year is required.", {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }

        if (this.url) {
          this.err_url = false;
        } else {
          this.err_url = true;
          toast.error("url is required.", {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }

        if (this.doi) {
          this.err_doi = false;
        } else {
          this.err_doi = true;
          toast.error("DOI is required.", {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
      }

      if (this.isPublished) {
        if (
          !this.err_journalfile &&
          !this.err_title &&
          !this.err_abstract &&
          !this.err_mainauthorfname &&
          !this.err_mainauthorlname &&
          !this.err_mainauthormobphone &&
          !this.err_mainauthoremail &&
          !this.err_mainauthororg &&
          !this.err_corFname &&
          !this.err_corLname &&
          !this.err_corEmail &&
          !this.err_corOrg &&
          !this.err_authorsKeywords &&
          !this.err_publishedDateMonth &&
          !this.err_publishedDateYear &&
          !this.err_url &&
          !this.err_doi
        ) {
          const fd = JSON.stringify({
            userid: this.$store.getters.getUserdetailID,
            id: this.journalID,
            title: this.title,
            abstract: this.abstract,
            journalTaggedEvent: this.journalTaggedEvent,
            typeOfArticle: this.typeOfArticle,
            mainauthorfname: this.mainAuthorFname,
            mainauthormname: this.mainAuthorMname,
            mainauthorlname: this.mainAuthorLname,
            mainauthormobphone: this.mainAuthorMobPhone,
            mainauthoremail: this.mainAuthorEmail,
            mainauthororg: this.mainAuthorOrg,
            corFname: this.corFname,
            corLname: this.corLname,
            corEmail: this.corEmail,
            corOrg: this.corOrg,
            coauthors: JSON.stringify(this.coAuthors),
            authorsKeywords: this.authorsKeywords,
            isPublished: this.isPublished,
            publishedDateMonth: this.publishedDateMonth,
            publishedDateYear: this.publishedDateYear,
            volume: this.volume,
            pages: this.pages,
            issue: this.issue,
            url: this.url,
            categories: JSON.stringify(this.checkedComputed),
            // categories: this.journalCat,
            doi: this.doi,
            publisher: this.publisher,
            publishedIn: this.publishedIn,
            conferenceDate: this.conferenceDate,
            conferenceLocation: this.conferenceLocation,
            accessionNumber: this.accessionNumber,
            remarkItem: this.remarkItem,
          });

          newForm.append("body", fd);
          newForm.append("file", this.journalfile);
          axios
            .post(
              process.env.VUE_APP_API_SERVER + "/fileupload/editpending",
              newForm,
              {
                headers: {
                  Authorization: `Bearer ${this.$store.getters.getToken}`,
                },
              }
            )
            .then(
              (response) => {
                this.isUploading1 = false;
                // console.log(response);
                const alert_msg = {
                  msg: response.data.msg,
                };
                setTimeout(() => {
                  this.closeAddModal();
                  this.$emit("refreshTable");
                  this.$parent.$emit("success_alert", alert_msg);
                }, 500);
              },
              (error) => {
                console.log(error);
              }
            );
        } else {
          this.isUploading1 = false;
          return;
        }
      } else if (!this.isPublished) {
        if (
          !this.err_journalfile &&
          !this.err_title &&
          !this.err_abstract &&
          !this.err_mainauthorfname &&
          !this.err_mainauthorlname &&
          !this.err_mainauthormobphone &&
          !this.err_mainauthoremail &&
          !this.err_mainauthororg &&
          !this.err_corFname &&
          !this.err_corLname &&
          !this.err_corEmail &&
          !this.err_corOrg &&
          !this.err_journal_cat &&
          !this.err_authorsKeywords
        ) {
          const fd = JSON.stringify({
            userid: this.$store.getters.getUserdetailID,
            id: this.journalID,
            title: this.title,
            abstract: this.abstract,
            journalTaggedEvent: this.journalTaggedEvent,
            typeOfArticle: this.typeOfArticle,
            mainauthorfname: this.mainAuthorFname,
            mainauthormname: this.mainAuthorMname,
            mainauthorlname: this.mainAuthorLname,
            mainauthormobphone: this.mainAuthorMobPhone,
            mainauthoremail: this.mainAuthorEmail,
            mainauthororg: this.mainAuthorOrg,
            corFname: this.corFname,
            corLname: this.corLname,
            corEmail: this.corEmail,
            corOrg: this.corOrg,
            coauthors: JSON.stringify(this.coAuthors),
            authorsKeywords: this.authorsKeywords,
            isPublished: this.isPublished,
            volume: this.volume,
            pages: this.pages,
            issue: this.issue,
            publishedDateMonth: null,
            publishedDateYear: null,
            url: null,
            categories: JSON.stringify(this.checkedComputed),
            // categories: this.journalCat,
            doi: null,
            publisher: null,
            publishedIn: null,
            conferenceDate: null,
            conferenceLocation: null,
            accessionNumber: null,
            remarkItem: this.remarkItem,
          });

          newForm.append("body", fd);
          newForm.append("file", this.journalfile);
          axios
            .post(
              process.env.VUE_APP_API_SERVER + "/fileupload/editpending",
              newForm,
              {
                headers: {
                  Authorization: `Bearer ${this.$store.getters.getToken}`,
                },
              }
            )
            .then(
              (response) => {
                this.isUploading1 = false;
                // console.log(response);
                // this.initModal();
                const alert_msg = {
                  msg: response.data.msg,
                };
                setTimeout(() => {
                  this.closeAddModal();
                  this.$emit("refreshTable");
                  this.$parent.$emit("success_alert", alert_msg);
                }, 500);
              },
              (error) => {
                console.log(error);
              }
            );
        } else {
          this.isUploading1 = false;
          return;
        }
      }
    },

    // add co authors inputs to the DOM
    addCoAuthor() {
      const toast = useToast();

      if (this.coAuthors == null) {
        this.coAuthors = [];
      }

      var valid_fname = true;
      var valid_lname = true;
      var valid_mobphone = true;
      var valid_email = true;
      var valid_org = true;

      var fnameCA = document.querySelector(".fname_coauthor");
      var mnameCA = document.querySelector(".mname_coauthor");
      var lnameCA = document.querySelector(".lname_coauthor");
      // var mobphoneCA = document.querySelector(".mobile_phone_coauthor");
      var emailCA = document.querySelector(".email_coauthor");
      var orgCA = document.querySelector(".organization_coauthor");

      if (fnameCA.value == null || fnameCA.value == "") {
        valid_fname = false;
        toast.error("First name is required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } else {
        valid_fname = true;
      }

      if (lnameCA.value == null || lnameCA.value == "") {
        valid_lname = false;
        toast.error("Last name is required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } else {
        valid_lname = true;
      }
      // if (mobphoneCA.value == null || mobphoneCA.value == "") {
      //   valid_mobphone = false;
      //   toast.error("Mobile number is required", {
      //     position: "top-right",
      //     timeout: 3000,
      //     closeOnClick: true,
      //     pauseOnFocusLoss: false,
      //     pauseOnHover: false,
      //     draggable: true,
      //     draggablePercent: 0.6,
      //     showCloseButtonOnHover: false,
      //     hideProgressBar: false,
      //     closeButton: "button",
      //     icon: true,
      //     rtl: false,
      //   });
      // } else {
      //   if (this.checkIsPhoneNumber(mobphoneCA.value)) {
      //     valid_mobphone = true;
      //   } else {
      //     valid_mobphone = false;
      //     toast.error("Invalid phone number", {
      //       position: "top-right",
      //       timeout: 3000,
      //       closeOnClick: true,
      //       pauseOnFocusLoss: false,
      //       pauseOnHover: false,
      //       draggable: true,
      //       draggablePercent: 0.6,
      //       showCloseButtonOnHover: false,
      //       hideProgressBar: false,
      //       closeButton: "button",
      //       icon: true,
      //       rtl: false,
      //     });
      //   }
      // }
      // if (emailCA.value == null || emailCA.value == "") {
      //   valid_email = false;
      //   toast.error("Email is required", {
      //     position: "top-right",
      //     timeout: 3000,
      //     closeOnClick: true,
      //     pauseOnFocusLoss: false,
      //     pauseOnHover: false,
      //     draggable: true,
      //     draggablePercent: 0.6,
      //     showCloseButtonOnHover: false,
      //     hideProgressBar: false,
      //     closeButton: "button",
      //     icon: true,
      //     rtl: false,
      //   });
      // } else {
      //   if (this.checkIsEmail(emailCA.value)) {
      //     valid_email = true;
      //   } else {
      //     valid_email = false;
      //     toast.error("Invalid email", {
      //       position: "top-right",
      //       timeout: 3000,
      //       closeOnClick: true,
      //       pauseOnFocusLoss: false,
      //       pauseOnHover: false,
      //       draggable: true,
      //       draggablePercent: 0.6,
      //       showCloseButtonOnHover: false,
      //       hideProgressBar: false,
      //       closeButton: "button",
      //       icon: true,
      //       rtl: false,
      //     });
      //   }
      // }
      // if (this.checkIsEmail(emailCA.value)) {
      //   valid_email = true;
      // } else {
      //   valid_email = false;
      //   toast.error("Invalid email", {
      //     position: "top-right",
      //     timeout: 3000,
      //     closeOnClick: true,
      //     pauseOnFocusLoss: false,
      //     pauseOnHover: false,
      //     draggable: true,
      //     draggablePercent: 0.6,
      //     showCloseButtonOnHover: false,
      //     hideProgressBar: false,
      //     closeButton: "button",
      //     icon: true,
      //     rtl: false,
      //   });
      // }
      if (orgCA.value == null || orgCA.value == "") {
        valid_org = false;
        toast.error("Organization is required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } else {
        valid_org = true;
      }

      if (
        (valid_fname && valid_lname && valid_mobphone && valid_org) ||
        valid_email
      ) {
        var data = {
          fname: fnameCA.value,
          mname: mnameCA.value,
          lname: lnameCA.value,
          // mobile_phone: mobphoneCA.value,
          email: emailCA.value,
          organization: orgCA.value,
        };
        this.coAuthors.push(data);
      } else {
        return;
      }

      fnameCA.value = "";
      mnameCA.value = "";
      lnameCA.value = "";
      // mobphoneCA.value = "";
      emailCA.value = "";
      orgCA.value = "";
    },

    // remove added coauthor
    removeCoAuthor(item) {
      this.coAuthors = this.coAuthors.filter((s) => s.fname !== item.fname);
    },

    openAddModal() {
      this.getJournalCategories();
      this.getEvents();

      document.getElementById("addModal").classList.remove("modal_close");
      document.getElementById("addModal").classList.add("modal_open");

      setTimeout(() => this.$emit("openAddModal"), 500);
    },

    closeAddModal() {
      document.getElementById("addModal").classList.add("modal_close");
      document.getElementById("addModal").classList.remove("modal_open");
      setTimeout(() => this.$emit("closeAddModal"), 500);
    },

    // toggle the journal status div hidden
    unpublishedRadio() {
      this.isPublished = false;
      this.journalStatusDiv = false;
    },
    publishedRadio() {
      this.isPublished = true;
      this.journalStatusDiv = true;
    },

    checkOne(e) {
      this.journalCat = e.target.value;
    },

    reupload() {
      this.reuploadJournal = true;
    },

    isPhoneNumber(e) {
      const mp_regex = /^(09|\+639)\d{9}$/;
      return mp_regex.test(e.target.value);
    },

    isEmail(e) {
      const email_test = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
      return email_test.test(e.target.value);
    },

    checkIsPhoneNumber(pn) {
      const mp_regex = /^(09|\+639)\d{9}$/;
      return mp_regex.test(pn);
    },

    checkIsEmail(email) {
      const email_test = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
      return email_test.test(email);
    },
    // format date to display
    formatDate(date) {
      const d = new Date(date);
      var dd = d.getDate();
      if (dd < 10) {
        dd = "0" + dd;
      }
      var mm = d.getMonth() + 1;
      if (mm < 10) {
        mm = "0" + mm;
      }
      var yy = d.getFullYear();
      return yy + "-" + mm + "-" + dd;
    },
  },

  computed: {
    filteredList() {
      // return this.filters_data.filter(item => {
      //   return item.lastname.toLowerCase().includes(this.search.toLowerCase());
      // });
      // for (var i = 0; i < this.filters_data.length; i++){
      //   console.log(this.filters_data[i].userdetail.fname);

      // }
      return this.filters_data.filter((item, index) => {
        return (
          item.userdetail.fname
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          item.userdetail.lname
            .toLowerCase()
            .includes(this.search.toLowerCase())
        );
        // console.log(item.userdetail)
        // return item.userdetail.fname
        // return item.userdetail.fname
        //   .toLowerCase()
        //   .includes(this.search.toLowerCase()) ||
        //   item.userdetail.lname
        //     .toLowerCase()
        //     .includes(this.search.toLowerCase())
      });
    },

    hasCoAuthors() {
      if (this.coAuthors == null || this.coAuthors == []) {
        return false;
      } else {
        return this.coAuthors.length > 0;
      }
    },

    checkedComputed() {
      // console.log(this.checkedCategory);
      return this.checkedCategory;
    },
    checkedComputed2() {
      // console.log(this.checkedFilters);
      return this.checkedFilters;
    },
  },
  watch: {
    itemToUpdate: {
      handler(val) {
        if (val) {
          this.coAuthors = JSON.parse(val.journal.file_coauthors);
          this.checkedCategory = JSON.parse(val.journal.file_categories);
          // this.srcPDF =
          //   "http://localhost:3000/fileupload/stream-file/" +
          //   val.journal.file_filename +
          //   "#toolbar=0";

          this.journalID = val.journal.file_id;
          this.journalStatusDiv = val.journal.file_isPublished;
          this.title = val.journal.file_title;
          this.abstract = val.journal.file_abstract;
          this.mainAuthorFname = val.journal.file_mainauthorfname;
          this.mainAuthorMname = val.journal.file_mainauthormname;
          this.mainAuthorLname = val.journal.file_mainauthorlname;
          this.mainAuthorMobPhone = val.journal.file_mainauthormobphone;
          this.mainAuthorEmail = val.journal.file_mainauthoremail;
          this.mainAuthorOrg = val.journal.file_mainauthororg;
          this.authorsKeywords = val.journal.file_authorsKeywords;
          this.isPublished = val.journal.file_isPublished;
          this.journalTaggedEvent = val.journalTaggedEvent;
          this.typeOfArticle = val.journal.typeOfArticle;
          // this.publishedDate = this.formatDate(val.journal.file_publishedDate);
          this.publishedDateMonth = val.journal.file_publishedDateMonth;
          this.publishedDateYear = val.journal.file_publishedDateYear;
          this.volume = val.journal.file_volume;
          this.issue = val.journal.file_issue;
          this.pages = val.journal.file_pages;
          this.url = val.journal.file_url;
          this.doi = val.journal.file_doi;
          this.publisher = val.journal.file_publisher;
          this.publishedIn = val.journal.file_publishedIn;
          this.conferenceDate = this.formatDate(
            val.journal.file_conferenceDate
          );
          this.conferenceLocation = val.journal.file_conferenceLocation;
          this.accessionNumber = val.journal.file_accessionNumber;
          // this.journalCat = val.journal.file_categories;
          this.remarkItem = val.remarkItem;
          axios
            .get(
              process.env.VUE_APP_API_SERVER +
                "/fileupload/stream-file/" +
                val.journal.file_filename,
              {
                responseType: "blob",
                headers: {
                  Authorization: `Bearer ${this.$store.getters.getToken}`,
                },
              }
            )
            .then((response) => {
              const blob = new Blob([response.data], {
                type: "application/pdf",
              });
              const objectURL = URL.createObjectURL(blob);
              this.srcPDF = objectURL;
            });
        } else {
          return;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
/* .mobileModalheight {
  /* height: 40rem; 
} */
input[type="radio"],
input[type="checkbox"] {
  accent-color: #105005;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #a1a1a1;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

.withArrow::after {
  content: "";
  position: absolute;
  right: 80%;
  border-width: 6px;
  border-style: solid;
  --tw-bg-opacity: 1;
  border-color: transparent rgba(254, 226, 226, var(--tw-bg-opacity))
    transparent transparent;
}

.withArrow1::after {
  content: "";
  position: absolute;
  right: 91.1%;
  border-width: 6px;
  border-style: solid;
  --tw-bg-opacity: 1;
  border-color: transparent rgba(254, 243, 199, var(--tw-bg-opacity))
    transparent transparent;
}

.withArrow2::after {
  content: "";
  position: absolute;
  right: 85.5%;
  border-width: 6px;
  margin-left: 10px;
  border-style: solid;
  --tw-bg-opacity: 1;
  border-color: transparent rgba(254, 226, 226, var(--tw-bg-opacity))
    transparent transparent;
}

/* .thisIsInput:focus-within ~ .thisIsLabel {
 @apply transform scale-75 -translate-y-5 text-105005;
} */
</style>
