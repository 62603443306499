<template>
  <div class="h-auto w-full md:w-auto" id="bg">
    <div class="pt-44 m-auto w-auto md:w-10/12 h-3/5">
      <div class="flex w-full m-auto rounded-md bg-gray-50 px-10 py-10">
        <div class="w-auto md:w-1/2 grid">
          <h1 class="h_1 text-gray-600 mb-4">
            Enter your
            <span class="text-green-900 font-semibold">OTP</span>(One-Time
            Passcode) below
          </h1>
          <div class="mt-1 mb-6">
            <div
              id="otp"
              class="flex flex-row justify-center text-center px-2 mt-1"
            >
              <input
                class="m-2 border border-gray-500 h-10 w-10 text-center form-control rounded"
                type="text"
                id="first"
                maxlength="1"
              />
              <input
                class="m-2 border border-gray-500 h-10 w-10 text-center form-control rounded"
                type="text"
                id="second"
                maxlength="1"
              />
              <input
                class="m-2 border border-gray-500 h-10 w-10 text-center form-control rounded"
                type="text"
                id="third"
                maxlength="1"
              />
              <input
                class="m-2 border border-gray-500 h-10 w-10 text-center form-control rounded"
                type="text"
                id="fourth"
                maxlength="1"
              />
              <input
                class="m-2 border border-gray-500 h-10 w-10 text-center form-control rounded"
                type="text"
                id="fifth"
                maxlength="1"
              />
              <input
                class="m-2 border border-gray-500 h-10 w-10 text-center form-control rounded"
                type="text"
                id="sixth"
                maxlength="1"
              />
            </div>
          </div>
          <button
            @click="handleSubmit"
            class="uppercase w-full bg-yellow-500 font-semibold py-1 rounded-full hover:text-white mb-4"
          >
            Submit
          </button>

          <hr />

          <p class="text-xs italic text-gray-500 mt-2">
            Did not receive email verification?
            <span
              class="text-blue-500 hover:underline customPointer"
              @click="play"
              >Click here to get new one.</span
            >
            <span class="text-sm text-blue-500" v-if="timerEnabled">{{
              this.$store.getters.getCountDown
            }}</span>
          </p>
        </div>
        <div class="w-1/2 hidden md:block lg:block">
          <img
            src="../assets/bg/otp.png"
            width="400"
            height="400"
            class="absolute -mt-20 ml-10"
          />
        </div>
      </div>
    </div>
    <Spinner v-if="isLoading" />
    <LandingFooter />
  </div>
</template>

<script>
import axios from "axios";
import LandingFooter from "./LandingFooter.vue";
import Spinner from "../components/Spinner.vue";
export default {
  name: "RegistrationOTP",
  components: {
    LandingFooter,
    Spinner,
  },
  created() {
    if (!this.$store.getters.getUserEmail) {
      this.$router.push("/signin/login");
    }
  },
  mounted() {
    this.email = this.$store.getters.getUserEmail;
    this.OTPInput();
  },
  beforeUnmount() {
    document.getElementById("first").value = "";
    document.getElementById("second").value = "";
    document.getElementById("third").value = "";
    document.getElementById("fourth").value = "";
    document.getElementById("fifth").value = "";
    document.getElementById("sixth").value = "";
  },

  data() {
    return {
      email: "",
      password: "",
      otp: "",
      isLoading: false,
      timerEnabled: false,
      timerCounts: 120,
    };
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.$store.dispatch("decrement");
          this.timerCounts = this.$store.getters.getCountDown;
        }, 1000);
      }
    },

    timerCounts: {
      handler(value) {
        if (value > 0 && this.timerEnabled) {
          setTimeout(() => {
            this.$store.dispatch("decrement");
            this.timerCounts = this.$store.getters.getCountDown;
            if (this.$store.getters.getCountDown == 0) {
              this.timerEnabled = false;
              this.timerCounts = 120;
              this.$store.commit("setCountDown");
            }
          }, 1000);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },

  methods: {
    OTPInput() {
      const inputs = document.querySelectorAll("#otp > *[id]");
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].addEventListener("keydown", function (event) {
          if (event.key === "Backspace") {
            inputs[i].value = "";
            if (i !== 0) inputs[i - 1].focus();
          } else {
            if (i === inputs.length - 1 && inputs[i].value !== "") {
              return true;
            } else if (event.keyCode > 47 && event.keyCode < 58) {
              inputs[i].value = event.key;
              if (i !== inputs.length - 1) inputs[i + 1].focus();
              event.preventDefault();
            } else if (event.keyCode > 64 && event.keyCode < 91) {
              inputs[i].value = String.fromCharCode(event.keyCode);
              if (i !== inputs.length - 1) inputs[i + 1].focus();
              event.preventDefault();
            }
          }
        });
      }
    },
    handleSubmit() {
      this.isLoading = true;
      const first = document.getElementById("first").value;
      const second = document.getElementById("second").value;
      const third = document.getElementById("third").value;
      const fourth = document.getElementById("fourth").value;
      const fifth = document.getElementById("fifth").value;
      const sixth = document.getElementById("sixth").value;

      this.otp =
        first +
        "" +
        second +
        "" +
        third +
        "" +
        fourth +
        "" +
        fifth +
        "" +
        sixth;

      const fd = {
        email: this.email,
        OTP: this.otp,
      };

      axios
        .post(process.env.VUE_APP_API_SERVER + "/auth/login_validate", fd)
        .then(
          (response) => {
            if (response.data.status == 202) {
              this.$store.commit("setToken", response.data.token);
              this.$store.commit("setUserRole", 4);
              this.$store.commit("setIsValidated", true);
              this.isLoading = false;
              this.$router.push("/userspage/dashboard");
            } else if (
              response.data.status == 400 ||
              response.data.status == 404
            ) {
              this.isLoading = false;
              alert(response.data.message);
            }
          },
          (error) => {
            this.isLoading = false;
            alert(error);
          }
        );
    },

    // timer for resending new email verification
    play() {
      if (!this.timerEnabled) {
        this.timerEnabled = true;
        this.sendNewOTP();
      }
      if (this.timerEnabled) {
        return;
      }
    },

    sendNewOTP() {
      // const data = {
      //   email: this.email,
      //   id: this.$store.getters.getUserID,
      // };
      // console.log(data);

      if (this.$store.getters.getCountDown == 120) {
        axios
          .post(process.env.VUE_APP_API_SERVER + "/users-controller/newOTP", {
            email: this.email,
            id: this.$store.getters.getUserID,
          })
          .then(
            (response) => {
              // console.log(response);
            },
            (error) => {
              console.log(error);
            }
          );
      }
    },
  },
};
</script>


<style scoped>
#bg {
  background-image: url("../assets/bg/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 70rem;
}
.h_1 {
  font-size: 30px;
}
.customPointer {
  cursor: pointer;
}
</style>