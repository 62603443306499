<template>
  <div
    class="w-full h-full bg-gray-100 dark:bg-darkBG pb-2 content"
    id="content"
  >
    <h1
      class="md:text-xl text-xs lg:text-xl font-semibold py-5 pl-6 mb-2 mt-2 text-gray-900 dark:text-gray-200"
    >
      My Profile
    </h1>

    <div
      class="bg-white dark:bg-darkBG border dark:border-gray-400 h-auto py-2 flex rounded-lg mx-3 truncate pl-4 pb-5 relative"
    >
      <div class="avatar md:w-1/3">
        <button
          class="w-20 h-20 sm:w-24 sm:h-24 md:w-44 md:h-44 mt-5 md:ml-5 md:mr-12 lg:mr-7 xl:mr-7"
        >
          <!-- <img class="w-48 auto rounded-full" src="../assets/bg/avatar1.png" alt="Rounded avatar" /> -->
          <!-- <img  :src="item.url" alt="Rounded avatar"/> -->
          <!-- <img v-for="item in imgUrls" :key="item" :src="item.url"  class="w-48 auto rounded-full ring-2 ring-105005" />  -->
          <img
            :src="filePath"
            class="w-15 h-15 md:w-40 md:h-40 lg:w-44 lg:h-44 xl:w-44 xl:h-44 auto rounded-full ring-2 ring-105005"
          />

          <svg
            @click="openAddModal"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="w-6 h-6 p-1 bg-105005 rounded-full border-gray-300 text-gray-200 hover:text-white transform mr-2 translate-x-16 -translate-y-6 md:-translate-y-6 md:translate-x-32 lg:-translate-y-6 lg:translate-x-32 xl:-translate-y-6 xl:translate-x-32"
          >
            <path
              d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z"
            />
          </svg>

          <!-- <svg @click="openAddModal" xmlns="http://www.w3.org/2000/svg"
        	class=" h-6 w-6 p-1  bg-105005 rounded-full border-gray-300 text-gray-200 hover:text-white transform translate-x-32 -translate-y-10 "
        	viewBox="0 0 20 20" fill="currentColor">
        	<path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
        	<path fill-rule="evenodd"
          	d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
          	clip-rule="evenodd" />
      	</svg> -->
        </button>
        <!-- <input type="file" name="" ref="file" style="display: none" /> -->
      </div>

      <div class="grid w-full mr-10 pt-4 mx-2 md:ml-5 lg:ml-7 xl:ml-7">
        <p
          class="text-xl md:text-md lg:text-lg xl:text-3xl text-left ml-5 md:ml-10 lg:ml-5 xl:ml-5 md:pt-10 lg:pt-10 xl:pt-10 text-105005 font-semibold"
        >
          {{ username }}
        </p>
        <hr
          class="w-auto ml-5 md:ml-10 lg:ml-5 xl:ml-5 md:w-auto lg:w-auto xl:w-auto"
        />
        <p class="text-sm ml-5 text-gray-500 md:ml-10 lg:ml-5 xl:ml-5">
          Davao del Norte State College
        </p>

        <ul
          class="flex w-auto md:mx-6 md:ml-10 lg:ml-5 xl:ml-5 text-gray-600 font-semibold justify-start mt-12"
        >
          <li class="w-auto mx-2 md:mx-4">
            <router-link to="/userspage/myprofile/" class="">About</router-link>
          </li>
          <li class="w-auto mx-2 md:mx-4">
            <router-link to="/userspage/myprofile/publication"
              >Publication</router-link
            >
          </li>
          <li class="w-auto mx-2 md:mx-4">
            <router-link to="/userspage/myprofile/activityLogs"
              >Logs</router-link
            >
          </li>

          <li
            class="w-auto mx-2 md:mx-4"
            v-if="!$store.getters.getIsGoogleSignIn"
          >
            <router-link to="/userspage/myprofile/security"
              >Security</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- <button
      class="absolute right-0 bottom-0 mr-20 mb-10 bg-105005 p-3 rounded-full"
      @click="toTop"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="w-5 h-5 text-white"
      >
        <path
          fill-rule="evenodd"
          d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"
          clip-rule="evenodd"
        />
      </svg>
    </button> -->

    <div
      class="bg-white dark:bg-darkBG border dark:border-gray-400 w-auto rounded-lg mx-3 mt-2 p-4"
    >
      <router-view
        v-on:users_details_updated="UserDetails"
        v-on:getImage="getImage"
      ></router-view>
    </div>
    <UpdateProfilePicture
      v-if="showAddModal"
      v-on:closeModal="closeAddModal"
      :action="modalAction"
      v-on:getImage="getImage"
    />
  </div>
</template>

<script>
// import Security from "./Security.vue";
// import Publication from "./Publication.vue";
// import About from "./About.vue";
import axios from "axios";
import { useToast } from "vue-toastification";
import UpdateProfilePicture from "../modals/UpdateProfilePicture.vue";
export default {
  name: "UsersMyProfile",
  components: { UpdateProfilePicture },
  data() {
    return {
      username: null,
      showAddModal: false,
      modalAction: "changeProfilePic",
      filename: null,
      trueImg: null,
      imgUrls: [],
      filePath: null,
      defaultImg: "../assets/bg/avatar1.png",
      scTimer: 0,
      scY: 0,
    };
  },

  mounted() {
    this.UserDetails();
    this.getImage();
  },
  created() {
    if (this.$route.params.refreshData) {
      // console.log(this.$route.params.refreshData);
      this.UserDetails();
      this.getImage();
    }
  },

  methods: {
    getImage() {
      axios
        .get(
          process.env.VUE_APP_API_SERVER + "/users-controller/stream-file/",
          {
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then((response) => {
          const blob = new Blob([response.data]);
          var objectURL = URL.createObjectURL(blob);
          this.filePath = objectURL;
        });
    },
    async getImage2(filename) {
      var img;
      return await axios
        .get(
          process.env.VUE_APP_API_SERVER +
            "/users-controller/stream-file2/" +
            filename,
          {
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then((response) => {
          const blob = new Blob([response.data]);
          var objectURL = URL.createObjectURL(blob);
          this.filePath = objectURL;
          // var n = {
          //   url: objectURL,
          //   name: filename,
          // };
          // this.filePath = objectURL;
          // console.log(n)

          // console.log(n)
          // this.imgUrls.push(img);
        });
    },
    openAddModal() {
      this.showAddModal = true;
      const toast = useToast();
    },

    closeAddModal() {
      this.showAddModal = false;
    },
    UserDetails() {
      const id = this.$store.getters.getUserID;
      const fname = this.$store.getters.getUserFirstName;
      const mname = this.$store.getters.getUserMidName;
      const lname = this.$store.getters.getUserLastName;
      mname
        ? (this.username = fname + " " + mname[0].toUpperCase() + ". " + lname)
        : (this.username = fname + " " + lname);

      // axios
      //   .get(process.env.VUE_APP_API_SERVER + "/user-controllers/getEventTagged/" + this.journal_item.id, {
      // 	headers: {
      //   	Authorization: `Bearer ${this.$store.getters.getToken}`,
      // 	},
      //   })
      //   .then(
      // 	(response) => {
      //   	if (response.data.msgID == 1) {
      //     	this.eventTaggedID = response.data.getEventDatas.id;
      //     	this.eventID = response.data.getEventDatas.eventID;
      //     	this.eventName = response.data.getEventDatas.eventDetails.eventName;
      //     	this.eventLength = response.data.getEventDatas.length;
      //     	this.isExist = true;
      //   	} else if (response.data.msgID == 0) {
      //     	this.isExist = false;
      //   	}
      //   	// console.log(response);
      //   	// console.log(response.data.getEventDatas.eventID);
      // 	},
      // 	(error) => {
      //   	console.log(error);
      // 	}
      //   );
    },
  },
};
</script>

<style scoped>
.router-link-exact-active {
  text-decoration: underline;
  color: #105005;
  text-underline-offset: 7px;
  text-decoration-thickness: 1px;
}

.back-to-top {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  border-radius: 100%;
  background: #141c38;
  padding: 0.5rem;
  border: none;
  cursor: pointer;
  opacity: 100%;
  transition: opacity 0.5s;
}

.back-to-top:hover {
  opacity: 60%;
}

.hidden {
  opacity: 0%;
}

.back-to-top-icon {
  width: 1rem;
  height: 1rem;
  color: #7ac9f9;
}
</style>
