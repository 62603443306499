<template>
  <div class="w-1/4 m-auto py-40">
    <div class="bg-white rounded-xl p-8">
      <form method="POST" @submit.prevent class="mt-4">
        <div>
          <h1 class="italic text-gray-600 text-sm -mt-4 mb-4">
            Please enter your email address for verification.
          </h1>
        </div>
        <div class="mb-2">
          <!-- <label class="block text-green-700 text-sm mb-2">
            Email Address
          </label> -->
          <div class="mt-1 relative rounded-md shadow-sm">
            <div
              class="
                absolute
                inset-y-0
                right-0
                pr-3
                flex
                items-center
                pointer-events-none
              "
            >
              <svg
                class="h-5 w-5 text-gray-400"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                ></path>
              </svg>
            </div>
            <input
              id="email"
              v-model="email"
              class="
                block
                pr-10
                shadow
                appearance-none
                rounded-xl
                w-full
                py-2
                px-4
                text-gray-700
                mb-3
                leading-tight
                focus:outline-none focus:bg-white focus:border-orange-500
                transition
                duration-500
                ease-in-out
              "
            />
          </div>
        </div>

        <div class="mb-2">
          <div class="grid w-full m-auto text-center">
            <div>
              <button
                @click="verify"
                class="
                  bg-yellow-500
                  w-full
                  py-2
                  mt-4
                  font-md
                  rounded-xl
                  text-white
                "
              >
                Verify
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <Spinner v-if="isLoading" />
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
// import LoadingScreen from "../components/LoadingScreen.vue";
import Spinner from "../Spinner.vue";

export default {
  name: "ForgotEmail",
  data() {
    return {
      email: null,
      password: null,
      email_err: false,
      email_err_msg: "",
      password_err: false,
      isLoading: false,
    };
  },
  components: {
    Spinner,
  },

  methods: {
    verify() {
      this.isLoading = true;
      const toast = useToast();
      if (!this.email) {
        this.email_err = true;
        toast.error("Email is required", {
          position: "top-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.isLoading = false;
      } else {
        const email_test = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
        if (email_test.test(this.email)) {
          // insert axios call here
          axios
            .get(
              process.env.VUE_APP_API_SERVER + "/auth/checkEmail/" + this.email
            )
            .then(
              (response) => {
                if (response.data.status == 404) {
                  this.isLoading = false;
                  toast.error("Email does not exist.", {
                    position: "top-right",
                    timeout: 2000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: false,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: false,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                  });
                } else if (response.data.status == 302) {
                  toast.success(
                    "An OTP is sent to your email. Please check the OTP sent by the system.",
                    {
                      position: "top-right",
                      timeout: 4000,
                      closeOnClick: true,
                      pauseOnFocusLoss: true,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 0.6,
                      showCloseButtonOnHover: false,
                      hideProgressBar: false,
                      closeButton: "button",
                      icon: true,
                      rtl: false,
                    }
                  );
                  setTimeout(() => {
                    this.$router.push({
                      name: "OTP",
                      params: { data: this.email },
                    });
                    this.$store.commit("setUserEmail", this.email);
                  }, 3000);
                }
              },
              (error) => {
                console.log(error);
              }
            );
        } else {
          this.email_err = true;
          toast.error("This is not a valid email address", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.bgGreen {
  background-color: #105005;
}

.zIndex {
  z-index: -1;
}
</style>