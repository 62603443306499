<template>
    <div id="defaultModal" tabindex="-1" aria-hidden="true"
        class=" bgModal fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-screen lg:h-screen xl:h-screen 2xl:h-screen justify-center items-center">
        <div class="relative mt-10 mb-10 m-auto w-full h-full md:max-w-3xl p-4 md:h-auto">
            <div id="div"
                class=" modal relative bg-white md:max-h-full rounded-lg shadow dark:bg-gray-700 animated modal_open">
                <!-- modal header -->
                <div class="flex justify-between border-b py-4 px-4 bgColor rounded-t-lg">
                    <h1 class="text-white text-sm pl-2">
                        {{ action == "Add" ? "Add new events" : "Update events" }}
                    </h1>
                    <button type="button" @click="closeModal"
                        class=" absolute top-1 right-2.5 text-white bg-transparent rounded-lg text-sm p-1 py-3 px-3 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white ">
                        <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"></path>
                        </svg>
                    </button>
                </div>
                <!-- modal content -->

                <div v-if="action == 'Add'" class="">
                    <div class="rounded-md">
                        <div class="pt-3 px-5 max-h-96 overflow-y-auto">
                            <div class="form-group mb-3 md:grid md:grid-cols-2">
                                <div class="mr-2">
                                    <div>
                                        <div class="inline-flex">
                                            <label for="small-input"
                                                class="block mb-2 text-xs font-semibold text-gray-600 dark:text-gray-300">Event
                                                Name</label>&nbsp;<span class="text-red-500">*</span>
                                        </div>
                                        <input v-model="eventName" id="eventName" @keyup="hasChanged($event)"
                                            placeholder="ex. Research Paper Presentation of Marine Bio Student" type="text"
                                            class="customIndent block py-2 w-full text-gray-600 bg-white rounded-lg border border-gray-300 text-xs focus:ring-green-300 focus:border-green-300" />
                                    </div>
                                    <div class="mt-2">
                                        <div class="inline-flex">
                                            <label for="small-input"
                                                class=" block mb-2 text-xs font-semibold text-gray-600 dark:text-gray-300 ">Event
                                                Date</label>&nbsp;<span class="text-red-500">*</span>
                                        </div>
                                        <input v-model="eventDate" type="date" id="eventDate" @change="hasChanged($event)"
                                            class=" block py-2 w-full text-gray-600 bg-white rounded-lg border border-gray-300 text-xs pr-4 pl-3 focus:ring-green-300 focus:border-green-300 " />
                                    </div>
                                    <div class="mt-2">
                                        <div class="inline-flex">
                                            <label for="small-input"
                                                class=" block mb-2 text-xs font-semibold text-gray-600 dark:text-gray-300 ">Event
                                                Status</label>&nbsp;<span class="text-red-500">*</span>
                                        </div>
                                        <select v-model="eventStatus" @change="hasChanged($event)"
                                            class="border border-gray-300 text-gray-500 rounded-lg focus:ring-green-500 focus:border-green-500 text-xs w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value="1">Active</option>
                                            <option value="2">Previous</option>
                                            <option value="3">Upcoming</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="ml-3 mt-2 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0">
                                    <div class="inline-flex">
                                        <label for="small-input"
                                            class=" block mb-2 text-xs font-semibold text-gray-600 dark:text-gray-300">Event
                                            Description</label>&nbsp;<span class="text-red-500">*</span>
                                    </div>
                                    <textarea v-model="eventDesc" id="eventDesc" @keyup="hasChanged($event)"
                                        class=" block py-2 px-2 w-full text-gray-600 bg-white rounded-lg border border-gray-300 text-xs focus:ring-green-300 focus:border-green-300"
                                        placeholder="Type the event description here..." cols="65" rows="10"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" flex justify-end w-full border-t pt-3 pb-4 px-5 space-x-2 rounded-b dark:border-gray-600 ">
                        <!-- Save -->
                        <button v-if="action == 'Add'"
                            :disabled="eventName1 == true && eventDesc1 == true && eventDate1 == true ? false : true"
                            @click="save" type="button" id="addBtn"
                            :class="eventName1 == true && eventDesc1 == true && eventDate1 == true ? 'bgColor' : 'bg-gray-500'"
                            class=" text-white font-medium rounded-md text-xs ml-5 px-4 py-1.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ">
                            <div class="flex">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                    stroke="currentColor" class="w-4 h-4 inline mr-1">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                                Save
                                <div class="btnloader h-4 w-4 ml-2" v-if="isLoading"></div>
                            </div>
                        </button>

                        <!-- Update -->
                        <button v-if="action == 'Update'" :disabled="isLoading" @click="update" type="button"
                            class=" text-white bg-green-800 font-medium rounded-md text-xs ml-5 px-5 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ">
                            <div class="flex">
                                Update
                                <div class="btnloader h-4 w-4 ml-2" v-if="isLoading"></div>
                            </div>
                        </button>

                        <button @click="closeModal" type="button"
                            class=" text-gray-500 bg-white rounded-md border border-gray-200 text-xs font-medium px-5 py-1.5 hover:text-gray-900 ">
                            Close </button>
                    </div>
                </div>

                <div v-if="action == 'Update'" class="pb-8">
                    <div class="rounded-md">
                        <div class="pt-3 px-5 max-h-96 overflow-y-auto">
                            <div class="form-group mb-3 md:grid md:grid-cols-2">
                                <div class="mr-2">
                                    <div>
                                        <div class="inline-flex">
                                            <label for="small-input"
                                                class="block mb-2 text-xs font-semibold text-gray-600 dark:text-gray-300">Event
                                                Name</label>&nbsp;<span class="text-red-500">*</span>
                                        </div>
                                        <input v-model="eventName" id="eventName1" @keyup="hasChanged1($event)"
                                            placeholder="ex. Research Paper Presentation of Marine Bio Student" type="text"
                                            class="customIndent block py-2 w-full text-gray-600 bg-white rounded-lg border border-gray-300 text-xs focus:ring-green-300 focus:border-green-300" />
                                    </div>
                                    <div class="mt-2">
                                        <div class="inline-flex">
                                            <label for="small-input"
                                                class=" block mb-2 text-xs font-semibold text-gray-600 dark:text-gray-300 ">Event
                                                Date</label>&nbsp;<span class="text-red-500">*</span>
                                        </div>
                                        <input v-model="eventDate" type="date" id="eventDate1" @change="hasChanged1($event)"
                                            class=" block py-2 w-full text-gray-600 bg-white rounded-lg border border-gray-300 text-xs pr-4 pl-3 focus:ring-green-300 focus:border-green-300 " />
                                    </div>
                                    <div class="mt-2">
                                        <div class="inline-flex">
                                            <label for="small-input"
                                                class=" block mb-2 text-xs font-semibold text-gray-600 dark:text-gray-300 ">Event
                                                Status</label>&nbsp;<span class="text-red-500">*</span>
                                        </div>
                                        <select v-model="eventStatus" id="eventStatus1" @change="hasChanged1($event)"
                                            class="border border-gray-300 text-gray-500 rounded-lg focus:ring-green-500 focus:border-green-500 text-xs w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value="1">Active</option>
                                            <option value="2">Previous</option>
                                            <option value="3">Upcoming</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="mt-2 ml-3 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0">
                                    <div class="inline-flex">
                                        <label for="small-input"
                                            class=" block mb-2 text-xs font-semibold text-gray-600 dark:text-gray-300">Event
                                            Description</label>&nbsp;<span class="text-red-500">*</span>
                                    </div>
                                    <textarea v-model="eventDesc" id="eventDesc1" @keyup="hasChanged1($event)"
                                        class="block py-2 px-2 w-full text-gray-600 bg-white rounded-lg border border-gray-300 text-xs"
                                        placeholder="Type the event description here..." cols="63" rows="10"></textarea>
                                </div>
                            </div>
                        </div>
                        <div
                            class=" flex justify-end border-t pt-3 pb-4 w-full px-5 space-x-2 rounded-b dark:border-gray-600 ">
                            <!-- Save -->
                            <button v-if="action == 'Add'" disabled @click="save" type="button" id="addBtn"
                                class=" text-white bgColor font-medium rounded-md text-xs ml-5 px-5 py-1.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ">
                                <div class="flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline mr-1">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                    </svg>
                                    Save
                                    <div class="btnloader h-4 w-4 ml-2" v-if="isLoading"></div>
                                </div>
                            </button>

                            <!-- Update -->
                            <button v-if="action == 'Update'" disabled @click="update" type="button" id="updateBtn"
                                class=" text-white bg-gray-500 font-medium rounded-md text-xs ml-5 px-4 py-1.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ">
                                <div class="flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline mr-1">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                    </svg>
                                    Update
                                    <div class="btnloader h-4 w-4 ml-2" v-if="isLoading"></div>
                                </div>
                            </button>

                            <button @click="closeModal" type="button"
                                class=" text-gray-500 bg-white rounded-lg border border-gray-200 text-xs font-medium px-5 py-1.5 hover:text-gray-900 ">
                                Close </button>
                        </div>
                        <hr />
                        <div class="overflow-hidden px-5" v-if="action == 'Update'">
                            <table id="taggedTable" class="w-full mx-auto table-fixed md:mt-5 rounded">
                                <tr>
                                    <th class="cursor-pointer font-poppins rounded-t-md text-xs text-left bg-customGreen font-medium text-white px-6 md:py-3" @click="sortTable(labelIndex)"
                                        v-for="(label, labelIndex) in table_headers" :key="labelIndex"> {{ label.text }}
                                    </th>
                                </tr>
                                <tr v-if="displayedTaggedEvents.length == 0"
                                    class="bg-grey-100 border-b border-l border-r border-t border-gray-400 transition duration-300 ease-in-out pl-0">
                                    <td class=" text-xs text-gray-600 font-poppins font-light px-6 py-2 md:py-2 lg:py-2 xl:py-2 text-justify withData ">No data found.</td>
                                </tr>
                                <tr v-else v-for="item in displayedTaggedEvents" :key="item"
                                    class="bg-grey-100 border-b border-l border-r border-t border-gray-400 transition duration-300 ease-in-out pl-0">
                                    <td class=" text-xs text-gray-600 font-poppins font-light px-6 py-2 md:py-2 lg:py-2 xl:py-2 text-justify withData "
                                        valign="center">
                                        {{ item.journalDetails.title }}
                                    </td>
                                </tr>
                            </table>
                            <div
                                class=" grid font-poppins w-full sm:flex md:flex lg:flex flex-row justify-end md:w-full sm:w-full mx-auto items-center border-l border-b border-r border-gray-400 rounded-bl-lg rounded-br-lg pt-4 pb-4 mb-5 md:mb-0 lg:mb-0 xl:mb-0 2xl:mb-0">
                                <div class=" w-auto sm:w-3/6 md:w-3/6 lg:w-3/6 xl:w-3/6 gap-x-4 mx-3 flex ">
                                    <div class="hidden text-xs text-gray-600 md:flex w-1/3 md:w-2/5 items-center "> <label
                                            for="" class="w-2/3">Rows per
                                            page</label>&nbsp; <select class="w-1/3 border-gray-600 border-b"
                                            v-model="perPage">
                                            <option :value="item" v-for="item in selectPerPage" :key="item"> {{ item }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="hidden md:flex w-1/3 md:w-2/5">
                                        <!-- <div class="flex" v-if="results != 0"> -->
                                        <span class="text-xs m-auto text-gray-600 dark:text-gray-400"> Showing <span
                                                class="font-semibold text-gray-900 dark:text-white">{{ fromPage }}</span> to
                                            <span class="font-semibold text-gray-900 dark:text-white">{{ toPage }}</span> of
                                            <span class="font-semibold text-gray-900 dark:text-white">{{ results }}</span>
                                            Entries
                                        </span>
                                    </div>
                                    <nav class="w-1/3 md:w-1/5">
                                        <ul class="flex md:justify-end">
                                            <li class="page-item mx-1"> <button type="button"
                                                    class=" page-link bg-customGreen text-white rounded-md p-1 text-xs "
                                                    v-if="page > 1" @click="page--"> <svg xmlns="http://www.w3.org/2000/svg"
                                                        class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fill-rule="evenodd"
                                                            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                                            clip-rule="evenodd" />
                                                    </svg> </button> <!--for the disabled button --> <button type="button"
                                                    class=" page-link bg-customGreen rounded-md text-white p-1 text-xs "
                                                    v-if="page == 1" disabled>
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5"
                                                        viewBox="0 0 20 20" fill="currentColor">
                                                        <path fill-rule="evenodd"
                                                            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                                            clip-rule="evenodd" />
                                                    </svg> </button> </li>
                                            <li class="page-item mx-1"> <button type="button" @click="page++"
                                                    v-if="page < pages.length"
                                                    class=" page-link bg-customGreen rounded-md text-white p-1 text-xs ">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5"
                                                        viewBox="0 0 20 20" fill="currentColor">
                                                        <path fill-rule="evenodd"
                                                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                            clip-rule="evenodd" />
                                                    </svg> </button> <!--for the disabled button --> <button type="button"
                                                    disabled v-if="page >= pages.length"
                                                    class=" page-link bg-customGreen rounded-md text-white p-1 text-xs ">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5"
                                                        viewBox="0 0 20 20" fill="currentColor">
                                                        <path fill-rule="evenodd"
                                                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                            clip-rule="evenodd" />
                                                    </svg> </button>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Table from "../components/Table.vue";
import { useToast } from "vue-toastification";
export default {
    name: "EventsModal",
    props: ["action", "eventsItem"],
    components: { Table },
    data() {
        return {
            eventName: null,
            eventDesc: null,
            eventDate: null,
            eventID: null,
            eventStatus: 1,
            isLoading: false,
            journalTitleData: null,
            contents: [],
            page: 1,
            isLoading: false,
            perPage: 10,
            selectPerPage: [10, 15, 20, 30, 50],
            pages: [],
            fromPage: 1,
            eventName1: false,
            eventDesc1: false,
            eventDate1: false,
            eventStatus1: false,
            addBtnChange: false,
            toPage: null,
            results: null,
            defEventName: null,
            defEventDesc: null,
            defEventDate: null,
            defEventStatus: null,
            table_headers: [
                { text: "Research Title", field: "journalDetails" },
                // { text: "Action", field: "action" },
            ],
            defAction: null
        }
    },
    mounted() {
        this.init();
        this.loadTaggedResearch();
    },
    computed: {
        displayedTaggedEvents() {
            return this.paginate(this.contents);
        },
    },
    methods: {
        sortTable(d) {
            var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
            table = document.getElementById('taggedTable');
            switching = true;
            dir = 'asc';
            while (switching) {
                switching = false;
                rows = table.rows;
                for (i = 1; i < (rows.length - 1); i++) {
                    shouldSwitch = false;
                    x = rows[i].getElementsByTagName("TD")[d];
                    y = rows[i + 1].getElementsByTagName("TD")[d];
                    if (dir == "asc") {
                        if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                            // If so, mark as a switch and break the loop:
                            shouldSwitch = true;
                            break;
                        }
                    }
                    else if (dir == "desc") {
                        if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                            // If so, mark as a switch and break the loop:
                            shouldSwitch = true;
                            break;
                        }
                    }
                }
                if (shouldSwitch) {
                    rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                    switching = true;
                    switchcount++;
                }
                else {
                    if (switchcount == 0 && dir == "asc") {
                        dir = "desc";
                        switching = true;
                    }
                }
            }
        },
        hasChanged(event) {
            if (event.target.id == 'eventName') {
                if (event.currentTarget.value.length == 0) {
                    this.eventName1 = false;
                }
                if (event.currentTarget.value.length >= 1) {
                    this.eventName1 = true;
                }
            }

            if (event.target.id == 'eventDesc') {
                if (event.currentTarget.value.length == 0) {
                    this.eventDesc1 = false;
                }
                if (event.currentTarget.value.length >= 1) {
                    this.eventDesc1 = true;
                }
            }

            if (event.target.id == 'eventDate') {
                if (event.currentTarget.value.length == 0) {
                    this.eventDate1 = false;
                }
                if (event.currentTarget.value.length >= 1) {
                    this.eventDate1 = true;
                }
            }
        },
        hasChanged1(event) {
            let updateBtn = document.getElementById('updateBtn');
            let eventDate = this.eventDate;
            let eventDesc = this.eventDesc;
            let eventName = this.eventName;
            let eventStatus = this.eventStatus;
            let defEventName = this.defEventName;
            let defEventDesc = this.defEventDesc;
            let defEventDate = this.defEventDate;
            let defEventStatus = this.defEventStatus;
            if (event.target.id == 'eventName1') {
                if (event.currentTarget.value.length == String(defEventName).length) {
                    if (event.currentTarget.value.trim()) {
                        if (event.currentTarget.value != String(defEventName)) {
                            if (eventDate != null || eventDate != '') {
                                if (eventDesc != null || eventDesc != '') {
                                    updateBtn.disabled = false;
                                    updateBtn.classList.remove('bg-gray-500');
                                    updateBtn.classList.add('bgColor');
                                }
                                else {
                                    updateBtn.disabled = true;
                                    updateBtn.classList.add('bg-gray-500');
                                    updateBtn.classList.remove('bgColor');
                                }
                            }
                            else {
                                updateBtn.disabled = true;
                                updateBtn.classList.add('bg-gray-500');
                                updateBtn.classList.remove('bgColor');
                            }
                        }
                        else {
                            updateBtn.disabled = true;
                            updateBtn.classList.add('bg-gray-500');
                            updateBtn.classList.remove('bgColor');
                        }
                    }
                    else {
                        updateBtn.disabled = true;
                        updateBtn.classList.add('bg-gray-500');
                        updateBtn.classList.remove('bgColor');
                    }
                }

                if (event.currentTarget.value.length > String(defEventName).length || event.currentTarget.value.length < String(defEventName).length) {
                    if (event.currentTarget.value.trim()) {
                        if (event.currentTarget.value != String(defEventName)) {
                            if (eventDate != null || eventDate != '') {
                                if (eventDesc != null || eventDesc != '') {
                                    updateBtn.disabled = false;
                                    updateBtn.classList.remove('bg-gray-500');
                                    updateBtn.classList.add('bgColor');
                                }
                                else {
                                    updateBtn.disabled = true;
                                    updateBtn.classList.add('bg-gray-500');
                                    updateBtn.classList.remove('bgColor');
                                }
                            }
                            else {
                                updateBtn.disabled = true;
                                updateBtn.classList.add('bg-gray-500');
                                updateBtn.classList.remove('bgColor');
                            }
                        }
                        else {
                            updateBtn.disabled = true;
                            updateBtn.classList.add('bg-gray-500');
                            updateBtn.classList.remove('bgColor');
                        }
                    }
                    else {
                        updateBtn.disabled = true;
                        updateBtn.classList.add('bg-gray-500');
                        updateBtn.classList.remove('bgColor');
                    }
                }
            }

            if (event.target.id == 'eventDesc1') {
                if (event.currentTarget.value.length == String(defEventDesc).length) {
                    if (event.currentTarget.value.trim()) {
                        if (event.currentTarget.value != String(defEventDesc)) {
                            if (eventDate != null || eventDate != '') {
                                if (eventName != null || eventName != '') {
                                    updateBtn.disabled = false;
                                    updateBtn.classList.remove('bg-gray-500');
                                    updateBtn.classList.add('bgColor');
                                }
                                else {
                                    updateBtn.disabled = true;
                                    updateBtn.classList.add('bg-gray-500');
                                    updateBtn.classList.remove('bgColor');
                                }
                            }
                            else {
                                updateBtn.disabled = true;
                                updateBtn.classList.add('bg-gray-500');
                                updateBtn.classList.remove('bgColor');
                            }
                        }
                        else {
                            updateBtn.disabled = true;
                            updateBtn.classList.add('bg-gray-500');
                            updateBtn.classList.remove('bgColor');
                        }
                    }
                    else {
                        updateBtn.disabled = true;
                        updateBtn.classList.add('bg-gray-500');
                        updateBtn.classList.remove('bgColor');
                    }
                }


                if (event.currentTarget.value.length > String(defEventDesc).length || event.currentTarget.value.length < String(defEventDesc).length) {
                    if (event.currentTarget.value.trim()) {
                        if (event.currentTarget.value != String(defEventDesc)) {
                            if (eventDate != null || eventDate != '') {
                                if (eventName != null || eventName != '') {
                                    updateBtn.disabled = false;
                                    updateBtn.classList.remove('bg-gray-500');
                                    updateBtn.classList.add('bgColor');
                                }
                                else {
                                    updateBtn.disabled = true;
                                    updateBtn.classList.add('bg-gray-500');
                                    updateBtn.classList.remove('bgColor');
                                }
                            }
                            else {
                                updateBtn.disabled = true;
                                updateBtn.classList.add('bg-gray-500');
                                updateBtn.classList.remove('bgColor');
                            }
                        }
                        else {
                            updateBtn.disabled = true;
                            updateBtn.classList.add('bg-gray-500');
                            updateBtn.classList.remove('bgColor');
                        }
                    }
                    else {
                        updateBtn.disabled = true;
                        updateBtn.classList.add('bg-gray-500');
                        updateBtn.classList.remove('bgColor');
                    }
                }
            }

            if (event.target.id == 'eventDate1') {
                if (event.currentTarget.value != defEventDate) {
                    if (event.currentTarget.value != null && event.currentTarget.value != '') {
                        if (eventName != null || eventName != '') {
                            if (eventDesc != null || eventDesc != '') {
                                updateBtn.disabled = false;
                                updateBtn.classList.remove('bg-gray-500');
                                updateBtn.classList.add('bgColor');
                            }
                            else {
                                updateBtn.disabled = true;
                                updateBtn.classList.add('bg-gray-500');
                                updateBtn.classList.remove('bgColor');
                            }
                        }
                        else {
                            updateBtn.disabled = true;
                            updateBtn.classList.add('bg-gray-500');
                            updateBtn.classList.remove('bgColor');
                        }
                    }
                    else {
                        updateBtn.disabled = true;
                        updateBtn.classList.add('bg-gray-500');
                        updateBtn.classList.remove('bgColor');
                    }
                }
                else {
                    if (eventName != null || eventName != '') {
                        if (eventName != defEventName) {
                            if (eventDesc != null || eventDesc != '') {
                                if (eventDesc != defEventDesc) {
                                    if (eventStatus != defEventStatus) {
                                        updateBtn.disabled = false;
                                        updateBtn.classList.remove('bg-gray-500');
                                        updateBtn.classList.add('bgColor');
                                    }
                                    else {
                                        updateBtn.disabled = true;
                                        updateBtn.classList.add('bg-gray-500');
                                        updateBtn.classList.remove('bgColor');
                                    }
                                }
                                else {
                                    updateBtn.disabled = true;
                                    updateBtn.classList.add('bg-gray-500');
                                    updateBtn.classList.remove('bgColor');
                                }
                            }
                            else {
                                updateBtn.disabled = true;
                                updateBtn.classList.add('bg-gray-500');
                                updateBtn.classList.remove('bgColor');
                            }
                        }
                        else {
                            updateBtn.disabled = true;
                            updateBtn.classList.add('bg-gray-500');
                            updateBtn.classList.remove('bgColor');
                        }

                    }
                    else {
                        updateBtn.disabled = true;
                        updateBtn.classList.add('bg-gray-500');
                        updateBtn.classList.remove('bgColor');
                    }
                }
            }

            if (event.target.id == 'eventStatus1') {
                if (event.currentTarget.value != defEventStatus) {
                    if (eventDate != null || eventDate != '') {
                        if (eventName != null || eventName != '') {
                            if (eventDesc != null || eventDesc != '') {
                                updateBtn.disabled = false;
                                updateBtn.classList.remove('bg-gray-500');
                                updateBtn.classList.add('bgColor');
                            }
                            else {
                                updateBtn.disabled = true;
                                updateBtn.classList.add('bg-gray-500');
                                updateBtn.classList.remove('bgColor');
                            }
                        }
                        else {
                            updateBtn.disabled = true;
                            updateBtn.classList.add('bg-gray-500');
                            updateBtn.classList.remove('bgColor');
                        }
                    }
                    else {
                        updateBtn.disabled = true;
                        updateBtn.classList.add('bg-gray-500');
                        updateBtn.classList.remove('bgColor');
                    }
                }
                else {
                    updateBtn.disabled = true;
                    updateBtn.classList.add('bg-gray-500');
                    updateBtn.classList.remove('bgColor');
                }
            }
        },
        init() {
            this.contents = [];
            this.page = 1;
            this.perPage = 10;
            this.pages = [];
            this.fromPage = 1;
            this.toPage = null;
            this.results = null;
            document.getElementById("div").classList.remove("modal_close");
            document.getElementById("div").classList.add("modal_open");
            // this.loadTaggedResearch();
        },
        closeModal() {
            document.getElementById("div").classList.remove("modal_open");

            document.getElementById("div").classList.add("modal_close");

            setTimeout(() => this.$emit("closeModal"), 500);
        },

        // load all user accounts to verify
        loadTaggedResearch() {
            // console.log(this.eventsItem.id);
            // if (this.action == 'update') {
            //     var id = this.eventsItem.id;
            // }
            // else {
            //     var id = null;
            // }
            // console.log(this.eventID);
            axios
                .get(process.env.VUE_APP_API_SERVER + "/journalEvents/getTaggedJournal/" + this.eventID, {
                    headers: {
                        Authorization: `Bearer ${this.$store.getters.getToken}`,
                    },
                })
                .then(
                    (response) => {
                        this.contents = response.data;
                        // console.log(response.data);
                        // this.journalTitleData = response.data.journalDetails.title;
                        this.results = response.data.length;
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        },

        save() {
            const fd = { eventName: this.eventName, eventDesc: this.eventDesc, eventDate: this.eventDate, eventStatus: this.eventStatus };
            const toast = useToast();
            this.isLoading = true;
            if (this.eventName == null || this.eventName == "") {
                toast.error("Event Name is required.", {
                    position: "top-right",
                    timeout: 3000,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: false,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: false,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                });
                this.isLoading = false;
            } else if (this.eventDesc == null || this.eventDesc == "") {
                toast.error("Event Description is required.", {
                    position: "top-right",
                    timeout: 3000,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: false,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: false,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                });
                this.isLoading = false;
            } else if (this.eventDate == null || this.eventDate == "") {
                toast.error("Event Date is required.", {
                    position: "top-right",
                    timeout: 3000,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: false,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: false,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                });
                this.isLoading = false;
            } else {
                axios
                    .post(
                        process.env.VUE_APP_API_SERVER +
                        "/journalEvents/addNewEvents",
                        fd,
                        {
                            headers: {
                                Authorization: `Bearer ${this.$store.getters.getToken}`,
                            },
                        }
                    )
                    .then(
                        (response) => {
                            // console.log(response);
                            if (response.data.status == 201) {
                                this.$emit("success");

                                toast.success(response.data.msg, {
                                    position: "top-right",
                                    timeout: 3000,
                                    closeOnClick: true,
                                    pauseOnFocusLoss: false,
                                    pauseOnHover: false,
                                    draggable: true,
                                    draggablePercent: 0.6,
                                    showCloseButtonOnHover: false,
                                    hideProgressBar: false,
                                    closeButton: "button",
                                    icon: true,
                                    rtl: false,
                                });
                            } else if (response.data.status == 400) {
                                toast.error(response.data.msg, {
                                    position: "top-right",
                                    timeout: 3000,
                                    closeOnClick: true,
                                    pauseOnFocusLoss: false,
                                    pauseOnHover: false,
                                    draggable: true,
                                    draggablePercent: 0.6,
                                    showCloseButtonOnHover: false,
                                    hideProgressBar: false,
                                    closeButton: "button",
                                    icon: true,
                                    rtl: false,
                                });
                            }
                            this.isLoading = false;
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
            }
        },

        update() {

            this.isLoading = true;
            const toast = useToast();

            if (this.eventName == null || this.eventName == "") {
                toast.error("Event Name is required.", {
                    position: "top-right",
                    timeout: 3000,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: false,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: false,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                });
            } else if (this.eventDesc == null || this.eventDesc == "") {
                toast.error("Event Description is required.", {
                    position: "top-right",
                    timeout: 3000,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: false,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: false,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                });
                this.isLoading = false;
            } else if (this.eventDate == null || this.eventDate == "") {
                toast.error("Event Date is required.", {
                    position: "top-right",
                    timeout: 3000,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: false,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: false,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                });
                this.isLoading = false;
            } else {
                const data = {
                    eventName: this.eventName,
                    eventDesc: this.eventDesc,
                    eventDate: this.eventDate,
                    eventStatus: this.eventStatus
                };

                axios
                    .patch(
                        process.env.VUE_APP_API_SERVER +
                        "/journalEvents/" +
                        this.eventID,
                        data,
                        {
                            headers: {
                                Authorization: `Bearer ${this.$store.getters.getToken}`,
                            },
                        }
                    )
                    .then(
                        (response) => {
                            if (response.data.status == 200) {
                                this.$emit("success");

                                toast.success(response.data.msg, {
                                    position: "top-right",
                                    timeout: 3000,
                                    closeOnClick: true,
                                    pauseOnFocusLoss: false,
                                    pauseOnHover: false,
                                    draggable: true,
                                    draggablePercent: 0.6,
                                    showCloseButtonOnHover: false,
                                    hideProgressBar: false,
                                    closeButton: "button",
                                    icon: true,
                                    rtl: false,
                                });
                            } else if (response.data.status == 400) {
                                toast.error(response.data.msg, {
                                    position: "top-right",
                                    timeout: 3000,
                                    closeOnClick: true,
                                    pauseOnFocusLoss: false,
                                    pauseOnHover: false,
                                    draggable: true,
                                    draggablePercent: 0.6,
                                    showCloseButtonOnHover: false,
                                    hideProgressBar: false,
                                    closeButton: "button",
                                    icon: true,
                                    rtl: false,
                                });
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
            }
        },

        toUpdate(item) {
            let items = {
                journal: this.journal,
                remarkItem: item,
            };
            // console.log(items);
            this.$emit("toUpdate", items);
        },
        // set number of pages to paginate
        setPages() {
            this.pages = [];
            let numberOfPages = Math.ceil(
                parseInt(this.contents.length) / this.perPage
            );
            for (let index = 1; index <= numberOfPages; index++) {
                this.pages.push(index);
            }
        },

        // handles the from and to of pagination table
        paginate(contents) {
            let page = this.page;
            let perPage = this.perPage;
            let from = page * perPage - perPage;
            let to = page * perPage;

            if (to > this.contents.length) {
                this.fromPage = from + 1;
                this.toPage = this.contents.length;
            } else {
                this.fromPage = from + 1;
                this.toPage = to;
            }

            return contents.slice(from, to);
        },
    },

    watch: {
        eventsItem: {
            handler(val) {
                if (val) {
                    // console.log(val)
                    this.eventID = val.id;
                    this.eventDesc = val.eventDesc;
                    this.eventName = val.eventName;
                    this.eventDate = val.eventDate;
                    this.eventStatus = val.eventStatus;

                    this.defEventName = val.eventName;
                    this.defEventDesc = val.eventDesc;
                    this.defEventDate = val.eventDate;
                    this.defEventStatus = val.eventStatus;
                } else {
                    this.eventStatus = 1;
                    this.eventID = null;
                    this.eventName = null;
                    this.eventDesc = null;
                    this.eventDate = null;
                    this.eventID = null;
                }
            },
            immediate: true,
        },
        action: {
            handler(val) {
                if (val) {
                    this.defAction = val;
                }
            },
            immediate: true,
        }
    },
};
</script>

<style>
.customIndent {
    text-indent: 10px;
}

.accordion-caret {
    background-image: linear-gradient(to top right, transparent 50%, #727272 50%);
    width: 0.5rem;
    height: 0.5rem;
    transform: rotate(-45deg);
}

#defaultModal {
    transition: opacity 0.25s ease;
}</style>