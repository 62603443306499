<template>
  <div class="w-auto h-auto pb-12">
    <div
      class="flex justify-end md:jus filter_by w-auto md:w-auto overflow-hidden bg-white mx-3 lg:h-auto md:h-auto md:py-2 py-3 md:px-0 m-auto rounded-md"
    >
      <button
        @click="toggleAddModal('add', '')"
        class="text-white bg-customGreen w-52 md:w-52 xl:w-52 lg-w-52 2xl:w-52 md:mr-5 text-center md:float-right hover:cursor-pointer hover:bgColor hover:text-white group flex items-center px-3 md:px-3 py-2 mr-4 md:mx-4 text-xs font-medium rounded-md"
      >
        <!-- Heroicon name: plus -->
        <svg
          class="fill-current group-hover:text-gray-300 flex-shrink-0 h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 4v16m8-8H4"
          />
        </svg>
        Add new Announcement
      </button>

      <!-- <h4 class="ml-11 font-semibold text-gray-500 text-xs font-poppins"> Filter by </h4> -->
      <!-- <div class="grid w-full flex-wrap md:flex lg:flex px-8 font-poppins"> <div class="grid text-left mx-2 my-2 w-full"> <h4 class="text-gray-500 font-semibold text-xs md:text-xs"> Research Title </h4> <input v-model="searchText" @keyup="handleSearching" type="search" class=" indent flex-auto py-1 md:w-1/3 w-full text-xs font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded md:rounded-md lg:rounded-md transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none " placeholder="Search for title" /> </div> </div> -->
    </div>
    <div v-if="isLoading" class="flex justify-center py-8">
      <div class="spinLoader"></div>
    </div>

    <div
      class="journal-tbl bg-white w-auto m-auto mt-4 pb-4 mx-3 rounded"
      v-if="!isLoading"
    >
      <div class="flex flex-col">
        <div class="overflow-x-auto">
          <div class="inline-block min-w-full">
            <div class="overflow-hidden mx-4">
              <Table
                class="mt-4 md:mt-0 lg:mt-0 xl:mt-0"
                :labels="
                  this.$store.getters.getUserRole == 1
                    ? table_headers
                    : table_headers2
                "
                :data="displayedAnnouncement"
                :idName="'announcementTable'"
                :notifField="'id'"
                :notifID="notifID"
              >
                <template v-slot:createdAt="{ item }">
                  <!-- {{ formatDate(item.createdAt) }} -->
                  {{ timeToday(formatDate(item.createdAt)) }}
                </template>
                <template v-slot:isDisplayed="{ item }">
                  <span
                    class="inline-flex items-center rounded-full uppercase m-0.5 px-4 py-1 text-xs"
                    :class="
                      item.isDisplayed == 1
                        ? 'bg-green-100 text-green-800'
                        : 'bg-red-100 text-red-800'
                    "
                    >{{
                      item.isDisplayed == 1 ? "Displayed" : "Not displayed"
                    }}</span
                  >
                </template>
                <template
                  v-slot:name="{ item }"
                  v-if="this.$store.getters.getUserRole == 1"
                >
                  {{
                    this.$store.getters.getUserRole != item.user.roleID
                      ? item.user.fname + " " + item.user.lname
                      : this.$store.getters.getUserRole == item.user.roleID
                      ? "You"
                      : ""
                  }}
                </template>
                <template v-slot:action="{ item }">
                  <button
                    v-if="this.$store.getters.getUserRole != 1"
                    @click="viewAnnouncement(item)"
                    class="bg-white hover:bg-FFB800 hover:text-white text-FFB800 font-semibold py-1 px-3 text-xs border border-FFB800 rounded shadow"
                  >
                    View
                  </button>
                  &nbsp;
                  <button
                    v-if="this.$store.getters.getUserRole == 1"
                    @click="updateAnnouncement(item)"
                    class="bg-white hover:bg-FFB800 hover:text-white text-FFB800 font-semibold py-1 px-3 text-xs border border-FFB800 rounded shadow"
                  >
                    Update
                  </button>
                </template>
              </Table>
              <div
                class="grid font-poppins w-full sm:flex md:flex lg:flex flex-row justify-end md:w-full sm:w-full mx-auto items-center border-l border-b border-r border-gray-400 rounded-bl-lg rounded-br-lg pt-4 pb-4"
              >
                <div
                  class="w-auto sm:w-3/6 md:w-3/6 lg:w-3/6 xl:w-3/6 gap-x-4 mx-3 flex"
                >
                  <div
                    class="hidden text-xs text-gray-600 md:flex lg:flex xl:flex 2xl:flex w-1/3 md:w-2/5 items-center"
                  >
                    <label for="" class="w-2/3">Rows per page</label>&nbsp;
                    <select
                      class="w-1/3 border-gray-600 border-b"
                      v-model="perPage"
                    >
                      <option
                        :value="item"
                        v-for="item in selectPerPage"
                        :key="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>

                  <div
                    class="hidden md:flex lg:flex xl:flex 2xl:flex w-1/3 md:w-2/5"
                  >
                    <!-- <div class="flex" v-if="results != 0"> -->
                    <span
                      class="text-xs m-auto text-gray-600 dark:text-gray-400"
                    >
                      Showing
                      <span
                        class="font-semibold text-gray-900 dark:text-white"
                        >{{ fromPage }}</span
                      >
                      to
                      <span
                        class="font-semibold text-gray-900 dark:text-white"
                        >{{ toPage }}</span
                      >
                      of
                      <span
                        class="font-semibold text-gray-900 dark:text-white"
                        >{{ results }}</span
                      >
                      Entries
                    </span>
                  </div>

                  <nav class="w-1/3 md:w-1/5">
                    <ul class="flex md:justify-end">
                      <li class="page-item mx-1">
                        <button
                          type="button"
                          class="page-link bg-customGreen text-white rounded-md p-1 text-xs"
                          v-if="page > 1"
                          @click="page--"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>

                        <!--for the disabled button -->
                        <button
                          type="button"
                          class="page-link bg-customGreen rounded-md text-white p-1 text-xs"
                          v-if="page == 1"
                          disabled
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </li>

                      <li class="page-item mx-1">
                        <button
                          type="button"
                          @click="page++"
                          v-if="page < pages.length"
                          class="page-link bg-customGreen rounded-md text-white p-1 text-xs"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>

                        <!--for the disabled button -->
                        <button
                          type="button"
                          disabled
                          v-if="page >= pages.length"
                          class="page-link bg-customGreen rounded-md text-white p-1 text-xs"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import Table from "../components/Table.vue";
import moment from "moment";
export default {
  name: "AnnouncementTable",
  props: ["refreshtable", "notifID"],
  components: { Table },
  data() {
    return {
      journalID: null,
      toggleModal: false,
      contents: [],
      searchText: null,
      page: 1,
      isLoading: false,
      perPage: 10,
      selectPerPage: [10, 15, 20, 30, 50],
      pages: [],
      fromPage: 1,
      toPage: null,
      results: null,
      percentage: null,
      table_headers: [
        // { text: "ID", field: "id" },
        { text: "Title", field: "title" },
        { text: "Date Uploaded", field: "createdAt" },
        { text: "Made by", field: "name" },
        { text: "Status", field: "isDisplayed" },
        { text: "Action", field: "action" },
      ],
      table_headers2: [
        { text: "Title", field: "title" },
        { text: "Date Uploaded", field: "createdAt" },
        { text: "Status", field: "isDisplayed" },
        { text: "Action", field: "action" },
      ],
    };
  },
  created() {
    this.moment = moment;
    this.getMyAnnouncement();
  },
  mounted() {},
  methods: {
    timeToday(d) {
      var today = new Date(d);
      return moment(today).format("ll");
    },
    // initialize data values to default
    init() {},

    // load all users journals uploaded
    async getMyAnnouncement() {
      this.isLoading = true;
      if (this.$store.getters.getUserRole == 1) {
        axios
          .get(process.env.VUE_APP_API_SERVER + "/announcement/forApproval", {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          })
          .then(
            (response) => {
              this.contents = response.data;

              this.results = response.data.length;
              this.isLoading = false;
              // console.log(response.data);
            },
            (error) => {
              console.log(error);
            }
          );
      } else {
        axios
          .get(process.env.VUE_APP_API_SERVER + "/announcement/my", {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          })
          .then(
            (response) => {
              this.contents = response.data;

              this.results = response.data.length;
              this.isLoading = false;
              // console.log(this.contents);
            },
            (error) => {
              console.log(error);
            }
          );
      }
    },

    reloadTable() {
      this.getMyAnnouncement();
      this.setPages();
    },
    // toggle modal to announcement
    viewAnnouncement(item) {
      // console.log(item);
      this.toggleAddModal("update", item);
    },

    toggleAddModal(action, item) {
      this.$emit("toggleAddModal");
      const toEmit = {
        item: item,
        action: action,
      };
      this.$emit("action", toEmit);
    },

    updateAnnouncement(announcement) {
      // console.log(announcement);
      this.$emit("updToDisplayAnnouncement", announcement);
    },

    // format date to display
    formatDate(date) {
      const d = new Date(date);
      var dd = d.getDate();
      if (dd < 10) {
        dd = "0" + dd;
      }
      var mm = d.getMonth() + 1;
      if (mm < 10) {
        mm = "0" + mm;
      }
      var yy = d.getFullYear();
      return mm + "-" + dd + "-" + yy;
    },

    // set number of pages to paginate
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(
        parseInt(this.contents.length) / this.perPage
      );
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    // handles the from and to of pagination table
    paginate(contents) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;

      if (to > this.contents.length) {
        this.fromPage = from + 1;
        this.toPage = this.contents.length;
      } else {
        this.fromPage = from + 1;
        this.toPage = to;
      }

      return contents.slice(from, to);
    },
  },

  computed: {
    displayedAnnouncement() {
      return this.paginate(this.contents);
    },
    computeStatus() {
      return this.init();
    },
    checkProfilePercentage() {
      return this.percentage == 100;
    },
  },
  watch: {
    contents() {
      this.setPages();
    },

    perPage() {
      this.page = 1;
      this.setPages();
    },
    refreshtable: {
      handler(value) {
        if (value) {
          this.init();
          this.getMyAnnouncement();
          this.setPages();
        } else {
          return;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.tbl_header {
  background-color: #105005;
}

.indent {
  text-indent: 0.5rem;
}

/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>