<template>
  <div class="w-auto h-auto pb-12">
    <div
      class="rounded-lg bg-white dark:bg-darkBG w-auto m-auto mt-4 pb-4 mx-3"
    >
      <div
        class="py-2 mx-1 bg-white dark:bg-darkBG rounded-tl-md rounded-tr-md"
      >
        <ul class="flex font-poppins ml-1" @click="toggleSubMenu($event)">
          <li>
            <a
              @click="loadJournalRequests"
              href="#"
              class="active px-4 uppercase text-xs font-semibold text-gray-700 tracking-wide"
              >Journal Requests</a
            >
          </li>
          <li v-if="this.$store.getters.getUserRole != 1">
            <a
              @click="loadJournalRequested"
              href="#"
              class="px-4 uppercase text-xs font-semibold text-gray-700 tracking-wide"
              >Requested</a
            >
          </li>
        </ul>
      </div>
      <div
        class="filter_by w-auto md:flex md:justify-between md:w-auto overflow-hidden bg-white dark:bg-darkBG lg:h-auto md:h-auto md:mx-3 m-auto rounded-md"
      >
        <div class="w-full md:w-1/2 md:grid lg:flex px-5 md:px-2 py-2">
          <input
            @keyup="handleSearching()"
            type="text"
            class="indent font-poppins md:py-2.5 py-2.5 md:w-3/4 w-full text-xs font-normal text-gray-700 bg-gray-100 dark:bg-darkBG dark:focus:bg-darkBG bg-clip-padding border border-solid border-gray-300 rounded md:rounded-md lg:rounded-md transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
            v-model="searchText"
            placeholder="Search for journal requests"
          />
        </div>
        <div
          class="w-full md:w-1/5 py-2 text-center md:flex md:flex-row-reverse px-5 md:px-0 lg:px-0 xl:px-0 2xl:px-0"
        >
          <button
            v-if="isDisableRevokeAll == false && tabActive == 1"
            @click="revokeAllBtn"
            :class="this.contentNull == false ? 'visible' : 'invisible'"
            class="bgColor text-white font-semibold py-1.5 w-full md:mr-2 md:w-full md:py-1 md:px-1 px-3 justify-center text-xs border rounded shadow"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4 inline"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3"
              />
            </svg>
            Revoke All
          </button>
          <button
            @click="approveAllBtn"
            v-if="isDisableApproveAll == false && tabActive == 1"
            :class="this.contentNull == false ? 'visible md:mr-2' : 'invisible'"
            class="bg-yellow-500 text-white font-semibold w-full md:w-full py-1.5 md:py-1 md:px-1 px-3 justify-center text-xs border rounded shadow"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4 inline"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
            Approve All
          </button>

          <!-- <div v-if="isDisableApproveAll == false"> <button @click="approveAllBtn" class=" bg-yellow-500 text-white font-semibold md:float-right w-full py-1.5 md:py-1 md:px-3 px-3 justify-center text-xs border rounded shadow"> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline"> <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" /> </svg> Approve All </button> </div> <div v-if="isDisableRevokeAll == false"> <button @click="revokeAllBtn" class=" bgColor text-white font-semibold md:float-right w-full py-1.5 md:py-1 md:px-3 px-3 justify-center text-xs border rounded shadow"> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline"> <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3" /> </svg> Revoke All </button> </div> -->
        </div>
      </div>
      <div class="md:flex flex-col">
        <div class="overflow-x-auto">
          <div class="inline-block min-w-full">
            <div class="overflow-hidden mx-5">
              <Table
                :idName="'journalRequestTable'"
                :labels="tabActive == 1 ? table_headers : table_headers2"
                :data="displayedEvents"
                :notifField="'id'"
                :notifID="notifID"
              >
                <template v-slot:title="{ item }">
                  {{ item.user_journal.title }}
                </template>
                <template v-slot:isApproved="{ item }">
                  {{ item.isApproved == 0 ? "Not yet approved" : "APPROVED" }}
                </template>
                <template v-slot:date_added="{ item }">
                  {{ stringFormatDate(formatDate(item.date_added)) }}
                </template>
                <template v-slot:user_details="{ item }">
                  {{
                    item.user_details != null
                      ? item.user_details.fname + " " + item.user_details.lname
                      : ""
                  }}
                </template>
                <template v-slot:action="{ item }">
                  <button
                    @click="approve(item)"
                    v-if="item.isApproved == 0 && this.tabActive == 1"
                    title="Approve this journal request"
                    class="bg-white hover:bg-yellow-500 hover:text-white text-yellow-500 font-semibold px-1 py-1 text-xs border border-yellow-500 rounded shadow"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4 inline"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>
                    Approve
                  </button>
                  <button
                    @click="revoke(item)"
                    v-if="item.isApproved == 1 && this.tabActive == 1"
                    title="Revoke approved"
                    class="bg-white hover:bg-customGreen hover:text-white text-customGreen font-semibold px-1 py-1 text-xs border border-customGreen rounded shadow"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4 inline"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3"
                      />
                    </svg>
                    Revoke
                  </button>
                </template>
              </Table>
              <!-- <Table v-if="$store.getters.getUserRole != 1" :labels="table_headers2" :data="displayedEvents"> <template v-if="$store.getters.getUserRole == 1" v-slot:action="{ item }"> <button @click="viewEvents(item)" title="Update" class=" bg-white hover:bg-yellow-500 hover:text-white text-yellow-500 font-semibold px-3 py-1 text-xs border border-yellow-500 rounded shadow"> View Event Details</button> </template> </Table> -->
              <div
                class="grid font-poppins w-full sm:flex md:flex lg:flex flex-row justify-end md:w-full sm:w-full mx-auto items-center border-l border-b border-r border-gray-400 rounded-bl-lg rounded-br-lg pt-4 pb-4"
              >
                <div
                  class="w-auto sm:w-3/6 md:w-3/6 lg:w-3/6 xl:w-3/6 gap-x-4 mx-3 flex"
                >
                  <div
                    class="hidden text-xs text-gray-600 md:flex w-1/3 md:w-2/5 items-center"
                  >
                    <label for="" class="w-2/3">Rows per page</label>&nbsp;
                    <select
                      class="w-1/3 border-gray-600 border-b dark:bg-darkBG pb-1"
                      v-model="perPage"
                    >
                      <option
                        :value="item"
                        v-for="item in selectPerPage"
                        :key="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div class="hidden md:flex w-1/3 md:w-2/5">
                    <!-- <div class="flex" v-if="results != 0"> -->
                    <span
                      class="text-xs m-auto text-gray-600 dark:text-gray-400"
                    >
                      Showing
                      <span
                        class="font-semibold text-gray-900 dark:text-white"
                        >{{ fromPage }}</span
                      >
                      to
                      <span class="font-semibold text-gray-900 dark:text-white">
                        {{ toPage }}</span
                      >
                      of
                      <span
                        class="font-semibold text-gray-900 dark:text-white"
                        >{{ results }}</span
                      >
                      Entries
                    </span>
                  </div>
                  <nav class="w-1/3 md:w-1/5">
                    <ul class="flex md:justify-end">
                      <li class="page-item mx-1">
                        <button
                          type="button"
                          class="page-link bg-customGreen text-white rounded-md p-1 text-xs"
                          v-if="page > 1"
                          @click="page--"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                        <!--for the disabled button -->
                        <button
                          type="button"
                          class="page-link bg-customGreen rounded-md text-white p-1 text-xs"
                          v-if="page == 1"
                          disabled
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </li>
                      <li class="page-item mx-1">
                        <button
                          type="button"
                          @click="page++"
                          v-if="page < pages.length"
                          class="page-link bg-customGreen rounded-md text-white p-1 text-xs"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                        <!--for the disabled button -->
                        <button
                          type="button"
                          disabled
                          v-if="page >= pages.length"
                          class="page-link bg-customGreen rounded-md text-white p-1 text-xs"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <RequestConfirmationModal
      v-on:closeModal="closeModal"
      v-on:confirmApproved="confirmApproved"
      v-on:confirmRevoked="confirmRevoked"
      v-on:confirmApproveAll="confirmApproveAll"
      v-on:confirmRevokeAll="confirmRevokeAll"
      :actionDesc="actionDesc"
      :requester="requester"
      v-if="isConfirmModal"
    />
  </div>
</template>

<script>
import axios from "axios";
import Table from "../components/Table.vue";
import RequestConfirmationModal from "../modals/RequestConfirmationModal.vue";
import { useToast } from "vue-toastification";

export default {
  name: "JournalRequestsTable",
  props: ["isUpdate", "notifID"],
  components: { Table, RequestConfirmationModal },

  data() {
    return {
      contents: [],
      contents2: [],
      tabActive: 1,
      page: 1,
      isLoading: false,
      modalAction: "Add",
      perPage: 10,
      contentNull: true,
      selectPerPage: [10, 15, 20, 30, 50],
      pages: [],
      fromPage: 1,
      toPage: null,
      results: null,
      searchText: null,
      isConfirmModal: false,
      isShowModal: false,
      itemToRemove: null,
      eventsToRemove: "",
      eventTargetData: [],
      eventTargetResult: null,
      isApproveReq: false,
      actionDesc: 1,
      confirmApproveData: null,
      confirmRevokeData: null,
      allData: null,
      requester: null,
      isDisableRevokeAll: null,
      isDisableApproveAll: null,
      table_headers: [
        { text: "Journal Name", field: "title" },
        { text: "Status", field: "isApproved" },
        { text: "Date Requested", field: "date_added" },
        { text: "Requested By", field: "user_details" },
        { text: "Action", field: "action" },
      ],

      table_headers2: [
        { text: "Journal Name", field: "title" },
        { text: "Status", field: "isApproved" },
        { text: "Date Requested", field: "date_added" },
        { text: "Requested To", field: "user_details" },
      ],
    };
  },

  methods: {
    toggleSubMenu(e) {
      var elems = document.querySelector(".active");
      if (elems !== null) {
        elems.classList.remove("active");
      }
      e.target.classList.add("active");
    },

    formatNumber(num) {
      if (num <= 9) {
        return "0" + num;
      } else {
        return num;
      }
    },

    stringFormatDate(date) {
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(date).toLocaleDateString("en", options);
    },

    formatDate(date) {
      const d = new Date(date);
      this.timeAgo = d;

      const mn = d.getMonth() + 1;
      var day = d.getDate();
      const yr = d.getFullYear();
      const hh = d.getHours();
      const mm = d.getMinutes();
      const ss = d.getSeconds();

      return (
        this.formatNumber(mn) +
        "-" +
        this.formatNumber(day) +
        "-" +
        yr +
        " " +
        this.formatNumber(hh) +
        ":" +
        this.formatNumber(mm) +
        ":" +
        this.formatNumber(ss)
      );
    },
    init() {
      this.contents = [];
      this.page = 1;
      this.perPage = 10;
      this.pages = [];
      this.fromPage = 1;
      this.toPage = null;
      this.results = null;
    },

    openModal(action) {
      this.modalAction = action;
      this.$emit("toggleEventsModal", action);
    },

    closeModal() {
      this.isConfirmModal = false;
    },

    confirmApproveAll() {
      const toast = useToast();
      // console.log(this.allData);
      axios
        .post(
          process.env.VUE_APP_API_SERVER + "/journal-permission/approve_all",
          this.allData,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            if (response.data.status == 201 || response.data.status == 200) {
              this.loadJournalRequests();
              this.isConfirmModal = false;
              toast.success(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            } else {
              this.loadJournalRequests();
              this.isConfirmModal = false;
              toast.warning(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },

    confirmRevokeAll() {
      const toast = useToast();
      axios
        .post(
          process.env.VUE_APP_API_SERVER + "/journal-permission/revoke_all",
          this.allData,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            this.loadJournalRequests();
            this.isConfirmModal = false;
            toast.success(response.data.msg, {
              position: "top-right",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: false,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            this.isConfirmModal = false;
          },
          (error) => {
            console.log(error);
          }
        );
    },

    confirmApproved() {
      const toast = useToast();
      axios
        .post(
          process.env.VUE_APP_API_SERVER +
            "/journal-permission/approve_this_request",
          this.confirmApproveData,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            if (response.data.status == 201 || response.data.status == 200) {
              this.loadJournalRequests();
              this.isConfirmModal = false;
              toast.success(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            } else {
              this.loadJournalRequests();
              this.isConfirmModal = false;
              toast.warning(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },

    confirmRevoked() {
      const toast = useToast();
      axios
        .post(
          process.env.VUE_APP_API_SERVER +
            "/journal-permission/revoke_this_request",
          this.confirmRevokeData,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            if (response.data.status == 201 || response.data.status == 200) {
              this.loadJournalRequests();
              this.isConfirmModal = false;
              toast.success(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            } else {
              this.loadJournalRequests();
              this.isConfirmModal = false;
              toast.warning(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },

    approve(item) {
      if (Array.isArray(item)) {
        item.push({ user_now: this.$store.getters.getUserdetailID });
      } else {
        item.user_now = this.$store.getters.getUserdetailID;
      }
      this.confirmApproveData = item;
      this.isConfirmModal = true;
      this.isApproveReq = true;
      this.actionDesc = 1;
      this.requester = item.user_details.fname + " " + item.user_details.lname;
    },
    revoke(item) {
      if (Array.isArray(item)) {
        item.push({ user_now: this.$store.getters.getUserdetailID });
      } else {
        item.user_now = this.$store.getters.getUserdetailID;
      }
      this.confirmRevokeData = item;
      this.isConfirmModal = true;
      this.isApproveReq = true;
      this.actionDesc = 0;
      this.requester = item.user_details.fname + " " + item.user_details.lname;
    },

    approveAllBtn() {
      if (Array.isArray(this.allData)) {
        this.allData.push({ user_now: this.$store.getters.getUserdetailID });
      } else {
        this.allData.user_now = this.$store.getters.getUserdetailID;
      }
      this.isConfirmModal = true;
      this.isApproveReq = true;
      this.actionDesc = 2;
    },

    revokeAllBtn() {
      if (Array.isArray(this.allData)) {
        this.allData.push({ user_now: this.$store.getters.getUserdetailID });
      } else {
        this.allData.user_now = this.$store.getters.getUserdetailID;
      }
      this.isConfirmModal = true;
      this.isApproveReq = true;
      this.actionDesc = 3;
    },
    // load all user accounts to verify
    loadJournalRequests() {
      this.tabActive = 1;
      axios
        .get(
          process.env.VUE_APP_API_SERVER +
            "/journal-permission/getAllJournalRequests/" +
            this.$store.getters.getUserdetailID,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            if (response.data.getRequests.length != 0) {
              this.contentNull = false;
              // console.log(response.data.getRequests);
              this.contents = response.data.getRequests;
              this.allData = response.data.getRequests;
              this.results = response.data.getRequests.length;
              var cnt1 = null;
              var cnt2 = null;
              for (var i = 0; i < response.data.getRequests.length; i++) {
                // revoke all btn is disabled, approve all btn is not disabled
                if (response.data.getRequests[i].isApproved == 0) {
                  cnt1 += 1;
                }
                // approve all btn is disabled, revoke all btn is not disabled
                else if (response.data.getRequests[i].isApproved == 1) {
                  cnt2 += 1;
                }
              }
              // if all item is not approve = 0
              if (cnt1 == this.results) {
                this.isDisableApproveAll = false;
                this.isDisableRevokeAll = true;
              }
              // if all item is not approve = 1
              else if (cnt2 == this.results) {
                this.isDisableApproveAll = true;
                this.isDisableRevokeAll = false;
              } else if (cnt1 > 1 || cnt2 > 1) {
                this.isDisableApproveAll = false;
                this.isDisableRevokeAll = false;
              }
            } else {
              this.contentNull = true;
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },

    loadJournalRequested() {
      this.tabActive = 2;
      axios
        .get(
          process.env.VUE_APP_API_SERVER +
            "/journal-permission/getAllJournalRequested/" +
            this.$store.getters.getUserdetailID,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            if (response.data.getRequested.length != 0) {
              //   console.log(response.data.getRequested);
              this.contents2 = response.data.getRequested;
              this.allData = response.data.getRequested;
              this.results = response.data.getRequested.length;
              var cnt1 = null;
              var cnt2 = null;
              for (var i = 0; i < response.data.getRequested.length; i++) {
                // revoke all btn is disabled, approve all btn is not disabled
                if (response.data.getRequested[i].isApproved == 0) {
                  cnt1 += 1;
                }
                // approve all btn is disabled, revoke all btn is not disabled
                else if (response.data.getRequested[i].isApproved == 1) {
                  cnt2 += 1;
                }
              }
              // if all item is not approve = 0
              if (cnt1 == this.results) {
                this.isDisableApproveAll = false;
                this.isDisableRevokeAll = true;
              }
              // if all item is not approve = 1
              else if (cnt2 == this.results) {
                this.isDisableApproveAll = true;
                this.isDisableRevokeAll = false;
              } else if (cnt1 > 1 || cnt2 > 1) {
                this.isDisableApproveAll = false;
                this.isDisableRevokeAll = false;
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },

    handleSearching() {
      if (this.searchText == "" || !this.searchText) {
        if (this.tabActive == 1) {
          this.loadJournalRequests();
        } else {
          this.loadJournalRequested();
        }
        this.setPages();
      } else {
        if (this.tabActive == 1) {
          this.contents = this.contents.filter(
            (data) =>
              data.user_journal_id.title
                .toLowerCase()
                .indexOf(this.searchText.toLowerCase()) !== -1 ||
              data.user_details.fname
                .toLowerCase()
                .indexOf(this.searchText.toLowerCase()) !== -1 ||
              data.user_details.lname
                .toLowerCase()
                .indexOf(this.searchText.toLowerCase()) !== -1 ||
              data.date_added
                .toLowerCase()
                .indexOf(this.searchText.toLowerCase()) !== -1 ||
              data.remarks
                .toLowerCase()
                .indexOf(this.searchText.toLowerCase()) !== -1
          );
          this.results = this.contents.length;
          this.setPages();
        } else {
          this.contents2 = this.contents2.filter(
            (data) =>
              data.user_journal_id.title
                .toLowerCase()
                .indexOf(this.searchText.toLowerCase()) !== -1 ||
              data.user_details.fname
                .toLowerCase()
                .indexOf(this.searchText.toLowerCase()) !== -1 ||
              data.user_details.lname
                .toLowerCase()
                .indexOf(this.searchText.toLowerCase()) !== -1 ||
              data.date_added
                .toLowerCase()
                .indexOf(this.searchText.toLowerCase()) !== -1 ||
              data.remarks
                .toLowerCase()
                .indexOf(this.searchText.toLowerCase()) !== -1
          );
          this.results = this.contents2.length;
          this.setPages();
        }
      }
    },

    // set number of pages to paginate
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(
        parseInt(this.contents.length) / this.perPage
      );
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    // handles the from and to of pagination table
    paginate(contents) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;

      if (to > this.contents.length) {
        this.fromPage = from + 1;
        this.toPage = this.contents.length;
      } else {
        this.fromPage = from + 1;
        this.toPage = to;
      }

      return contents.slice(from, to);
    },
  },

  computed: {
    displayedEvents() {
      if (this.tabActive == 1) {
        return this.paginate(this.contents);
      } else {
        return this.paginate(this.contents2);
      }
    },
  },
  watch: {
    contents() {
      this.setPages();
    },

    perPage() {
      this.page = 1;
      this.setPages();
    },
    isUpdate: {
      handler(val) {
        if (val) {
          this.init();
          this.loadJournalRequests();
          this.setPages();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.loadJournalRequests();
    this.setPages();
  },
};
</script>

<style scoped>
.tbl_header {
  background-color: #105005;
}

.indent {
  text-indent: 0.5rem;
}

/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}

.active {
  text-decoration: underline;
  color: #43703f;
  text-underline-offset: 7px;
}
</style>