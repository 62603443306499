<template>
  <div class="w-auto h-auto rounded-md">
    <div class="bg-white rounded">
      <div
        class="-mb-2"
        v-if="
          !isLoading &&
          displayedJournals.filter((w) =>
            w.title.toLowerCase().includes(this.searchTitle.toLowerCase())
          ).length == 0
        "
      >
        <p class="text-sm font-medium text-gray-500 italic">
          No results in articles related to "{{ this.searchTitle }}".
        </p>
      </div>
      <div
        class="py-1 -mt-2 flex justify-between text-gray-400 text-xs"
        v-if="
          displayedJournals.filter((w) =>
            w.title.toLowerCase().includes(this.searchTitle.toLowerCase())
          ).length > 0
        "
      >
        <div class="">
          About
          {{
            displayedJournals.filter((w) =>
              w.title.toLowerCase().includes(this.searchTitle.toLowerCase())
            ).length
          }}
          results ({{ duration }} sec)
        </div>
        <div class="">
          {{ date_now }}
        </div>
      </div>
      <div v-if="isLoading" class="flex justify-center py-8">
        <div class="spinLoader"></div>
      </div>
      <div class="mb-2" v-if="!isLoading">
        <div class="grid grid-rows-1">
          <!-- THIS VIEW IS FOR AVAILABLE ARTICLES -->
          <div v-if="!seeSaved" class="h-full">
            <div
              class="row-span-1 mt-2 mb-10 md:my-5 leading-6 md:leading-8 h-auto"
              v-for="(journalData, index) in displayedJournals.filter((w) =>
                w.title.toLowerCase().includes(this.searchTitle.toLowerCase())
              )"
              :key="journalData"
            >
              <a
                href="javascript:void(0)"
                class="underline text-blue-700 text-md md:text-lg font-semibold"
                @click="viewJournal(journalData)"
                >{{ journalData.title }}</a
              >
              <div class="w-full text-green-600 text-sm"></div>
              <div class="text-sm text">
                <p
                  class="text-justify md:text-left text-gray-500 captionText"
                  v-if="journalData.isPublished == 1"
                  :id="'abstract_' + index"
                >
                  {{ journalData.abstract }}
                </p>
                <p
                  class="text-justify captionText md:text-left text-gray-500"
                  v-if="journalData.isPublished == 0"
                  :id="'abstract_' + index"
                >
                  {{ journalData.abstract }}
                </p>
                <!-- <p
                  style="font-size: 11px"
                  class="italic text-center text-xs md:text-left captionText text-justify md:text-left bg-gray-100 text-gray-500 p-2 my-1.5 rounded"
                  v-if="journalData.isPublished == 0"
                  :id="'abstract_' + index"
                >
                  {{ journalData.abstract }}
                  Unpublished articles' abstract is hidden due to privacy policy
                </p> -->
                <span
                  class="text-sm text-105005 font-semibold hover:underline cursor-pointer"
                  :id="'seeMoreText' + index"
                  @click="seeMoreFunc(index)"
                  >Read more ...</span
                >
                <span
                  class="text-sm text-105005 font-semibold hover:underline cursor-pointer hidden"
                  :id="'seeLessText' + index"
                  @click="seeLessFunc(index)"
                  >Read less ...</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="mx-5 mb-2"
        v-if="
          !isLoading &&
          displayedJournals.filter((w) =>
            w.title.toLowerCase().includes(this.searchTitle.toLowerCase())
          ).length > 0
        "
      >
        <div
          v-if="displayedJournals"
          class="grid w-full sm:flex md:flex lg:flex flex-row justify-center md:w-full sm:w-full mx-auto items-center py-3"
        >
          <div class="w-full flex justify-center">
            <nav class="content-center">
              <ul class="flex md:justify-end">
                <li class="page-item mx-1">
                  <button
                    type="button"
                    @click="page = 1"
                    v-if="page > 1"
                    class="page-link p-2 text-md mx-0.5 text-gray-500 border rounded-md border-gray-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"
                      />
                    </svg>
                  </button>

                  <button
                    type="button"
                    disabled
                    v-if="page == 1"
                    class="page-link p-2 text-md mx-0.5 text-gray-300 border rounded-md border-gray-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"
                      />
                    </svg>
                  </button>
                </li>
                <li class="page-item mx-1">
                  <button
                    type="button"
                    class="page-link border border-gray-300 text-gray-600 p-2 text-xs rounded-md"
                    v-if="page > 1"
                    @click="page--"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>

                  <!--for the disabled button -->
                  <button
                    type="button"
                    class="page-link border border-gray-300 text-gray-300 p-2 text-xs rounded-md"
                    v-if="page == 1"
                    disabled
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </li>

                <li v-for="(item, index) in items" :key="index">
                  <button
                    type="button"
                    v-if="isNaN(Number(item))"
                    class="page-link p-2 text-md mx-0.5 text-gray-500 border rounded-md border-gray-300"
                  >
                    <p class="h-5 w-5">{{ item }}</p>
                  </button>
                  <!-- <span v-if="isNaN(Number(item))" class="pagination-more">{{ item }}</span> -->
                  <button
                    v-else-if="item == page"
                    type="button"
                    class="page-link p-2 text-md mx-0.5 border-2 border-105005 rounded-md text-105005"
                  >
                    <p class="h-5 w-5 font-semibold">{{ item }}</p>
                  </button>
                  <button
                    v-else
                    type="button"
                    @click="page = item"
                    class="page-link p-2 text-md mx-0.5 text-gray-500 border rounded-md border-gray-300"
                  >
                    <p class="h-5 w-5">{{ item }}</p>
                  </button>

                  <!-- <button v-else type="button"
                                        @click="$emit('input', item)">{{ item }}</button> -->
                </li>
                <!-- <li> -->

                <li class="page-item mx-1">
                  <button
                    type="button"
                    @click="page++"
                    v-if="page < pages.length"
                    class="page-link border border-gray-300 text-gray-600 p-2 text-xs rounded-md"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>

                  <!--for the disabled button -->
                  <button
                    type="button"
                    disabled
                    v-if="page >= pages.length"
                    class="page-link border border-gray-300 text-gray-300 p-2 text-xs rounded-md"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </li>
                <li class="page-item mx-1">
                  <button
                    type="button"
                    @click="page = pages.length"
                    v-if="page < pages.length"
                    class="page-link p-2 text-md mx-0.5 text-gray-500 border rounded-md border-gray-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </button>

                  <button
                    type="button"
                    disabled
                    v-if="page >= pages.length"
                    class="page-link p-2 text-md mx-0.5 text-gray-300 border rounded-md border-gray-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PleaseLoginModal from "../modals/PleaseLoginModal.vue";
import axios from "axios";
import moment from "moment";
import { useToast } from "vue-toastification";
import CitationModal from "../modals/CitationModal.vue";
export default {
  props: ["searchTitle"],
  name: "JournalTable",
  components: { PleaseLoginModal, CitationModal },
  data() {
    return {
      searchTitleData: this.searchTitle ? this.searchTitle : null,
      isLoading: false,
      contents: [],
      page: 1,
      perPage: 10,
      selectPerPage: [10, 15, 20, 30, 50],
      pages: [],
      fromPage: 1,
      duration: null,
      toPage: null,
      results: null,
      duration: null,
      date_now: null,
      categories: [],
      categoryDesc: [],
      isPleaseLoginModal: false,
      isCitationModal: false,
      citationData: null,
      citedCount: null,
      seeSaved: false,
      filterDiv: false,
      showFilterIsPub: false,
      showFilterDate1: true,
      showFilterDate2: false,
      showFilter: false,
      showFilterString: false,
      showTableForFilterVal: true,
      showDropdownPublished: false,
      showDropdownPublishedRange: false,
      showCategoriesList: false,
      showTypeOfArticle: false,
      tableForFilterVal: "equal_date",
      tableForFilter: "file_createdAt",
      filterOption: [
        { option: "Equals", val: "equal_date" },
        { option: "Greater than", val: "greater_than_date" },
        { option: "Not equal", val: "not_equal_date" },
        { option: "In range", val: "in_range_date" },
      ],
      publishedYearList: [
        1955, 1956, 1957, 1958, 1959, 1960, 1961, 1962, 1963, 1964, 1965, 1966,
        1967, 1968, 1969, 1970, 1971, 1972, 1973, 1974, 1975, 1976, 1977, 1978,
        1979, 1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990,
        1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002,
        2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014,
        2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025,
      ],
      publishedMonthList: [
        { month: "January", value: 1 },
        { month: "February", value: 2 },
        { month: "March", value: 3 },
        { month: "April", value: 4 },
        { month: "May", value: 5 },
        { month: "June", value: 6 },
        { month: "July", value: 7 },
        { month: "August", value: 8 },
        { month: "September", value: 9 },
        { month: "October", value: 10 },
        { month: "November", value: 11 },
        { month: "December", value: 12 },
      ],
      categoriesList: [],
      filterDate1: null,
      filterDate2: null,
      filterIsPublished: [],
      filterText: null,
      filterFname: null,
      filterMname: null,
      filterLname: null,
      filterEmail: null,
      filterOrg: null,
      filterPublishedMonth: 1,
      filterPublishedYear: 1955,
      filterPublishedYear1: 1955,
      filterPublishedYear2: 2023,
      filterPublishedMonth1: 1,
      filterPublishedMonth2: 1,
      filterCategory: 1,
      filterTypeOfArticle: 1,
      table_filter: [
        // { tablename: 'Research Title', field: 'file_title' },
        { tablename: "Date Uploaded", field: "file_createdAt" },
        { tablename: "Corresponding Author", field: "file_corFname" },
        { tablename: "Main Author", field: "file_mainauthorfname" },
        { tablename: "Co-Author", field: "file_coauthor" },
        { tablename: "Date Published", field: "file_publishedDateMonth" },
        { tablename: "Categories", field: "file_categories" },
        { tablename: "Author's keywords", field: "file_authorsKeywords" },
        { tablename: "Type Of Article", field: "file_typeOfArticle" },
        { tablename: "Published", field: "file_isPublished" },
      ],
    };
  },
  mounted() {
    this.timeToday();
  },

  methods: {
    seeLessFunc(i) {
      const c = document.querySelector("#abstract_" + i);
      c.classList.add("captionText");
      const sl = document.querySelector("#seeLessText" + i);
      sl.classList.add("hidden");
      const s = document.querySelector("#seeMoreText" + i);
      s.classList.remove("hidden");
    },
    seeMoreFunc(i) {
      const c = document.querySelector("#abstract_" + i);
      c.classList.remove("captionText");
      const s = document.querySelector("#seeMoreText" + i);
      s.classList.add("hidden");
      const sl = document.querySelector("#seeLessText" + i);
      sl.classList.remove("hidden");
    },
    goFilter() {
      // const toast = useToast();
      // toast.error("Please complete your profile to continue.", {
      //   position: "top-center",
      //   timeout: 3000,
      //   closeOnClick: true,
      //   pauseOnFocusLoss: false,
      //   pauseOnHover: false,
      //   draggable: true,
      //   draggablePercent: 0.6,
      //   showCloseButtonOnHover: false,
      //   hideProgressBar: false,
      //   closeButton: "button",
      //   icon: true,
      //   rtl: false,
      // });
      // if (this.filterText) {

      // }
      const fd = {
        tableForFilter: this.tableForFilter,
        tableForFilterVal: this.tableForFilterVal,
        filterText: this.filterText,
        filterDate1: this.filterDate1,
        filterDate2: this.filterDate2,
        filterFname: this.filterFname,
        filterMname: this.filterMname,
        filterLname: this.filterLname,
        filterEmail: this.filterEmail,
        filterOrg: this.filterOrg,
        filterPublishedMonth: this.filterPublishedMonth,
        filterPublishedYear: this.filterPublishedYear,
        filterPublishedMonth1: this.filterPublishedMonth1,
        filterPublishedMonth2: this.filterPublishedMonth2,
        filterPublishedYear1: this.filterPublishedYear1,
        filterPublishedYear2: this.filterPublishedYear2,
        filterIsPublished: this.filterIsPublished,
        filterCategory: this.filterCategory,
        filterTypeOfArticle: this.filterTypeOfArticle,
      };
      axios
        .post(
          process.env.VUE_APP_API_SERVER + "/fileupload/filteredArticles",
          fd,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            this.contents = response.data;
            // console.log(response.data);
            this.results = response.data.length;
            this.isLoading = true;
            setTimeout(() => (this.isLoading = false), 1000);
          },
          (error) => {
            console.log(error);
          }
        );
    },
    changeField2(event) {
      var val = event.target.value;
      // var template = '';
      if (val == "in_range_date") {
        this.showFilterDate1 = false;
        this.showFilterDate2 = true;
      }

      if (val == "not_equal_date") {
        this.showFilterDate1 = true;
        this.showFilterDate2 = false;
      }

      if (val == "equal_date") {
        this.showFilterDate1 = true;
        this.showFilterDate2 = false;
      }

      if (val == "greater_than_date") {
        this.showFilterDate1 = true;
        this.showFilterDate2 = false;
      }

      if (val == "starts_with_string") {
        this.showFilter = false;
        this.showFilterDate2 = false;
        this.showFilterIsPub = false;
        this.showFilterString = true;
      }

      if (val == "ends_with_string") {
        this.showFilter = false;
        this.showFilterDate2 = false;
        this.showFilterIsPub = false;
        this.showFilterString = true;
      }

      if (val == "contain_string") {
        this.showFilter = false;
        this.showFilterDate2 = false;
        this.showFilterIsPub = false;
        this.showFilterString = true;
      }

      if (val == "not_contain_string") {
        this.showFilter = false;
        this.showFilterDate2 = false;
        this.showFilterIsPub = false;
        this.showFilterString = true;
      }

      if (val == "equal_string") {
        this.showFilter = false;
        this.showFilterDate2 = false;
        this.showFilterIsPub = false;
        this.showFilterString = true;
      }

      if (val == "not_equal_string") {
        this.showFilter = false;
        this.showFilterDate2 = false;
        this.showFilterIsPub = false;
        this.showFilterString = true;
      }

      if (
        val == "equal_date_pub" ||
        val == "greater_than_date_pub" ||
        val == "not_equal_date_pub"
      ) {
        this.showDropdownPublished = true;
        this.showDropdownPublishedRange = false;
      }

      if (val == "in_range_date_pub") {
        this.showDropdownPublishedRange = true;
        this.showDropdownPublished = false;
      }

      if (
        val == "contain_string_ak" ||
        val == "not_contain_string_ak" ||
        val == "equal_string_ak" ||
        val == "not_equal_string_ak" ||
        val == "starts_with_string_ak" ||
        val == "ends_with_string_ak"
      ) {
        this.showFilter = true;
        this.showFilterDate2 = false;
        this.showFilterIsPub = false;
        this.showFilterString = false;
      }
    },
    changeField(event) {
      var val = event.target.value;
      if (
        val == "file_corFname" ||
        val == "file_mainauthorfname" ||
        val == "file_coauthor"
      ) {
        var options = [
          { option: "Contains", val: "contain_string" },
          { option: "Not contains", val: "not_contain_string" },
          { option: "Equal", val: "equal_string" },
          { option: "Not equal", val: "not_equal_string" },
          { option: "Starts with", val: "starts_with_string" },
          { option: "Ends with", val: "ends_with_string" },
        ];
        this.filterOption = options;
        this.tableForFilterVal = "contain_string";
        this.showFilter = false;
        this.showFilterIsPub = false;
        this.showFilterDate1 = false;
        this.showFilterDate2 = false;
        this.showFilterString = true;
        this.showTableForFilterVal = true;
        this.showDropdownPublished = false;
        this.showDropdownPublishedRange = false;
        this.showCategoriesList = false;
        this.showTypeOfArticle = false;
      } else if (val == "file_authorsKeywords") {
        var options = [
          { option: "Contains", val: "contain_string_ak" },
          { option: "Not contains", val: "not_contain_string_ak" },
          { option: "Equal", val: "equal_string_ak" },
          { option: "Not equal", val: "not_equal_string_ak" },
          { option: "Starts with", val: "starts_with_string_ak" },
          { option: "Ends with", val: "ends_with_string_ak" },
        ];
        this.filterOption = options;
        this.tableForFilterVal = "contain_string_ak";
        this.showFilter = true;
        this.showFilterIsPub = false;
        this.showFilterDate1 = false;
        this.showFilterDate2 = false;
        this.showFilterString = false;
        this.showTableForFilterVal = true;
        this.showDropdownPublished = false;
        this.showDropdownPublishedRange = false;
        this.showCategoriesList = false;
        this.showTypeOfArticle = false;
      } else if (val == "file_createdAt") {
        var options = [
          { option: "Equals", val: "equal_date" },
          { option: "Greater than", val: "greater_than_date" },
          { option: "Not equal", val: "not_equal_date" },
          { option: "In range", val: "in_range_date" },
        ];
        this.filterOption = options;
        this.tableForFilterVal = "equal_date";
        this.showFilter = false;
        this.showFilterIsPub = false;
        this.showFilterDate1 = true;
        this.showFilterDate2 = false;
        this.showFilterString = false;
        this.showTableForFilterVal = true;
        this.showDropdownPublished = false;
        this.showDropdownPublishedRange = false;
        this.showCategoriesList = false;
        this.showTypeOfArticle = false;
      } else if (val == "file_publishedDateMonth") {
        var options = [
          { option: "Equals", val: "equal_date_pub" },
          { option: "Greater than", val: "greater_than_date_pub" },
          { option: "Not equal", val: "not_equal_date_pub" },
          { option: "In range", val: "in_range_date_pub" },
        ];
        this.filterOption = options;
        this.tableForFilterVal = "equal_date_pub";
        this.showFilter = false;
        this.showFilterIsPub = false;
        this.showFilterDate1 = false;
        this.showFilterDate2 = false;
        this.showFilterString = false;
        this.showTableForFilterVal = true;
        this.showDropdownPublished = true;
        this.showDropdownPublishedRange = false;
        this.showCategoriesList = false;
        this.showTypeOfArticle = false;
      } else if (val == "file_categories") {
        axios
          .get(process.env.VUE_APP_API_SERVER + "/journalcategory/", {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          })
          .then(
            (response) => {
              // console.log("categories ", response.data);
              this.categoriesList = response.data;
            },
            (error) => {
              console.log(error);
            }
          );
        this.filterOption = options;
        this.tableForFilterVal = "equal_date_pub";
        this.showFilter = false;
        this.showFilterIsPub = false;
        this.showFilterDate1 = false;
        this.showFilterDate2 = false;
        this.showFilterString = false;
        this.showTableForFilterVal = false;
        this.showDropdownPublished = false;
        this.showDropdownPublishedRange = false;
        this.showCategoriesList = true;
        this.showTypeOfArticle = false;
      } else if (val == "file_typeOfArticle") {
        this.showFilter = false;
        this.showFilterIsPub = false;
        this.showFilterDate1 = false;
        this.showFilterDate2 = false;
        this.showFilterString = false;
        this.showTableForFilterVal = false;
        this.showDropdownPublished = false;
        this.showDropdownPublishedRange = false;
        this.showCategoriesList = false;
        this.showTypeOfArticle = true;
      } else if (val == "file_isPublished") {
        this.showFilter = false;
        this.showFilterIsPub = true;
        this.showFilterDate1 = false;
        this.showFilterDate2 = false;
        this.showFilterString = false;
        this.showTableForFilterVal = false;
        this.showDropdownPublished = false;
        this.showDropdownPublishedRange = false;
        this.showCategoriesList = false;
        this.showTypeOfArticle = false;
      }
    },
    toggleThis() {
      this.seeSaved = false;
      this.autoDisplayJournals();
    },
    toggleThis2() {
      this.seeSaved = !this.seeSaved;
      this.autoDisplayJournalSaved();
    },
    refreshData() {
      this.autoDisplayJournals();
    },
    closeSave() {
      this.isPleaseLoginModal = false;
    },
    closeCite() {
      this.isCitationModal = false;
    },
    redirectTo(loc) {
      this.$router.push("/signin/" + loc);
    },

    init() {
      this.contents = [];
      this.page = 1;
      this.perPage = 10;
      this.pages = [];
      this.fromPage = 1;
      this.toPage = null;
      this.results = null;
    },

    formatNumber(num) {
      if (num <= 9) {
        return "0" + num;
      } else {
        return num;
      }
    },

    stringFormatDate(date) {
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(date).toLocaleDateString("en", options);
    },

    toggleSubMenu(e) {
      var elems = document.querySelector("#tab-active");
      // console.log(elems)
      // if (elems !== null) {
      //     elems.classList.remove("activeTab");
      // }
      // e.target.classList.add("activeTab");
    },

    formatDate(date) {
      const d = new Date(date);
      this.timeAgo = d;

      const mn = d.getMonth() + 1;
      var day = d.getDate();
      const yr = d.getFullYear();
      const hh = d.getHours();
      const mm = d.getMinutes();
      const ss = d.getSeconds();

      return (
        this.formatNumber(mn) +
        "-" +
        this.formatNumber(day) +
        "-" +
        yr +
        " " +
        this.formatNumber(hh) +
        ":" +
        this.formatNumber(mm) +
        ":" +
        this.formatNumber(ss)
      );
    },

    timeToday() {
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var dateTime = date + " " + time;

      this.date_now = moment(today).format("lll");
    },

    save(d) {
      const toast = useToast();
      axios
        .get(
          process.env.VUE_APP_API_SERVER +
            "/journalcategory/addNewSaved/" +
            d.id +
            "/" +
            this.$store.getters.getUserdetailID,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            if (response.data.status == 200 || response.data.status == 201) {
              toast.success(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
              this.autoDisplayJournals();
              // console.log(response);
            } else {
              toast.warning("Something went wrong!", {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
              this.autoDisplayJournals();
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },

    unsaved(d) {
      const toast = useToast();
      axios
        .get(
          process.env.VUE_APP_API_SERVER +
            "/journalcategory/unsaved/" +
            d.id +
            "/" +
            this.$store.getters.getUserdetailID,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            if (response.data.status == 200 || response.data.status == 201) {
              this.autoDisplayJournals();
              this.seeSaved = false;
              toast.success(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            } else {
              this.autoDisplayJournals();
              this.seeSaved = false;
              toast.warning(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
    unsaved2(d) {
      const toast = useToast();
      axios
        .get(
          process.env.VUE_APP_API_SERVER +
            "/journalcategory/unsaved2/" +
            d.journalID +
            "/" +
            this.$store.getters.getUserdetailID,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            if (response.data.status == 200 || response.data.status == 201) {
              this.autoDisplayJournals();
              this.seeSaved = false;
              toast.success(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            } else {
              this.autoDisplayJournals();
              this.seeSaved = false;
              toast.warning(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
    cite(d) {
      if (d) {
        this.isCitationModal = true;
        this.citationData = d;
      }
    },

    //load approved journals
    // async getJournals() {
    //     if (this.searchkey == null || this.searchkey == "") {
    //         this.contents = [];
    //         this.results = 0;
    //         this.isLoading = false;
    //         return false;
    //     } else {
    //         const start = performance.now();
    //         axios
    //             .get(process.env.VUE_APP_API_SERVER + "/fileupload/previewAll/" + this.searchkey)
    //             .then(
    //                 (response) => {
    //                     this.contents = response.data;
    //                     this.results = response.data.length;
    //                     this.isLoading = false;
    //                     console.log(response.data)
    //                 },
    //                 (error) => {
    //                     console.log(error);
    //                 }
    //             );
    //         const end = performance.now();
    //         const duration = Math.round(end - start) / 100;
    //         this.duration = duration;
    //         this.timeToday();
    //     }

    // },

    //load approved journals all thesis
    // async getThesis() {
    //     if (this.searchkey == null || this.searchkey == "") {
    //         this.contents = [];
    //         this.results = 0;
    //         this.isLoading = false;
    //         return false;
    //     } else {
    //         const start = performance.now();
    //         axios
    //             .get(process.env.VUE_APP_API_SERVER + "/fileupload/previewThesis/" + this.searchkey)
    //             .then(
    //                 (response) => {
    //                     this.contents = response.data;
    //                     this.results = response.data.length;
    //                     this.isLoading = false;
    //                     // console.log(response.data)
    //                 },
    //                 (error) => {
    //                     console.log(error);
    //                 }
    //             );
    //         const end = performance.now();
    //         const duration = Math.round(end - start) / 100;
    //         this.duration = duration;
    //         this.timeToday();
    //     }

    // },

    //load approved journals all dissertation
    // async getDiss() {
    //     if (this.searchkey == null || this.searchkey == "") {
    //         this.contents = [];
    //         this.results = 0;
    //         this.isLoading = false;
    //         return false;
    //     } else {
    //         const start = performance.now();
    //         axios
    //             .get(process.env.VUE_APP_API_SERVER + "/fileupload/previewDissertation/" + this.searchkey)
    //             .then(
    //                 (response) => {
    //                     this.contents = response.data;
    //                     this.results = response.data.length;
    //                     this.isLoading = false;
    //                     // console.log(response.data)
    //                 },
    //                 (error) => {
    //                     console.log(error);
    //                 }
    //             );
    //         const end = performance.now();
    //         const duration = Math.round(end - start) / 100;
    //         this.duration = duration;
    //         this.timeToday();
    //     }

    // },

    handleSearching() {
      if (this.searchText == "" || !this.searchText) {
        this.autoDisplayJournals();
      } else {
        var n = null;
        var nn = null;
        if (
          this.searchText == "thesis" ||
          this.searchText == "Thesis" ||
          this.searchText == "THESIS" ||
          this.searchText == "thes" ||
          this.searchText == "Thes" ||
          this.searchText == "THES"
        ) {
          n = 1;
        }

        if (
          this.searchText == "capstone" ||
          this.searchText == "Capstone" ||
          this.searchText == "CAPSTONE" ||
          this.searchText == "cap" ||
          this.searchText == "Cap" ||
          this.searchText == "CAP"
        ) {
          n = 2;
        }

        if (
          this.searchText == "dissertation" ||
          this.searchText == "Dissertation" ||
          this.searchText == "DISSERTATION"
        ) {
          n = 3;
        }

        if (
          this.searchText == "research" ||
          this.searchText == "Research" ||
          this.searchText == "RESEARCH"
        ) {
          n = 4;
        }

        if (
          this.searchText == "journal" ||
          this.searchText == "Journal" ||
          this.searchText == "JOURNAL"
        ) {
          n = 5;
        }

        if (
          this.searchText == "others" ||
          this.searchText == "Others" ||
          this.searchText == "OTHERS"
        ) {
          n = 6;
        }

        if (
          this.searchText == "published" ||
          this.searchText == "Published" ||
          this.searchText == "PUBLISHED" ||
          this.searchText == "publish" ||
          this.searchText == "Publish" ||
          this.searchText == "PUBLISH" ||
          this.searchText == "pub" ||
          this.searchText == "PUB" ||
          this.searchText == "Pub" ||
          this.searchText == "pu" ||
          this.searchText == "Pu" ||
          this.searchText == "PU"
        ) {
          nn = 1;
        }

        if (
          this.searchText == "unpublished" ||
          this.searchText == "Unpublished" ||
          this.searchText == "UNPUBLISHED" ||
          this.searchText == "unpublish" ||
          this.searchText == "Unpublish" ||
          this.searchText == "UNPUBLISH" ||
          this.searchText == "not yet publish" ||
          this.searchText == "Not Yet Publish" ||
          this.searchText == "NOT YET PUBLISH" ||
          this.searchText == "not yet published" ||
          this.searchText == "Not Yet Published" ||
          this.searchText == "NOT YET PUBLISHED" ||
          this.searchText == "un" ||
          this.searchText == "UN" ||
          this.searchText == "Un"
        ) {
          nn = 0;
        }

        this.contents = this.filteredData.filter(
          (data) =>
            data.title.toLowerCase().indexOf(this.searchText.toLowerCase()) !==
              -1 ||
            data.mainauthorfname
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) !== -1 ||
            data.mainauthorlname
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) !== -1 ||
            data.authorsKeywords
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) !== -1 ||
            data.typeOfArticle.toString().indexOf(n) !== -1 ||
            data.isPublished.toString().indexOf(nn) !== -1
        );
      }
    },

    //load approved journals all dissertation
    // async getJournals2() {
    //     if (this.searchkey == null || this.searchkey == "") {
    //         this.contents = [];
    //         this.results = 0;
    //         this.isLoading = false;
    //         return false;
    //     } else {
    //         const start = performance.now();
    //         axios
    //             .get(process.env.VUE_APP_API_SERVER + "/fileupload/previewJournal/" + this.searchkey)
    //             .then(
    //                 (response) => {
    //                     this.contents = response.data;
    //                     this.results = response.data.length;
    //                     this.isLoading = false;
    //                     // console.log(response.data)
    //                 },
    //                 (error) => {
    //                     console.log(error);
    //                 }
    //             );
    //         const end = performance.now();
    //         const duration = Math.round(end - start) / 100;
    //         this.duration = duration;
    //         this.timeToday();
    //     }

    // },

    autoDisplayJournals() {
      const start = performance.now();
      this.searchText = "";
      this.isLoading = true;
      setTimeout(() => (this.isLoading = false), 3000);

      axios
        .get(
          process.env.VUE_APP_API_SERVER + "/fileupload/getAutoDisplayJournal/",
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            this.contents = response.data;
            this.filteredData = response.data;
            // console.log(response.data);
            this.results = response.data.length;
            this.isLoading = false;
          },
          (error) => {
            console.log(error);
          }
        );
      const end = performance.now();
      const duration = Math.round(end - start) / 100;
      this.duration = duration;
      this.timeToday();
    },

    autoDisplayJournalSaved() {
      const start = performance.now();
      this.searchText = "";

      var userdetailID = this.$store.getters.getUserdetailID;
      // console.log(userdetailID);
      axios
        .get(
          process.env.VUE_APP_API_SERVER +
            "/fileupload/getSavedArticles/" +
            userdetailID,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            this.isLoading = true;
            setTimeout(() => (this.isLoading = false), 1000);
            // console.log(response.data);
            this.contents = response.data;
            this.filteredData = response.data;
            this.results = response.data.length;
            this.isLoading = false;
          },
          (error) => {
            console.log(error);
          }
        );
      const end = performance.now();
      const duration = Math.round(end - start) / 100;
      this.duration = duration;
      this.timeToday();
    },

    viewJournal(data) {
      this.isLoading = true;
      setTimeout(function () {
        this.isLoading = false;
      }, 3000);
      this.$emit("viewJournal", data);
      this.$emit("searchText", this.searchText);
      // console.log(item);
    },

    // format date to display
    formatDate(date, year) {
      var mnthArr = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      if (date == null || date == "") {
        return year;
      } else {
        return mnthArr[date] + " " + year;
      }
    },

    // set number of pages to paginate
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(
        parseInt(this.contents.length) / this.perPage
      );
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    // handles the from and to of pagination table
    paginate(contents) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;

      if (to > this.contents.length) {
        this.fromPage = from + 1;
        this.toPage = this.contents.length;
      } else {
        this.fromPage = from + 1;
        this.toPage = to;
      }

      return contents.slice(from, to);
    },

    getRange(from, to) {
      const range = [];

      from = from > 0 ? from : 1;

      for (let i = from; i <= to; i++) {
        range.push(i);
      }

      return range;
    },
  },
  computed: {
    displayedJournals() {
      return this.paginate(this.contents);
    },
    isValueLast() {
      return this.value >= this.length;
    },

    isValueFirst() {
      return this.value <= 1;
    },

    items() {
      const maxLength = 7;
      if (this.pages.length <= maxLength || maxLength < 1) {
        return this.getRange(1, this.pages.length);
      }

      const even = maxLength % 2 === 0 ? 1 : 0;
      const left = Math.floor(maxLength / 2);
      const right = this.pages.length - left + 1 + even;

      if (this.page > left && this.page < right) {
        const start = this.page - left + 2;
        const end = this.page + left - 2 - even;

        return [
          1,
          "...",
          ...this.getRange(start, end),
          "...",
          this.pages.length,
        ];
      }
      if (this.page === left) {
        const end = this.page + left - 1 - even;

        return [...this.getRange(1, end), "...", this.pages.length];
      }
      if (this.page === right) {
        const start = this.page - left + 1;

        return [1, "...", ...this.getRange(start, this.pages.length)];
      } else {
        return [
          ...this.getRange(1, left),
          "...",
          ...this.getRange(right, this.pages.length),
        ];
      }
    },
  },
  watch: {
    contents() {
      this.setPages();
    },

    perPage() {
      this.page = 1;
      this.setPages();
    },
    containerWidth() {
      // trigger pagination resize
      this.$nextTick(() => {
        window.dispatchEvent(new Event("resize"));
      });
    },
    searchTitle: {
      handler(val) {
        if (val) {
          this.searchTitleData = val;
          // console.log(val)
        }
      },
    },
  },
  created() {
    this.moment = moment;
    this.autoDisplayJournals();
  },
};
</script>

<style scoped>
.tbl_header {
  background-color: #105005;
}

.indent {
  text-indent: 0.5rem;
  height: 35px;
}

/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}

.mustTruncate {
  text-overflow: ellipsis;
}

li > a.activeTab {
  border-color: rgba(16, 80, 5, var(--tw-border-opacity));
  border-bottom-width: 2px;
  color: rgba(16, 80, 5, var(--tw-text-opacity));
}

.captionText {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>