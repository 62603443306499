<template>
  <div
    id="defaultModal"
    tabindex="-1"
    aria-hidden="true"
    class="bgModal fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full justify-center items-center"
  >
    <div class="relative mt-10 m-auto w-full h-full max-w-md p-4 md:h-auto">
      <div
        id="divModal"
        class="modal relative bg-white overflow-hidden rounded-lg shadow dark:bg-gray-700 animated modal_open"
      >
        <!-- modal header -->
        <div class="flex justify-between border-b py-3 px-3 bg-105005">
          <h1 class="text-white text-sm font-poppins font-semibold">
            Pending Research
          </h1>
          <button
            type="button"
            @click="closeModal"
            class="absolute top-1 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="authentication-modal"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- modal content -->
        <div class="grid items-center">
          <div class="m-auto px-2 py-5" v-if="!isPendingForm">
            <img
              src="../assets/bg/Thinking-face-bro.png"
              class="w-auto h-auto m-auto"
              alt=""
            />
            <p class="m-auto pb-2 pt-2 text-sm text-gray-800 font-semibold">
              Are you sure you want to pend this document?
            </p>
          </div>

          <!-- pending textarea -->
          <div class="mx-auto my-0 py-3" v-if="isPendingForm">
            <textarea
              v-model="pendingInput"
              class="border text-xs inputIndent py-1 px-1 rounded-md bg-gray-100 font-poppins"
              cols="61"
              rows="10"
              placeholder="Input reason here"
            ></textarea>
          </div>
        </div>
        <!-- buttons -->
        <div
          class="flex justify-end p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600"
        >
          <!-- this button is to toggle the textarea visible  -->
          <button
            v-if="!isPendingForm"
            type="button"
            @click="isPendingForm = true"
            class="border border-FFB800 rounded bg-FFB800 text-white text-sm font-normal px-5 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-5 h-5 inline"
            >
              <path
                d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z"
              />
              <path
                d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z"
              />
            </svg>

            Pending
          </button>

          <!-- this button is to open the message modal on pending  -->
          <button
            v-if="isPendingForm"
            @click="savePending(journaldoc)"
            type="button"
            class="text-sm text-white bg-105005 border border-105005 rounded font-normal px-2 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 inline"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            Save Pending Remarks
          </button>
          <button
            type="button"
            @click="closeModal"
            class="text-sm text-gray-500 bg-gray-300 font-bold rounded font-normal px-5 py-1 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
          >
            <!-- <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 inline"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg> -->
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
  name: "PendingDecisionModal",
  props: ["journaldoc"],

  data() {
    return {
      isPendingForm: false,
      pendingInput: "",
      journaldata: null,
    };
  },

  methods: {
    closeModal() {
      document.getElementById("divModal").classList.add("modal_close");
      document.getElementById("divModal").classList.remove("modal_open");
      setTimeout(() => this.$emit("closeModal"), 500);
      // this.$emit("closeModal");
    },

    //pending journal function

    savePending(doc) {
      if (this.pendingInput == null || this.pendingInput == "") {
        const toast = useToast();
        var msg = "Remarks / Comments is required upon pending the the journal";
        // document.getElementById("defaultModal").classList.add("modal_close");
        // document.getElementById("defaultModal").classList.remove("modal_open");
        // setTimeout(() => 500);
        toast.warning(msg, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } else {
        const data = { reason: this.pendingInput };
        const final = Object.assign(doc, data);

        axios
          .post(process.env.VUE_APP_API_SERVER + "/fileupload/pending", final, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          })
          .then(
            (response) => {
              if (response.data.affected == 1) {
                // emit to ApprovalView
                // console.log(response);
                this.$emit("pending");
                this.$emit("isUpdatedData");
              }
            },
            (error) => {
              console.log(error);
            }
          );
      }
    },
  },
};
</script>

<style scoped>
.bgGreen {
  color: #105005;
}
.inputIndent {
  text-indent: 10px;
}
</style>
