<template>
  <div class="w-full sm:w-full sm:m-auto md:w-7/12 md:m-auto font-poppins">
    <div class="bg-white rounded-xl px-5 py-4 overflow-y-auto">
      <div class="flex m-auto">
        <p class="text-md text-gray-500">Sign Up</p>
        <p class="text-gray-500 mx-3">|</p>
        <p class="text-md font-semibold text-green-900">Welcome Back !</p>
      </div>
      <hr class="mt-1" />

      <form method="POST" @submit.prevent class="mt-4">
        <div class="grid md:grid-flow-col md:justify-stretch m-auto">
          <div class="mb-2 mx-1">
            <label
              for="fname"
              class="block text-gray-700 text-xs font-bold mb-2"
            >
              <span class="text-red-500">&nbsp;*</span>
              First Name
            </label>
            <div class="mt-1 relative rounded-md shadow-sm w-full">
              <!-- <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"> <svg class="h-5 w-5 text-gray-400" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24"><path d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg> </div> -->
              <input
                id="fname"
                @keyup="onInput($event)"
                v-model="fname"
                :class="fname_err ? 'border-red-500' : ''"
                class="block text-xs shadow appearance-none border-2 border-orange-100 rounded w-full py-2 px-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-orange-500 transition duration-500 ease-in-out"
                placeholder="John"
              />
            </div>
            <strong v-if="fname_err" class="text-red-500 text-xs flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 mr-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                />
              </svg>
              First name is required
            </strong>
          </div>
          <!-- <div class="mb-2 mx-1"> <label for="mname" class="block text-gray-700 text-xs font-bold mb-2" > Middle Name </label> <div class="mt-1 relative rounded-md shadow-sm"> <input id="mname" v-model="mname" class="text-xs block shadow appearance-none border-2 border-orange-100 rounded w-full py-2 px-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-orange-500 transition duration-500 ease-in-out" placeholder="Doe" /> </div> </div> -->
        </div>
        <div class="mb-2 mx-1">
          <label for="mname" class="block text-gray-700 text-xs font-bold mb-2">
            <!-- <span class="text-red-500">&nbsp;*</span> -->
            Middle Name
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <input
              id="mname"
              v-model="mname"
              class="text-xs block pr-10 shadow appearance-none border-2 border-orange-100 rounded w-full py-2 px-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-orange-500 transition duration-500 ease-in-out"
              placeholder="Doe"
            />
          </div>
        </div>

        <div class="mb-2 mx-1">
          <label for="mname" class="block text-gray-700 text-xs font-bold mb-2">
            <span class="text-red-500">&nbsp;*</span>
            Last Name
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <input
              id="lname"
              @keyup="onInput($event)"
              v-model="lname"
              :class="fname_err ? 'border-red-500' : ''"
              class="text-xs block pr-10 shadow appearance-none border-2 border-orange-100 rounded w-full py-2 px-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-orange-500 transition duration-500 ease-in-out"
              placeholder="Doe"
            />
          </div>
          <strong v-if="lname_err" class="text-red-500 text-xs flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
              />
            </svg>
            Last name is required
          </strong>
        </div>

        <div class="mb-6 mx-1 pt-2">
          <label for="mname" class="block text-gray-700 text-xs font-bold mb-2">
            <span class="text-red-500">&nbsp;*</span>
            What are you?
          </label>
          <div
            class="flex flex-col sm:justify-center md:justify-center lg:justify-center xl:justify-center"
          >
            <select
              v-model="userType"
              class="text-xs block pr-10 shadow appearance-none border-2 border-orange-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-orange-500 transition duration-500 ease-in-out"
            >
              <option value="Student">Student</option>
              <option value="Alumni">Alumni</option>
              <option value="Faculty">Faculty</option>
              <option value="Staff">Staff</option>
              <option value="Others">Others</option>
            </select>
            <!-- <div class="form-check mx-5 mb-2 text-xs justify-center md:items-center flex"> <input class="form-check-input rounded-full h-4 w-4 text-sm border border-gray-300 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="flexRadioDefault" id="flexRadioDefault10" checked @change="onSelected" v-model="userType" value="Student" /> <label for="flexRadioDefault10">Student</label> </div> <div class="form-check mx-5 mb-2 text-xs justify-center md:items-center flex"> <input class="form-check-input rounded-full h-4 w-4 border border-gray-300 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="flexRadioDefault" value="Alumni" v-model="userType" @change="onSelected" id="flexRadioDefault20" /> <label for="">Alumni</label> </div> <div class="form-check mx-5 text-xs justify-center md:items-center flex"> <input class="form-check-input rounded-full h-4 w-4 border border-gray-300 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="flexRadioDefault" value="Faculty" v-model="userType" @change="onSelected" id="flexRadioDefault30" /> <label for="flexRadioDefault30">Faculty</label> </div> <div class="form-check mx-5 text-xs justify-center md:items-center flex"> <input class="form-check-input rounded-full h-4 w-4 border border-gray-300 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="flexRadioDefault" value="Faculty" v-model="userType" @change="onSelected" id="flexRadioDefault40" /> <label for="flexRadioDefault40">Others</label> </div> -->
          </div>
        </div>

        <div class="mb-2 mx-1">
          <label for="mname" class="block text-gray-700 text-xs font-bold mb-2">
            <span class="text-red-500">&nbsp;*</span>
            Institute / Department
          </label>
          <div class="mt-1 relative rounded-md shadow-sm"></div>
          <select
            v-model="institute_select"
            class="text-xs block pr-10 shadow appearance-none border-2 rounded w-full py-2 px-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-orange-500 transition duration-500 ease-in-out"
          >
            <option
              v-for="institute in institutes"
              :key="institute"
              :value="institute.id"
            >
              {{ institute.name }}
            </option>
          </select>
        </div>

        <div class="mb-2 pt-2">
          <label
            for="username"
            class="block text-gray-700 text-xs font-bold mb-2"
          >
            <span class="text-red-500">&nbsp;*</span>
            Email Address
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <div
              class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
            >
              <svg
                class="h-5 w-5 text-gray-400"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                ></path>
              </svg>
            </div>
            <input
              v-model="email"
              @keyup="emailCheck"
              :class="email_err ? 'border-red-500' : ''"
              class="text-xs block pr-10 shadow appearance-none border-2 border-orange-100 rounded w-full py-2 px-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-orange-500 transition duration-500 ease-in-out"
              placeholder="you@dnsc.edu.ph"
            />
          </div>
          <strong v-if="email_err" class="text-red-500 text-xs flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
              />
            </svg>
            {{ email_err }}</strong
          >
        </div>

        <div class="mb-2 pt-2">
          <label class="block text-gray-700 text-xs font-bold mb-2">
            <span class="text-red-500">&nbsp;*</span>
            Password
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <div
              class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
            >
              <svg
                class="h-5 w-5 text-gray-400"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                ></path>
              </svg>
            </div>
            <input
              type="password"
              v-model="password"
              @keyup="passwordCheck"
              :class="password_err ? 'border-red-500' : ''"
              class="text-xs block pr-10 shadow appearance-none border-2 border-orange-100 rounded w-full py-2 px-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-orange-500 transition duration-500 ease-in-out"
              placeholder="***********************"
            />
          </div>
          <strong v-if="password_err" class="text-red-500 text-xs flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
              />
            </svg>
            Must contain an uppercase, lowercase, alphanumeric and special
            characters and must be 8 characters or higher</strong
          >
        </div>

        <div class="mb-2 pt-2">
          <label class="block text-gray-700 text-xs font-bold mb-2">
            <span class="text-red-500">&nbsp;*</span>
            Confirm Password
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <div
              class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
            >
              <svg
                class="h-5 w-5 text-gray-400"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                ></path>
              </svg>
            </div>
            <input
              id="confirm_password"
              type="password"
              v-model="confirm_password"
              @keyup="confirmPasswordCheck"
              class="text-xs block pr-10 shadow appearance-none border-2 border-orange-100 rounded w-full py-2 px-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-orange-500 transition duration-500 ease-in-out"
              placeholder="***********************"
            />
          </div>
          <strong
            v-if="confirm_password_err"
            :class="confirm_password_err ? 'text-green-800' : 'text-red-500'"
            class="text-xs italic"
            >{{ confirm_password_err_msg }}</strong
          >
        </div>

        <div class="mb-6 pt-4">
          <div class="form-check flex items-center">
            <input
              @change="checkTermsandCondition"
              class="form-check-input h-4 w-4 border border-gray-300 rounded-sm bg-whitecd checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="checkbox"
              value=""
              id="termsCheckBox"
            />
            <label
              class="form-check-label inline-block text-xs text-green-700"
              for="flexCheckChecked"
            >
              I have read and agreed to the
              <span
                class="cursor-pointer text-blue-800 underline"
                @click="redirectToTerms"
                >Terms and Policy</span
              >
            </label>
          </div>
        </div>

        <div class="mb-5">
          <div class="grid w-full m-auto text-center">
            <div>
              <button
                v-if="!isLoading"
                id="registerBtn"
                :disabled="!terms"
                :class="terms ? 'bgGreen' : 'bgGreen-disabled'"
                @click="registerUser"
                class="w-full py-2 font-md rounded-md text-white"
              >
                <div class="flex justify-center">
                  <div v-if="!isLoading">Sign Up</div>
                  <div class="signuploader w-6 h-6" v-if="isLoading"></div>
                </div>
              </button>
              <button
                v-if="isLoading"
                disabled
                id="registerBtn"
                :class="terms ? 'bgGreen' : 'bgGreen-disabled'"
                @click="registerUser"
                class="w-full py-2 font-md rounded-md text-white"
              >
                <div class="flex justify-center">
                  <div v-if="!isLoading">Sign Up</div>
                  <div class="signuploader w-6 h-6" v-if="isLoading"></div>
                </div>
              </button>
            </div>
          </div>
        </div>

        <hr />

        <div class="mt-3 mb-3">
          <div class="grid w-full m-auto text-center">
            <h4 class="text-xs text-green-900">Already have an account?</h4>

            <router-link
              to="/signin/login"
              class="bg-yellow-500 block py-2 font-md rounded-md text-white mt-5"
              >Sign in</router-link
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
export default {
  name: "Register",
  data() {
    return {
      fname: null,
      mname: null,
      lname: null,
      userType: "Student",
      email: null,
      password: null,
      confirm_password: null,
      institute_select: 1,
      fname_err: false,
      mname_err: false,
      lname_err: false,
      email_err: null,
      password_err: false,
      confirm_password_err: false,
      confirm_password_err_msg: "",
      terms: false,
      valid: false,
      isLoading: false,
      institutes: [],
    };
  },

  mounted() {
    this.init();
  },
  methods: {
    // set initial values
    init() {
      this.fname = null;
      this.mname = null;
      this.lname = null;
      this.userType = "Student";
      this.email = null;
      this.password = null;
      this.confirm_password = null;
      this.fname_err = false;
      this.mname_err = false;
      this.lname_err = false;
      this.email_err = null;
      this.password_err = false;
      this.confirm_password_err = false;
      this.confirm_password_err_msg = "";
      this.terms = false;
      this.loadInstitutes();
    },
    emailCheck() {
      //  Regex for dnsc email
      // Checking if the inputted email is dnsc email only
      if (this.email == "") {
        this.email_err = null;
      } else {
        const email_test = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
        if (email_test.test(this.email)) {
          this.email_err = null;
        } else {
          this.email_err = "This is not a valid email address";
        }
      }
    },

    passwordCheck() {
      // password regex Uppercase, lowercase, special chars, alphanumeric

      if (this.password == "") {
        this.password_err = false;
      } else {
        const regex =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/;
        //console.log(regex.test(this.password));
        if (regex.test(this.password)) {
          this.password_err = false;
        } else {
          this.password_err = true;
          // "Must contain an uppercase, lowercase, alphanumeric and special characters and must be 8 characters or higher";
        }
      }
    },
    redirectToTerms() {
      let route = this.$router.resolve({ path: "/termsandpolicy" });
      window.open(route.href);
    },

    loadInstitutes() {
      axios.get(process.env.VUE_APP_API_SERVER + "/institutes").then(
        (response) => {
          this.institutes = response.data;
        },
        (error) => {
          console.log(error);
        }
      );
    },

    //Confirm password
    confirmPasswordCheck() {
      const cp = document.getElementById("confirm_password");
      if (this.confirm_password == "") {
        this.confirm_password_err = false;
        cp.classList.remove("border-green-500");
        cp.classList.remove("border-red-500");
      } else {
        if (this.confirm_password == this.password) {
          this.confirm_password_err = true;
          this.confirm_password_err_msg = "Password matched";
          cp.classList.add("border-green-500");
        } else {
          this.confirm_password_err = false;
          this.confirm_password_err_msg = "Password not matched";

          cp.classList.add("border-red-500");
        }
      }
    },

    checkTermsandCondition() {
      // enable / disable sign up button
      this.terms = !this.terms;
      const btn = document.getElementById("registerBtn");
      if (this.terms == true) {
        btn.disabled = false;
      } else {
        btn.disabled = true;
      }
    },

    // radio button selected
    onSelected() {
      //console.log(this.userType);
    },
    onInput(e) {
      if (e.target.value == "") {
        e.target.classList.add("border-red-500");
      } else {
        e.target.classList.remove("border-red-500");
      }
    },

    registerUser() {
      const toast = useToast();
      this.isLoading = true;
      const cp = document.getElementById("confirm_password");
      // checkiung if email is valid
      if (this.email == "" || this.email == null) {
        this.email_err = "This is required";
        this.valid = false;
      } else {
        if (this.email_err == null) {
          this.valid = true;
        } else {
          this.valid = false;
        }
      }

      // password checking
      if (this.password == null || this.password == "") {
        this.password_err = true;
        this.valid = false;
      } else {
        if (this.password_err == false) {
          this.valid = true;
        } else {
          this.valid = false;
        }
      }

      // confirm passwrd checking
      if (this.confirm_password == null || this.confirm_password == "") {
        cp.classList.add("border-red-500");
        this.confirm_password_err = false;
        this.confirm_password_err_msg = "This is required";
        this.valid = false;
      } else {
        if (this.confirm_password_err == false) {
          this.valid = true;
        } else {
          this.valid = false;
        }
      }

      // checking if fname has value
      if (this.fname == "" || this.fname == null) {
        this.fname_err = true;
        this.valid = false;
      } else {
        this.fname_err = false;
        this.valid = true;
      }

      // checking if name has value
      if (this.lname == "" || this.lname == null) {
        this.lname_err = true;
        this.valid = false;
      } else {
        this.lname_err = false;
        this.valid = true;
      }
      if (this.valid) {
        const fd = {
          fname: this.fname,
          mname: this.mname,
          lname: this.lname,
          institute: this.institute_select,
          userType: this.userType,
          email: this.email,
          password: this.password,
        };
        axios
          .post(process.env.VUE_APP_API_SERVER + "/users-controller/new", fd)
          .then(
            (response) => {
              if (response.data)
                if (response.data.status == 201) {
                  this.$store.commit(
                    "setUserType",
                    response.data.details.userType
                  );
                  this.$store.commit("setUserEmail", response.data.user.email);
                  this.$store.commit("setUserID", response.data.user.id);
                  this.$store.commit(
                    "setUserdetailID",
                    response.data.userdetailID
                  );
                  this.$store.commit(
                    "setUserFirstName",
                    response.data.details.fname
                  );
                  this.$store.commit(
                    "setUserMidName",
                    response.data.details.mname
                  );
                  this.$store.commit(
                    "setUserLastName",
                    response.data.details.lname
                  );

                  this.$router.push("/landing/verify");
                } else if (response.data.status == 400) {
                  toast.error(response.data.msg, {
                    position: "top-center",
                    timeout: 3000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: false,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                  });
                }
              this.isLoading = false;
            },
            (error) => {
              console.log(error);

              this.isLoading = false;
            }
          );
      } else {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.bgGreen {
  background-color: #105005;
}

.bgGreen-disabled {
  background-color: #43703f;
}

input[type="radio"],
input[type="checkbox"] {
  accent-color: #105005;
}
</style>
