<template>
  <div class="w-full bg-gray-100 dark:bg-darkBG h-auto">
    <div
      class="grid md:flex items-center sm:xl:justify-between md:justify-between xl:justify-between lg:justify-between bg-gray-100 dark:bg-darkBG mb-2 pt-2"
    >
      <div class="md:w-1/2">
        <h1
          class="md:text-xl text-xs lg:text-xl font-semibold py-5 pl-6 text-gray-900 dark:text-gray-200"
        >
          Main Menu - Dashboard
        </h1>
        <h1
          class="text-xs md:text-sm font-normal ml-6 md:ml-7 -mt-4 text-gray-500 dark:text-gray-300 mb-10"
        >
          Hello {{ this.$store.getters.getUserFirstName }}, Welcome to DNSC
          ReDMaS
        </h1>
      </div>

      <div
        class="hidden md:flex md:justify-end lg:flex lg:justify-end xl:flex xl:justify-end sm:hidden mr-3 -mt-6 overflow-hidden"
      >
        <div class="relative sm:w-60 md:w-96 px-4">
          <input
            type="text"
            v-model="searchval"
            @keypress.enter="searchBtn"
            class="block py-1 px-2 w-full z-20 text-sm text-gray-600 bg-gray-50 dark:bg-darkBG dark:text-gray-200 rounded-lg border-l-gray-50 border-l-2 border border-gray-300 outline-none"
            placeholder="Search by anything"
            required
          />
          <button
            type="submit"
            @click="searchBtn"
            class="absolute top-0 right-0 py-1 px-2 text-sm font-medium text-white bg-customGreen rounded-tr-lg rounded-br-lg border focus:ring-4 focus:outline-none focus:ring-blue-300"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
            <span class="sr-only">Search</span>
          </button>
        </div>
      </div>
      <!-- <span>PS: Approved unpublished and published researches only.</span> -->
    </div>

    <div class="ml-6 text-center md:text-left lg:text-left xl:text-left">
      <span
        class="text-green-700 dark:text-gray-200 text-sm font-semibold font-poppins"
        >{{ dateNow }} <span>{{ time }}</span></span
      >
    </div>

    <div class="my-2 md:mt-1 md:mb-2 mx-2 min-h-full">
      <!-- <div class="bg-green-200 text-105005 text-sm px-2 py-1 rounded mx-2 mb-2 text-center" v-if="showNotice">
        <p class="uppercase">System Update scheduled today <b>June 8, 2023</b>.</p>
      </div> -->
      <div class="md:flex lg:flex justify-evenly">
        <div
          v-if="
            this.$store.getters.getUserRole == 1 ||
            this.$store.getters.getUserRole == 2 ||
            this.$store.getters.getUserRole == 3
          "
          class="flex bg-white md:flex border mx-2 md:w-1/3 lg:w-1/3 items-center rounded-lg px-2 my-2 md:my-0 lg:my-0 xl:my-0 2xl:my-0"
        >
          <div class="w-full m-auto py-2">
            <TotalChart
              :chartDataprop="totalChartData ? totalChartData : {}"
              :value="totalUploads"
            />
            <!-- <div class="text-xs"><h5 class="text-xs font-semibold inline">LGND:</h5>
              <span style="background-color: #22577A" class="text-white">PENDING</span>,
              <span style="background-color: #38A3A5" class="text-white">APPROVE (DEAN)</span>,
              <span style="background-color: #38A3A5" class="text-white">FOR APPROVAL</span>,
              <span style="background-color: #38A3A5" class="text-white">ENDORSED</span>,
              <span style="background-color: #38A3A5" class="text-white">APPROVED (LIB)</span>
            </div> -->
          </div>
        </div>

        <div
          class="bg-white dark:bg-gray-900 flex justify-between border mx-2 md:w-1/3 lg:w-1/3 rounded-lg px-2 py-2 my-2 md:my-0 lg:my-0 xl:my-0 2xl:my-0"
        >
          <div class="w-full m-auto py-2">
            <PublishedChart
              :chartDataprop="publishedChartData ? publishedChartData : {}"
              :value="publishedVal"
            />
          </div>
        </div>

        <div
          class="bg-white dark:bg-gray-900 flex justify-between border mx-2 md:w-1/3 lg:w-1/3 rounded-lg px-2 py-2 my-2 md:my-0 lg:my-0 xl:my-0 2xl:my-0"
        >
          <div class="w-full m-auto py-2">
            <UnpublishedChart
              :chartDataprop="unPublishedChartData ? unPublishedChartData : {}"
              :value="unpublishedVal"
            />
          </div>
        </div>

        <div
          class="bg-white dark:bg-gray-900 flex justify-between border mx-2 md:w-1/3 lg:w-1/3 rounded-lg px-2 py-2 my-2 md:my-0 lg:my-0 xl:my-0 2xl:my-0"
        >
          <div class="w-full m-auto py-2">
            <ForPublicationChart
              :chartDataprop="
                forPublicationChartData ? forPublicationChartData : {}
              "
              :value="forPublicationVal"
            />
          </div>
        </div>

        <!-- <div class=" bg-white flex justify-between border mx-2 md:w-1/3 lg:w-1/3 rounded-lg px-2 py2 ">
          <div class="w-full m-auto py-2">
            <UserChart :chartDataprop="unPublishedChartData ? unPublishedChartData : {}" :value="unpublishedVal" />
          </div>
        </div> -->
      </div>
    </div>

    <div
      class="px-2 md:flex lg:flex xl:flex md:justify-evenly lg:justify-evenly xl:justify-evenly rounded-lg mx-2 py-2 mt-2 mb-2 min-h-full"
    >
      <!-- <div
        v-if="this.$store.getters.getUserRole == 4 || this.$store.getters.getUserRole == 5 || this.$store.getters.getUserRole == 6"
        class="w-full h-full rounded-lg shadow-md" style="height:50%;">
        <div class="text-xs float-right px-2 py-2">
          <select v-model="yearSelected" @change="changeYear()"
            class="text-gray-600 w-full font-poppins text-right border">
            <option v-for="yr in yearsArr" :key="yr" :value="yr">
              {{ yr }}
            </option>
          </select>
        </div>
        <UploadStatBarChart class="px-4 h-full w-full pb-5 bg-white"
          :chartDataprop="uploadStatData ? uploadStatData : {}" />
      </div> -->
      <div
        class="w-full bg-white dark:bg-gray-400 border p-3 mb-2 ml-0 md:ml-1 lg:ml-2 xl:ml-2 md:mr-2 lg:mr-2 md:mb-0 md:w-8/12 lg:w-8/12 xl:w-8/12 xl:mr-2 rounded-lg shadow-md"
        style="height: 40rem"
      >
        <div class="text-xs float-right px-2 py-2">
          <select
            v-model="yearSelected"
            @change="changeYear()"
            class="text-gray-600 w-full dark:bg-gray-300 font-poppins text-center border rounded"
          >
            <option v-for="yr in yearsArr" :key="yr" :value="yr">
              {{ yr }}
            </option>
          </select>
        </div>
        <UploadStatBarChart
          :chartDataprop="uploadStatData ? uploadStatData : {}"
        />
      </div>
      <div
        class="w-full h-auto ml-0 xl:mt-0 md:w-4/12 lg:w-4/12 xl:w-4/12 py-2 xl:px-2 xl:ml-2 md:ml-0 lg:ml-0 rounded-md shadow-md bg-white dark:bg-gray-900 border"
      >
        <div class="p-3">
          <h1
            class="md:text-xl text-xs lg:text-xl font-semibold text-105005 dark:text-gray-200"
          >
            Events
          </h1>
        </div>
        <div class="overflow-y-auto pr-2 -mr-2">
          <ul
            class="divide-y divide-gray-200 dark:divide-gray-700 px-3 event_ul"
          >
            <!-- <div v-if="this.$store.getters.getUserRole != 4 && this.$store.getters.getUserRole != 5">
                <div @click="categoryDivClicked = !categoryDivClicked" data-bs-toggle="collapse"
                  data-bs-target="#categoryDiv" aria-expanded="false" aria-controls="categoryDiv"
                  class=" flex justify-between uppercase cursor-pointer ml-3 font-semibold text-xs pb-3 pt-3 text-gray-800 ">
                  <h4>Category</h4>
                  <div
                    :class="categoryDivClicked ? 'transform rotate-180 duration-150' : 'transform rotate-0 duration-150'">
                    <Icons :name="'chevron-up'" class="w-4 h-4"></Icons>
                  </div>
                </div>
                <ul id="categoryDiv" class="collapse multi-collapse">
                  <li>
                    <router-link to="/userspage/category" class=" mb-0.5 text-gray-500 hover:bg-customGreen hover:text-white group flex items-center px-2 py-1 text-xs font-medium rounded-md
                              " @click="refreshNotification">
                      <Icons :name="'rectangle-group'" class="w-6 h-6 mr-3"></Icons>
                      Journal Category
                    </router-link>
                  </li>
                </ul>
              </div> -->
            <li class="sm:pb-4 py-2" v-for="item in contents" :key="item">
              <div class="flex items-center space-x-4">
                <div class="flex-1 min-w-0">
                  <p
                    class="text-sm text-gray-700 dark:text-gray-200 break-words cursor-pointer w-40 hover:underline hover:text-blue-600 hover:cursor-pointer"
                    @click="changeLoc()"
                  >
                    {{ item.eventName }}
                  </p>
                  <p
                    class="text-xs font-medium text-gray-400 truncate dark:text-gray-600"
                  >
                    {{ moment(item.eventAdded).fromNow() }}
                  </p>
                </div>
                <!-- <div class="flex-1 min-w-0"
                  v-if="this.$store.getters.getUserRole == 2 || this.$store.getters.getUserRole == 3">
                  <p @click="myFilter(item.id)"
                    :class="isActive && item.id == this.tempNum ? 'text-blue-500 font-semibold' : ''"
                    class="text-sm text-gray-700 break-words dark:text-gray-400 cursor-pointer w-40 hover:cursor-pointer">
                    {{ item.eventName }}
                  </p>
                  <p class="text-xs font-medium text-gray-400 truncate dark:text-white">
                    {{ moment(item.eventAdded).fromNow() }}
                  </p>
                </div> -->
                <div
                  class="inline-flex items-center text-gray-900 dark:text-white"
                >
                  <span
                    style="font-size: 11px"
                    v-if="item.eventStatus == 1"
                    class="inline-flex items-center rounded-full uppercase bg-green-100 dark:bg-darkBG border dark:text-gray-200 m-0.5 px-4 py-1 text-green-800"
                    >Active</span
                  >
                  <span
                    style="font-size: 11px"
                    v-if="item.eventStatus == 2"
                    class="inline-flex items-center rounded-full uppercase dark:bg-darkBG dark:text-gray-200 border bg-yellow-100 m-0.5 px-4 py-1 text-yellow-800"
                    >Previous</span
                  >
                  <span
                    style="font-size: 11px"
                    v-if="item.eventStatus == 3"
                    class="inline-flex items-center rounded-full uppercase dark:bg-darkBG dark:text-gray-200 border bg-purple-100 m-0.5 px-4 py-1 text-purple-800"
                    >Upcoming</span
                  >
                </div>
              </div>
              <div
                class="text-justify text-xs pt-1 text-gray-500"
                :class="
                  isActive && item.id == this.tempNum
                    ? 'ease-in-out duration-700'
                    : 'truncate'
                "
                v-if="
                  this.$store.getters.getUserRole == 2 ||
                  this.$store.getters.getUserRole == 3
                "
              >
                <!-- {{ item.eventDesc }} -->
                {{ item.eventDesc }}
              </div>
            </li>
            <!-- <li class="py-3 sm:py-4">
            <div class="flex items-center space-x-4">
              <div class="flex-1 min-w-0">
                <p class="text-sm text-gray-800 truncate dark:text-gray-400">
                  Information Technology Conference
                </p>
                <p class="text-xs font-medium text-gray-400 truncate dark:text-white">
                  a month ago
                </p>
              </div>
              <div class="inline-flex items-center text-gray-900 dark:text-white">
                <span style="font-size: 11px;"
                  class="inline-flex items-center rounded-full uppercase bg-green-100 m-0.5 px-4 py-1 text-green-800">Active</span>
              </div>
            </div>
          </li>
          <li class="py-3 sm:py-4">
            <div class="flex items-center space-x-4">
              <div class="flex-1 min-w-0">
                <p class="text-sm text-gray-800 truncate dark:text-gray-400">
                  Information Technology Conference
                </p>
                <p class="text-xs font-medium text-gray-400 truncate dark:text-white">
                  12 days ago
                </p>
              </div>
              <div class="inline-flex items-center text-gray-900 dark:text-white">
                <span  style="font-size: 11px;"
                  class="inline-flex items-center rounded-full uppercase bg-green-100 m-0.5 px-4 py-1 text-green-800">Active</span>
              </div>
            </div>
          </li>
          <li class="py-3 sm:py-4">
            <div class="flex items-center space-x-4">
              <div class="flex-1 min-w-0">
                <p class="text-sm text-gray-800 truncate dark:text-gray-400">
                  Information Technology Conference
                </p>
                <p class="text-xs font-medium text-gray-400 truncate dark:text-white">
                  a few minutes ago
                </p>
              </div>
              <div class="inline-flex items-center text-gray-900 dark:text-white">
                <span  style="font-size: 11px;"
                  class="inline-flex items-center rounded-full uppercase bg-yellow-100 m-0.5 px-4 py-1 text-yellow-800">Previous</span>
              </div>
            </div>
          </li>
          <li class="pt-3 pb-2 sm:pt-4">
            <div class="flex items-center space-x-4">
              <div class="flex-1 min-w-0">
                <p class="text-sm text-gray-800 truncate dark:text-gray-400">
                  Information Technology Conference
                </p>
                <p class="text-xs font-medium text-gray-400 truncate dark:text-white">
                  a week ago
                </p>
              </div>
              <div class="inline-flex items-center text-gray-900 dark:text-white">
                <span  style="font-size: 11px;"
                  class="inline-flex items-center rounded-full uppercase bg-purple-100 m-0.5 px-4 py-1 text-purple-800">Upcoming</span>
              </div>
            </div>
          </li> -->
          </ul>
        </div>
      </div>
    </div>
    <div class="w-auto mx-5 mt-2 pb-5">
      <!-- <div class="text-xs float-right px-2 py-2">
            <select v-model="yearSelected" @change="changeYear()"
              class="text-gray-600 w-full font-poppins text-right border">
              <option v-for="yr in yearsArr" :key="yr" :value="yr">
                {{ yr }}
              </option>
            </select>
          </div> -->
      <HistoricalChart
        class="bg-white p-5 rounded-lg dark:bg-gray-400"
        :chartDataprop="historicalData ? historicalData : {}"
      />
    </div>
  </div>
</template>

<script>
// import MyWorksTable from "./MyWorksTable.vue";
import axios from "axios";
import { defineComponent } from "@vue/runtime-core";
import moment from "moment";
import momentTZ from "moment-timezone";
// import BarChart from "../charts/BarChart.vue";
import UnpublishedChart from "../charts/UnpublishedChart.vue";
import PublishedChart from "../charts/PublishedChart.vue";
import TotalChart from "../charts/TotalChart.vue";
import UploadStatBarChart from "../charts/UploadStatBarChart.vue";
import HistoricalChart from "../charts/HistoricalChart.vue";
import ForPublicationChart from "../charts/ForPublicationChart.vue";
import EndorsedChart from "../charts/EndorsedChart.vue";
import ApprovedChart from "../charts/ApprovedChart.vue";
import UserChart from "../charts/UserChart.vue";
export default defineComponent({
  name: "UsersDashboard",

  components: {
    // BarChart,
    ForPublicationChart,
    UnpublishedChart,
    PublishedChart,
    TotalChart,
    UploadStatBarChart,
    EndorsedChart,
    ApprovedChart,
    UserChart,
    HistoricalChart,
  },

  mounted() {
   console.log(this.$store.getters.getToken);
    this.loadUnPublished();
    this.loadPublished();
    this.loadTotal();
    this.loadUploadedStats(this.yearSelected);
    this.loadHistoricalData(this.yearSelected);
    
  },

  data() {
    return {
      showNotice: true,
      historicalData: null,
      unPublishedChartData: null,
      publishedChartData: null,
      endorsedChartData: null,
      uploadStatData: null,
      forPublicationChartData: null,
      forPublicationVal: "",
      publishedVal: "",
      unpublishedVal: "",
      endorsedVal: "",
      totalUploads: 0,
      totalChartData: null,
      stored: null,
      totalsData: [],
      yearsArr: [2023, 2022, 2021, 2020],
      yearSelected: 2023,
      timeNow: null,
      dateNow: null,
      interval: null,
      time: null,
      contents: [],
      results: null,
      eventDiv: false,
      isActive: false,
      tempNum: false,
      searchval: null,
    };
  },
  mounted() {
    this.timeToday();
    this.loadPublished();
    this.loadUnPublished();
    this.loadTotal();
    this.loadUploadedStats(this.yearSelected);
    this.loadHistoricalData(this.yearSelected);
    this.loadJournalEvents();
    this.loadForPublication();
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
  },

  created() {
    if (this.$route.params.refreshData) {
      this.timeToday();
      this.loadPublished();
      this.loadUnPublished();
      this.loadTotal();
      this.loadUploadedStats(this.yearSelected);
      this.loadHistoricalData(this.yearSelected);
      this.loadJournalEvents();
      this.loadForPublication();
    }

    this.moment = moment;
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat(navigator.language, {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format();
    }, 1000);
  },

  methods: {
    searchBtn() {
      if (this.searchval == null || this.searchval == "") {
        return;
      } else {
        this.$router.push({
          name: "UserSearch",
          params: { data_text: this.searchval },
        });
      }
    },
    myFilter: function (id) {
      this.isActive = !this.isActive;
      // some code to filter users
      // console.log(id)
      this.tempNum = id;
    },

    changeLoc() {
      this.$router.push("/userspage/events");
    },

    dropDown(name, desc) {
      // console.log(name)
      document.getElementById("showDesc").innerHTML = desc;
    },

    loadJournalEvents() {
      axios
        .get(process.env.VUE_APP_API_SERVER + "/journalEvents/getAllEvents", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            this.contents = response.data;
            // console.log(response);
            this.results = response.data.length;
          },
          (error) => {
            // console.log(error);
          }
        );
    },

    changeYear() {
      // this.loadUploadedStats(this.yearSelected);
      this.loadUploadedStats(this.yearSelected);
    },

    timeToday() {
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var dateTime = date + " " + time;

      this.dateNow =
        moment(today).format("dddd") +
        ", " +
        this.stringFormatDate(this.formatDate(dateTime));
    },

    // loadEndorsed() {
    //   axios
    //     .get(
    //       process.env.VUE_APP_API_SERVER + "/fileupload/myEndorsedCount/4",
    //       {
    //         headers: {
    //           Authorization: `Bearer ${this.$store.getters.getToken}`,
    //         },
    //       }
    //     )
    //     .then((response) => {
    //       this.endorsedVal = response.data.my;
    //       this.endorsedChartData = {
    //         datasets: [
    //           {
    //             data: [response.data.my, response.data.overall],
    //             backgroundColor: ["Green", "#98E486"],
    //             offset: 1,
    //             weight: 1,
    //             borderWidth: 1,
    //             borderRadius: 15,
    //           },
    //         ],
    //         // These labels appear in the legend and in the tooltips when hovering different arcs
    //         labels: ["Published Research", "Unpublished"],
    //       };
    //     });
    // },
    // loadUsers() {
    //   axios
    //     .get(
    //       process.env.VUE_APP_API_SERVER + "/fileupload/myPublicationCount/0",
    //       {
    //         headers: {
    //           Authorization: `Bearer ${this.$store.getters.getToken}`,
    //         },
    //       }
    //     )
    //     .then((response) => {
    //       this.unpublishedVal = (parseInt(response.data.approvedByDean) + parseInt(response.data.approvedByLibrarian)).toString();
    //       this.unPublishedChartData = {
    //         datasets: [
    //           {
    //             data: [response.data.approvedByDean, response.data.approvedByLibrarian],
    //             backgroundColor: ["Green", "#98E486"],
    //             offset: 1,
    //             weight: 1,
    //             borderWidth: 1,
    //             borderRadius: 15,
    //           },
    //         ],
    //         // These labels appear in the legend and in the tooltips when hovering different arcs
    //         labels: ["Dean", "Librarian"],
    //       };
    //     });
    // },

    loadUnPublished() {
      axios
        .get(
          process.env.VUE_APP_API_SERVER + "/fileupload/myPublicationCount/0",
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then((response) => {
          // console.log(response.data)
          this.unpublishedVal = (
            parseInt(response.data.thesis) +
            parseInt(response.data.capstone) +
            parseInt(response.data.dissertation) +
            parseInt(response.data.research) +
            parseInt(response.data.journal) +
            parseInt(response.data.others)
          ).toString();
          this.unPublishedChartData = {
            datasets: [
              {
                data: [
                  response.data.thesis,
                  response.data.capstone,
                  response.data.dissertation,
                  response.data.research,
                  response.data.journal,
                  response.data.others,
                ],
                backgroundColor: [
                  "#105005",
                  "#397329",
                  "#5E984B",
                  "#FFFBCE",
                  "#83BF6F",
                  "#AAE794",
                ],
                offset: 1,
                weight: 1,
                borderWidth: 1,
                borderRadius: 15,
              },
            ],
            // These labels appear in the legend and in the tooltips when hovering different arcs
            labels: [
              "Thesis",
              "Capstone",
              "Dissertation",
              "Research",
              "Journal",
              "Others",
            ],
          };
        });
    },

    loadPublished() {
      axios
        .get(
          process.env.VUE_APP_API_SERVER + "/fileupload/myPublicationCount/1",
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then((response) => {
          this.publishedVal = (
            parseInt(response.data.thesis) +
            parseInt(response.data.capstone) +
            parseInt(response.data.dissertation) +
            parseInt(response.data.research) +
            parseInt(response.data.journal) +
            parseInt(response.data.others)
          ).toString();
          this.publishedChartData = {
            datasets: [
              {
                data: [
                  response.data.thesis,
                  response.data.capstone,
                  response.data.dissertation,
                  response.data.research,
                  response.data.journal,
                  response.data.others,
                ],
                backgroundColor: [
                  "#105005",
                  "#397329",
                  "#5E984B",
                  "#FFFBCE",
                  "#83BF6F",
                  "#AAE794",
                ],
                offset: 1,
                weight: 1,
                borderWidth: 1,
                borderRadius: 15,
              },
            ],
            // These labels appear in the legend and in the tooltips when hovering different arcs
            labels: [
              "Thesis",
              "Capstone",
              "Dissertation",
              "Research",
              "Journal",
              "Others",
            ],
          };
        });
    },

    loadForPublication() {
      axios
        .get(
          process.env.VUE_APP_API_SERVER + "/fileupload/getForPublicationCount",
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then((response) => {
          // console.log(response.data)
          this.forPublicationVal = (
            parseInt(response.data.docPublished) +
            parseInt(response.data.docUnpublished)
          ).toString();
          this.forPublicationChartData = {
            datasets: [
              {
                data: [
                  response.data.docPublished,
                  response.data.docUnpublished,
                ],
                backgroundColor: ["#1DB000", "#C4C600"],
                offset: 1,
                weight: 1,
                borderWidth: 1,
                borderRadius: 15,
              },
            ],
            // These labels appear in the legend and in the tooltips when hovering different arcs
            labels: ["Published", "Unpublished"],
          };
        });
    },

    loadUploadedStats(year) {
      axios
        .get(
          process.env.VUE_APP_API_SERVER +
            "/fileupload/uploadedPaperStat/" +
            year,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then((response) => {
          // console.log(response.data);
          this.uploadStatData = {
            datasets: [
              {
                label: "Uploaded Papers Statistics",
                fontSize: 20,
                backgroundColor: "#105005",
                data: response.data.temp,
              },
              {
                label: "Published Papers Statistics",
                fontSize: 20,
                backgroundColor: "#00943C",
                data: response.data.temp2,
              },
              {
                label: "Unpublished Papers Statistics",
                fontSize: 20,
                backgroundColor: "#02DF5C",
                data: response.data.temp3,
              },
            ],
            // These labels appear in the legend and in the tooltips when hovering different arcs
            labels: [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ],
          };
        });
    },

    loadHistoricalData(year) {
      axios
        .get(
          process.env.VUE_APP_API_SERVER +
            "/fileupload/uploadedHistoricalStat/" +
            year,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then((response) => {
          // console.log(response.data);
          this.historicalData = {
            datasets: [
              {
                label: "Uploaded Papers Statistics",
                // fontSize: 20,
                fill: false,
                borderColor: "#36D91A",
                data: response.data.temp,
                // backgroundColor: "rgba(2, 223, 92, 0.3)",
                borderWidth: 1,
                pointStyle: "circle",
                pointRadius: 5,
                pointBorderColor: "#105005",
              },
              {
                label: "Published Papers Statistics",
                // fontSize: 20,
                fill: false,
                borderColor: "#00B899",
                data: response.data.temp2,
                // backgroundColor: "rgba(2, 223, 92, 0.3)",
                borderWidth: 1,
                pointStyle: "circle",
                pointRadius: 5,
                pointBorderColor: "#105005",
              },
              {
                label: "Unpublished Papers Statistics",
                // fontSize: 20,
                fill: false,
                borderColor: "#CE9247",
                data: response.data.temp3,
                // backgroundColor: 'rgba(206, 146, 71, 0.3)',
                borderWidth: 1,
                pointStyle: "circle",
                pointRadius: 5,
                pointBorderColor: "#105005",
              },
            ],
            // These labels appear in the legend and in the tooltips when hovering different arcs
            labels: [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ],
          };
        });
    },

    loadTotal() {
      axios
        .get(process.env.VUE_APP_API_SERVER + "/fileupload/totalStatusCount", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then((response) => {
          // console.log(response.data);
          this.totalUploads =
            parseInt(response.data.pending) +
            parseInt(response.data.approvedByDean) +
            parseInt(response.data.forApproval) +
            // parseInt(response.data.endorsed) +
            parseInt(response.data.approvedByLibrarian);

          this.totalChartData = {
            datasets: [
              {
                data: [
                  response.data.pending,
                  response.data.approvedByDean,
                  response.data.forApproval,
                  // response.data.endorsed,
                  response.data.approvedByLibrarian,
                ],
                backgroundColor: ["#22577A", "#38A3A5", "#57CC99", "#42E6A4"],
                offset: 1,
                weight: 1,
                borderWidth: 1,
                borderRadius: 15,
              },
            ],
            // These labels appear in the legend and in the tooltips when hovering different arcs
            labels: ["Pending", "Verified", "For Verification", "Published"],
          };
        });
    },

    formatNumber(num) {
      if (num <= 9) {
        return "0" + num;
      } else {
        return num;
      }
    },

    stringFormatDate(date) {
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(date).toLocaleDateString("en", options);
    },

    formatDate(date) {
      const d = new Date(date);
      this.timeAgo = d;

      const mn = d.getMonth() + 1;
      var day = d.getDate();
      const yr = d.getFullYear();
      const hh = d.getHours();
      const mm = d.getMinutes();
      const ss = d.getSeconds();

      return (
        this.formatNumber(mn) +
        "-" +
        this.formatNumber(day) +
        "-" +
        yr +
        " " +
        this.formatNumber(hh) +
        ":" +
        this.formatNumber(mm) +
        ":" +
        this.formatNumber(ss)
      );
    },
  },
});
</script>

<style scoped>
.dashboardWidth {
  width: 70rem;
}

.customSearch {
  width: 20rem;
}

@media only screen and (max-width: 640px) {
  .event_ul {
    height: auto;
  }
}

@media only screen and (max-width: 720px) {
  .event_ul {
    height: auto;
  }
}
</style>
