<template>
  <div
    id="defaultModal"
    tabindex="-1"
    aria-hidden="true"
    class="bgModal fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full justify-center items-center"
  >
    <div class="relative mt-10 m-auto w-full h-full max-w-md p-4 md:h-auto">
      <div
        id="divModal"
        class="modal relative bg-white overflow-hidden rounded-lg shadow dark:bg-gray-700 animated modal_open"
      >
        <!-- modal header -->
        <div class="flex justify-between border-b py-3 px-3 bg-105005">
          <h1 class="text-white text-sm font-poppins font-semibold">
            {{ modal_header }}
          </h1>
          <button
            type="button"
            @click="closeModal"
            class="absolute top-1 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="authentication-modal"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- modal content -->
        <div class="grid items-center">
          <div class="m-auto px-2 py-5">
            <img
              src="../assets/bg/Ok-face-bro.png"
              class="w-auto h-auto"
              alt=""
            />
          </div>
          <p class="m-auto pb-5 text-sm text-gray-800 font-semibold">
            {{ modal_content }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageModal",
  props: ["decision"],

  data() {
    return {
      modal_content: "",
      modal_header: "",
    };
  },
  mounted() {
    document.getElementById("divModal").classList.add("modal_open");
    document.getElementById("divModal").classList.remove("modal_close");

    //approved by Dean
    if (this.decision == "approvedbyDean") {
      this.modal_header = "Succeeded!";
      this.modal_content = "You have successfully verified this document.";
      setTimeout(() => {
        this.closeModal();
      }, 5000);
      //approved by Lib
    } else if (this.decision == "approvedbyLib") {
      this.modal_header = "Succeeded!";
      this.modal_content = "You have successfully published this document.";
      setTimeout(() => {
        this.closeModal();
      }, 5000);
      // this.$emit("updatedData1");
      // this.$emit("updatedData");
    } else if (this.decision == "pending") {
      this.modal_header = "Pended!";
      this.modal_content = "You have successfully pending this document.";
      setTimeout(() => {
        this.closeModal();
      }, 5000);
    } else if (this.decision == "endorsed") {
      this.modal_header = "Endorsed!";
      this.modal_content = "You have successfully endorsed this document.";
    } else if (this.decision == "update") {
      this.modal_header = "Updated!";
      this.modal_content =
        "You have successfully updated this details of this document";
      setTimeout(() => {
        this.closeModal();
      }, 5000);
    }
  },

  methods: {
    closeModal() {
      document.getElementById("divModal").classList.add("modal_close");
      document.getElementById("divModal").classList.remove("modal_open");
      setTimeout(() => this.$emit("closeModal"), 500);

      // journal
    },
  },
};
</script>

<style>
</style>