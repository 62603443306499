<template>
  <div
    id="defaultModal"
    tabindex="-1"
    aria-hidden="true"
    class="bgModal fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full justify-center items-center"
  >
    <div class="relative mt-10 m-auto w-full h-full max-w-md p-4 md:h-auto">
      <div
        id="divModal"
        class="modal relative bg-white overflow-hidden rounded-lg shadow dark:bg-gray-700 animated"
      >
        <!-- modal header -->
        <div class="flex justify-between py-3 px-2 bg-105005">
          <h1 class="text-white text-md pl-2.5">
            Notification
            <span
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 inline -mt-1.5 ml-0.5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5"
                />
              </svg>
            </span>
          </h1>
          <button
            type="button"
            @click="closeModal"
            class="absolute top-1 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-2.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="authentication-modal"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- modal content -->
        <div class="grid bg-gray-100">
          <div
            class="py-2.5 px-2.5 h-auto max-h-96 text-justify overflow-y-auto"
          >
            <div class="separator">
              <!-- <span class="separator-span text-gray-400">Yesterday</span> -->
            </div>
            <div
              class="border rounded-lg mt-1 px-2 pt-1 pb-1 shadow-sm bg-white"
              v-for="item in data.filter((m) => m.notify_isOpened === 0)"
              :key="item"
            >
              <div
                class="inline-flex"
                @click="redirectNotif(item.notify_notifType, item)"
              >
                <div class="py-2 px-2 rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="w-6 h-6 text-105005"
                  >
                    <path
                      d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z"
                    />
                    <path
                      d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z"
                    />
                  </svg>
                </div>
                <p
                  class="font-poppins text-xs align-item-center mt-2 ml-2 pr-2.5 cursor-pointer"
                >
                  {{ item.notify_notifBody }}<br />
                  <span style="font-size: 9px"
                    >{{ moment(item.notify_createdAt).fromNow() }}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <!-- <div class="flex justify-center">
                        <img src="../assets/bg/Thinking-face-bro.png" alt="thinking" class="w-2/3 h-full" />
                    </div>

                    <h4 class="text-sm text-gray-600 text-center">
                        {{ content }}
                    </h4>
                    <div class="flex justify-center mt-5 mb-5">
                        <button @click="confirmRemove" type="button"
                            class=" inline-block px-3 py-1 h-8 bgColor text-white border font-medium text-xs leading-tight uppercase rounded shadow-md active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out ">
                            Yes</button>&nbsp;
                        <button @click="closeModal" type="button"
                            class=" inline-block px-3 py-1 h-8 bg-white-600 text-gray-400 border font-medium text-xs leading-tight uppercase rounded shadow-md active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out ">
                            No
                        </button>
                    </div> -->
        </div>
        <div
          class="bg-gray-100 flex justify-end px-3 pb-3 py-3 gap-1 shadow-md border border-t"
        >
          <button
            class="bg-105005 text-white rounded text-sm px-1.5 py-0.5"
            @click="markAllAsRead"
          >
            Set all as read
          </button>
          <!-- <button class="bg-yellow-500 text-white rounded text-sm px-1.5 py-0.5">Go to</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "NotificationModal",
  props: ["unreadNotif"],
  // props: ["content"],
  data() {
    return {
      confirm: false,
      data: null,
    };
  },
  created() {
    this.moment = moment;
  },
  mounted() {
    document.getElementById("divModal").classList.add("modal_open");
    document.getElementById("divModal").classList.remove("modal_close");
  },

  methods: {
    closeModal() {
      document.getElementById("divModal").classList.remove("modal_open");
      document.getElementById("divModal").classList.add("modal_close");
      setTimeout(() => this.$emit("closeModal"), 400);
    },

    confirmRemove() {
      document.getElementById("divModal").classList.remove("modal_open");
      document.getElementById("divModal").classList.add("modal_close");
      setTimeout(() => {
        this.$emit("confirmUnpublished");
        this.$emit("closeModal");
      }, 400);
    },

    markAllAsRead() {
      if (
        this.$store.getters.getUserRole == 1 ||
        this.$store.getters.getUserRole == 2 ||
        this.$store.getters.getUserRole == 3
      ) {
        axios
          .get(
            process.env.VUE_APP_API_SERVER +
              "/notification/getByInstitute/markAllAsRead",
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
              },
            }
          )
          .then(
            (response) => {
              document
                .getElementById("divModal")
                .classList.remove("modal_open");
              document.getElementById("divModal").classList.add("modal_close");
              setTimeout(() => this.$emit("closeModal"), 400);
            },
            (error) => {
              console.log(error);
            }
          );
      } else if (
        this.$store.getters.getUserRole == 5 ||
        this.$store.getters.getUserRole == 4 ||
        this.$store.getters.getUserRole == 6 ||
        this.$store.getters.getUserRole == 7
      ) {
        axios
          .get(
            process.env.VUE_APP_API_SERVER +
              "/notification/getByUser/markAllAsRead",
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
              },
            }
          )
          .then(
            (response) => {
              document
                .getElementById("divModal")
                .classList.remove("modal_open");
              document.getElementById("divModal").classList.add("modal_close");
              setTimeout(() => this.$emit("closeModal"), 400);
            },
            (error) => {
              console.log(error);
            }
          );
      }
    },

    redirectNotif(route, notif) {
      document.getElementById("divModal").classList.remove("modal_open");
      document.getElementById("divModal").classList.add("modal_close");
      axios
        .patch(
          process.env.VUE_APP_API_SERVER +
            "/notification/openned/" +
            notif.notify_id,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            if (response.data.status == 200) {
              // NEW USER NOTIF TO DEAN
              if (route == "acc_approval") {
                setTimeout(() => {
                  this.$emit("closeModal");
                  this.$router.push({
                    name: "UserVerify",
                    params: {
                      userid: notif.notify_userID,
                    },
                  });
                }, 300);
                setTimeout(() => {
                  this.$parent.$refs.accountVerify.$el.classList.add(
                    "router-link-exact-active"
                  );
                }, 300);
              }
              // NEW USER NOTIF TO LIBRARIAN
              else if (route == "acc_approval2") {
                setTimeout(() => {
                  this.$emit("closeModal");
                  this.$router.push({
                    name: "UserVerify",
                    params: {
                      userid: notif.notify_userID,
                    },
                  });
                }, 300);
                setTimeout(() => {
                  this.$parent.$refs.accountVerify.$el.classList.add(
                    "router-link-exact-active"
                  );
                }, 300);
              }
              // JOURNAL APPROVAL NOTIF TO DEAN
              else if (route == "journal_approval") {
                // redirect notif to userRoleId = 4 (GUEST) userRoleId = 5 (Alumni)
                if (
                  this.$store.getters.getUserRole == 4 ||
                  this.$store.getters.getUserRole == 5 ||
                  this.$store.getters.getUserRole == 6 ||
                  this.$store.getters.getUserRole == 7
                ) {
                  setTimeout(() => {
                    this.$emit("closeModal");
                    this.$router.push({
                      name: "UserMyWork",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$parent.$refs.myWorks.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                } else {
                  setTimeout(() => {
                    this.$emit("closeModal");
                    this.$router.push({
                      name: "UserJournalApproval",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$parent.$refs.approval.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                }
              }
              // JOURNAL APPROVAL NOTIF TO LIBRARIAN
              else if (route == "journal_approval2") {
                // redirect notif to userRoleId = 4 (GUEST) userRoleId = 5 (Alumni)
                if (
                  this.$store.getters.getUserRole == 4 ||
                  this.$store.getters.getUserRole == 5 ||
                  this.$store.getters.getUserRole == 6 ||
                  this.$store.getters.getUserRole == 7
                ) {
                  setTimeout(() => {
                    this.$emit("closeModal");
                    this.$router.push({
                      name: "UserMyWork",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$parent.$refs.myWorks.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                } else {
                  setTimeout(() => {
                    this.$emit("closeModal");
                    this.$router.push({
                      name: "UserJournalApproval",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$parent.$refs.approval.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                }
              }
              // JOURNAL APPROVAL NOTIF TO LIBRARIAN
              else if (route == "journal_approved_user") {
                // redirect notif to userRoleId = 4 (GUEST) userRoleId = 5 (Alumni)
                if (
                  this.$store.getters.getUserRole == 4 ||
                  this.$store.getters.getUserRole == 5 ||
                  this.$store.getters.getUserRole == 6 ||
                  this.$store.getters.getUserRole == 7
                ) {
                  setTimeout(() => {
                    this.$emit("closeModal");
                    this.$router.push({
                      name: "UserMyWork",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$parent.$refs.myWorks.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                } else {
                  setTimeout(() => {
                    this.$emit("closeModal");
                    this.$router.push({
                      name: "UserJournalApproval",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$parent.$refs.approval.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                }
              }
              // superadmin
              else if (route == "journal_approved") {
                // redirect notif to userRoleId = 4 (GUEST) userRoleId = 5 (Alumni)
                if (
                  this.$store.getters.getUserRole == 4 ||
                  this.$store.getters.getUserRole == 5 ||
                  this.$store.getters.getUserRole == 6 ||
                  this.$store.getters.getUserRole == 7
                ) {
                  setTimeout(() => {
                    this.$emit("closeModal");
                    this.$router.push({
                      name: "UserMyWork",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$parent.$refs.myWorks.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                } else {
                  setTimeout(() => {
                    this.$emit("closeModal");
                    this.$router.push({
                      name: "UserJournalApproval",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$parent.$refs.approval.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                }
              }
              // ANNOUNCEMENT APPROVAL NOTIF TO LIBRARIAN
              if (route == "ann_approval") {
                this.$emit("closeModal");
                setTimeout(() => {
                  this.$router.push({
                    name: "UserAnnouncement",
                    params: {
                      fileID: notif.notify_fileID,
                    },
                  });
                }, 300);
                setTimeout(() => {
                  this.$parent.$refs.userAnnouncement.$el.classList.add(
                    "router-link-exact-active"
                  );
                }, 300);
              }
              // ANNOUNCEMENT APPROVED NOTIF TO DEAN
              if (route == "ann_approved") {
                setTimeout(() => {
                  this.$emit("closeModal");
                  this.$router.push({
                    name: "UserAnnouncement",
                    params: {
                      fileID: notif.notify_fileID,
                    },
                  });
                }, 300);
                setTimeout(() => {
                  this.$parent.$refs.userAnnouncement.$el.classList.add(
                    "router-link-exact-active"
                  );
                }, 300);
              }
              // JOURNAL ENDORSED NOTIF TO LIBRARIAN
              else if (route == "journal_endorsed") {
                // redirect notif to userRoleId = 4 (GUEST) userRoleId = 5 (Alumni)
                if (
                  this.$store.getters.getUserRole == 4 ||
                  this.$store.getters.getUserRole == 5 ||
                  this.$store.getters.getUserRole == 6 ||
                  this.$store.getters.getUserRole == 7
                ) {
                  setTimeout(() => {
                    this.$emit("closeModal");
                    this.$router.push({
                      name: "UserMyWork",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$parent.$refs.myWorks.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                } else {
                  setTimeout(() => {
                    this.$emit("closeModal");
                    this.$router.push({
                      name: "UserJournalApproval",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$parent.$refs.approval.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                }
              }
              // JOURNAL ENDORSED NOTIF TO USER
              else if (route == "journal_endorsed_user") {
                // redirect notif to userRoleId = 4 (GUEST) userRoleId = 5 (Alumni)
                if (
                  this.$store.getters.getUserRole == 4 ||
                  this.$store.getters.getUserRole == 5 ||
                  this.$store.getters.getUserRole == 6 ||
                  this.$store.getters.getUserRole == 7
                ) {
                  setTimeout(() => {
                    this.$emit("closeModal");
                    this.$router.push({
                      name: "UserMyWork",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$parent.$refs.myWorks.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                } else {
                  setTimeout(() => {
                    this.$emit("closeModal");
                    this.$router.push({
                      name: "UserJournalApproval",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$parent.$refs.approval.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                }
              }
              // JOURNAL PENDED NOTIF TO USER
              else if (route == "journal_pended_user") {
                // redirect notif to userRoleId = 4 (GUEST) userRoleId = 5 (Alumni)
                if (
                  this.$store.getters.getUserRole == 4 ||
                  this.$store.getters.getUserRole == 5 ||
                  this.$store.getters.getUserRole == 6 ||
                  this.$store.getters.getUserRole == 7
                ) {
                  setTimeout(() => {
                    this.$emit("closeModal");
                    this.$router.push({
                      name: "UserMyWork",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$parent.$refs.myWorks.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                } else {
                  setTimeout(() => {
                    this.$emit("closeModal");
                    this.$router.push({
                      name: "UserJournalApproval",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$parent.$refs.approval.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                }
              }
              // JOURNAL REQUESTS
              else if (route == "journal_permission_request") {
                // redirect notif to userRoleId = 4 (GUEST) userRoleId = 5 (Alumni)
                setTimeout(() => {
                  this.$emit("closeModal");
                  this.$router.push({
                    name: "JournalRequests",
                    params: {
                      fileid: notif.notify_fileID,
                    },
                  });
                }, 300);
                setTimeout(() => {
                  this.$parent.$refs.requests.$el.classList.add(
                    "router-link-exact-active"
                  );
                }, 300);
              }
              // JOURNAL REQUESTS
              else if (route == "journal_permission_granted") {
                // redirect notif to userRoleId = 4 (GUEST) userRoleId = 5 (Alumni)
                setTimeout(() => {
                  this.$emit("closeModal");
                  this.$router.push({
                    name: "UserJournals",
                    params: {
                      fileid: notif.notify_fileID,
                    },
                  });
                }, 300);
                setTimeout(() => {
                  this.$parent.$refs.journal.$el.classList.add(
                    "router-link-exact-active"
                  );
                }, 300);
              }
              // JOURNAL REVOKE
              else if (route == "journal_permission_revoke") {
                // redirect notif to userRoleId = 4 (GUEST) userRoleId = 5 (Alumni)
                setTimeout(() => {
                  this.$emit("closeModal");
                  this.$router.push({
                    name: "UserJournals",
                    params: {
                      fileid: notif.notify_fileID,
                    },
                  });
                }, 300);
                setTimeout(() => {
                  this.$parent.$refs.journal.$el.classList.add(
                    "router-link-exact-active"
                  );
                }, 300);
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
  watch: {
    unreadNotif: {
      handler(val) {
        if (val) {
          this.data = val;
          // console.log(val)
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.mycursor {
  cursor: pointer;
}
</style>