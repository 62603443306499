<template>
  <div class="h-auto" id="bg">
    <div class="pt-44 m-auto h-auto">
      <div class="flex w-9/12 m-auto rounded-md bg-gray-200 px-10 py-10">
        <div class="w-auto md:w-1/2 grid">
          <h1 class="h_1 text-gray-600 mb-4">
            Page
            <span class="text-green-900 font-semibold"> Not Found!</span>
          </h1>
          <p class="text-gray-600 -mt-3">
            Sorry we can't seem to find the page you are looking for.
          </p>
          <p class="text-gray-600 text-xs mt-6">
            You've landed on a URL that doesn't exist. If you think this is an
            error on our part, please
            <span class="text-blue-700 cursor-pointer hover:underline"
              >Let us know.</span
            >
          </p>

          <router-link
            to="/landing/home"
            class="
              uppercase
              w-1/2
              mt-11
              text-center
              bg-yellow-500
              font-semibold
              py-1
              rounded-full
              hover:text-white
            "
            >Take me home</router-link
          >
        </div>
        <div class="w-1/2 hidden md:block lg:block">
          <img
            src="../assets/bg/404_error.png"
            width="400"
            height="400"
            class="absolute -mt-24 ml-10"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",

  created() {},

  methods: {},
};
</script>

<style scoped>
.h_1 {
  font-size: 30px;
}
</style>