<template>
  <div class="flex w-full">
    <div class="w-1/2 grid text-gray-700 font-semibold text-center">
      <h1 class="text-sm text-left dark:text-gray-200">Unpublished Research</h1>
      <h1 class="text-3xl text-left ml-3 dark:text-gray-200">{{ value }}</h1>
    </div>
    <div class="flex justify-center w-1/2">
      <DoughnutChart
        :chartData="chartData"
        :chartOptions="chartOptions"
        :width="100"
        :height="85"
      />
    </div>
  </div>
</template>

<script>
import DoughnutChart from "./DoughnutChart.vue";
export default {
  name: "PublishedChart",
  components: {
    DoughnutChart,
  },
  props: {
    chartDataprop: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chartData: {},
      chartOptions: {
        responsive: true,
        aspectRatio: 1,

        cutoutPercentage: 50, // for data thickens
        legend: {
          display: false,
          position: "top",
          align: "center",
          fullWidth: false,
          maintainAspectRatio: false,
          labels: {
            boxWidth: 5,
            usePointStyle: true, // circle points
          },
        },
        elements: {
          arc: {
            spacing: 15,
            borderRadius: 100,
          },
        },
        layout: {
          padding: {
            top: 0,
            bottom: 0,
          },
        },
        scales: {},
      },
    };
  },

  watch: {
    chartDataprop: {
      handler(val) {
        this.chartData = val;
        // console.log(val)
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
</style>