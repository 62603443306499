<template>
  <div class="w-auto grid pb-6 mx-5">
    <div class="flex justify-between py-1">
      <h1 class="text-l text-gray-600 font-semibold py-1 px-2">
        Publication ( {{ contents.length }} )
      </h1>
      <button
        v-if="viewPdf"
        @click="viewPdf = false"
        class="border border-FFB800 text-FFB800 hover:bg-FFB800 hover:text-white w-14 px-1 h-6 py-1 rounded-lg text-xs"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4 -mt-1 inline"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
          />
        </svg>

        Back
      </button>
    </div>
    <hr />
    <div v-if="!viewPdf">
      <div
        class="papers flex rounded sm:flex md:flex sm:justify-between md:justify-between mt-2 bg-gray-100 px-4 py-2 hover:bg-gray-300"
        v-for="journal in contents"
        :key="journal"
      >
        <div class="grid w-full">
          <h1 class="title text-sm font-semibold w-full ellipsis text-gray-800">
            {{ journal.file_title }}
          </h1>
          <p
            class="text-xs text- gray-500 mt-1"
            v-if="journal.file_isPublished == 1"
          >
            Published on
            {{
              formatDate(
                journal.file_publishedDateMonth,
                journal.file_publishedDateYear
              )
            }}
          </p>
          <p class="text-xs text- gray-500 mt-1" v-else>Unpublished</p>
        </div>
        <div class="w-1/2 flex justify-end py-2">
          <button
            @click="viewJournal(journal)"
            class="mr-2 h-6 bg-transparent hover:bg-FFB800 text-FFB800 font-semibold hover:text-white px-2 text-xs border border-FFB800 hover:border-transparent rounded-md"
          >
            View
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="contents.length == 0"
      class="px-2 pt-5 text-center text-gray-400"
    >
      No uploaded publication.
    </div>
    <div>
      <FileViewer v-if="viewPdf" :filename="filename" class="" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import FileViewer from "../components/FileViewer.vue";
export default {
  name: "Publication",
  components: { FileViewer },
  data() {
    return {
      journals: [],
      pub_cnt: 0,
      viewPdf: false,
      filename: null,
      contents: [],
    };
  },
  mounted() {
    this.getMyJournals();
  },

  methods: {
    // get users journals
    async getMyJournals() {
      this.isLoading = true;
      axios
        .get(process.env.VUE_APP_API_SERVER + "/fileupload/my", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            this.contents = response.data;
            // console.log(response.data);
            this.results = response.data.length;
            this.isLoading = false;
          },
          (error) => {
            console.log(error);
          }
        );
    },

    viewJournal(journal) {
      // console.log(journal);
      this.filename = journal.file_filename;
      this.viewPdf = true;
    },

    formatDate(date, year) {
      var mL = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const mnth = mL[date - 1];
      return mnth + " " + year;
    },
  },
};
</script>

<style scoped>
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>