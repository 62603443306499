<template>
  <div class="w-auto h-auto mx-3 bg-white rounded-md">
    <div
      class="filter_by w-auto md:w-auto overflow-hidden bg-white lg:h-auto md:h-auto py-2 mx-2 m-auto rounded-md"
    >
      <!-- <h4 class="ml-14 font-semibold text-gray-500 text-xs">Filter by</h4> -->
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full md:w-full lg:w-full xl:w-full">
            <div
              class="flex sm:justify-between md:justify-between lg:justify-between xl:justify-between leading-none p-3 md:p-4"
            >
              <div
                class="flex no-underline w-96 text-black"
                href="javascript:void(0)"
              >
                <!-- <img alt="Placeholder" class="block rounded-full h-5 w-5" src="https://picsum.photos/32/32/?random"> -->
                <input
                  type="text"
                  class="font-poppins indent flex-auto py-1 block text-xs font-normal text-gray-700 bg-gray-100 bg-clip-padding border border-solid border-gray-300 rounded md:rounded-md lg:rounded-md transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
                  placeholder="Search for anything"
                  v-model="searchText"
                  @keyup="handleSearching()"
                />

                <div class="ml-2 text-sm md:mr-2 lg:mr-2 xl:mr-2 2xl:mr-2 mt-1">
                  <button @click="autoDisplayJournals" title="Refresh Table">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-7 h-7 text-105005"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div
                class="hidden md:flex no-underline text-grey-darker text-gray-400 hover:text-red-dark"
                href="#"
              >
                <button
                  @click="changeView('listView')"
                  :class="listView ? 'bg-105005' : 'border border-gray-500'"
                  class="p-1 rounded-lg h-10 w-10 mr-1"
                  title="List"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    :class="listView ? 'text-white' : 'text-gray-500'"
                  >
                    <path
                      d="M5.625 3.75a2.625 2.625 0 100 5.25h12.75a2.625 2.625 0 000-5.25H5.625zM3.75 11.25a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75zM3 15.75a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75zM3.75 18.75a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75z"
                    />
                  </svg>
                </button>
                <button
                  @click="changeView('boxView')"
                  :class="listView ? 'border border-gray-500' : 'bg-105005'"
                  class="p-1 rounded-lg h-10 w-10"
                  title="Box"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    :class="listView ? 'text-gray-500' : 'text-white'"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="grid w-full md:flex-wrap md:flex lg:flex lg:flex-wrap -mt-12 md:-mt-0 lg:-mt-0 xl:-mt-0 2xl:-mt-0"
      >
        <div
          class="grid ml-3 xl:ml-1 lg:ml-1 text-left sm:ml-3 my-2 w-1/3 md:ml-6"
        >
          <!-- <h4 class="text-gray-500 font-semibold text-xs md:text-xs">Search</h4> -->
          <!-- <input type="search" class=" font-poppins indent flex-auto py-1 block text-xs font-normal text-gray-700 bg-gray-100 bg-clip-padding border border-solid border-gray-300 rounded md:rounded-md lg:rounded-md transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none " placeholder="Search for anything" v-model="searchText" @keypress.enter="getJournals()" /> -->
        </div>
        &nbsp;&nbsp;
        <button @click="autoDisplayJournals" title="Refresh Table">
          <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-105005"> <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" /> </svg> -->
        </button>
        <div class="mt-2 md:ml-96 lg:translate-x-6 xl:translate-x-6">
          <!-- List Type View / Table -->
          <!-- <button @click="changeView('listView')" :class="listView ? 'bg-105005' : ''" class="border border-105005 hover:border-105005 p-1 rounded-lg" title="List"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-105005" :class="listView ? 'text-white' : ''"> <path d="M5.625 3.75a2.625 2.625 0 100 5.25h12.75a2.625 2.625 0 000-5.25H5.625zM3.75 11.25a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75zM3 15.75a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75zM3.75 18.75a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75z" /> </svg> </button> -->
          <!-- Box Type View -->
          <!-- <button @click="changeView('boxView')" :class="listView ? '' : 'bg-105005'" class="border border-105005 hover:border-105005 p-1 rounded-lg" title="Box"> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-105005" :class="listView ? '' : 'text-white'"> <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" /> </svg> </button> -->
        </div>
        <!-- <div class="grid text-left mx-4 my-2"> <h4 class="text-gray-500 font-semibold text-xs md:text-xs"> Date Published </h4> <div class="grid md:flex lg:flex"> <input type="date" class=" indent flex-auto block py-1 text-xs font-normal text-gray-700 bg-white bg-clip-padding border border-gray-300 rounded md:rounded-l-md lg:rounded-l-md transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none " placeholder="From mm-dd-yyyy" /> <input type="date" class=" indent flex-auto block py-1 text-xs font-normal text-gray-700 bg-white bg-clip-padding border border-gray-300 rounded md:rounded-r-md lg:rounded-r-md transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none " placeholder="From mm-dd-yyyy" /> </div> </div> -->
        <!-- <div class="grid text-left mx-4 my-2"> <h4 class="text-gray-500 font-semibold text-xs md:text-xs"> Area Published </h4> <input type="search" class=" indent flex-auto py-1 block text-xs font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded md:rounded-md lg:rounded-md transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none " placeholder="Search for by area" /> </div> -->
        <!-- <div class="grid text-left mx-4 my-2"> <h4 class="text-gray-500 font-semibold text-xs md:text-xs">Author</h4> <input v-model="author" @keypress.enter="getJournals()" type="search" class=" indent flex-auto block text-xs py-1 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded md:rounded-md lg:rounded-md transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none " placeholder="Search for an author" /> </div> -->
      </div>
    </div>

    <div v-if="isLoading" class="flex justify-center py-8">
      <div class="spinLoader"></div>
    </div>

    <div class="journal-tbl bg-white w-auto m-auto pb-4" v-if="!isLoading">
      <div class="flex flex-col">
        <div class="overflow-x-auto">
          <div class="inline-block min-w-full">
            <div v-if="listView" class="overflow-hidden mx-5 sm:mx-5 md:mx-5">
              <!-- <table class="table-fixed w-11/12 mx-auto mt-4"> <tr class=""> <th v-for="item in table_headers" :key="item" class=" text-xs font-poppins bg-customGreen font-medium text-white border sm:border-none md:border-none lg:border-none xl:border-none px-1 sm:px-6 md:px-6 lg:px-6 xl:px-6 py-3 text-left " > {{ item.title }} </th> </tr> <tbody> <tr v-if="results == 0"> <td colspan="5" class=" text-center font-poppins text-gray-500 italic border-b border-l border-r border-gray-400 text-xs py-1 " > No data found </td> </tr> <tr v-else class=" bg-grey-100 border-b border-l border-r border-gray-400 transition duration-300 ease-in-out " v-for="item in displayedJournals" :key="item" > <td class=" font-poppins px-1 sm:px-6 md:px-6 lg:px-6 xl:px-6 py-1 text-xs font-medium text-gray-600 break-all whitespace-normal " width="30%" > {{ item.title }} </td> <td class=" font-poppins text-xs text-gray-600 text-left font-light px-1 sm:px-6 md:px-6 lg:px-6 xl:px-6 py-1 whitespace-nowrap " width="20%" > {{ formatDate( item.publishedDateMonth, item.publishedDateYear ) }} </td> <td class=" font-poppins text-xs text-gray-600 font-light px-1 sm:px-6 md:px-6 lg:px-6 xl:px-6 py-1 break-all whitespace-normal " width="20%" > {{ item.publishedIn }} </td> <td class=" font-poppins text-xs text-gray-600 font-light px-1 sm:px-6 md:px-6 lg:px-6 xl:px-6 py-1 break-all whitespace-normal " width="20%" > {{ item.mainauthorlname ? item.mainauthorlname + ", " : "" }} {{ item.mainauthorfname }} {{ item.mainauthormname ? item.mainauthormname[0] + "." : "" }} </td> <td class=" text-xs text-gray-600 font-light px-1 sm:px-6 md:px-6 lg:px-6 xl:px-6 py-1 text-center whitespace-nowrap " width="15%" > <button @click="viewJournal(item)" class=" bg-white hover:bg-FFB800 hover:text-white text-FFB800 font-semibold py-1 px-3 text-xs border border-FFB800 rounded shadow " > View </button> </td> </tr> </tbody> </table> -->
              <Table :labels="table_headers" :data="displayedJournals">
                <template v-slot:publishedDate="{ item }">
                  {{
                    item.publishedDateMonth == "" ||
                    item.publishedDateYear == ""
                      ? formatDate(
                          item.publishedDateMonth,
                          item.publishedDateYear
                        )
                      : "-"
                  }}
                </template>
                <template v-slot:author="{ item }">
                  {{ item.mainauthorlname ? item.mainauthorlname + ", " : "" }}
                  {{ item.mainauthorfname }}
                  {{
                    item.mainauthormname ? item.mainauthormname[0] + "." : ""
                  }}
                </template>
                <template v-slot:action="{ item }">
                  <button
                    @click="viewJournal(item)"
                    class="bg-white hover:bg-yellow-500 hover:text-white text-yellow-500 font-semibold py-1 px-3 text-xs border border-yellow-500 rounded shadow"
                  >
                    View
                  </button>
                </template>
              </Table>
              <div
                class="grid font-poppins w-full sm:flex md:flex lg:flex flex-row justify-end md:w-full sm:w-full mx-auto items-center border-l-2 border-b-2 border-r-2 border-gray-400 rounded-bl-lg rounded-br-lg pt-4 pb-4"
              >
                <div
                  class="w-auto sm:w-3/6 md:w-3/6 lg:w-3/6 xl:w-3/6 gap-x-4 mx-3 flex"
                >
                  <div
                    class="hidden text-xs text-gray-600 md:flex lg:flex xl:flex 2xl:flex w-1/3 md:w-2/5 items-center"
                  >
                    <label for="" class="w-2/3">Rows per page</label>&nbsp;
                    <select
                      class="w-1/3 border-gray-600 border-b"
                      v-model="perPage"
                    >
                      <option
                        :value="item"
                        v-for="item in selectPerPage"
                        :key="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>

                  <div
                    class="hidden md:flex lg:flex xl:flex 2xl:flex w-1/3 md:w-2/5"
                  >
                    <!-- <div class="flex" v-if="results != 0"> -->
                    <span
                      class="text-xs m-auto text-gray-600 dark:text-gray-400"
                    >
                      Showing
                      <span
                        class="font-semibold text-gray-900 dark:text-white"
                        >{{ fromPage }}</span
                      >
                      to
                      <span
                        class="font-semibold text-gray-900 dark:text-white"
                        >{{ toPage }}</span
                      >
                      of
                      <span
                        class="font-semibold text-gray-900 dark:text-white"
                        >{{ results }}</span
                      >
                      Entries
                    </span>
                  </div>

                  <nav class="w-1/3 md:w-1/5">
                    <ul class="flex md:justify-end">
                      <li class="page-item mx-1">
                        <button
                          type="button"
                          class="page-link bg-customGreen text-white rounded-md p-1 text-xs"
                          v-if="page > 1"
                          @click="page--"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>

                        <!--for the disabled button -->
                        <button
                          type="button"
                          class="page-link bg-customGreen rounded-md text-white p-1 text-xs"
                          v-if="page == 1"
                          disabled
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </li>

                      <li class="page-item mx-1">
                        <button
                          type="button"
                          @click="page++"
                          v-if="page < pages.length"
                          class="page-link bg-customGreen rounded-md text-white p-1 text-xs"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>

                        <!--for the disabled button -->
                        <button
                          type="button"
                          disabled
                          v-if="page >= pages.length"
                          class="page-link bg-customGreen rounded-md text-white p-1 text-xs"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div
              v-if="!listView"
              class="overflow-hidden mx-5 sm:mx-5 md:mx-5 text-sm mt-5 mr-10"
            >
              <div
                class="md:my-1 md:px-1 w-full text-center"
                v-if="this.contents.length <= 0"
              >
                <article
                  class="rounded-lg border border-gray-300 w-full py-2 px-2 text-gray-400"
                >
                  No data found.
                </article>
              </div>
              <div class="container">
                <div class="flex flex-wrap md:flex md:flex-wrap">
                  <!-- Column -->
                  <div
                    v-for="c in contents"
                    :key="c"
                    class="my-1 md:px-1 w-full md:w-1/2 lg:my-4 lg:px-3 lg:w-1/3 xl:w-1/3"
                  >
                    <!-- Article -->
                    <div
                      class="h-auto md:h-88 w-1/2 md:w-full lg:w-full xl:w-full"
                    >
                      <article
                        class="md:overflow-hidden rounded-lg shadow-lg border border-gray-300 mr-36 md:mr-2 lg:mr-2 xl:mr-2 2xl:mr-2"
                      >
                        <a href="#">
                          <img
                            v-if="c.typeOfArticle == 1"
                            alt="Placeholder"
                            class="block h-36 w-full rounded-t-lg"
                            src="../assets/bg2/1.jpg"
                          />
                          <img
                            v-if="c.typeOfArticle == 2"
                            alt="Placeholder"
                            class="block h-36 w-full rounded-t-lg"
                            src="../assets/bg2/2.jpg"
                          />
                          <img
                            v-if="c.typeOfArticle == 3"
                            alt="Placeholder"
                            class="block h-36 w-full rounded-t-lg"
                            src="../assets/bg2/3.jpg"
                          />
                          <img
                            v-if="c.typeOfArticle == 4"
                            alt="Placeholder"
                            class="block h-36 w-full rounded-t-lg"
                            src="../assets/bg2/4.jpg"
                          />
                          <img
                            v-if="c.typeOfArticle == 5"
                            alt="Placeholder"
                            class="block h-36 w-full rounded-t-lg"
                            src="../assets/bg2/5.jpg"
                          />
                          <img
                            v-if="c.typeOfArticle == 6"
                            alt="Placeholder"
                            class="block h-36 w-full rounded-t-lg"
                            src="../assets/bg2/6.jpg"
                          />
                          <!-- <img alt="Placeholder" class="block h-auto w-full" src="https://picsum.photos/600/400/?random"> -->
                        </a>

                        <div
                          class="flex items-center justify-between leading-tight p-2 md:p-4 border-t"
                        >
                          <div
                            @click="viewJournal(c)"
                            class="no-underline truncate text-lg hover:underline"
                          >
                            {{ c.title }}
                          </div>
                        </div>
                        <!-- <header class="flex items-center justify-between leading-tight p-2 md:p-4 border-t">
                        
                        <h1 class="text-lg">
                          <div class="no-underline truncate hover:underline text-black" @click="viewJournal(c)">
                            {{ c.title }}
                          </div>
                          
                        </h1>
                      </header> -->
                        <span
                          class="bg-blue-100 text-blue-800 text-xs font-semibold px-1 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3"
                          >{{
                            c.typeOfArticle == 1
                              ? "Thesis"
                              : c.typeOfArticle == 2
                              ? "Capstone"
                              : c.typeOfArticle == 3
                              ? "Dissertation"
                              : c.typeOfArticle == 4
                              ? "Research"
                              : c.typeOfArticle == 5
                              ? "Journal"
                              : c.typeOfArticle == 6
                              ? "Others"
                              : ""
                          }}
                        </span>
                        <span
                          :class="
                            c.isPublished == 1
                              ? 'bg-green-100 text-green-800'
                              : c.isPublished == 0
                              ? 'bg-red-100 text-red-800'
                              : ''
                          "
                          class="text-xs font-semibold mr-1 px-1 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-1"
                          >{{
                            c.isPublished == 1
                              ? "Published"
                              : c.isPublished == 0
                              ? "Unpublished"
                              : ""
                          }}
                        </span>
                        <!-- <span
                          class="bg-yellow-100 text-yellow-800 text-xs font-semibold mr-1 px-1 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">{{
                            getCat(c.categories) }}
                        </span> -->
                        <div
                          class="text-gray-600 text-xs font-semibold mr-3 py-0.5 text-justify pl-1 ml-3 mt-1 truncate"
                        >
                          Keywords: {{ c.authorsKeywords }}
                        </div>
                        <footer
                          class="flex items-center justify-between leading-none p-2 md:p-4"
                        >
                          <a
                            class="flex items-center no-underline text-black"
                            href="javascript:void(0)"
                          >
                            <!-- <img alt="Placeholder" class="block rounded-full h-5 w-5" src="https://picsum.photos/32/32/?random"> -->
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-5 h-5 text-105005"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                            </svg>

                            <p class="ml-2 text-xs">
                              {{ c.mainauthorfname + " " + c.mainauthorlname }}
                            </p>
                          </a>
                          <a
                            class="no-underline text-grey-darker text-gray-400 hover:text-red-dark"
                            href="#"
                          >
                            {{
                              formatDate(
                                c.publishedDateMonth,
                                c.publishedDateYear
                              )
                            }}
                            <!-- <span class="hidden">Like</span>
                          <i class="fa fa-heart"></i> -->
                          </a>
                        </footer>
                      </article>
                    </div>
                    <!-- END Article -->
                  </div>
                  <!-- END Column -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Table from "../components/Table.vue";
import { useToast } from "vue-toastification";
export default {
  name: "JournalTable",
  props: ["searchval", "isDataShow"],
  components: { Table },
  data() {
    return {
      isLogged: true,
      isLoading: false,
      contents: [],
      searchText: null,
      searchText2: null,
      author: null,
      page: 1,
      perPage: 10,
      selectPerPage: [10, 15, 20, 30, 50],
      pages: [],
      percentage: null,
      table_headers: [
        { text: "Research Title", field: "title" },
        { text: "Date Pubished", field: "publishedDate" },
        { text: "Area Published", field: "publishedIn" },
        { text: "Author", field: "author" },
        { text: "Action", field: "action" },
      ],
      fromPage: 1,
      toPage: null,
      results: null,
      listView: true,
      filteredData: [],
      tempNum: null,
      isActive: false,
    };
  },

  methods: {
    getCat: function (cat) {
      // console.log(cat + " " + cat.length);
      // console.log(cat.slice(2, 3))
      // console.log(cat.split(",").map(function (number) {
      //   return '' + number.trim();
      // }).join(","));
      // 5 = 1 cat, single digit
      // 9 = 2 cat, single digit
      // 14 = 3 cat, single digit
      // 19 = 4 cat, single digit
      // console.log(cat.slice(6, 7))
      // console.log(cat.slice(10, 11))
      // console.log(cat.slice(14, 15))
      // axios
      //   .get(
      //     process.env.VUE_APP_API_SERVER +
      //     "/fileupload/getCategoryDesc/" +
      //     cat,
      //     {
      //       headers: {
      //         Authorization: `Bearer ${this.$store.getters.getToken}`,
      //       },
      //     }
      //   )
      //   .then(
      //     (response) => {
      //       // this.contents = response.data;
      //       console.log(response)
      //       // this.results = response.data.length;
      //       // this.isLoading = false;
      //     },
      //     (error) => {
      //       console.log(error);
      //     }
      //   );
    },
    myFilter: function (id) {
      // console.log(id)
      this.isActive = !this.isActive;
      // some code to filter users
      // console.log(id)
      this.tempNum = id;
    },
    changeView: function (n) {
      if (n == "listView") {
        this.listView = true;
      } else if (n == "boxView") {
        this.listView = false;
      }
    },
    init() {
      // this.autoDisplayJournals();
      this.contents = [];
      this.page = 1;
      this.perPage = 10;
      this.pages = [];
      this.fromPage = 1;
      this.toPage = null;
      this.results = null;
    },

    //load approved journals
    async getJournals() {
      this.isLoading = true;
      setTimeout(() => (this.isLoading = false), 3000);
      // if (this.searchText && this.author) {
      //   axios
      //     .get(
      //       process.env.VUE_APP_API_SERVER +
      //         "/fileupload/search/" +
      //         this.searchText +
      //         "/" +
      //         this.author,
      //       {
      //         headers: {
      //           Authorization: `Bearer ${this.$store.getters.getToken}`,
      //         },
      //       }
      //     )
      //     .then(
      //       (response) => {
      //         console.log("1");

      //         this.contents = response.data;
      //         this.results = response.data.length;
      //         this.isLoading = false;
      //       },
      //       (error) => {
      //         console.log(error);
      //       }
      //     );
      // }
      if (this.searchText && !this.author) {
        axios
          .get(
            process.env.VUE_APP_API_SERVER +
              "/fileupload/search/" +
              this.searchText +
              "/" +
              null,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
              },
            }
          )
          .then(
            (response) => {
              this.contents = response.data;
              this.results = response.data.length;

              this.isLoading = false;
            },
            (error) => {
              console.log(error);
            }
          );
      }
      // else if (!this.searchText && this.author) {
      //   axios
      //     .get(
      //       process.env.VUE_APP_API_SERVER +
      //         "/fileupload/search/" +
      //         null +
      //         "/" +
      //         this.author,
      //       {
      //         headers: {
      //           Authorization: `Bearer ${this.$store.getters.getToken}`,
      //         },
      //       }
      //     )
      //     .then(
      //       (response) => {
      //         console.log("3");

      //         this.contents = response.data;
      //         this.results = response.data.length;
      //         this.isLoading = false;
      //       },
      //       (error) => {
      //         console.log(error);
      //       }
      //     );
      // }
      else if (!this.searchText && !this.author) {
        axios
          .get(
            process.env.VUE_APP_API_SERVER +
              "/fileupload/search/" +
              null +
              "/" +
              null,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
              },
            }
          )
          .then(
            (response) => {
              this.contents = response.data;

              this.results = response.data.length;
              this.isLoading = false;
            },
            (error) => {
              console.log(error);
            }
          );
      }
    },

    autoDisplayJournals() {
      this.searchText = "";
      this.isLoading = true;
      setTimeout(() => (this.isLoading = false), 3000);
      axios
        .get(
          process.env.VUE_APP_API_SERVER + "/fileupload/getAutoDisplayJournal/",
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            this.contents = response.data;

            for (var i = 0; i < response.data.length; i++) {
              // console.log(response.data[i].categories);
              this.getCat(response.data[i].categories);
            }

            this.filteredData = response.data;
            this.results = response.data.length;
            this.isLoading = false;
          },
          (error) => {
            console.log(error);
          }
        );
    },

    viewJournal(data) {
      this.isLoading = true;
      setTimeout(function () {
        this.isLoading = false;
      }, 3000);
      this.$emit("viewJournal", data);
      this.$emit("searchText", this.searchText);
      // console.log(item);
    },

    getProfilePercentage() {
      axios
        .get(process.env.VUE_APP_API_SERVER + "/users-controller", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            // console.log(response);
            var cnt = 0;

            if (
              response.data.userdetail.fname != null &&
              response.data.userdetail.fname != ""
            ) {
              cnt += 1;
            }
            if (
              response.data.userdetail.lname != null &&
              response.data.userdetail.lname != ""
            ) {
              cnt += 1;
            }
            if (
              response.data.userdetail.educ_qual != null &&
              response.data.userdetail.educ_qual != ""
            ) {
              cnt += 1;
            }
            if (
              response.data.userdetail.name_of_school != null &&
              response.data.userdetail.name_of_school != ""
            ) {
              cnt += 1;
            }
            // if (
            //   response.data.position != null &&
            //   response.data.position != ""
            // ) {
            //   cnt += 1;
            // }
            if (
              response.data.userdetail.office != null &&
              response.data.userdetail.office != ""
            ) {
              cnt += 1;
            }
            if (
              response.data.userdetail.designation != null &&
              response.data.userdetail.designation != ""
            ) {
              cnt += 1;
            }

            this.percentage = (parseInt(cnt) / 6) * 100;
            this.percentage = this.percentage.toFixed(2);
          },
          (error) => {
            console.log(error);
          }
        );
    },

    // format date to display
    formatDate(date, year) {
      var mnthArr = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      if (date == null || date == "") {
        return year;
      } else {
        return mnthArr[date] + " " + year;
      }
    },

    // set number of pages to paginate
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(
        parseInt(this.contents.length) / this.perPage
      );
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    // handles the from and to of pagination table
    paginate(contents) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;

      if (to > this.contents.length) {
        this.fromPage = from + 1;
        this.toPage = this.contents.length;
      } else {
        this.fromPage = from + 1;
        this.toPage = to;
      }

      return contents.slice(from, to);
    },

    handleSearching() {
      if (this.searchText == "" || !this.searchText) {
        this.autoDisplayJournals();
      } else {
        var n = null;
        var nn = null;
        if (
          this.searchText == "thesis" ||
          this.searchText == "Thesis" ||
          this.searchText == "THESIS" ||
          this.searchText == "thes" ||
          this.searchText == "Thes" ||
          this.searchText == "THES"
        ) {
          n = 1;
        }

        if (
          this.searchText == "capstone" ||
          this.searchText == "Capstone" ||
          this.searchText == "CAPSTONE" ||
          this.searchText == "cap" ||
          this.searchText == "Cap" ||
          this.searchText == "CAP"
        ) {
          n = 2;
        }

        if (
          this.searchText == "dissertation" ||
          this.searchText == "Dissertation" ||
          this.searchText == "DISSERTATION"
        ) {
          n = 3;
        }

        if (
          this.searchText == "research" ||
          this.searchText == "Research" ||
          this.searchText == "RESEARCH"
        ) {
          n = 4;
        }

        if (
          this.searchText == "journal" ||
          this.searchText == "Journal" ||
          this.searchText == "JOURNAL"
        ) {
          n = 5;
        }

        if (
          this.searchText == "others" ||
          this.searchText == "Others" ||
          this.searchText == "OTHERS"
        ) {
          n = 6;
        }

        if (
          this.searchText == "published" ||
          this.searchText == "Published" ||
          this.searchText == "PUBLISHED" ||
          this.searchText == "publish" ||
          this.searchText == "Publish" ||
          this.searchText == "PUBLISH" ||
          this.searchText == "pub" ||
          this.searchText == "PUB" ||
          this.searchText == "Pub" ||
          this.searchText == "pu" ||
          this.searchText == "Pu" ||
          this.searchText == "PU"
        ) {
          nn = 1;
        }

        if (
          this.searchText == "unpublished" ||
          this.searchText == "Unpublished" ||
          this.searchText == "UNPUBLISHED" ||
          this.searchText == "unpublish" ||
          this.searchText == "Unpublish" ||
          this.searchText == "UNPUBLISH" ||
          this.searchText == "not yet publish" ||
          this.searchText == "Not Yet Publish" ||
          this.searchText == "NOT YET PUBLISH" ||
          this.searchText == "not yet published" ||
          this.searchText == "Not Yet Published" ||
          this.searchText == "NOT YET PUBLISHED" ||
          this.searchText == "un" ||
          this.searchText == "UN" ||
          this.searchText == "Un"
        ) {
          nn = 0;
        }

        this.contents = this.filteredData.filter(
          (data) =>
            data.title.toLowerCase().indexOf(this.searchText.toLowerCase()) !==
              -1 ||
            data.mainauthorfname
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) !== -1 ||
            data.mainauthorlname
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) !== -1 ||
            data.authorsKeywords
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) !== -1 ||
            data.typeOfArticle.toString().indexOf(n) !== -1 ||
            data.isPublished.toString().indexOf(nn) !== -1
        );
      }
    },
  },
  computed: {
    displayedJournals() {
      // console.log(this.paginate(this.contents))
      return this.paginate(this.contents);
    },
    checkProfilePercentage() {
      return this.percentage == 100;
      // console.log(this.percentage);
    },
  },
  mounted() {
    this.autoDisplayJournals();
  },
  watch: {
    contents() {
      this.setPages();
    },

    perPage() {
      this.page = 1;
      this.setPages();
    },
    searchval: {
      handler(value) {
        this.searchText = value;
        // this.autoDisplayJournals();
        this.getJournals();
        this.setPages();
      },
      immediate: true,
    },
  },
  created() {
    this.getJournals();
    this.autoDisplayJournals();
    this.setPages();
    this.getProfilePercentage();
  },
};
</script>

<style scoped>
.tbl_header {
  background-color: #105005;
}

.indent {
  text-indent: 0.5rem;
}

.customSearchWidth {
  width: 100%;
}

/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
