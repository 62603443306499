<template>
  <div class="w-1/4 m-auto py-40">
    <div class="bg-white rounded-xl p-8">
      <form method="POST" @submit.prevent class="mt-4">
        <div>
          <h1 class="italic text-gray-600 text-sm -mt-4 mb-4">
            Please enter your new password
          </h1>
        </div>
        <div class="mb-2">
          <label class="block text-green-700 text-xs mb-2">
            New password
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <div
              class="
                absolute
                inset-y-0
                right-0
                pr-3
                flex
                items-center
                pointer-events-none
              "
            >
              <svg
                class="h-5 w-5 text-gray-400"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                />
              </svg>
            </div>
            <input
              type="password"
              v-model="password"
              class="
                block
                pr-10
                shadow
                appearance-none
                rounded-xl
                w-full
                py-2
                px-4
                text-gray-700
                mb-3
                leading-tight
                focus:outline-none focus:bg-white focus:border-orange-500
                transition
                duration-500
                ease-in-out
              "
            />
          </div>
        </div>
        <div class="mb-2 mt-6">
          <label class="block text-green-700 text-xs mb-2">
            Confirm password
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <div
              class="
                absolute
                inset-y-0
                right-0
                pr-3
                flex
                items-center
                pointer-events-none
              "
            >
              <svg
                class="h-5 w-5 text-gray-400"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                />
              </svg>
            </div>
            <input
              type="password"
              v-model="confirm_password"
              class="
                block
                pr-10
                shadow
                appearance-none
                rounded-xl
                w-full
                py-2
                px-4
                text-gray-700
                mb-3
                leading-tight
                focus:outline-none focus:bg-white focus:border-orange-500
                transition
                duration-500
                ease-in-out
              "
            />
          </div>
        </div>

        <div class="mb-2">
          <div class="grid w-full m-auto text-center">
            <div>
              <button
                @click="save"
                class="
                  bg-yellow-500
                  w-full
                  py-2
                  mt-4
                  font-md
                  rounded-xl
                  text-white
                "
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <Spinner v-if="isLoading" />
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
// import LoadingScreen from "../components/LoadingScreen.vue";
import Spinner from "../Spinner.vue";

export default {
  name: "ChangePassword",
  data() {
    return {
      password: null,
      password_err: false,
      confirm_password: null,
      isLoading: false,
    };
  },
  components: {
    Spinner,
  },

  methods: {
    save() {
      this.isLoading = true;
      const toast = useToast();

      if (!this.password) {
        toast.error("Password is required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.isLoading = false;
      } else {
        const regex =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/;
        if (!regex.test(this.password)) {
          // insert axios call here
          toast.error(
            "Must contain an uppercase, lowercase, alphanumeric and special characters and must be 8 characters or higher",
            {
              position: "top-right",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false,
            }
          );
          this.isLoading = false;
        } else {
          if (!this.confirm_password) {
            toast.error("Confirm password is required", {
              position: "top-right",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            this.isLoading = false;
          } else {
            if (this.password != this.confirm_password) {
              toast.error("Password do not match", {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
              this.isLoading = false;
            } else {
              // insert axios call here
              const fd = {
                email: this.$store.getters.getUserEmail,
                password: this.password,
              };

              axios
                .post(process.env.VUE_APP_API_SERVER + "/auth/updateAuth", fd)
                .then(
                  (response) => {
                    // console.log(response.data);
                    toast.success(
                      "Password successfully updated, you may now login using your new password.",
                      {
                        position: "top-right",
                        timeout: 3000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: false,
                        closeButton: "button",
                        icon: true,
                        rtl: false,
                      }
                    );

                    setTimeout(() => {
                      this.$store.commit("setUserEmail", null);
                      this.$router.push("/signin/login/");
                      this.isLoading = false;
                    }, 3000);
                  },
                  (error) => {
                    this.isLoading = false;
                    alert(error);
                  }
                );
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.bgGreen {
  background-color: #105005;
}

.zIndex {
  z-index: -1;
}
</style>