<template>
    <div id="defaultModal" tabindex="-1" aria-hidden="true" class="
      bgModal
      fixed
      top-0
      left-0
      right-0
      z-50
      w-full
      overflow-x-hidden overflow-y-auto
      md:inset-0
      h-screen
      md:h-full
      justify-center
      items-center
    ">
        <div class="relative mt-10 m-auto w-full h-full max-w-md p-4 md:h-auto">
            <div class="
          modal
          relative
          bg-white
          overflow-hidden
          rounded-lg
          shadow
          dark:bg-gray-700
          animated
          modal_open
        ">
                <!-- modal header -->
                <div class="flex justify-between border-b py-3 px-4 bg-105005">
                    <h1 class="text-white text-sm font-poppins font-semibold">Endorse this research</h1>
                    <button type="button" @click="closeModal" class="
              absolute
              top-1
              right-2.5
              text-gray-400
              bg-transparent
              hover:bg-gray-200 hover:text-gray-900
              rounded-lg
              text-sm
              p-1.5
              ml-auto
              inline-flex
              items-center
              dark:hover:bg-gray-800 dark:hover:text-white
            " data-modal-toggle="authentication-modal">
                        <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"></path>
                        </svg>
                    </button>
                </div>
                <!-- modal content -->
                <div class="grid items-center">
                    <div class="m-auto px-2 py-5" v-if="!isToEndorse">
                        <img src="../assets/bg/Thinking-face-bro.png" class="w-auto h-auto m-auto" alt="" />
                        <p class="m-auto pb-2 pt-2 text-sm text-gray-800 font-semibold">
                            Do you really want to endorse this document?
                        </p>
                    </div>

                    <!-- approval remarks textarea -->
                    <div class="m-auto py-3" v-if="isToEndorse">
                        <textarea v-model="endorsementRemark" class="
                border
                text-xs
                inputIndent
                py-1
                px-1
                rounded-md
                bg-gray-100
                font-poppins
              " cols="63" rows="10" placeholder="Input remarks here ..."></textarea>
                    </div>
                </div>
                <!-- buttons -->
                <div class="
            flex
            justify-center
            p-4
            space-x-2
            border-t
            rounded-b
            dark:border-gray-600
          ">
                    <button type="button" @click="closeModal" class="
              text-sm text-FF0000
              border border-FF0000
              rounded
              hover:bg-FF0000
              hover:text-white
              hover:border-white
              font-normal
              px-5
              py-1
              focus:z-10
              dark:bg-gray-700
              dark:text-gray-300
              dark:border-gray-500
              dark:hover:text-white
              dark:hover:bg-gray-600
              dark:focus:ring-gray-600
            ">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                class="w-5 h-5 inline">
                <path stroke-linecap="round" stroke-linejoin="round"
                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
                        Close
                    </button>
                    <!-- button to toggle approval remarks textarea -->

                    <button v-if="!isToEndorse" @click="isToEndorse = true" type="button" class="
              font-normal
              text-105005 text-sm
              border border-105005
              rounded
              hover:border-white
              hover:bg-105005
              hover:text-white
              px-5
              py-1
              text-center
              dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
            ">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 inline">
                <path fill-rule="evenodd"
                    d="M4.72 3.97a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L11.69 12 4.72 5.03a.75.75 0 010-1.06zm6 0a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 11-1.06-1.06L17.69 12l-6.97-6.97a.75.75 0 010-1.06z"
                    clip-rule="evenodd" />
            </svg>


                        Endorse
                    </button>
                    <!-- button to save remarks and approve journal -->
                    <button v-if="isToEndorse" @click="Endorse(journaldoc)" type="button" class="
              text-sm text-105005
              border border-105005
              rounded
              hover:bg-105005
              hover:text-white
              hover:border-105005
              font-normal
              px-5
              py-1
              text-center
              dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
            ">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                class="w-5 h-5 inline">
                <path stroke-linecap="round" stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>

                        Save Remarks
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "ApproveDecisionModal",
    props: ["journaldoc"],

    data() {
        return {
            journaldata: null,
            endorsementRemark: null,
            isToEndorse: false,
        };
    },

    methods: {
        closeModal() {
            this.$emit("closeModal");
        },

        // approve journal function
        Endorse(doc) {
            const data = { remarks: this.endorsementRemark };
            const final = Object.assign(doc, data);

            axios
                .post(process.env.VUE_APP_API_SERVER + "/fileupload/endorse", final, {
                    headers: {
                        Authorization: `Bearer ${this.$store.getters.getToken}`,
                    },
                })
                .then(
                    (response) => {
                        // console.log(response);
                        if (response.data.affected == 1) {
                            // emit to ApprovalView
                            this.$emit("endorsed");
                            this.$emit("isUpdatedData");
                            // this.journaldoc.status.id = 4;
                        }
                        else {
                            // console.log(response);
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        },
    },
};
</script>

<style scoped>
.bgGreen {
    color: #105005;
}

.inputIndent {
    text-indent: 10px;
}
</style>