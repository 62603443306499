<template>
  <div
    id="defaultModal"
    tabindex="-1"
    aria-hidden="true"
    class="bgModal fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full justify-center items-center"
  >
    <div class="relative m-auto w-full h-full max-w-6xl p-4 md:h-auto">
      <div
        id="thisModal"
        class="modal relative bg-white overflow-hidden rounded-lg shadow dark:bg-gray-700 animated modal_open"
      >
        <!-- modal header -->
        <div class="flex justify-between border-b py-4 px-1 bg-105005">
          <h1 class="text-sm text-white px-5">
            {{ videoName }}
          </h1>
          <button
            type="button"
            @click="closeModal"
            class="absolute top-2.5 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="authentication-modal"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- modal content -->
        <div class="grid items-center w-full px-6">
          <div
            v-if="video == 1"
            class="flex justify-center items-center w-full md:w-full mt-6 m-auto"
          >
            <video
              class="w-full"
              autoplay
              controls
              controlsList="nodownload"
              oncontextmenu="return false;"
            >
              <source src="../video/video1.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div
            v-if="video == 2"
            class="flex justify-center items-center w-full md:w-full mt-6 m-auto"
          >
            <video
              class="w-full"
              autoplay
              controls
              controlsList="nodownload"
              oncontextmenu="return false;"
            >
              <source src="../video/video2.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div
            v-if="video == 3"
            class="flex justify-center items-center w-full md:w-full mt-6 m-auto"
          >
            <video
              class="w-full"
              autoplay
              controls
              controlsList="nodownload"
              oncontextmenu="return false;"
            >
              <source src="../video/video3.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div
          class="flex justify-end p-4 space-x-2 rounded-b dark:border-gray-600"
        >
          <button
            type="button"
            @click="closeModal"
            class="text-sm text-gray-500 font-normal bg-gray-100 px-5 mr-2 py-1.5 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600 border border-gray-300 rounded hover:bg-gray-500 hover:text-white"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
            
            <script>
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
  name: "UpdateProfilePicture",
  props: ["video", "videoName"],
  data() {
    return {
      fileData: [],
      title: null,
      title_err: false,
      isUploadImage: true,
      imgUrls: [],
      captions: null,
      captions_err: false,
      imgs: null,
      isUpdate: true,
      itemToDelete: [],
      updateAnnouncement: null,
      userID: null,
      srcLink: null,
    };
  },

  methods: {
    closeModal() {
      const bgModal = document.getElementById("thisModal");
      bgModal.classList.remove("modal_open");
      bgModal.classList.add("modal_close");
      setTimeout(() => {
        this.$emit("closeModal");
        bgModal.classList.remove("modal_close");
        bgModal.classList.add("modal_open");
      }, 500);
    },
  },
};
</script>
            
<style scoped>
.bgGreen {
  color: #105005;
}

.inputIndent {
  text-indent: 10px;
}
</style>