<template>
    <div class="w-full">
        <div class="journal-body py-8 px-10 w-full">
            <div class="flex mt-20 -mb-8 justify-center relative h-32">
                <div class="w-6/12 pb-2 h-auto absolute">
                    <div class="flex flex-row py-2">
                        <div class="mx-2 rounded-full -mt-3">
                            <img :src="filePath" class="h-28 w-32 rounded-full border-FFB800 border-2"/>
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                class="w-28 h-28 text-105005 bg-white rounded-full">
                                <path fill-rule="evenodd"
                                    d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                                    clip-rule="evenodd" />
                            </svg> -->
                        </div>
                        <div class="w-full flex justify-between">
                            <div class="mt-2 text-white ml-5">
                                <div class="text-3xl text-FFB800">{{userdetailData.userdetail.fname + ' '+userdetailData.userdetail.lname}}</div>
                                <div class="text-md">{{userdetailData.userdetail.educ_qual}}</div>
                                <span class="text-md">{{ userdetailData.email }}</span>
                            </div>
                            <div class="mt-3 ml-5">
                                <button class="bg-FFB800 text-white text-sm px-5 py-1.5 text-left rounded-lg">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                        class="w-4 h-4 inline">
                                        <path
                                            d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
                                    </svg>
                                    Follow
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full flex justify-center">
            <div class="mt-5 h-auto w-6/12 px-8">
                <div class="text-center leading-7">
                    <div class="text-justify text-gray-500">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo atque reprehenderit velit at
                        illum
                        sequi, a iste tenetur laborum laudantium, quod, debitis iure sint quis voluptate eaque rem sunt?
                        Facere.
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi in voluptatibus eligendi possimus vero
                        vel
                        nisi unde quo, magni exercitationem pariatur quaerat! Voluptatum corporis cupiditate amet
                        repellendus
                        quod voluptate ratione.
                    </div>
                    <!-- <div class="mx-96 px-52 text-justify">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo atque reprehenderit velit at illum
                    sequi, a iste tenetur laborum laudantium, quod, debitis iure sint quis voluptate eaque rem sunt? Facere.
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi in voluptatibus eligendi possimus vero vel
                    nisi unde quo, magni exercitationem pariatur quaerat! Voluptatum corporis cupiditate amet repellendus
                    quod voluptate ratione.
                </div> -->
                    <div class="text-justify mt-8">
                        <table class="table-auto w-full border-collapse">
                            <thead class="bg-gray-100">
                                <tr>
                                    <th class="">Title</th>
                                    <th class="">Co Authors</th>
                                    <th class="">Cited</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>The Sliding Mr. Bones (Next Stop, Pottersville)</td>
                                    <td>Malcolm Lockyer</td>
                                    <td>1961</td>
                                </tr>
                                <tr>
                                    <td>Witchy Woman</td>
                                    <td>The Eagles</td>
                                    <td>1972</td>
                                </tr>
                                <tr>
                                    <td>Shining Star</td>
                                    <td>Earth, Wind, and Fire</td>
                                    <td>1975</td>
                                </tr>
                                <tr>
                                    <td>The Sliding Mr. Bones (Next Stop, Pottersville)</td>
                                    <td>Malcolm Lockyer</td>
                                    <td>1961</td>
                                </tr>
                                <tr>
                                    <td>Witchy Woman</td>
                                    <td>The Eagles</td>
                                    <td>1972</td>
                                </tr>
                                <tr>
                                    <td>Shining Star</td>
                                    <td>Earth, Wind, and Fire</td>
                                    <td>1975</td>
                                </tr>
                                <tr>
                                    <td>The Sliding Mr. Bones (Next Stop, Pottersville)</td>
                                    <td>Malcolm Lockyer</td>
                                    <td>1961</td>
                                </tr>
                                <tr>
                                    <td>Witchy Woman</td>
                                    <td>The Eagles</td>
                                    <td>1972</td>
                                </tr>
                                <tr>
                                    <td>Shining Star</td>
                                    <td>Earth, Wind, and Fire</td>
                                    <td>1975</td>
                                </tr>
                                <tr>
                                    <td>The Sliding Mr. Bones (Next Stop, Pottersville)</td>
                                    <td>Malcolm Lockyer</td>
                                    <td>1961</td>
                                </tr>
                                <tr>
                                    <td>Witchy Woman</td>
                                    <td>The Eagles</td>
                                    <td>1972</td>
                                </tr>
                                <tr>
                                    <td>Shining Star</td>
                                    <td>Earth, Wind, and Fire</td>
                                    <td>1975</td>
                                </tr>
                                <tr>
                                    <td>The Sliding Mr. Bones (Next Stop, Pottersville)</td>
                                    <td>Malcolm Lockyer</td>
                                    <td>1961</td>
                                </tr>
                                <tr>
                                    <td>Witchy Woman</td>
                                    <td>The Eagles</td>
                                    <td>1972</td>
                                </tr>
                                <tr>
                                    <td>Shining Star</td>
                                    <td>Earth, Wind, and Fire</td>
                                    <td>1975</td>
                                </tr>
                                <tr>
                                    <td>The Sliding Mr. Bones (Next Stop, Pottersville)</td>
                                    <td>Malcolm Lockyer</td>
                                    <td>1961</td>
                                </tr>
                                <tr>
                                    <td>Witchy Woman</td>
                                    <td>The Eagles</td>
                                    <td>1972</td>
                                </tr>
                                <tr>
                                    <td>Shining Star</td>
                                    <td>Earth, Wind, and Fire</td>
                                    <td>1975</td>
                                </tr>
                                <tr>
                                    <td>The Sliding Mr. Bones (Next Stop, Pottersville)</td>
                                    <td>Malcolm Lockyer</td>
                                    <td>1961</td>
                                </tr>
                                <tr>
                                    <td>Witchy Woman</td>
                                    <td>The Eagles</td>
                                    <td>1972</td>
                                </tr>
                                <tr>
                                    <td>Shining Star</td>
                                    <td>Earth, Wind, and Fire</td>
                                    <td>1975</td>
                                </tr>
                                <tr>
                                    <td>The Sliding Mr. Bones (Next Stop, Pottersville)</td>
                                    <td>Malcolm Lockyer</td>
                                    <td>1961</td>
                                </tr>
                                <tr>
                                    <td>Witchy Woman</td>
                                    <td>The Eagles</td>
                                    <td>1972</td>
                                </tr>
                                <tr>
                                    <td>Shining Star</td>
                                    <td>Earth, Wind, and Fire</td>
                                    <td>1975</td>
                                </tr>
                                <tr>
                                    <td>The Sliding Mr. Bones (Next Stop, Pottersville)</td>
                                    <td>Malcolm Lockyer</td>
                                    <td>1961</td>
                                </tr>
                                <tr>
                                    <td>Witchy Woman</td>
                                    <td>The Eagles</td>
                                    <td>1972</td>
                                </tr>
                                <tr>
                                    <td>Shining Star</td>
                                    <td>Earth, Wind, and Fire</td>
                                    <td>1975</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- <h1 class="text-green-800 text-6xl font-semibold">Research Database</h1>
                <h1 class="text-FFB800 text-6xl font-semibold -mt-2">
                    Management System
                </h1> -->

                    <div class="flex justify-center md:w-1/2 mx-auto mt-8">

                        <!-- <div class=" relative block w-full sm:w-full md:w-full px-3 mr-4 md:mr-0 ">
                        <span class=" absolute w-auto inset-y-0 left-5 flex text-gray-500 items-center pl-3 pr-2 ">
                            <svg class="h-5 w-5 fill-current" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30"
                                height="30" viewBox="0 0 30 30">
                                <path
                                    d="M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z">
                                </path>
                            </svg>
                        </span>
                        <input type="text" v-model="defaultSearchInp1" @keypress.enter="submitSearch1"
                            class="outline-none pl-10 block py-3.5 px-2 w-full z-20 text-sm text-gray-600 bg-gray-50 rounded-full border-l-gray-50 border-l-2 border border-gray-300 focus:ring-green-600 focus:border-green-600 "
                            placeholder="Search for Title, Author, or Keywords..." required />
                        <button @click="submitSearch1" type="submit"
                            class=" absolute top-0 right-0 py-3.5 px-2 text-xs mt-0.5 mr-3.5 font-semibold bg-FFB800 rounded-full text-black md:w-1/5 w-1/4 border focus:ring-2 focus:outline-none focus:ring-FFB800">
                            SEARCH
                        </button>
                    </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="container1" v-if="!isDefault">
            <LandingFooter />
        </div>

        <div class="container2" v-if="isDefault">
            <LandingFooter />
        </div>

    </div>
</template>

<script>
import PreviewJournalTable from "../components/PreviewJournalTable.vue";
import JournalView from "./JournalView.vue";
import LandingFooter from "./LandingFooter.vue";
import PreviewJournalResults from './PreviewJournalResults.vue';
import axios from "axios";

export default {
    name: "Journals",
    components: {
        PreviewJournalTable,
        PreviewJournalResults,
        JournalView,
        LandingFooter
    },
    data() {
        return {
            view_journal: false,
            journalData: null,
            journalItem: null,
            isDefault: true,
            searchvalue: "",
            defaultSearchInp1: null,
            defaultSearchInp2: null,
            userID: null,
            filePath: null,
            userdetailData: null
        };
    },
    mounted() {
        // this.getDetails();
    },
    created() {
        if (this.$route.params.data) {
            this.userID = this.$route.params.data;
            this.getDetails();
            this.getImage();
        } else {
            this.$router.push("/landing/journals/");
        }
    },
    methods: {
        getImage() {
            axios
                .get(
                    process.env.VUE_APP_API_SERVER +
                    "/users-controller/stream-file3/"+this.userID,
                    {
                        responseType: "blob",
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getToken}`,
                        },
                    }
                )
                .then((response) => {
                    const blob = new Blob([response.data]);
                    var objectURL = URL.createObjectURL(blob);
                    this.filePath = objectURL;
                });
        },
        getDetails() {
            axios
                .get(
                    process.env.VUE_APP_API_SERVER +
                    "/users-controller/getUserdetails/"+this.userID,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getToken}`,
                        },
                    }
                )
                .then((response) => {
                    this.userdetailData = response.data;
                    console.log(response.data)
                    // const blob = new Blob([response.data]);
                    // var objectURL = URL.createObjectURL(blob);
                    // this.filePath = objectURL;
                });
        },
        ViewJournal(item) {
            this.journalItem = item;
            this.view_journal = true;
        },
        ViewJournalTable() {
            this.view_journal = false;
        },
        searchJournal() {
            if (this.searchvalue == null || this.searchvalue == "") {
                this.isDefault = true;
                return;
            } else {
                this.isDefault = false;
            }
        },

        // handles searching
        submitSearch1() {
            if (this.isDefault) {
                this.isDefault = false;
                this.searchvalue = this.defaultSearchInp1;
                this.defaultSearchInp2 = this.defaultSearchInp1;
            }
            // if (e.target.value == "") {

            //   // if (this.defaultSearchInp == null || this.defaultSearchInp == "") {
            //     return;
            //   // }
            //   // else {
            //   //   this.isDefault = false;
            //   //   this.searchvalue = this.defaultSearchInp;
            //   //   this.defaultSearchInp = this.defaultSearchInp;
            //   // }
            // } else {
            //   console.log(e.target);
            //   this.isDefault = false;
            //   this.searchvalue = e.target.value;
            //   this.defaultSearchInp = e.target.value;
            //   // this.defaultSearchInp = null;
            // }
        },

        submitSearch2() {
            if (!this.isDefault) {
                this.isDefault = false;
                this.searchvalue = this.defaultSearchInp2;
            }
        },
    },
};
</script>

<style scoped>
.journal-body {
    background-image: url("../assets/bg/b2.png");
    /* background: url("../assets/bg/Fast_loading-amico_1.png"); */
    background-size: cover;
    background-repeat: no-repeat;
}

.customSearchWidth {
    width: 25rem;
}

.customTransform {
    transform: translate(0%, 50%);
}

.container1 {
    /* background: url("../assets/bg/Fast_loading-amico_1.png"); */
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: bottom right;
}

.container2 {
    /* background: url("../assets/bg/Fast_loading-amico_1.png"); */
    /* margin-top: 9rem; */
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: bottom right;
}</style>