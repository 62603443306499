<template>
  <div class="bg w-screen h-screen">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "ForgotPassword",

  methods: {},
};
</script>

<style>
.bg {
  background-image: url("../assets/bg/Vector.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;
  padding-bottom: 10rem;
}
.h_1 {
  font-size: 58px;
}
</style>