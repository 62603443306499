<template>
    <div class="journal-body">
        <div class="container1">
            <div class="landing-body">
                <div class="flex pt-24">
                    <div class="flex-1 m-auto text-left md:w-1/2 md:mx-32 px-10">
                        <h1 class="text-3xl md:text-5xl font-semibold text-105005 md:text-left text-center">All About ReDMaS
                        </h1>
                        <div class="flex justify-center  mx-auto mt-8 pb-8">
                            <div
                                class="relative block w-full sm:w-full md:w-full  mr-4 md:mr-0 text-gray-500 leading-8 md:leading-10">
                                <ul class="list-decimal text-justify">
                                    <li class="ml-4 pl-2">Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                                        Necessitatibus quo, eaque, et optio culpa nihil mollitia eius ratione enim, quaerat
                                        qui
                                        inventore dolor voluptas? Autem beatae dolorum asperiores tempora minima?</li>
                                    <li class="ml-4 pl-2">Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                                        Necessitatibus quo, eaque, et optio culpa nihil mollitia eius ratione enim, quaerat
                                        qui
                                        inventore dolor voluptas? Autem beatae dolorum asperiores tempora minima?</li>
                                    <li class="ml-4 pl-2">Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                                        Necessitatibus quo, eaque, et optio culpa nihil mollitia eius ratione enim, quaerat
                                        qui
                                        inventore dolor voluptas? Autem beatae dolorum asperiores tempora minima?</li>
                                    <li class="ml-4 pl-2">Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                                        Necessitatibus quo, eaque, et optio culpa nihil mollitia eius ratione enim, quaerat
                                        qui
                                        inventore dolor voluptas? Autem beatae dolorum asperiores tempora minima?</li>
                                </ul>
                                <div class="text-justify">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima temporibus, voluptate
                                    illum
                                    tempore adipisci animi recusandae, magnam porro tenetur culpa nihil ratione obcaecati
                                    perspiciatis libero nesciunt ipsum quibusdam, repudiandae id! Lorem ipsum dolor sit amet
                                    consectetur, adipisicing elit. Eos officia soluta exercitationem provident quaerat
                                    sapiente
                                    assumenda laborum numquam impedit cumque, commodi voluptatum explicabo atque blanditiis
                                    iure
                                    cupiditate rerum tempore magni. Lorem ipsum dolor sit, amet consectetur adipisicing
                                    elit.
                                    Doloribus officiis adipisci sit magni ipsa excepturi neque mollitia temporibus quam. A
                                    aut
                                    beatae fugiat voluptates laudantium dolore enim quibusdam molestiae iste.
                                </div>
                                <div>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima temporibus, voluptate
                                    illum
                                    tempore adipisci animi recusandae, magnam porro tenetur culpa nihil ratione obcaecati
                                    perspiciatis libero nesciunt ipsum quibusdam, repudiandae id!</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container1" v-if="!isDefault">
                <LandingFooter />
            </div>

            <div class="container2" v-if="isDefault">
                <LandingFooter />
            </div>
        </div>
    </div>
</template>

<script>
// import LandingNav from '../components/LandingNav.vue'
import countapi from "countapi-js";
import AOS from "aos";
import axios from "axios";
import LandingFooter from "../components/LandingFooter.vue";
import Announcement from "../components/Announcements.vue";
export default {
    name: "Landing",
    data() {
        return {
            searchval: null,
            mostvieweddata: null,
            mostviewedresults: null,
            total_uploads: 0,
            siteVisitCount: 0,
            explored_count: 0,
        };
    },
    components: {
        // LandingNav,
        LandingFooter,
        Announcement,
    },

    created() {
        if (
            !this.$store.getters.isExpired &&
            this.$store.getters.getIsValidated == 1
        ) {
            this.$router.push("/userspage/dashboard");
        }
        // this.local = localStorage.getItem("vuex");
        this.getMostViewedData();
        this.getFileUploadCount();
        this.getExploredCount();
        // this.setVisitInitValue();
        this.incrementVisit();
    },

    mounted() {
        AOS.init();
    },
    methods: {
        searchBtn() {
            if (this.searchval == null || this.searchval == "") {
                return;
            } else {
                this.$router.push({
                    name: "Journals",
                    params: { data: this.searchval },
                });
            }
        },

        // get most viewed file count
        getMostViewedData() {
            axios
                .get(process.env.VUE_APP_API_SERVER + "/viewedfiles/visitcount")
                .then(
                    (response) => {
                        this.mostvieweddata = response.data;
                        this.mostviewedresults = response.data.length;
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        },
        // get file upload count
        getFileUploadCount() {
            axios
                .get(process.env.VUE_APP_API_SERVER + "/fileupload/fileuploadcount")
                .then(
                    (response) => {
                        // console.log();
                        if (
                            response.data[0].total_uploads != "" ||
                            response.data[0].total_uploads != null
                        ) {
                            this.total_uploads = response.data[0].total_uploads;
                        } else {
                            this.total_uploads = 0;
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        },

        getExploredCount() {
            axios
                .get(process.env.VUE_APP_API_SERVER + "/viewedfiles/exploreCount")
                .then(
                    (response) => {
                        if (
                            response.data[0].Count != "" ||
                            response.data[0].Count != null
                        ) {
                            this.explored_count = response.data[0].Count;
                        } else {
                            this.explored_count = 0;
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        },

        getTotalVisits() {
            countapi
                .get("default", "9cec55df-c575-43eb-8f2f-8985989ae7b2")
                .then((result) => {
                    // console.log(result);
                });
        },

        incrementVisit() {
            countapi.hit("9cec55df-c575-43eb-8f2f-8985989ae7b2").then((result) => {
                this.siteVisitCount = result.value;
            });
        },

        setVisitInitValue() {
            countapi.set("global", 0).then((result) => {
                // console.log(result);
            });
        },

        createCount() {
            countapi.create().then((result) => {
                // console.log(result);
            });
        },
    },
};
</script>

<style scoped>
.journal-body {
    background-image: url("../assets/bg/b2.png");
    height: 4rem;
    /* background: url("../assets/bg/Fast_loading-amico_1.png"); */
    background-size: cover;
    background-repeat: no-repeat;
}

.landing-body {
    /* background: url("../assets/bg/hero_banner2.png"); */
    /* background-image: url("../assets/bg/b2.png"); */

    background-repeat: no-repeat;
    background-size: cover;
    /* height: auto; */
}

.customSearchWidth {
    width: 25rem;
}

.h_1,
.h_2 {
    font-size: 60px;
}

.h_3 {
    font-size: 40px;
}

.boxwidth {
    width: 22rem;
}

/*     
    .container1{
        height: 1000px;
    } */
/* .img_icon {
  width: 100px;
} */

.container1 {
    /* background: url("../assets/bg/Fast_loading-amico_1.png"); */
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: bottom right;
}

.container2 {
    /* background: url("../assets/bg/Fast_loading-amico_1.png"); */
    /* margin-top: 9rem; */
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: bottom right;
}

#div4,
.features_div {
    background-color: rgba(107, 114, 128, 0.1);
}

.animate_card {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
}

.feature-card:hover {
    /* transform: translateY(20%); */
    -webkit-animation-name: mymove;
    animation-name: mymove;
}

@keyframes mymove {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(10%);
    }
}</style>