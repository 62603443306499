<template>
  <div class="w-full relative">
    <h4
      class="text-gray-600 font-poppins font-extrabold text-4xl text-center tracking-wide"
    >
      ANNOUNCEMENTS
    </h4>

    <div
      v-if="contents.length == 0"
      class="text-center font-poppins text-gray-600 text-xs mt-4 italic"
    >
      No anouncement has been.
    </div>

    <div class="mt-10 relative">
      <div class="center relative w-full" id="content">
        <div v-if="contents.length == 1">
          <div
            style="margin-left: 33.2%"
            class="whitespace-normal w-full top-5 max-w-md"
            v-for="(items, i) in contents"
            :key="items"
          >
            <div class="px-3 flex">
              <div class="relative">
                <div>
                  <img
                    :src="imgUrl(convertStrToArr(items.filename)[0])"
                    class="block w-96 h-96 rounded-lg"
                    :alt="i"
                  />
                  <header
                    class="centerDes absolute bottom-0 w-auto h-44 overflow-y-auto flex-none block text-justify align-text-top px-9 py-6 text-xs"
                  >
                    <p class="text-lg">{{ items.title }}</p>
                    <p class="captionText">{{ items.caption }}</p>
                    <p :id="'captionText' + i" class="captionText">
                      {{ items.caption }}
                    </p>
                    <span
                      :id="'seeMoreText' + i"
                      class="text-blue-600 hover:underline cursor-pointer"
                      @click="seeMoreFunc(i)"
                      >Read more...</span
                    >
                  </header>
                </div>
                <!-- <div class="absolute w-full overflow-y-scroll bottom-0">
                <p class=" text-xs">{{items.caption}}</p>
              </div> -->
              </div>
            </div>
          </div>
        </div>
        <div v-if="contents.length == 2" style="margin-left: 15%">
          <div
            class="inline-block whitespace-normal w-full top-5 max-w-md"
            v-for="(items, i) in contents"
            :key="items"
          >
            <div class="px-3 flex">
              <div class="relative">
                <div>
                  <img
                    :src="imgUrl(convertStrToArr(items.filename)[0])"
                    class="block w-96 h-96 rounded-lg"
                    :alt="i"
                  />
                  <header
                    class="centerDes absolute bottom-0 w-auto h-44 overflow-y-auto flex-none block text-justify align-text-top px-9 py-6 text-xs"
                  >
                    <p class="text-lg">{{ items.title }}</p>
                    <p class="captionText">{{ items.caption }}</p>
                    <p :id="'captionText' + i" class="captionText">
                      {{ items.caption }}
                    </p>
                    <span
                      :id="'seeMoreText' + i"
                      class="text-blue-600 hover:underline cursor-pointer"
                      @click="seeMoreFunc(i)"
                      >Read more...</span
                    >
                  </header>
                </div>
                <!-- <div class="absolute w-full overflow-y-scroll bottom-0">
                <p class=" text-xs">{{items.caption}}</p>
              </div> -->
              </div>
            </div>
          </div>
        </div>
        <div v-if="contents.length > 2" class="relative">
          <div
            class="inline-block whitespace-normal w-full top-5 max-w-md"
            v-for="(items, i) in contents"
            :key="items"
          >
            <div class="px-3 flex">
              <div class="relative">
                <div>
                  <img
                    :src="imgUrl(convertStrToArr(items.filename)[0])"
                    class="block w-96 h-96 rounded-lg"
                    :alt="i"
                  />
                  <header
                    class="centerDes absolute bottom-0 w-auto h-44 overflow-y-auto flex-none block text-justify align-text-top px-9 py-6 text-xs"
                  >
                    <p class="text-lg font-semibold">{{ items.title }}</p>
                    <p :id="'captionText' + i" class="captionText">
                      {{ items.caption }}
                    </p>
                    <span
                      :id="'seeMoreText' + i"
                      class="text-blue-600 hover:underline cursor-pointer"
                      @click="seeMoreFunc(i)"
                      >Read more...</span
                    >
                  </header>
                </div>
                <!-- <div class="absolute w-full overflow-y-scroll bottom-0">
                <p class=" text-xs">{{items.caption}}</p>
              </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        @click="leftBtnFun"
        class="absolute top-0 left-0 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
        data-carousel-prev
      >
        <span
          class="inline-flex bg-105005 items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none"
        >
          <svg
            class="w-4 h-4 text-white dark:text-gray-800"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 1 1 5l4 4"
            />
          </svg>
          <span class="sr-only">Previous</span>
        </span>
      </button>
      <button
        type="button"
        @click="rightBtnFun"
        class="absolute top-0 right-0 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
        data-carousel-next
      >
        <span
          class="inline-flex bg-105005 items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none"
        >
          <svg
            class="w-4 h-4 text-white dark:text-gray-800"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 9 4-4-4-4"
            />
          </svg>
          <span class="sr-only">Next</span>
        </span>
      </button>
      <!-- <div class="flex justify-between inset-0 w-10 h-10">
        <button
          class="left-0 bg-105005 h-8 rounded-full text-white w-8 absolute top-0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="w-6 h-6"
          >
            <path
              fill-rule="evenodd"
              d="M7.72 12.53a.75.75 0 010-1.06l7.5-7.5a.75.75 0 111.06 1.06L9.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <button
          class="right-0 bg-105005 h-8 w-8 text-white rounded-full absolute top-0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="w-6 h-6"
          >
            <path
              fill-rule="evenodd"
              d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div> -->
      <!-- <div class="">
        <div class="">
          <button
            id="left-button"
            @click="leftBtnFun"
            :class="
              contents.length == 0 ||
              contents.length == 1 ||
              contents.length == 2
                ? 'hidden'
                : 'flex'
            "
            class="bg-red-500 rounded-full absolute carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline -left-16"
            type="button"
            data-bs-slide="prev"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-6 h-6"
            >
              <path
                fill-rule="evenodd"
                d="M7.72 12.53a.75.75 0 010-1.06l7.5-7.5a.75.75 0 111.06 1.06L9.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5z"
                clip-rule="evenodd"
              />
            </svg>
             <span
              class="carousel-control-prev-icon inline-block bg-no-repeat"
              aria-hidden="true"
            ></span> -->
      <!-- <span class="text-black">Previous</span> 
          </button>
        </div>
        <div class="right-0">
          <button
            id="right-button"
            @click="rightBtnFun"
            :class="
              contents.length == 0 ||
              contents.length == 1 ||
              contents.length == 2
                ? 'hidden'
                : 'flex'
            "
            class="rightDes carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline -right-6"
            type="button"
            data-bs-slide="next"
          >
            <span
              class="carousel-control-next-icon inline-block bg-no-repeat"
              aria-hidden="true"
            ></span>
            <span class="text-black">Next</span>
          </button>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ITERATION } from "citeproc";
export default {
  name: "Announcements",
  data() {
    return {
      contents: [],
      hasImage: false,
      imgArr: [],
      len: null,
    };
  },
  mounted() {
    this.loadAnnouncements();
    // const rightBtn = document.querySelector('#right-button');
    // const leftBtn = document.querySelector('#left-button');

    // rightBtn.addEventListener("click", function (event) {
    //   const conent = document.querySelector('#content');
    //   conent.scrollLeft += 500;
    //   sideScroll(conent, 'right', 25, 100, 10);
    //   console.log('ccc')
    //   event.preventDefault();
    // });

    // leftBtn.addEventListener("click", function (event) {
    //   const conent = document.querySelector('#content');
    //   conent.scrollLeft -= 500;
    //   sideScroll(conent, 'left', 25, 100, 10);
    //   console.log('sss')
    //   event.preventDefault();
    // });
  },
  computed: {},
  methods: {
    seeMoreFunc(i) {
      const c = document.querySelector("#captionText" + i);
      c.classList.remove("captionText");
      const s = document.querySelector("#seeMoreText" + i);
      s.classList.add("hidden");
    },
    leftBtnFun() {
      const conent = document.querySelector("#content");
      // conent.scrollLeft += 500;
      conent.scrollBy({
        behavior: "smooth",
      });
      this.sideScroll(conent, "left", 50, 100, 400);
    },
    rightBtnFun() {
      const conent = document.querySelector("#content");
      // conent.scrollLeft -= 500;
      conent.scrollBy({
        behavior: "smooth",
      });
      this.sideScroll(conent, "right", 50, 100, 400);
    },
    sideScroll(element, direction, speed, distance, step) {
      var scrollAmount = 0;
      var slideTimer = setInterval(function () {
        if (direction == "left") {
          element.scrollLeft -= step;
        } else {
          element.scrollLeft += step;
        }
        scrollAmount += step;
        if (scrollAmount >= distance) {
          window.clearInterval(slideTimer);
        }
      }, speed);
    },
    loadAnnouncements() {
      axios
        .get(
          process.env.VUE_APP_API_SERVER +
            "/announcement/announcementToDisplay",
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            // console.log(response.data);
            this.len = response.data.length;
            this.contents = response.data;
          },
          (error) => {
            // console.log(error);
          }
        );
    },

    imgUrl(filename) {
      // console.log(filename);
      // console.log(
      //   process.env.VUE_APP_API_SERVER + "/announcement/stream-file/" + filename
      // );
      return (
        process.env.VUE_APP_API_SERVER + "/announcement/stream-file/" + filename
      );
    },
    getDataSlideTo(cnt) {
      return cnt - 1;
    },
    formatAnnouncementDate(date) {
      var days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

      const d = new Date(date);
      var dd = d.getDate();
      if (dd < 10) {
        dd = "0" + dd;
      }
      var mm = d.getMonth() + 1;
      if (mm < 10) {
        mm = "0" + mm;
      }
      var yy = d.getFullYear();

      var hh = d.getHours() + 1;
      var min = d.getMinutes();
      var sec = d.getSeconds();

      var weekDay = d.getDay();

      return (
        days[weekDay] +
        ", " +
        yy +
        "-" +
        mm +
        "-" +
        sec +
        " " +
        hh +
        ":" +
        min +
        ":" +
        sec
      );
    },

    convertStrToArr(str) {
      if (str) {
        return JSON.parse(str);
      } else {
        return;
      }
    },
  },
};
</script>

<style scoped>
#content {
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}

.leftDes {
  /* background: red; */
  /* height: 30em;
  top: -20%; */
  /* top: -15%;
  height: 30em;
  left: -9.5rem; */
  background: red;
  /* background-image: linear-gradient(
    to left,
    rgba(0, 177, 35, 0),
    rgba(255, 255, 255, 1)
  ); */
}

.rightDes {
  top: -15%;
  height: 30em;
  right: -6.2rem;
  background-image: linear-gradient(
    to right,
    rgba(0, 177, 35, 0),
    rgba(255, 255, 255, 1)
  );
  /* background: red; */
}

.centerDes {
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 1)
  );
}

/* previous btn */
.left {
  position: absolute;
  /* left: 0px; */
  float: right;
  /* width: 30%;
 height: 200px; */
  /* border: 1px solid black; */
}

.center {
  /* float: left;  */
  /* width: 100%; */
  width: 100%;
  /* height: 200px; */
  /* border: 1px solid black; */
  position: relative;
  margin: 1px;
  overflow: hidden;
  /*will change this to hidden later to deny scolling to user*/
  white-space: nowrap;
}

/* next btn */
.right {
  position: absolute;
  /* right: 0px; */
  float: left;
  /* width: 30%;
 height: 200px; */
  /* border: 1px solid black; */
}

.captionText {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>