<template>
  <div class="w-auto h-auto pb-12">
    <div class="
          filter_by
          flex
          w-auto
          md:w-auto
          overflow-hidden
          bg-white
          lg:h-auto
          md:h-auto
          py-2
          mx-3
          m-auto
          rounded-md
        ">
      <div class="grid w-full md:w-1/2 md:grid lg:flex px-5 py-2">
        <input @keyup="handleSearching()" type="text" class="
              indent
              font-poppins
              py-2.5
              md:w-3/4
              w-full
              text-xs
              font-normal
              text-gray-700
              bg-gray-100 bg-clip-padding
              border border-solid border-gray-300
              rounded
              md:rounded-md
              lg:rounded-md
              transition
              ease-in-out
              m-0
              focus:text-gray-700
              focus:bg-white
              focus:border-green-600
              focus:outline-none
            " v-model="searchText" placeholder="Search for anything" />
      </div>
    </div>

    <div class="rounded-lg bg-white w-auto m-auto mt-4 pb-4 mx-3">
      <div class="md:flex flex-col">
        <div class="overflow-x-auto">
          <div class="inline-block min-w-full">
            <div class="overflow-hidden mx-5">
              <!-- Desktop view -->
              <Table class="mt-4 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0" :idName="'logsTable'" :labels="table_headers" :data="displayedLogs">
                <template v-slot:createdAt="{ item }">
                  {{
                    timeToday(formatDate(item.createdAt))
                  }}
                </template>
              </Table>
              <!-- <table id="datatable" class="w-full mx-auto mt-8">
                <tr class="">
                  <th v-for="item in table_headers" :key="item" :data="item.text" class="
                        bg-customGreen
                        text-left
                        px-6
                        text-xs
                        font-poppins font-medium
                        text-white
                        py-3
                      ">
                    {{ item.text }}
                  </th>
                </tr>
                <tbody v-if="results <= 0">
                  <tr>
                    <td colspan="5" class="
                          text-center text-xs text-gray-500
                          italic
                          font-poppins
                          border-b border-l border-r border-t border-gray-400
                        ">
                      No data found.
                    </td>
                  </tr>
                </tbody>

                <tbody v-else class="">
                  <tr class="
                        bg-grey-100
                        border-b border-l border-r border-t border-gray-400
                        transition
                        duration-300
                        ease-in-out
                      " v-for="(item, i) in displayedLogs" :key="i">
                    <td class="
                          flex
                          font-poppins
                          px-6
                          py-1
                          text-left text-xs
                          font-medium
                          text-gray-600
                          break-words
                          whitespace-normal
                        ">
                      {{ item.description }}
                    </td>
                    <td class="
                          font-poppins
                          text-xs text-gray-600
                          font-light
                          px-6
                          py-1
                          text-left
                        ">
                      {{ formatDate(item.createdAt) }}
                    </td>

                    <td class="
                          text-xs text-gray-600
                          font-poppins font-light
                          px-6
                          py-1
                          text-left
                        ">
                      {{ item.action }}
                    </td>
                  </tr>
                </tbody>
              </table> -->

              <div class="
                    grid
                    font-poppins
                    w-full
                    sm:flex
                    md:flex
                    lg:flex
                    flex-row
                    justify-end
                    md:w-full
                    sm:w-full
                    mx-auto
                    items-center
                    border-l border-b border-r border-gray-400
                    rounded-bl-lg rounded-br-lg
                    pt-4
                    pb-4
                  ">
                <div class="
                      w-auto
                      sm:w-3/6
                      md:w-3/6
                      lg:w-3/6
                      xl:w-3/6
                      gap-x-4
                      mx-3
                      flex
                    ">
                  <div class="
                        hidden
                        text-xs text-gray-600
                        md:flex
                        w-1/3
                        md:w-2/5
                        items-center
                      ">
                    <label for="" class="w-2/3">Rows per page</label>&nbsp;
                    <select class="w-1/3 border-gray-600 border-b" v-model="perPage">
                      <option :value="item" v-for="item in selectPerPage" :key="item">
                        {{ item }}
                      </option>
                    </select>
                  </div>

                  <div class="hidden md:flex w-1/3 md:w-2/5">
                    <!-- <div class="flex" v-if="results != 0"> -->
                    <span class="text-xs m-auto text-gray-600 dark:text-gray-400">
                      Showing
                      <span class="font-semibold text-gray-900 dark:text-white">{{ fromPage }}</span>
                      to
                      <span class="font-semibold text-gray-900 dark:text-white">{{ toPage }}</span>
                      of
                      <span class="font-semibold text-gray-900 dark:text-white">{{ results }}</span>
                      Entries
                    </span>
                  </div>

                  <nav class="w-1/3 md:w-1/5">
                    <ul class="flex md:justify-end">
                      <li class="page-item mx-1">
                        <button type="button" class="
                              page-link
                              bg-customGreen
                              text-white
                              rounded-md
                              p-1
                              text-xs
                            " v-if="page > 1" @click="page--">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clip-rule="evenodd" />
                          </svg>
                        </button>

                        <!--for the disabled button -->
                        <button type="button" class="
                              page-link
                              bg-customGreen
                              rounded-md
                              text-white
                              p-1
                              text-xs
                            " v-if="page == 1" disabled>
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clip-rule="evenodd" />
                          </svg>
                        </button>
                      </li>

                      <li class="page-item mx-1">
                        <button type="button" @click="page++" v-if="page < pages.length" class="
                              page-link
                              bg-customGreen
                              rounded-md
                              text-white
                              p-1
                              text-xs
                            ">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clip-rule="evenodd" />
                          </svg>
                        </button>

                        <!--for the disabled button -->
                        <button type="button" disabled v-if="page >= pages.length" class="
                              page-link
                              bg-customGreen
                              rounded-md
                              text-white
                              p-1
                              text-xs
                            ">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clip-rule="evenodd" />
                          </svg>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Table from "./Table.vue";
import moment from "moment";
export default {
  name: "CategoriesTable",
  components: { Table },
  data() {
    return {
      contents: [],
      page: 1,
      isLoading: false,
      modalAction: "Add",
      perPage: 10,
      selectPerPage: [10, 15, 20, 30, 50],
      pages: [],
      fromPage: 1,
      toPage: null,
      results: null,
      searchText: null,
      table_headers: [
        { text: "Description", field: "description" },
        { text: "Date", field: "createdAt" },
        { text: "Action", field: "action" },
      ],
    };
  },

  methods: {
    timeToday(d) {
      var today = new Date(d);
      return moment(today).format('lll');
    },
    init() {
      this.contents = [];
      this.page = 1;
      this.perPage = 10;
      this.pages = [];
      this.fromPage = 1;
      this.toPage = null;
      this.results = null;
    },

    openModal(action) {
      this.modalAction = action;
      this.$emit("toggleCategoryModal", action);
    },

    viewCategory(item) {
      this.$emit("toggleCategoryModal", "Update");
      this.$emit("viewCategory", item);
    },

    // load all user accounts to verify
    loadLogs() {
      axios
        .get(process.env.VUE_APP_API_SERVER + "/logs-data/", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            this.contents = [];
            this.contents = response.data;
            this.results = response.data.length;
            // this.user_accounts = response.data;
            // console.log(this.contents);
          },
          (error) => {
            console.log(error);
          }
        );
    },

    handleSearching() {
      if (this.searchText == "" || !this.searchText) {
        this.loadLogs();
        this.setPages();
      } else {
        this.contents = this.contents.filter((data) =>
          data.description
            ? data.description
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) !== -1 ||
            data.action
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) !== -1
            : ""
        );
        this.results = this.contents.length;

        this.setPages();
      }
    },

    formatNumber(num) {
      if (num <= 9) {
        return "0" + num;
      } else {
        return num;
      }
    },

    formatDate(date) {
      const d = new Date(date);

      const mn = d.getMonth() + 1;
      var day = d.getDate();
      const yr = d.getFullYear();
      const hh = d.getHours();
      const mm = d.getMinutes();
      const ss = d.getSeconds();

      return (
        this.formatNumber(mn) +
        "-" +
        this.formatNumber(day) +
        "-" +
        yr +
        " " +
        this.formatNumber(hh) +
        ":" +
        this.formatNumber(mm) +
        ":" +
        this.formatNumber(ss)
      );
    },
    // set number of pages to paginate
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(
        parseInt(this.contents.length) / this.perPage
      );
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    // handles the from and to of pagination table
    paginate(contents) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      // console.log("page : " + page + "  from : " + from + " to : " + to);
      if (to > this.contents.length) {
        this.fromPage = from + 1;
        this.toPage = this.contents.length;
      } else {
        this.fromPage = from + 1;
        this.toPage = to;
      }

      return contents.slice(from, to);
    },
  },

  computed: {
    displayedLogs() {
      return this.paginate(this.contents);
    },
  },
  watch: {
    contents() {
      this.setPages();
    },

    perPage() {
      this.page = 1;
      this.setPages();
    },
  },
  created() {
    this.moment = moment;
    this.loadLogs();
    this.setPages();
  },
};
</script>

<style scoped>
@media only screen and (max-width: 800px) {

  #datatable table,
  #datatable thead,
  #datatable tbody,
  #datatable td,
  #datatable tr {
    display: block;
  }

  #datatable tr th {
    display: none;
    border-top-color: green;
  }

  #datatable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  #datatable td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    white-space: normal;
    text-align: left;
  }

  #datatable td:before {
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-left: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }

  #datatable td:before {
    content: attr(data);
    text-transform: uppercase;
  }

}

.tbl_header {
  background-color: #105005;
}

.indent {
  text-indent: 0.5rem;
}

/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>