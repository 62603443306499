<template>
  <div class="journal-body">
    <div class="container1">
      <!-- <div class="landing-body"> <div class="flex pt-24 mb-60"> <div class="flex-1 m-auto text-left md:w-1/2 md:mx-32 px-10"> <h1 class="text-3xl md:text-5xl font-semibold text-105005 md:text-left text-center">Announcements </h1> <div class=" border-l border-gray-200 text-gray-500 w-full mt-10 pl-2" v-if="this.len == 0"> No announcement has been posted. </div> <div class="flex justify-center  mx-auto mt-8 pb-8"> <div class="accordion w-full"> <div class="accordion-item" v-for="(item, i) in contents" :key="item"> <button id="accordion-button-1" aria-expanded="false" data-bs-toggle="collapse" :data-bs-target="'#div_' + i"> <span class="accordion-title">{{ item.title }}</span> <span class="text-gray-500 text-xs">{{ this.stringFormatDate(this.formatDate(item.createdAt)) }}</span> <span class="icon" aria-hidden="true"></span> </button> <div class="accordion-content" :id="'div_' + i"> <p> {{ item.caption }} </p> </div> </div> </div> </div> </div> </div> </div> -->
      <div class="landing-body">
        <div class="flex pt-24 mb-10">
          <div class="flex-1 m-auto text-left md:w-1/2 md:mx-32 px-36">
            <h1
              class="text-3xl md:text-5xl font-bold text-105005 md:text-left text-center mt-10 mb-10"
            >
              Announcements
            </h1>
            <!-- <span class="text-gray-500 text-sm">No. of Announcements</span> -->
            <!-- <div class="border-l border-gray-200 text-gray-500 w-full mt-10 pl-2"> No announcement has been posted. </div> -->
            <hr />
            <div
              class="flex mx-auto py-10"
              v-for="(item, i) in contents"
              :key="item"
            >
              <div class="flex flex-row">
                <div class="text-gray-400 text-sm w-1/4 font-semibold">
                  {{ this.stringFormatDate(this.formatDate(item.createdAt)) }}
                </div>
                <div
                  class="w-full md:flex rounded-xl p-8 md:p-0 dark:bg-slate-800"
                >
                  <div class="w-full">
                    <h1 class="text-2xl font-bold">{{ item.title }}</h1>
                    <span class="uppercase text-sm font-semibold text-105005">{{
                      item.user.fname + " " + item.user.lname
                    }}</span>
                    <div class="w-full text-normal my-5 pr-5">
                      <p
                        class="captionText w-full leading-8"
                        :id="'captionText' + i"
                      >
                        {{ item.caption }}
                      </p>
                    </div>
                    <span
                      class="text-sm text-105005 font-semibold hover:underline cursor-pointer"
                      :id="'seeMoreText' + i"
                      @click="seeMoreFunc(i)"
                      >Read more ...</span
                    >
                    <span
                      class="text-sm text-105005 font-semibold hover:underline cursor-pointer hidden"
                      :id="'seeLessText' + i"
                      @click="seeLessFunc(i)"
                      >Read Less ...</span
                    >
                  </div>
                  <img
                    class="w-24 h-24 md:w-48 md:h-auto md:rounded-lg rounded-full mx-auto transition ease-in-out delay-150"
                    :id="'image' + i"
                    :src="imgUrl(convertStrToArr(item.filename)[0])"
                    alt=""
                    width="384"
                    height="512"
                  />
                </div>
              </div>
            </div>
            <div class="w-full flex justify-center">
              <nav class="content-center">
                <ul class="flex md:justify-end">
                  <li class="page-item mx-1">
                    <button
                      type="button"
                      @click="page = 1"
                      v-if="page > 1"
                      class="page-link p-2 text-md mx-0.5 text-gray-500 border rounded-md border-gray-300"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"
                        />
                      </svg>
                    </button>

                    <button
                      type="button"
                      disabled
                      v-if="page == 1"
                      class="page-link p-2 text-md mx-0.5 text-gray-300 border rounded-md border-gray-300"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"
                        />
                      </svg>
                    </button>
                  </li>
                  <li class="page-item mx-1">
                    <button
                      type="button"
                      class="page-link border border-gray-300 text-gray-600 p-2 text-xs rounded-md"
                      v-if="page > 1"
                      @click="page--"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>

                    <!--for the disabled button -->
                    <button
                      type="button"
                      class="page-link border border-gray-300 text-gray-300 p-2 text-xs rounded-md"
                      v-if="page == 1"
                      disabled
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                  </li>

                  <li v-for="(item, index) in items" :key="index">
                    <button
                      type="button"
                      v-if="isNaN(Number(item))"
                      class="page-link p-2 text-md mx-0.5 text-gray-500 border rounded-md border-gray-300"
                    >
                      <p class="h-5 w-5">{{ item }}</p>
                    </button>
                    <!-- <span v-if="isNaN(Number(item))" class="pagination-more">{{ item }}</span> -->
                    <button
                      v-else-if="item == page"
                      type="button"
                      class="page-link p-2 text-md mx-0.5 border-2 border-105005 rounded-md text-105005"
                    >
                      <p class="h-5 w-5 font-semibold">{{ item }}</p>
                    </button>
                    <button
                      v-else
                      type="button"
                      @click="page = item"
                      class="page-link p-2 text-md mx-0.5 text-gray-500 border rounded-md border-gray-300"
                    >
                      <p class="h-5 w-5">{{ item }}</p>
                    </button>

                    <!-- <button v-else type="button" @click="$emit('input', item)">{{ item }}</button> -->
                  </li>
                  <!-- <li> -->

                  <li class="page-item mx-1">
                    <button
                      type="button"
                      @click="page++"
                      v-if="page < pages.length"
                      class="page-link border border-gray-300 text-gray-600 p-2 text-xs rounded-md"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>

                    <!--for the disabled button -->
                    <button
                      type="button"
                      disabled
                      v-if="page >= pages.length"
                      class="page-link border border-gray-300 text-gray-300 p-2 text-xs rounded-md"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                  </li>
                  <li class="page-item mx-1">
                    <button
                      type="button"
                      @click="page = pages.length"
                      v-if="page < pages.length"
                      class="page-link p-2 text-md mx-0.5 text-gray-500 border rounded-md border-gray-300"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
                        />
                      </svg>
                    </button>

                    <button
                      type="button"
                      disabled
                      v-if="page >= pages.length"
                      class="page-link p-2 text-md mx-0.5 text-gray-300 border rounded-md border-gray-300"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
                        />
                      </svg>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div class="flex pt-10 mb-24">
          <div class="flex-1 m-auto text-left md:w-1/2 md:mx-32 px-36">
            <h1
              class="text-3xl md:text-5xl font-bold text-105005 md:text-left text-center mt-10 mb-10"
            >
              Help / Tutorials
            </h1>
            <hr />
            <div class="flex flex-col">
              <div class="overflow-x-auto">
                <div class="inline-block min-w-full">
                  <div class="overflow-hidden mt-5 flex gap-2">
                    <div
                      class="withBGDesign max-w-sm hover:border-yellow-500 w-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
                    >
                      <a href="javascript:void(0)">
                        <h5
                          class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"
                        >
                          Accessing Website and User Registration
                        </h5>
                      </a>
                      <p
                        class="mb-3 font-normal text-gray-700 dark:text-gray-400"
                      >
                        1:09 <span class="px-1">•</span> Video
                      </p>
                      <a
                        href="javascript:void(0)"
                        class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-FFB800 rounded-lg hover:bg-yellow-600 focus:ring-1 focus:outline-none focus:ring-yellow-800"
                        @click="
                          openModal(
                            1,
                            'Accessing Website and User Registration'
                          )
                        "
                      >
                        Learn how
                        <svg
                          class="w-3.5 h-3.5 ml-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 10"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M1 5h12m0 0L9 1m4 4L9 9"
                          />
                        </svg>
                      </a>
                    </div>
                    <div
                      class="max-w-sm hover:border-FFB800 w-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
                    >
                      <a href="javascript:void(0)">
                        <h5
                          class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"
                        >
                          Accessing the website using Google Sign In
                        </h5>
                      </a>
                      <p
                        class="mb-3 font-normal text-gray-700 dark:text-gray-400"
                      >
                        1:05
                        <span class="px-1">•</span> Video
                      </p>
                      <a
                        href="javascript:void(0)"
                        class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-FFB800 rounded-lg hover:bg-yellow-600 focus:ring-1 focus:outline-none focus:ring-yellow-800"
                        @click="
                          openModal(
                            2,
                            'Accessing the website using Google Sign In'
                          )
                        "
                      >
                        Learn how
                        <svg
                          class="w-3.5 h-3.5 ml-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 10"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M1 5h12m0 0L9 1m4 4L9 9"
                          />
                        </svg>
                      </a>
                    </div>
                    <div
                      class="max-w-sm hover:border-FFB800 w-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
                    >
                      <a href="javascript:void(0)">
                        <h5
                          class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"
                        >
                          How to upload Articles in ReDMaS
                        </h5>
                      </a>
                      <p
                        class="mb-3 font-normal text-gray-700 dark:text-gray-400"
                      >
                        1:28 <span class="px-1">•</span> Video
                      </p>
                      <a
                        href="javascript:void(0)"
                        class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-FFB800 rounded-lg hover:bg-yellow-600 focus:ring-1 focus:outline-none focus:ring-yellow-800"
                        @click="
                          openModal(3, 'How to upload Articles in ReDMaS')
                        "
                      >
                        Learn how
                        <svg
                          class="w-3.5 h-3.5 ml-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 10"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M1 5h12m0 0L9 1m4 4L9 9"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container1" v-if="!isDefault">
        <LandingFooter />
      </div>

      <div class="container2" v-if="isDefault">
        <LandingFooter />
      </div>
      <UserTutorialModal
        v-if="showModal"
        v-on:closeModal="closeModal"
        :video="video"
        :videoName="videoName"
      />
    </div>
  </div>
</template>

<script>
// import LandingNav from '../components/LandingNav.vue'
import countapi from "countapi-js";
import AOS from "aos";
import axios from "axios";
import LandingFooter from "../components/LandingFooter.vue";
import Announcement from "../components/Announcements.vue";
import UserTutorialModal from "../modals/UserTutorialModal.vue";
export default {
  name: "Landing",
  data() {
    return {
      searchval: null,
      mostvieweddata: null,
      mostviewedresults: null,
      total_uploads: 0,
      siteVisitCount: 0,
      explored_count: 0,
      isDefault: false,
      contents: null,
      len: null,
      page: 1,
      perPage: 10,
      selectPerPage: [10, 15, 20, 30, 50],
      pages: [],
      fromPage: 1,
      video: 1,
      videoName: null,
      showModal: false,
    };
  },
  components: {
    // LandingNav,
    LandingFooter,
    Announcement,
    UserTutorialModal,
  },

  created() {
    if (
      !this.$store.getters.isExpired &&
      this.$store.getters.getIsValidated == 1
    ) {
      this.$router.push("/userspage/dashboard");
    }
    // this.local = localStorage.getItem("vuex");
    this.getMostViewedData();
    this.getFileUploadCount();
    this.getExploredCount();
    // this.setVisitInitValue();
    this.incrementVisit();
  },

  mounted() {
    AOS.init();
    this.loadAnnouncements();
    const items = document.querySelectorAll(".accordion button");
    items.forEach((item) =>
      item.addEventListener("click", this.toggleAccordion())
    );
  },
  computed: {
    items() {
      const maxLength = 7;
      if (this.pages.length <= maxLength || maxLength < 1) {
        return this.getRange(1, this.pages.length);
      }

      const even = maxLength % 2 === 0 ? 1 : 0;
      const left = Math.floor(maxLength / 2);
      const right = this.pages.length - left + 1 + even;

      if (this.page > left && this.page < right) {
        const start = this.page - left + 2;
        const end = this.page + left - 2 - even;

        return [
          1,
          "...",
          ...this.getRange(start, end),
          "...",
          this.pages.length,
        ];
      }
      if (this.page === left) {
        const end = this.page + left - 1 - even;

        return [...this.getRange(1, end), "...", this.pages.length];
      }
      if (this.page === right) {
        const start = this.page - left + 1;

        return [1, "...", ...this.getRange(start, this.pages.length)];
      } else {
        return [
          ...this.getRange(1, left),
          "...",
          ...this.getRange(right, this.pages.length),
        ];
      }
    },
  },
  methods: {
    openModal(i, name) {
      this.showModal = true;
      this.videoName = name;
      this.video = i;
    },
    closeModal() {
      this.showModal = false;
      this.videoName = null;
      this.video = 0;
    },
    paginate(contents) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;

      if (to > this.contents.length) {
        this.fromPage = from + 1;
        this.toPage = this.contents.length;
      } else {
        this.fromPage = from + 1;
        this.toPage = to;
      }

      return contents.slice(from, to);
    },
    getRange(from, to) {
      const range = [];

      from = from > 0 ? from : 1;

      for (let i = from; i <= to; i++) {
        range.push(i);
      }

      return range;
    },
    seeLessFunc(i) {
      const c = document.querySelector("#captionText" + i);
      c.classList.add("captionText");
      const sl = document.querySelector("#seeLessText" + i);
      sl.classList.add("hidden");
      const s = document.querySelector("#seeMoreText" + i);
      s.classList.remove("hidden");
      const img = document.querySelector("#image" + i);
      img.classList.remove("hidden");
    },
    seeMoreFunc(i) {
      const c = document.querySelector("#captionText" + i);
      c.classList.remove("captionText");
      const s = document.querySelector("#seeMoreText" + i);
      s.classList.add("hidden");
      const img = document.querySelector("#image" + i);
      img.classList.add("hidden");
      const sl = document.querySelector("#seeLessText" + i);
      sl.classList.remove("hidden");
    },
    formatNumber(num) {
      if (num <= 9) {
        return "0" + num;
      } else {
        return num;
      }
    },

    stringFormatDate(date) {
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(date).toLocaleDateString("en", options);
    },

    formatDate(date) {
      const d = new Date(date);
      this.timeAgo = d;

      const mn = d.getMonth() + 1;
      var day = d.getDate();
      const yr = d.getFullYear();
      const hh = d.getHours();
      const mm = d.getMinutes();
      const ss = d.getSeconds();

      return (
        this.formatNumber(mn) +
        "-" +
        this.formatNumber(day) +
        "-" +
        yr +
        " " +
        this.formatNumber(hh) +
        ":" +
        this.formatNumber(mm) +
        ":" +
        this.formatNumber(ss)
      );
    },
    loadAnnouncements() {
      axios
        .get(
          process.env.VUE_APP_API_SERVER +
            "/announcement/announcementToDisplay",
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            // console.log(response.data);
            this.contents = response.data;
            this.len = response.data.length;
          },
          (error) => {
            // console.log(error);
          }
        );
    },
    convertStrToArr(str) {
      if (str) {
        return JSON.parse(str);
      } else {
        return;
      }
    },

    imgUrl(filename) {
      // console.log(filename);
      // console.log(
      //   process.env.VUE_APP_API_SERVER + "/announcement/stream-file/" + filename
      // );
      return (
        process.env.VUE_APP_API_SERVER + "/announcement/stream-file/" + filename
      );
    },
    searchBtn() {
      if (this.searchval == null || this.searchval == "") {
        return;
      } else {
        this.$router.push({
          name: "Journals",
          params: { data: this.searchval },
        });
      }
    },

    // get most viewed file count
    getMostViewedData() {
      axios
        .get(process.env.VUE_APP_API_SERVER + "/viewedfiles/visitcount")
        .then(
          (response) => {
            this.mostvieweddata = response.data;
            this.mostviewedresults = response.data.length;
          },
          (error) => {
            console.log(error);
          }
        );
    },
    // get file upload count
    getFileUploadCount() {
      axios
        .get(process.env.VUE_APP_API_SERVER + "/fileupload/fileuploadcount")
        .then(
          (response) => {
            // console.log();
            if (
              response.data[0].total_uploads != "" ||
              response.data[0].total_uploads != null
            ) {
              this.total_uploads = response.data[0].total_uploads;
            } else {
              this.total_uploads = 0;
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },

    getExploredCount() {
      axios
        .get(process.env.VUE_APP_API_SERVER + "/viewedfiles/exploreCount")
        .then(
          (response) => {
            if (
              response.data[0].Count != "" ||
              response.data[0].Count != null
            ) {
              this.explored_count = response.data[0].Count;
            } else {
              this.explored_count = 0;
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },

    getTotalVisits() {
      countapi
        .get("default", "9cec55df-c575-43eb-8f2f-8985989ae7b2")
        .then((result) => {
          // console.log(result);
        });
    },

    incrementVisit() {
      countapi.hit("9cec55df-c575-43eb-8f2f-8985989ae7b2").then((result) => {
        this.siteVisitCount = result.value;
      });
    },

    setVisitInitValue() {
      countapi.set("global", 0).then((result) => {
        // console.log(result);
      });
    },

    createCount() {
      countapi.create().then((result) => {
        // console.log(result);
      });
    },
  },
};
</script>

<style scoped>
.container {
  margin: 0 auto;
  padding: 4rem;
  width: 48rem;
}

.accordion .accordion-item {
  border-bottom: 1px solid #e5e5e5;
}

.accordion .accordion-item button[aria-expanded="true"] {
  border-bottom: 1px solid #ffb008;
}

.accordion button {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  padding: 1em 0;
  color: #105005;
  font-size: 1.15rem;
  font-weight: 400;
  border: none;
  background: none;
  outline: none;
}

.accordion button:hover,
.accordion button:focus {
  cursor: pointer;
  color: #ffb008;
}

.accordion button:hover::after,
.accordion button:focus::after {
  cursor: pointer;
  color: #ffb008;
  border: 1px solid #105005;
}

.accordion button .accordion-title {
  padding: 1em 1.5em 1em 0;
}

.accordion button .icon {
  display: inline-block;
  position: absolute;
  top: 18px;
  right: 0;
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-radius: 22px;
}

.accordion button .icon::before {
  display: block;
  position: absolute;
  content: "";
  top: 9px;
  left: 5px;
  width: 10px;
  height: 2px;
  background: currentColor;
}

.accordion button .icon::after {
  display: block;
  position: absolute;
  content: "";
  top: 5px;
  left: 9px;
  width: 2px;
  height: 10px;
  background: currentColor;
}

.accordion button[aria-expanded="true"] {
  color: #ffb008;
}

.accordion button[aria-expanded="true"] .icon::after {
  width: 0;
}

.accordion button[aria-expanded="true"] + .accordion-content {
  opacity: 1;
  height: auto;
  max-height: 9em;
  overflow-y: auto;
  transition: all 200ms linear;
  will-change: opacity, max-height;
}

.accordion .accordion-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear;
  will-change: opacity, max-height;
}

.accordion .accordion-content p {
  font-size: 1rem;
  font-weight: 300;
  margin: 1em 0;
}

.accordion-content {
  height: auto;
  padding-left: 10px;
  padding-right: 10px;
  text-align: justify;
}

.journal-body {
  background-image: url("../assets/bg/b2.png");
  height: 4rem;
  /* background: url("../assets/bg/Fast_loading-amico_1.png"); */
  background-size: cover;
  background-repeat: no-repeat;
}

.landing-body {
  /* background: url("../assets/bg/hero_banner2.png"); */
  /* background-image: url("../assets/bg/b2.png"); */

  background-repeat: no-repeat;
  background-size: cover;
  /* max-height: fit-content; */
  /* height: auto; */
}

.customSearchWidth {
  width: 25rem;
}

.h_1,
.h_2 {
  font-size: 60px;
}

.h_3 {
  font-size: 40px;
}

.boxwidth {
  width: 22rem;
}

/*     
    .container1{
        height: 1000px;
    } */
/* .img_icon {
  width: 100px;
} */

.container1 {
  /* background: url("../assets/bg/Fast_loading-amico_1.png"); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom right;
}

.container2 {
  /* background: url("../assets/bg/Fast_loading-amico_1.png"); */
  /* margin-top: 9rem; */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom right;
}

#div4,
.features_div {
  background-color: rgba(107, 114, 128, 0.1);
}

.animate_card {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.feature-card:hover {
  /* transform: translateY(20%); */
  -webkit-animation-name: mymove;
  animation-name: mymove;
}

@keyframes mymove {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(10%);
  }
}

.captionText {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
