<template>
  <div
    id="defaultModal"
    tabindex="-1"
    aria-hidden="true"
    class="bgModal fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full justify-center items-center"
  >
    <div class="relative mt-10 m-auto w-full h-full max-w-lg p-4 md:h-auto">
      <div
        id="thisModal"
        class="modal relative bg-white overflow-hidden rounded-lg shadow dark:bg-gray-700 animated modal_open"
      >
        <!-- modal header -->
        <div class="flex justify-between border-b py-4 px-1 bg-105005">
          <h1 class="text-sm text-white px-5">Update Profile Picture</h1>
          <button
            type="button"
            @click="closeModal"
            class="absolute top-2.5 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="authentication-modal"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- modal content -->
        <div class="grid items-center w-full px-6">
          <form enctype="multipart/form-data">
            <!-- <div class="mt-6">
                            <a @click="isUploadImage = true" v-if="!isUploadImage" href="javascript:void(0)"
                                class="text-xs text-gray-600 cursor-pointer hover:underline">Click here to upload
                                image/s</a>
                        </div> -->
            <div
              class="flex justify-center items-center w-full md:w-full mt-6 mb-6 m-auto"
              :class="imgUrls.length > 0 ? 'hidden' : 'visible'"
              v-if="isUploadImage"
            >
              <label
                for="dropzone-file"
                @drop="dropt"
                @dragenter.prevent
                @dragover.prevent
                @drag.prevent
                @change="onFileChange"
                class="flex flex-col justify-center items-center w-full h-40 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div
                  class="flex flex-col justify-center items-center pt-5 pb-6"
                >
                  <svg
                    aria-hidden="true"
                    class="mb-3 w-10 h-10 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    ></path>
                  </svg>
                  <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span class="font-semibold">Click to upload</span> or drag
                    and drop
                  </p>
                  <p class="text-xs text-gray-500 dark:text-gray-400">
                    SVG, PNG, JPG or GIF (MAX. 800x400px)
                  </p>
                </div>
                <input id="dropzone-file" type="file" multiple class="hidden" />
              </label>
            </div>
          </form>
        </div>
        <!-- @click="imgToDelete($event, item.name)" -->
        <div v-for="item in imgUrls" :key="item" class="ml-12 md:ml-28 mt-3">
          <span
            class="inline-flex relative items-center p-3 text-sm font-medium text-center text-white rounded-lg focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <div>
              <img class="w-60 h-60 border" :src="item.url" />
              <div
                @click="imgToDelete(item.name)"
                class="cursor-pointer inline-flex absolute top-1 right-1 justify-center items-center w-6 h-6 text-xs font-bold text-white bg-red-500 rounded-full border-2 border-white dark:border-gray-900"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
          </span>
        </div>

        <!-- buttons -->
        <div
          class="flex justify-end p-4 space-x-2 rounded-b dark:border-gray-600"
        >
          <!-- button to toggle approval remarks textarea -->

          <!-- <button v-if="!isUpdate" @click="save()" type="button"
                        class=" font-normal text-white text-sm px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 border bg-105005 rounded-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 inline">
                            <path fill-rule="evenodd"
                                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                                clip-rule="evenodd" />
                        </svg>
                        Save
                    </button> -->

          <button
            v-if="isUpdate == true"
            @click="update()"
            type="button"
            :disabled="imgUrls.length == 0 ? true : false"
            class="font-normal text-white text-sm px-3 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 border rounded-lg"
            :class="
              imgUrls.length > 0
                ? ' bg-105005 opacity-1 animation'
                : 'bg-gray-700 opacity-1'
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-5 h-5 inline"
            >
              <path
                fill-rule="evenodd"
                d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                clip-rule="evenodd"
              />
            </svg>
            Update
          </button>
          <button
            type="button"
            @click="closeModal"
            class="text-sm text-gray-500 font-normal bg-gray-100 px-3 py-1 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600 border border-gray-300 rounded-lg hover:bg-gray-500 hover:text-white"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
  name: "UpdateProfilePicture",
  props: ["announcement"],
  data() {
    return {
      fileData: [],
      title: null,
      title_err: false,
      isUploadImage: true,
      imgUrls: [],
      captions: null,
      captions_err: false,
      imgs: null,
      isUpdate: true,
      itemToDelete: [],
      updateAnnouncement: null,
      userID: null,
    };
  },

  methods: {
    async getImage(filename) {
      var img;
      return await axios
        .get(
          process.env.VUE_APP_API_SERVER +
            "/announcement/stream-file/" +
            filename,
          {
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then((response) => {
          const blob = new Blob([response.data]);
          var objectURL = URL.createObjectURL(blob);
          return {
            url: objectURL,
            name: filename,
          };
          // this.imgUrls.push(img);
        });
    },

    dropt(ev) {
      ev.preventDefault();

      const dropfls = ev.dataTransfer.files;

      for (let i = 0; i < dropfls.length; i++) {
        var urls = URL.createObjectURL(dropfls[i]);
        var img = {
          url: urls,
          name: dropfls[i].name,
        };
        this.imgUrls.push(img);

        this.fileData.push(dropfls[i]);
      }
    },

    onFileChange(event) {
      const ed = event.target.files;

      for (let i = 0; i < ed.length; i++) {
        var urls = URL.createObjectURL(ed[i]);
        var img = {
          url: urls,
          name: ed[i].name,
        };
        this.imgUrls.push(img);

        this.fileData.push(ed[i]);
      }
    },

    imgToDelete(filename) {
      if (this.isUpdate) {
        this.itemToDelete.push(filename);
        this.imgUrls = this.imgUrls.filter(
          (imgurl) => imgurl.name !== filename
        );
        this.fileData = this.fileData.filter((file) => file.name !== filename);
      } else {
        this.imgUrls = this.imgUrls.filter(
          (imgurl) => imgurl.name !== filename
        );
        this.fileData = this.fileData.filter((file) => file.name !== filename);
      }
      document.querySelector("#dropzone-file").value = "";
    },

    save() {
      const toast = useToast();
      if (!this.captions) {
        this.captions_err = true;
        toast.error("Captions is required.", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (!this.title) {
        this.title_err = true;
        toast.error("Title is required.", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (!this.title_err && !this.captions_err) {
        const fd = new FormData();
        this.fileData.forEach((element) => {
          fd.append("files", element);
        });

        fd.append("caption", this.captions);
        fd.append("title", this.title);

        axios
          .post(
            process.env.VUE_APP_API_SERVER +
              "/users-controller/updateProfilePic",
            fd,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
              },
            }
          )
          .then(
            (response) => {
              const bgModal = document.getElementById("thisModal");
              bgModal.classList.remove("modal_open");
              bgModal.classList.add("modal_close");
              setTimeout(() => {
                this.$emit("closeModal");
                bgModal.classList.remove("modal_close");
                bgModal.classList.add("modal_open");
              }, 500);
              this.$emit("refreshTable");
              if (response.data.status == 201 || response.data.status == 200) {
                toast.success("Announcement saved.", {
                  position: "top-right",
                  timeout: 3000,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: false,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
      } else {
        return;
      }
    },

    update() {
      const toast = useToast();
      if (this.fileData.length != 0) {
        const fd = new FormData();
        this.fileData.forEach((element) => {
          fd.append("files", element);
        });
        fd.append("itemToDelete", JSON.stringify(this.itemToDelete));

        axios
          .post(
            process.env.VUE_APP_API_SERVER +
              "/users-controller/updateProfilePic",
            fd,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
              },
            }
          )
          .then(
            (response) => {
              const bgModal = document.getElementById("thisModal");
              bgModal.classList.remove("modal_open");
              bgModal.classList.add("modal_close");
              setTimeout(() => {
                this.$emit("closeModal");
                bgModal.classList.remove("modal_close");
                bgModal.classList.add("modal_open");
              }, 500);
              this.$emit("refreshTable");
              if (response.data.status == 201 || response.data.status == 200) {
                // window.location.reload();
                toast.success("Updated profile picture successfully.", {
                  position: "top-right",
                  timeout: 3000,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: false,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
                this.$emit("getImage");
                this.$parent.$emit("getImage");
              }
            },
            (error) => {
              console.log(error);
            }
          );
      } else {
        toast.warning("No file selected", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },

    closeModal() {
      const bgModal = document.getElementById("thisModal");
      bgModal.classList.remove("modal_open");
      bgModal.classList.add("modal_close");
      setTimeout(() => {
        document.querySelector("#dropzone-file").value = "";
        this.$emit("closeModal");
        bgModal.classList.remove("modal_close");
        bgModal.classList.add("modal_open");
      }, 500);
    },
  },
};
</script>

<style scoped>
.bgGreen {
  color: #105005;
}

.inputIndent {
  text-indent: 10px;
}
</style>