<template>
  <div class="w-auto h-auto pb-4 mx-3 bg-white">
    <div
      class="filter_by font-poppins w-auto md:w-auto overflow-hidden lg:h-auto md:h-auto py-2 m-auto rounded-md"
    >
      <h4 class="ml-5 mb-2 font-semibold text-gray-500 text-xs">Filter by</h4>
      <div class="grid w-full md:grid lg:flex px-5">
        <div class="grid text-left my-2">
          <h4 class="text-gray-500 font-semibold text-xs md:text-xs">
            Research Title
          </h4>
          <input
            type="text"
            class="indent flex-auto block text-xs font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded md:rounded-md lg:rounded-md transition py-2 ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
            v-model="searchText"
            @keyup="searchJournal"
            placeholder="Search for title"
          />
        </div>
        <div class="grid text-left my-2 mx-1.5">
          <h4 class="text-gray-500 font-semibold text-xs mb-1">
            Date Uploaded
          </h4>
          <input
            type="date"
            class="indent flex-auto min-w-0 py-2 block text-xs font-normal text-gray-700 bg-white bg-clip-padding border border-gray-300 transition rounded-md ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none pr-3"
            @keypress.delete="clearSearchDate"
            v-model="searchDate"
            @change="searchJournal"
            placeholder="mm-dd-yyyy"
          />
        </div>
        <!-- <div class="grid text-left mx-4 my-2">
          <h4 class="text-gray-500 font-semibold text-xs md:text-xs">
            Date Uploaded
          </h4>
          <div class="grid my-2">
            
            <input type="date"
              class=" indent flex-auto min-w-0 py-1 block text-xs font-normal text-gray-700 bg-white bg-clip-padding border border-gray-300 transition rounded-md ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none pr-3"
              @keypress.delete="clearSearchDate" v-model="searchDate" @change="searchJournal" placeholder="mm-dd-yyyy" />
          </div>
        </div> -->
        <!-- <div class="grid text-left mx-4 my-2">
      	<h4 class="text-gray-500 font-semibold text-xs md:text-xs">
        	Category
      	</h4>
      	<select
        	class="
          	indent
          	flex-auto
          	w-full
          	sm:w-40
          	md:w-40
          	lg:w-40
          	xl:w-40
          	py-1
          	block
          	text-xs
          	font-normal
          	text-gray-500
          	bg-white bg-clip-padding
          	border border-solid border-gray-300
          	rounded
          	md:rounded-md
          	lg:rounded-md
          	transition
          	ease-in-out
          	m-0
          	focus:text-gray-700
          	focus:bg-white
          	focus:border-green-600
          	focus:outline-none
        	"
      	>
        	<option value="Reseaches">Reseaches</option>
        	<option value="Journals">Journals</option>
        	<option value="Articles">Articles</option>
        	<option value="References">References</option>
      	</select>
    	</div> -->
      </div>
    </div>

    <div v-if="isLoading" class="flex justify-center py-5">
      <div class="spinLoader"></div>
    </div>

    <div
      class="journal-tbl bg-white w-auto mx-5 m-auto md:pb-4"
      v-if="!isLoading"
    >
      <div class="flex flex-col">
        <div class="overflow-x-auto">
          <div class="inline-block min-w-full">
            <div class="overflow-hidden">
              <Table
                :labels="table_headers"
                :data="displayedJournals"
                :idName="'approvaltable'"
                :notifField="'id'"
                :notifID="notifID"
              >
                <template v-slot:createdAt="{ item }">
                  {{ timeToday(formatDate(item.createdAt)) }}
                </template>

                <template v-slot:uploader="{ item }">
                  {{
                    item.userdetail
                      ? item.userdetail.mname
                        ? item.userdetail.fname +
                          " " +
                          item.userdetail.mname[0] +
                          ". " +
                          item.userdetail.lname
                        : item.userdetail.fname + " " + item.userdetail.lname
                      : ""
                  }}
                </template>
                <template v-slot:isPublished="{ item }">
                  <span
                    style="font-size: 9px"
                    class="uppercase py-1 rounded-full px-1.5"
                    :class="
                      item.isPublished == 0
                        ? 'bg-red-100 text-red-700'
                        : 'bg-green-100 text-green-700'
                    "
                    >{{ item.isPublished == 0 ? "Unpublished" : "Published" }}
                    {{
                      item.typeOfArticle == 1
                        ? "Thesis"
                        : item.typeOfArticle == 2
                        ? "Capstone"
                        : item.typeOfArticle == 3
                        ? "Dissertation"
                        : item.typeOfArticle == 4
                        ? "Research"
                        : item.typeOfArticle == 5
                        ? "Journal"
                        : item.typeOfArticle == 6
                        ? "Others"
                        : ""
                    }}</span
                  >
                </template>

                <template v-slot:action="{ item }">
                  <div
                    class="flex gap-1 sm:grid md:grid lg:flex lg:gap-1 xl:flex xl:gap-1 2xl:flex 2xl:gap-1"
                  >
                    <!-- FOR SUPERADMIN -->
                    <div
                      v-if="
                        this.$store.getters.getUserRole == 1 && activeTab == 5
                      "
                    >
                      <button
                        class="font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-three-dots"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                          />
                        </svg>
                      </button>
                      <ul
                        class="dropdown-menu hidden bg-white z-10 divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 py-2 text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li v-if="this.$store.getters.getUserRole != 1">
                          <a
                            class="dropdown-item block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            href="javascript:void(0)"
                            @click="viewJournal(item)"
                            >{{
                              item.journalstatusID == 1
                                ? "Verify"
                                : item.journalstatusID == 2
                                ? "View"
                                : item.journalstatusID == 5
                                ? "View"
                                : "View"
                            }}
                          </a>
                        </li>
                        <li v-if="this.$store.getters.getUserRole == 1">
                          <a
                            class="dropdown-item block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            href="javascript:void(0)"
                            @click="viewJournal(item)"
                          >
                            {{
                              item.journalstatusID == 2
                                ? "Publish"
                                : item.journalstatusID == 5
                                ? "View"
                                : item.journalstatusID == 3
                                ? "View"
                                : ""
                            }}
                          </a>
                        </li>
                        <li
                          v-if="
                            this.$store.getters.getUserRole == 1 &&
                            item.isRequestedDate != null &&
                            item.isEditApprovedDate == null
                          "
                        >
                          <a
                            class="dropdown-item block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            href="javascript:void(0)"
                            @click="confirmedGrant(item)"
                            >Grant Edit Request</a
                          >
                        </li>
                        <li
                          v-if="
                            this.$store.getters.getUserRole == 1 &&
                            item.isEditApprovedDate != null &&
                            item.isEditApprovedDate != null
                          "
                        >
                          <a
                            class="dropdown-item block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            href="javascript:void(0)"
                            @click="confirmedRevoke(item)"
                            >Revoke Grant</a
                          >
                        </li>
                        <!-- <li>
                        <a
                          class="dropdown-item block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          href="javascript:void(0)"
                          >Something else here</a
                        >
                      </li> -->
                        <li
                          v-if="
                            activeTab == 5 &&
                            this.$store.getters.getUserRole == 1
                          "
                        >
                          <a
                            class="dropdown-item block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            href="javascript:void(0)"
                            @click="unpublishThis(item)"
                            >Unpublish</a
                          >
                        </li>
                      </ul>
                    </div>

                    <!-- FOR DEAN AND PROGRAM CHAIR -->
                    <div
                      v-if="
                        this.$store.getters.getUserRole != 1 && activeTab == 2
                      "
                    >
                      <button
                        class="font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
                        type="button"
                        id="dropdownMenuButton2"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-three-dots"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                          />
                        </svg>
                      </button>
                      <ul
                        class="dropdown-menu hidden bg-white z-10 divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 py-2 text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownMenuButton2"
                      >
                        <li v-if="this.$store.getters.getUserRole != 1">
                          <a
                            class="dropdown-item block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            href="javascript:void(0)"
                            @click="viewJournal(item)"
                            >{{
                              item.journalstatusID == 1
                                ? "Verify"
                                : item.journalstatusID == 2
                                ? "View"
                                : item.journalstatusID == 5
                                ? "View"
                                : "View"
                            }}
                          </a>
                        </li>
                        <li v-if="this.$store.getters.getUserRole == 1">
                          <a
                            class="dropdown-item block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            href="javascript:void(0)"
                            @click="viewJournal(item)"
                          >
                            {{
                              item.journalstatusID == 2
                                ? "Publish"
                                : item.journalstatusID == 5
                                ? "View"
                                : item.journalstatusID == 3
                                ? "View"
                                : ""
                            }}
                          </a>
                        </li>
                        <li
                          v-if="
                            this.$store.getters.getUserRole != 1 &&
                            item.isRequestedDate != null &&
                            item.isEditApprovedDate == null &&
                            item.journalstatusID == 2
                          "
                        >
                          <a
                            class="dropdown-item block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            href="javascript:void(0)"
                            @click="confirmedGrant(item)"
                            >Grant Edit Request</a
                          >
                        </li>
                        <li
                          v-if="
                            this.$store.getters.getUserRole != 1 &&
                            item.isEditApprovedDate != null &&
                            item.isEditApprovedDate != null &&
                            item.journalstatusID == 2
                          "
                        >
                          <a
                            class="dropdown-item block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            href="javascript:void(0)"
                            @click="confirmedRevoke(item)"
                            >Revoke Grant</a
                          >
                        </li>
                        <!-- <li>
                        <a
                          class="dropdown-item block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          href="javascript:void(0)"
                          >Something else here</a
                        >
                      </li> -->
                        <li
                          v-if="
                            activeTab == 5 &&
                            this.$store.getters.getUserRole == 1
                          "
                        >
                          <a
                            class="dropdown-item block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            href="javascript:void(0)"
                            @click="unpublishThis(item)"
                            >Unpublish</a
                          >
                        </li>
                      </ul>
                    </div>
                    <div>
                      <button
                        v-if="
                          this.$store.getters.getUserRole != 1 &&
                          item.journalstatusID == 1 &&
                          activeTab == 1
                        "
                        @click="viewJournal(item)"
                        class="bg-white hover:bg-FFB800 hover:text-white text-FFB800 uppercase font-semibold text-xs border border-FFB800 rounded shadow px-2 py-1"
                      >
                        <!-- {{
                        item.journalstatusID == 1
                          ? "Verify"
                          : item.journalstatusID == 2
                          ? "View"
                          : item.journalstatusID == 5
                          ? "View"
                          : "View"
                      }} -->
                        Verify
                      </button>
                      <button
                        v-if="
                          this.$store.getters.getUserRole != 1 &&
                          item.journalstatusID == 3 &&
                          activeTab == 3
                        "
                        @click="viewJournal(item)"
                        class="bg-white hover:bg-FFB800 hover:text-white text-FFB800 uppercase font-semibold text-xs border border-FFB800 rounded shadow px-2 py-1"
                      >
                        View
                      </button>
                      <button
                        v-if="
                          this.$store.getters.getUserRole != 1 &&
                          item.journalstatusID == 5 &&
                          activeTab == 5
                        "
                        @click="viewJournal(item)"
                        class="bg-white hover:bg-FFB800 hover:text-white text-FFB800 uppercase font-semibold text-xs border border-FFB800 rounded shadow px-2 py-1"
                      >
                        View
                      </button>
                    </div>
                    <div>
                      <button
                        v-if="
                          this.$store.getters.getUserRole == 1 &&
                          item.journalstatusID == 2 &&
                          activeTab == 2
                        "
                        @click="viewJournal(item)"
                        class="bg-white hover:bg-FFB800 hover:text-white text-FFB800 uppercase font-semibold text-xs border border-FFB800 rounded shadow px-2 py-1"
                      >
                        <!-- {{
                          item.journalstatusID == 2
                            ? "Publish"
                            : item.journalstatusID == 5
                            ? "View"
                            : item.journalstatusID == 3
                            ? "View"
                            : ""
                        }} -->
                        Publish
                      </button>
                      <button
                        v-if="
                          this.$store.getters.getUserRole == 1 &&
                          item.journalstatusID == 3 &&
                          activeTab == 3
                        "
                        @click="viewJournal(item)"
                        class="bg-white hover:bg-FFB800 hover:text-white text-FFB800 uppercase font-semibold text-xs border border-FFB800 rounded shadow px-2 py-1"
                      >
                        View
                      </button>
                    </div>

                    <!-- <button
                      @click="confirmedGrant(item)"
                      v-if="item.isRequestedDate != null && activeTab == 2"
                      class="bg-white hover:bg-105005 uppercase hover:text-white text-105005 font-semibold text-xs border border-105005 rounded shadow px-1"
                    >
                      Grant Request
                    </button> -->
                    <!-- <button
                      @click="confirmedRevoke(item)"
                      v-if="
                        item.isEditApprovedDate != null &&
                        item.isEdit == 1 &&
                        (activeTab == 2 || activeTab == 5)
                      "
                      class="bg-white hover:bg-105005 uppercase hover:text-white text-105005 font-semibold text-xs border border-105005 rounded shadow px-1"
                    >
                      Revoke Grant
                    </button> -->

                    <!-- <button
                      @click="viewRemarks(item)"
                      v-if="activeTab == 1"
                      class="bg-white hover:bg-FFB800 uppercase hover:text-white text-FFB800 font-semibold text-xs border border-FFB800 rounded shadow px-1"
                    >
                      View Remarks
                    </button> -->
                    <!-- <button
                      @click="unpublishThis(item)"
                      v-if="
                        activeTab == 5 && this.$store.getters.getUserRole == 1
                      "
                      class="bg-white hover:bg-purple-500 uppercase hover:text-white text-purple-500 font-semibold text-xs border border-purple-500 rounded shadow px-1"
                    >
                      Unpublish
                    </button> -->
                  </div>
                </template>
              </Table>
              <div
                class="grid font-poppins w-full sm:flex md:flex lg:flex flex-row justify-end md:w-full sm:w-full mx-auto items-center border-l border-b border-r border-gray-400 rounded-bl-lg rounded-br-lg pt-4 pb-4 mb-8 md:mb-0"
              >
                <div
                  class="w-auto sm:w-3/6 md:w-3/6 lg:w-3/6 xl:w-3/6 2xl:w-3/6 gap-x-4 mx-3 flex"
                >
                  <div
                    class="hidden sm:flex text-xs text-gray-600 md:flex w-1/3 sm:w-2/5 md:w-2/5 lg:w-2/5 xl:w-2/5 2xl:w-2/5 items-center"
                  >
                    <label for="" class="w-2/3">Rows per page</label>&nbsp;
                    <select
                      class="w-1/3 border-gray-600 border-b"
                      v-model="perPage"
                    >
                      <option
                        :value="item"
                        v-for="item in selectPerPage"
                        :key="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>

                  <div
                    class="hidden sm:flex md:flex lg:flex xl:flex 2xl:flex w-1/3 sm:w-1/3 md:w-1/3 lg:w-2/5 xl:w-2/5 2xl:w-2/5 sm:break-words"
                  >
                    <!-- <div class="flex" v-if="results != 0"> -->
                    <span
                      class="text-xs m-auto text-gray-600 dark:text-gray-400"
                    >
                      Showing
                      <span
                        class="font-semibold text-gray-900 dark:text-white"
                        >{{ fromPage }}</span
                      >
                      to
                      <span
                        class="font-semibold text-gray-900 dark:text-white"
                        >{{ toPage }}</span
                      >
                      of
                      <span
                        class="font-semibold text-gray-900 dark:text-white"
                        >{{ results }}</span
                      >
                      Entries
                    </span>
                  </div>

                  <nav
                    class="w-1/5 sm:w-1/5 md:w-1/5 lg:w-1/5 xl:w-1/5 2xl:w-1/5"
                  >
                    <ul
                      class="flex sm:flex sm:justify-end md:justify-end lg:justify-end xl:justify-end 2xl:justify-end"
                    >
                      <li class="page-item mx-1">
                        <button
                          type="button"
                          class="page-link bg-customGreen text-white rounded-md p-1 text-xs"
                          v-if="page > 1"
                          @click="page--"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>

                        <!--for the disabled button -->
                        <button
                          type="button"
                          class="page-link bg-customGreen rounded-md text-white p-1 text-xs"
                          v-if="page == 1"
                          disabled
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </li>

                      <li class="page-item mx-1">
                        <button
                          type="button"
                          @click="page++"
                          v-if="page < pages.length"
                          class="page-link bg-customGreen rounded-md text-white p-1 text-xs"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>

                        <!--for the disabled button -->
                        <button
                          type="button"
                          disabled
                          v-if="page >= pages.length"
                          class="page-link bg-customGreen rounded-md text-white p-1 text-xs"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PendingReasonModal
      v-if="isViewRemarks"
      :journal="journalFile"
      :tableFrom="'approval'"
      v-on:closeModal="isViewRemarks = false"
    />
    <AlertConfirmationModal
      v-on:confirmUnpublished="confirmUnpublished"
      v-on:closeModal="closeUnpublishedModal"
      :content="unpublishedContent"
      v-if="isUnpublishedModal"
    />
    <RequestToEditModal
      v-on:confirmGrant="confirmGrant"
      v-on:confirmRevoke="confirmRevoke"
      v-on:closeRequestModal="closeRequestModal"
      :content="RequestToEditContent"
      :mode="RTEMode"
      v-if="isCloseRequestModal"
    />
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import PendingReasonModal from "../modals/PendingReasonModal.vue";
import AlertConfirmationModal from "../modals/AlertConfirmationModal.vue";
import RequestToEditModal from "../modals/RequestToEditModal.vue";
import moment from "moment";
import Table from "../components/Table.vue";
export default {
  name: "ApprovalTable",
  props: ["statusType", "notifID", "isUpdatedResult"],
  components: {
    PendingReasonModal,
    Table,
    AlertConfirmationModal,
    RequestToEditModal,
  },

  data() {
    return {
      RequestToEditContent: "",
      RTEMode: 1,
      grantData: [],
      isCloseRequestModal: false,
      triggerUpdate: this.isUpdatedResult,
      trigerBoolean: false,
      contents: [],
      contentss: [],
      isViewRemarks: false,
      journalFile: null,
      duplicate_content: [],
      page: 1,
      status: this.statusType,
      perPage: 10,
      selectPerPage: [10, 15, 20, 30, 50, 100],
      pages: [],
      isLoading: false,
      fromPage: 1,
      toPage: null,
      results: null,
      activeTab: 1,
      searchText: null,
      searchDate: null,
      isUnpublishedModal: false,
      unpublishedContent: null,
      unpublishedData: null,
      interval: null,
      table_headers: [
        // { text: "ID", field: "id" },
        { text: "Research Title", field: "title" },
        { text: "Status", field: "isPublished" },
        { text: "Date Uploaded", field: "createdAt" },
        { text: "Uploaded By", field: "uploader" },
        { text: "Action", field: "action" },
      ],
    };
  },
  methods: {
    // contents() {
    //   this.setPages();
    // },

    // perPage() {
    //   this.page = 1;
    //   this.setPages();
    // },
    confirmGrant() {
      const toast = useToast();
      const fd = {
        isEdit: true,
        articleID: this.grantData.id,
        journalstatusID: this.grantData.journalstatusID,
        userid: this.grantData.userdetail.id,
        instituteBelongID: this.grantData.userdetail.instituteBelongID,
      };
      axios
        .post(
          process.env.VUE_APP_API_SERVER + "/fileupload/grantrequestToEdit",
          fd,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            if (response.data.status == 200 || response.data.status == 201) {
              toast.success(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
              this.isUploading1 = false;
              // const alert_msg = {
              //   msg: response.data.msg,
              // };
              setTimeout(() => {
                this.closeRequestModal();
                if (this.grantData.journalstatusID == 2) {
                  this.approvedByDean();
                  this.activeTab = 2;
                } else if (this.grantData.journalstatusID == 5) {
                  this.approvedByLibrarian();
                  this.activeTab = 5;
                }
                // this.$parent.$emit("success_alert", alert_msg);
              }, 500);
            } else if (
              response.data.status != 200 ||
              response.data.status != 201
            ) {
              toast.error(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
              setTimeout(() => {
                this.closeRequestModal();
                if (this.grantData.journalstatusID == 2) {
                  this.approvedByDean();
                  this.activeTab = 2;
                } else if (this.grantData.journalstatusID == 5) {
                  this.approvedByLibrarian();
                  this.activeTab = 5;
                }
              }, 500);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },

    confirmRevoke() {
      const toast = useToast();
      const fd = {
        isEdit: true,
        articleID: this.grantData.id,
        journalstatusID: this.grantData.journalstatusID,
        userid: this.grantData.userdetail.id,
        instituteBelongID: this.grantData.userdetail.instituteBelongID,
      };
      axios
        .post(
          process.env.VUE_APP_API_SERVER +
            "/fileupload/revokegrantrequestToEdit",
          fd,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            if (response.data.status == 200 || response.data.status == 201) {
              toast.success(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
              this.isUploading1 = false;
              // const alert_msg = {
              //   msg: response.data.msg,
              // };
              setTimeout(() => {
                this.closeRequestModal();
                if (this.grantData.journalstatusID == 2) {
                  this.approvedByDean();
                  this.activeTab = 2;
                } else if (this.grantData.journalstatusID == 5) {
                  this.approvedByLibrarian();
                  this.activeTab = 5;
                }
                // this.$parent.$emit("success_alert", alert_msg);
              }, 500);
            } else if (
              response.data.status != 200 ||
              response.data.status != 201
            ) {
              toast.error(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
              setTimeout(() => {
                this.closeRequestModal();
                if (this.grantData.journalstatusID == 2) {
                  this.approvedByDean();
                  this.activeTab = 2;
                } else if (this.grantData.journalstatusID == 5) {
                  this.approvedByLibrarian();
                  this.activeTab = 5;
                }
              }, 500);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
    closeRequestModal() {
      this.isCloseRequestModal = false;
    },
    confirmedGrant(i) {
      this.grantData = i;
      this.RTEMode = 2;
      this.RequestToEditContent =
        "You're about to grant this request to edit his/her own uploaded article. Proceed?";
      this.isCloseRequestModal = true;
    },

    confirmedRevoke(i) {
      this.grantData = i;
      this.RTEMode = 3;
      this.RequestToEditContent =
        "You're about to revoke your grant to edit his/her own uploaded article. Proceed?";
      this.isCloseRequestModal = true;
    },
    timeToday(d) {
      var today = new Date(d);
      return moment(today).format("ll");
    },

    confirmUnpublished() {
      const toast = useToast();
      axios
        .post(
          process.env.VUE_APP_API_SERVER + "/fileupload/unpublished",
          this.unpublishedData,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            if (response.data.status == 201 || response.data.status == 200) {
              toast.success(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
              this.approvedByLibrarian();
            } else {
              toast.warning(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            }
            // this.contents = response.data.list;
            // // this.duplicate_content = response.data.list;
            // this.results = response.data.list.length;
            // this.isLoading = false;
            // console.log(this.response);
          },
          (error) => {
            console.log(error);
          }
        );
    },

    // toggle emits what to diplay on the ApprovalView
    init() {
      this.status = this.statusType;
      this.contents = [];
      this.page = 1;
      this.perPage = 10;

      this.pages = [];
      this.fromPage = 1;
      this.toPage = null;
      this.results = null;
      this.triggerUpdate = this.isUpdatedResult;
    },

    unpublishThis(item) {
      this.isUnpublishedModal = true;
      this.unpublishedContent =
        "Do you want to unpublished " + item.title + "?";
      this.unpublishedData = item;
    },

    closeUnpublishedModal() {
      this.isUnpublishedModal = false;
    },

    // loads journal data with status forApproval
    async forApproval() {
      this.isLoading = true;
      this.results = 0;
      axios
        .get(process.env.VUE_APP_API_SERVER + "/fileupload/files/1", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            this.contents = response.data.list;

            this.duplicate_content = response.data.list;
            this.results = response.data.list.length;
            this.isLoading = false;
            // console.log(response.data.list.length);
          },
          (error) => {
            console.log(error);
          }
        );
    },

    // loads journal data with status approved by dean or program chair
    async approvedByDean() {
      this.results = 0;
      this.isLoading = true;
      axios
        .get(process.env.VUE_APP_API_SERVER + "/fileupload/files/2", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            this.contents = response.data.list;
            this.duplicate_content = response.data.list;
            this.results = response.data.list.length;
            this.isLoading = false;
          },
          (error) => {
            // console.log(error);
          }
        );
      // axios
      //   .get(process.env.VUE_APP_API_SERVER + "/fileupload/files/5", {
      // 	headers: {
      //   	Authorization: `Bearer ${this.$store.getters.getToken}`,
      // 	},
      //   })
      //   .then(
      // 	(response) => {
      //   	this.contents.forEach(o => {
      //     	o.forEach(so => this.contentss.push(so))
      //   	})
      //   	this.contents = [].concat.apply(response.data.list, this.contents);
      //   	this.duplicate_content = [].concat.apply(response.data.list, this.duplicate_content);
      //   	this.results = parseInt(this.results) + response.data.list.length;
      // 	},
      // 	(error) => {
      //   	console.log(error);
      // 	}
      //   );
    },

    async approvedByDeanAndLib() {
      this.results = 0;
      this.isLoading = true;
      axios
        .get(process.env.VUE_APP_API_SERVER + "/fileupload/files/2", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .get(process.env.VUE_APP_API_SERVER + "/fileupload/files/5", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            this.contents = response.data.list;
            this.duplicate_content = response.data.list;
            this.results = response.data.list.length;
            this.isLoading = false;
          },
          (error) => {
            // console.log(error);
          }
        );
    },

    // loads journal data with status pending
    async pending() {
      this.results = 0;
      this.isLoading = true;
      axios
        .get(process.env.VUE_APP_API_SERVER + "/fileupload/files/3", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            this.contents = response.data.list;
            this.duplicate_content = response.data.list;
            this.results = response.data.list.length;
            this.isLoading = false;
          },
          (error) => {
            // console.log(error);
          }
        );
    },

    // loads journal data with status endorsed to librarian by dean or program chair
    async endorsed() {
      this.results = 0;
      this.isLoading = true;
      axios
        .get(process.env.VUE_APP_API_SERVER + "/fileupload/files/4", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            this.contents = response.data.list;
            this.duplicate_content = response.data.list;
            this.results = response.data.list.length;
            this.isLoading = false;
          },
          (error) => {
            // console.log(error);
          }
        );
    },

    // loads journal data with status approved by librarian
    async approvedByLibrarian() {
      this.results = 0;
      this.isLoading = true;
      axios
        .get(process.env.VUE_APP_API_SERVER + "/fileupload/files/5", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            this.contents = response.data.list;
            this.duplicate_content = response.data.list;
            this.results = response.data.list.length;
            this.isLoading = false;
          },
          (error) => {
            // console.log(error);
          }
        );
    },

    // handkes s
    searchJournal() {
      if (this.searchText && this.searchDate) {
        this.contents = this.contents.filter(
          (data) =>
            data.title.toLowerCase().indexOf(this.searchText.toLowerCase()) !==
              -1 &&
            this.formatDate(data.createdAt) == this.formatDate(this.searchDate)
        );
        this.results = this.contents.length;
        this.setPages();
      } else if (this.searchText && !this.searchDate) {
        this.contents = this.duplicate_content;
        this.results = this.duplicate_content.length;
        this.contents = this.contents.filter(
          (data) =>
            data.title.toLowerCase().indexOf(this.searchText.toLowerCase()) !==
            -1
        );
        this.results = this.contents.length;
        this.setPages();
      } else if (!this.searchText && this.searchDate) {
        this.contents = this.duplicate_content;
        this.results = this.duplicate_content.length;
        this.contents = this.contents.filter(
          (data) =>
            this.formatDate(data.createdAt) == this.formatDate(this.searchDate)
        );
        this.results = this.contents.length;
        this.setPages();
      } else if (!this.searchText && !this.searchDate) {
        this.contents = this.duplicate_content;
        this.results = this.duplicate_content.length;
      }
    },

    // clear the value in search date
    clearSearchDate() {
      this.searchDate = "";
    },

    // emit data to view selected journal
    viewJournal(item) {
      // this.$emit("viewJournal");

      this.$emit("toggleView", item);
      // console.log(item);
    },

    // emit data to view selected journal
    viewRemarks(item) {
      this.journalFile = item;
      this.isViewRemarks = true;
    },
    getUploader(item) {
      let user = item.userdetail;
      // console.log(user.fname);
      // return item.userdetail.fname + " " + item.userdetail.lname;
    },

    // set pages to pagination
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(
        parseInt(this.contents.length) / this.perPage
      );
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    // handles the from and to of pagination table
    paginate(contents) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;

      if (to > this.contents.length) {
        this.fromPage = from + 1;
        this.toPage = this.contents.length;
      } else {
        this.fromPage = from + 1;
        this.toPage = to;
      }

      return contents.slice(from, to);
    },

    // format date to display
    formatDate(date) {
      const d = new Date(date);
      var dd = d.getDate();
      if (dd < 10) {
        dd = "0" + dd;
      }
      var mm = d.getMonth() + 1;
      if (mm < 10) {
        mm = "0" + mm;
      }
      var yy = d.getFullYear();
      return mm + "-" + dd + "-" + yy;
    },
  },

  computed: {
    displayedJournals() {
      return this.paginate(this.contents);
    },
    computeStatus() {
      return this.init();
    },
  },
  watch: {
    isUpdatedResult: {
      handler(val) {
        // console.log(this.$store.getters.getUserRole);
        if (this.$store.getters.getUserRole == 1) {
          this.init();
          this.approvedByDean();
        } else {
          this.init();
          this.forApproval();
        }
      },
      immediate: true,
    },
    // isUpdatedResult: function (newVal) {
    //   this.trigerBoolean = newVal;
    //   this.triggerUpdate = newVal;
    //   if (this.triggerUpdate == true) {
    //     if (this.$store.getters.getUserRole != 1) {
    //       this.init();
    //       this.forApproval();
    //       this.setPages();
    //     } else if (this.$store.getters.getUserRole == 1) {
    //       this.init();
    //       this.approvedByDean();
    //       this.setPages();
    //     }
    //   }
    // },

    statusType: {
      handler(val) {
        if (val) {
          this.status = val;

          if (this.status == "forApproval") {
            this.init();
            this.forApproval();
            this.setPages();
            this.activeTab = 1;
          } else if (this.status == "approvedByDean") {
            // VERIFIED
            this.init();
            this.approvedByDean();
            this.setPages();
            this.activeTab = 2;
          } else if (this.status == "pending") {
            this.init();
            this.pending();
            this.setPages();
            this.activeTab = 3;
          } else if (this.status == "endorsed") {
            this.init();
            this.endorsed();
            this.setPages();
            this.activeTab = 4;
          } else if (this.status == "approvedByLibrarian") {
            // PUBLISHED
            this.init();
            this.approvedByLibrarian();
            this.setPages();
            this.activeTab = 5;
          }
        }
      },
      immediate: true,
    },
    // statusType: function (newVal) {
    //   this.status = newVal;

    //   if (this.status == "forApproval") {
    //     this.init();
    //     this.forApproval();
    //     this.setPages();
    //     this.activeTab = 1;
    //   } else if (this.status == "approvedByDean") {
    //     this.init();
    //     this.approvedByDean();
    //     this.setPages();
    //     this.activeTab = 2;
    //   } else if (this.status == "pending") {
    //     this.init();
    //     this.pending();
    //     this.setPages();
    //     this.activeTab = 3;
    //   } else if (this.status == "endorsed") {
    //     this.init();
    //     this.endorsed();
    //     this.setPages();
    //     this.activeTab = 4;
    //   } else if (this.status == "approvedByLibrarian") {
    //     this.init();
    //     this.approvedByLibrarian();
    //     this.setPages();
    //     this.activeTab = 5;
    //   }
    // },

    // function (value) {
    //   console.log("refereshtable: " + value);
    //   if (value) {
    // 	this.init();
    // 	this.forApproval();
    // 	this.setPages();
    //   }
    // },
  },
  // beforeDestroy() {
  //   // prevent memory leak
  //   clearInterval(this.interval);
  // },
  // mounted() {
  //   if (this.$store.getters.getUserRole != 1) {
  //     this.forApproval();
  //   } else if (this.$store.getters.getUserRole == 1) {
  //     this.approvedByDean();
  //   }
  // },

  created() {
    // this.interval = setInterval(() => {
    //   this.triggerUpdate = this.isUpdatedResult;
    // }, 500);
    this.moment = moment;
    this.setPages();
  },
};
</script>

<style scoped>
.tbl_header {
  background-color: #105005;
}

.indent {
  text-indent: 0.5rem;
}

/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
