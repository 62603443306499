<template>
  <div class="w-10/12 m-auto font-poppins h-62">
    <div class="bg-white rounded-md px-5 h-full py-6">
      <div class="flex m-auto">
        <!-- <p class="text-md text-gray-500">Sign Up</p>
        <p class="text-gray-500 mx-3">|</p> -->
        <p class="text-md font-semibold text-green-900">
          Welcome {{ username }}
        </p>
      </div>
      <hr class="mt-1" />

      <form method="POST" @submit.prevent class="mt-4">
        <div class="flex m-auto">
          <div class="mb-6 mx-1 pt-2 border w-full px-2 pb-2 rounded-md">
            <label for="mname" class="block text-gray-700 text-xs mb-2">
              <!-- <span class="text-red-500">&nbsp;*</span> -->
              What are you?
            </label>
            <div class="flex justify-center">
              <div class="grid grid-cols-2">
                <div class="grid grid-rows-1 gap-1">
                  <div class="form-check mx-10 text-xs items-center flex">
                    <input
                      class="form-check-input rounded-full h-4 w-4 text-sm border border-gray-300 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault10"
                      checked
                      @change="onSelected"
                      v-model="userType"
                      :value="'Student'"
                    />
                    <label for="flexRadioDefault10">Student</label>
                  </div>
                  <div class="form-check mx-10 text-xs items-center flex">
                    <input
                      class="form-check-input rounded-full h-4 w-4 text-sm border border-gray-300 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault20"
                      @change="onSelected"
                      v-model="userType"
                      :value="'Alumni'"
                    />
                    <label for="flexRadioDefault20">Alumni</label>
                  </div>
                </div>
                <div class="grid grid-rows-1 gap-1">
                  <div class="form-check mx-10 text-xs items-center flex">
                    <input
                      class="form-check-input rounded-full h-4 w-4 border border-gray-300 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      name="flexRadioDefault"
                      :value="'Faculty'"
                      v-model="userType"
                      @change="onSelected"
                      id="flexRadioDefault30"
                    />
                    <label for="flexRadioDefault30">Faculty</label>
                  </div>
                  <div class="form-check mx-10 text-xs items-center flex">
                    <input
                      class="form-check-input rounded-full h-4 w-4 border border-gray-300 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      name="flexRadioDefault"
                      :value="'Others'"
                      v-model="userType"
                      @change="onSelected"
                      id="flexRadioDefault40"
                    />
                    <label for="flexRadioDefault40">Other</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-2 mx-1">
          <label for="mname" class="block text-gray-700 text-xs font-bold mb-2">
            <span class="text-red-500">&nbsp;*</span>
            Institute
          </label>
          <div class="mt-1 relative rounded-md shadow-sm"></div>
          <select
            v-model="institute_select"
            class="text-xs block pr-10 shadow appearance-none border-2 rounded w-full py-2 px-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-orange-500 transition duration-500 ease-in-out"
          >
            <option
              v-for="institute in institutes"
              :key="institute"
              :value="institute.id"
            >
              {{ institute.name }}
            </option>
          </select>
        </div>

        <div class="bottom-0 m-auto text-center">
          <button
            @click="registerUser"
            class="bg-yellow-500 w-full py-2 font-md rounded-md text-white mt-5"
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      username: null,
      valid: false,
      isLoading: false,
      userType: "Student",
      cred: null,
      institutes: [],
      institute_select: 1,
    };
  },

  created() {
    this.username = this.$route.params.gUser;
    this.cred = this.$route.params.cred;
    this.loadInstitutes();
    // console.log(this.$route.params.cred)
  },
  methods: {
    // radio button selected
    onSelected() {
      // console.log(this.userType);
    },
    onInput(e) {
      if (e.target.value == "") {
        e.target.classList.add("border-red-500");
      } else {
        e.target.classList.remove("border-red-500");
      }
    },

    loadInstitutes() {
      axios.get(process.env.VUE_APP_API_SERVER + "/institutes").then(
        (response) => {
          this.institutes = response.data;
        },
        (error) => {
          console.log(error);
        }
      );
    },

    registerUser() {
      var data;
      if (this.userType == "Student") {
        data = {
          cred: this.cred,
          roleid: 4,
          userType: "Student",
          institute: this.institute_select,
        };
      } else if (this.userType == "Faculty") {
        data = {
          cred: this.cred,
          roleid: 4,
          userType: "Faculty",
          institute: this.institute_select,
        };
      } else if (this.userType == "Alumni") {
        data = {
          cred: this.cred,
          roleid: 5,
          userType: "Alumni",
          institute: this.institute_select,
        };
      } else if (this.userType == "Other") {
        data = {
          cred: this.cred,
          roleid: 4,
          userType: "Other",
          institute: this.institute_select,
        };
      }

      // console.log(data);

      axios
        .post(process.env.VUE_APP_API_SERVER + "/auth/regGoogleUser", data)
        .then(
          (response) => {
            // console.log(response.data);
            this.$store.commit("setToken", response.data.token);
            this.$store.commit("setIsValidated", true);
            this.$store.commit(
              "setUserType",
              response.data.user.userdetail.userType
            );
            this.$store.commit(
              "setUserdetailID",
              response.data.user.userdetail.id
            );
            this.$store.commit("setUserEmail", response.data.user.email);
            this.$store.commit("setUserID", response.data.user.id);
            this.$store.commit(
              "setUserFirstName",
              response.data.user.userdetail.fname
            );
            this.$store.commit(
              "setUserMidName",
              response.data.user.userdetail.mname
            );
            this.$store.commit(
              "setUserLastName",
              response.data.user.userdetail.lname
            );
            this.$store.commit(
              "setUserRole",
              response.data.user.userdetail.roleID
            );

            this.$store.commit(
              "setIsAdminVerified",
              response.data.user.userdetail.isAdminVerified
            );

            this.$store.commit(
              "setIsGoogleSignIn",
              response.data.user.isGoogleSignIn
            );

            // this.isLoading = false;
            this.$router.push("/userspage/dashboard");
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
};
</script>

<style scoped>
.bgGreen {
  background-color: #105005;
}

.bgGreen-disabled {
  background-color: #43703f;
}

input[type="radio"],
input[type="checkbox"] {
  accent-color: #105005;
}
</style>