import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";


import { EncryptStorage } from 'encrypt-storage';

export const encryptStorage = new EncryptStorage(process.env.VUE_APP_LOCAL_STORAGE_ENCRYPT, {
  storageType: 'localStorage',
 
});

export default createStore({
      state : {
          
        user :{
            user_email : null,
            token : null,
            user_id : null,
            user_fname: null,
            user_mname : null,
            user_lname : null,
            user_type : null,
              user_isValidated: 0,
            user_userdetailID: null,
            user_role : null,
              isAdminVerified: 0,
               isGoogleSignIn: 0,
               
            expiryDate : new Date()
        },
          countdown : 120,
        
    },
    getters: {
        // return true if token expiry date is less than curr_date
        isExpired(state) {
            
            return new Date(state.expiryDate) < new Date();   
        },
        
        
        getIsValidated(state){
            return state.user.user_isValidated;
        },
        getIsGoogleSignIn(state){
            return state.user.isGoogleSignIn;
        },
        getToken(state){
            return state.user.token;
        },

        getUserEmail(state){
            return state.user.user_email;
        },
        getUserType(state){
            return state.user.user_type;
        },
        getUserID(state){
            return state.user.user_id
        },
        getUserdetailID(state) {
            return state.user.user_userdetailID
        },
        getUserFirstName(state){
            return state.user.user_fname
      },
        getUserMidName(state){
            return state.user.user_mname
      },
        getUserLastName(state){
            return state.user.user_lname
        },
         getUserRole(state){
            return state.user.user_role
        },
        getIsAdminVerified(state) {
            return state.user.isAdminVerified  
         },
        getCountDown(state){
            return state.countdown
        },

    },
    mutations: {
        setExpiryDate(state) {
            // expiry time set to 1day
            const date = new Date();
            date.setDate(date.getDate() + 1);
            state.expiryDate = date;
         },   

        setIsValidated(state, value){
            state.user.user_isValidated = value;  
        },
        
        setIsGoogleSignIn(state, value) {
            state.user.isGoogleSignIn = value;  
        },
        setIsAdminVerified(state, value) {
            state.user.isAdminVerified = value;  
        },
        setToken(state, value){
             state.user.token = value;
        },

        setUserEmail(state, value){
           state.user.user_email = value;
        },
        setUserType(state, value){
           state.user.user_type = value;
        },
        setUserID(state, value){
             state.user.user_id = value;
        },
        setUserdetailID(state, value) {
            state.user.user_userdetailID = value;
        },
        setUserFirstName(state, value){
             state.user.user_fname = value;
         
        },
        setUserMidName(state, value){
            state.user.user_mname = value;
        },
        setUserLastName(state, value){
            state.user.user_lname = value;
        },
         setUserRole(state, value){
            state.user.user_role= value;
        },
        setCountDown(state) {

            state.countdown = 120 
        },
        decrement(state) {
            
            state.countdown--
        }
    },
  actions: {
      setFirstNameAction(context, payload) {
        
          context.commit('setUserFirstName', payload)
      },
       setMidNameAction(context, payload) {
        
          context.commit('setUserMidName', payload)
       },
        setLastNameAction(context, payload) {
         
          context.commit('setUserLastName', payload)
      },
       decrement(context) {
           
           context.commit('decrement')
        }
       

  },
  modules: {
  },

    plugins: [createPersistedState({
       
      storage: {
        getItem: (key) => encryptStorage.getItem(key),
           setItem: (key, state) => encryptStorage.setItem(key, state),
        removeItem: (key) => encryptStorage.removeItem(key)
      },
    }
              
   )],
})
