import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'
import LandingPage from '../views/LandingPage.vue'
import Logout from '../views/Logout.vue'
import Journals from '../components/Journals.vue'
import Landing from '../components/Landing.vue'
import AboutPage from '../components/AboutPage.vue'
import Signin from '../views/Signin.vue'
import Login from '../components/Login.vue'
import Register from '../components/Register.vue'
import RegistrationVerify from '../components/RegistrationVerify.vue'
import RegistrationOTP from '../components/RegistrationOTP.vue'
import UsersPage from '../views/UsersPage.vue'
import UserJournals from '../layouts/UserJournals.vue'
import UserMyWork from '../layouts/UserMyWork.vue'
import UserSearch from '../layouts/UserSearch.vue'
import UserDashboard from '../layouts/UserDashboard.vue'
import UserMyProfile from '../layouts/UserMyProfile.vue'
import UserTutorials from '../layouts/UserTutorials.vue'
import UserJournalApproval from '../layouts/UserJournalApproval.vue'
import Publication from '../components/Publication.vue'
import Security from '../components/Security.vue'
import Abouts from '../components/Abouts.vue'
import UserVerify from '../layouts/UserVerify.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ForgotEmail from '../components/forgot-password/ForgotEmail.vue'
import OTP from '../components/forgot-password/OTP.vue'
import ChangePassword from '../components/forgot-password/ChangePassword.vue'
import JournalCategory from "../layouts/JournalCategory.vue"
import JournalEvents from '../layouts/JournalEvents.vue'
import JournalRequests from '../layouts/JournalRequests.vue'
import PageNotFound from "../components/PageNotFound.vue"
import UserLogs from '../layouts/UserLogs.vue'
import Spinner2 from '../components/Spinner2.vue'
import Position from '../components/Position.vue'
import UserAnnouncement from '../layouts/UserAnnouncement.vue'
import TermsAndPolicy from "../components/TermsAndPolicy.vue"
import LandingPageProfile from '../components/LandingPageProfile.vue'
import AnnouncementDisplay from '../components/AnnouncementDisplay.vue'
import ActivityLogs from '../components/ActivityLogs.vue';
// import BarChart from '../components/BarChart.vue'
import store from '../store'
const routes = [
  {
    path: '/',
    redirect: '/landing/home'
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: { title: "page-not-found" }
  },


  {
    path: '/landing',
    name: 'LandingPage',
    redirect: '/landing/home',
    component: LandingPage,
    meta: { authRequired: false },
    children: [
      {
        path: 'home',
        name: 'Landing',
        component: Landing,
        meta: { authRequired: false, title: "home-page" },
      },
      {
        path: 'journals',
        name: 'Journals',
        component: Journals,
        meta: { authRequired: false, title: "search-journal" },
      },
      {
        path: 'aboutpage',
        name: 'AboutPage',
        component: AboutPage,
        meta: { authRequired: false, title: "about-page" },
      },
      {
        path: 'announcements',
        name: 'Announcements',
        component: AnnouncementDisplay,
        meta: { authRequired: false, title: "others" },
      },
      {
        path: 'verify',
        name: 'RegistrationVerify',
        component: RegistrationVerify,
        meta: { authRequired: false, title: "registration-verify" },
      },
      {
        path: 'otp',
        name: 'RegistrationOTP',
        component: RegistrationOTP,
        meta: { authRequired: false, title: "registration-OTP" },
      },
      {
        path: 'profilepage',
        name: 'ProfilePage',
        component: LandingPageProfile,
        meta: { authRequired: false, title: "Profile" },
      },


    ],
  },

  {
    path: '/signin',
    name: 'SignIn',
    redirect: '/signin/login',
    component: Signin,
    meta: { authRequired: false },
    children: [

      {
        path: 'login',
        name: 'Login',
        component: Login,
        meta: { authRequired: false, title: "login" },
      },
      {
        path: 'register',
        name: 'Register',
        component: Register,
        meta: { authRequired: false, title: "registration" },
      },
      {
        path: 'position',
        name: 'Position',
        component: Position,
        meta: { authRequired: false, title: "position" },
      },


    ],

  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
    redirect: '/forgotpassword/auth',
    meta: { authRequired: false },
    children: [

      {
        path: 'auth',
        name: 'ForgotEmail',
        component: ForgotEmail,
        meta: { authRequired: false, title: "forgot-password" },
      },
      {
        path: 'get-otp',
        name: 'OTP',
        component: OTP,
        meta: { authRequired: false, title: "OTP" },
      },
      {
        path: 'change-auth',
        name: 'ChangePassword',
        component: ChangePassword,
        meta: { authRequired: false, title: "change-password" },
      },


    ],

  },
  {
    path: '/userspage',
    name: "UsersPage",
    redirect: '/userspage/dashboard',
    component: UsersPage,
    meta: { authRequired: true },
    children: [
      {
        path: 'dashboard',
        name: 'UserDashboard',
        component: UserDashboard,
        meta: { authRequired: true, title: "dashboard" },
      },
      {
        path: 'journal',
        name: 'UserJournals',
        component: UserJournals,
        meta: { authRequired: true, title: "search-journal" },
      },
      {
        path: 'mywork',
        name: 'UserMyWork',
        component: UserMyWork,
        meta: { authRequired: true, title: "my-works" },


      },

      {
        path: 'usersearch',
        name: 'UserSearch',
        component: UserSearch,
        meta: { authRequired: true, title: "search" },


      },
      {
        path: 'approval',
        name: 'UserJournalApproval',
        component: UserJournalApproval,
        meta: { authRequired: true, title: "journal-approval" },

      },
      {
        path: 'accverify',
        name: 'UserVerify',
        component: UserVerify,
        meta: { authRequired: true, title: "account-verification" },


      },
      {
        path: 'requests',
        name: 'JournalRequests',
        component: JournalRequests,
        meta: { authRequired: true, title: "journal-requests" },
      },
      {
        path: 'category',
        name: 'JournalCategory',
        component: JournalCategory,
        meta: { authRequired: true, title: "journal-category" },


      },
      {
        path: 'events',
        name: 'JournalEvents',
        component: JournalEvents,
        meta: { authRequired: true, title: "journal-events" },


      },
      {
        path: 'announcement',
        name: 'UserAnnouncement',
        component: UserAnnouncement,
        meta: { authRequired: true, title: "announcement" },
      },


      {
        path: 'logs',
        name: 'UserLogs',
        component: UserLogs,
        meta: { authRequired: true, title: "user-logs" },


      },
      {
        path: 'tutorials',
        name: 'UserTutorials',
        component: UserTutorials,
        meta: { authRequired: true, title: "user-tutorials" },


      },
      {
        path: 'myprofile',
        name: 'UserMyProfile',
        component: UserMyProfile,
        meta: { authRequired: true },
        children: [
          {
            path: '',
            name: 'Abouts',
            component: Abouts,
            meta: { authRequired: true, title: "my-profile" },

          },
          {
            path: 'publication',
            name: 'Publication',
            component: Publication,
            meta: { authRequired: true, title: "my-publication" },

          },
          {
            path: 'activityLogs',
            name: 'activityLogs',
            component: ActivityLogs,
            meta: { authRequired: true, title: "my-logs" },

          },

          {
            path: 'security',
            name: 'Security',
            component: Security,
            meta: { authRequired: true, title: "my-security" },

          }
        ]
      },

    ]
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: { authRequired: true },

  },
  {
    path: '/termsandpolicy',
    name: 'TermsAndPolicy',
    component: TermsAndPolicy,
    meta: { authRequired: false, title: "Terms and Policy" },

  },
  // {
  //   path: '/aboutpage',
  //   name: 'AboutPage',
  //   component: AboutPage,
  //   meta: { authRequired: false, title: "About" },

  // },
  // {
  //   path: '/about-page',
  //   name: 'AboutPage',
  //   // route level code-splitting

  //   component: () => import(/* webpackChunkName: "about" */ '../components/AboutPage.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = "DNSC ReDMaS | " + to.meta.title || "DNSC ReDMaS"
  const isAuthenticated = store.getters.getIsValidated;
  if (to.matched.some(record => record.meta.authRequired == true)) {

    if (isAuthenticated) {
      next()
    }
    else {
      next('/signin/login');
    }
  }
  else {

    if (isAuthenticated) {
      next('/userspage/dashboard')
    }
    else {

      next();
    }

  }

})

// router.beforeEach((to,from, next) => {
//   const isAuthenticated = store.getters.getIsValidated;
//   // const token = store.getters.getToken;


//   if (isAuthenticated == 0 && to.path === '/u/dashboard') {
//     // redirect the user to the landing page

//     next({ path: '/' })
//   }else if (isAuthenticated == 0 && to.path === '/') {
//     // redirect the user to the landing page

//     next({ path: '/l/home' })
//   }
//   else if (isAuthenticated == 1 && to.path === '/') {
//     // redirect the user to the dashboard

//     next({ path: '/u/dashboard' })
//   }
//   //    else if (isAuthenticated == 1 && to.path === '/u/dashboard') {
//   //   // redirect the user to the dashboard
//   //   next({ path: '/u/dashboard' })
//   // }
//    else if (isAuthenticated == 1 && to.path === '/l/home') {
//     // redirect the user to the dashboard
//     next({ path: '/u/dashboard' })
//   }
//  else if (isAuthenticated == 1 && to.path === '/s/login') {
//     // redirect the user to the dashboard
//     next({ path: '/u/dashboard' })
//   }
//   else {
//     next()
//   }

// })


export default router
