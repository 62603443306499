<template>
  <div>
    <div class="loading-wave">
      <div class="loading-bar"></div>
      <div class="loading-bar"></div>
      <div class="loading-bar"></div>
      <div class="loading-bar"></div>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "LoadingScreen",
  props: ["loading", "fullPage"],
  data() {
    return {
      isLoading: false,
      loaderColor: "#FFF",
      loaderBGColor: "#105005",
      loaderBlur: "5px",
    };
  },
  beforeMount() {
    this.isLoading = this.loading;
    // console.log("1");
  },
  mounted() {
    // console.log(this.loading);
    this.isLoading = this.loading;
  },
  created() {
    // console.log("3");
    this.isLoading = this.loading;
  },
  components: {
    Loading,
  },
};
</script>

<style scoped>
.loading-wave {
  width: 300px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.loading-bar {
  width: 20px;
  height: 10px;
  margin: 0 5px;
  background-color: #3498db;
  border-radius: 5px;
  animation: loading-wave-animation 1s ease-in-out infinite;
}

.loading-bar:nth-child(2) {
  animation-delay: 0.1s;
}

.loading-bar:nth-child(3) {
  animation-delay: 0.2s;
}

.loading-bar:nth-child(4) {
  animation-delay: 0.3s;
}

@keyframes loading-wave-animation {
  0% {
    height: 10px;
  }

  50% {
    height: 50px;
  }

  100% {
    height: 10px;
  }
}
</style>