<template>
  <div class="w-auto h-auto pb-12 relative">
    <div
      class="filter_by w-auto md:w-auto overflow-hidden flex flex-row-reverse bg-white mx-3 lg:h-auto md:h-auto md:py-2 py-5 md:px-0 px-5 m-auto rounded-md"
    >
      <div class="w-full md:flex-wrap md:flex lg:flex md:px-3 px-0">
        <div class="md:grid text-left md:mx-2 md:my-2 w-full">
          <!-- <h4 class="hidden md:flex text-gray-500 font-semibold text-xs md:text-xs mb-2">
                        Research Title
                    </h4> -->
          <div
            class="md:mr-4 lg:mr-4 xl:mr-0 flex md:justify-between"
            id="btns"
          >
            <div class="w-full text-lg flex mt-1">
              <h1>
                Search result for
                <span class="font-bold">"{{ this.search }}"</span>...
              </h1>
            </div>
            <div>
              <ul
                @click="toggleMenu($event)"
                class="hidden text-sm font-medium text-center text-gray-500 divide-x divide-gray-200 rounded-lg shadow sm:flex dark:divide-gray-700 dark:text-gray-400"
              >
                <li class="w-full">
                  <button
                    id="tab_All"
                    @click="
                      (hideModule = false),
                        (hideArticle = false),
                        (hideEvent = false)
                    "
                    class="inline-block w-full px-3 py-2 rounded-l-lg focus:ring-1 focus:ring-blue-300 activeTab focus:outline-none"
                    aria-current="page"
                  >
                    All
                  </button>
                </li>
                <li class="w-full">
                  <button
                    id="tab_Modules"
                    @click="
                      (hideModule = false),
                        (hideArticle = true),
                        (hideEvent = true)
                    "
                    class="inline-block w-full px-3 py-2 hover:text-gray-700 hover:bg-gray-50 focus:ring-1 focus:ring-blue-300 focus:outline-none"
                  >
                    Modules
                  </button>
                </li>
                <li class="w-full">
                  <button
                    id="tab_Articles"
                    @click="
                      (hideModule = true),
                        (hideArticle = false),
                        (hideEvent = true)
                    "
                    class="inline-block w-full px-3 py-2 bg-white hover:text-gray-700 hover:bg-gray-50 focus:ring-1 focus:ring-blue-300 focus:outline-none"
                  >
                    Articles
                  </button>
                </li>
                <li class="w-full">
                  <button
                    id="tab_Events"
                    @click="
                      (hideModule = true),
                        (hideArticle = true),
                        (hideEvent = false)
                    "
                    class="inline-block w-full px-3 py-2 bg-white rounded-r-lg hover:text-gray-700 hover:bg-gray-50 focus:ring-1 focus:outline-none focus:ring-blue-300"
                  >
                    Events
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLoading" class="flex justify-center py-8">
      <div class="spinLoader"></div>
    </div>
    <div class="flex flex-row gap-1" v-if="!isLoading">
      <div
        class="w-full bg-white rounded m-auto mt-4 pb-4 ml-3 mr-1"
        :class="
          hideModule
            ? 'hidden'
            : !hideModule && !hideArticle && !hideEvent
            ? ''
            : !hideModule
            ? 'mr-3'
            : ''
        "
        v-if="!isLoading"
      >
        <div class="md:flex bg-slate-100 rounded-xl md:p-0 dark:bg-slate-800">
          <div class="pt-6 md:p-6 text-center md:text-left space-y-4 w-full">
            <h4 class="font-bold border-b pb-3 mb-2">Modules</h4>
            <div>
              <router-link
                v-if="checkDashboard(search)"
                to="/userspage/dashboard"
                class="text-gray-700 group hover:bg-gray-100 bg-white border-gray-200 border focus:ring-1 focus:outline-none focus:ring-105005 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4 mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"
                  />
                </svg>
                Dashboard
              </router-link>
              <!-- <button type="button" v-if="checkDashboard(search)" @click="redirect('UserDashboard')" class="text-gray-700 group hover:bg-gray-100 bg-white border-gray-200 border focus:ring-1 focus:outline-none focus:ring-105005 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-2"> <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" /> </svg> Dashboard </button> -->
              <router-link
                v-if="checkSearchArticle(search)"
                to="/userspage/journal"
                class="text-gray-700 group hover:bg-gray-100 bg-white border-gray-200 border focus:ring-4 focus:outline-none focus:ring-105005 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"
              >
                <Icons :name="'book-open'" class="w-4 h-4 mr-2"></Icons>
                Search Articles
              </router-link>
              <!-- <button type="button" v-if="checkSearchArticle(search)" @click="redirect('UserJournals')" class="text-gray-700 group hover:bg-gray-100 bg-white border-gray-200 border focus:ring-4 focus:outline-none focus:ring-105005 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"> <Icons :name="'book-open'" class="w-4 h-4 mr-2"></Icons> Search Articles </button> -->
              <router-link
                v-if="checkListOfEvents(search)"
                to="/userspage/events"
                class="text-gray-700 group hover:bg-gray-100 bg-white border-gray-200 border focus:ring-4 focus:outline-none focus:ring-105005 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4 mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
                  />
                </svg>
                List of events
              </router-link>
              <!-- <button type="button" v-if="checkListOfEvents(search)" @click="redirect('JournalEvents')"
                                class="text-gray-700 group hover:bg-gray-100 bg-white border-gray-200 border focus:ring-4 focus:outline-none focus:ring-105005 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                    stroke="currentColor" class="w-4 h-4 mr-2">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                                </svg>
                                List of events
                            </button> -->
              <router-link
                v-if="checkUploadArticle(search)"
                to="/userspage/mywork"
                class="text-gray-700 group hover:bg-gray-100 bg-white border-gray-200 border focus:ring-4 focus:outline-none focus:ring-105005 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"
              >
                <Icons
                  :name="'chat-bubble-bottom'"
                  class="w-4 h-4 mr-2"
                ></Icons>
                Upload an Article
              </router-link>
              <!-- <button type="button" v-if="checkUploadArticle(search)" @click="redirect('UserMyWork')"
                                class="text-gray-700 group hover:bg-gray-100 bg-white border-gray-200 border focus:ring-4 focus:outline-none focus:ring-105005 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                                <Icons :name="'chat-bubble-bottom'" class="w-4 h-4 mr-2"></Icons>
                                Upload Article
                            </button> -->
              <router-link
                v-if="checkArticleRequests(search)"
                to="/userspage/requests"
                class="text-gray-700 group hover:bg-gray-100 bg-white border-gray-200 border focus:ring-4 focus:outline-none focus:ring-105005 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"
              >
                <Icons
                  :name="'chat-bubble-bottom'"
                  class="w-4 h-4 mr-2"
                ></Icons>
                Article Requests
              </router-link>
              <!-- <button type="button" v-if="checkArticleRequests(search)" @click="redirect('JournalRequests')"
                                class="text-gray-700 group hover:bg-gray-100 bg-white border-gray-200 border focus:ring-4 focus:outline-none focus:ring-105005 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                                <Icons :name="'chat-bubble-bottom'" class="w-4 h-4 mr-2"></Icons>
                                Article Requests
                            </button> -->
              <router-link
                v-if="checkAnnouncements(search)"
                to="/userspage/announcement"
                :class="
                  this.$store.getters.getUserRole == 4 ||
                  this.$store.getters.getUserRole == 5 ||
                  this.$store.getters.getUserRole == 6 ||
                  this.$store.getters.getUserRole == 7
                    ? 'hidden'
                    : ''
                "
                class="text-gray-700 group hover:bg-gray-100 bg-white border-gray-200 border focus:ring-4 focus:outline-none focus:ring-105005 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"
              >
                <Icons :name="'megaphone'" class="w-4 h-4 mr-2"></Icons>
                Announcements
              </router-link>
              <!-- <button type="button" v-if="checkAnnouncements(search)" @click="redirect('UserAnnouncement')" :class="this.$store.getters.getUserRole == 1 || this.$store.getters.getUserRole == 4 || this.$store.getters.getUserRole == 5 ? 'hidden' : ''" class="text-gray-700 group hover:bg-gray-100 bg-white border-gray-200 border focus:ring-4 focus:outline-none focus:ring-105005 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"> <Icons :name="'megaphone'" class="w-4 h-4 mr-2"></Icons> Announcements </button> -->
              <router-link
                v-if="checkAccounts(search)"
                :class="
                  this.$store.getters.getUserRole == 4 ||
                  this.$store.getters.getUserRole == 5 ||
                  this.$store.getters.getUserRole == 6 ||
                  this.$store.getters.getUserRole == 7
                    ? 'hidden'
                    : ''
                "
                to="/userspage/accverify"
                class="text-gray-700 group hover:bg-gray-100 bg-white border-gray-200 border focus:ring-4 focus:outline-none focus:ring-105005 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"
              >
                <Icons :name="'users'" class="w-4 h-4 mr-2"></Icons>
                Accounts
              </router-link>
              <!-- <button type="button" v-if="checkAccounts(search)" @click="redirect('UserVerify')" :class="this.$store.getters.getUserRole == 1 || this.$store.getters.getUserRole == 4 || this.$store.getters.getUserRole == 5 ? 'hidden' : ''" class="text-gray-700 group hover:bg-gray-100 bg-white border-gray-200 border focus:ring-4 focus:outline-none focus:ring-105005 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"> <Icons :name="'users'" class="w-4 h-4 mr-2"></Icons> Accounts </button> -->
              <router-link
                v-if="checkLogs(search)"
                to="/userspage/logs"
                class="text-gray-700 group hover:bg-gray-100 bg-white border-gray-200 border focus:ring-4 focus:outline-none focus:ring-105005 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4 mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                  />
                </svg>
                Logs
              </router-link>
              <!-- <button type="button" v-if="checkLogs(search)" @click="redirect('UserLogs')" class="text-gray-700 group hover:bg-gray-100 bg-white border-gray-200 border focus:ring-4 focus:outline-none focus:ring-105005 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-2"> <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" /> </svg> Logs </button> -->
              <router-link
                v-if="checkApproveArticle(search)"
                to="/userspage/approval"
                :class="
                  this.$store.getters.getUserRole == 4 ||
                  this.$store.getters.getUserRole == 5 ||
                  this.$store.getters.getUserRole == 6 ||
                  this.$store.getters.getUserRole == 7
                    ? 'hidden'
                    : ''
                "
                class="text-gray-700 group hover:bg-gray-100 bg-white border-gray-200 border focus:ring-4 focus:outline-none focus:ring-105005 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"
              >
                <Icons
                  :name="'chat-bubble-bottom'"
                  class="w-4 h-4 mr-2"
                ></Icons>
                Article Approve
              </router-link>
              <!-- <button type="button" :class="this.$store.getters.getUserRole == 4 || this.$store.getters.getUserRole == 5 ? 'hidden' : ''" v-if="checkApproveArticle(search)" @click="redirect('UserJournalApproval')" class="text-gray-700 group hover:bg-gray-100 bg-white border-gray-200 border focus:ring-4 focus:outline-none focus:ring-105005 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"> <Icons :name="'chat-bubble-bottom'" class="w-4 h-4 mr-2"></Icons> Approve Article </button> -->
              <router-link
                v-if="checkCategory(search)"
                to="/userspage/category"
                :class="
                  this.$store.getters.getUserRole == 4 ||
                  this.$store.getters.getUserRole == 5 ||
                  this.$store.getters.getUserRole == 6 ||
                  this.$store.getters.getUserRole == 7
                    ? 'hidden'
                    : ''
                "
                class="text-gray-700 group hover:bg-gray-100 bg-white border-gray-200 border focus:ring-4 focus:outline-none focus:ring-105005 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"
              >
                <Icons :name="'rectangle-group'" class="w-4 h-4 mr-2"></Icons>
                Article Category
              </router-link>
              <!-- <button type="button" v-if="checkCategory(search)" @click="redirect('JournalCategory')" :class="this.$store.getters.getUserRole > 3 || this.$store.getters.getUserRole <= 5 ? 'hidden' : ''" class="text-gray-700 group hover:bg-gray-100 bg-white border-gray-200 border focus:ring-4 focus:outline-none focus:ring-105005 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"> <Icons :name="'rectangle-group'" class="w-4 h-4 mr-2"></Icons> Article Category </button> -->
              <router-link
                to="/userspage/myprofile"
                v-if="checkAccountSettings(search)"
                class="text-gray-700 group hover:bg-gray-100 bg-white border-gray-200 border focus:ring-4 focus:outline-none focus:ring-105005 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"
              >
                <Icons :name="'user'" class="w-4 h-4 mr-2"></Icons>
                Account Settings
              </router-link>
              <!-- <button type="button" v-if="checkAccountSettings(search)" @click="redirect('UserMyProfile')" class="text-gray-700 group hover:bg-gray-100 bg-white border-gray-200 border focus:ring-4 focus:outline-none focus:ring-105005 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"> <Icons :name="'user'" class="w-4 h-4 mr-2"></Icons> Account Settings </button> -->
            </div>
            <!-- FOR PROGRAM CHAIR AND DEAN -->
            <p
              class="text-sm font-medium text-gray-500 italic"
              :class="
                this.$store.getters.getUserRole == 1 ||
                this.$store.getters.getUserRole == 4 ||
                this.$store.getters.getUserRole == 5 ||
                this.$store.getters.getUserRole == 6 ||
                this.$store.getters.getUserRole == 7
                  ? 'hidden'
                  : ''
              "
              v-if="
                !checkDashboard(search) &&
                !checkSearchArticle(search) &&
                !checkUploadArticle(search) &&
                !checkAnnouncements(search) &&
                !checkAccounts(search) &&
                !checkLogs(search) &&
                !checkApproveArticle(search) &&
                !checkCategory(search) &&
                !checkAccountSettings(search) &&
                !checkArticleRequests(search) &&
                !checkListOfEvents(search)
              "
            >
              No results in modules related to "{{ search }}".
            </p>
            <!-- FOR STUDENTS AND ALUMNI -->
            <p
              class="text-sm font-medium text-gray-500 italic"
              :class="
                this.$store.getters.getUserRole == 1 ||
                this.$store.getters.getUserRole == 2 ||
                this.$store.getters.getUserRole == 3
                  ? 'hidden'
                  : ''
              "
              v-if="
                !checkDashboard(search) &&
                !checkSearchArticle(search) &&
                !checkListOfEvents(search) &&
                !checkUploadArticle(search) &&
                !checkAccountSettings(search) &&
                !checkArticleRequests(search)
              "
            >
              No results in modules related to "{{ search }}".
            </p>
            <!-- FOR SUPERADMIN -->
            <p
              class="text-sm font-medium text-gray-500 italic"
              :class="this.$store.getters.getUserRole != 1 ? 'hidden' : ''"
              v-if="
                !checkDashboard(search) &&
                !checkSearchArticle(search) &&
                !checkListOfEvents(search) &&
                !checkUploadArticle(search) &&
                !checkAnnouncements(search) &&
                !checkAccounts(search) &&
                !checkLogs(search) &&
                !checkApproveArticle(search) &&
                !checkCategory(search) &&
                !checkAccountSettings(search) &&
                !checkArticleRequests(search)
              "
            >
              No results in modules related to "{{ search }}".
            </p>
          </div>
        </div>
      </div>
      <div
        class="w-full bg-white rounded m-auto mt-4 pb-4 mx-1"
        :class="
          hideArticle
            ? 'hidden'
            : !hideModule && !hideArticle && !hideEvent
            ? ''
            : !hideArticle
            ? 'ml-3 mr-3'
            : ''
        "
        v-if="!isLoading"
      >
        <div class="md:flex bg-slate-100 rounded-xl md:p-0 dark:bg-slate-800">
          <div class="pt-6 md:p-6 text-center md:text-left space-y-4 w-full">
            <h4 class="font-bold border-b pb-3">Articles</h4>
            <JournalSearchResult :searchTitle="search" />
          </div>
        </div>
      </div>
      <div
        class="w-full bg-white rounded m-auto mt-4 pb-4 ml-1 mr-3"
        :class="
          hideEvent
            ? 'hidden'
            : !hideModule && !hideArticle && !hideEvent
            ? ''
            : !hideEvent
            ? 'ml-3'
            : ''
        "
        v-if="!isLoading"
      >
        <div class="md:flex bg-slate-100 rounded-xl md:p-0 dark:bg-slate-800">
          <div class="pt-6 md:p-6 text-center md:text-left space-y-4 w-full">
            <h4 class="font-bold border-b pb-3">Events</h4>
            <ul class="divide-y divide-gray-200 dark:divide-gray-700 event_ul">
              <li
                class="sm:pb-4 py-2"
                v-for="item in eventContent.filter((w) =>
                  w.eventName.toLowerCase().includes(this.search.toLowerCase())
                )"
                :key="item"
              >
                <div class="flex items-center space-x-4">
                  <div class="flex-1 min-w-0">
                    <p
                      class="text-sm text-gray-700 break-words dark:text-gray-400 cursor-pointer w-40 hover:underline hover:text-blue-600 hover:cursor-pointer"
                      @click="changeLoc()"
                    >
                      {{ item.eventName }}
                    </p>
                    <p
                      class="text-xs font-medium text-gray-400 truncate dark:text-white"
                    >
                      {{ moment(item.eventAdded).fromNow() }}
                    </p>
                  </div>
                  <div
                    class="inline-flex items-center text-gray-900 dark:text-white"
                  >
                    <span
                      style="font-size: 11px"
                      v-if="item.eventStatus == 1"
                      class="inline-flex items-center rounded-full uppercase bg-green-100 m-0.5 px-4 py-1 text-green-800"
                      >Active</span
                    >
                    <span
                      style="font-size: 11px"
                      v-if="item.eventStatus == 2"
                      class="inline-flex items-center rounded-full uppercase bg-yellow-100 m-0.5 px-4 py-1 text-yellow-800"
                      >Previous</span
                    >
                    <span
                      style="font-size: 11px"
                      v-if="item.eventStatus == 3"
                      class="inline-flex items-center rounded-full uppercase bg-purple-100 m-0.5 px-4 py-1 text-purple-800"
                      >Upcoming</span
                    >
                  </div>
                </div>
                <div
                  class="text-justify text-xs pt-1 text-gray-500"
                  v-if="
                    this.$store.getters.getUserRole == 2 ||
                    this.$store.getters.getUserRole == 3
                  "
                >
                  {{ item.eventDesc }}
                </div>
              </li>
            </ul>
            <p
              class="text-sm font-medium text-gray-500 italic"
              v-if="
                eventContent.filter((w) =>
                  w.eventName.toLowerCase().includes(this.search.toLowerCase())
                ).length == 0
              "
            >
              No results in events related to "{{ search }}".
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JournalSearchResult from "../components/JournalSearchResult.vue";
import Table from "../components/Table.vue";
import axios from "axios";
import moment from "moment";
import Icons from "../components/Icons.vue";
import { useToast } from "vue-toastification";
export default {
  name: "MyWorksTable",
  props: ["searchData"],
  components: {
    Table,
    Icons,
    JournalSearchResult,
  },
  data() {
    return {
      search: this.searchData ? this.searchData : null,
      categoriesList: [],
      contents: [],
      eventContent: [],
      page: 1,
      isLoading: false,
      perPage: 10,
      selectPerPage: [10, 15, 20, 30, 50],
      pages: [],
      fromPage: 1,
      toPage: null,
      results: null,
      percentage: null,
      hideModule: false,
      hideArticle: false,
      hideEvent: false,
    };
  },
  methods: {
    toggleMenu(e) {
      var elems = document.querySelector(".activeTab");
      if (elems !== null) {
        elems.classList.remove("activeTab");
      }
      e.target.classList.add("activeTab");
    },
    loadJournalEvents() {
      axios
        .get(process.env.VUE_APP_API_SERVER + "/journalEvents/getAllEvents", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            this.eventContent = response.data;
            // console.log(response);
            this.results = response.data.length;
          },
          (error) => {
            // console.log(error);
          }
        );
    },
    checkArticleRequests(d) {
      const possibleSearch = [
        "all",
        "request",
        "requests",
        "article",
        "articles",
        "journal",
        "dissertation",
        "requested",
        "research",
      ];
      if (possibleSearch.includes(d.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    checkAccounts(d) {
      const possibleSearch = [
        "all",
        "review",
        "account",
        "verify",
        "verification",
        "accounts",
        "approve",
      ];
      if (possibleSearch.includes(d.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    checkDashboard(d) {
      const possibleSearch = ["all", "dash", "board", "dashboard"];
      if (possibleSearch.includes(d.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    checkSearchArticle(d) {
      const possibleSearch = [
        "all",
        "search",
        "article",
        "articles",
        "journal",
        "research",
        "capstone",
        "dissertation",
        "project",
      ];
      if (possibleSearch.includes(d.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    checkListOfEvents(d) {
      const possibleSearch = ["all", "events", "event", "list"];
      if (possibleSearch.includes(d.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    checkUploadArticle(d) {
      const possibleSearch = [
        "all",
        "upload",
        "article",
        "articles",
        "journal",
        "dissertation",
        "capstone",
        "others",
        "my",
        "work",
        "works",
      ];
      if (possibleSearch.includes(d.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    checkLogs(d) {
      const possibleSearch = ["all", "logs", "log"];
      if (possibleSearch.includes(d.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    checkAnnouncements(d) {
      const possibleSearch = [
        "all",
        "announcement",
        "announce",
        "announcements",
      ];
      if (possibleSearch.includes(d.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    checkApproveArticle(d) {
      const possibleSearch = [
        "all",
        "approve",
        "article",
        "dissertation",
        "capstone",
        "project",
        "research",
        "approval",
      ];
      if (possibleSearch.includes(d.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    checkCategory(d) {
      const possibleSearch = ["all", "category", "categories"];
      if (possibleSearch.includes(d.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    checkAccountSettings(d) {
      const possibleSearch = [
        "all",
        "account settings",
        "settings",
        "account",
        "accounts",
        "profile",
        "setting",
      ];
      if (possibleSearch.includes(d.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    timeToday(d) {
      var today = new Date(d);
      return moment(today).format("ll");
    },
    // initialize data values to default
    init() {
      this.contents = [];
      this.page = 1;
      this.perPage = 10;
      this.pages = [];
      this.fromPage = 1;
      this.toPage = null;
      this.results = null;
    },

    // load all users journals uploaded
    async getMyJournals() {
      this.isLoading = true;
      axios
        .get(process.env.VUE_APP_API_SERVER + "/fileupload/my", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            this.contents = response.data;
            // console.log(response.data);
            this.results = response.data.length;
            this.isLoading = false;
          },
          (error) => {
            console.log(error);
          }
        );
    },

    getProfilePercentage() {
      axios
        .get(process.env.VUE_APP_API_SERVER + "/users-controller", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            var cnt = 0;

            if (
              response.data.userdetail.fname != null &&
              response.data.userdetail.fname != ""
            ) {
              cnt += 1;
            }
            if (
              response.data.userdetail.lname != null &&
              response.data.userdetail.lname != ""
            ) {
              cnt += 1;
            }
            if (
              response.data.userdetail.educ_qual != null &&
              response.data.userdetail.educ_qual != ""
            ) {
              cnt += 1;
            }
            if (
              response.data.userdetail.name_of_school != null &&
              response.data.userdetail.name_of_school != ""
            ) {
              cnt += 1;
            }
            // if (
            //   response.data.position != null &&
            //   response.data.position != ""
            // ) {
            //   cnt += 1;
            // }
            if (
              response.data.userdetail.office != null &&
              response.data.userdetail.office != ""
            ) {
              cnt += 1;
            }
            if (
              response.data.userdetail.designation != null &&
              response.data.userdetail.designation != ""
            ) {
              cnt += 1;
            }

            this.percentage = (parseInt(cnt) / 6) * 100;
            this.percentage = this.percentage.toFixed(2);
          },
          (error) => {
            console.log(error);
          }
        );
    },

    reloadTable() {
      this.getMyJournals();
      this.setPages();
    },
    // toggle modal to view reason for the pending status of journal
    viewJournal(item) {
      this.journalID = item;
      this.toggleModal = true;
    },

    toggleAddModal(action, item) {
      const toast = useToast();
      if (this.checkProfilePercentage) {
        this.$emit("toggleAddModal");
        const toEmit = {
          item: item,
          action: action,
        };
        this.$emit("action", toEmit);
      } else {
        toast.error("Please complete your profile to continue.", {
          position: "top-center",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },

    toggleUpdateModal(action, item) {
      const toast = useToast();
      if (this.checkProfilePercentage) {
        this.$emit("toggleUpdateModal");
        const toEmit = {
          item: item,
          action: action,
        };
        this.$emit("action", toEmit);
      } else {
        toast.error("Please complete your profile to continue.", {
          position: "top-center",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },

    journalToUpdate(item) {
      this.toggleModal = false;
      this.toggleAddModal("update", item);
    },

    // format date to display
    formatDate(date) {
      const d = new Date(date);
      var dd = d.getDate();
      if (dd < 10) {
        dd = "0" + dd;
      }
      var mm = d.getMonth() + 1;
      if (mm < 10) {
        mm = "0" + mm;
      }
      var yy = d.getFullYear();
      return mm + "-" + dd + "-" + yy;
    },

    handleSearching() {
      if (this.searchText == "" || !this.searchText) {
        this.getMyJournals();
        this.setPages();
      } else {
        this.contents = this.contents.filter(
          (data) =>
            data.file_title
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) !== -1 ||
            data.file_createdAt.indexOf(this.searchText) !== -1
        );
        this.results = this.contents.length;
        this.setPages();
      }
    },

    // set number of pages to paginate
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(
        parseInt(this.contents.length) / this.perPage
      );
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    // handles the from and to of pagination table
    paginate(contents) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;

      if (to > this.contents.length) {
        this.fromPage = from + 1;
        this.toPage = this.contents.length;
      } else {
        this.fromPage = from + 1;
        this.toPage = to;
      }

      return contents.slice(from, to);
    },
  },

  computed: {
    displayedJournals() {
      return this.paginate(this.contents);
    },
    computeStatus() {
      return this.init();
    },
    checkProfilePercentage() {
      return this.percentage == 100;
    },
  },
  watch: {
    searchData: {
      handler(val) {
        if (val) {
          this.search = val;
        }
      },
      immediate: true,
    },
  },
  created() {
    this.moment = moment;
    this.getMyJournals();
    this.setPages();
    this.getProfilePercentage();
  },
  mounted() {
    this.loadJournalEvents();
  },
};
</script>

<style scoped>
.activeTab {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.unactive {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.tbl_header {
  background-color: #105005;
}

.indent {
  text-indent: 0.5rem;
}

/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}

/* extra small resolution */
@media only screen and (min-width: 150px) and (max-width: 340px) {
  /* #dropdown {
    background: red;
    top: 2.3%;
    left: 20%;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  } */

  .addBTN {
    visibility: hidden;
  }
}

/* small resolution */
@media only screen and (min-width: 340px) and (max-width: 500px) {
  /* #dropdown {
    background: orange;
    top: 0.5%;
    left: 30%;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  } */

  .addBTN {
    visibility: hidden;
  }
}

/* medium resolution */
@media only screen and (min-width: 500px) and (max-width: 768px) {
  /* #dropdown {
    background: yellow;
    top: 0.5%;
    left: 48%;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  } */

  .addNewArticle {
    font-size: 11px;
    word-break: keep-all;
  }

  .addBTN {
    visibility: hidden;
  }
}

/* large resolution */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* #dropdown {
    background: green;
    top: 1.6%;
    left: 60.9%;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  } */

  .addBTN {
    visibility: hidden;
  }
}

/* xtra large resolution */
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  /* #dropdown {
    background: blue;
    top: 2.2%;
    left: 58.5%;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  } */

  .addBTN1 {
    visibility: hidden;
  }
}

/* 2xtra large resolution */
@media only screen and (min-width: 1280px) and (max-width: 1536px) {
  /* #dropdown {
    background: indigo;
    top: 2.3%;
    left: 59%;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  } */

  .addBTN1 {
    visibility: hidden;
  }
}

@media only screen and (min-width: 1536px) and (max-width: 1700px) {
  /* #dropdown {
    background: pink;
    top: 3.8%;
    left: 57%;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  } */

  .addBTN1 {
    visibility: hidden;
  }
}

@media only screen and (min-width: 1700px) and (max-width: 2500px) {
  /* #dropdown {
    background: burlywood;
    top: 9%;
    left: 46.9%;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  } */

  .addBTN1 {
    visibility: hidden;
  }
}
</style>
