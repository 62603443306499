<template>
  <router-view />
</template>

<script>
import axios from "axios";
export default {
  methods: {
    getUser(token) {
      axios
        .get(process.env.VUE_APP_API_SERVER + "/users-controller/getCurUser", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(
          (response) => {
            console.log(response.data);
            if (response) {
              this.$store.commit("setExpiryDate");
              this.$store.commit("setToken", token);
              this.$store.commit("setIsValidated", true);
              this.$store.commit(
                "setUserType",
                response.data.user.userdetail.userType
              );
              this.$store.commit("setUserEmail", response.data.user.email);
              this.$store.commit("setUserID", response.data.user.id);
              this.$store.commit(
                "setUserdetailID",
                response.data.user.userdetailID
              );
              this.$store.commit(
                "setUserFirstName",
                response.data.user.userdetail.fname
              );
              this.$store.commit(
                "setUserMidName",
                response.data.user.userdetail.mname
              );
              this.$store.commit(
                "setUserLastName",
                response.data.user.userdetail.lname
              );
              this.$store.commit(
                "setUserRole",
                response.data.user.userdetail.role.id
              );

              this.$store.commit(
                "setIsAdminVerified",
                response.data.user.userdetail.isAdminVerified
              );

              // this.$router.push("/userspage/dashboard");
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
  created() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    axios;
    const token = urlParams.get("token");
    if (token) {
      localStorage.setItem("token", token);
      this.getUser(token);
      this.$router.push("/");
      // window.location.reload();
    }
    if (this.$store.getters.isExpired) {
      this.$router.push("/logout");
    }
    // else {
    //   this.$router.push("/userspage/dashboard");
    // }
  },
};
</script>
<style>
html {
  font-family: "Roboto", sans-serif !important;
}
.bgColor {
  background-color: #105005;
}
.modal-backdrop {
  z-index: -1;
}
.bgModal {
  background-color: rgba(0, 0, 0, 0.5);
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(0%);
  }
  100% {
    opacity: 1;
    transform: translateX(100%);
  }
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    transform: translateX(100%);
  }
  100% {
    opacity: 0;
    transform: translateX(0);
  }
}

@keyframes fadeInAlert {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOutAlert {
  0% {
    opacity: 0;
    transform: translateX(0);
  }
  100% {
    opacity: 1;
    transform: translateX(100%);
  }
}

/* button loader */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.sidebar_r {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
.alertsIn {
  -webkit-animation-name: fadeInAlert;
  animation-name: fadeInAlert;
}

.alertsOut {
  -webkit-animation-name: fadeOutAlert;
  animation-name: fadeOutAlert;
}

.modal_open {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
.modal_close {
  -webkit-animation-name: fadeDown;
  animation-name: fadeDown;
}

.mobile_nav_open {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
.mobile_nav_close {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}
.animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  /* -webkit-animation-fill-mode: both;
            animation-fill-mode: both; */
}

.btnloader {
  border: 2px solid #fff;
  border-radius: 60%;
  border-top: 2px solid #105005;
  /* width: 250px;
  height: 250px; */
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.signuploader {
  border: 2px solid #fff;
  border-radius: 60%;
  border-top: 2px solid #7876ff;
  /* width: 250px;
  height: 250px; */
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

.spinLoader {
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #105005; /* green */

  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}
/* SCROLL BAR CUSTOMIZE*/
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5a5e59;
  /* border-radius: 5px; */
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #3add58;
} */
</style>
