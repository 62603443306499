<template>
  <div>
    <BarChart
      style="height: 35rem"
      v-bind:chartData="chartData"
      :chartOptions="chartOptions"
    />
  </div>
</template>

<script>
import BarChart from "../charts/BarChart.vue";
export default {
  name: "UploadStatBarChart",
  props: {
    chartDataprop: {
      type: Object,
      required: true,
    },
  },
  components: {
    BarChart,
  },
  data() {
    return {
      chartData: {},
      chartOptions: {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                max: 100,
                callback: function (value) {
                  return ((value / this.max) * 100).toFixed(0) + "%"; // convert it to percentage
                },
                beginAtZero: true,
                stepSize: 10,
              },
            },
          ],
        },
      },
    };
  },

  watch: {
    chartDataprop: {
      handler(val) {
        this.chartData = val;
        // console.log(this.chartData)
        //  console.log(val)
      },
      immediate: true,
    },
  },
};
</script>

<style>
</style>