<template>
    <div class="w-auto">
        <div
            class=" grid md:flex items-center sm:xl:justify-between md:justify-between xl:justify-between lg:justify-between bg-gray-100 mb-2 mt-2 rounded-md ">
            <div class="w-1/2">
                <h1 class=" md:text-xl text-xs lg:text-xl font-semibold py-5 pl-6 text-gray-900 ">
                    User Search
                </h1>
                <h1 class="text-sm font-normal ml-7 -mt-4 text-gray-500 mb-10">
                    Search Results
                </h1>
            </div>
            <div
                class=" hidden md:flex md:justify-end lg:flex lg:justify-end xl:flex xl:justify-end sm:hidden mr-3 -mt-6 overflow-hidden ">
                <div class="relative sm:w-60 md:w-96 px-4">
                    <input type="text" v-model="searchval" @keypress.enter="searchBtn"
                        class=" block py-1 px-2 w-full z-20 text-sm text-gray-600 bg-gray-50 rounded-lg border-l-gray-50 border-l-2 border border-gray-300"
                        placeholder="Search by anything" required />
                    <button type="submit" @click="searchBtn"
                        class=" absolute top-0 right-0 py-1 px-2 text-sm font-medium text-white bg-customGreen rounded-tr-lg rounded-br-lg border focus:ring-4 focus:outline-none focus:ring-blue-300 ">
                        <svg aria-hidden="true" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                        </svg>
                        <span class="sr-only">Search</span>
                    </button>
                </div>
            </div>
        </div>

        <SearchResult v-on:toggleAddModal="openAddModal" v-on:toggleUpdateModal="openUpdateModal" v-on:action="action" :searchData="searchData"
            :refreshtable="refreshData" />

    </div>
</template>


<script>
import MyWorksTable from "../components/MyWorksTable.vue";
import SearchResult from '../components/SearchResult.vue';
import MyWorksModal from "../modals/MyWorksModal.vue";
import MyWorkUpdateModal from '../modals/MyWorkUpdateModal.vue';
import { useToast } from "vue-toastification";
export default {
    name: "UserSearch",
    components: {
        SearchResult,
        MyWorksTable,
        MyWorksModal,
        MyWorkUpdateModal,
        
    },
    data() {
        return {
            showUpdateModal: false,
            showAddModal: false,
            isLoading: false,
            modalAction: "",
            item: null,
            refreshData: false,
            searchval: this.$route.params.data_text? this.$route.params.data_text: null,
            searchData: null,
        };
    },

    mounted() {
        this.searchval = this.$route.params.data_text;
    },
    watch: {
         "$route.params.data_text": {
            handler(value) {
                //  console.log(value);
                 this.searchval = value;
                 this.searchData = value;
            },
            immediate: true,
        },
    },

    methods: {
        searchBtn() {
            if (this.searchval == null || this.searchval == "") {
                return;
            } else {
                this.searchData = this.searchval; 
            }
        },
        openAddModal() {
            const toast = useToast();
            if (this.$store.getters.getIsAdminVerified) {
                this.showAddModal = true;
            } else {
                toast.error("You are not verified by the admin yet", {
                    position: "top-center",
                    timeout: 3000,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: false,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: false,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                });
            }
        },

        openUpdateModal() {
            this.showUpdateModal = true;
        },

        closeAddModal() {
            this.showAddModal = false;
        },
        closeUpdateModal() {
            this.showUpdateModal = false;
        },
        refreshTable() {
            this.refreshData = true;
            setTimeout(() => {
                this.refreshData = false;
            }, 100);
        },
        action(act) {
            if (act.action == "add") {
                this.modalAction = "Add New Journal";
                this.item = null;
            } else {
                this.modalAction = "Update Journal";
                this.item = act.item;
            }
        },
    },
};
</script>

<style scoped>
.customSearch {
    width: 20rem;
}

.modal_hgt {
    height: 36rem;
}

.form_modal_hgt {
    height: 26rem;
}
</style>