<template>
  <div class="w-auto">
    <div class=" grid md:flex items-center sm:xl:justify-between md:justify-between xl:justify-between lg:justify-between bg-gray-100 mb-2 mt-2 rounded-md " >
      <div class="w-1/2">
        <h1 class=" md:text-xl text-xs lg:text-xl font-semibold py-5 pl-6 text-gray-900 " >
          My Works - Announcements
        </h1>
        <h1 class="text-sm font-normal ml-7 -mt-4 text-gray-500 mb-10">
          List of Announcement
        </h1>
      </div>
      <div class=" hidden md:flex md:justify-end lg:flex lg:justify-end xl:flex xl:justify-end sm:hidden mr-3 -mt-6 overflow-hidden " >
        <div class="relative sm:w-60 md:w-96 px-4">
          <input type="text" v-model="searchval" @keypress.enter="searchBtn" class=" block py-1 px-2 w-full z-20 text-sm text-gray-600 bg-gray-50 rounded-lg border-l-gray-50 border-l-2 border border-gray-300 focus:ring-green-500 focus:border-green-500 " placeholder="Search by anything" required />
          <button type="submit" @click="searchBtn" class=" absolute top-0 right-0 py-1 px-2 text-sm font-medium text-white bg-customGreen rounded-tr-lg rounded-br-lg border focus:ring-4 focus:outline-none focus:ring-blue-300 " >
            <svg aria-hidden="true" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" ></path>
            </svg>
            <span class="sr-only">Search</span>
          </button>
        </div>
      </div>
    </div>

    <AnnouncementTable v-on:toggleAddModal="openAddModal" v-on:action="action" v-on:updToDisplayAnnouncement="updToDisplayAnnouncement" :refreshtable="refreshData" :notifID="notifID" />

    <!-- Modal for adding journal -->
    <AnnouncementModal id="announcementModal" v-if="showAddModal" v-on:closeModal="closeModal" :action="modalAction" :itemToUpdate="item" v-on:refreshTable="refreshTable" />

    <UpdateAnnouncementToDisplay v-if="showUpdateToDisplayModal" v-on:closeModal="closeUpdateAnnouncementModal" v-on:refreshTable="refreshTable" :itemToUpdate="announcementToDisplay" />
  </div>
</template>


<script>
import AnnouncementTable from "../components/AnnouncementTable.vue";
import AnnouncementModal from "../modals/AnnouncementModal.vue";
import UpdateAnnouncementToDisplay from "../modals/UpdateAnnouncementToDisplay.vue";
import { useToast } from "vue-toastification";
export default {
  name: "UserAnnouncement",
  components: {
    AnnouncementTable,
    AnnouncementModal,
    UpdateAnnouncementToDisplay,
  },
  data() {
    return {
      showAddModal: false,
      showUpdateToDisplayModal: false,
      announcementToDisplay: null,
      isLoading: false,
      modalAction: "",
      item: null,
      refreshData: false,
      notifID: null,
      isUpdatedUser: false,
      searchval: null
    };
  },

  mounted() {
    // console.log(this.$route.params.fileID);
    this.notifID = this.$route.params.fileID;
    // this.refreshData = true;
  },
  watch: {
    "$route.params.fileID": {
      handler(value) {
        // console.log(value);
        this.notifID = value;
      },
    },
  },
  //

  methods: {
    searchBtn() {
      if (this.searchval == null || this.searchval == "") {
        return;
      } else {
        this.$router.push({
          name: "UserSearch",
          params: { data_text: this.searchval },
        });
      }
    },
    openAddModal() {
      this.showAddModal = true;
    },

    closeModal() {
        this.showAddModal = false;
    },

    closeUpdateAnnouncementModal() {
      this.showUpdateToDisplayModal = false;
    },

    updToDisplayAnnouncement(announce) {
      // console.log(announce);
      this.announcementToDisplay = announce;
      this.showUpdateToDisplayModal = true;
    },
    refreshTable() {
      this.refreshData = true;
      setTimeout(() => {
        this.refreshData = false;
      }, 500);
    },
    action(act) {
      if (act.action == "add") {
        this.modalAction = "Add";
        this.item = null;
      } else {
        this.modalAction = "Update";
        this.item = act.item;
      }
    },
  },
};
</script>

<style scoped>
.customSearch {
  width: 20rem;
}
.modal_hgt {
  height: 36rem;
}
.form_modal_hgt {
  height: 26rem;
}
</style>