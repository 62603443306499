<template>
  <div class="grid w-full">
    <!-- NOT LOGGED IN -->
    <div class="bg-white dark:bg-gray-300 px-5" v-if="!isLogged">
      <div class="md:flex md:justify-end mt-2 mx-10 md:mx-48">
        <div
          class="hidden md:w-full md:flex sm:flex lg:flex xl:flex 2xl:flex w-full mt-2 -mb-6"
        >
          <nav
            class="md:flex -ml-4 md:-ml-0 h-8 md:mt-10 lg:mt-0 xl:mt-0 2xl:mt-0"
            aria-label="Breadcrumb"
          >
            <ol
              role="list"
              class="flex space-x-1 sm:space-x-1 md:space-x-1 lg:space-x-4 xl:space-x-4 2xl:space-x-4 rounded-md px-6"
            >
              <li
                class="flex md:flex-col md:flex lg:flex-row xl:flex-row 2xl:flex-row md:items-center"
              >
                <router-link
                  to="/landing/home"
                  class="text-blue-600 hover:text-gray-500"
                  title="Dashboard"
                >
                  <svg
                    class="h-5 w-5 flex-shrink-0 md:mt-1.5 lg:mt-0 xl:mt-0 2xl:mt-0"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </router-link>
              </li>
              <!-- <li class="flex  items-center"> <div class="flex items-center"> <svg class="h-5 w-5 flex-shrink-0 text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true"> <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" /> </svg> <a href="#" @click="back" class="ml-4 text-xs font-medium text-blue-600 hover:text-gray-700">Approval</a> </div> </li> -->
              <li class="flex items-center">
                <div class="flex items-center">
                  <svg
                    class="h-5 w-5 flex-shrink-0 text-gray-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                  <a
                    href="#"
                    class="md:ml-0 lg:ml-4 text-xs font-medium text-blue-600 hover:text-gray-700"
                    @click="back"
                    >Researches</a
                  >
                </div>
              </li>
              <li class="flex items-center" v-if="toggleBreadcrumbs == false">
                <div class="flex items-center">
                  <svg
                    class="h-5 w-5 flex-shrink-0 text-gray-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                  <a
                    href="#"
                    class="md:ml-0 lg:ml-4 text-xs font-medium text-gray-500"
                    >See details</a
                  >
                </div>
              </li>
              <li
                class="flex items-center"
                v-if="toggleBreadcrumbs == true"
                @click="goBack()"
              >
                <div class="flex items-center">
                  <svg
                    class="h-5 w-5 flex-shrink-0 text-gray-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                  <a href="#" class="ml-4 text-xs font-medium text-blue-600"
                    >See details</a
                  >
                </div>
              </li>
              <li class="flex items-center" v-if="toggleBreadcrumbs == true">
                <div class="flex items-center">
                  <svg
                    class="h-5 w-5 flex-shrink-0 text-gray-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                  <a href="#" class="ml-4 text-xs font-medium text-gray-500"
                    >View journals</a
                  >
                </div>
              </li>
            </ol>
          </nav>
        </div>
        <div class="w-full flex justify-end ml-14">
          <button
            v-if="journalData.isPublished && isLogged && journalData.doi"
            @click="redirect(journalData)"
            class="text-xs py-1 mt-2 mr-4 px-1 bg-transparent bg-FFB800 hover:bg-FFB800 text-white font-semibold hover:text-white border border-FFB800 hover:border-transparent rounded"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 -mt-1 inline"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
              />
            </svg>
            Download
          </button>
          <button
            @click="back"
            title="Back"
            class="text-xs py-1 px-2 mt-2 mr-5 bg-transparent text-gray-500 border bg-gray-300 border-gray-300 rounded"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 -mt-1 inline"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
              />
            </svg>
            Back
          </button>

          <!-- if user is not logged in -->
          <button
            v-if="journalData.isPublished && !isLogged"
            @click="openModal"
            :class="journalData.isPublished == 1 ? 'px-5' : ''"
            class="text-xs py-1 mr-4 mt-2 px-1 bg-yellow-500 text-white hover:bg-FFB800 font-semibold hover:text-white border border-FFB800 hover:border-transparent rounded"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 -mt-1 inline"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
              />
            </svg>
            Download
          </button>
        </div>
      </div>
      <div v-if="isLoading" class="flex justify-center md:py-8">
        <div class="spinLoader"></div>
      </div>

      <div class="grid md:flex lg:flex w-auto py-6 md:mx-48">
        <div
          class="md:grid md:grid-cols-3 md:gap-5 lg:gap-7 xl:gap-10 2xl:gap-10"
        >
          <div
            style="height: 778px"
            v-show="isViewJournal"
            :class="
              isLoading == true
                ? 'invisible'
                : isViewJournal == true
                ? 'visible'
                : 'invisible'
            "
            class="unselectable2 w-full h-auto overflow-y-auto md:mb-4 md:w-full md:grid md:col-span-2 text-left px-4"
          >
            <FileViewer
              v-if="isViewJournal"
              :filename="filename"
              :filelink="filelink"
              class="w-full"
            />
          </div>

          <div
            v-show="!isViewJournal"
            :class="
              isLoading == true
                ? 'invisible'
                : journalData.isPublished == 1
                ? 'visible w-full'
                : journalData.isPublished == 0
                ? 'visible w-full'
                : ''
            "
            class="bg-white md:w-full rounded-md shadow-md border mr-2 md:mr-5 md:ml-4 md:mb-4 md:grid md:col-span-2 text-left px-7"
          >
            <div class="mt-5 md:mt-5">
              <h1
                class="text-2xl md:text-4xl pt-5 font-semibold text-justify md:w-full lg:w-full"
              >
                {{ journalData.title }}
              </h1>
            </div>
            <div class="mt-4 md:mt-4">
              <!-- START OF JOURNAL PUBLISHED -->
              <span
                v-if="journalData.isPublished == 0"
                class="inline-flex items-center rounded-full uppercase bg-red-100 m-0.5 px-4 py-1 text-xs text-red-800"
                >Unpublished</span
              >
              <span
                v-if="journalData.isPublished == 1"
                class="inline-flex items-center rounded-full uppercase bg-green-100 m-0.5 px-4 py-1 text-xs text-green-800"
                >Published</span
              >
              <!-- END OF JOURNAL PUBLISHED -->

              <!-- START OF JOURNAL STATUS  -->
              <!-- <span v-if="journalData.journalstatusID.id == 1"
            class="inline-flex items-center rounded-full uppercase bg-blue-100 m-0.5 px-4 py-1 text-xs text-blue-800">For
            Approval</span> -->
              <span
                v-if="journalData.journalstatusID.id == 2"
                class="inline-flex items-center rounded-full uppercase bg-blue-100 m-0.5 px-4 py-1 text-xs text-blue-800"
                >Verified</span
              >
              <!-- <span v-if="journalData.journalstatusID.id == 3"
            class="inline-flex items-center rounded-full uppercase bg-blue-100 m-0.5 px-4 py-1 text-xs text-blue-800">Pending</span> -->
              <!-- <span v-if="journalData.journalstatusID.id == 4"
            class="inline-flex items-center rounded-full uppercase bg-blue-100 m-0.5 px-4 py-1 text-xs text-blue-800">Endorsed</span> -->
              <!-- <span v-if="journalData.journalstatusID.id == 5"
            class="inline-flex items-center rounded-full uppercase bg-blue-100 m-0.5 px-4 py-1 text-xs text-blue-800">Approved
            By Librarian</span> -->
              <!-- END OF JOURNAL STATUS  -->

              <!-- START OF JOURNAL TYPE  -->
              <span
                v-if="journalData.typeOfArticle == 1"
                class="inline-flex items-center rounded-full uppercase bg-purple-100 m-0.5 px-4 py-1 text-xs text-purple-800"
                >Thesis</span
              >
              <span
                v-if="journalData.typeOfArticle == 2"
                class="inline-flex items-center rounded-full uppercase bg-purple-100 m-0.5 px-4 py-1 text-xs text-purple-800"
                >Capstone</span
              >
              <span
                v-if="journalData.typeOfArticle == 3"
                class="inline-flex items-center rounded-full uppercase bg-purple-100 m-0.5 px-4 py-1 text-xs text-purple-800"
                >Dissertation</span
              >
              <span
                v-if="journalData.typeOfArticle == 4"
                class="inline-flex items-center rounded-full uppercase bg-purple-100 m-0.5 px-4 py-1 text-xs text-purple-800"
                >Research</span
              >
              <span
                v-if="journalData.typeOfArticle == 5"
                class="inline-flex items-center rounded-full uppercase bg-purple-100 m-0.5 px-4 py-1 text-xs text-purple-800"
                >Journal</span
              >
              <span
                v-if="journalData.typeOfArticle == 6"
                class="inline-flex items-center rounded-full uppercase bg-purple-100 m-0.5 px-4 py-1 text-xs text-purple-800"
                >Others</span
              >
              <!-- END OF JOURNAL TYPE  -->

              <!-- <a href="#" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Cite"
            class="transition duration-150 ease-in-out float-right font-bold mt-1 text-sm bg-yellow-500 hover:text-yellow-200 rounded w-auto px-2 py-1 m-1 text-white top-0">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-4 h-4 inline">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
            </svg>
            Cite this journal
          </a> -->
            </div>

            <div class="mt-4 md:mt-4" v-if="journalData.publisher != null">
              <h1 class="text-green text-sm">
                Publisher:
                <span class="text-gray-600"> {{ journalData.publisher }}</span>
              </h1>
            </div>

            <div class="mt-4 md:mt-4">
              <h1 class="text-green text-sm">
                Author:
                <span class="text-gray-600">
                  {{
                    journalData.mainauthorlname
                      ? journalData.mainauthorlname + ", "
                      : ""
                  }}
                  {{ journalData.mainauthorfname }}
                  {{
                    journalData.mainauthormname
                      ? journalData.mainauthormname[0] + "."
                      : ""
                  }}
                </span>
              </h1>
            </div>

            <div class="mt-4 md:mt-2">
              <h1 class="text-green text-sm">
                Corresponding Author:
                <span class="text-gray-600">
                  {{ journalData.corLname ? journalData.corLname + ", " : "" }}
                  {{ journalData.corFname }}
                  {{
                    journalData.corMname ? journalData.corMname[0] + "." : ""
                  }}
                </span>
              </h1>
            </div>

            <div class="mt-4 md:mt-2">
              <h1 class="text-green text-sm">
                Co-author/s:
                <span class="text-gray-600"
                  >{{ coauthors ? coauthors : "-" }}
                </span>
              </h1>
            </div>
            <div class="mt-4 md:mt-2" v-if="journalData.publisher != null">
              <h1 class="text-green text-sm">
                Keywords:
                <span class="text-gray-600">
                  {{ journalData.authorsKeywords }}</span
                >
              </h1>
            </div>
            <div
              class="flex flex-wrap justify-between text-xs pb-1 pt-3 -mt-2"
              v-if="journalData.isPublished == 1"
            >
              <!-- <h1 class="text-green text-sm">Cite this journal:</h1> -->
              <select
                v-model="choiceOfFormat"
                @change="showCite(choiceOfFormat)"
                class="w-full rounded-lg outline-none border focus:border-yellow-300 h-10 pl-2 mt-2 text-sm"
              >
                <option value="0" class="text-xs">
                  Choose a format to cite:
                </option>
                <option value="1" class="text-xs">MLA</option>
                <option value="2" class="text-xs">
                  APA <span>Seventh Edition</span>
                </option>
                <option value="3" class="text-xs">Vancouver</option>
                <option value="4" class="text-xs">Harvard</option>
                <option value="5" class="text-xs">Chicago</option>
                <option value="6" class="text-xs">IEEE</option>
              </select>
              <!-- <div :class="citationDiv ? 'transform rotate-0 duration-150' : 'transform rotate-180 duration-150'">
              <Icons :name="'chevron-up'" class="w-4 h-4"></Icons>
            </div> -->
            </div>
            <div
              id="citationDiv"
              v-show="journalData.isPublished == 1 && !optionHide"
            >
              <div>
                <div
                  class="text-gray-600 text-justify text-sm border p-2 rounded cursor-pointer"
                  @click="selectAll(this.choiceText)"
                >
                  <p>{{ this.choiceText }}</p>
                </div>
              </div>
            </div>
            <hr class="mt-4 md:mt-4" />

            <div class="mt-4 md:mt-4">
              <h1 class="text-sm font-semibold md:-mt-2 md:mb-2">Abstract</h1>
              <p class="text-justify text-sm text-gray-600">
                {{ journalData.abstract }}
              </p>
            </div>
            <div class="mt-4 text-sm grid grid-cols-1 gap-4">
              <div
                class="w-full flex justify-between"
                v-if="journalData.publisher"
              >
                <div class="font-semibold">Publisher:</div>
                <div class="text-blue-800">
                  {{ journalData.publisher ? journalData.publisher : "" }}
                </div>
              </div>
              <div class="w-full flex justify-between">
                <div class="font-semibold">INSPEC Accession Number:</div>
                <div class="text-gray-500" v-if="journalData.isPublished">
                  {{ journalData.accessionNumber }}
                </div>
                <!-- <div
                  v-if="this.$store.getters.getUserRole > 1"
                  class="text-gray-500"
                  :class="
                    journalData.isPublished == 0
                      ? this.$store.getters.getUserdetailID !=
                        journalData.userID
                        ? journalData.journalpermission != null
                          ? journalData.journalpermission.isApproved == 1
                            ? ''
                            : 'italic text-xs'
                          : 'italic text-xs'
                        : ''
                      : ''
                  "
                >
                  {{
                    journalData.isPublished == 0
                      ? journalData.userID !=
                        this.$store.getters.getUserdetailID
                        ? journalData.journalpermission != null
                          ? journalData.journalpermission.isApproved == 1
                            ? journalData.accessionNumber
                            : "hidden"
                          : "hidden"
                        : journalData.accessionNumber
                      : journalData.accessionNumber != null
                      ? journalData.accessionNumber
                      : ""
                  }} 

                  {{
                    journalData.isPublished == 0
                      ? journalData.userID !=
                        this.$store.getters.getUserdetailID
                        ? journalData.journalpermission != null
                          ? journalData.journalpermission.isApproved == 1
                            ? journalData.accessionNumber != ""
                              ? journalData.accessionNumber
                              : ""
                            : "hidden"
                          : "hidden"
                        : this.$store.getters.getUserRole == 1
                        ? journalData.accessionNumber
                        : "hidden"
                      : journalData.accessionNumber != ""
                      ? journalData.accessionNumber
                      : ""
                  }}
                </div> -->
              </div>
              <div
                class="w-full flex justify-between"
                v-if="journalData.conferenceDate != ''"
              >
                <div class="font-semibold">Year submitted:</div>
                <div class="text-gray-500">
                  {{
                    journalData.conferenceDate != ""
                      ? formatYear(journalData.conferenceDate)
                      : "-"
                  }}
                </div>
              </div>
              <div
                class="w-full flex justify-between"
                v-if="journalData.doi != '' && journalData.doi != null"
              >
                <div class="font-semibold">DOI:</div>
                <div class="text-gray-500">
                  {{ journalData.doi ? journalData.doi : "" }}
                </div>
              </div>
              <div
                class="w-full flex justify-between"
                v-if="journalData.createdAt != ''"
              >
                <div class="font-semibold">Date Uploaded to ReDMaS:</div>
                <div class="text-gray-500">
                  {{ formatDate(journalData.createdAt) }}
                </div>
              </div>
            </div>

            <!-- <div class="mt-4 md:mt-4 grid md:grid lg:flex m-auto w-full">
              <div class="mt-4 flex w-full md:pr-10">
                <h1
                  class="text-left w-full font-semibold text-sm mr-0 sm:mr-0 md:mr-5 lg:mr-0 xl:mr-0 2xl:mr-0"
                >
                  Published in:
                </h1>
                <h1 class="text-blue-800 text-sm w-full text-left">
                  {{
                    journalData.isPublished
                      ? journalData.publishedIn
                      : "Not published"
                  }}
                </h1>
              </div>
              <div class="mt-4 flex w-full md:pr-10">
                <h1
                  class="font-semibold w-full text-left text-sm mr-0 sm:mr-0 md:mr-5 lg:mr-0 xl:mr-0 2xl:mr-0"
                >
                  INSPEC Accession Number:
                </h1>
                <h1 class="text-gray-800 text-sm w-full text-left">
                  {{
                    journalData.accessionNumber
                      ? journalData.accessionNumber
                      : "-"
                  }}
                </h1>
              </div>
            </div>

            <div class="mt-3 md:mt-1 grid lg:flex md:grid m-auto w-full">
              <div class="flex w-full pr-10">
            <h1 class="text-left w-full font-semibold text-sm">
              Date Conferred:
            </h1>
            <h1 class="text-gray-800 text-sm w-full text-left">
              {{ formatDate(journal_item.conferenceDate) }}
            </h1>
          </div>
              <div class="mt-4 flex w-full md:pr-10">
                <h1
                  class="font-semibold w-full text-sm mr-0 sm:mr-0 md:mr-5 lg:mr-0 xl:mr-0 2xl:mr-0"
                >
                  Date Conferred:
                </h1>
                <h1 class="text-gray-800 text-sm w-full">
                  {{
                    journalData.conferenceDate != ""
                      ? formatDate(journalData.conferenceDate)
                      : "-"
                  }}
                </h1>
              </div>
              <div class="mt-4 flex w-full md:pr-10">
                <h1
                  class="font-semibold w-full text-sm mr-0 sm:mr-0 md:mr-5 lg:mr-0 xl:mr-0 2xl:mr-0"
                >
                  DOI:
                </h1>
                <h1 class="text-gray-800 text-sm w-full">
                  {{ journalData.doi ? journalData.doi : "-" }}
                </h1>
              </div>
            </div>

            <div class="mt-4 md:mt-1 lg:flex md:grid m-auto w-full">
              <div class="mt-4 flex w-full md:pr-10">
                <h1
                  class="text-left w-full font-semibold text-sm mr-0 sm:mr-0 md:mr-5 lg:mr-0 xl:mr-0 2xl:mr-0"
                >
                  Date Added to ReDMaS:
                </h1>
                <h1 class="text-gray-800 text-sm w-full">
                  {{ formatDate(journalData.createdAt) }}
                </h1>
              </div>
              <div class="mt-4 flex w-full md:pr-10">
                <h1
                  class="font-semibold w-full text-sm mr-0 sm:mr-0 md:mr-5 lg:mr-0 xl:mr-0 2xl:mr-0"
                >
                  Conference Location:
                </h1>
                <h1 class="text-gray-800 text-sm w-full">
                  {{
                    journalData.conferenceLocation
                      ? journalData.conferenceLocation
                      : "-"
                  }}
                </h1>
              </div>
            </div> -->

            <hr class="w-full mt-10" />

            <div class="w-full grid">
              <button
                @click="openModal"
                v-if="!isLogged"
                :class="!isLogged ? 'lg:w-full' : ''"
                class="px-3 md:px-0 text-xs flex justify-center uppercase font-semibold mt-5 bg-FFB800 rounded md:text-sm w-full md:w-full py-2 mb-5 m-auto hover:text-white h-8"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5 font-semibold mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                <!-- Request -->
                {{ journalData.isPublished == 1 ? "View Article" : "Request" }}
              </button>
              <button
                @click="openModal2"
                v-if="this.$store.getters.getUserRole == 1"
                class="flex justify-center uppercase font-semibold mt-5 bg-FFB800 rounded-lg text-sm w-full md:w-full lg:w-1/2 xl:w-1/4 2xl:w-1/4 py-2 mb-5 m-auto hover:text-white h-8"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5 font-semibold mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>

                View Article
              </button>
              <button
                @click="openModal2"
                v-if="
                  this.$store.getters.getUserRole > 1 &&
                  this.$store.getters.getUserdetailID == journalData.userID
                "
                class="flex justify-center uppercase font-semibold mt-5 bg-FFB800 rounded-lg text-sm w-full md:w-full lg:w-1/2 xl:w-1/4 2xl:w-1/4 py-2 mb-5 m-auto hover:text-white h-8"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5 font-semibold mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>

                View Article
              </button>
              <button
                @click="openModal2"
                v-if="
                  this.$store.getters.getUserRole > 1 &&
                  this.$store.getters.getUserdetailID != journalData.userID &&
                  journalData.journalpermission != null &&
                  journalData.journalpermission.isApproved == 1
                "
                class="flex justify-center uppercase font-semibold mt-5 bg-FFB800 rounded-lg text-sm w-full md:w-full lg:w-1/2 xl:w-1/4 2xl:w-1/4 py-2 mb-5 m-auto hover:text-white h-8"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5 font-semibold mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>

                View Article
              </button>
              <button
                @click="openModal2"
                v-if="
                  this.$store.getters.getUserRole > 1 &&
                  this.$store.getters.getUserdetailID != journalData.userID &&
                  journalData.journalpermission != null &&
                  journalData.journalpermission.isApproved == 0
                "
                disabled
                class="flex justify-center text-gray-500 uppercase font-semibold mt-5 bg-gray-300 rounded text-xs w-full py-2 mb-5 m-auto hover:text-black h-8"
              >
                <!-- <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5 font-semibold mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg> -->

                You have successfully requested, please wait for the approval
              </button>
              <button
                @click="openModal"
                v-if="
                  this.$store.getters.getUserRole > 1 &&
                  this.$store.getters.getUserdetailID != journalData.userID &&
                  journalData.journalpermission == null
                "
                class="px-3 md:px-0 text-xs flex justify-center uppercase font-semibold mt-5 bg-FFB800 rounded-lg md:text-sm w-full md:w-full lg:w-1/2 xl:w-1/4 2xl:w-1/4 py-2 mb-5 m-auto hover:text-white h-8"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5 font-semibold mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                <!-- Request -->
                {{ journalData.isPublished == 1 ? "View Articlez" : "Request" }}
              </button>
            </div>
          </div>
          <!-- <div class="w-3/12"> -->
          <div
            id="div4"
            :class="
              isLoading == true
                ? 'invisible'
                : isViewJournal == true
                ? 'visible'
                : journalData.isPublished == 0
                ? 'w-full'
                : journalData.isPublished == 1
                ? 'w-full'
                : ''
            "
            class="most-dl-journal md:w-full mt-4 mr-40 md:mr-0 lg:mt-0 md:mt-0 h-auto bg-white m-auto rounded-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700"
          >
            <div class="w-10/12 mb-10 mx-auto md:ml-4 lg:ml-5 2xl:ml-8">
              <h1
                class="text-4xl md:text-3xl text-center md:text-left mt-8 mb-1 font-semibold"
              >
                Most <span class="text-green-900">Viewed</span> Journal
              </h1>
              <p class="text-gray-500 text-xs text-justify mt-2 mb-1 md:pr-2">
                This database management system contains the thesis /
                dissertations / publications of the graduate students, and
                faculty of the
                <span class="font-semibold">Davao del Norte State College</span>
                which is accessible to the academic community.
              </p>
            </div>
            <div
              class="div4_stat grid lg:grid md:grid w-full px-2 md:w-10/12 mx-auto lg:ml-2 md:ml-2"
            >
              <div
                class="stat_l grid w-ful pl-4 md:pl-0 lg:pl-4 xl:pl-4 2xl:pl-4 pb-3"
              >
                <div
                  class="flex mb-6 customTooltip"
                  v-for="(item, index) in mostvieweddata"
                  :key="index"
                >
                  <ul
                    class="w-full divide-y divide-gray-200 dark:divide-gray-700 pr-6 md:pr-0"
                  >
                    <li class="pb-3 sm:pb-4">
                      <div class="flex items-center space-x-4">
                        <div
                          class="flex-shrink-0 bg-105005 text-white px-2 py-1 rounded-full"
                        >
                          #{{ index + 1 }}
                        </div>
                        <div
                          class="flex-1 min-w-0 w-14 md:w-full lg:w-full xl:w-full"
                        >
                          <p
                            class="text-xs font-medium text-gray-600 text-justify dark:text-white"
                          >
                            {{ item.title }}
                          </p>
                          <!-- <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                        email@flowbite.com
                      </p> -->
                        </div>
                        <!-- <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                      $320
                    </div> -->
                      </div>
                    </li>
                  </ul>

                  <!-- <b class=" px-1 w-14 text-center text-red py-1 text-xs bg-yellow-300 rounded-full ">#{{ index + 1 }}</b>
              <input type="text" class=" customIndent rounded-full text-gray-500 w-full border-gray-800 bg-white text-xs mr-3 break-words"
                :value="item.title" disabled />
              <span class="customTooltiptext text-xs px-2 py-1">{{
                item.title
              }}</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END TAG NOT LOGGED IN -->

    <!-- LOGGED  IN -->
    <div class="bg-gray-100 dark:bg-gray-300" v-if="isLogged">
      <div class="md:flex md:justify-end mt-2">
        <div class="hidden md:w-full md:flex sm:flex lg:flex xl:flex 2xl:flex">
          <nav
            class="md:flex -ml-4 md:-ml-0 h-8 md:mt-3 lg:mt-0 xl:mt-0 2xl:mt-0"
            aria-label="Breadcrumb"
          >
            <ol
              role="list"
              class="flex space-x-1 sm:space-x-1 md:space-x-1 lg:space-x-4 xl:space-x-4 2xl:space-x-4 rounded-md px-6"
            >
              <li
                class="flex md:flex-col md:flex lg:flex-row xl:flex-row 2xl:flex-row md:items-center"
              >
                <router-link
                  to="/userspage/dashboard"
                  class="text-blue-600 hover:text-gray-500"
                  title="Dashboard"
                >
                  <svg
                    class="h-5 w-5 flex-shrink-0 md:mt-1.5 lg:mt-0 xl:mt-0 2xl:mt-0"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </router-link>
              </li>
              <!-- <li class="flex  items-center"> <div class="flex items-center"> <svg class="h-5 w-5 flex-shrink-0 text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true"> <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" /> </svg> <a href="#" @click="back" class="ml-4 text-xs font-medium text-blue-600 hover:text-gray-700">Approval</a> </div> </li> -->
              <li class="flex items-center">
                <div class="flex items-center">
                  <svg
                    class="h-5 w-5 flex-shrink-0 text-gray-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                  <a
                    href="#"
                    class="md:ml-0 lg:ml-4 text-xs font-medium text-blue-600 hover:text-gray-700"
                    @click="back"
                    >Researches</a
                  >
                </div>
              </li>
              <li class="flex items-center" v-if="toggleBreadcrumbs == false">
                <div class="flex items-center">
                  <svg
                    class="h-5 w-5 flex-shrink-0 text-gray-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                  <a
                    href="#"
                    class="md:ml-0 lg:ml-4 text-xs font-medium text-gray-500"
                    >See details</a
                  >
                </div>
              </li>
              <li
                class="flex items-center"
                v-if="toggleBreadcrumbs == true"
                @click="goBack()"
              >
                <div class="flex items-center">
                  <svg
                    class="h-5 w-5 flex-shrink-0 text-gray-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                  <a href="#" class="ml-4 text-xs font-medium text-blue-600"
                    >See details</a
                  >
                </div>
              </li>
              <li class="flex items-center" v-if="toggleBreadcrumbs == true">
                <div class="flex items-center">
                  <svg
                    class="h-5 w-5 flex-shrink-0 text-gray-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                  <a href="#" class="ml-4 text-xs font-medium text-gray-500"
                    >View journals</a
                  >
                </div>
              </li>
            </ol>
          </nav>
        </div>
        <div class="w-full flex justify-end">
          <button
            v-if="journalData.isPublished && isLogged"
            @click="redirect(journalData)"
            class="text-xs py-1 mt-2 mr-4 px-1 bg-transparent bg-FFB800 hover:bg-FFB800 text-white font-semibold hover:text-white border border-FFB800 hover:border-transparent rounded"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 -mt-1 inline"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
              />
            </svg>
            Download
          </button>
          <button
            @click="back"
            title="Back"
            class="text-xs py-1 px-2 mt-2 mr-5 bg-transparent text-gray-500 border bg-gray-300 border-gray-300 rounded"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 -mt-1 inline"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
              />
            </svg>
            Back
          </button>

          <!-- if user is not logged in -->
          <button
            v-if="journalData.isPublished && !isLogged"
            @click="openModal"
            class="text-xs py-1 mr-4 mt-2 px-1 bg-transparent hover:bg-FFB800 text-FFB800 font-semibold hover:text-white border border-FFB800 hover:border-transparent rounded"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 -mt-1 inline"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
              />
            </svg>
            Download
          </button>
        </div>
      </div>
      <div v-if="isLoading" class="flex justify-center py-8">
        <div class="spinLoader"></div>
      </div>

      <div class="grid md:flex lg:flex w-auto py-6 mx-2">
        <div
          class="md:grid md:grid-cols-3 md:gap-5 lg:gap-7 xl:gap-10 2xl:gap-10"
        >
          <div
            style="height: 778px"
            v-show="isViewJournal"
            :class="
              isLoading == true
                ? 'invisible'
                : isViewJournal == true
                ? 'visible'
                : 'invisible'
            "
            class="unselectable2 w-full h-auto overflow-y-auto md:mb-4 md:w-full md:grid md:col-span-2 text-left px-4"
          >
            <FileViewer
              v-if="isViewJournal"
              :filename="filename"
              :filelink="filelink"
              class="w-full"
            />
          </div>

          <div
            v-show="!isViewJournal"
            :class="isLoading == true ? 'hidden' : 'block'"
            class="bg-white h-auto w-full rounded-md shadow-md mr-5 md:ml-4 md:mb-4 md:grid md:col-span-2 text-left px-7"
          >
            <div class="mt-5 md:mt-5">
              <h1
                class="text-2xl md:text-4xl pt-5 font-semibold text-justify md:w-full lg:w-full"
              >
                {{ journalData.title }}
              </h1>
            </div>
            <div class="mt-4 md:mt-4">
              <!-- START OF JOURNAL PUBLISHED -->
              <span
                v-if="journalData.isPublished == 0"
                class="inline-flex items-center rounded-full uppercase bg-red-100 m-0.5 px-4 py-1 text-xs text-red-800"
                >Unpublished</span
              >
              <span
                v-if="journalData.isPublished == 1"
                class="inline-flex items-center rounded-full uppercase bg-green-100 m-0.5 px-4 py-1 text-xs text-green-800"
                >Published</span
              >
              <!-- END OF JOURNAL PUBLISHED -->

              <!-- START OF JOURNAL STATUS  -->
              <!-- <span v-if="journalData.journalstatusID.id == 1"
            class="inline-flex items-center rounded-full uppercase bg-blue-100 m-0.5 px-4 py-1 text-xs text-blue-800">For
            Approval</span> -->
              <span
                v-if="journalData.journalstatusID.id == 2"
                class="inline-flex items-center rounded-full uppercase bg-blue-100 m-0.5 px-4 py-1 text-xs text-blue-800"
                >Verified</span
              >
              <!-- <span v-if="journalData.journalstatusID.id == 3"
            class="inline-flex items-center rounded-full uppercase bg-blue-100 m-0.5 px-4 py-1 text-xs text-blue-800">Pending</span> -->
              <!-- <span v-if="journalData.journalstatusID.id == 4"
            class="inline-flex items-center rounded-full uppercase bg-blue-100 m-0.5 px-4 py-1 text-xs text-blue-800">Endorsed</span> -->
              <!-- <span v-if="journalData.journalstatusID.id == 5"
            class="inline-flex items-center rounded-full uppercase bg-blue-100 m-0.5 px-4 py-1 text-xs text-blue-800">Approved
            By Librarian</span> -->
              <!-- END OF JOURNAL STATUS  -->

              <!-- START OF JOURNAL TYPE  -->
              <span
                v-if="journalData.typeOfArticle == 1"
                class="inline-flex items-center rounded-full uppercase bg-purple-100 m-0.5 px-4 py-1 text-xs text-purple-800"
                >Thesis</span
              >
              <span
                v-if="journalData.typeOfArticle == 2"
                class="inline-flex items-center rounded-full uppercase bg-purple-100 m-0.5 px-4 py-1 text-xs text-purple-800"
                >Capstone</span
              >
              <span
                v-if="journalData.typeOfArticle == 3"
                class="inline-flex items-center rounded-full uppercase bg-purple-100 m-0.5 px-4 py-1 text-xs text-purple-800"
                >Dissertation</span
              >
              <span
                v-if="journalData.typeOfArticle == 4"
                class="inline-flex items-center rounded-full uppercase bg-purple-100 m-0.5 px-4 py-1 text-xs text-purple-800"
                >Research</span
              >
              <span
                v-if="journalData.typeOfArticle == 5"
                class="inline-flex items-center rounded-full uppercase bg-purple-100 m-0.5 px-4 py-1 text-xs text-purple-800"
                >Journal</span
              >
              <span
                v-if="journalData.typeOfArticle == 6"
                class="inline-flex items-center rounded-full uppercase bg-purple-100 m-0.5 px-4 py-1 text-xs text-purple-800"
                >Others</span
              >
              <!-- END OF JOURNAL TYPE  -->

              <!-- <a href="#" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Cite"
            class="transition duration-150 ease-in-out float-right font-bold mt-1 text-sm bg-yellow-500 hover:text-yellow-200 rounded w-auto px-2 py-1 m-1 text-white top-0">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-4 h-4 inline">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
            </svg>
            Cite this journal
          </a> -->
            </div>

            <div class="mt-4 md:mt-4" v-if="journalData.publisher != null">
              <h1 class="text-green-800 font-bold text-sm">
                Publisher:
                <span class="text-gray-600 font-normal">
                  {{ journalData.publisher }}</span
                >
              </h1>
            </div>

            <div class="mt-4 md:mt-4">
              <h1 class="text-green-800 font-bold text-sm">
                Author:
                <span class="text-gray-600 font-normal">
                  {{
                    journalData.mainauthorlname
                      ? journalData.mainauthorlname + ", "
                      : ""
                  }}
                  {{ journalData.mainauthorfname }}
                  {{
                    journalData.mainauthormname
                      ? journalData.mainauthormname[0] + "."
                      : ""
                  }}
                </span>
              </h1>
            </div>

            <div class="mt-4 md:mt-2">
              <h1 class="text-green-800 font-bold text-sm">
                Corresponding Author:
                <span class="text-gray-600 font-normal">
                  {{ journalData.corLname ? journalData.corLname + ", " : "" }}
                  {{ journalData.corFname }}
                  {{
                    journalData.corMname ? journalData.corMname[0] + "." : ""
                  }}
                </span>
              </h1>
            </div>

            <div class="mt-4 md:mt-2">
              <h1 class="text-green-800 font-bold text-sm">
                Co-author/s:
                <span class="text-gray-600 font-normal"
                  >{{ coauthors ? coauthors : "-" }}
                </span>
              </h1>
            </div>
            <div class="mt-4 md:mt-2">
              <h1 class="text-green-800 font-bold text-sm">
                Keywords:
                <span class="text-gray-600 font-normal">
                  {{ journalData.authorsKeywords }}</span
                >
              </h1>
            </div>
            <div
              class="flex flex-wrap justify-between text-xs pb-1 pt-3 -mt-2"
              v-if="journalData.isPublished == 1"
            >
              <!-- <h1 class="text-green text-sm">Cite this journal:</h1> -->
              <select
                v-model="choiceOfFormat"
                @change="showCite(choiceOfFormat)"
                class="w-full rounded-lg outline-none border focus:border-yellow-300 h-10 pl-2 mt-2 text-sm appearance-none"
              >
                <option value="0" class="text-xs">
                  Choose a format to cite:
                </option>
                <option value="1" class="text-xs">MLA</option>
                <option value="2" class="text-xs">
                  APA <span>Seventh Edition</span>
                </option>
                <option value="3" class="text-xs">Vancouver</option>
                <option value="4" class="text-xs">Harvard</option>
                <option value="5" class="text-xs">Chicago</option>
                <option value="6" class="text-xs">IEEE</option>
              </select>
              <!-- <div :class="citationDiv ? 'transform rotate-0 duration-150' : 'transform rotate-180 duration-150'">
              <Icons :name="'chevron-up'" class="w-4 h-4"></Icons>
            </div> -->
            </div>
            <div
              id="citationDiv"
              v-show="journalData.isPublished == 1 && !optionHide"
            >
              <div>
                <div
                  class="text-gray-600 text-justify text-sm border p-2 rounded cursor-pointer"
                  @click="selectAll(this.choiceText)"
                >
                  <p>{{ this.choiceText }}</p>
                </div>
              </div>
            </div>
            <div v-if="citationErrorDiv">
              <div
                class="text-gray-600 text-sm text-center p-2 rounded bg-gray-100"
              >
                <p class="text-gray-400 text-xs">{{ this.citationErr }}</p>
              </div>
            </div>

            <hr class="mt-4 md:mt-4" />

            <!-- :class="
                journalData.isPublished == 1
                  ? 'block'
                  : journalData.isPublished == 0
                  ? 'hidden'
                  : ''
              " -->
            <div class="mt-4 md:mt-6">
              <h1 class="text-sm font-semibold md:-mt-2 md:mb-2 text-green-800">
                Abstract
              </h1>
              <p class="text-justify text-sm text-gray-600">
                {{ journalData.abstract }}
              </p>
            </div>
            <div class="mt-4 text-sm grid grid-cols-1 gap-4">
              <div
                class="w-full flex justify-between"
                v-if="journalData.publisher"
              >
                <div class="font-semibold">Publisher:</div>
                <div class="text-blue-800">
                  {{ journalData.publisher ? journalData.publisher : "" }}
                </div>
              </div>
              <div class="w-full flex justify-between">
                <div class="font-semibold">INSPEC Accession Number:</div>
                <div
                  class="text-gray-500"
                  v-if="this.$store.getters.getUserRole == 1"
                >
                  {{ !isLogged ? "" : journalData.accessionNumber }}
                </div>
                <div
                  v-if="this.$store.getters.getUserRole > 1"
                  class="text-gray-500"
                  :class="
                    journalData.isPublished == 0
                      ? this.$store.getters.getUserdetailID !=
                        journalData.userID
                        ? journalData.journalpermission != null
                          ? journalData.journalpermission.isApproved == 1
                            ? ''
                            : 'italic text-xs'
                          : 'italic text-xs'
                        : ''
                      : ''
                  "
                >
                  <!-- {{
                    journalData.isPublished == 0
                      ? journalData.userID !=
                        this.$store.getters.getUserdetailID
                        ? journalData.journalpermission != null
                          ? journalData.journalpermission.isApproved == 1
                            ? journalData.accessionNumber
                            : "hidden"
                          : "hidden"
                        : journalData.accessionNumber
                      : journalData.accessionNumber != null
                      ? journalData.accessionNumber
                      : ""
                  }} -->
                  {{
                    journalData.isPublished == 0
                      ? journalData.userID !=
                        this.$store.getters.getUserdetailID
                        ? journalData.journalpermission != null
                          ? journalData.journalpermission.isApproved == 1
                            ? journalData.accessionNumber != ""
                              ? journalData.accessionNumber
                              : ""
                            : "hidden"
                          : "hidden"
                        : this.$store.getters.getUserRole == 1
                        ? journalData.accessionNumber != ""
                          ? journalData.accessionNumber
                          : ""
                        : "hidden"
                      : journalData.accessionNumber != ""
                      ? journalData.accessionNumber
                      : ""
                  }}
                </div>
              </div>
              <div
                class="w-full flex justify-between"
                v-if="journalData.conferenceDate != ''"
              >
                <div class="font-semibold">Year submitted:</div>
                <div class="text-gray-500">
                  {{
                    journalData.conferenceDate != ""
                      ? formatYear(journalData.conferenceDate)
                      : "-"
                  }}
                </div>
              </div>
              <div
                class="w-full flex justify-between"
                v-if="journalData.doi != '' && journalData.doi != null"
              >
                <div class="font-semibold">DOI:</div>
                <div class="text-gray-500">
                  {{ journalData.doi }}
                </div>
              </div>
              <div
                class="w-full flex justify-between"
                v-if="journalData.createdAt != ''"
              >
                <div class="font-semibold">Date Uploaded to ReDMaS:</div>
                <div class="text-gray-500">
                  {{ formatDate(journalData.createdAt) }}
                </div>
              </div>
            </div>

            <!-- <div class="mt-4 text-sm flex justify-start gap-8">
              <div class="w-full flex justify-between">
                <div class="font-semibold">Year submitted:</div>
                <div class="text-gray-500"> {{ journalData.conferenceDate != "" ? formatYear(journalData.conferenceDate) : "-" }}</div>
              </div>
              <div class="w-full flex justify-between">
                <div class="font-semibold">DOI:</div>
                <div class="text-gray-500"> {{ journalData.doi ? journalData.doi : "-" }}</div>
              </div>
            </div>

            <div class="mt-4 text-sm flex justify-start gap-8">
              <div class="w-full flex justify-between">
                <div class="font-semibold">Date Uploaded to ReDMaS:</div>
                <div class="text-gray-500"> {{ formatDate(journalData.createdAt) }}</div>
              </div>
              <div class="w-full flex justify-between">
                <div class="font-semibold">DOI:</div>
                <div class=""> {{ journalData.doi ? journalData.doi : "-" }}</div>
              </div>
            </div> -->

            <hr class="w-full mt-10" />

            <div class="w-full grid">
              <!-- FOR LIBRARIAN / SUPER ADMIN -->
              <button
                @click="openModal2"
                v-if="this.$store.getters.getUserRole == 1"
                class="flex justify-center uppercase font-semibold mt-5 bg-FFB800 rounded-lg text-sm w-full md:w-full lg:w-1/2 xl:w-1/4 2xl:w-1/4 py-2 mb-5 m-auto hover:text-white h-8"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5 font-semibold mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>

                View Article
              </button>
              <!-- FOR LIBRARIAN / SUPER ADMIN END -->

              <!-- FOR OTHER USER ROLE WHOSE ALSO THE UPLOADER -->
              <button
                @click="openModal2"
                v-if="
                  this.$store.getters.getUserRole > 1 &&
                  this.$store.getters.getUserdetailID == journalData.userID
                "
                class="flex justify-center uppercase font-semibold mt-5 bg-FFB800 rounded-lg text-sm w-full md:w-full lg:w-1/2 xl:w-1/4 2xl:w-1/4 py-2 mb-5 m-auto hover:text-white h-8"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5 font-semibold mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>

                View Article
              </button>
              <!-- FOR OTHER USER ROLE WHOSE ALSO THE UPLOADER -->

              <!-- FOR OTHER USER ROLE THAT REQUESTED TO VIEW UNPUBLISHED ARTICLES AND WAS APPROVED BY UPLOADER -->
              <button
                @click="openModal2"
                v-if="
                  this.$store.getters.getUserRole > 1 &&
                  this.$store.getters.getUserdetailID != journalData.userID &&
                  journalData.journalpermission != null &&
                  journalData.journalpermission.isApproved == 1
                "
                class="flex justify-center uppercase font-semibold mt-5 bg-FFB800 rounded-lg text-sm w-full md:w-full lg:w-1/2 xl:w-1/4 2xl:w-1/4 py-2 mb-5 m-auto hover:text-white h-8"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5 font-semibold mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>

                View Article
              </button>
              <!-- FOR OTHER USER ROLE THAT REQUESTED TO VIEW UNPUBLISHED ARTICLES AND WAS APPROVED BY UPLOADER  END -->

              <!-- FOR OTHER USER ROLE THAT REQUESTED ALREADY AND WAITING FOR THE UPLOADER TO APPROVED THEIR REQUEST -->
              <button
                @click="openModal2"
                v-if="
                  this.$store.getters.getUserRole > 1 &&
                  this.$store.getters.getUserdetailID != journalData.userID &&
                  journalData.journalpermission != null &&
                  journalData.journalpermission.isApproved == 0
                "
                disabled
                class="flex justify-center text-gray-500 uppercase font-semibold mt-5 bg-gray-300 rounded text-xs w-full py-2 mb-5 m-auto hover:text-black h-8"
              >
                You have successfully requested, please wait for the approval
              </button>
              <!-- FOR OTHER USER ROLE THAT REQUESTED ALREADY AND WAITING FOR THE UPLOADER TO APPROVED THEIR REQUEST -->

              <!-- FOR OTHER USER ROLE WHOSE NOT THE UPLOADER THAT NEEDS TO REQUEST FOR ACCESSION NUMBER -->
              <button
                @click="openModal"
                v-if="
                  this.$store.getters.getUserRole > 1 &&
                  this.$store.getters.getUserdetailID != journalData.userID &&
                  journalData.journalpermission == null
                "
                class="px-3 md:px-0 text-xs flex justify-center uppercase font-semibold mt-5 bg-FFB800 rounded-lg md:text-sm w-full md:w-full lg:w-1/2 xl:w-1/4 2xl:w-1/4 py-2 mb-5 m-auto hover:text-white h-8"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5 font-semibold mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                <!-- Request -->
                {{ journalData.isPublished == 1 ? "View Article" : "Request" }}
              </button>
              <!-- FOR OTHER USER ROLE WHOSE NOT THE UPLOADER THAT NEEDS TO REQUEST FOR ACCESSION NUMBER END -->
            </div>
          </div>
          <!-- <div class="w-3/12"> -->
          <div
            id="div4"
            :class="
              isLoading == true
                ? 'invisible'
                : isViewJournal == true
                ? 'visible'
                : 'visible'
            "
            class="most-dl-journal w-full mt-4 mr-4 md:mr-0 lg:mt-0 md:mt-0 h-auto bg-white m-auto rounded-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700"
          >
            <div class="w-10/12 mb-10 mx-auto md:ml-4 lg:ml-5 2xl:ml-8">
              <h1
                class="text-4xl md:text-3xl text-center md:text-left mt-8 mb-1 font-semibold"
              >
                Most <span class="text-green-900">Viewed</span> Journal
              </h1>
              <p class="text-gray-500 text-xs text-justify mt-2 mb-1 md:pr-2">
                This database management system contains the thesis /
                dissertations / publications of the graduate students, and
                faculty of the
                <span class="font-semibold">Davao del Norte State College</span>
                which is accessible to the academic community.
              </p>
            </div>
            <div
              class="div4_stat grid lg:grid md:grid w-full px-2 md:w-10/12 mx-auto lg:ml-2 md:ml-2"
            >
              <div
                class="stat_l grid w-ful pl-4 md:pl-0 lg:pl-4 xl:pl-4 2xl:pl-4 pb-3"
              >
                <div
                  class="flex mb-6 customTooltip"
                  v-for="(item, index) in mostvieweddata"
                  :key="index"
                >
                  <ul
                    class="w-full divide-y divide-gray-200 dark:divide-gray-700 pr-6 md:pr-0"
                  >
                    <li class="pb-3 sm:pb-4">
                      <div class="flex items-center space-x-4">
                        <div
                          class="flex-shrink-0 bg-105005 text-white px-2 py-1 rounded-full"
                        >
                          #{{ index + 1 }}
                        </div>
                        <div
                          class="flex-1 min-w-0 w-14 md:w-full lg:w-full xl:w-full"
                        >
                          <p
                            class="text-xs font-medium text-gray-600 text-justify dark:text-white"
                          >
                            {{ item.title }}
                          </p>
                          <!-- <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                        email@flowbite.com
                      </p> -->
                        </div>
                        <!-- <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                      $320
                    </div> -->
                      </div>
                    </li>
                  </ul>

                  <!-- <b class=" px-1 w-14 text-center text-red py-1 text-xs bg-yellow-300 rounded-full ">#{{ index + 1 }}</b>
              <input type="text" class=" customIndent rounded-full text-gray-500 w-full border-gray-800 bg-white text-xs mr-3 break-words"
                :value="item.title" disabled />
              <span class="customTooltiptext text-xs px-2 py-1">{{
                item.title
              }}</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END TAG LOGGED IN -->

    <PleaseLoginModal
      v-if="isPleaseLoginModal"
      v-on:closeModal="close"
      v-on:redirect="redirectTo"
    />
    <JournalPermissionModal
      v-if="isOpenModal"
      v-on:doneRequestSuccess="doneRequestSuccess"
      v-on:closeModal="closeModal"
      :action="modalAction"
      :jpItem="itemToView"
    />
  </div>
</template>

<script>
import PleaseLoginModal from "../modals/PleaseLoginModal.vue";
import FileViewer from "../components/FileViewer.vue";
import LoadingBars from "../components/LoadingBars.vue";
import { useToast } from "vue-toastification";
import Icons from "../components/Icons.vue";
import copy from "copy-to-clipboard";
import axios from "axios";
import Cite from "citation-js";
import { isFile } from "@babel/types";
import JournalPermissionModal from "../modals/JournalPermissionModal.vue";
import LoadingBarsVue from "./LoadingBars.vue";
export default {
  name: "JournalView",
  props: ["isLogged", "journalData", "file", "searchText"],
  components: {
    PleaseLoginModal,
    JournalPermissionModal,
    FileViewer,
    Icons,
    Cite,
    LoadingBars,
  },
  data() {
    return {
      doneRequest: false,
      isLoading: false,
      citationErrorDiv: false,
      citationErr: null,
      isPleaseLoginModal: false,
      isOpenModal: false,
      isViewJournal: false,
      filename: "",
      filelink: null,
      mostvieweddata: null,
      modalAction: "add",
      coauthors: "",
      searchData: null,
      toggleBreadcrumbs: false,
      citationDiv: false,
      citeResAPA: null,
      citeResVancouver: null,
      citeResHarvard: null,
      citeResChicago: null,
      citeResIEEE: null,
      choiceOfFormat: 0,
      isCopy: false,
      choiceText: null,
      optionHide: true,
      options: [],
      itemToView: null,
      isLoggedData: null,
    };
  },
  computed: {
    checkProfilePercentage() {
      return this.percentage == 100;
    },
  },
  created() {
    this.showCite(this.choiceOfFormat);
    this.filename = this.file;
    if (this.isLoggedData) {
      this.getProfilePercentage();
    }
    this.getMostViewedData();
  },

  methods: {
    doneRequestSuccess() {
      this.doneRequest = true;
    },
    Success() {
      this.refreshTable = true;
      setTimeout(() => (this.refreshTable = false), 300);
      this.isOpenModal = false;
    },
    selectAll(val) {
      const toast = useToast();
      // navigator.clipboard
      //   .writeText(val)
      //   .then(() => {
      try {
        copy(val, {
          debug: true,
          message: val.toString,
        });
        axios
          .get(
            process.env.VUE_APP_API_SERVER +
              "/journalcategory/addNewCitedCount/" +
              this.journalData.id
          )
          .then(
            (response) => {
              if (response.data.status == 200 || response.data.status == 201) {
                toast.success("Citation successfully copied to clipboard!", {
                  position: "top-right",
                  timeout: 3000,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: false,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
      } catch {
        console.log("Something went wrong!");
      } // })
    },
    // selectAll(val) {
    //   const toast = useToast();
    //   navigator.clipboard
    //     .writeText(val)
    //     .then(() => {
    //       toast.success("Citation successfully copied to clipboard!", {
    //         position: "top-center",
    //         timeout: 3000,
    //         closeOnClick: true,
    //         pauseOnFocusLoss: false,
    //         pauseOnHover: false,
    //         draggable: true,
    //         draggablePercent: 0.6,
    //         showCloseButtonOnHover: false,
    //         hideProgressBar: false,
    //         closeButton: "button",
    //         icon: true,
    //         rtl: false,
    //       })
    //         .catch(() => {
    //           toast.warning("Citation copy failed.", {
    //             position: "top-center",
    //             timeout: 3000,
    //             closeOnClick: true,
    //             pauseOnFocusLoss: false,
    //             pauseOnHover: false,
    //             draggable: true,
    //             draggablePercent: 0.6,
    //             showCloseButtonOnHover: false,
    //             hideProgressBar: false,
    //             closeButton: "button",
    //             icon: true,
    //             rtl: false,
    //           })
    //         });
    //     })
    //   // console.log(val);
    // },
    async showCite(val) {
      if (val == 0) {
        this.optionHide = true;
        this.citationErrorDiv = false;
      }
      //MLA
      else if (val == 1) {
        try {
          const e1 = new Cite(this.journalData.doi);
          const o1 = e1.format("bibliography", {
            type: "string",
            lang: "en-US",
            template: "mla",
          });
          this.optionHide = false;
          this.citationErrorDiv = false;
          this.choiceText = o1;
        } catch (error) {
          this.optionHide = true;
          this.citationErrorDiv = true;
          this.citationErr = "Invalid DOI, can't capture citation...";
        }
      }
      //APA
      else if (val == 2) {
        try {
          const e1 = new Cite(this.journalData.doi);
          const o2 = e1.format("bibliography", {
            type: "string",
            lang: "en-US",
            template: "apa",
          });
          this.choiceText = o2;
          this.optionHide = false;
          this.citationErrorDiv = false;
        } catch (error) {
          this.optionHide = true;
          this.citationErrorDiv = true;
          this.citationErr = "Invalid DOI, can't capture citation...";
        }
      }
      //VANCOUVER
      else if (val == 3) {
        try {
          const e1 = new Cite(this.journalData.doi);
          const o3 = e1.format("bibliography", {
            type: "string",
            lang: "en-US",
            template: "vancouver",
          });
          this.choiceText = o3;
          this.optionHide = false;
          this.citationErrorDiv = false;
        } catch (error) {
          this.optionHide = true;
          this.citationErrorDiv = true;
          this.citationErr = "Invalid DOI, can't capture citation...";
        }
      }
      //HARVARD
      else if (val == 4) {
        try {
          const e1 = new Cite(this.journalData.doi);
          const o4 = e1.format("bibliography", {
            type: "string",
            lang: "en-US",
            template: "harvard1",
          });
          this.choiceText = o4;
          this.optionHide = false;
          this.citationErrorDiv = false;
        } catch (error) {
          this.optionHide = true;
          this.citationErrorDiv = true;
          this.citationErr = "Invalid DOI, can't capture citation...";
        }
      }
      //CHICAGO
      else if (val == 5) {
        try {
          const e1 = new Cite(this.journalData.doi);
          const o5 = e1.format("bibliography", {
            type: "string",
            lang: "en-US",
            template: "chicago",
          });
          this.choiceText = o5;
          this.optionHide = false;
          this.citationErrorDiv = false;
        } catch (error) {
          this.optionHide = true;
          this.citationErrorDiv = true;
          this.citationErr = "Invalid DOI, can't capture citation...";
        }
      }
      //IEEE
      else if (val == 6) {
        try {
          const e1 = new Cite(this.journalData.doi);
          const o6 = e1.format("bibliography", {
            type: "string",
            lang: "en-US",
            template: "ieee",
          });
          this.choiceText = o2;
          this.optionHide = false;
          this.citationErrorDiv = false;
        } catch (error) {
          this.optionHide = true;
          this.citationErrorDiv = true;
          this.citationErr = "Invalid DOI, can't capture citation...";
        }
      }
    },
    back() {
      this.isViewJournal = false;
      this.isLoading = true;
      setTimeout(function () {
        this.isLoading = false;
      }, 3000);
      this.$emit("back");
      this.$emit("autoDisplayJournals");
    },

    openModal2() {
      if (!this.isLogged) {
        this.isPleaseLoginModal = true;
      } else {
        const toast = useToast();
        if (this.checkProfilePercentage) {
          this.toggleBreadcrumbs = true;
          // console.log(this.journalData.fileLink);
          // this.filename = this.journalData.filename;
          // this.filelink = this.journalData.fileLink;
          this.isViewJournal = true;
        } else {
          toast.error("Please complete your profile to continue.", {
            position: "top-center",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
      }
    },
    openModal() {
      if (!this.isLogged) {
        this.isPleaseLoginModal = true;
      } else {
        const toast = useToast();
        if (this.checkProfilePercentage) {
          if (this.$store.getters.getUserRole == 1) {
            this.isLoading = true;
            setTimeout(() => (this.isLoading = false), 3000);
            // setTimeout(function () {
            //   this.isLoading = false;
            // }, 3000);
            this.isViewJournal = true;
            this.toggleBreadcrumbs = true;
          } else {
            if (
              this.journalData.userID.id != this.$store.getters.getUserdetailID
            ) {
              if (this.journalData.isPublished == 0) {
                this.itemToView = [
                  {
                    user_journal_id: this.journalData.id,
                    user_id: this.journalData.userID.id,
                    user_now: this.$store.getters.getUserdetailID,
                    userdetails: this.journalData.userID,
                  },
                ];
                axios
                  .post(
                    process.env.VUE_APP_API_SERVER +
                      "/journal-permission/getJournalRequest",
                    this.itemToView,
                    {
                      headers: {
                        Authorization: `Bearer ${this.$store.getters.getToken}`,
                      },
                    }
                  )
                  .then(
                    (response) => {
                      if (
                        response.data.getRequest == null ||
                        response.data.getRequest == ""
                      ) {
                        this.isOpenModal = true;
                      } else if (response.data.getRequest.isApproved == 0) {
                        toast.success(
                          "You already requested to the author. Please wait for the approval.",
                          {
                            position: "top-right",
                            timeout: 3000,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: false,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: false,
                            closeButton: "button",
                            icon: true,
                            rtl: false,
                          }
                        );
                      } else if (response.data.getRequest.isApproved == 1) {
                        this.isLoading = true;
                        setTimeout(() => (this.isLoading = false), 3000);
                        // setTimeout(function () {
                        //   this.isLoading = false;
                        // }, 3000);
                        this.isViewJournal = true;
                        this.toggleBreadcrumbs = true;
                      }
                    },
                    (error) => {
                      console.log(error);
                    }
                  );
              } else {
                this.isLoading = true;
                setTimeout(() => (this.isLoading = false), 3000);
                this.toggleBreadcrumbs = true;
                // this.viewFileInfo = file;
                this.isViewJournal = true;
              }
            } else {
              this.isLoading = true;
              setTimeout(() => (this.isLoading = false), 3000);
              this.toggleBreadcrumbs = true;
              // this.viewFileInfo = file;
              this.isViewJournal = true;
            }
          }
        } else {
          // console.log(this.percentage);
          toast.error("Please complete your profile to continue.", {
            position: "top-center",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
      }
    },
    goBack() {
      this.isViewJournal = false;
      this.toggleBreadcrumbs = false;

      // console.log('clicked')
    },

    redirect(file) {
      // handles rendering of pdf files to UI
      if (file.doi) {
        const entry = {
          fileID: file.id,
          userID: this.$store.getters.getUserID,
        };
        axios
          .post(
            process.env.VUE_APP_API_SERVER + "/viewedfiles/addExploreEntry",
            entry,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
              },
            }
          )
          .then(
            (response) => {
              if (response.data.status == 202) {
                window.open("https://doi.org/" + file.doi, "_blank");
              }
            },
            (error) => {
              console.log(error);
            }
          );
      }
    },
    redirectTo(loc) {
      this.$router.push("/signin/" + loc);
    },
    closeModal() {
      // window.location.reload();
      this.isOpenModal = false;
    },
    close() {
      this.isPleaseLoginModal = false;
    },
    getProfilePercentage() {
      axios
        .get(process.env.VUE_APP_API_SERVER + "/users-controller", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            // console.log(response);
            var cnt = 0;

            if (
              response.data.userdetail.fname != null &&
              response.data.userdetail.fname != ""
            ) {
              cnt += 1;
            }
            if (
              response.data.userdetail.lname != null &&
              response.data.userdetail.lname != ""
            ) {
              cnt += 1;
            }
            if (
              response.data.userdetail.educ_qual != null &&
              response.data.userdetail.educ_qual != ""
            ) {
              cnt += 1;
            }
            if (
              response.data.userdetail.name_of_school != null &&
              response.data.userdetail.name_of_school != ""
            ) {
              cnt += 1;
            }
            // if (
            //   response.data.position != null &&
            //   response.data.position != ""
            // ) {
            //   cnt += 1;
            // }
            if (
              response.data.userdetail.office != null &&
              response.data.userdetail.office != ""
            ) {
              cnt += 1;
            }
            if (
              response.data.userdetail.designation != null &&
              response.data.userdetail.designation != ""
            ) {
              cnt += 1;
            }

            this.percentage = (parseInt(cnt) / 6) * 100;
            this.percentage = this.percentage.toFixed(2);
          },
          (error) => {
            console.log(error);
          }
        );
    },

    getMostViewedData() {
      axios
        .get(process.env.VUE_APP_API_SERVER + "/viewedfiles/visitcount", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            this.mostvieweddata = response.data;
            // console.log(response.data);
          },
          (error) => {
            console.log(error);
          }
        );
    },
    formatDate(date) {
      const d = new Date(date);
      var dd = d.getDate();
      if (dd < 10) {
        dd = "0" + dd;
      }
      var mm = d.getMonth() + 1;
      if (mm < 10) {
        mm = "0" + mm;
      }
      var yy = d.getFullYear();
      return mm + "-" + dd + "-" + yy;
    },
    formatYear(date) {
      const d = new Date(date);
      var dd = d.getDate();
      if (dd < 10) {
        dd = "0" + dd;
      }
      var mm = d.getMonth() + 1;
      if (mm < 10) {
        mm = "0" + mm;
      }
      var yy = d.getFullYear();
      // return mm + "-" + dd + "-" + yy;
      return yy;
    },
  },

  watch: {
    journalData: {
      handler(val) {
        // console.log(val.fileLink);
        this.filename = val.filename;
        this.filelink = val.fileLink;
        const parsedCoAuthors = JSON.parse(val.coauthors);
        if (val.coauthors != null) {
          for (let i = 0; i < parsedCoAuthors.length; i++) {
            const element = parsedCoAuthors[i];
            if (element.mname) {
              this.coauthors +=
                element.lname +
                ", " +
                element.fname +
                " " +
                element.mname[0] +
                "; ";
            } else {
              this.coauthors += element.lname + ", " + element.fname + "; ";
            }
          }
        }
        // console.log(val.coauthors);
        // if (val.coauthors != null) {
        //   val.coauthors.forEach((element) => {
        //     if (element.mname) {
        //       this.coauthors +=
        //         element.lname +
        //         ", " +
        //         element.fname +
        //         " " +
        //         element.mname[0] +
        //         "; ";
        //     } else {
        //       this.coauthors += element.lname + ", " + element.fname + "; ";
        //     }
        //   });
        // }
      },
      immediate: true,
    },

    searchText: {
      handler(val) {
        this.searchData = val;
      },
      immediate: true,
    },
    isLogged: {
      handler(val) {
        this.isLoggedData = val;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.intro_p {
  /* border: 1px solid black; */
  height: 100px;
  opacity: 0.3;
}

.view_btn {
  transition: 1s;
}

.customIndent {
  text-indent: 1rem;
}

.view_btn:hover {
  transform: translateY(-50%);
}

/* TOOLTIP CSS */

.customTooltip {
  position: relative;
  /* display: inline-block; */
  /* border-bottom: 1px dotted black; */
}

.customTooltip .customTooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: #105005;
  color: #fff;
  text-align: center;
  border-radius: 6px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 20%;
  margin-left: -60px;
}

.customTooltip .customTooltiptext:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  /* triangle on top of the tooltip */
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #105005;
  left: 40px;
  top: -5px;
}

.customTooltip:hover .customTooltiptext {
  visibility: visible;
}

/* disabling text highlighting  in the rendered pdf*/
.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
     Introduced in Internet Explorer 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
  -ms-user-select: none;
  user-select: none;
}
*.unselectable2 {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  /*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
  -ms-user-select: none;
  user-select: none;
}
</style>
