<template>
  <div
    class="
      bgModal
      fixed
      top-0
      left-0
      right-0
      z-50
      w-full
      h-full
      overflow-x-hidden overflow-y-auto
      md:inset-0
      h-modal
      md:h-full
      justify-center
      items-center
    "
  >
    <div
      class="loader w-20 h-20 md:w-60 md:h-60 m-auto z-0 mt-48 md:mt-32"
    ></div>
    <!-- <img src="../assets/logo.png" class="m-auto z-40 relative -top-52" alt="" /> -->
    <h1
      class="
        text-xl
        md:text-4xl
        font-semibold
        text-white text-center
        relative
        -top-0
        md:-top-36
        titleLoader
      "
    >
      ReDMaS
    </h1>
  </div>
</template>

<script>
export default {
  name: "SpinnerLoader",
};
</script>

<style scoped>
.loader {
  border: 16px solid #105005;
  border-radius: 50%;
  /* border-top: 16px solid #3498db; */
  /* width: 250px;
  height: 250px; */
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.titleLoader {
  -webkit-animation: reverseSpin 10s linear infinite; /* Safari */
  animation: reverseSpin 10s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

@-webkit-keyframes reverseSpin {
  0% {
    -webkit-transform: rotateX(360deg);
  }
  100% {
    -webkit-transform: rotateX(0deg);
  }
}

@keyframes reverseSpin {
  0% {
    transform: rotateX(3600deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
</style>