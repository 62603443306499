<template>
  <div class="w-full h-full m-auto text-sm bg-gray-100 unselectable">
    <div v-if="isLoading" class="px-2 ml-2 text-gray-500 italic text-xs">
      rendering, please wait...
    </div>

    <div
      v-if="!isLoading && !showLink"
      class="border rounded-xl bg-gray-300 mx-2 py-2"
    >
      <div class="flex justify-between px-4 pb-2 items-center">
        <span class="font-poppins text-xs" v-if="showAllPages">
          {{ pageCount }} page(s)
        </span>

        <span v-else class="font-poppins text-xs py-2">
          <button
            :disabled="page <= 1"
            @click="page--"
            class="py-1 px-3 rounded-md border bg-gray-700 text-white"
          >
            ❮
          </button>

          {{ page }} / {{ pageCount }}

          <button
            :disabled="page >= pageCount"
            @click="page++"
            class="py-1 px-3 rounded-md border bg-gray-700 text-white"
          >
            ❯
          </button>
        </span>

        <label class="right font-poppins text-xs inline-flex space-x-6"
          >&nbsp;
          <input v-model="showAllPages" type="checkbox" />

          Show all pages
        </label>
      </div>
      <div v-if="!showLink && !isLoading">
        <VuePdfEmbed
          ref="pdfRef"
          :page="page"
          @rendered="handleDocumentRender"
          :source="pdfsrc"
          class="text-sm overflow-y-auto overflow-x-auto unselectable"
        />
      </div>
    </div>
    <div class="bg-gray-300 pt-5 pb-3 rounded-lg">
      <!-- <embed
        :src="pdfsrc2"
        type="application/pdf"
        frameBorder="0"
        height="900"
        width="100%"
        class="unselectable2"
      /> -->
      <!-- <iframe :src="filelink">Opps something went wrong</iframe> -->
      <iframe
        wmode="transparent"
        :src="pdfsrc2"
        width="100%"
        height="700"
        style="border: none"
        class="px-2 rounded-sm"
      ></iframe>
    </div>
  </div>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed";
import axios from "axios";
export default {
  data() {
    return {
      pdfsrc: "",
      pdfsrc2: "",
      isLoading: true,
      journal_filename: null,
      page: null,
      pageCount: 1,
      showAllPages: true,
      showLink: false,
    };
  },
  components: {
    VuePdfEmbed,
  },
  props: ["filename", "filelink"],

  methods: {
    handleDocumentRender() {
      this.isLoading = false;
      this.pageCount = this.$refs.pdfRef.pageCount;
    },
    async getPDFPath(newfile) {
      var file = newfile;
      if (file != null || file != "") {
        axios
          .get(
            process.env.VUE_APP_API_SERVER + "/fileupload/stream-file/" + file,
            {
              responseType: "blob",
              headers: {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
              },
            }
          )
          .then((response) => {
            const blob = new Blob([response.data]);
            const objectURL = URL.createObjectURL(blob);
            this.pdfsrc = objectURL;
          });
      }
    },
  },

  watch: {
    filename: {
      handler(val) {
        if (val) {
          this.getPDFPath(val);
          setTimeout(() => {
            // console.log(val);
            this.showLink = false;
            this.isLoading = false;
          }, 1000);
        }
      },
      immediate: true,
    },
    filelink: {
      handler(val) {
        if (val) {
          // this.pdfsrc2 = val + "#toolbar=0";
          this.pdfsrc2 = val;
          this.showLink = true;
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        } else if (!val) {
          // console.log(val);
        }
      },
      immediate: true,
    },
    showAllPages() {
      this.page = this.showAllPages ? null : 1;
    },
  },
};
</script>

<style scoped>
/* disabling text highlighting  in the rendered pdf*/
.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
     Introduced in Internet Explorer 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
  -ms-user-select: none;
  user-select: none;
}
*.unselectable2 {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  /*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
  -ms-user-select: none;
  user-select: none;
}
</style>
