<template>
  <div class="w-full grid pb-4">
    <div class="flex justify-between">
      <div class="grid w-full mx-2 pb-4">
        <div class="flex justify-between mb-1">
          <span class="text-base font-normal text-gray-600 dark:text-white">
            Basic Details</span
          >
          <span
            class="text-sm font-medium dark:text-white"
            :class="percentage < 100.0 ? 'text-FF0000' : 'text-customGreen'"
          >
            {{ percentage }}%</span
          >
        </div>
        <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
          <div
            :class="percentage < 100.0 ? 'bg-FF0000' : 'bg-customGreen'"
            class="h-2.5 rounded-full"
            :style="{ width: percentage + '%' }"
          ></div>
        </div>
      </div>

      <button title="Update" class="">
        <svg
          @click="onEditBtn(true)"
          xmlns="http://www.w3.org/2000/svg"
          :class="isEdit ? 'animate-bounce' : ''"
          class="cursor_pointer h-10 w-10 p-1 text-gray-500 mr-4 hover:text-green-800"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
          />
          <path
            fill-rule="evenodd"
            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>

    <hr />
    <div class="mt-4 md:mt-10">
      <div class="grid md:flex flex-wrap">
        <div class="w-full md:w-1/4 px-3 md:mb-0">
          <label
            class="block tracking-wide text-green-800 text-xs font-bold mb-2"
            for="grid-first-name"
          >
            First Name <span class="text-red-500" v-if="isEdit">*</span>
          </label>
          <input
            v-model="fname"
            id="fname"
            disabled
            class="form_inputs appearance-none block w-full bg-gray-200 text-gray-700 border rounded-md py-1 text-sm px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            type="text"
            placeholder="Jane"
          />
          <p v-if="fname_err" class="text-red-500 text-xs italic">
            Please fill out this field.
          </p>
        </div>
        <div class="w-full md:w-1/4 px-3">
          <label
            class="block tracking-wide text-green-800 text-xs font-bold mb-2"
            for="grid-last-name"
          >
            Middle Name
          </label>
          <input
            v-model="mname"
            id="mname"
            disabled
            class="form_inputs appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-md py-1 text-sm mb-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            placeholder="Doe"
          />
        </div>
        <div class="w-full md:w-1/4 px-3 md:mb-0">
          <label
            class="block tracking-wide text-green-800 text-xs font-bold mb-2"
            for="grid-first-name"
          >
            Last Name<span class="text-red-500" v-if="isEdit">*</span>
          </label>
          <input
            v-model="lname"
            id="lname"
            disabled
            class="form_inputs appearance-none block w-full bg-gray-200 border border-gray-200 text-gray-700 rounded-md py-1 text-sm px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            type="text"
            placeholder="Last name"
          />
          <p v-if="lname_err" class="text-red-500 text-xs italic">
            Please fill out this field.
          </p>
        </div>

        <div class="w-full md:w-1/4 px-3">
          <label
            class="block tracking-wide text-green-800 text-xs font-bold mb-2"
            for="grid-last-name"
          >
            Highest Educational Qualifications<span
              v-if="isEdit"
              class="text-red-500"
              >*</span
            >
          </label>
          <input
            v-model="educ_qual"
            id="educ_qual"
            disabled
            class="form_inputs appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-md py-1 mb-3 text-sm px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            placeholder="BS in InfoTech"
          />
        </div>
      </div>
      <div class="grid md:flex flex-wrap">
        <div class="w-full md:w-1/4 px-3 md:mb-0">
          <label
            class="block tracking-wide text-green-800 text-xs font-bold mb-2"
            for="grid-first-name"
          >
            Name of School<span v-if="isEdit" class="text-red-500">*</span>
          </label>
          <input
            v-model="name_of_school"
            id="name_of_school"
            disabled
            class="form_inputs appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-md py-1 text-sm px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            type="text"
            placeholder="Davao del Norte State College"
          />
        </div>
        <div class="w-full md:w-1/4 px-3">
          <label
            class="block tracking-wide text-green-800 text-xs font-bold mb-2"
            for="grid-last-name"
          >
            Position
          </label>
          <input
            v-model="position"
            id="position"
            disabled
            class="form_inputs appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-md py-1 text-sm px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            placeholder="Position"
          />
        </div>
        <div class="w-full md:w-1/4 px-3 mt-3 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0">
          <label
            class="block tracking-wide text-green-800 text-xs font-bold mb-2"
          >
            Office<span v-if="isEdit" class="text-red-500">*</span>
          </label>
          <input
            v-model="office"
            id="office"
            disabled
            class="form_inputs appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-md py-1 text-sm px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            placeholder="Institute of Computing"
          />
        </div>
        <div class="w-full md:w-1/4 px-3 mt-3 md:mt-3 lg:mt-0 xl:mt-0 2xl:mt-0">
          <label
            class="block tracking-wide text-green-800 text-xs font-bold mb-2"
            for="grid-last-name"
          >
            Designation <span v-if="isEdit" class="text-red-500">*</span>
          </label>
          <input
            v-model="designation"
            id="designation"
            disabled
            class="form_inputs appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-md py-1 text-sm px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            placeholder="Institute Dean"
          />
        </div>
      </div>

      <div class="flex justify-end mr-4 pt-2" v-show="isEdit">
        <a
          href="javascript:void(0)"
          @click="handlesSaveBtn"
          class="text-white text-sm font-normal px-3 py-1 h-8 bgColor rounded"
        >
          <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 inline">
            <path fill-rule="evenodd"
              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
              clip-rule="evenodd" />
          </svg> -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4 inline"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4.5 12.75l6 6 9-13.5"
            />
          </svg>
          Save</a
        >
        <a
          href="#"
          class="text-sm text-gray-400 px-3 py-1 font-normal border mx-1 rounded h-8"
          @click="onEditBtn(false)"
        >
          Cancel</a
        >
      </div>

      <div class="pl-3 -mb-5 pt-3" v-if="isEdit">
        <p class="text-xs italic text-red-500">Fields with * are required.</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Abouts",
  data() {
    return {
      isEdit: false,
      fname: null,
      mname: null,
      lname: null,
      educ_qual: null,
      name_of_school: null,
      position: null,
      office: null,
      designation: null,
      fname_err: false,
      lname_err: false,
      percentage: null,
    };
  },
  mounted() {
    this.getUserDetails();
  },
  methods: {
    //  getting user details
    async getUserDetails() {
      axios
        .get(process.env.VUE_APP_API_SERVER + "/users-controller", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            var cnt = 0;
            this.fname = response.data.userdetail.fname;
            this.mname = response.data.userdetail.mname;
            this.lname = response.data.userdetail.lname;
            this.educ_qual = response.data.userdetail.educ_qual;
            this.name_of_school = response.data.userdetail.name_of_school;
            this.position = response.data.userdetail.position;
            this.office = response.data.userdetail.office;
            this.designation = response.data.userdetail.designation;

            if (
              response.data.userdetail.fname != null &&
              response.data.userdetail.fname != ""
            ) {
              cnt += 1;
            }
            if (
              response.data.userdetail.lname != null &&
              response.data.userdetail.lname != ""
            ) {
              cnt += 1;
            }
            if (
              response.data.userdetail.educ_qual != null &&
              response.data.userdetail.educ_qual != ""
            ) {
              cnt += 1;
            }
            if (
              response.data.userdetail.name_of_school != null &&
              response.data.userdetail.name_of_school != ""
            ) {
              cnt += 1;
            }
            // if (
            //   response.data.position != null &&
            //   response.data.position != ""
            // ) {
            //   cnt += 1;
            // }
            if (
              response.data.userdetail.office != null &&
              response.data.userdetail.office != ""
            ) {
              cnt += 1;
            }
            if (
              response.data.userdetail.designation != null &&
              response.data.userdetail.designation != ""
            ) {
              cnt += 1;
            }

            this.percentage = (parseInt(cnt) / 6) * 100;
            this.percentage = this.percentage.toFixed();
          },
          (error) => {
            console.log(error);
          }
        );
    },

    // handles DOM on form inputs
    onEditBtn(val) {
      this.isEdit = val;
      const inputs = document.querySelectorAll(".form_inputs");
      if (val == true) {
        inputs.forEach((inputField) => {
          inputField.classList.remove("bg-gray-200");
          inputField.disabled = false;
        });
      } else {
        this.getUserDetails();
        inputs.forEach((inputField) => {
          inputField.classList.add("bg-gray-200");
          inputField.disabled = true;
        });
      }
    },

    //Handles the sae button on user details
    handlesSaveBtn() {
      var valid = true;
      if (this.fname == null || this.fname == "") {
        this.fname_err = true;
        valid = false;
      } else {
        this.fname_err = false;
      }

      if (this.lname == null || this.lname == "") {
        this.lname_err = true;
        valid = false;
      } else {
        this.lname_err = false;
      }

      if (valid) {
        // console.log("valid");
        const fd = {
          fname: this.fname,
          mname: this.mname,
          lname: this.lname,
          educ_qual: this.educ_qual,
          name_of_school: this.name_of_school,
          position: this.position,
          office: this.office,
          designation: this.designation,
        };

        axios
          .post(process.env.VUE_APP_API_SERVER + "/users-controller/user", fd, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          })
          .then(
            (response) => {
              if (response.data.status == 200) {
                this.$store.dispatch("setFirstNameAction", this.fname);
                this.$store.dispatch("setMidNameAction", this.mname);
                this.$store.dispatch("setLastNameAction", this.lname);

                const alertMsg = { msg: "Update Success" };
                this.$parent.$parent.$emit("success_alert", alertMsg);
                this.isEdit = false;
                const inputs = document.querySelectorAll(".form_inputs");

                // disable input fields
                inputs.forEach((inputField) => {
                  inputField.classList.add("bg-gray-200");
                  inputField.disabled = true;
                });

                this.getUserDetails();
                // emit event to parent parent component
                this.$emit("users_details_updated");
              }
            },
            (error) => {
              console.log(error);
            }
          );
      } else {
        console.log("error");
      }
    },
  },
};
</script>

<style scoped>
.cursor_pointer:hover {
  cursor: pointer;
}
</style>