<template>
  <div class="grid w-full px-5">
    <div class="md:flex md:justify-end mt-2 -mr-1">
      <div class="hidden md:w-full md:flex sm:flex lg:flex xl:flex 2xl:flex">
        <nav
          class="md:flex -ml-4 h-8 md:mt-3 lg:mt-0 xl:mt-0 2xl:mt-0"
          aria-label="Breadcrumb"
        >
          <ol
            role="list"
            class="flex space-x-1 sm:space-x-1 md:space-x-1 lg:space-x-4 xl:space-x-4 2xl:space-x-4 rounded-md px-6"
          >
            <li
              class="flex md:flex-col md:flex lg:flex-row xl:flex-row 2xl:flex-row md:items-center"
            >
              <router-link
                to="/userspage/dashboard"
                class="text-blue-600 hover:text-gray-500"
                title="Dashboard"
              >
                <svg
                  class="h-5 w-5 flex-shrink-0 md:mt-1.5 lg:mt-0 xl:mt-0 2xl:mt-0"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                    clip-rule="evenodd"
                  />
                </svg>
              </router-link>
            </li>
            <!-- <li class="flex  items-center"> <div class="flex items-center"> <svg class="h-5 w-5 flex-shrink-0 text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true"> <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" /> </svg> <a href="#" @click="back" class="ml-4 text-xs font-medium text-blue-600 hover:text-gray-700">Approval</a> </div> </li> -->
            <li class="flex items-center">
              <div class="flex items-center">
                <svg
                  class="h-5 w-5 flex-shrink-0 text-gray-700"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
                <a
                  href="#"
                  class="md:ml-0 lg:ml-4 text-xs font-medium text-blue-600 hover:text-gray-700"
                  @click="back"
                  >Researches</a
                >
              </div>
            </li>
            <li class="flex items-center" v-if="toggleBreadcrumbs == false">
              <div class="flex items-center">
                <svg
                  class="h-5 w-5 flex-shrink-0 text-gray-700"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
                <a
                  href="#"
                  class="md:ml-0 lg:ml-4 text-xs font-medium text-gray-500"
                  >See details</a
                >
              </div>
            </li>
            <li
              class="flex items-center"
              v-if="toggleBreadcrumbs == true"
              @click="goBack()"
            >
              <div class="flex items-center">
                <svg
                  class="h-5 w-5 flex-shrink-0 text-gray-700"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
                <a href="#" class="ml-4 text-xs font-medium text-blue-600"
                  >See details</a
                >
              </div>
            </li>
            <li class="flex items-center" v-if="toggleBreadcrumbs == true">
              <div class="flex items-center">
                <svg
                  class="h-5 w-5 flex-shrink-0 text-gray-700"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
                <a href="#" class="ml-4 text-xs font-medium text-gray-500"
                  >View journals</a
                >
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div class="w-full flex justify-end">
        <!-- BACK BUTTON  -->

        <!-- <button @click="back" data-bs-toggle="tooltip" data-bs-placement="top" title="Back" class=" mx-2 h-8 bg-gray-600 text-white font-semibold hover:text-gray-200 w-auto px-2 py-1 text-sm border border-gray-500 hover:border-transparent rounded "> <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"> <path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" /> </svg> Back </button> -->

        <!-- ENDORSE TO LIBRARIAN BUTTON (DEAN/PC PAGE) -->
        <!-- <button data-bs-toggle="tooltip" data-bs-placement="top" title="Endorse"
          v-if="journal_item.journalstatus.id == 2 && journal_item.typeOfArticle <= 2 && this.$store.getters.getUserRole != 1"
          @click="toggleEndorseDecisionModal(journal_item)"
          class=" mx-2 bg-105005 text-white font-semibold hover:text-green-200 w-auto px-2 py-1 text-sm hover:border-transparent rounded ">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline" fill="none" viewBox="0 0 24 24"
            stroke="currentColor" stroke-width="2">
            <path fill-rule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clip-rule="evenodd" />
          </svg> Endorse
        </button> -->

        <!-- <button data-bs-toggle="tooltip" data-bs-placement="top" title="Approve" v-if="journal_item.journalstatus.id == 1 && journal_item.typeOfArticle >= 3 && this.$store.getters.getUserRole != 1" @click="toggleApproveDecisionModal(journal_item)" class=" mx-2 bg-105005 text-white font-semibold hover:text-green-100 w-auto px-2 py-1 text-sm border border-green-900 hover:border-transparent rounded "> <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"> <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" /> </svg> Approve </button> -->
        <!-- <button data-bs-toggle="tooltip" data-bs-placement="top" title="Endorse" v-if="journal_item.journalstatus.id == 1 && journal_item.typeOfArticle <= 2 && this.$store.getters.getUserRole != 1" @click="toggleEndorseDecisionModal(journal_item)" class=" mx-2 bg-105005 text-white font-semibold hover:text-green-200 w-auto px-2 py-1 text-sm hover:border-transparent rounded "> <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"> <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" /> </svg> Endorse </button> -->

        <!-- APPROVED BUTTON (DEAN/PC PAGE) -->
        <button
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Approve"
          v-if="
            journal_item.journalstatus.id == 1 &&
            this.$store.getters.getUserRole != 1
          "
          @click="toggleApproveDecisionModal(journal_item)"
          class="mx-2 bg-105005 text-white font-semibold hover:text-green-100 w-auto px-2 py-1 text-sm border border-green-900 hover:border-transparent rounded"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4 inline"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              fill-rule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
          Set as verified
        </button>
        <!-- <button data-bs-toggle="tooltip" data-bs-placement="top" title="Approve" v-if="journal_item.journalstatus.id == 1 && journal_item.typeOfArticle <= 2 && this.$store.getters.getUserRole == 1" @click="toggleApproveDecisionModal(journal_item)" class=" mx-2 bg-105005 text-white font-semibold hover:text-green-100 w-auto px-2 py-1 text-sm border border-green-900 hover:border-transparent rounded "> <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"> <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" /> </svg> Approvesdsd </button> -->

        <!-- APPROVED BUTTON (SUPERADMIN/LIBRARIAN PAGE) -->
        <button
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Approve"
          v-if="
            journal_item.journalstatus.id == 2 &&
            journal_item.journalstatus.id != 5 &&
            this.$store.getters.getUserRole == 1
          "
          @click="toggleApproveDecisionModal2(journal_item)"
          class="mx-2 bg-105005 text-white font-semibold hover:text-green-100 w-auto px-2 md:px-3 md:py-0 py-1 text-sm border border-green-900 hover:border-transparent rounded"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4 inline"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              fill-rule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
          Publish
        </button>
        <!-- PENDING BUTTON (DEAN/PC PAGE) -->
        <button
          v-if="
            journal_item.journalstatus.id == 1 &&
            this.$store.getters.getUserRole != 1
          "
          @click="togglePendingDecisionModal1(journal_item)"
          class="mx-2 bg-FFB800 text-white font-semibold hover:bg-orange-400 hover:text-yellow-200 w-auto px-2 py-1 text-sm border border-yellow-500 hover:border-transparent rounded"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="w-4 h-4 inline"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
            />
          </svg>
          &nbsp;Pending
        </button>

        <!-- PENDING BUTTON (LIBRARIAN/SUPERADMIN) -->
        <button
          v-if="
            journal_item.journalstatus.id == 2 &&
            this.$store.getters.getUserRole == 1
          "
          @click="togglePendingDecisionModal2(journal_item)"
          class="mx-2 bg-FFB800 text-white font-semibold hover:bg-orange-400 hover:text-yellow-200 w-auto px-2 py-1 text-sm border border-yellow-500 hover:border-transparent rounded"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="w-4 h-4 inline"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
            />
          </svg>
          &nbsp;Pending
        </button>
        <button
          @click="back"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Back"
          class="mx-2 text-gray-500 bg-gray-300 border font-semibold w-auto px-2 py-1 text-sm rounded"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4 inline"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
            />
          </svg>
          Back
        </button>
      </div>
    </div>
    <!-- <div
      v-if="isExist && !isViewFile && this.$store.getters.getUserRole == 1 && journal_item.journalstatus.id == 4 || journal_item.journalstatus.id == 1 || this.eventLength > 0"
      class="bg-yellow-100 md:w-full p-3 mt-3 rounded-md -mb-2 border border-yellow-200">
      <div class="flex items-center justify-between">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="w-7 h-7 inline">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5" />
        </svg>&nbsp;&nbsp;&nbsp;
        <span class="flex flex-grow flex-col">
          <span class="text-sm font-medium text-gray-900" id="availability-label">{{ this.eventName }}</span>
          <span class="text-sm text-gray-500" id="availability-description">{{ this.journal_item.title }} wants to join
            {{ this.eventName }}.</span>
        </span>
        Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200"
        <div class="pr-4">
          <button type="button" @click="toggleBtn" :class="isBtnActive ? 'bg-yellow-300' : 'bg-gray-300'"
            title="Approved to join research to this event"
            class="toggleApproveEvent relative toggle inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-yellow-300 focus:ring-offset-2"
            role="switch" aria-checked="false" aria-labelledby="availability-label"
            aria-describedby="availability-description">
            Enabled: "translate-x-5", Not Enabled: "translate-x-0"
            <span aria-hidden="true" :class="isBtnActive ? 'translate-x-5' : 'translate-x-0'"
              class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out">
            </span>
          </button>
        </div>
      </div>

    </div> -->
    <!-- && !isViewFile && this.$store.getters.getUserRole == 1 || this.$store.getters.getUserRole == 2 || this.$store.getters.getUserRole == 3 && journal_item.journalstatus.id == 1 -->

    <div
      v-if="
        journal_item.journalstatus.id == 1 &&
        isExist == true &&
        this.$store.getters.getUserRole == 2 &&
        !isViewFile
      "
    >
      <div v-if="isLoading" class="flex justify-center py-5">
        <div class="spinLoader"></div>
      </div>
      <div class="border-l-4 border-yellow-400 bg-yellow-50 p-4 mt-3 -mb-2">
        <div class="flex items-center justify-between">
          <div class="flex-shrink-0">
            <!-- Heroicon name: mini/exclamation-triangle -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 text-yellow-400"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5"
              />
            </svg>
          </div>
          <div class="ml-3 flex flex-grow flex-col">
            <p class="text-sm text-yellow-700">
              The author would like to submit this research for this event:<br />
              "<span class="font-semibold">{{ this.eventName }}</span
              >"
              <!-- <a href="#" class="font-medium text-yellow-700 underline hover:text-yellow-600">Upgrade your account to add
              more
              credits.</a> -->
            </p>
          </div>
          <div class="pr-4">
            <button
              type="button"
              @click="toggleBtn"
              :class="isBtnActive ? 'bg-yellow-300' : 'bg-gray-300'"
              title="Approve this research"
              class="toggleApproveEvent relative toggle inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out"
              role="switch"
              aria-checked="false"
              aria-labelledby="availability-label"
              aria-describedby="availability-description"
            >
              <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
              <span
                aria-hidden="true"
                :class="isBtnActive ? 'translate-x-5' : 'translate-x-0'"
                class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
              >
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        journal_item.journalstatus.id == 1 &&
        isExist == true &&
        this.$store.getters.getUserRole == 3 &&
        !isViewFile
      "
    >
      <div class="border-l-4 border-yellow-400 bg-yellow-50 p-4 mt-3 -mb-2">
        <div class="flex items-center justify-between">
          <div class="flex-shrink-0">
            <!-- Heroicon name: mini/exclamation-triangle -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 text-yellow-400"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5"
              />
            </svg>
          </div>
          <div class="ml-3 flex flex-grow flex-col">
            <p class="text-sm text-yellow-700">
              The author would like to submit this research for this event:<br />
              "<span class="font-semibold">{{ this.eventName }}</span
              >"
              <!-- <a href="#" class="font-medium text-yellow-700 underline hover:text-yellow-600">Upgrade your account to add
                more
                credits.</a> -->
            </p>
          </div>
          <div class="pr-4">
            <button
              type="button"
              @click="toggleBtn"
              :class="isBtnActive ? 'bg-yellow-300' : 'bg-gray-300'"
              title="Approve this research"
              class="toggleApproveEvent relative toggle inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out"
              role="switch"
              aria-checked="false"
              aria-labelledby="availability-label"
              aria-describedby="availability-description"
            >
              <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
              <span
                aria-hidden="true"
                :class="isBtnActive ? 'translate-x-5' : 'translate-x-0'"
                class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
              >
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="grid auto-rows-auto sm:grid sm:auto-rows-auto md:grid md:auto-rows-auto h-auto lg:flex xl:flex w-full py-6"
    >
      <div
        class="md:mr-2 w-auto md:mb-4 lg:w-full bg-white md:grid shadow-sm text-left px-5 rounded-md overflow-y-auto"
        v-if="!isViewFile"
      >
        <div class="mt-2 md:mt-2 grid lg:flex md:flex m-auto w-full">
          <div class="mt-4 flex w-full">
            <h1
              class="text-2xl md:text-4xl font-semibold text-justify md:w-full lg:w-full"
            >
              {{ journal_item.title }}
            </h1>
          </div>
        </div>

        <div class="mt-4 md:mt-4">
          <!-- PUBLISHED/UNPUBLISHED BADGES -->
          <span
            v-if="journal_item.isPublished == 0"
            class="inline-flex items-center rounded-full uppercase bg-red-100 m-0.5 px-4 py-1 text-xs text-red-800"
            >Unpublished</span
          >
          <span
            v-if="journal_item.isPublished == 1"
            class="inline-flex items-center rounded-full uppercase bg-green-100 m-0.5 px-4 py-1 text-xs text-green-800"
            >Published</span
          >
          <!-- END OF PUBLISHED/UNPUBLISHED BADGES -->

          <!-- <span v-if="journal_item.isPublished == 1"
            class="uppercase font-bold mt-2 bg-green-500 rounded-full text-xs w-auto px-4 py-1 m-1 text-white top-0">Published</span>
          <span v-if="journal_item.isPublished == 0"
            class="uppercase font-bold mt-1 bg-red-600 rounded-full text-xs w-auto px-4 py-1 m-1 text-white top-0">Unpublished</span> -->

          <!-- JOURNAL STATUS BADGE -->
          <span
            v-if="journal_item.journalstatus.id == 1"
            class="inline-flex items-center rounded-full uppercase bg-blue-100 m-0.5 px-4 py-1 text-xs text-blue-800"
            >For Verification</span
          >

          <span
            v-if="journal_item.journalstatus.id == 2"
            class="inline-flex items-center rounded-full uppercase bg-blue-100 m-0.5 px-4 py-1 text-xs text-blue-800"
            >Verified</span
          >

          <span
            v-if="
              journal_item.journalstatus.id == 3 &&
              this.$store.getters.getUserRole != 1
            "
            class="inline-flex items-center rounded-full uppercase bg-blue-100 m-0.5 px-4 py-1 text-xs text-blue-800"
            >Pending</span
          >

          <!-- <span v-if="journal_item.journalstatus.id == 4 && this.$store.getters.getUserRole != 1"
            class="inline-flex items-center rounded-full uppercase bg-blue-100 m-0.5 px-4 py-1 text-xs text-blue-800">Endorsed
            to Librarian</span> -->

          <!-- <span v-if="journal_item.journalstatus.id == 4 && this.$store.getters.getUserRole == 1"
            class="inline-flex items-center rounded-full uppercase bg-blue-100 m-0.5 px-4 py-1 text-xs text-blue-800">Endorsed
            By Dean</span> -->

          <!-- <span v-if="journal_item.journalstatus.id == 5"
            class="inline-flex items-center rounded-full uppercase bg-blue-100 m-0.5 px-4 py-1 text-xs text-blue-800">Approved
            By Librarian</span> -->

          <!-- TYPE OF ARTICLE BADGES -->
          <span
            v-if="journal_item.typeOfArticle == 1"
            class="inline-flex items-center rounded-full uppercase bg-purple-100 m-0.5 px-4 py-1 text-xs text-purple-800"
            >Thesis</span
          >

          <span
            v-if="journal_item.typeOfArticle == 2"
            class="inline-flex items-center rounded-full uppercase bg-purple-100 m-0.5 px-4 py-1 text-xs text-purple-800"
            >Capstone</span
          >

          <span
            v-if="journal_item.typeOfArticle == 3"
            class="inline-flex items-center rounded-full uppercase bg-purple-100 m-0.5 px-4 py-1 text-xs text-purple-800"
            >Dissertation</span
          >

          <span
            v-if="journal_item.typeOfArticle == 4"
            class="inline-flex items-center rounded-full uppercase bg-purple-100 m-0.5 px-4 py-1 text-xs text-purple-800"
            >Research</span
          >

          <span
            v-if="journal_item.typeOfArticle == 5"
            class="inline-flex items-center rounded-full uppercase bg-purple-100 m-0.5 px-4 py-1 text-xs text-purple-800"
            >Journal</span
          >

          <span
            v-if="journal_item.typeOfArticle == 6"
            class="inline-flex items-center rounded-full uppercase bg-purple-100 m-0.5 px-4 py-1 text-xs text-purple-800"
            >Others</span
          >

          <!-- <span v-if="journal_item.typeOfArticle == 1"
            class="uppercase font-bold mt-1 bg-purple-500 rounded-full text-xs w-auto px-4 py-1 m-1 text-white top-0">Thesis</span>
          <span v-if="journal_item.typeOfArticle == 2"
            class="uppercase font-bold mt-1 bg-purple-500 rounded-full text-xs w-auto px-4 py-1 m-1 text-white top-0">Capstone</span>
          <span v-if="journal_item.typeOfArticle == 3"
            class="uppercase font-bold mt-1 bg-purple-500 rounded-full text-xs w-auto px-4 py-1 m-1 text-white top-0">Dissertation</span>
          <span v-if="journal_item.typeOfArticle == 4"
            class="uppercase font-bold mt-1 bg-purple-500 rounded-full text-xs w-auto px-4 py-1 m-1 text-white top-0">Research</span>
          <span v-if="journal_item.typeOfArticle == 5"
            class="uppercase font-bold mt-1 bg-purple-500 rounded-full text-xs w-auto px-4 py-1 m-1 text-white top-0">Journal</span>
          <span v-if="journal_item.typeOfArticle == 6"
            class="uppercase font-bold mt-1 bg-purple-500 rounded-full text-xs w-auto px-4 py-1 m-1 text-white top-0">Others</span> -->
          <!--END OF TYPE OF ARTICLE BADGES -->

          <!-- UPDATE TYPE OF ARTICLE BUTTON -->
          <!-- <button type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Update Type"
            v-if="journal_item.journalstatus.id == 2" @click="toggleUpdateJournalDetailsDecisionModal(journal_item)"
            class="transition duration-150 hidden md:flex ease-in-out float-right font-bold mt-1 text-sm bg-yellow-500 hover:text-yellow-200 rounded w-auto px-2 py-1 m-1 text-white top-0">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-4 h-4 inline">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
            </svg>
            Update Type
          </button> -->
          <!-- END OF UPDATE TYPE OF ARTICLE BUTTON -->
        </div>
        <div class="mt-4 md:mt-4" v-if="isExist2 == true">
          <h1 class="text-green text-sm">
            Event:
            <span class="text-gray-700 font-semibold">{{
              this.eventName2
            }}</span>
          </h1>
        </div>
        <!-- <div class="mt-5 p-2">
          <nav aria-label="Progress">
            <ol role="list" class="space-y-4 md:flex md:space-y-0 md:space-x-8">
              <li class="md:flex-1">
                Completed Step
                <div
                  class="group flex flex-col border-l-4 border-indigo-600 py-2 pl-4 hover:border-indigo-800 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0">
                  <span class="text-sm font-medium text-indigo-600 group-hover:text-indigo-800">
                    <div class="inline">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                        <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                          clip-rule="evenodd" />
                      </svg>
                      <span></span>
                    </div>
                    <span class="text-sm font-medium">Job details</span>
                  </span>
                </div>
              </li>

              <li class="md:flex-1">
                Current Step
                <a href="#"
                  class="flex flex-col border-l-4 border-indigo-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                  aria-current="step">
                  <span class="text-sm font-medium text-indigo-600">Step 2</span>
                  <span class="text-sm font-medium">Application form</span>
                </a>
              </li>

              <li class="md:flex-1">
                Upcoming Step
                <a href="#"
                  class="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0">
                  <span class="text-sm font-medium text-gray-500 group-hover:text-gray-700">Step 3</span>
                  <span class="text-sm font-medium">Preview</span>
                </a>
              </li>
            </ol>
          </nav>
        </div> -->
        <div class="mt-4 md:mt-4" v-if="journal_item.isPublished">
          <h1 class="text-green-800 font-bold text-sm">
            Publisher:
            <span class="text-gray-700 font-normal">{{
              journal_item.publisher
            }}</span>
          </h1>
        </div>

        <div class="mt-4 md:mt-4">
          <h1 class="text-green-800 font-bold text-sm text-start">
            Main author:
            <span class="text-gray-700 font-normal text-end">
              {{
                journal_item.mainauthormname
                  ? journal_item.mainauthorlname +
                    ", " +
                    journal_item.mainauthorfname +
                    " " +
                    journal_item.mainauthormname[0]
                  : journal_item.mainauthorlname +
                    ", " +
                    journal_item.mainauthorfname
              }}
            </span>
          </h1>
        </div>

        <div class="mt-4 md:mt-2">
          <h1 class="text-green-800 font-bold text-sm">
            Corresponding Author:
            <span class="text-gray-600 font-normal">
              {{
                journal_item.mainauthorlname
                  ? journal_item.mainauthorlname + ", "
                  : ""
              }}
              {{ journal_item.mainauthorfname }}
              {{
                journal_item.mainauthormname
                  ? journal_item.mainauthormname[0] + "."
                  : ""
              }}
            </span>
          </h1>
        </div>

        <div class="mt-4 md:mt-2">
          <h1 class="text-green-800 font-bold text-sm">
            Co-author/s:
            <span class="text-gray-700 font-normal"
              >{{ coauthors ? coauthors : "-" }}
            </span>
          </h1>
        </div>
        <div class="mt-4 md:mt-2">
          <h1 class="text-green-800 font-bold text-sm">
            Keywords:
            <span class="text-gray-700 font-normal"
              >{{ journal_item.authorsKeywords }}
            </span>
          </h1>
        </div>

        <hr class="mt-4 md:mt-4" />

        <div class="mt-4 md:mt-4">
          <h1
            class="text-sm font-semibold lg:text-lg md:text-lg md:-mt-2 md:mb-2"
          >
            Abstract
          </h1>
          <p
            class="text-justify sm:text-justify md:text-justify lg:text-justify xl:text-justify 2xl:text-justify text-sm"
          >
            {{ journal_item.abstract }}
          </p>
        </div>

        <div class="mt-4 grid grid-cols-2 gap-4">
          <div class="w-full text-sm flex justify-between">
            <div class="font-semibold">Publisher:</div>
            <div class="text-blue-800">
              {{
                journal_item.isPublished == 1
                  ? journal_item.publisher
                  : "Not yet published"
              }}
            </div>
          </div>
          <div
            class="w-full text-sm flex justify-between"
            v-if="
              journal_item.accessionNumber != null ||
              journal_item.accessionNumber != ''
            "
          >
            <div class="font-semibold">INSPEC Accession Number:</div>
            <div
              class="text-gray-500"
              v-if="this.$store.getters.getUserRole == 1"
            >
              {{ journal_item.accessionNumber }}
            </div>
            <div
              v-if="this.$store.getters.getUserRole > 1"
              class="text-gray-500"
            >
              {{
                journal_item.accessionNumber
                  ? journal_item.accessionNumber
                  : "-"
              }}
            </div>
          </div>
          <div
            class="w-full text-sm flex justify-between"
            v-if="journal_item.conferenceDate != ''"
          >
            <div class="font-semibold">Year submitted:</div>
            <div class="text-gray-500">
              {{
                journal_item.conferenceDate != ""
                  ? journal_item.conferenceDate
                  : "-"
              }}
            </div>
          </div>
          <div
            class="w-full text-sm flex justify-between"
            v-if="journal_item.doi"
          >
            <div class="font-semibold">DOI:</div>
            <div class="text-gray-500">
              {{ journal_item.doi ? journal_item.doi : "-" }}
            </div>
          </div>
          <div class="w-full text-sm flex justify-between">
            <div class="font-semibold">Date Uploaded to ReDMaS:</div>
            <div class="text-gray-500">
              {{ formatDate(journal_item.createdAt) }}
            </div>
          </div>
        </div>

        <!-- <div class="mt-4 text-sm flex justify-start gap-8">
          <div class="w-full flex justify-between">
            <div class="font-semibold">Year submitted:</div>
            <div class="text-gray-500">
              {{
                journal_item.conferenceDate != ""
                  ? formatYear(journal_item.conferenceDate)
                  : "-"
              }}
            </div>
          </div>
          <div class="w-full flex justify-between">
            <div class="font-semibold">DOI:</div>
            <div class="text-gray-500">
              {{ journal_item.doi ? journal_item.doi : "-" }}
            </div>
          </div>
        </div>

        <div class="mt-4 text-sm flex justify-start gap-8">
          <div class="w-full flex justify-between">
            <div class="font-semibold">Date Uploaded to ReDMaS:</div>
            <div class="text-gray-500">
              {{ formatDate(journal_item.createdAt) }}
            </div>
          </div>
          <div class="w-full flex justify-between">
            <div class="font-semibold">DOI:</div>
                <div class=""> {{ journalData.doi ? journalData.doi : "-" }}</div>
          </div>
        </div> -->

        <!-- <div class="mt-4 md:mt-4 grid md:grid lg:flex m-auto w-full">
          <div class="mt-4 flex w-full">
            <h1
              class="text-left w-full font-semibold text-sm mr-0 sm:mr-0 md:mr-5 lg:mr-0 xl:mr-0 2xl:mr-0"
            >
              Published in:
            </h1>
            <h1 class="text-blue-800 text-sm w-full text-left">
              {{
                journal_item.isPublished
                  ? journal_item.publishedIn
                  : "Not published"
              }}
            </h1>
          </div>
          <div class="mt-4 flex w-full">
            <h1
              class="font-semibold w-full text-left text-sm mr-0 sm:mr-0 md:mr-5 lg:mr-0 xl:mr-0 2xl:mr-0"
            >
              INSPEC Accession Number:
            </h1>
            <h1 class="text-gray-800 text-sm w-full text-left">
              {{
                journal_item.accessionNumber
                  ? journal_item.accessionNumber
                  : "-"
              }}
            </h1>
          </div>
        </div>

        <div class="mt-3 md:mt-1 grid lg:flex md:grid m-auto w-full">
          <div class="mt-4 flex w-full">
            <h1
              class="font-semibold w-full text-sm mr-0 sm:mr-0 md:mr-5 lg:mr-0 xl:mr-0 2xl:mr-0"
            >
              Date Conferred:
            </h1>
            <h1 class="text-gray-800 text-sm w-full">
              {{
                journal_item.conferenceDate != ""
                  ? formatDate(journal_item.conferenceDate)
                  : "-"
              }}
            </h1>
          </div>
          <div class="mt-4 flex w-full">
            <h1
              class="font-semibold w-full text-sm mr-0 sm:mr-0 md:mr-5 lg:mr-0 xl:mr-0 2xl:mr-0"
            >
              DOI:
            </h1>
            <h1 class="text-gray-800 text-sm w-full">
              {{ journal_item.doi ? journal_item.doi : "-" }}
            </h1>
          </div>
        </div>

        <div class="mt-3 md:mt-1 grid lg:flex md:grid m-auto w-full">
          <div class="mt-4 flex w-full">
            <h1
              class="font-semibold w-full text-sm mr-0 sm:mr-0 md:mr-5 lg:mr-0 xl:mr-0 2xl:mr-0"
            >
              Date Added to ReDMaS:
            </h1>
            <h1 class="text-gray-800 text-sm w-full">
              {{ formatDate(journal_item.createdAt) }}
            </h1>
          </div>
          <div class="mt-4 flex w-full">
            <h1
              class="font-semibold w-full text-sm mr-0 sm:mr-0 md:mr-5 lg:mr-0 xl:mr-0 2xl:mr-0"
            >
              Conference Location:
            </h1>
            <h1
              class="text-gray-800 text-sm"
              :class="
                journal_item.conferenceLocation ? 'w-72 break-words' : 'w-full'
              "
            >
              {{
                journal_item.conferenceLocation
                  ? journal_item.conferenceLocation
                  : "-"
              }}
            </h1>
          </div>
        </div> -->

        <!-- <div class="mt-4 md:mt-1 lg:flex md:grid m-auto w-full">
          <div class="mt-4 flex w-full md:pr-10">
            <h1 class="text-left w-full font-semibold text-sm mr-0 sm:mr-0 md:mr-5 lg:mr-0 xl:mr-0 2xl:mr-0">
              Date Added to ReDMaS:
            </h1>
            <h1 class="text-gray-800 text-sm w-full">
              {{ formatDate(journal_item.createdAt) }}
            </h1>
          </div>
          <div class="mt-4 flex w-full md:pr-10">
            <h1 class="font-semibold w-full text-sm mr-0 sm:mr-0 md:mr-5 lg:mr-0 xl:mr-0 2xl:mr-0">Conference Location:
            </h1>
            <h1 class="text-gray-800 text-sm w-full">
              {{ journal_item.conferenceLocation ? journal_item.conferenceLocation : '-' }}
            </h1>
          </div>
        </div> -->

        <hr class="w-full mt-10" />

        <div class="w-full grid pb-5">
          <button
            @click="redirectTo(journal_item.filename, journal_item.fileLink)"
            class="view_btn uppercase font-semibold mt-4 bg-yellow-500 rounded-md text-sm md:px-4 w-full md:w-full lg:w-1/2 xl:w-1/4 2xl:w-1/4 py-1 m-auto hover:text-white"
          >
            View Article
          </button>
        </div>
      </div>
      <div
        class="md:mb-4 lg:mb-0 xl:mb-0 2xl:mb-0 sm:w-full md:w-full lg:w-1/3 xl:w-1/3 2xl:w-1/3 py-5 px-5 md:px-8 md:py-8 bg-white lg:ml-2 xl:ml-2 2xl:ml-2 mt-5 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0 shadow-sm rounded-md overflow-y-auto md:-ml-1 w-auto text-left"
        v-if="!isViewFile"
      >
        <nav
          aria-label="Progress"
          class="sm:flex md:grid md:grid-cols-2 md:gap-2 lg:grid xl:grid lg:grid-cols-1 xl:grid-cols-1 2xl:grid 2xl:grid-cols-1"
        >
          <ol role="list" class="overflow-hidden">
            <!-- UPLOADED -->
            <li
              class="relative pb-10"
              v-if="
                journal_item.journalstatus.id == 1 ||
                journal_item.journalstatus.id == 2 ||
                journal_item.journalstatus.id == 4 ||
                journal_item.journalstatus.id == 5
              "
            >
              <div
                class="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-105005"
                aria-hidden="true"
              ></div>
              <!-- Complete Step -->
              <div class="group relative flex items-start">
                <span class="flex h-9 items-center">
                  <span
                    class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-105005"
                  >
                    <!-- Heroicon name: mini/check -->
                    <svg
                      class="h-5 w-5 text-green-100"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </span>
                <span class="ml-4 flex min-w-0 flex-col">
                  <span class="text-sm text-105005 font-semibold"
                    >Uploaded</span
                  >
                  <span class="text-xs text-105005">{{
                    moment(journal_item.createdAt).fromNow()
                  }}</span>
                </span>
              </div>
            </li>
            <li
              class="relative pb-10"
              v-if="journal_item.journalstatus.id == 3"
            >
              <div
                style="
                  background-image: linear-gradient(
                    to bottom,
                    #116603,
                    #538615,
                    #8aa52b,
                    #c3c444,
                    #ffe362
                  );
                "
                class="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-105005"
                aria-hidden="true"
              ></div>
              <div class="group relative flex items-start">
                <span class="flex h-9 items-center">
                  <span
                    class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-105005"
                  >
                    <svg
                      class="h-5 w-5 text-green-100"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </span>
                <span class="ml-4 flex min-w-0 flex-col">
                  <span class="text-sm text-105005 font-semibold"
                    >Uploaded</span
                  >
                  <span class="text-xs text-105005">{{
                    stringFormatDate(formatDate(journal_item.createdAt))
                  }}</span>
                </span>
              </div>
            </li>
            <!-- END OF UPLOADED -->

            <!-- APPROVED BY DEAN -->
            <li
              class="relative pb-10"
              v-if="journal_item.journalstatus.id == 1"
            >
              <div
                class="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                aria-hidden="true"
              ></div>
              <!-- Current Step -->
              <div class="group relative flex items-start" aria-current="step">
                <span class="flex h-9 items-center" aria-hidden="true">
                  <span
                    class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-105005 bg-white"
                  >
                    <span
                      class="h-2.5 w-2.5 animate-pulse rounded-full bg-105005"
                    ></span>
                  </span>
                </span>
                <span class="ml-4 flex min-w-0 flex-col">
                  <span class="text-sm text-105005 font-semibold"
                    >Verification</span
                  >
                  <span class="text-xs text-gray-500">Verifying...</span>
                </span>
              </div>
            </li>
            <li
              class="relative pb-10"
              v-if="journal_item.journalstatus.id == 2"
            >
              <div
                class="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-105005"
                aria-hidden="true"
              ></div>
              <!-- Complete Step -->
              <div class="group relative flex items-start" aria-current="step">
                <span class="flex h-9 items-center" aria-hidden="true">
                  <span
                    class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-105005"
                  >
                    <!-- Heroicon name: mini/check -->
                    <svg
                      class="h-5 w-5 text-green-100"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </span>
                <span class="ml-4 flex min-w-0 flex-col">
                  <span class="text-sm text-105005 font-semibold"
                    >Verified</span
                  >
                  <span class="text-xs text-105005">{{
                    moment(journal_item.approvedByDeanDate).fromNow()
                  }}</span>
                </span>
              </div>
            </li>
            <li
              class="relative pb-10"
              v-if="journal_item.journalstatus.id == 4"
            >
              <div
                class="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-105005"
                aria-hidden="true"
              ></div>
              <div class="group relative flex items-start" aria-current="step">
                <span class="flex h-9 items-center" aria-hidden="true">
                  <span
                    class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-105005"
                  >
                    <svg
                      class="h-5 w-5 text-green-100"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </span>
                <span class="ml-4 flex min-w-0 flex-col">
                  <span class="text-sm text-105005 font-semibold"
                    >Verified</span
                  >
                  <span class="text-xs text-105005">{{
                    moment(journal_item.approvedByDeanDate).fromNow()
                  }}</span>
                </span>
              </div>
            </li>
            <li
              class="relative pb-10"
              v-if="journal_item.journalstatus.id == 5"
            >
              <div
                class="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-105005"
                aria-hidden="true"
              ></div>
              <div class="group relative flex items-start" aria-current="step">
                <span class="flex h-9 items-center" aria-hidden="true">
                  <span
                    class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-105005"
                  >
                    <svg
                      class="h-5 w-5 text-green-100"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </span>
                <span class="ml-4 flex min-w-0 flex-col">
                  <span class="text-sm text-105005 font-semibold"
                    >Verified</span
                  >
                  <span class="text-xs text-105005">{{
                    moment(journal_item.approvedByDeanDate).fromNow()
                  }}</span>
                </span>
              </div>
            </li>
            <!-- END OF DEAN'S APPROVAL -->
            <li
              class="relative pb-10"
              v-if="journal_item.journalstatus.id == 3"
            >
              <div
                style="
                  background-image: linear-gradient(
                    to top,
                    #116603,
                    #538615,
                    #8aa52b,
                    #c3c444,
                    #ffe362
                  );
                "
                class="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                aria-hidden="true"
              ></div>
              <!-- Upcoming Step -->
              <a href="#" class="group relative flex items-start">
                <span class="flex h-9 items-center" aria-hidden="true">
                  <span
                    class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-yellow-500"
                  >
                    <!-- Heroicon name: mini/check -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5 text-white"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                      />
                    </svg>
                  </span>
                </span>
                <span class="ml-4 flex min-w-0 flex-col">
                  <span class="text-sm font-semibold text-yellow-500"
                    >Pended</span
                  >
                  <span class="text-xs text-yellow-500 text-justify"
                    >Please see remarks below.</span
                  >
                  <!-- <span class="text-xs text-yellow-500 text-justify">{{
                    stringFormatDate(formatDate(journal_item.pendingDate))
                  }}</span> -->
                  <span class="text-xs text-yellow-500">{{
                    moment(journal_item.approvedByLibDate).fromNow()
                  }}</span>
                </span>
              </a>
            </li>
            <li
              class="relative pb-10"
              v-if="journal_item.journalstatus.id == 2"
            >
              <!-- <div class="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true"></div> -->
              <!-- Current Step -->
              <div class="group relative flex items-start" aria-current="step">
                <span class="flex h-9 items-center" aria-hidden="true">
                  <span
                    class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-105005 bg-white"
                  >
                    <span
                      class="h-2.5 w-2.5 animate-pulse rounded-full bg-105005"
                    ></span>
                  </span>
                </span>
                <span class="ml-4 flex min-w-0 flex-col">
                  <span class="text-sm text-105005 font-semibold"
                    >Ready to publish</span
                  >
                  <span class="text-xs text-gray-500"
                    >Waiting to publish...</span
                  >
                </span>
              </div>
            </li>

            <li class="relative" v-if="journal_item.journalstatus.id == 5">
              <!-- Upcoming Step -->
              <a href="#" class="group relative flex items-start">
                <span class="flex h-9 items-center" aria-hidden="true">
                  <span
                    class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-105005"
                  >
                    <!-- Heroicon name: mini/check -->
                    <svg
                      class="h-5 w-5 text-green-100"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </span>
                <span class="ml-4 flex min-w-0 flex-col">
                  <span class="text-sm font-semibold text-105005"
                    >Published</span
                  >
                  <!-- <span class="text-xs text-yellow-500 text-justify">{{
                      stringFormatDate(formatDate(journal_item.pendingDate))
                    }}</span> -->
                  <span class="text-xs text-105005">{{
                    moment(journal_item.approvedByLibDate).fromNow()
                  }}</span>
                </span>
              </a>
            </li>

            <li class="relative" v-if="journal_item.journalstatus.id == 1">
              <!-- Upcoming Step -->
              <a href="#" class="group relative flex items-start">
                <span class="flex h-9 items-center" aria-hidden="true">
                  <span
                    class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400"
                  >
                    <span
                      class="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                    ></span>
                  </span>
                </span>
                <span class="ml-4 flex min-w-0 flex-col">
                  <span class="text-sm font-semibold text-gray-500"
                    >Ready to publish</span
                  >
                  <span class="text-xs text-gray-500"
                    >Waiting to publish...</span
                  >
                </span>
              </a>
            </li>
            <li class="relative" v-if="journal_item.journalstatus.id == 3">
              <a href="#" class="group relative flex items-start">
                <span class="flex h-9 items-center" aria-hidden="true">
                  <span
                    class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-105005 bg-white"
                  >
                    <span
                      class="h-2.5 w-2.5 animate-pulse rounded-full bg-105005"
                    ></span>
                  </span>
                </span>
                <span class="ml-4 flex min-w-0 flex-col">
                  <span class="text-sm font-semibold text-gray-500"
                    >Reapprove</span
                  >
                  <span class="text-xs text-gray-500"
                    >You will reapprove this soon.</span
                  >
                </span>
              </a>
            </li>
          </ol>
          <ol role="list" class="overflow-hidden">
            <li class="relative mt-5 sm:mt-5 md:-mt-1">
              <h5 class="font-semibold text-md text-gray-700">Remarks</h5>
              <div v-if="remarks.length != 0">
                <div
                  class="text-sm text-justify"
                  v-for="item in remarks"
                  :key="item"
                >
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4 inline text-purple-800 mr-2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                      />
                    </svg>

                    <span class="text-justify text-gray-700">{{
                      item.msg
                    }}</span>
                    -
                    <span
                      style="font-size: 10px"
                      class="font-semibold text-gray-500"
                      >{{ stringFormatDate(formatDate(item.createdAt)) }}</span
                    >
                  </div>
                </div>
              </div>
              <div v-if="remarks.length == 0" class="text-xs text-gray-500">
                No remarks found.
              </div>
            </li>
          </ol>
        </nav>
      </div>

      <FileViewer
        v-if="isViewFile"
        :filename="viewFileInfo"
        :filelink="viewFileLink"
      />
    </div>

    <!-- modals -->
    <!-- Modal for verification -->
    <ApproveDecisionModal
      :journaldoc="journaldoc"
      v-if="toggleApproveModal"
      v-on:closeModal="toggleApproveDecisionModal"
      v-on:approvedWithoutRemarks="Approved"
      v-on:isUpdatedDataVerify="isUpdatedDataNowVerify"
    />
    <!-- Modal for dean/program chair level -->

    <ApproveEventDecisionModal
      :journaldoc="journaldoc"
      v-if="toggleApproveEventModal"
      v-on:closeModal="toggleApproveEventDecisionModal"
      v-on:approved="Approved"
      v-on:isUpdatedData="isUpdatedDataNow"
    />

    <!-- This modal for publication, Librarian Level -->
    <ApproveDecisionModal
      :journaldoc="journaldoc"
      v-if="toggleApproveModal2"
      v-on:closeModal="toggleApproveDecisionModal2"
      v-on:approved="Approved2"
      v-on:isUpdatedData="isUpdatedDataNowPublish"
    />
    <!-- This modal for publication, Librarian Level -->

    <PendingDecisionModal
      :journaldoc="journaldoc"
      v-if="togglePendingModal"
      v-on:closeModal="togglePendingModal = !togglePendingModal"
      v-on:pending="Pending"
      v-on:isUpdatedData="isUpdatedDataNowPending"
    />

    <UpdateJournalDetailsModal
      :journaldoc="journaldoc"
      v-if="toggleUpdateJournalDetailsModal"
      v-on:closeModal="
        toggleUpdateJournalDetailsModal = !toggleUpdateJournalDetailsModal
      "
      v-on:updated="Updated"
    />

    <EndorseDecisionModal
      :journaldoc="journaldoc"
      v-if="toggleEndorseModal"
      v-on:closeModal="toggleEndorseDecisionModal"
      v-on:endorsed="Endorsed"
      v-on:isUpdatedData="isUpdatedDataNow"
    />

    <MessageModal
      :decision="message_decision"
      v-if="isMessageModal"
      v-on:closeModal="closeMessageModal"
    />
  </div>
</template>

<script>
import ApproveEventDecisionModal from "../modals/ApproveEventDecisionModal.vue";
import FileViewer from "../components/FileViewer.vue";
import moment from "moment";
import ApproveDecisionModal from "../modals/ApproveDecisionModal.vue";
// import ApproveDecisionModal2 from "../modals/ApproveDecisionModal2.vue";
import MessageModal from "../modals/MessageModal.vue";
import PendingDecisionModal from "../modals/PendingDecisionModal.vue";
import UpdateJournalDetailsModal from "../modals/UpdateJournalDetailsModal.vue";
import EndorseDecisionModal from "../modals/EndorseDecisionModal.vue";
import { useToast } from "vue-toastification";
import axios from "axios";
export default {
  name: "ApprovalView",
  data() {
    return {
      remarksPended: null,
      file: [],
      remarks: [],
      isUpdatedData: false,
      isViewFile: false,
      isBtnActive: false,
      viewFileInfo: "",
      viewFileLink: "",
      toggleApproveModal: false,
      toggleApproveModal2: false,
      isEventApproved: false,
      togglePendingModal: false,
      toggleUpdateJournalDetailsModal: false,
      toggleApproveEventModal: false,
      toggleEndorseModal: false,
      journaldoc: null,
      journal_decision: null,
      message_decision: null,
      isMessageModal: false,
      eventName: null,
      isLoading: false,
      eventID: null,
      eventTaggedID: null,
      eventName2: null,
      isApproved2: null,
      eventID2: null,
      eventTaggedID2: null,
      isExist: false,
      isExist2: false,
      isFinallyApproved: false,
      toggleBreadcrumbs: false,
      eventLength: null,
      coauthors: "",
    };
  },
  props: ["journal_item"],
  components: {
    FileViewer,
    ApproveDecisionModal,
    PendingDecisionModal,
    EndorseDecisionModal,
    MessageModal,
    UpdateJournalDetailsModal,
    ApproveEventDecisionModal,
  },
  created() {
    this.moment = moment;
    this.isUpdatedData = false;

    // this.getEventTagged();
  },
  mounted() {
    // console.log(this.$route.params.userid);
    // this.notifID = this.$route.params.userid;
    this.isUpdatedData = false;
    this.getRemarks();
    this.getEventTagged();
    this.getEventTagged2();
    this.isLoading = true;
    setTimeout(function () {
      this.isLoading = false;
    }, 3000);
  },
  methods: {
    formatYear(date) {
      const d = new Date(date);
      var dd = d.getDate();
      if (dd < 10) {
        dd = "0" + dd;
      }
      var mm = d.getMonth() + 1;
      if (mm < 10) {
        mm = "0" + mm;
      }
      var yy = d.getFullYear();
      // return mm + "-" + dd + "-" + yy;
      return yy;
    },
    toggleBtn() {
      this.isBtnActive = this.isBtnActive ? false : true;
      var msg1 =
        "You have approved the submission of " + this.journal_item.title;
      var msg2 = "You have undo the approval";
      if (this.isBtnActive) {
        const toast = useToast();
        toast.success(msg1, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } else {
        const toast = useToast();
        toast.warning(msg2, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    getEventTagged() {
      // console.log(this.journal_item.id);
      axios
        .get(
          process.env.VUE_APP_API_SERVER +
            "/journalEvents/getEventTagged/" +
            this.journal_item.id,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            if (response.data.msgID == 1) {
              this.eventTaggedID = response.data.getEventDatas.id;
              this.eventID = response.data.getEventDatas.eventID;
              this.eventName =
                response.data.getEventDatas.eventDetails.eventName;
              this.eventLength = response.data.getEventDatas.length;
              this.isExist = true;
            } else if (response.data.msgID == 0) {
              this.isExist = false;
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
    getEventTagged2() {
      axios
        .get(
          process.env.VUE_APP_API_SERVER +
            "/journalEvents/getEventTagged2/" +
            this.journal_item.id,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            if (response.data.msgID == 1) {
              this.eventTaggedID2 = response.data.getEventDatas.id;
              this.eventID2 = response.data.getEventDatas.eventID;
              this.eventName2 =
                response.data.getEventDatas.eventDetails.eventName;
              this.eventLength = response.data.getEventDatas.length;
              this.isApproved2 = response.data.getEventDatas.isApproved;
              this.isExist2 = true;
            } else if (response.data.msgID == 0) {
              this.isExist2 = false;
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
    getRemarks() {
      var fileID = this.journal_item.id;
      axios
        .get(process.env.VUE_APP_API_SERVER + "/remarks/" + fileID, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            this.remarks = response.data;
          },
          (error) => {
            console.log(error);
          }
        );
    },
    isUpdatedDataNowVerify() {
      // alert('triggered');
      this.$emit("updateData1Nowverify");
      // this.back();
    },
    isUpdatedDataNowPublish() {
      this.$emit("updateData1NowPublication");
    },
    isUpdatedDataNowPending() {
      this.$emit("updateData1NowPublication");
    },
    back() {
      this.$emit("back");
    },
    goBack() {
      this.isViewFile = !this.isViewFile;
      this.toggleBreadcrumbs = false;
    },
    // getPendedRemark() { //   var pDate = this.journal_item.pendingDate; //   const d = new Date(pDate); //   var dd = d.getDate(); //   if (dd < 10) { //     dd = "0" + dd; //   } //   var mm = d.getMonth() + 1; //   if (mm < 10) { //     mm = "0" + mm; //   } //   var yy = d.getFullYear(); //   var pDate2 = mm + "-" + dd + "-" + yy; //   console.log(pDate2); //   for (var i = 0; this.remarks.length; i++){ //     if (pDate2 == this.remarks[i].createdAt) { //       this.remarksPended = this.remarks[i].msg; //       console.log(this.remarksPended); //     } //   } // },

    formatDate(date) {
      const d = new Date(date);
      var dd = d.getDate();
      if (dd < 10) {
        dd = "0" + dd;
      }
      var mm = d.getMonth() + 1;
      if (mm < 10) {
        mm = "0" + mm;
      }
      var yy = d.getFullYear();
      return mm + "-" + dd + "-" + yy;
    },
    stringFormatDate(date) {
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(date).toLocaleDateString("en", options);
    },
    getMonthName(mnth) {
      var mL = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return mL[mnth - 1];
    },
    // to view the file uploaded
    redirectTo(file, link) {
      if (file) {
        this.toggleBreadcrumbs = true;
        this.viewFileInfo = file;
        this.isViewFile = true;
      } else {
        this.toggleBreadcrumbs = true;
        this.viewFileLink = link;
        this.isViewFile = true;
      }
    },
    // open approval modal
    toggleApproveDecisionModal(item) {
      var arr = {
        eventTaggedID: this.eventTaggedID,
        eventID: this.eventID,
        isApprove: this.isBtnActive,
      };
      const items = [].concat(arr, item);
      this.journaldoc = items;
      this.toggleApproveModal = !this.toggleApproveModal;
      // console.log(item);
    },

    toggleApproveDecisionModal2(item) {
      var arr = {
        eventTaggedID: this.eventTaggedID,
        eventID: this.eventID,
        isApprove: this.isBtnActive,
      };
      const items = [].concat(arr, item);
      this.journaldoc = items;
      // console.log(this.journaldoc);
      // item.push(this.eventName);

      // console.log(item);
      this.toggleApproveModal2 = !this.toggleApproveModal2;
    },

    togglePendingDecisionModal1(item) {
      this.journaldoc = item;
      this.togglePendingModal = !this.togglePendingModal;
    },

    togglePendingDecisionModal2(item) {
      this.journaldoc = item;
      this.togglePendingModal = !this.togglePendingModal;
    },

    toggleEndorseDecisionModal(item) {
      this.journaldoc = item;
      this.toggleEndorseModal = !this.toggleEndorseModal;
    },

    toggleUpdateJournalDetailsDecisionModal(item) {
      this.journaldoc = item;
      // console.log(item);
      this.toggleUpdateJournalDetailsModal =
        !this.toggleUpdateJournalDetailsModal;
    },

    Approved() {
      // console.log("approvedByDean");
      this.toggleMessageModal("approvedbyDean");
    },
    Approved2() {
      this.toggleMessageModal("approvedbyLib");
    },
    Pending() {
      this.toggleMessageModal("pending");
    },
    Endorsed() {
      this.toggleMessageModal("endorsed");
    },
    Updated() {
      this.toggleMessageModal("updated");
    },

    // open message modal
    toggleMessageModal(decision) {
      this.message_decision = decision;
      this.toggleApproveModal = false;
      this.toggleApproveModal2 = false;
      this.togglePendingModal = false;
      this.toggleEndorseModal = false;
      this.toggleUpdateJournalDetailsModal = false;
      this.isMessageModal = true;
      // setTimeout(() => {
      //   this.isMessageModal = false;
      //   // this.back();
      //   this.isUpdatedDataNowVerify();
      // }, 1000);
    },

    // close the message modal
    closeMessageModal() {
      this.isMessageModal = false;
      if (!this.isMessageModal) {
        this.back();
      } else {
        this.isMessageModal = false;
      }
      // this.back();
      // this.back();
    },
  },

  watch: {
    journal_item: {
      handler(val) {
        this.toogleBreadcrumbs = false;
        // console.log(val);

        const parsedCoAuthors = JSON.parse(val.coauthors);
        if (val.coauthors != null) {
          for (let i = 0; i < parsedCoAuthors.length; i++) {
            const element = parsedCoAuthors[i];
            if (element.mname) {
              this.coauthors +=
                element.lname +
                ", " +
                element.fname +
                " " +
                element.mname[0] +
                "; ";
            } else {
              this.coauthors += element.lname + ", " + element.fname + "; ";
            }
          }

          // val.coauthors.forEach((element) => {
          //   if (element.mname) {
          //     this.coauthors +=
          //       element.lname +
          //       ", " +
          //       element.fname +
          //       " " +
          //       element.mname[0] +
          //       "; ";
          //   } else {
          //     this.coauthors += element.lname + ", " + element.fname + "; ";
          //   }
          // });
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.intro_p {
  /* border: 1px solid black; */
  height: 100px;
  opacity: 0.3;
}
</style>
