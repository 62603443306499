<template>
  <div class="w-auto h-auto mx-3 rounded-md">
    <div
      class="mx-1 px-4 py-3 bg-white dark:bg-darkBG border dark:border-gray-600 rounded"
    >
      <div class="grid grid-rows-2 md:flex md:justify-between">
        <div
          class="flex md:flex relative md:no-underline md:w-full text-black gap-1"
          href="javascript:void(0)"
        >
          <input
            type="text"
            class="font-poppins indent py-1 w-1/3 block text-xs font-normal text-gray-700 bg-gray-100 dark:bg-darkBG dark:text-gray-100 border border-solid border-gray-300 rounded md:rounded-md lg:rounded-md transition ease-in-out m-0 focus:text-gray-700 focus:bg-darkBG mr-0.5 focus:border-green-600 focus:outline-none"
            placeholder="Search for anything"
            v-model="searchText"
            @keyup="handleSearching()"
          />
          <button
            @click="autoDisplayJournals"
            title="Refresh Table"
            class="border border-gray-200 px-2 dark:bg-darkBG rounded indent"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4 text-gray-400 dark:text-gray-500"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            </svg>
          </button>

          <button
            class="font-medium rounded text-sm px-5 text-center inline-flex items-center border border-blue-300 dark:border-blue-400 dark:bg-darkBG"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-4 h-4 text-blue-400 dark:text-blue-400"
            >
              <path
                fill-rule="evenodd"
                d="M3.792 2.938A49.069 49.069 0 0112 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 011.541 1.836v1.044a3 3 0 01-.879 2.121l-6.182 6.182a1.5 1.5 0 00-.439 1.061v2.927a3 3 0 01-1.658 2.684l-1.757.878A.75.75 0 019.75 21v-5.818a1.5 1.5 0 00-.44-1.06L3.13 7.938a3 3 0 01-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836z"
                clip-rule="evenodd"
              />
            </svg>
            <!-- <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-three-dots"
              viewBox="0 0 16 16"
            >
              <path
                d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
              />
            </svg> -->
          </button>
          <ul
            class="dropdown-menu hidden bg-white z-10 divide-y divide-gray-100 rounded-lg shadow w-48 dark:bg-darkBG border py-2 text-sm text-gray-700 dark:text-gray-500"
            aria-labelledby="dropdownMenuButton1"
          >
            <li class="pr-5">
              <select
                class="p-1.5 w-full mx-2 mb-2 border border-solid border-gray-300 rounded dark:bg-gray-200"
                @change="changeField($event)"
                v-model="tableForFilter"
              >
                <option
                  v-for="item in table_filter"
                  :key="item"
                  :value="item.field"
                >
                  {{ item.tablename }}
                </option>
              </select>
              <select
                v-if="showTableForFilterVal"
                id="tableForFilterVal"
                @change="changeField2($event)"
                class="p-1.5 w-full mx-2 mb-2 border border-solid border-gray-300 rounded dark:bg-gray-200"
                v-model="tableForFilterVal"
              >
                <option
                  v-for="item in filterOption"
                  :key="item"
                  :value="item.val"
                >
                  {{ item.option }}
                </option>
              </select>
              <div id="inputFieldDiv" class="w-full">
                <!-- FOR RESEARCH TITLE -->
                <div v-if="showFilter">
                  <input
                    v-model="filterText"
                    type="text"
                    class="p-1.5 w-full mx-2 border border-solid border-gray-300 rounded dark:bg-gray-200"
                    placeholder="Filter..."
                  />
                </div>
                <!-- FOR ISPUBLISHED -->
                <div v-if="showFilterIsPub">
                  <input
                    type="checkbox"
                    id="unpublished"
                    value="Unpublished"
                    class="p-1.5 mx-2 mb-2.5"
                    v-model="filterIsPublished"
                  />
                  <label class="dark:text-gray-200" for="unpublished"
                    >Unpublished</label
                  >
                  <br />
                  <input
                    type="checkbox"
                    id="published"
                    value="Published"
                    class="p-1.5 mx-2 mb-2.5"
                    v-model="filterIsPublished"
                  />
                  <label class="dark:text-gray-200" for="published"
                    >Published</label
                  >
                </div>
                <!-- FOR DATE PUBLISHED -->
                <div v-if="showFilterDate1">
                  <input
                    v-model="filterDate1"
                    type="date"
                    class="p-1.5 w-full mx-2 border border-solid border-gray-300 rounded dark:bg-gray-200"
                  />
                </div>
                <div v-if="showFilterDate2">
                  <span class="p-1.5 mb-2 mx-2 text-xs">From</span>
                  <input
                    v-model="filterDate1"
                    type="date"
                    class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded dark:bg-gray-200"
                  />
                  <span class="p-1.5 mb-2 mx-2 text-xs">To</span>
                  <input
                    v-model="filterDate2"
                    type="date"
                    class="p-1.5 w-full mx-2 border border-solid border-gray-300 rounded dark:bg-gray-200"
                  />
                </div>
                <!-- FOR CORRESPONDING AUTHOR, CO AUTHOR, MAIN AUTHOR -->
                <div v-if="showFilterString">
                  <input
                    v-model="filterFname"
                    type="text"
                    class="p-1.5 w-full mx-2 mb-2 border border-solid border-gray-300 rounded dark:bg-gray-200"
                    placeholder="First Name"
                  />
                  <input
                    v-model="filterMname"
                    type="text"
                    class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded dark:bg-gray-200"
                    placeholder="Middle Name"
                  />
                  <input
                    v-model="filterLname"
                    type="text"
                    class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded dark:bg-gray-200"
                    placeholder="Last Name"
                  />
                  <input
                    v-model="filterEmail"
                    type="text"
                    class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded dark:bg-gray-200"
                    placeholder="Email"
                  />
                  <input
                    v-model="filterOrg"
                    type="text"
                    class="p-1.5 w-full mx-2 border border-solid border-gray-300 rounded dark:bg-gray-200"
                    placeholder="Organization"
                  />
                </div>
                <div v-if="showDropdownPublished">
                  <select
                    class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded dark:bg-gray-200"
                    v-model="filterPublishedYear"
                  >
                    <option
                      v-for="yr in publishedYearList"
                      :value="yr"
                      :key="yr"
                    >
                      {{ yr }}
                    </option>
                  </select>
                  <select
                    class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded dark:bg-gray-200"
                    v-model="filterPublishedMonth"
                  >
                    <option
                      v-for="mm in publishedMonthList"
                      selected
                      :value="mm.value"
                      :key="mm"
                    >
                      {{ mm.month }}
                    </option>
                  </select>
                </div>
                <div
                  v-if="showDropdownPublishedRange"
                  class="grid grid-cols-2 grid-rows-1 w-full gap-2"
                >
                  <div>
                    <span class="p-1.5 mb-2 mx-2 text-xs">From</span>
                    <select
                      class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded dark:bg-gray-200"
                      v-model="filterPublishedYear1"
                    >
                      <option
                        v-for="yr in publishedYearList"
                        :value="yr"
                        :key="yr"
                      >
                        {{ yr }}
                      </option>
                    </select>
                    <select
                      class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded dark:bg-gray-200"
                      v-model="filterPublishedMonth1"
                    >
                      <option
                        v-for="mm in publishedMonthList"
                        selected
                        :value="mm.value"
                        :key="mm"
                      >
                        {{ mm.month }}
                      </option>
                    </select>
                  </div>
                  <div>
                    <span class="p-1.5 mb-2 mx-2 text-xs">To</span>
                    <select
                      class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded dark:bg-gray-200"
                      v-model="filterPublishedYear2"
                    >
                      <option
                        v-for="yr in publishedYearList"
                        :value="yr"
                        :key="yr"
                      >
                        {{ yr }}
                      </option>
                    </select>
                    <select
                      class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded dark:bg-gray-200"
                      v-model="filterPublishedMonth2"
                    >
                      <option
                        v-for="mm in publishedMonthList"
                        selected
                        :value="mm.value"
                        :key="mm"
                      >
                        {{ mm.month }}
                      </option>
                    </select>
                  </div>
                </div>
                <div v-if="showCategoriesList">
                  <select
                    v-model="filterCategory"
                    class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded dark:bg-gray-200"
                  >
                    <option v-for="d in categoriesList" :key="d" :value="d.id">
                      {{ d.categoryDesc }}
                    </option>
                  </select>
                </div>
                <div v-if="showTypeOfArticle">
                  <select
                    v-model="filterTypeOfArticle"
                    class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded dark:bg-gray-200"
                  >
                    <option selected value="1">Thesis</option>
                    <option value="2">Capstone Project</option>
                    <option value="3">Dissertation</option>
                    <option value="4">Research</option>
                    <option value="5">Journal</option>
                    <option value="6">Others</option>
                  </select>
                </div>
              </div>

              <input
                type="button"
                value="DONE"
                @click="goFilter"
                data-bs-toggle="collapse"
                data-bs-target="#dropdown"
                class="p-1.5 w-full mx-2 mb-2 text-xs mt-2 bg-blue-500 text-white rounded"
              />
              <!-- <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</a> -->
            </li>
          </ul>
          <!-- <button
            id="dropdownDefaultButton"
            data-bs-toggle="collapse"
            data-bs-target="#dropdown"
            @click="filterDiv = !filterDiv"
            class="text-gray-300 bg-white border border-blue-300 focus:border-blue-600 focus:outline-none font-medium rounded text-sm px-2 indent text-center inline-flex items-center"
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-4 h-4 text-blue-400"
            >
              <path
                fill-rule="evenodd"
                d="M3.792 2.938A49.069 49.069 0 0112 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 011.541 1.836v1.044a3 3 0 01-.879 2.121l-6.182 6.182a1.5 1.5 0 00-.439 1.061v2.927a3 3 0 01-1.658 2.684l-1.757.878A.75.75 0 019.75 21v-5.818a1.5 1.5 0 00-.44-1.06L3.13 7.938a3 3 0 01-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836z"
                clip-rule="evenodd"
              />
            </svg>
            <svg
              class="w-2.5 h-2.5 ml-2.5"
              :class="
                filterDiv
                  ? 'transform rotate-180 duration-150 text-blue-500'
                  : 'transform rotate-0 duration-150 '
              "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </button> -->
          <div
            class="visible md:pl-0 md:invisible lg:invisible xl:invisible 2xl:invisible"
          >
            <!-- SEE SAVED JOURNAL -->
            <button
              v-if="seeSaved"
              @click="toggleThis()"
              title="Close Saved Articles"
              class="bg-105005 px-2 py-2 text-white dark:bg-gray-400 text-xs rounded"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-4 h-4 inline"
              >
                <path
                  d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM20.25 5.507v11.561L5.853 2.671c.15-.043.306-.075.467-.094a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93zM3.75 21V6.932l14.063 14.063L12 18.088l-7.165 3.583A.75.75 0 013.75 21z"
                />
              </svg>
            </button>
            <!-- SEE JOURNALS -->
            <button
              v-if="!seeSaved"
              @click="toggleThis2()"
              title="Open Saved Articles"
              class="border border-customGreen bg-white dark:bg-gray-400 px-2 py-2 text-xs rounded"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-4 h-4 inline text-105005"
              >
                <path
                  fill-rule="evenodd"
                  d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
          <!-- <div
            id="dropdown"
            class="bg-white collapse absolute md:top-0 top-10 ml-2 left-32 md:left-72 text-gray-500 divide-y-2 divide-blue-300 rounded-lg shadow shadow-gray-500 w-48 dark:bg-gray-700"
          >
            <div
              class="text-center text-gray-200 p-2 grid place-content-center"
              data-bs-toggle="collapse"
              data-bs-target="#dropdown"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-5 h-5 text-blue-400"
              >
                <path
                  fill-rule="evenodd"
                  d="M3.792 2.938A49.069 49.069 0 0112 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 011.541 1.836v1.044a3 3 0 01-.879 2.121l-6.182 6.182a1.5 1.5 0 00-.439 1.061v2.927a3 3 0 01-1.658 2.684l-1.757.878A.75.75 0 019.75 21v-5.818a1.5 1.5 0 00-.44-1.06L3.13 7.938a3 3 0 01-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <ul
              class="py-2 text-xs text-gray-700 dark:text-gray-200"
              aria-labelledby="dropdownDefaultButton"
            >
              <li>
                <select
                  class="p-1.5 w-44 mx-2 mb-2 border border-solid border-gray-300 rounded"
                  @change="changeField($event)"
                  v-model="tableForFilter"
                >
                  <option
                    v-for="item in table_filter"
                    :key="item"
                    :value="item.field"
                  >
                    {{ item.tablename }}
                  </option>
                </select>
                <select
                  v-if="showTableForFilterVal"
                  id="tableForFilterVal"
                  @change="changeField2($event)"
                  class="p-1.5 w-44 mx-2 mb-2 border border-solid border-gray-300 rounded"
                  v-model="tableForFilterVal"
                >
                  <option
                    v-for="item in filterOption"
                    :key="item"
                    :value="item.val"
                  >
                    {{ item.option }}
                  </option>
                </select>
                <div id="inputFieldDiv" class="w-full">
                  <div v-if="showFilter">
                    <input
                      v-model="filterText"
                      type="text"
                      class="p-1.5 w-44 mx-2 border border-solid border-gray-300 rounded"
                      placeholder="Filter..."
                    />
                  </div>
                  <div v-if="showFilterIsPub">
                    <input
                      type="checkbox"
                      id="unpublished"
                      value="Unpublished"
                      class="p-1.5 mx-2 mb-2.5"
                      v-model="filterIsPublished"
                    />
                    <label for="unpublished">Unpublished</label> <br />
                    <input
                      type="checkbox"
                      id="published"
                      value="Published"
                      class="p-1.5 mx-2 mb-2.5"
                      v-model="filterIsPublished"
                    />
                    <label for="published">Published</label>
                  </div>
                  <div v-if="showFilterDate1">
                    <input
                      v-model="filterDate1"
                      type="date"
                      class="p-1.5 w-44 mx-2 border border-solid border-gray-300 rounded"
                    />
                  </div>
                  <div v-if="showFilterDate2">
                    <span class="p-1.5 mb-2 mx-2 text-xs">From</span>
                    <input
                      v-model="filterDate1"
                      type="date"
                      class="p-1.5 w-44 mb-2 mx-2 border border-solid border-gray-300 rounded"
                    />
                    <span class="p-1.5 mb-2 mx-2 text-xs">To</span>
                    <input
                      v-model="filterDate2"
                      type="date"
                      class="p-1.5 w-44 mx-2 border border-solid border-gray-300 rounded"
                    />
                  </div>
                  <div v-if="showFilterString">
                    <input
                      v-model="filterFname"
                      type="text"
                      class="p-1.5 w-44 mx-2 mb-2 border border-solid border-gray-300 rounded"
                      placeholder="First Name"
                    />
                    <input
                      v-model="filterMname"
                      type="text"
                      class="p-1.5 w-44 mb-2 mx-2 border border-solid border-gray-300 rounded"
                      placeholder="Middle Name"
                    />
                    <input
                      v-model="filterLname"
                      type="text"
                      class="p-1.5 w-44 mb-2 mx-2 border border-solid border-gray-300 rounded"
                      placeholder="Last Name"
                    />
                    <input
                      v-model="filterEmail"
                      type="text"
                      class="p-1.5 w-44 mb-2 mx-2 border border-solid border-gray-300 rounded"
                      placeholder="Email"
                    />
                    <input
                      v-model="filterOrg"
                      type="text"
                      class="p-1.5 w-44 mx-2 border border-solid border-gray-300 rounded"
                      placeholder="Organization"
                    />
                  </div>
                  <div v-if="showDropdownPublished">
                    <select
                      class="p-1.5 w-44 mb-2 mx-2 border border-solid border-gray-300 rounded"
                      v-model="filterPublishedYear"
                    >
                      <option
                        v-for="yr in publishedYearList"
                        :value="yr"
                        :key="yr"
                      >
                        {{ yr }}
                      </option>
                    </select>
                    <select
                      class="p-1.5 w-44 mb-2 mx-2 border border-solid border-gray-300 rounded"
                      v-model="filterPublishedMonth"
                    >
                      <option
                        v-for="mm in publishedMonthList"
                        selected
                        :value="mm.value"
                        :key="mm"
                      >
                        {{ mm.month }}
                      </option>
                    </select>
                  </div>
                  <div
                    v-if="showDropdownPublishedRange"
                    class="grid grid-cols-2 grid-rows-1 w-44 gap-2"
                  >
                    <div>
                      <span class="p-1.5 mb-2 mx-2 text-xs">From</span>
                      <select
                        class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded"
                        v-model="filterPublishedYear1"
                      >
                        <option
                          v-for="yr in publishedYearList"
                          :value="yr"
                          :key="yr"
                        >
                          {{ yr }}
                        </option>
                      </select>
                      <select
                        class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded"
                        v-model="filterPublishedMonth1"
                      >
                        <option
                          v-for="mm in publishedMonthList"
                          selected
                          :value="mm.value"
                          :key="mm"
                        >
                          {{ mm.month }}
                        </option>
                      </select>
                    </div>
                    <div>
                      <span class="p-1.5 mb-2 mx-2 text-xs">To</span>
                      <select
                        class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded"
                        v-model="filterPublishedYear2"
                      >
                        <option
                          v-for="yr in publishedYearList"
                          :value="yr"
                          :key="yr"
                        >
                          {{ yr }}
                        </option>
                      </select>
                      <select
                        class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded"
                        v-model="filterPublishedMonth2"
                      >
                        <option
                          v-for="mm in publishedMonthList"
                          selected
                          :value="mm.value"
                          :key="mm"
                        >
                          {{ mm.month }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div v-if="showCategoriesList">
                    <select
                      v-model="filterCategory"
                      class="p-1.5 w-44 mb-2 mx-2 border border-solid border-gray-300 rounded"
                    >
                      <option
                        v-for="d in categoriesList"
                        :key="d"
                        :value="d.id"
                      >
                        {{ d.categoryDesc }}
                      </option>
                    </select>
                  </div>
                  <div v-if="showTypeOfArticle">
                    <select
                      v-model="filterTypeOfArticle"
                      class="p-1.5 w-44 mb-2 mx-2 border border-solid border-gray-300 rounded"
                    >
                      <option selected value="1">Thesis</option>
                      <option value="2">Capstone Project</option>
                      <option value="3">Dissertation</option>
                      <option value="4">Research</option>
                      <option value="5">Journal</option>
                      <option value="6">Others</option>
                    </select>
                  </div>
                </div>

                <input
                  type="button"
                  value="DONE"
                  @click="goFilter"
                  data-bs-toggle="collapse"
                  data-bs-target="#dropdown"
                  class="p-1.5 w-20 float-right mx-2 mb-2 text-xs mt-2 bg-blue-500 text-white rounded"
                />
                <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</a>
              </li>
              <li> <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Settings</a> </li> <li> <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Earnings</a> </li> <li> <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Sign out</a> </li>
            </ul>
          </div> -->
        </div>

        <div class="invisible md:visible lg:visible xl:visible 2xl:visible">
          <!-- SEE SAVED JOURNAL -->
          <button
            v-if="seeSaved"
            @click="toggleThis()"
            title="Close Saved Articles"
            class="bg-105005 px-2 py-2 text-white dark:bg-darkBG text-xs rounded"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-4 h-4 inline"
            >
              <path
                d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM20.25 5.507v11.561L5.853 2.671c.15-.043.306-.075.467-.094a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93zM3.75 21V6.932l14.063 14.063L12 18.088l-7.165 3.583A.75.75 0 013.75 21z"
              />
            </svg>
            <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 inline"> <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" /> </svg> -->
          </button>
          <!-- SEE JOURNALS -->
          <button
            v-if="!seeSaved"
            @click="toggleThis2()"
            title="Open Saved Articles"
            class="border border-customGreen dark:border-lighterBg bg-white dark:bg-darkBG px-2 py-2 text-xs rounded"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-4 h-4 inline text-105005 dark:text-lighterBg"
            >
              <path
                fill-rule="evenodd"
                d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z"
                clip-rule="evenodd"
              />
            </svg>
            <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 inline"> <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" /> <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" /> </svg> -->
          </button>
        </div>
      </div>
    </div>
    <div
      class="mx-1 mt-5 bg-white dark:bg-darkBG border mb-20 border-white dark:border-gray-700 py-3 rounded"
    >
      <div
        class="px-4 py-3 -mt-2 flex justify-between text-gray-400 dark:text-gray-500 text-xs"
      >
        <div class="">
          About {{ displayedJournals.length }} results ({{ duration }} sec)
        </div>
        <div class="">
          {{ date_now }}
        </div>
      </div>
      <div class="mx-5 my-5" v-if="displayedJournals.length <= 0 && !isLoading">
        <div class="grid grid-rows-1 gap-2 leading-8">
          <div class="row-span-1 mb-2 text-sm text-gray-400 text-center">
            No results.
          </div>
        </div>
      </div>
      <div v-if="isLoading" class="flex justify-center py-8">
        <div class="spinLoader"></div>
      </div>
      <div class="mx-5 mb-2" v-if="!isLoading">
        <div class="grid grid-rows-1">
          <!-- THIS VIEW IS FOR AVAILABLE ARTICLES -->
          <div v-if="!seeSaved" class="h-full">
            <div
              class="row-span-1 mt-2 mb-10 md:my-2 leading-6 md:leading-8 h-auto py-3 px-3 hover:bg-gray-100 rounded"
              v-for="(journalData, index) in displayedJournals"
              :key="journalData"
            >
              <a
                href="#"
                class="hover:underline text-blue-700 text-md md:text-xl font-semibold"
                @click="viewJournal(journalData)"
                >{{ journalData.title }}</a
              >
              <div class="w-full text-green-600 text-sm">
                <span class="text-yellow-500 dark:text-yellow-600">{{
                  journalData.mainauthorfname != null &&
                  journalData.mainauthorlname != null
                    ? journalData.mainauthorfname +
                      " " +
                      journalData.mainauthorlname
                    : ""
                }}</span>
                <span class="px-1" v-if="journalData.typeOfArticle">•</span>
                <span>{{
                  journalData.typeOfArticle == 1
                    ? " Thesis"
                    : journalData.typeOfArticle == 2
                    ? " Capstone Project"
                    : journalData.typeOfArticle == 3
                    ? " Dissertation"
                    : journalData.typeOfArticle == 4
                    ? " Research"
                    : journalData.typeOfArticle == 5
                    ? " Journal"
                    : journalData.typeOfArticle == 6
                    ? " Others"
                    : ""
                }}</span>
                <span class="px-1">•</span>
                <span>{{
                  journalData.isPublished == 0
                    ? " Unpublished"
                    : journalData.isPublished == 1
                    ? " Published"
                    : ""
                }}</span>

                <span class="px-1" v-if="journalData.publishedDateYear">•</span>
                <span>{{
                  journalData.publishedDateYear != null
                    ? journalData.publishedDateYear
                    : ""
                }}</span>
                <span class="px-1" v-if="journalData.publisher">•</span>
                <span>{{
                  journalData.publisher != null ? journalData.publisher : ""
                }}</span>
                <!-- <span v-if="journalData.userID != null" class="px-1">•</span>
                <span
                  class="text-yellow-500"
                  v-if="journalData.userID != null"
                  >{{ journalData.user.email }}</span
                > -->
              </div>
              <div class="text-sm text">
                <p
                  class="truncate-overflow text-justify md:text-left dark:text-gray-400 captionText"
                  :id="'abstract_' + index"
                >
                  {{ journalData.abstract }}
                </p>
                <span
                  v-if="String(journalData.abstract).length > 50"
                  class="text-xs text-105005 dark:text-gray-600 hover:underline cursor-pointer"
                  :id="'seeMoreText' + index"
                  @click="seeMoreFunc(index)"
                  >Read more ...</span
                >
                <span
                  class="text-xs text-105005 dark:text-gray-600 hover:underline cursor-pointer hidden"
                  :id="'seeLessText' + index"
                  @click="seeLessFunc(index)"
                  >Read less ...</span
                >
                <!-- <p
                  style="font-size: 11px"
                  class="italic text-center text-xs md:text-left bg-gray-100 text-gray-500 p-2 my-1.5 rounded"
                  v-if="journalData.isPublished == 0"
                >
                  Unpublished articles' abstract is hidden due to privacy policy
                </p> -->
              </div>
              <div class="text-blue-700 flex text-sm mt-2">
                <div
                  class="cursor-pointer"
                  @click="save(journalData)"
                  v-if="journalData.journalsaved == null"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4 inline -mt-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                    />
                  </svg>
                  Save
                </div>
                <div
                  class="cursor-pointer"
                  @click="save(journalData)"
                  v-if="
                    journalData.journalsaved != null &&
                    journalData.journalsaved.userdetailID !=
                      this.$store.getters.getUserdetailID
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4 inline -mt-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                    />
                  </svg>
                  Save
                </div>
                <!-- <div class="cursor-pointer" @click="save(journalData)" v-if="journalData.journalsaved != null"> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline -mt-1"> <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" /> </svg> Save </div> -->
                <div
                  class="cursor-pointer"
                  @click="unsaved(journalData)"
                  v-if="
                    journalData.journalsaved != null &&
                    journalData.journalsaved.userdetailID ==
                      this.$store.getters.getUserdetailID
                  "
                >
                  <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 inline -mt-1"> <path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2. 424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z" clip-rule="evenodd" /> </svg> -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="w-4 h-4 inline -mt-1"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  Unsaved
                </div>
                <div class="px-2" v-if="journalData.isPublished == 1">•</div>
                <div
                  class="cursor-pointer"
                  v-if="journalData.isPublished == 1"
                  @click="cite(journalData)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4 inline -mt-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                    />
                  </svg>
                  Cite
                </div>
                <!-- <div class="px-2" v-if="journalData.journalcited != null">•</div>
                                <div v-if="journalData.journalcited != null">Cited by {{ journalData.journalcited != null ?
                                    journalData.journalcited.citedCount : '' }}</div> -->
              </div>
            </div>
          </div>
          <!-- THIS VIEW IS FOR SAVED ARTICLES -->
          <div v-if="seeSaved">
            <div
              class="row-span-1 mt-2 mb-10 md:my-6 leading-6 md:leading-8"
              v-for="(journalData, index) in displayedJournals"
              :key="journalData"
            >
              <span
                class="border border-white bg-yellow-500 rounded-full p-1 mr-1.5"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-4 h-4 inline text-white"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6 3a3 3 0 00-3 3v12a3 3 0 003 3h12a3 3 0 003-3V6a3 3 0 00-3-3H6zm1.5 1.5a.75.75 0 00-.75.75V16.5a.75.75 0 001.085.67L12 15.089l4.165 2.083a.75.75 0 001.085-.671V5.25a.75.75 0 00-.75-.75h-9z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
              <a
                href="#"
                class="hover:underline text-blue-700 text-md md:text-xl font-semibold"
                @click="viewJournal(journalData)"
                >{{ journalData.title }}</a
              >

              <div class="w-full text-green-600 text-sm">
                <span class="text-yellow-500">{{
                  journalData.mainauthorfname != null &&
                  journalData.mainauthorlname != null
                    ? journalData.mainauthorfname +
                      " " +
                      journalData.mainauthorlname
                    : ""
                }}</span>
                <span class="px-1" v-if="journalData.typeOfArticle">•</span>
                <span>{{
                  journalData.typeOfArticle == 1
                    ? " Thesis"
                    : journalData.typeOfArticle == 2
                    ? " Capstone Project"
                    : journalData.typeOfArticle == 3
                    ? " Dissertation"
                    : journalData.typeOfArticle == 4
                    ? " Research"
                    : journalData.typeOfArticle == 5
                    ? " Journal"
                    : journalData.typeOfArticle == 6
                    ? " Others"
                    : ""
                }}</span>
                <span class="px-1">•</span>
                <span>{{
                  journalData.isPublished == 0
                    ? " Unpublished"
                    : journalData.isPublished == 1
                    ? " Published"
                    : ""
                }}</span>
                <!-- <span class="px-1" v-if="journalData.publishedDateYear">•</span> <span>{{ journalData.file.publishedDateYear != null ? journalData.publishedDateYear : '' }}</span> -->
                <span class="px-1" v-if="journalData.publisher">•</span>
                <span>{{
                  journalData.publisher != null ? journalData.publisher : ""
                }}</span>
              </div>
              <div class="text-sm">
                <p
                  class="truncate-overflow text-justify md:text-left captionText"
                  :id="'abstract_' + index"
                >
                  {{ journalData.abstract }}
                </p>
                <span
                  class="text-xs text-105005 dark:text-gray-600 hover:underline cursor-pointer"
                  :id="'seeMoreText' + index"
                  @click="seeMoreFunc(index)"
                  >Read more ...</span
                >
                <span
                  class="text-xs text-105005 dark:text-gray-600 hover:underline cursor-pointer hidden"
                  :id="'seeLessText' + index"
                  @click="seeLessFunc(index)"
                  >Read less ...</span
                >
              </div>
              <div class="text-blue-700 flex text-sm mt-2">
                <div
                  class="cursor-pointer"
                  @click="save(journalData)"
                  v-if="journalData.journalsaved == null"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4 inline -mt-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                    />
                  </svg>
                  Save
                </div>
                <div
                  class="cursor-pointer"
                  @click="save(journalData)"
                  v-if="
                    journalData.journalsaved != null &&
                    journalData.journalsaved.userdetailID !=
                      this.$store.getters.getUserdetailID
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4 inline -mt-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                    />
                  </svg>
                  Save
                </div>
                <!-- <div class="cursor-pointer" @click="save(journalData)" v-if="journalData.journalsaved != null"> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline -mt-1"> <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" /> </svg> Save </div> -->
                <div
                  class="cursor-pointer"
                  @click="unsaved(journalData)"
                  v-if="
                    journalData.journalsaved != null &&
                    journalData.journalsaved.userdetailID ==
                      this.$store.getters.getUserdetailID
                  "
                >
                  <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 inline -mt-1"> <path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2. 424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z" clip-rule="evenodd" /> </svg> -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="w-4 h-4 inline -mt-1"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  Unsaved
                </div>
                <div
                  class="px-2"
                  v-if="
                    journalData.doi != null &&
                    journalData.doi != 'n/a' &&
                    journalData.doi != 'N/A'
                  "
                >
                  •
                </div>
                <div
                  class="cursor-pointer"
                  v-if="
                    journalData.doi != null &&
                    journalData.doi != 'n/a' &&
                    journalData.doi != 'N/A'
                  "
                  @click="cite(journalData)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4 inline -mt-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                    />
                  </svg>
                  Cite
                </div>
                <!-- <div class="px-2" v-if="journalData.journalcited != null">•</div> <div v-if="journalData.journalcited != null">Cited by {{ journalData.journalcited != null ? journalData.journalcited.citedCount : '' }}</div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mx-5 mb-2" v-if="!isLoading">
        <div
          v-if="displayedJournals"
          class="grid w-full sm:flex md:flex lg:flex flex-row justify-center md:w-full sm:w-full mx-auto items-center py-3"
        >
          <div class="w-full flex justify-center">
            <nav class="content-center">
              <ul class="flex md:justify-end">
                <li class="page-item mx-1">
                  <button
                    type="button"
                    @click="page = 1"
                    v-if="page > 1"
                    class="page-link p-2 text-md mx-0.5 text-gray-500 border rounded-md dark:border-gray-800 border-gray-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"
                      />
                    </svg>
                  </button>

                  <button
                    type="button"
                    disabled
                    v-if="page == 1"
                    class="page-link p-2 text-md mx-0.5 text-gray-300 border rounded-md dark:border-gray-400 dark:text-gray-400 border-gray-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"
                      />
                    </svg>
                  </button>
                </li>
                <li class="page-item mx-1">
                  <button
                    type="button"
                    class="page-link border border-gray-300 text-gray-600 dark:border-gray-400 dark:text-gray-400 p-2 text-xs rounded-md"
                    v-if="page > 1"
                    @click="page--"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>

                  <!--for the disabled button -->
                  <button
                    type="button"
                    class="page-link border border-gray-300 text-gray-300 dark:border-gray-400 dark:text-gray-400 p-2 text-xs rounded-md"
                    v-if="page == 1"
                    disabled
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </li>

                <li v-for="(item, index) in items" :key="index">
                  <button
                    type="button"
                    v-if="isNaN(Number(item))"
                    class="page-link p-2 text-md mx-0.5 text-gray-500 border rounded-md border-gray-300 dark:border-gray-400 dark:text-gray-400"
                  >
                    <p class="h-5 w-5">{{ item }}</p>
                  </button>
                  <!-- <span v-if="isNaN(Number(item))" class="pagination-more">{{ item }}</span> -->
                  <button
                    v-else-if="item == page"
                    type="button"
                    class="page-link p-2 text-md mx-0.5 border border-105005 rounded-md text-105005 dark:border-gray-400 dark:text-gray-600"
                  >
                    <p class="h-5 w-5 font-semibold dark:text-gray-200">
                      {{ item }}
                    </p>
                  </button>
                  <button
                    v-else
                    type="button"
                    @click="page = item"
                    class="page-link p-2 text-md mx-0.5 text-gray-500 border rounded-md border-gray-300 dark:border-gray-400 dark:text-gray-400"
                  >
                    <p class="h-5 w-5">{{ item }}</p>
                  </button>

                  <!-- <button v-else type="button"
                                        @click="$emit('input', item)">{{ item }}</button> -->
                </li>
                <!-- <li> -->

                <li class="page-item mx-1">
                  <button
                    type="button"
                    @click="page++"
                    v-if="page < pages.length"
                    class="page-link border border-gray-300 text-gray-600 p-2 text-xs rounded-md dark:border-gray-400 dark:text-gray-400"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>

                  <!--for the disabled button -->
                  <button
                    type="button"
                    disabled
                    v-if="page >= pages.length"
                    class="page-link border border-gray-300 dark:border-gray-400 dark:text-gray-400 text-gray-300 p-2 text-xs rounded-md"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </li>
                <li class="page-item mx-1">
                  <button
                    type="button"
                    @click="page = pages.length"
                    v-if="page < pages.length"
                    class="page-link p-2 text-md mx-0.5 text-gray-500 dark:border-gray-400 dark:text-gray-400 border rounded-md border-gray-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </button>

                  <button
                    type="button"
                    disabled
                    v-if="page >= pages.length"
                    class="page-link p-2 text-md mx-0.5 text-gray-300 dark:border-gray-400 dark:text-gray-400 border rounded-md border-gray-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <CitationModal
      v-if="isCitationModal"
      v-on:closeModal="closeCite"
      :isLogged="true"
      v-on:redirect="redirectTo"
      :citationData="citationData"
      v-on:refreshData="refreshData"
      :seeSaved="seeSaved"
    />
    <PleaseLoginModal
      v-if="isPleaseLoginModal"
      v-on:closeModal="closeSave"
      v-on:redirect="redirectTo"
    />
  </div>
</template>

<script>
import PleaseLoginModal from "../modals/PleaseLoginModal.vue";
import axios from "axios";
import moment from "moment";
import { useToast } from "vue-toastification";
import CitationModal from "../modals/CitationModal.vue";
export default {
  name: "JournalTable",
  components: { PleaseLoginModal, CitationModal },
  data() {
    return {
      isLoading: false,
      contents: [],
      page: 1,
      perPage: 10,
      selectPerPage: [10, 15, 20, 30, 50],
      pages: [],
      fromPage: 1,
      duration: null,
      toPage: null,
      results: null,
      duration: null,
      date_now: null,
      categories: [],
      categoryDesc: [],
      isPleaseLoginModal: false,
      isCitationModal: false,
      citationData: null,
      citedCount: null,
      seeSaved: false,
      filterDiv: false,
      showFilterIsPub: false,
      showFilterDate1: true,
      showFilterDate2: false,
      showFilter: false,
      showFilterString: false,
      showTableForFilterVal: true,
      showDropdownPublished: false,
      showDropdownPublishedRange: false,
      showCategoriesList: false,
      showTypeOfArticle: false,
      tableForFilterVal: "equal_date",
      tableForFilter: "file_createdAt",
      filterOption: [
        { option: "Equals", val: "equal_date" },
        { option: "Greater than", val: "greater_than_date" },
        { option: "Not equal", val: "not_equal_date" },
        { option: "In range", val: "in_range_date" },
      ],
      publishedYearList: [
        1955, 1956, 1957, 1958, 1959, 1960, 1961, 1962, 1963, 1964, 1965, 1966,
        1967, 1968, 1969, 1970, 1971, 1972, 1973, 1974, 1975, 1976, 1977, 1978,
        1979, 1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990,
        1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002,
        2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014,
        2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025,
      ],
      publishedMonthList: [
        { month: "January", value: 1 },
        { month: "February", value: 2 },
        { month: "March", value: 3 },
        { month: "April", value: 4 },
        { month: "May", value: 5 },
        { month: "June", value: 6 },
        { month: "July", value: 7 },
        { month: "August", value: 8 },
        { month: "September", value: 9 },
        { month: "October", value: 10 },
        { month: "November", value: 11 },
        { month: "December", value: 12 },
      ],
      categoriesList: [],
      filterDate1: null,
      filterDate2: null,
      filterIsPublished: [],
      filterText: null,
      filterFname: null,
      filterMname: null,
      filterLname: null,
      filterEmail: null,
      filterOrg: null,
      filterPublishedMonth: 1,
      filterPublishedYear: 1955,
      filterPublishedYear1: 1955,
      filterPublishedYear2: 2023,
      filterPublishedMonth1: 1,
      filterPublishedMonth2: 1,
      filterCategory: 1,
      filterTypeOfArticle: 1,
      table_filter: [
        // { tablename: 'Research Title', field: 'file_title' },
        { tablename: "Date Uploaded", field: "file_createdAt" },
        { tablename: "Corresponding Author", field: "file_corFname" },
        { tablename: "Main Author", field: "file_mainauthorfname" },
        { tablename: "Co-Author", field: "file_coauthor" },
        { tablename: "Date Published", field: "file_publishedDateMonth" },
        { tablename: "Categories", field: "file_categories" },
        { tablename: "Author's keywords", field: "file_authorsKeywords" },
        { tablename: "Type Of Article", field: "file_typeOfArticle" },
        { tablename: "Published", field: "file_isPublished" },
      ],
    };
  },
  mounted() {
    this.timeToday();
  },

  methods: {
    seeLessFunc(i) {
      const c = document.querySelector("#abstract_" + i);
      c.classList.add("captionText");
      const sl = document.querySelector("#seeLessText" + i);
      sl.classList.add("hidden");
      const s = document.querySelector("#seeMoreText" + i);
      s.classList.remove("hidden");
    },
    seeMoreFunc(i) {
      const c = document.querySelector("#abstract_" + i);
      c.classList.remove("captionText");
      const s = document.querySelector("#seeMoreText" + i);
      s.classList.add("hidden");
      const sl = document.querySelector("#seeLessText" + i);
      sl.classList.remove("hidden");
    },
    goFilter() {
      // const toast = useToast(); // toast.error("Please complete your profile to continue.", { //   position: "top-center", //   timeout: 3000, //   closeOnClick: true, //   pauseOnFocusLoss: false, //   pauseOnHover: false, //   draggable: true, //   draggablePercent: 0.6, //   showCloseButtonOnHover: false, //   hideProgressBar: false, //   closeButton: "button", //   icon: true, //   rtl: false, // }); // if (this.filterText) { // }
      const fd = {
        tableForFilter: this.tableForFilter,
        tableForFilterVal: this.tableForFilterVal,
        filterText: this.filterText,
        filterDate1: this.filterDate1,
        filterDate2: this.filterDate2,
        filterFname: this.filterFname,
        filterMname: this.filterMname,
        filterLname: this.filterLname,
        filterEmail: this.filterEmail,
        filterOrg: this.filterOrg,
        filterPublishedMonth: this.filterPublishedMonth,
        filterPublishedYear: this.filterPublishedYear,
        filterPublishedMonth1: this.filterPublishedMonth1,
        filterPublishedMonth2: this.filterPublishedMonth2,
        filterPublishedYear1: this.filterPublishedYear1,
        filterPublishedYear2: this.filterPublishedYear2,
        filterIsPublished: this.filterIsPublished,
        filterCategory: this.filterCategory,
        filterTypeOfArticle: this.filterTypeOfArticle,
      };
      axios
        .post(
          process.env.VUE_APP_API_SERVER + "/fileupload/filteredArticles",
          fd,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            this.contents = response.data;
            // console.log(response.data);
            this.results = response.data.length;
            this.isLoading = true;
            setTimeout(() => (this.isLoading = false), 1000);
          },
          (error) => {
            console.log(error);
          }
        );
    },
    changeField2(event) {
      var val = event.target.value;
      // var template = '';
      if (val == "in_range_date") {
        this.showFilterDate1 = false;
        this.showFilterDate2 = true;
      }

      if (val == "not_equal_date") {
        this.showFilterDate1 = true;
        this.showFilterDate2 = false;
      }

      if (val == "equal_date") {
        this.showFilterDate1 = true;
        this.showFilterDate2 = false;
      }

      if (val == "greater_than_date") {
        this.showFilterDate1 = true;
        this.showFilterDate2 = false;
      }

      if (val == "starts_with_string") {
        this.showFilter = false;
        this.showFilterDate2 = false;
        this.showFilterIsPub = false;
        this.showFilterString = true;
      }

      if (val == "ends_with_string") {
        this.showFilter = false;
        this.showFilterDate2 = false;
        this.showFilterIsPub = false;
        this.showFilterString = true;
      }

      if (val == "contain_string") {
        this.showFilter = false;
        this.showFilterDate2 = false;
        this.showFilterIsPub = false;
        this.showFilterString = true;
      }

      if (val == "not_contain_string") {
        this.showFilter = false;
        this.showFilterDate2 = false;
        this.showFilterIsPub = false;
        this.showFilterString = true;
      }

      if (val == "equal_string") {
        this.showFilter = false;
        this.showFilterDate2 = false;
        this.showFilterIsPub = false;
        this.showFilterString = true;
      }

      if (val == "not_equal_string") {
        this.showFilter = false;
        this.showFilterDate2 = false;
        this.showFilterIsPub = false;
        this.showFilterString = true;
      }

      if (
        val == "equal_date_pub" ||
        val == "greater_than_date_pub" ||
        val == "not_equal_date_pub"
      ) {
        this.showDropdownPublished = true;
        this.showDropdownPublishedRange = false;
      }

      if (val == "in_range_date_pub") {
        this.showDropdownPublishedRange = true;
        this.showDropdownPublished = false;
      }

      if (
        val == "contain_string_ak" ||
        val == "not_contain_string_ak" ||
        val == "equal_string_ak" ||
        val == "not_equal_string_ak" ||
        val == "starts_with_string_ak" ||
        val == "ends_with_string_ak"
      ) {
        this.showFilter = true;
        this.showFilterDate2 = false;
        this.showFilterIsPub = false;
        this.showFilterString = false;
      }
    },
    changeField(event) {
      var val = event.target.value;
      if (
        val == "file_corFname" ||
        val == "file_mainauthorfname" ||
        val == "file_coauthor"
      ) {
        var options = [
          { option: "Contains", val: "contain_string" },
          { option: "Not contains", val: "not_contain_string" },
          { option: "Equal", val: "equal_string" },
          { option: "Not equal", val: "not_equal_string" },
          { option: "Starts with", val: "starts_with_string" },
          { option: "Ends with", val: "ends_with_string" },
        ];
        this.filterOption = options;
        this.tableForFilterVal = "contain_string";
        this.showFilter = false;
        this.showFilterIsPub = false;
        this.showFilterDate1 = false;
        this.showFilterDate2 = false;
        this.showFilterString = true;
        this.showTableForFilterVal = true;
        this.showDropdownPublished = false;
        this.showDropdownPublishedRange = false;
        this.showCategoriesList = false;
        this.showTypeOfArticle = false;
      } else if (val == "file_authorsKeywords") {
        var options = [
          { option: "Contains", val: "contain_string_ak" },
          { option: "Not contains", val: "not_contain_string_ak" },
          { option: "Equal", val: "equal_string_ak" },
          { option: "Not equal", val: "not_equal_string_ak" },
          { option: "Starts with", val: "starts_with_string_ak" },
          { option: "Ends with", val: "ends_with_string_ak" },
        ];
        this.filterOption = options;
        this.tableForFilterVal = "contain_string_ak";
        this.showFilter = true;
        this.showFilterIsPub = false;
        this.showFilterDate1 = false;
        this.showFilterDate2 = false;
        this.showFilterString = false;
        this.showTableForFilterVal = true;
        this.showDropdownPublished = false;
        this.showDropdownPublishedRange = false;
        this.showCategoriesList = false;
        this.showTypeOfArticle = false;
      } else if (val == "file_createdAt") {
        var options = [
          { option: "Equals", val: "equal_date" },
          { option: "Greater than", val: "greater_than_date" },
          { option: "Not equal", val: "not_equal_date" },
          { option: "In range", val: "in_range_date" },
        ];
        this.filterOption = options;
        this.tableForFilterVal = "equal_date";
        this.showFilter = false;
        this.showFilterIsPub = false;
        this.showFilterDate1 = true;
        this.showFilterDate2 = false;
        this.showFilterString = false;
        this.showTableForFilterVal = true;
        this.showDropdownPublished = false;
        this.showDropdownPublishedRange = false;
        this.showCategoriesList = false;
        this.showTypeOfArticle = false;
      } else if (val == "file_publishedDateMonth") {
        var options = [
          { option: "Equals", val: "equal_date_pub" },
          { option: "Greater than", val: "greater_than_date_pub" },
          { option: "Not equal", val: "not_equal_date_pub" },
          { option: "In range", val: "in_range_date_pub" },
        ];
        this.filterOption = options;
        this.tableForFilterVal = "equal_date_pub";
        this.showFilter = false;
        this.showFilterIsPub = false;
        this.showFilterDate1 = false;
        this.showFilterDate2 = false;
        this.showFilterString = false;
        this.showTableForFilterVal = true;
        this.showDropdownPublished = true;
        this.showDropdownPublishedRange = false;
        this.showCategoriesList = false;
        this.showTypeOfArticle = false;
      } else if (val == "file_categories") {
        axios
          .get(process.env.VUE_APP_API_SERVER + "/journalcategory/", {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          })
          .then(
            (response) => {
              // console.log("categories ", response.data);
              this.categoriesList = response.data;
            },
            (error) => {
              console.log(error);
            }
          );
        this.filterOption = options;
        this.tableForFilterVal = "equal_date_pub";
        this.showFilter = false;
        this.showFilterIsPub = false;
        this.showFilterDate1 = false;
        this.showFilterDate2 = false;
        this.showFilterString = false;
        this.showTableForFilterVal = false;
        this.showDropdownPublished = false;
        this.showDropdownPublishedRange = false;
        this.showCategoriesList = true;
        this.showTypeOfArticle = false;
      } else if (val == "file_typeOfArticle") {
        this.showFilter = false;
        this.showFilterIsPub = false;
        this.showFilterDate1 = false;
        this.showFilterDate2 = false;
        this.showFilterString = false;
        this.showTableForFilterVal = false;
        this.showDropdownPublished = false;
        this.showDropdownPublishedRange = false;
        this.showCategoriesList = false;
        this.showTypeOfArticle = true;
      } else if (val == "file_isPublished") {
        this.showFilter = false;
        this.showFilterIsPub = true;
        this.showFilterDate1 = false;
        this.showFilterDate2 = false;
        this.showFilterString = false;
        this.showTableForFilterVal = false;
        this.showDropdownPublished = false;
        this.showDropdownPublishedRange = false;
        this.showCategoriesList = false;
        this.showTypeOfArticle = false;
      }
    },
    toggleThis() {
      this.seeSaved = false;
      this.autoDisplayJournals();
    },
    toggleThis2() {
      this.seeSaved = !this.seeSaved;
      this.autoDisplayJournalSaved();
    },
    refreshData() {
      this.autoDisplayJournals();
    },
    closeSave() {
      this.isPleaseLoginModal = false;
    },
    closeCite() {
      this.isCitationModal = false;
    },
    redirectTo(loc) {
      this.$router.push("/signin/" + loc);
    },

    init() {
      this.contents = [];
      this.page = 1;
      this.perPage = 10;
      this.pages = [];
      this.fromPage = 1;
      this.toPage = null;
      this.results = null;
    },

    formatNumber(num) {
      if (num <= 9) {
        return "0" + num;
      } else {
        return num;
      }
    },

    stringFormatDate(date) {
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(date).toLocaleDateString("en", options);
    },

    toggleSubMenu(e) {
      var elems = document.querySelector("#tab-active");
      // console.log(elems)
      // if (elems !== null) {
      //     elems.classList.remove("activeTab");
      // }
      // e.target.classList.add("activeTab");
    },

    formatDate(date) {
      const d = new Date(date);
      this.timeAgo = d;

      const mn = d.getMonth() + 1;
      var day = d.getDate();
      const yr = d.getFullYear();
      const hh = d.getHours();
      const mm = d.getMinutes();
      const ss = d.getSeconds();

      return (
        this.formatNumber(mn) +
        "-" +
        this.formatNumber(day) +
        "-" +
        yr +
        " " +
        this.formatNumber(hh) +
        ":" +
        this.formatNumber(mm) +
        ":" +
        this.formatNumber(ss)
      );
    },

    timeToday() {
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var dateTime = date + " " + time;

      this.date_now = moment(today).format("lll");
    },

    save(d) {
      const toast = useToast();
      axios
        .get(
          process.env.VUE_APP_API_SERVER +
            "/journalcategory/addNewSaved/" +
            d.id +
            "/" +
            this.$store.getters.getUserdetailID,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            if (response.data.status == 200 || response.data.status == 201) {
              toast.success(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
              this.autoDisplayJournals();
              // console.log(response);
            } else {
              toast.warning("Something went wrong!", {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
              this.autoDisplayJournals();
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },

    unsaved(d) {
      const toast = useToast();
      axios
        .get(
          process.env.VUE_APP_API_SERVER +
            "/journalcategory/unsaved/" +
            d.id +
            "/" +
            this.$store.getters.getUserdetailID,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            if (response.data.status == 200 || response.data.status == 201) {
              this.autoDisplayJournals();
              this.seeSaved = false;
              toast.success(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            } else {
              this.autoDisplayJournals();
              this.seeSaved = false;
              toast.warning(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
    unsaved2(d) {
      const toast = useToast();
      axios
        .get(
          process.env.VUE_APP_API_SERVER +
            "/journalcategory/unsaved2/" +
            d.journalID +
            "/" +
            this.$store.getters.getUserdetailID,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            if (response.data.status == 200 || response.data.status == 201) {
              this.autoDisplayJournals();
              this.seeSaved = false;
              toast.success(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            } else {
              this.autoDisplayJournals();
              this.seeSaved = false;
              toast.warning(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
    cite(d) {
      if (d) {
        this.isCitationModal = true;
        this.citationData = d;
      }
    },

    //load approved journals
    // async getJournals() {
    //     if (this.searchkey == null || this.searchkey == "") {
    //         this.contents = [];
    //         this.results = 0;
    //         this.isLoading = false;
    //         return false;
    //     } else {
    //         const start = performance.now();
    //         axios
    //             .get(process.env.VUE_APP_API_SERVER + "/fileupload/previewAll/" + this.searchkey)
    //             .then(
    //                 (response) => {
    //                     this.contents = response.data;
    //                     this.results = response.data.length;
    //                     this.isLoading = false;
    //                     console.log(response.data)
    //                 },
    //                 (error) => {
    //                     console.log(error);
    //                 }
    //             );
    //         const end = performance.now();
    //         const duration = Math.round(end - start) / 100;
    //         this.duration = duration;
    //         this.timeToday();
    //     }

    // },

    //load approved journals all thesis
    // async getThesis() {
    //     if (this.searchkey == null || this.searchkey == "") {
    //         this.contents = [];
    //         this.results = 0;
    //         this.isLoading = false;
    //         return false;
    //     } else {
    //         const start = performance.now();
    //         axios
    //             .get(process.env.VUE_APP_API_SERVER + "/fileupload/previewThesis/" + this.searchkey)
    //             .then(
    //                 (response) => {
    //                     this.contents = response.data;
    //                     this.results = response.data.length;
    //                     this.isLoading = false;
    //                     // console.log(response.data)
    //                 },
    //                 (error) => {
    //                     console.log(error);
    //                 }
    //             );
    //         const end = performance.now();
    //         const duration = Math.round(end - start) / 100;
    //         this.duration = duration;
    //         this.timeToday();
    //     }

    // },

    //load approved journals all dissertation
    // async getDiss() {
    //     if (this.searchkey == null || this.searchkey == "") {
    //         this.contents = [];
    //         this.results = 0;
    //         this.isLoading = false;
    //         return false;
    //     } else {
    //         const start = performance.now();
    //         axios
    //             .get(process.env.VUE_APP_API_SERVER + "/fileupload/previewDissertation/" + this.searchkey)
    //             .then(
    //                 (response) => {
    //                     this.contents = response.data;
    //                     this.results = response.data.length;
    //                     this.isLoading = false;
    //                     // console.log(response.data)
    //                 },
    //                 (error) => {
    //                     console.log(error);
    //                 }
    //             );
    //         const end = performance.now();
    //         const duration = Math.round(end - start) / 100;
    //         this.duration = duration;
    //         this.timeToday();
    //     }

    // },

    handleSearching() {
      if (this.searchText == "" || !this.searchText) {
        this.autoDisplayJournals();
      } else {
        var n = null;
        var nn = null;
        if (
          this.searchText == "thesis" ||
          this.searchText == "Thesis" ||
          this.searchText == "THESIS" ||
          this.searchText == "thes" ||
          this.searchText == "Thes" ||
          this.searchText == "THES"
        ) {
          n = 1;
        }

        if (
          this.searchText == "capstone" ||
          this.searchText == "Capstone" ||
          this.searchText == "CAPSTONE" ||
          this.searchText == "cap" ||
          this.searchText == "Cap" ||
          this.searchText == "CAP"
        ) {
          n = 2;
        }

        if (
          this.searchText == "dissertation" ||
          this.searchText == "Dissertation" ||
          this.searchText == "DISSERTATION"
        ) {
          n = 3;
        }

        if (
          this.searchText == "research" ||
          this.searchText == "Research" ||
          this.searchText == "RESEARCH"
        ) {
          n = 4;
        }

        if (
          this.searchText == "journal" ||
          this.searchText == "Journal" ||
          this.searchText == "JOURNAL"
        ) {
          n = 5;
        }

        if (
          this.searchText == "others" ||
          this.searchText == "Others" ||
          this.searchText == "OTHERS"
        ) {
          n = 6;
        }

        if (
          this.searchText == "published" ||
          this.searchText == "Published" ||
          this.searchText == "PUBLISHED" ||
          this.searchText == "publish" ||
          this.searchText == "Publish" ||
          this.searchText == "PUBLISH" ||
          this.searchText == "pub" ||
          this.searchText == "PUB" ||
          this.searchText == "Pub" ||
          this.searchText == "pu" ||
          this.searchText == "Pu" ||
          this.searchText == "PU"
        ) {
          nn = 1;
        }

        if (
          this.searchText == "unpublished" ||
          this.searchText == "Unpublished" ||
          this.searchText == "UNPUBLISHED" ||
          this.searchText == "unpublish" ||
          this.searchText == "Unpublish" ||
          this.searchText == "UNPUBLISH" ||
          this.searchText == "not yet publish" ||
          this.searchText == "Not Yet Publish" ||
          this.searchText == "NOT YET PUBLISH" ||
          this.searchText == "not yet published" ||
          this.searchText == "Not Yet Published" ||
          this.searchText == "NOT YET PUBLISHED" ||
          this.searchText == "un" ||
          this.searchText == "UN" ||
          this.searchText == "Un"
        ) {
          nn = 0;
        }

        this.contents = this.filteredData.filter(
          (data) =>
            data.title.toLowerCase().indexOf(this.searchText.toLowerCase()) !==
              -1 ||
            data.mainauthorfname
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) !== -1 ||
            data.mainauthorlname
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) !== -1 ||
            data.authorsKeywords
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) !== -1 ||
            data.typeOfArticle.toString().indexOf(n) !== -1 ||
            data.isPublished.toString().indexOf(nn) !== -1
        );
      }
    },

    //load approved journals all dissertation
    // async getJournals2() {
    //     if (this.searchkey == null || this.searchkey == "") {
    //         this.contents = [];
    //         this.results = 0;
    //         this.isLoading = false;
    //         return false;
    //     } else {
    //         const start = performance.now();
    //         axios
    //             .get(process.env.VUE_APP_API_SERVER + "/fileupload/previewJournal/" + this.searchkey)
    //             .then(
    //                 (response) => {
    //                     this.contents = response.data;
    //                     this.results = response.data.length;
    //                     this.isLoading = false;
    //                     // console.log(response.data)
    //                 },
    //                 (error) => {
    //                     console.log(error);
    //                 }
    //             );
    //         const end = performance.now();
    //         const duration = Math.round(end - start) / 100;
    //         this.duration = duration;
    //         this.timeToday();
    //     }

    // },

    autoDisplayJournals() {
      const start = performance.now();
      this.searchText = "";
      this.isLoading = true;
      setTimeout(() => (this.isLoading = false), 3000);

      axios
        .get(
          process.env.VUE_APP_API_SERVER + "/fileupload/getAutoDisplayJournal/",
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            this.contents = response.data;
            this.filteredData = response.data;
            // console.log(response.data);
            this.results = response.data.length;
            this.isLoading = false;
          },
          (error) => {
            console.log(error);
          }
        );
      const end = performance.now();
      const duration = Math.round(end - start) / 100;
      this.duration = duration;
      this.timeToday();
    },

    autoDisplayJournalSaved() {
      const start = performance.now();
      this.searchText = "";

      var userdetailID = this.$store.getters.getUserdetailID;
      // console.log(userdetailID);
      axios
        .get(
          process.env.VUE_APP_API_SERVER +
            "/fileupload/getSavedArticles/" +
            userdetailID,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            this.isLoading = true;
            setTimeout(() => (this.isLoading = false), 1000);
            // console.log(response.data);
            this.contents = response.data;
            this.filteredData = response.data;
            this.results = response.data.length;
            this.isLoading = false;
          },
          (error) => {
            console.log(error);
          }
        );
      const end = performance.now();
      const duration = Math.round(end - start) / 100;
      this.duration = duration;
      this.timeToday();
    },

    viewJournal(data) {
      this.isLoading = true;
      setTimeout(function () {
        this.isLoading = false;
      }, 3000);
      this.$emit("viewJournal", data);
      this.$emit("searchText", this.searchText);
      // console.log(item);
    },

    // format date to display
    formatDate(date, year) {
      var mnthArr = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      if (date == null || date == "") {
        return year;
      } else {
        return mnthArr[date] + " " + year;
      }
    },

    // set number of pages to paginate
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(
        parseInt(this.contents.length) / this.perPage
      );
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    // handles the from and to of pagination table
    paginate(contents) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;

      if (to > this.contents.length) {
        this.fromPage = from + 1;
        this.toPage = this.contents.length;
      } else {
        this.fromPage = from + 1;
        this.toPage = to;
      }

      return contents.slice(from, to);
    },

    getRange(from, to) {
      const range = [];

      from = from > 0 ? from : 1;

      for (let i = from; i <= to; i++) {
        range.push(i);
      }

      return range;
    },
  },
  computed: {
    displayedJournals() {
      return this.paginate(this.contents);
    },
    isValueLast() {
      return this.value >= this.length;
    },

    isValueFirst() {
      return this.value <= 1;
    },

    items() {
      const maxLength = 7;
      if (this.pages.length <= maxLength || maxLength < 1) {
        return this.getRange(1, this.pages.length);
      }

      const even = maxLength % 2 === 0 ? 1 : 0;
      const left = Math.floor(maxLength / 2);
      const right = this.pages.length - left + 1 + even;

      if (this.page > left && this.page < right) {
        const start = this.page - left + 2;
        const end = this.page + left - 2 - even;

        return [
          1,
          "...",
          ...this.getRange(start, end),
          "...",
          this.pages.length,
        ];
      }
      if (this.page === left) {
        const end = this.page + left - 1 - even;

        return [...this.getRange(1, end), "...", this.pages.length];
      }
      if (this.page === right) {
        const start = this.page - left + 1;

        return [1, "...", ...this.getRange(start, this.pages.length)];
      } else {
        return [
          ...this.getRange(1, left),
          "...",
          ...this.getRange(right, this.pages.length),
        ];
      }
    },
  },
  watch: {
    contents() {
      this.setPages();
    },

    perPage() {
      this.page = 1;
      this.setPages();
    },
    containerWidth() {
      // trigger pagination resize
      this.$nextTick(() => {
        window.dispatchEvent(new Event("resize"));
      });
    },
  },
  created() {
    this.moment = moment;
    this.autoDisplayJournals();
  },
};
</script>

<style scoped>
.tbl_header {
  background-color: #105005;
}

.indent {
  text-indent: 0.5rem;
  height: 35px;
}

/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}

.mustTruncate {
  text-overflow: ellipsis;
}

li > a.activeTab {
  border-color: rgba(16, 80, 5, var(--tw-border-opacity));
  border-bottom-width: 2px;
  color: rgba(16, 80, 5, var(--tw-text-opacity));
}

.captionText {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
