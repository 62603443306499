<template>
  <div class="w-full grid pb-6">
    <div class="flex justify-between">
      <h1 class="text-l text-gray-600 font-semibold py-2 px-2">Security</h1>
      <button title="Update">
        <svg @click="onEditBtn(true)" xmlns="http://www.w3.org/2000/svg" :class="isEdit ? 'animate-bounce' : ''" class=" cursor_pointer h-10 w-10 p-1 text-gray-500 mr-4 hover:text-green-800 " viewBox="0 0 20 20" fill="currentColor" >
          <path
            d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
          />
          <path
            fill-rule="evenodd"
            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>

    <hr />
    <div class="mt-10">
      <div class="grid md:flex md:justify-evenly mb-10">
        <div class="w-full md:w-1/4 px-3 md:mb-6">
          <label
            class="block tracking-wide text-green-800 text-xs font-bold mb-2"
            for="grid-first-name"
          >
            Email
          </label>
          <input disabled v-model="email" class=" appearance-none block w-full bg-gray-200 text-gray-700 rounded-md py-1 text-sm px-4 mb-3 leading-tight focus:outline-none focus:bg-white " type="email" placeholder="Jane@dnsc.edu.ph" />
        </div>

        <div class="w-full md:w-1/4 px-3">
          <label
            class="block tracking-wide text-green-800 text-xs font-bold mb-2"
            for="grid-last-name"
          >
            Password
          </label>
          <input @keyup="testPassword" v-model="password" :disabled="!isEdit" :class="isEdit ? 'bg-white' : 'bg-gray-200'" class=" form_inputs appearance-none block w-full text-gray-700 border border-gray-200 rounded-md py-1 text-sm px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 " type="password" placeholder="********************"
          />
          <p v-if="password_err" class="text-FF0000 text-xs font-normal">
            Password should be an alphanumeric combination of atleast 10
            characters
          </p>
        </div>
        <div class="w-full md:w-1/4 px-3">
          <label
            class="block tracking-wide text-green-800 text-xs font-bold mb-2"
            for="grid-last-name"
          >
            Confirm Password
          </label>
          <input :disabled="!isEdit" v-model="confirm_password" @keyup="passwordMatch" :class="isEdit ? 'bg-white' : 'bg-gray-200'" class=" form_inputs appearance-none block w-full text-gray-700 border border-gray-200 rounded-md py-1 mb-1 text-sm px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 " type="password" placeholder="********************" />
          <p
            :class="confirm_password_err ? 'text-FF0000' : 'text-customGreen'"
            class="text-xs font-normal"
          >
            {{ confirm_err_msg }}
          </p>
        </div>
      </div>

      <div class="flex justify-end mr-4" v-show="isEdit">
        
        <a href="#" class="text-white bgColor text-sm font-normal px-3 py-1 h-8 rounded" @click="updateSecurity" >
          <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 inline">
              <path fill-rule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                clip-rule="evenodd" />
            </svg> -->
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
              class="w-4 h-4 inline">
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>
          Save</a
        >
        <a href="#" class="text-sm text-gray-400 font-normal px-3 border py-1 h-8 rounded ml-1" @click="onEditBtn(false)" >
            Cancel</a
          >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Abouts",
  data() {
    return {
      isEdit: false,
      email: null,
      password: null,
      confirm_password: null,
      password_err: false,
      confirm_password_err: false,
      confirm_err_msg: "",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    //init method
    init() {
      this.email = this.$store.getters.getUserEmail;
    },

    // PASSWORD REGEX
    testPassword() {
      this.confirm_password = null;
      this.confirm_password_err = false;
      this.confirm_err_msg = null;

      const pass_regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/;
      if (pass_regex.test(this.password)) {
        this.password_err = false;
      } else {
        this.password_err = true;
      }
    },

    passwordMatch() {
      if (this.confirm_password == "" || this.confirm_password == null) {
        this.confirm_err_msg = "";
        return false;
      } else {
        if (this.confirm_password == this.password) {
          this.confirm_password_err = false;
          this.confirm_err_msg = "Password matched.";
        } else {
          this.confirm_password_err = true;
          this.confirm_err_msg = "Password do not match.";
        }
      }
    },

    // UPDATE PASSWORD
    updateSecurity() {
      var valid = true;
      if (this.password != null && this.password_err == false) {
        valid = true;
      } else {
        valid = false;
      }

      if (this.confirm_password != null && this.confirm_password_err == false) {
        valid = true;
      } else {
        valid = false;
      }
      if (valid) {
        const fd = {
          password: this.password,
        };

        axios
          .post(
            process.env.VUE_APP_API_SERVER + "/users-controller/usersecurity",
            fd,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
              },
            }
          )
          .then(
            (response) => {
              if (response.data.status == 200) {
                const alertMsg = { msg: response.data.msg };
                this.password = null;
                this.confirm_password = null;
                this.confirm_password_err = false;
                this.confirm_err_msg = "";
                // emit event to parent parent component
                this.$emit("users_details_updated");
                this.$parent.$parent.$emit("success_alert", alertMsg);
                this.isEdit = false;
              }
            },
            (error) => {
              console.log(error);
            }
          );
      } else {
        this.password_err = true;
        this.confirm_password_err = true;
        this.confirm_err_msg = "This is required";
      }
    },

    onEditBtn(val) {
      (this.password = null),
        (this.confirm_password = null),
        (this.password_err = false),
        (this.confirm_password_err = false),
        (this.confirm_err_msg = ""),
        (this.isEdit = val);
    },
  },
};
</script>

<style scoped>
.cursor_pointer:hover {
  cursor: pointer;
}
</style>