<template>
  <div>
    <LandingNav />
    <router-view></router-view>
  </div>
</template>

<script>
// import Landing from '../components/Landing.vue'
import LandingNav from "../components/LandingNav.vue";
export default {
  components: {
    LandingNav,
  },
};
</script>

<style scoped>
</style>