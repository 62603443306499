<template>
  <div
    id="defaultModal"
    tabindex="-1"
    aria-hidden="true"
    class="bgModal fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-screen lg:h-screen xl:h-screen 2xl:h-screen justify-center items-center"
  >
    <div class="relative mt-10 m-auto w-full h-full max-w-xl p-4 md:h-auto">
      <div
        id="div"
        class="modal relative bg-white overflow-hidden rounded-lg shadow dark:bg-gray-700 animated modal_open"
      >
        <!-- modal header -->
        <div class="flex justify-between border-b py-4 px-4 bgColor">
          <h1 class="text-white text-sm pl-2">Request to view full article</h1>
          <button
            type="button"
            @click="closeModal"
            class="absolute top-1.5 right-2.5 text-white bg-transparent rounded-lg text-sm p-2 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- modal content -->
        <div class="mt-2 h-48 px-4">
          <span class="text-105005 text-sm font-bold">Message: </span>
          <div class="pt-2">
            <!-- <label for="small-input"
                            class=" block mb-2 text-xs font-semibold text-gray-600 dark:text-gray-300 ">Category
                            title</label> -->
            <textarea
              placeholder="I would like to request for accession number to view the full manuscript of your article."
              class="border w-full px-2 py-1 text-sm outline-none text-gray-500"
              rows="4"
              v-model="remarks"
            ></textarea>
          </div>

          <div
            class="flex justify-end pt-1 mt-2 mx-1 space-x-4 rounded-b dark:border-gray-600"
          >
            <button
              @click="closeModal"
              type="button"
              class="text-gray-600 bg-gray-200 rounded border border-gray-200 text-xs font-medium px-5 py-2 hover:text-gray-900"
            >
              Close
            </button>
            <button
              v-if="action == 'add'"
              @click="send"
              type="button"
              class="text-white bgColor font-medium rounded text-xs ml-5 px-3 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              <div class="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4 inline"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M4.5 12.75l6 6 9-13.5"
                  />
                </svg>
                Request
                <div class="btnloader h-4 w-4 ml-2" v-if="isLoading"></div>
              </div>
            </button>

            <!-- Save -->
            <button
              v-if="action == 'Add'"
              :disabled="isLoading"
              @click="save"
              type="button"
              class="text-white bg-green-800 font-medium rounded-lg text-xs ml-5 px-5 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              <div class="flex">
                Save
                <div class="btnloader h-4 w-4 ml-2" v-if="isLoading"></div>
              </div>
            </button>

            <!-- Update -->
            <button
              v-if="action == 'Update'"
              :disabled="isLoading"
              @click="update"
              type="button"
              class="text-white bg-green-800 font-medium rounded-lg text-xs ml-5 px-5 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              <div class="flex">
                Update
                <div class="btnloader h-4 w-4 ml-2" v-if="isLoading"></div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
export default {
  name: "JournalPermissionModal",
  props: ["action", "jpItem"],
  data() {
    return {
      remarks: null,
      user_journal_id: null,
      user_id: null,
      user_now: null,
      isLoading: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      document.getElementById("div").classList.remove("modal_close");
      document.getElementById("div").classList.add("modal_open");
    },
    closeModal() {
      document.getElementById("div").classList.remove("modal_open");

      document.getElementById("div").classList.add("modal_close");

      setTimeout(() => this.$emit("closeModal"), 500);
    },

    send() {
      const toast = useToast();
      var new_remarks;
      if (this.remarks == null || this.remarks == "") {
        new_remarks =
          "I would like to request for accession number to view the full manuscript of your article.";
        // new_remarks = "I would like to ask permission to view your research.";
      } else {
        new_remarks = this.remarks;
      }
      const fd = {
        remarks: new_remarks,
        user_id: this.jpItem[0].user_id,
        user_journal_id: this.jpItem[0].user_journal_id,
        user_now: this.jpItem[0].user_now,
        userdetails: this.jpItem[0].userdetails,
      };
      // console.log(fd);
      axios
        .post(
          process.env.VUE_APP_API_SERVER +
            "/journal-permission/add-new-journal-permission",
          fd,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            if (response.data.status == 201) {
              this.$emit("closeModal");
              this.$emit("doneRequestSuccess");
              toast.success(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            } else if (response.data.status == 400) {
              toast.error(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            }
            this.isLoading = false;
          },
          (error) => {
            console.log(error);
          }
        );
    },

    // save() {
    //     const fd = { categoryDesc: this.category };
    //     const toast = useToast();
    //     this.isLoading = true;
    //     if (this.category == null || this.category == "") {
    //         toast.error("Field is required.", {
    //             position: "top-right",
    //             timeout: 3000,
    //             closeOnClick: true,
    //             pauseOnFocusLoss: false,
    //             pauseOnHover: false,
    //             draggable: true,
    //             draggablePercent: 0.6,
    //             showCloseButtonOnHover: false,
    //             hideProgressBar: false,
    //             closeButton: "button",
    //             icon: true,
    //             rtl: false,
    //         });
    //         this.isLoading = false;
    //     } else {
    //         axios
    //             .post(
    //                 process.env.VUE_APP_API_SERVER +
    //                 "/journalcategory/add-new-category",
    //                 fd,
    //                 {
    //                     headers: {
    //                         Authorization: `Bearer ${this.$store.getters.getToken}`,
    //                     },
    //                 }
    //             )
    //             .then(
    //                 (response) => {
    //                     if (response.data.status == 201) {
    //                         this.$emit("success");

    //                         toast.success(response.data.msg, {
    //                             position: "top-right",
    //                             timeout: 3000,
    //                             closeOnClick: true,
    //                             pauseOnFocusLoss: false,
    //                             pauseOnHover: false,
    //                             draggable: true,
    //                             draggablePercent: 0.6,
    //                             showCloseButtonOnHover: false,
    //                             hideProgressBar: false,
    //                             closeButton: "button",
    //                             icon: true,
    //                             rtl: false,
    //                         });
    //                     } else if (response.data.status == 400) {
    //                         toast.error(response.data.msg, {
    //                             position: "top-right",
    //                             timeout: 3000,
    //                             closeOnClick: true,
    //                             pauseOnFocusLoss: false,
    //                             pauseOnHover: false,
    //                             draggable: true,
    //                             draggablePercent: 0.6,
    //                             showCloseButtonOnHover: false,
    //                             hideProgressBar: false,
    //                             closeButton: "button",
    //                             icon: true,
    //                             rtl: false,
    //                         });
    //                     }
    //                     this.isLoading = false;
    //                 },
    //                 (error) => {
    //                     console.log(error);
    //                 }
    //             );
    //     }
    // },

    // update() {
    //     this.isLoading = true;
    //     const toast = useToast();

    //     if (this.category == null || this.category == "") {
    //         toast.error("Field is required.", {
    //             position: "top-right",
    //             timeout: 3000,
    //             closeOnClick: true,
    //             pauseOnFocusLoss: false,
    //             pauseOnHover: false,
    //             draggable: true,
    //             draggablePercent: 0.6,
    //             showCloseButtonOnHover: false,
    //             hideProgressBar: false,
    //             closeButton: "button",
    //             icon: true,
    //             rtl: false,
    //         });
    //     } else {
    //         const data = {
    //             categoryDesc: this.category,
    //         };

    //         axios
    //             .patch(
    //                 process.env.VUE_APP_API_SERVER +
    //                 "/journalcategory/" +
    //                 this.categoryID,
    //                 data,
    //                 {
    //                     headers: {
    //                         Authorization: `Bearer ${this.$store.getters.getToken}`,
    //                     },
    //                 }
    //             )
    //             .then(
    //                 (response) => {
    //                     if (response.data.status == 200) {
    //                         this.$emit("success");

    //                         toast.success(response.data.msg, {
    //                             position: "top-right",
    //                             timeout: 3000,
    //                             closeOnClick: true,
    //                             pauseOnFocusLoss: false,
    //                             pauseOnHover: false,
    //                             draggable: true,
    //                             draggablePercent: 0.6,
    //                             showCloseButtonOnHover: false,
    //                             hideProgressBar: false,
    //                             closeButton: "button",
    //                             icon: true,
    //                             rtl: false,
    //                         });
    //                     } else if (response.data.status == 400) {
    //                         toast.error(response.data.msg, {
    //                             position: "top-right",
    //                             timeout: 3000,
    //                             closeOnClick: true,
    //                             pauseOnFocusLoss: false,
    //                             pauseOnHover: false,
    //                             draggable: true,
    //                             draggablePercent: 0.6,
    //                             showCloseButtonOnHover: false,
    //                             hideProgressBar: false,
    //                             closeButton: "button",
    //                             icon: true,
    //                             rtl: false,
    //                         });
    //                     }
    //                 },
    //                 (error) => {
    //                     console.log(error);
    //                 }
    //             );
    //     }
    // },

    toUpdate(item) {
      let items = {
        journal: this.journal,
        remarkItem: item,
      };
      // console.log(items);
      this.$emit("toUpdate", items);
    },
  },

  watch: {
    jpItem: {
      handler(val) {
        console.log(val);
        if (val) {
          this.user_journal_id = val.user_journal_id;
          this.user_id = val.user_id;
          this.user_now = val.user_now;
        } else {
          this.user_journal_id = null;
          this.user_id = null;
          this.user_now = null;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style>
.customIndent {
  text-indent: 10px;
}
</style>