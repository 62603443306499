<template>
  <div
    id="defaultModal"
    tabindex="-1"
    aria-hidden="true"
    class="bgModal modalShow fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full justify-center items-center transition-opacity duration-300"
  >
    <div
      class="relative mt-5 mb-32 md:mb-0 lg:mb-0 xl:mb-0 2xl:mb-0 md:mt-10 m-auto w-full h-full max-w-5xl max-h-full p-3 md:h-auto"
    >
      <div
        id="thisModal"
        class="modal relative bg-white overflow-hidden rounded-lg shadow dark:bg-gray-700 animated modal_open"
      >
        <!-- modal header -->
        <div class="flex justify-between border-b py-4 px-2 bg-105005">
          <h1 class="text-sm text-white px-5">Announcement to display</h1>
          <button
            type="button"
            @click="closeModal"
            class="modal_close absolute top-2.5 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="authentication-modal"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- modal content -->
        <div
          class="grid items-center w-full max-h-96 md:max-h-full px-6 overflow-y-auto"
        >
          <form enctype="multipart/form-data">
            <div
              v-show="imgUrls.length > 0"
              id="gallery"
              class="flex flex-wrap justify-center border border-gray-300 rounded-md w-full mt-5 md:mt-10 m-auto"
            >
              <div v-for="item in imgUrls" :key="item" class="">
                <span
                  class="inline-flex relative items-center p-3 text-sm font-medium text-center text-white rounded-lg focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  <div>
                    <img class="w-60 h-40 border" :src="item.url" />
                  </div>
                </span>
              </div>
            </div>

            <div class="mt-4">
              <label for="" class="text-xs text-gray-700 font-semibold"
                >Title</label
              >
              <input
                :disabled="
                  this.$store.getters.getUserRole != this.roleID ? true : false
                "
                v-model="title"
                :class="title_err ? 'border border-red-500' : ''"
                id="title"
                @keyup="hasChanged($event)"
                class="border w-full rounded md overflow-y-auto text-xs py-2 px-2 font-poppins"
                type="text"
                placeholder="Title"
              />
            </div>

            <!-- <div class="mt-4"> <label for="" class="text-xs text-gray-700 font-semibold">Captions</label> <textarea disabled v-model="captions" :class="captions_err ? 'border border-red-500' : ''" class=" border w-full rounded md overflow-y-auto text-xs py-2 px-2 font-poppins " cols="152" rows="15" type="text" placeholder="Type your captions here..." ></textarea> </div> -->

            <div class="mt-4">
              <label for="" class="text-xs text-gray-700 font-semibold"
                >Captions</label
              >
              <textarea
                :class="captions_err ? 'border border-red-500' : ''"
                :disabled="
                  this.$store.getters.getUserRole != this.roleID ? true : false
                "
                v-model="captions"
                id="caption"
                @keyup="hasChanged($event)"
                class="border w-full rounded md overflow-y-auto text-xs py-2 px-2 font-poppins"
                cols="152"
                rows="15"
                placeholder="Type captions here..."
              ></textarea>
            </div>

            <div class="mt-4 mb-5 md:mb-0">
              <label class="text-xs text-gray-700 font-semibold"
                >Display option</label
              >
              <select
                v-model="toDisplay"
                @change="onChange($event)"
                class="font-poppins mb-5 font-bold border bg-gray-50 border-gray-300 text-gray-900 rounded-lg focus:ring-105005 focus:border-105005 block w-full text-xs p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-105005 dark:focus:border-105005"
              >
                <option selected value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </form>
        </div>

        <div
          class="flex float-right p-5 space-x-2 rounded-b border-t w-full justify-end dark:border-gray-600"
        >
          <button
            @click="save()"
            type="button"
            id="thisBtn"
            disabled="true"
            class="font-normal text-sm px-3 py-1 h-8 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 border bg-gray-500 text-white rounded-md"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4 inline"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
            Save
          </button>
          <button
            type="button"
            @click="closeModal"
            class="text-sm text-gray-400 font-normal px-5 py-1 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600 border rounded-md"
          >
            Close
          </button>
          <!-- button to toggle approval remarks textarea -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
  name: "AnnouncementModal",
  props: ["announcement", "itemToUpdate"],
  data() {
    return {
      fileData: [],
      isUploadImage: false,
      imgUrls: [],
      title: null,
      title_err: false,
      captions: null,
      captions_err: false,
      imgs: null,
      toDisplay: 0,
      userID: null,
      changedDisplay: null,
      defaultVal: null,
      updateAnnouncement: null,
      roleID: null,
    };
  },

  watch: {
    itemToUpdate: {
      handler(val) {
        // console.log(val);
        if (!val) {
          this.announcementID = null;
          this.captions = null;
          this.userID = null;
          this.title = null;
        } else {
          if (val.filename && val.filename != "" && val.filename != []) {
            var f = JSON.parse(val.filename);
            f.forEach((element) => {
              var a = this.getImage(element);
              var prom = Promise.resolve(a);
              prom.then((val) => {
                var st = JSON.stringify(val);
                var con = JSON.parse(st);
                this.imgUrls.push(con);
              });
            });
          }
          this.defaultVal = val.isDisplayed;
          this.announcementID = val.id;
          this.toDisplay = val.isDisplayed;
          this.captions = val.caption;
          this.title = val.title;
          this.userID = val.userID;
          this.roleID = val.user.roleID;
        }
      },
      immediate: true,
    },
  },
  mounted() {},

  methods: {
    onChange(event) {
      if (event.target.value != this.defaultVal) {
        const btn = document.getElementById("thisBtn");
        btn.classList.remove("bg-gray-500");
        btn.classList.add("bg-105005");
        btn.disabled = false;
      } else {
        const btn = document.getElementById("thisBtn");
        btn.classList.remove("bg-105005");
        btn.classList.add("bg-gray-500");
        btn.disabled = true;
      }
    },
    async getImage(filename) {
      var img;
      return await axios
        .get(
          process.env.VUE_APP_API_SERVER +
            "/announcement/stream-file/" +
            filename,
          {
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then((response) => {
          const blob = new Blob([response.data]);
          var objectURL = URL.createObjectURL(blob);
          return {
            url: objectURL,
            name: filename,
          };
          // this.imgUrls.push(img);
        });
    },

    save() {
      const toast = useToast();
      const newData = {
        id: this.announcementID,
        toDisplay: this.toDisplay,
        userID: this.userID,
      };

      axios
        .post(
          process.env.VUE_APP_API_SERVER +
            "/announcement/updateAnnouncementToDisplay",
          newData,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            if (response.data.status == 201 || response.data.status == 200) {
              const bgModal = document.getElementById("thisModal");
              bgModal.classList.remove("modal_open");
              bgModal.classList.add("modal_close");

              setTimeout(() => {
                this.$emit("closeModal");
                bgModal.classList.add("modal_open");
                bgModal.classList.remove("modal_close");
              }, 500);
              this.$emit("refreshTable");
              toast.success(response.data.msg, {
                position: "top-center",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },

    closeModal() {
      const bgModal = document.getElementById("thisModal");
      bgModal.classList.remove("modal_open");
      bgModal.classList.add("modal_close");
      setTimeout(() => {
        this.$emit("closeModal");
        bgModal.classList.remove("modal_close");
        bgModal.classList.add("modal_open");
      }, 500);
      // this.$emit("closeModal");
    },
  },
};
</script>

<style scoped>
.bgGreen {
  color: #105005;
}

.inputIndent {
  text-indent: 10px;
}

.modal {
  transition: top 0.4s, opacity 0.4s;
}

.modalShow {
  top: 0;
  opacity: 1;
}
</style>