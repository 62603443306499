<template>
  <div
    id="defaultModal"
    tabindex="-1"
    aria-hidden="true"
    class="bgModal fixed top-0 left-0 right-0 z-50 w-full max-h-full md:inset-0 h-modal md:h-full justify-center items-center"
  >
    <!-- relative mt-5 mb-32 md:mb-0 lg:mb-0 xl:mb-0 2xl:mb-0 md:mt-10 m-auto w-full h-full max-w-5xl max-h-full p-3 md:h-auto -->
    <div
      class="relative mt-5 mb-96 m-auto w-full h-full max-w-5xl max-h-full p-3"
    >
      <div
        id="approveModal"
        class="modal_hgt modal relative bg-white overflow-hidden rounded-lg shadow dark:bg-gray-700 animated modal_open"
      >
        <div
          class="bgColor flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600"
        >
          <h3
            v-if="this.isAdminVerified != true"
            class="text-md ml-4 text-white dark:text-white mt-2"
          >
            Account Verification
          </h3>
          <h3
            v-if="this.isAdminVerified == true"
            class="text-md ml-4 text-white dark:text-white mt-2"
          >
            Account Details
          </h3>
          <button
            @click="closeModal"
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>

        <div class="grid max-h-96 -mb-4 md:-mb-0 overflow-y-auto">
          <div class="px-6 pt-4">
            <h1 class="text-md mb-2 font-semibold py-1">Basic Information</h1>
            <div class="flex flex-wrap mx-5">
              <div class="mb-4 mx-1">
                <label class="block text-gray-700 text-xs mb-2" for="">
                  First Name
                </label>
                <input
                  class="shadow appearance-none border rounded w-full text-xs py-1 px-4 pr-24 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  disabled
                  v-model="fname"
                  type="text"
                />
              </div>
              <div class="mb-4 mx-1">
                <label class="block text-xs text-gray-700 mb-2" for="">
                  Middle Name
                </label>
                <input
                  class="shadow appearance-none border rounded w-full text-xs py-1 px-4 pr-24 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  disabled
                  v-model="mname"
                  type="text"
                />
              </div>
              <div class="mb-4 mx-1">
                <label class="block text-gray-700 text-xs mb-2" for="">
                  Last Name
                </label>
                <input
                  class="shadow appearance-none border rounded w-full text-xs py-1 px-4 pr-24 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  disabled
                  v-model="lname"
                  type="text"
                />
              </div>
              <div class="mb-4 mx-1">
                <label class="block text-gray-700 text-xs mb-2" for="">
                  Level of Study
                </label>
                <input
                  class="shadow appearance-none border rounded w-full text-xs py-1 px-4 pr-24 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  disabled
                  v-model="lvlstudy"
                  type="text"
                />
              </div>
            </div>
          </div>

          <div class="px-6 pt-4">
            <h1 class="text-md mb-2 font-semibold py-1">School Affiliation</h1>
            <div class="flex flex-wrap mx-5">
              <div class="mb-4 mx-1">
                <label class="block text-gray-700 text-xs mb-2" for="">
                  Name of School
                </label>
                <input
                  class="shadow appearance-none border rounded w-full text-xs py-1 px-4 pr-24 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  disabled
                  v-model="school_name"
                  type="text"
                />
              </div>
              <div class="mb-4 mx-1">
                <label class="block text-gray-700 text-xs mb-2" for="">
                  School Address
                </label>
                <input
                  class="shadow appearance-none border rounded w-full text-xs py-1 px-4 pr-24 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  disabled
                  v-model="school_add"
                  type="text"
                />
              </div>
              <div class="mb-4 mx-1">
                <label class="block text-gray-700 text-xs mb-2" for="">
                  Office
                </label>
                <input
                  class="shadow appearance-none border rounded w-full text-xs py-1 px-4 pr-24 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  disabled
                  v-model="office"
                  type="text"
                />
              </div>
              <div class="mb-4 mx-1">
                <label class="block text-gray-700 text-xs mb-2" for="">
                  Position
                </label>
                <input
                  class="shadow appearance-none border rounded w-full text-xs py-1 px-4 pr-24 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  disabled
                  v-model="pos"
                  type="text"
                />
              </div>
              <div class="mb-4 mx-1">
                <label class="block text-gray-700 text-xs mb-2" for="">
                  Designation
                </label>
                <input
                  class="shadow appearance-none border rounded w-full text-xs py-1 px-4 pr-24 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  disabled
                  v-model="designation"
                  type="text"
                />
              </div>
            </div>
          </div>

          <div class="px-6 pt-4 pb-3">
            <h1 class="text-md mb-2 font-semibold py-1">Account Information</h1>
            <div class="flex flex-wrap mx-5">
              <div class="mb-4 mx-1">
                <label class="block text-gray-700 text-xs mb-2" for="">
                  Email Address
                </label>
                <input
                  class="shadow appearance-none border rounded w-full text-xs py-1 px-4 pr-24 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  disabled
                  v-model="email"
                  type="text"
                />
              </div>
              <div class="mb-4 mx-1">
                <label class="block text-gray-700 text-xs mb-2" for="">
                  Role
                </label>
                <select
                  @change="roleChange"
                  v-if="this.isAdminVerified != true"
                  class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full text-xs py-1 px-4 pr-20 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  v-model="roleid"
                >
                  <option v-for="item in roles" :key="item" :value="item.id">
                    {{ item.role }}
                  </option>
                </select>
                <select
                  @change="roleChange"
                  disabled
                  v-if="this.isAdminVerified == true"
                  class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full text-xs py-1 px-4 pr-20 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  v-model="roleid"
                >
                  <option v-for="item in roles" :key="item" :value="item.id">
                    {{ item.role }}
                  </option>
                </select>
                <!-- <input class=" shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline " type="text" /> -->
              </div>

              <!-- institute -->
              <div
                class="mb-10 md:mb-4 mx-1 w-full md:w-1/2"
                v-if="isDeanOrProgChair"
              >
                <label class="block text-gray-700 text-xs mb-2" for="">
                  Institute
                </label>
                <select
                  v-model="instituteID"
                  v-if="this.isAdminVerified != true"
                  class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full text-xs py-1 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option
                    v-for="item in institutes"
                    :key="item"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
                <select
                  v-model="instituteID"
                  disabled
                  v-if="this.isAdminVerified == true"
                  class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full text-xs py-1 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option
                    v-for="item in institutes"
                    :key="item"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="this.isAdminVerified != true"
          class="flex justify-end p-4 space-x-4 border-t border-gray-200 rounded-b dark:border-gray-600"
        >
          <button
            type="button"
            @click="toApprove()"
            class="text-white bg-105005 font-semibold text-sm px-3 py-1 text-center border h-8 rounded"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4 inline"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
            Approved
          </button>
          <button
            @click="closeModal"
            type="button"
            class="text-gray-400 text-sm font-semibold px-3 py-1 border rounded"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
export default {
  name: "UserToVerifyModal",
  props: ["user_account"],
  data() {
    return {
      id: null,
      fname: null,
      mname: null,
      lname: null,
      lvlstudy: null,
      school_name: null,
      school_add: null,
      office: null,
      pos: null,
      designation: null,
      email: null,
      roles: null,
      roleid: null,
      institutes: [],
      instituteID: 1,
      isAdminVerified: null,
    };
  },
  //put database items into input box
  mounted() {
    this.id = this.user_account.userdetail.id;
    this.fname = this.user_account.userdetail.fname;
    this.mname = this.user_account.userdetail.mname;
    this.lname = this.user_account.userdetail.lname;
    this.lvlstudy = this.user_account.userdetail.educ_qual;
    this.school_name = this.user_account.userdetail.name_of_school;
    this.school_add = null;
    this.office = this.user_account.userdetail.office;
    this.pos = this.user_account.userdetail.position;
    this.designation = this.user_account.userdetail.designation;
    this.email = this.user_account.email;
    this.roleid = this.user_account.userdetail.role.id;
    this.isAdminVerified = this.user_account.userdetail.isAdminVerified;
    document.getElementById("approveModal").classList.add("modal_open");
    document.getElementById("approveModal").classList.remove("modal_close");
    this.getRoles();
    this.getInstitutes();
  },
  methods: {
    //   close modal
    closeModal() {
      const bgModal = document.getElementById("approveModal");
      bgModal.classList.remove("modal_open");
      bgModal.classList.add("modal_close");
      setTimeout(() => {
        this.$emit("closeModal");
        bgModal.classList.remove("modal_close");
        bgModal.classList.add("modal_open");
      }, 500);
      // this.$emit("closeModal");
    },

    // load all role types
    getRoles() {
      axios
        .get(
          process.env.VUE_APP_API_SERVER + "/users-controller/roles/getRoles",
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            this.roles = response.data;
          },
          (error) => {
            console.log(error);
          }
        );
    },

    getInstitutes() {
      axios
        .get(process.env.VUE_APP_API_SERVER + "/institutes", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            this.institutes = response.data;
          },
          (error) => {
            console.log(error);
          }
        );
    },

    // handles onchange event to dropdown of roles
    roleChange() {},

    // to approve user
    toApprove() {
      const toast = useToast();
      const fd = {
        id: this.id,
        roleid: this.roleid,
        instituteID: this.instituteID,
      };
      axios
        .post(
          process.env.VUE_APP_API_SERVER + "/users-controller/approveUser",
          fd,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            // console.log(response);
            if (response.data.status == 200) {
              document
                .getElementById("approveModal")
                .classList.add("modal_close");
              document
                .getElementById("approveModal")
                .classList.remove("modal_open");
              setTimeout(() => this.closeModal(), 500);
              this.$emit("updatedUser"); //function in userverify layout
              toast.success(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            } else if (response.data.status == 400) {
              toast.error(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },

  computed: {
    isDeanOrProgChair() {
      return (
        this.roleid != 1 &&
        this.roleid != 4 &&
        this.roleid != 5 &&
        this.roleid != 6 &&
        this.roleid != 7
      );
    },
  },
};
</script>

<style></style>