<template>
  <div id="defaultModal" tabindex="-1" aria-hidden="true"
    class=" bgModal fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-screen lg:h-screen xl:h-screen 2xl:h-screen justify-center items-center ">
    <div class="relative mt-10 m-auto w-full h-full max-w-xl p-4 md:h-auto">
      <div id="div"
        class=" modal relative bg-white overflow-hidden h-auto rounded-lg shadow dark:bg-gray-700 animated modal_open ">
        <!-- modal header -->
        <div class="flex justify-between border-b py-4 px-4 bgColor">
          <h1 class="text-white text-sm pl-2">
            {{ action == "Add" ? "Add new category" : "Update category" }}
          </h1>
          <button type="button" @click="closeModal"
            class=" absolute top-2.5 right-2.5 text-white bg-transparent rounded-lg text-sm p-1 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white ">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"></path>
            </svg>
          </button>
        </div>
        <!-- modal content -->
        <div class="h-auto">
          <div class="pt-3 px-5" v-if="action == 'Add'">
            <label for="small-input" class=" block mb-2 text-xs font-semibold text-gray-600 dark:text-gray-300 ">Category
              title</label>
            <input v-model="category" type="text" placeholder="Input category title.." id="category"
              @keyup="hasChanged($event)"
              class="mb-4 customIndent block py-2 w-full text-xs text-gray-600 font-poppins bg-gray-50 rounded-lg border border-gray-300 sm:text-xs focus:ring-green-300 focus:border-green-300 " />
          </div>
          <div class="pt-3 px-5" v-if="action == 'Update'">
            <label for="small-input" class=" block mb-2 text-xs font-semibold text-gray-600 dark:text-gray-300 ">Category
              title</label>
            <input v-model="category" type="text" placeholder="Input category title.." id="category1"
              @keyup="hasChanged1($event)"
              class="mb-4 customIndent block py-2 w-full text-xs text-gray-600 font-poppins bg-gray-50 rounded-lg border border-gray-300 sm:text-xs focus:ring-green-300 focus:border-green-300 " />
          </div>
        </div>
        <div class=" flex justify-end w-full mt-2 py-4 px-5 space-x-2 rounded-b dark:border-gray-600 border-t">
          <!-- Save -->
          <button v-if="action == 'Add'" disabled @click="save" type="button" id="addBtn"
            :class="category1 == true ? 'bgColor' : 'bg-gray-500'"
            class=" text-white font-medium rounded-md text-xs ml-3 px-3 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ">
            <div class="flex">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-4 h-4 inline mr-1" :class="isLoading ? 'hidden' : 'flex'">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
              Save
              <div class="btnloader h-4 w-4 ml-2" v-if="isLoading"></div>
            </div>
          </button>

          <!-- Update -->
          <button v-if="action == 'Update'" disabled @click="update" type="button" id="updateBtn"
            class=" text-white bg-gray-500 font-medium rounded-md text-xs ml-2 px-3 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ">
            <div class="flex">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-4 h-4 inline mr-1" :class="isLoading ? 'hidden' : 'flex'">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
              Update
              <div class="btnloader h-4 w-4 ml-2" v-if="isLoading"></div>
            </div>
          </button>

          <button @click="closeModal" type="button"
            class=" text-gray-500 bg-white rounded-md border border-gray-200 text-xs font-medium px-5 py-1.5 hover:text-gray-900 ">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
export default {
  name: "CategoryModal",
  props: ["action", "categoryItem"],
  data() {
    return {
      category: null,
      categoryID: null,
      isLoading: false,
      defAction: null,
      category1: null,
      defCategory: null
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    hasChanged(event) {
      let addBtn = document.getElementById('addBtn');
      if (event.target.id == 'category') {
        if (event.currentTarget.value.length == 0) {
          this.category1 = false;
        }
        if (event.currentTarget.value.length >= 1) {
          if (!event.currentTarget.value.trim()) {
            this.category1 = false;
          }
          else {
            addBtn.disabled = false;
            this.category1 = true;
          }
          
        }
      }
    },
    hasChanged1(event) {
      let updateBtn = document.getElementById('updateBtn');
      let defCategory = this.defCategory;
      if (event.target.id == 'category1') {
        if (event.currentTarget.value.length == String(defCategory).length) {
          if (event.currentTarget.value.trim()) {
            if (event.currentTarget.value != defCategory) {
              updateBtn.disabled = false;
              updateBtn.classList.remove('bg-gray-500');
              updateBtn.classList.add('bgColor');
            }
            else {
              updateBtn.disabled = true;
              updateBtn.classList.add('bg-gray-500');
              updateBtn.classList.remove('bgColor');
             }
          }
          else {
            updateBtn.disabled = true;
            updateBtn.classList.add('bg-gray-500');
            updateBtn.classList.remove('bgColor');
          }

          // if (!event.currentTarget.value.trim()) { 
          //   updateBtn.disabled = true;
          //   updateBtn.classList.add('bg-gray-500');
          //   updateBtn.classList.remove('bgColor');
          // }
          // else {
          //   if (event.currentTarget.value == String(defCategory)) {
          //     updateBtn.disabled = true;
          //     updateBtn.classList.add('bg-gray-500');
          //     updateBtn.classList.remove('bgColor');
          //   }
          //   else {
          //     updateBtn.disabled = false;
          //     updateBtn.classList.remove('bg-gray-500');
          //     updateBtn.classList.add('bgColor');
          //   }
          // }
        }
        if (event.currentTarget.value.length > String(defCategory).length || event.currentTarget.value.length < String(defCategory).length) {
          if (event.currentTarget.value.length == 0) {
            updateBtn.disabled = false;
            updateBtn.classList.add('bg-gray-500');
            updateBtn.classList.remove('bgColor');
          }
          else {
            if (!event.currentTarget.value.trim()) {
              updateBtn.disabled = true;
              updateBtn.classList.add('bg-gray-500');
              updateBtn.classList.remove('bgColor');
            }
            else {
              updateBtn.disabled = false;
              updateBtn.classList.remove('bg-gray-500');
              updateBtn.classList.add('bgColor');
            }
          }

        }
      }
    },
    init() {
      document.getElementById("div").classList.remove("modal_close");
      document.getElementById("div").classList.add("modal_open");
    },
    closeModal() {
      document.getElementById("div").classList.remove("modal_open");

      document.getElementById("div").classList.add("modal_close");

      setTimeout(() => this.$emit("closeModal"), 500);
    },

    save() {
      const fd = { categoryDesc: this.category };
      const toast = useToast();
      this.isLoading = true;
      if (this.category == null || this.category == "") {
        toast.error("Field is required.", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.isLoading = false;
      } else {
        axios
          .post(
            process.env.VUE_APP_API_SERVER +
            "/journalcategory/add-new-category",
            fd,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
              },
            }
          )
          .then(
            (response) => {
              if (response.data.status == 201) {
                this.$emit("success");

                toast.success(response.data.msg, {
                  position: "top-right",
                  timeout: 3000,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: false,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
              } else if (response.data.status == 400) {
                toast.error(response.data.msg, {
                  position: "top-right",
                  timeout: 3000,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: false,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
              }
              this.isLoading = false;
            },
            (error) => {
              console.log(error);
            }
          );
      }
    },

    update() {
      this.isLoading = true;
      const toast = useToast();

      if (this.category == null || this.category == "") {
        toast.error("Field is required.", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } else {
        const data = {
          categoryDesc: this.category,
        };

        axios
          .patch(
            process.env.VUE_APP_API_SERVER +
            "/journalcategory/" +
            this.categoryID,
            data,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
              },
            }
          )
          .then(
            (response) => {
              if (response.data.status == 200) {
                this.$emit("success");

                toast.success(response.data.msg, {
                  position: "top-right",
                  timeout: 3000,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: false,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
              } else if (response.data.status == 400) {
                toast.error(response.data.msg, {
                  position: "top-right",
                  timeout: 3000,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: false,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
      }
    },

    toUpdate(item) {
      let items = {
        journal: this.journal,
        remarkItem: item,
      };
      // console.log(items);
      this.$emit("toUpdate", items);
    },
  },

  watch: {
    categoryItem: {
      handler(val) {
        // console.log(val);
        if (val) {
          this.categoryID = val.id;
          this.category = val.categoryDesc;
          this.defCategory = val.categoryDesc;
        } else {
          this.categoryID = null;
          this.category = null;
        }
      },
      immediate: true,
    },
    action: {
      handler(val) {
        if (val) {
          this.defAction = val;
        }
      }
    }
  },
};
</script>

<style>
.customIndent {
  text-indent: 10px;
}
</style>