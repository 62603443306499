<template>
  <div class="w-auto">
    <div
      class="
        grid
        md:flex
        items-center
        sm:xl:justify-between
        md:justify-between
        xl:justify-between
        lg:justify-between
        bg-gray-100
        mb-2
        mt-2
        rounded-md
      "
    >
      <div class="w-1/2">
        <h1
          class="
            md:text-xl
            text-xs
            lg:text-xl
            font-semibold
            py-5
            pl-6
            text-gray-900
          "
        >
          Main Menu - User Logs
        </h1>
        <h1 class="text-sm font-normal ml-7 -mt-4 text-gray-500 mb-10">
          List of categories
        </h1>
      </div>
      <div
        class="
          hidden
          md:flex md:justify-end
          lg:flex lg:justify-end
          xl:flex xl:justify-end
          sm:hidden
          mr-3
          -mt-6
          overflow-hidden
        "
      >
        <div class="relative sm:w-60 md:w-96 px-4">
          <input
            type="text"
            v-model="searchval"
            @keypress.enter="searchBtn"
            class="
              block
              py-1
              px-2
              w-full
              z-20
              text-sm text-gray-600
              bg-gray-50
              rounded-lg
              border-l-gray-50 border-l-2 border border-gray-300
              focus:ring-green-500 focus:border-green-500
            "
            placeholder="Search by anything"
            required
          />
          <button
            @click="searchBtn"
            type="submit"
            class="
              absolute
              top-0
              right-0
              py-1
              px-2
              text-sm
              font-medium
              text-white
              bg-customGreen
              rounded-tr-lg rounded-br-lg
              border
              focus:ring-4 focus:outline-none focus:ring-blue-300
            "
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
            <span class="sr-only">Search</span>
          </button>
        </div>
      </div>
    </div>
    <!-- main content -->
    <div class="w-auto">
      <LogsTable />
    </div>
  </div>
</template>

<script>
import LogsTable from "../components/LogsTable.vue";

export default {
  name: "JournalCategory",
  components: {
    LogsTable,
  },
  data() {
    return {
      isOpenModal: false,
      modalAction: "",
      itemToView: null,
      refreshTable: false,
      searchval: null
    };
  },
  created() {},

  methods: {
    searchBtn() {
      if (this.searchval == null || this.searchval == "") {
        return;
      } else {
        this.$router.push({
          name: "UserSearch",
          params: { data_text: this.searchval },
        });
      }
    },
    showModal(action) {
      if (action == "Add") {
        this.itemToView = null;
        this.modalAction = "Add";
      } else if (action == "Update") {
        this.modalAction = "Update";
      }

      this.isOpenModal = true;
    },
    ViewCategory(item) {
      this.itemToView = item;
    },

    Success() {
      // console.log("triger  success");
      this.refreshTable = true;
      setTimeout(() => (this.refreshTable = false), 300);
      this.isOpenModal = false;
    },

    closeModal() {
      this.isOpenModal = false;
    },
  },
};
</script>

<style scoped>
.customSearch {
  width: 20rem;
}
</style>