<template>
  <div class="w-full">
    <div class="journal-body py-16 px-10 w-full">
      <div class="flex mt-20 -mb-10 justify-center" v-if="!isDefault">
        <div class="grid grid-cols-1 md:w-4/12 w-full">
          <div class="mb-4 font-semibold text-white">Search results for:</div>
          <div class="w-full">
            <div class="relative block w-full sm:w-1/2 md:w-full md:mr-0">
            <span class=" absolute w-auto inset-y-0 left-3 flex text-gray-500 items-center ">
              <svg class="h-5 w-5 fill-current" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30"
                viewBox="0 0 30 30">
                <path
                  d="M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z">
                </path>
              </svg>
            </span>
            <input type="search" v-model="defaultSearchInp2" @keypress.enter="submitSearch2" class=" pl-10 block py-2 px-2 w-full z-20 text-sm text-gray-600 bg-gray-50 rounded-full outline-none border-l-gray-50 border-l-2 border border-gray-300 focus:ring-green-500 focus:border-green-500 " placeholder="Search for Title, Author, or Keywords..." required />
            <button @click="submitSearch2" type="submit" class=" absolute top-0 right-0 py-2.5 px-1 text-xs font-semibold bg-FFB800 rounded-full text-black md:w-1/5 w-1/4 border focus:ring-4 focus:outline-none focus:ring-blue-300
                ">
              SEARCH
            </button>
          </div>
          </div>
        </div>
        <!-- <div> <h1 class="hidden text-white text-2xl pr-5 lg:block md:hidden sm:hidden"> Search results for: </h1> </div> --> <!-- <div class="md:w-96 lg:w-96 w-1/2"> <div class="input-group flex flex-wrap w-auto mb-4"> <input v-model="defaultSearchInp" @keypress.enter="submitSearch" type="search" class=" form-control relative flex-auto min-w-0 block w-1/2 px-3 py-1 text-sm font-normal text-gray-700 border rounded-md bg-white bg-clip-padding transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none " placeholder="Search" aria-label="Search" aria-describedby="button-addon2" />` </div> </div> --> <!-- <div class="relative block w-full sm:w-1/2 md:w-1/2 px-4 mr-4 md:mr-0"> <span class=" absolute w-auto inset-y-0 left-5 flex text-gray-500 items-center pl-1 pr-2 "> <svg class="h-5 w-5 fill-current" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 30 30"> <path d="M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z"> </path> </svg> </span> <input type="search" v-model="defaultSearchInp" @keypress.enter="submitSearch" class=" pl-10 block py-1 px-2 w-full z-20 text-sm text-gray-600 bg-gray-50 rounded-full outline-none border-l-gray-50 border-l-2 border border-gray-300 focus:ring-green-500 focus:border-green-500 " placeholder="Search for Title, Author, or Keywords..." required /> <button @click="submitSearch" type="submit" class=" absolute top-0 right-0 py-1.5 px-1 text-xs font-semibold bg-FFB800 rounded-full text-black md:w-1/5 w-1/5 border focus:ring-4 focus:outline-none focus:ring-blue-300 "> SEARCH </button> </div> -->
      </div>
    </div>
    <div class="mx-4 mb-56" v-if="isDefault">
      <div class="text-center customTransform">
        <h1 class="text-green-800 text-6xl font-semibold">Research Database</h1>
        <h1 class="text-FFB800 text-6xl font-semibold -mt-2">
          Management System
        </h1>

        <!--<div class="flex justify-center mt-8">
           <div class="mb-3 xl:w-96">
            <div class="input-group relative flex flex-wrap w-full mb-4">
              <label class="relative block">
                <span
                  class="
                    absolute
                    inset-y-0
                    left-0
                    flex
                    text-gray-500
                    items-center
                    pl-2
                    pr-2
                  "
                >
                  <svg
                    class="h-5 w-5 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                  >
                    <path
                      d="M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z"
                    ></path>
                  </svg>
                </span>
                <input
                  v-model="defaultSearchInp"
                  @keypress.enter="submitSearch"
                  class="
                    text-gray-500
                    bg-gray-100
                    customSearchWidth
                    placeholder:font-italitc
                    border border-gray-200
                    rounded-full
                    text-sm
                    py-3
                    pl-10
                    pr-4
                  "
                  placeholder="Enter your keyword to search"
                  type="text"
                />
              </label>
            </div>
          </div> 
          <div class="relative block w-full sm:w-1/2 md:w-1/2 px-4 mr-4 md:mr-0">
            <span class="
                  absolute
                  w-auto
                  inset-y-0
                  left-5
                  flex
                  text-gray-500
                  items-center
                  pl-1
                  pr-2
                ">
              <svg class="h-5 w-5 fill-current" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30"
                viewBox="0 0 30 30">
                <path
                  d="M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z">
                </path>
              </svg>
            </span>
            <input type="search" v-model="defaultSearchInp" @keypress.enter="submitSearch" class=" pl-10 block py-3 px-2 w-full z-20 text-sm text-gray-600 bg-gray-50 rounded-full outline-none border-l-gray-50 border-l-2 border border-gray-300 focus:ring-green-600 focus:border-green-600 " placeholder="Search for Title, Author, or Keywords..." required />
            <button @click="submitSearch" type="submit" class=" absolute top-0 right-0 py-3.5 px-2 text-xs font-semibold bg-FFB800 rounded-full text-black md:w-1/5 w-1/5 border focus:ring-4 focus:outline-none focus:ring-blue-300 ">
              SEARCH
            </button>
          </div>
        </div>-->
        <div class="flex justify-center md:w-1/2 mx-auto mt-8">

              <div class=" relative block w-full sm:w-full md:w-full px-3 mr-4 md:mr-0 " >
                <span class=" absolute w-auto inset-y-0 left-5 flex text-gray-500 items-center pl-3 pr-2 " >
                  <svg class="h-5 w-5 fill-current" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 30 30" >
                    <path d="M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z" ></path>
                  </svg>
                </span>
                <input type="text" v-model="defaultSearchInp1" @keypress.enter="submitSearch1" class="outline-none pl-10 block py-3.5 px-2 w-full z-20 text-sm text-gray-600 bg-gray-50 rounded-full border-l-gray-50 border-l-2 border border-gray-300 focus:ring-green-600 focus:border-green-600 " placeholder="Search for Title, Author, or Keywords..." required />
                <button @click="submitSearch1" type="submit" class=" absolute top-0 right-0 py-3.5 px-2 text-xs mt-0.5 mr-3.5 font-semibold bg-FFB800 rounded-full text-black md:w-1/5 w-1/4 border focus:ring-2 focus:outline-none focus:ring-FFB800" >
                  SEARCH 
                </button>
              </div>
            </div>
      </div>
    </div>

    <div v-if="!isDefault">
      <PreviewJournalResults :searchval="searchvalue" :isLogged="false" v-on:viewJournal="ViewJournal" v-if="!view_journal"
          :journals="journalData" />
      <!-- <PreviewJournalTable :searchval="searchvalue" v-on:viewJournal="ViewJournal" v-if="!view_journal"
        :journals="journalData" /> -->
      <JournalView v-if="view_journal" :isLogged="false" v-on:back="ViewJournalTable" :journalData="journalItem" />
    </div>
    <div class="container1" v-if="!isDefault">
      <LandingFooter />
    </div>

    <div class="container2" v-if="isDefault">
        <LandingFooter />
      </div>

  </div>
</template>

<script>
import PreviewJournalTable from "../components/PreviewJournalTable.vue";
import JournalView from "./JournalView.vue";
import LandingFooter from "./LandingFooter.vue";
import PreviewJournalResults from './PreviewJournalResults.vue';

export default {
  name: "Journals",
  components: {
    PreviewJournalTable,
    PreviewJournalResults,
    JournalView,
    LandingFooter
  },
  props: ["searchval"],
  data() {
    return {
      view_journal: false,
      journalData: null,
      journalItem: null,
      isDefault: true,
      searchvalue: "",
      defaultSearchInp1: null,
      defaultSearchInp2: null,
    };
  },
  created() {
    if (this.$route.params.data) {
      // console.log(this.$route.params.data);
      this.searchvalue = this.$route.params.data;
      this.defaultSearchInp2 = this.$route.params.data;
      this.isDefault = false;
    } else {
      this.defaultSearchInp2 = null;
      this.isDefault = true;
    }
  },
  methods: {
    ViewJournal(item) {
      this.journalItem = item;
      this.view_journal = true;
    },
    ViewJournalTable() {
      this.view_journal = false;
    },
    searchJournal() {
      if (this.searchvalue == null || this.searchvalue == "") {
        this.isDefault = true;
        return;
      } else {
        this.isDefault = false;
      }
    },

    // handles searching
    submitSearch1() {
      if (this.isDefault) {
        this.isDefault = false;
        this.searchvalue = this.defaultSearchInp1;
        this.defaultSearchInp2 = this.defaultSearchInp1;
      }
      // if (e.target.value == "") {
        
      //   // if (this.defaultSearchInp == null || this.defaultSearchInp == "") {
      //     return;
      //   // }
      //   // else {
      //   //   this.isDefault = false;
      //   //   this.searchvalue = this.defaultSearchInp;
      //   //   this.defaultSearchInp = this.defaultSearchInp;
      //   // }
      // } else {
      //   console.log(e.target);
      //   this.isDefault = false;
      //   this.searchvalue = e.target.value;
      //   this.defaultSearchInp = e.target.value;
      //   // this.defaultSearchInp = null;
      // }
    },

    submitSearch2() {
      if (!this.isDefault) {
        this.isDefault = false;
        this.searchvalue = this.defaultSearchInp2;
      }
    },
  },
};
</script>

<style scoped>
.journal-body {
  background-image: url("../assets/bg/b2.png");
  /* background: url("../assets/bg/Fast_loading-amico_1.png"); */
  background-size: cover;
  background-repeat: no-repeat;
}

.customSearchWidth {
  width: 25rem;
}

.customTransform {
  transform: translate(0%, 50%);
}

.container1 {
  /* background: url("../assets/bg/Fast_loading-amico_1.png"); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom right;
}

.container2 {
  /* background: url("../assets/bg/Fast_loading-amico_1.png"); */
  /* margin-top: 9rem; */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom right;
}

</style>