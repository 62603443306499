<script>
import { defineComponent } from "vue";
import { Doughnut } from "vue3-chart-v2";

export default defineComponent({
  name: "DoughnutChart",
  extends: Doughnut,
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      required: false,
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.chartOptions);
  },
  methods: {
    renderDoughnutChart() {
      this.renderChart(this.chartData, this.chartOptions);
    },
  },
  watch: {
    chartData() {
      this.$nextTick(() => {
        this.renderDoughnutChart();
      });
    },
  },
});
</script>