<template>
  <div class="w-auto h-auto pb-12 mx-3">
    <!-- <div class=" filter_by w-auto md:w-auto overflow-hidden bg-gray-50 lg:h-auto md:h-auto py-2 rounded-md ">
      <div class="grid w-full md:flex-wrap md:flex lg:flex lg:flex-wrap px-10">
        <div class="grid text-left mx-4 my-2">
          <h4 class="text-gray-500 font-semibold text-xs md:text-xs">
            Research Title
          </h4>
          <input type="search" class=" indent flex-auto py-1 block text-xs font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded md:rounded-md lg:rounded-md transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none " placeholder="Search for title" /> 
        </div>
        <div class="grid text-left mx-4 my-2">
          <h4 class="text-gray-500 font-semibold text-xs md:text-xs">
            Date Published
          </h4>
          <div class="grid md:flex lg:flex">
            <input type="text" class="
                indent
                flex-auto
                block
                py-1
                text-xs
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-gray-300
                rounded
                md:rounded-l-md
                lg:rounded-l-md
                transition
                ease-in-out
                m-0
                focus:text-gray-700
                focus:bg-white
                focus:border-blue-600
                focus:outline-none
              " placeholder="From mm-dd-yyyy" />
            <input type="text" class="
                indent
                flex-auto
                block
                py-1
                text-xs
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-gray-300
                rounded
                md:rounded-r-md
                lg:rounded-r-md
                transition
                ease-in-out
                m-0
                focus:text-gray-700
                focus:bg-white
                focus:border-blue-600
                focus:outline-none
              " placeholder="From mm-dd-yyyy" />
          </div>
        </div>
        <div class="grid text-left mx-4 my-2">
          <h4 class="text-gray-500 font-semibold text-xs md:text-xs">
            Area Published
          </h4>

          <input type="search" class="
              indent
              flex-auto
              py-1
              block
              text-xs
              font-normal
              text-gray-700
              bg-white bg-clip-padding
              border border-solid border-gray-300
              rounded
              md:rounded-md
              lg:rounded-md
              transition
              ease-in-out
              m-0
              focus:text-gray-700
              focus:bg-white
              focus:border-blue-600
              focus:outline-none
            " placeholder="Search for by area" />
        </div>
        <div class="grid text-left mx-4 my-2">
          <h4 class="text-gray-500 font-semibold text-xs md:text-xs">Author</h4>
          <input type="search" class="
              indent
              flex-auto
              block
              text-xs
              py-1
              font-normal
              text-gray-700
              bg-white bg-clip-padding
              border border-solid border-gray-300
              rounded
              md:rounded-md
              lg:rounded-md
              transition
              ease-in-out
              m-0
              focus:text-gray-700
              focus:bg-white
              focus:border-blue-600
              focus:outline-none
            " placeholder="Search for an author" />
        </div>
      </div>
    </div> -->

    <div v-if="isLoading" class="flex justify-center py-8">
      <div class="spinLoader"></div>
    </div>

    <div class="journal-tbl rounded-lg bg-white w-auto m-auto mt-4 pb-4" v-if="!isLoading">
      <div class="flex flex-col">
        <div class="overflow-x-auto">
          <div class="inline-block min-w-full px-3">
            <div class="overflow-hidden">
              <!-- <table class="table-fixed w-11/12 mx-auto mt-8"> <tr class=""> <th v-for="item in table_headers" :key="item" class=" bg-customGreen text-xs font-medium text-white px-1 border sm:border-none md:border-none lg:border-none xl:border-none sm:px-6 md:px-6 lg:px-6 xl:px-6 py-3 text-left " > {{ item.title }} </th> </tr> <tbody> <tr v-if="results == 0"> <td colspan="5" class=" text-center text-gray-500 text-xs py-1 italic border-b border-l border-r border-gray-400 " > No data matched </td> </tr> <tr v-else class=" bg-white border-b border-l border-r border-gray-400 transition duration-300 ease-in-out " v-for="item in displayedJournals" :key="item" > <td class=" px-1 sm:px-6 md:px-6 lg:px-6 xl:px-6 py-1 text-xs font-medium text-gray-600 break-all whitespace-normal " width="20%" > {{ item.title }} </td> <td class=" text-xs text-gray-600 text-center font-light px-1 sm:px-6 md:px-6 lg:px-6 xl:px-6 py-1 whitespace-nowrap " width="20%" > {{ formatDate( item.publishedDateMonth, item.publishedDateYear ) }} </td> <td class=" text-xs text-gray-600 font-light px-1 sm:px-6 md:px-6 lg:px-6 xl:px-6 py-1 break-all whitespace-normal " width="20%" > {{ item.publishedIn }} </td> <td class=" text-xs text-gray-600 font-light px-1 sm:px-6 md:px-6 lg:px-6 xl:px-6 py-1 break-all whitespace-normal " width="20%" > {{ item.mainauthorlname ? item.mainauthorlname + ", " : "" }} {{ item.mainauthorfname }} {{ item.mainauthormname ? item.mainauthormname[0] + "." : "" }} </td> <td class=" text-xs text-gray-600 font-light px-1 sm:px-6 md:px-6 lg:px-6 xl:px-6 py-1 text-center whitespace-nowrap " width="20%" > <button @click="viewJournal(item)" class=" bg-white hover:bg-yellow-500 hover:text-white text-yellow-500 font-semibold py-1 px-3 text-xs border border-yellow-500 rounded shadow " > View </button> </td> </tr> </tbody> </table> -->
              <Table :labels="table_headers" :data="displayedJournals">
                <template v-slot:publishedDate="{ item }">
                  {{
                    formatDate(item.publishedDateMonth, item.publishedDateYear)
                  }}
                </template>
                <template v-slot:author="{ item }">
                  {{ item.mainauthorlname ? item.mainauthorlname + ", " : "" }}
                  {{ item.mainauthorfname }}
                  {{
                    item.mainauthormname ? item.mainauthormname[0] + "." : ""
                  }}
                </template>
                <template v-slot:action="{ item }">
                  <button @click="viewJournal(item)" class="
                      bg-white
                      hover:bg-yellow-500 hover:text-white
                      text-yellow-500
                      font-semibold
                      py-1
                      px-3
                      text-xs
                      border border-yellow-500
                      rounded
                      shadow
                    ">
                    View
                  </button>
                </template>
              </Table>
              <div class=" grid font-poppins w-full sm:flex md:flex lg:flex flex-row justify-end md:w-full sm:w-full mx-auto items-center border-l border-b border-r border-gray-400 rounded-bl-lg rounded-br-lg pt-4 ">
                <div class=" w-auto sm:w-3/6 md:w-3/6 lg:w-3/6 xl:w-3/6 gap-x-4 mx-3 flex ">
                  <div class=" text-xs text-gray-600 flex w-1/3 md:w-2/5 items-center ">
                    <label for="" class="w-2/3">Rows per page</label>&nbsp;
                    <select class="w-1/3 border-gray-600 border-b" v-model="perPage">
                      <option :value="item" v-for="item in selectPerPage" :key="item">
                        {{ item }}
                      </option>
                    </select>
                  </div>

                  <div class="flex w-1/3 md:w-2/5">
                    <!-- <div class="flex" v-if="results != 0"> -->
                    <span class="text-xs m-auto text-gray-600 dark:text-gray-400">
                      Showing
                      <span class="font-semibold text-gray-900 dark:text-white">{{ fromPage }}</span>
                      to
                      <span class="font-semibold text-gray-900 dark:text-white">{{ toPage }}</span>
                      of
                      <span class="font-semibold text-gray-900 dark:text-white">{{ results }}</span>
                      Entries
                    </span>
                  </div>

                  <nav class="w-1/3 md:w-1/5">
                    <ul class="flex md:justify-end">
                      <li class="page-item mx-1">
                        <button type="button" class="
                            page-link
                            bg-customGreen
                            text-white
                            rounded-md
                            p-1
                            text-xs
                          " v-if="page > 1" @click="page--">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path fill-rule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clip-rule="evenodd" />
                          </svg>
                        </button>

                        <!--for the disabled button -->
                        <button type="button" class="
                            page-link
                            bg-customGreen
                            rounded-md
                            text-white
                            p-1
                            text-xs
                          " v-if="page == 1" disabled>
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path fill-rule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clip-rule="evenodd" />
                          </svg>
                        </button>
                      </li>

                      <li class="page-item mx-1">
                        <button type="button" @click="page++" v-if="page < pages.length" class="
                            page-link
                            bg-customGreen
                            rounded-md
                            text-white
                            p-1
                            text-xs
                          ">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path fill-rule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clip-rule="evenodd" />
                          </svg>
                        </button>

                        <!--for the disabled button -->
                        <button type="button" disabled v-if="page >= pages.length" class="
                            page-link
                            bg-customGreen
                            rounded-md
                            text-white
                            p-1
                            text-xs
                          ">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path fill-rule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clip-rule="evenodd" />
                          </svg>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Table from "../components/Table.vue";
export default {
  name: "JournalTable",
  props: ["searchval"],
  components: { Table },
  data() {
    return {
      isLogged: true,
      journalData: [],
      isLoading: false,
      contents: [],
      page: 1,
      perPage: 10,
      selectPerPage: [10, 15, 20, 30, 50],
      pages: [],
      fromPage: 1,
      toPage: null,
      results: null,
      searchkey: this.searchval,
      table_headers: [
        { text: "Research Title", field: "title" },
        { text: "Date Published", field: "publishedDate" },
        { text: "Area Published", field: "publishedIn" },
        { text: "Author", field: "author" },
        { text: "Action", field: "action" },
      ],
    };
  },

  methods: {
    init() {
      this.contents = [];
      this.page = 1;
      this.perPage = 10;
      this.pages = [];
      this.fromPage = 1;
      this.toPage = null;
      this.results = null;
    },

    //load approved journals
    async getJournals(search) {
      if (search == null || search == "") {
        this.contents = [];
        this.results = 0;
        this.isLoading = false;
        return false;
      } else {
        axios
          .get(process.env.VUE_APP_API_SERVER + "/fileupload/preview/" + search)
          .then(
            (response) => {
              this.contents = response.data;
              this.results = response.data.length;
              this.isLoading = false;
              // console.log(this.contents);
            },
            (error) => {
              // console.log(error);
            }
          );
      }
    },

    viewJournal(data) {
      // console.log(item);
      this.$emit("viewJournal", data);
    },

    // format date to display
    formatDate(date, year) {
      var mnthArr = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      if (date == null || date == "") {
        return year;
      } else {
        return mnthArr[date] + " " + year;
      }
    },

    // set number of pages to paginate
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(
        parseInt(this.contents.length) / this.perPage
      );
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    // handles the from and to of pagination table
    paginate(contents) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;

      if (to > this.contents.length) {
        this.fromPage = from + 1;
        this.toPage = this.contents.length;
      } else {
        this.fromPage = from + 1;
        this.toPage = to;
      }

      return contents.slice(from, to);
    },
  },
  computed: {
    displayedJournals() {
      return this.paginate(this.contents);
    },
  },
  watch: {
    contents() {
      this.setPages();
    },

    perPage() {
      this.page = 1;
      this.setPages();
    },
    searchval: {
      handler(value) {
        this.getJournals(value);
        this.setPages();
      },
      immediate: true,
    },
  },
  created() {
    if (this.searchkey) {
      this.getJournals(this.searchkey);
      this.setPages();
    }
  },
};
</script>

<style scoped>
.tbl_header {
  background-color: #105005;
}

.indent {
  text-indent: 0.5rem;
}

/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>