<template>
  <div
    id="defaultModal"
    tabindex="-1"
    aria-hidden="true"
    class="bgModal fixed top-0 left-0 right-0 z-50 w-full h-screen md:inset-0 h-modal md:h-full justify-center items-center"
  >
    <div class="relative mt-10 m-auto w-full h-screen max-w-2xl p-4 md:h-auto">
      <div
        id="divModal"
        class="modal relative bg-white rounded-lg shadow dark:bg-gray-700 animated"
      >
        <!-- modal header -->
        <div class="flex justify-between py-2 px-2 border-b">
          <h1 class="text-gray-800 text-sm p-5"></h1>
          <button
            type="button"
            @click="closeModal"
            class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="authentication-modal"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- modal content -->
        <div class="grid items-center overflow-y-auto h-{2/3}">
          <div class="text-xs">
            <ul class="divide-y divide-gray-200 dark:divide-gray-700">
              <li
                class="pb-3 pt-3 sm:pb-4 cursor-pointer"
                v-for="items in data"
                :key="items"
                @click="selectAll(items.data)"
              >
                <div class="flex items-center space-x-4 px-5">
                  <div class="flex-1 min-w-0">
                    <p
                      class="text-xs font-medium text-gray-400 truncate dark:text-white"
                    >
                      {{ items.name }}
                    </p>
                    <p
                      class="text-xs text-gray-900 text-justify dark:text-gray-400 w-full"
                    >
                      {{ items.data }}
                    </p>
                  </div>
                </div>
              </li>
              <li v-if="data.length == 0">
                <div class="flex items-center space-x-4">
                  <div class="flex-1 min-w-0 my-2 px-2">
                    <p
                      class="text-xs font-medium py-3 text-gray-400 truncate dark:text-white text-center p-2 rounded disabled bg-gray-100"
                    >
                      Invalid DOI, can't capture citation...
                    </p>
                  </div>
                </div>
              </li>
              <!-- <li class="pb-3 pt-3 sm:pb-4">
                                <div class="flex items-center space-x-4 px-5">
                                    <div class="flex-1 min-w-0">
                                        <p class="text-sm font-medium text-gray-400 truncate dark:text-white">
                                            Vancouver
                                        </p>
                                        <p class="text-sm text-gray-900 text-justify dark:text-gray-400 w-full">
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut nulla architecto
                                            incidunt aspernatur, itaque iusto totam beatae labore voluptatum quasi. Sunt
                                            laborum facilis quas veniam provident, reiciendis autem recusandae blanditiis.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li class="pb-3 pt-3 sm:pb-4">
                                <div class="flex items-center space-x-4 px-5">
                                    <div class="flex-1 min-w-0">
                                        <p class="text-sm font-medium text-gray-400 truncate dark:text-white">
                                            Harvard
                                        </p>
                                        <p class="text-sm text-gray-900 text-justify dark:text-gray-400 w-full">
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut nulla architecto
                                            incidunt aspernatur, itaque iusto totam beatae labore voluptatum quasi. Sunt
                                            laborum facilis quas veniam provident, reiciendis autem recusandae blanditiis.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li class="pb-3 pt-3 sm:pb-4">
                                <div class="flex items-center space-x-4 px-5">
                                    <div class="flex-1 min-w-0">
                                        <p class="text-sm font-medium text-gray-400 truncate dark:text-white">
                                            Chicago
                                        </p>
                                        <p class="text-sm text-gray-900 text-justify dark:text-gray-400 w-full">
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut nulla architecto
                                            incidunt aspernatur, itaque iusto totam beatae labore voluptatum quasi. Sunt
                                            laborum facilis quas veniam provident, reiciendis autem recusandae blanditiis.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li class="pb-3 pt-3 sm:pb-4">
                                <div class="flex items-center space-x-4 px-5">
                                    <div class="flex-1 min-w-0">
                                        <p class="text-sm font-medium text-gray-400 truncate dark:text-white">
                                            IEEE
                                        </p>
                                        <p class="text-sm text-gray-900 text-justify dark:text-gray-400 w-full">
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut nulla architecto
                                            incidunt aspernatur, itaque iusto totam beatae labore voluptatum quasi. Sunt
                                            laborum facilis quas veniam provident, reiciendis autem recusandae blanditiis.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li class="pb-3 pt-3 sm:pb-4">
                                <div class="flex items-center space-x-4 px-5">
                                    <div class="flex-1 min-w-0">
                                        <p class="text-sm font-medium text-gray-400 truncate dark:text-white">
                                            MLA
                                        </p>
                                        <p class="text-sm text-gray-900 text-justify dark:text-gray-400 w-full">
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut nulla architecto
                                            incidunt aspernatur, itaque iusto totam beatae labore voluptatum quasi. Sunt
                                            laborum facilis quas veniam provident, reiciendis autem recusandae blanditiis.
                                        </p>
                                    </div>
                                </div>
                            </li> -->
            </ul>
          </div>
          <!-- <div class="m-auto px-2 py-5">
                        <img src="../assets/bg/Ok-face-bro.png" class="w-auto h-auto" alt="" />
                    </div>
                    <p class="m-auto pb-5 text-sm text-gray-800 font-semibold">
                        Cite
                        Please
                        <span class="text-blue-500 mycursor hover:underline" @click="redirect('login')">Login</span>
                        or
                        <span class="text-blue-500 mycursor hover:underline" @click="redirect('register')">Register</span>
                        to continue.
                    </p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cite from "citation-js";
import axios from "axios";
import { useToast } from "vue-toastification";
import copy from "copy-to-clipboard";
export default {
  name: "PeaseLoginModal",
  props: ["citationData", "isLogged", "seeSaved"],
  data() {
    return {
      citations: null,
      data: [],
      dataName: [],
    };
  },
  mounted() {
    document.getElementById("divModal").classList.add("modal_open");
    document.getElementById("divModal").classList.remove("modal_close");
    this.showCite();
  },
  watch: {
    citationData: {
      handler(value) {
        this.citations = value;
        // console.log(value);
      },
      immediate: true,
    },
  },

  methods: {
    closeModal() {
      document.getElementById("divModal").classList.remove("modal_open");
      document.getElementById("divModal").classList.add("modal_close");
      setTimeout(() => this.$emit("closeModal"), 400);
    },
    redirect(loc) {
      this.$emit("redirect", loc);
    },
    selectAll(val) {
      const toast = useToast();
      // navigator.clipboard
      //     .writeText(val)
      //     .then(() => {
      try {
        copy(val, {
          debug: true,
          message: val.toString,
        });

        axios
          .get(
            process.env.VUE_APP_API_SERVER +
              "/journalcategory/addNewCitedCount/" +
              this.citations.id
          )
          .then(
            (response) => {
              if (response.data.status == 200 || response.data.status == 201) {
                if (!this.isLogged) {
                  this.$emit("refreshData");
                  toast.success("Citation successfully copied to clipboard!", {
                    position: "top-right",
                    timeout: 3000,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: false,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: false,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                  });
                } else {
                  this.$emit("refreshData");
                  toast.success("Citation successfully copied to clipboard!", {
                    position: "top-right",
                    timeout: 3000,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: false,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: false,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                  });
                }
              }
            },
            (error) => {
              console.log(error);
            }
          );
      } catch {
        console.log("Something went wrong!");
      }

      // })
    },
    async showCite() {
      // console.log(this.seeSaved);
      if (this.seeSaved) {
        try {
          const e1 = new Cite(this.citations.doi);
          const o1 = e1.format("bibliography", {
            type: "string",
            lang: "en-US",
            template: "apa",
          });
          this.data.push({ name: "APA 7th Edition", data: o1 });

          const o2 = e1.format("bibliography", {
            type: "string",
            lang: "en-US",
            template: "vancouver",
          });
          this.data.push({ name: "Vancouver", data: o2 });

          const o3 = e1.format("bibliography", {
            type: "string",
            lang: "en-US",
            template: "harvard1",
          });
          this.data.push({ name: "Harvard", data: o3 });

          const o4 = e1.format("bibliography", {
            type: "string",
            lang: "en-US",
            template: "chicago",
          });
          this.data.push({ name: "Chicago", data: o4 });

          const o5 = e1.format("bibliography", {
            type: "string",
            lang: "en-US",
            template: "ieee",
          });
          this.data.push({ name: "IEEE", data: o5 });

          const o6 = e1.format("bibliography", {
            type: "string",
            lang: "en-US",
            template: "mla",
          });
          this.data.push({ name: "MLA", data: o6 });
        } catch (error) {
          this.data = [];
        }
      } else if (!this.seeSaved) {
        try {
          const e1 = new Cite(this.citations.doi);
          const o1 = e1.format("bibliography", {
            type: "string",
            lang: "en-US",
            template: "apa",
          });
          this.data.push({ name: "APA 7th Edition", data: o1 });

          const o2 = e1.format("bibliography", {
            type: "string",
            lang: "en-US",
            template: "vancouver",
          });
          this.data.push({ name: "Vancouver", data: o2 });

          const o3 = e1.format("bibliography", {
            type: "string",
            lang: "en-US",
            template: "harvard1",
          });
          this.data.push({ name: "Harvard", data: o3 });

          const o4 = e1.format("bibliography", {
            type: "string",
            lang: "en-US",
            template: "chicago",
          });
          this.data.push({ name: "Chicago", data: o4 });

          const o5 = e1.format("bibliography", {
            type: "string",
            lang: "en-US",
            template: "ieee",
          });
          this.data.push({ name: "IEEE", data: o5 });

          const o6 = e1.format("bibliography", {
            type: "string",
            lang: "en-US",
            template: "mla",
          });
          this.data.push({ name: "MLA", data: o6 });
        } catch (error) {
          this.data = [];
        }
      }
    },
  },
};
</script>

<style scoped>
.mycursor {
  cursor: pointer;
}
</style>