<template>
  <div class="w-auto h-auto text-sm">
    <div v-if="isLoading" class="flex justify-center py-8">
      <div class="spinLoader"></div>
    </div>
    <div
      class="w-full text-gray-400 px-24 border border-b-1 flex justify-center"
    >
      <div class="">
        <ul
          class="flex flex-row md:flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400"
          @click="toggleSubMenu($event)"
        >
          <li class="mr-2">
            <a
              href="#"
              @click="getJournals"
              class="inline-flex p-2 border-b-2 border-transparent rounded-t-lg active hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"
            >
              All
            </a>
          </li>
          <li class="mr-2">
            <a
              href="#"
              @click="getThesis"
              class="inline-flex p-2 border-b-2 border-transparent rounded-t-lg dark:text-blue-500 hover:border-gray-300 dark:border-105005 group"
              aria-current="page"
            >
              Thesis
            </a>
          </li>
          <li class="mr-2">
            <a
              href="#"
              @click="getDiss"
              class="inline-flex p-2 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"
            >
              Dissertation
            </a>
          </li>
          <li class="mr-2">
            <a
              href="#"
              @click="getJournals2"
              class="inline-flex p-2 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"
            >
              Journal
            </a>
          </li>
        </ul>
      </div>

      <!-- <div class="bg-white rounded-tl-md rounded-tr-md">
                <ul class="flex font-poppins ml-1" @click="toggleSubMenu($event)">
                    <li @click="getJournals" class="px-2 active">
                        <a href="#" @click="getJournals"
                            class="uppercase text-xs font-semibold text-gray-700">
                            <svg xmlns="http://www.w3.org/2000/svg" @click="getJournals" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-4 h-4 inline disabled">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z" />
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z" />
                            </svg>
                            All
                        </a>
                    </li>
                    <li @click="getThesis" class="px-2">
                        <a href="#"
                            class="uppercase text-xs font-semibold text-gray-700">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-4 h-4 inline">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z" />
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z" />
                            </svg>
                            Thesis
                        </a>
                    </li>
                    <li class="px-2">
                        <a @click="approvedByDean" href="#"
                            class="uppercase text-xs font-semibold text-gray-700 tracking-wide ">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-4 h-4 inline">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z" />
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z" />
                            </svg>
                            Dissertations
                        </a>
                    </li>
                    <li class="px-2">
                        <a @click="approvedByDean" href="#"
                            class="uppercase text-xs font-semibold text-gray-700 tracking-wide ">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-4 h-4 inline">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z" />
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z" />
                            </svg>
                            Journals
                        </a>
                    </li>
                </ul>
            </div> -->
    </div>
    <div
      class="journal-tbl rounded-lg bg-white m-auto mt-4 pb-4 w-full md:w-10/12"
    >
      <!-- <div class="col-span-1 leading-6">
                    <ul class="pb-3">
                        <li>Any time</li>
                        <li>Since 2023</li>
                        <li>Since 2022</li>
                        <li>Since 2019</li>
                        <li>Custom Range</li>
                    </ul>
                    <hr class="w-40">
                    <ul class="pt-3 pb-3">
                        <li>Sort by relevance</li>
                        <li>Sort by date</li>
                        <li>Since 2022</li>
                        <li>Since 2019</li>
                        <li>Custom Range</li>
                    </ul>
                    <hr class="w-40">
                    <ul class="pt-3 pb-3">
                        <li>Any type</li>
                        <li>Review Articles</li>
                    </ul>
                    <hr class="w-40">
                    <ul class="pt-3 pb-3">
                        <li>Any type</li>
                        <li>Review Articles</li>
                    </ul>
                </div> -->
      <div
        class="mx-6 md:mx-12 mb-5 -mt-2 flex text-xs md:text-sm justify-between text-gray-400"
      >
        <div class="">About {{ results }} results ({{ duration }} sec)</div>
        <div class="">
          {{ date_now }}
        </div>
      </div>
      <div class="mx-6 md:mx-12 my-5" v-if="this.results <= 0">
        <div class="grid grid-rows-2 gap-2 leading-8">
          <div class="row-span-1 mb-2">No results.</div>
        </div>
      </div>
      <div class="mx-6 md:mx-12 mb-2">
        <div class="grid grid-rows-1">
          <div
            class="row-span-1 my-3 leading-6 md:leading-8"
            v-for="journalData in displayedJournals"
            :key="journalData"
          >
            <a
              href="#"
              class="hover:underline text-blue-700 text-lg md:text-xl font-semibold"
              @click="viewJournal(journalData)"
              >{{ journalData.title }}</a
            >
            <div class="w-full text-green-600 text-sm">
              <!-- <span class="cursor-pointer text-yellow-500" title="Uploader">
                                {{
                                    journalData.userdetail.fname + ' ' + journalData.userdetail.lname
                                }}
                            </span>
                            <span class="px-1">•</span> -->
              <span
                class="text-green-600 underline cursor-pointer"
                title="Main Author"
              >
                {{
                  journalData.mainauthorfname != null &&
                  journalData.mainauthorlname != null
                    ? journalData.mainauthorfname +
                      " " +
                      journalData.mainauthorlname
                    : ""
                }}
              </span>
              <span class="text-green-600 px-1" v-if="journalData.typeOfArticle"
                >•</span
              >
              <span class="text-green-600" title="Type of Article">
                {{
                  journalData.typeOfArticle == 1
                    ? " Thesis"
                    : journalData.typeOfArticle == 2
                    ? " Capstone Project"
                    : journalData.typeOfArticle == 3
                    ? " Dissertation"
                    : journalData.typeOfArticle == 4
                    ? " Research"
                    : journalData.typeOfArticle == 5
                    ? " Journal"
                    : journalData.typeOfArticle == 6
                    ? " Others"
                    : ""
                }}
              </span>
              <span class="text-green-600 px-1">•</span>
              <span>{{
                journalData.isPublished == 0
                  ? " Unpublished"
                  : journalData.isPublished == 1
                  ? " Published"
                  : ""
              }}</span>
              <span
                class="text-green-600 px-1"
                v-if="journalData.publishedDateYear"
                >•</span
              >
              <span>{{
                journalData.publishedDateYear != null
                  ? journalData.publishedDateYear
                  : ""
              }}</span>
              <span class="text-green-600 px-1" v-if="journalData.publisher"
                >•</span
              >
              <span>{{
                journalData.publisher != null ? journalData.publisher : ""
              }}</span>
            </div>
            <div class="text-sm pb-0.5">
              <div class="truncate-overflow">
                {{ journalData.abstract }}
              </div>
            </div>
            <div class="text-blue-700 flex">
              <div class="cursor-pointer" @click="save">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4 inline -mt-1"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                  />
                </svg>
                Save
              </div>
              <div
                class="px-2"
                v-if="
                  journalData.doi != null &&
                  journalData.doi != 'n/a' &&
                  journalData.doi != 'N/A'
                "
              >
                •
              </div>
              <div
                class="cursor-pointer"
                v-if="
                  journalData.doi != null &&
                  journalData.doi != 'n/a' &&
                  journalData.doi != 'N/A'
                "
                @click="cite(journalData)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4 inline -mt-1"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                  />
                </svg>
                Cite
              </div>
              <!-- <div class="px-2" v-if="journalData.journalcited != null">•</div>
                            <div v-if="journalData.journalcited != null">Cited by {{ journalData.journalcited != null ?
                                journalData.journalcited.citedCount : '' }}</div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="mx-6 md:mx-12 mb-2">
        <div
          class="grid w-full sm:flex md:flex lg:flex flex-row md:justify-end md:w-full sm:w-full mx-auto items-center justify-center py-3"
        >
          <div class="w-full flex justify-center">
            <nav class="">
              <ul class="flex md:justify-end">
                <li class="page-item mx-1">
                  <button
                    type="button"
                    @click="page = 1"
                    v-if="page > 1"
                    class="page-link p-2 text-md mx-0.5 text-gray-500 border rounded-md border-gray-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"
                      />
                    </svg>
                  </button>

                  <button
                    type="button"
                    disabled
                    v-if="page == 1"
                    class="page-link p-2 text-md mx-0.5 text-gray-300 border rounded-md border-gray-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"
                      />
                    </svg>
                  </button>
                </li>
                <li class="page-item mx-1">
                  <button
                    type="button"
                    class="page-link border border-gray-300 text-gray-600 p-2 text-xs rounded-md"
                    v-if="page > 1"
                    @click="page--"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>

                  <!--for the disabled button -->
                  <button
                    type="button"
                    class="page-link border border-gray-300 text-gray-300 p-2 text-xs rounded-md"
                    v-if="page == 1"
                    disabled
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </li>

                <li v-for="(item, index) in items" :key="index">
                  <button
                    type="button"
                    v-if="isNaN(Number(item))"
                    class="page-link p-2 text-md mx-0.5 text-gray-500 border rounded-md border-gray-300"
                  >
                    <p class="h-5 w-5">{{ item }}</p>
                  </button>
                  <!-- <span v-if="isNaN(Number(item))" class="pagination-more">{{ item }}</span> -->
                  <button
                    v-else-if="item == page"
                    type="button"
                    class="page-link p-2 text-md mx-0.5 border-2 border-105005 rounded-md text-105005"
                  >
                    <p class="h-5 w-5 font-semibold">{{ item }}</p>
                  </button>
                  <button
                    v-else
                    type="button"
                    @click="page = item"
                    class="page-link p-2 text-md mx-0.5 text-gray-500 border rounded-md border-gray-300"
                  >
                    <p class="h-5 w-5">{{ item }}</p>
                  </button>

                  <!-- <button v-else type="button"
                                        @click="$emit('input', item)">{{ item }}</button> -->
                </li>

                <li class="page-item mx-1">
                  <button
                    type="button"
                    @click="page++"
                    v-if="page < pages.length"
                    class="page-link border border-gray-300 text-gray-600 p-2 text-xs rounded-md"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>

                  <!--for the disabled button -->
                  <button
                    type="button"
                    disabled
                    v-if="page >= pages.length"
                    class="page-link border border-gray-300 text-gray-300 p-2 text-xs rounded-md"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </li>
                <li class="page-item mx-1">
                  <button
                    type="button"
                    @click="page = pages.length"
                    v-if="page < pages.length"
                    class="page-link p-2 text-md mx-0.5 text-gray-500 border rounded-md border-gray-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </button>

                  <button
                    type="button"
                    disabled
                    v-if="page >= pages.length"
                    class="page-link p-2 text-md mx-0.5 text-gray-300 border rounded-md border-gray-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <CitationModal
      v-if="isCitationModal"
      v-on:closeModal="closeCite"
      v-on:redirect="redirectTo"
      :citationData="citationData"
      v-on:refreshData="refreshData"
      :isLogged="false"
    />
    <PleaseLoginModal
      v-if="isPleaseLoginModal"
      v-on:closeModal="closeSave"
      v-on:redirect="redirectTo"
    />
  </div>
</template>

<script>
import PleaseLoginModal from "../modals/PleaseLoginModal.vue";
import axios from "axios";
import moment from "moment";
import CitationModal from "../modals/CitationModal.vue";
export default {
  name: "JournalTable",
  props: ["searchval", "isLogged"],
  components: { PleaseLoginModal, CitationModal },
  data() {
    return {
      journalData: [],
      isLoading: false,
      contents: [],
      page: 1,
      perPage: 10,
      selectPerPage: [10, 15, 20, 30, 50],
      pages: [],
      fromPage: 1,
      toPage: null,
      results: null,
      searchkey: this.searchval,
      duration: null,
      date_now: null,
      categories: [],
      categoryDesc: [],
      isPleaseLoginModal: false,
      isCitationModal: false,
      citationData: null,
      citedCount: null,
    };
  },
  mounted() {
    this.timeToday();
  },

  methods: {
    goTo(g) {
      this.$router.push({
        name: "ProfilePage",
        params: { data: g },
      });
    },
    refreshData() {
      this.getJournals();
    },
    closeSave() {
      this.isPleaseLoginModal = false;
    },
    closeCite() {
      this.isCitationModal = false;
    },
    redirectTo(loc) {
      this.$router.push("/signin/" + loc);
    },
    toggleSubMenu(e) {
      var elems = document.querySelector(".active");
      if (elems !== null) {
        elems.classList.remove("active");
      }
      e.target.classList.add("active");
    },
    init() {
      this.contents = [];
      this.page = 1;
      this.perPage = 10;
      this.pages = [];
      this.fromPage = 1;
      this.toPage = null;
      this.results = null;
    },

    formatNumber(num) {
      if (num <= 9) {
        return "0" + num;
      } else {
        return num;
      }
    },

    stringFormatDate(date) {
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(date).toLocaleDateString("en", options);
    },

    formatDate(date) {
      const d = new Date(date);
      this.timeAgo = d;

      const mn = d.getMonth() + 1;
      var day = d.getDate();
      const yr = d.getFullYear();
      const hh = d.getHours();
      const mm = d.getMinutes();
      const ss = d.getSeconds();

      return (
        this.formatNumber(mn) +
        "-" +
        this.formatNumber(day) +
        "-" +
        yr +
        " " +
        this.formatNumber(hh) +
        ":" +
        this.formatNumber(mm) +
        ":" +
        this.formatNumber(ss)
      );
    },

    timeToday() {
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var dateTime = date + " " + time;

      this.date_now = moment(today).format("lll");
    },

    save() {
      this.isPleaseLoginModal = true;
    },
    cite(journalData) {
      this.isCitationModal = true;
      this.citationData = journalData;
    },

    //load approved journals
    async getJournals() {
      if (this.searchkey == null || this.searchkey == "") {
        this.contents = [];
        this.results = 0;
        this.isLoading = false;
        return false;
      } else {
        const start = performance.now();
        axios
          .get(
            process.env.VUE_APP_API_SERVER +
              "/fileupload/previewAll/" +
              this.searchkey
          )
          .then(
            (response) => {
              this.contents = response.data;
              this.results = response.data.length;
              this.isLoading = false;
              // console.log(response.data)
            },
            (error) => {
              console.log(error);
            }
          );
        const end = performance.now();
        const duration = Math.round(end - start) / 100;
        this.duration = duration;
        this.timeToday();
      }
    },

    //load approved journals all thesis
    async getThesis() {
      if (this.searchkey == null || this.searchkey == "") {
        this.contents = [];
        this.results = 0;
        this.isLoading = false;
        return false;
      } else {
        const start = performance.now();
        axios
          .get(
            process.env.VUE_APP_API_SERVER +
              "/fileupload/previewThesis/" +
              this.searchkey
          )
          .then(
            (response) => {
              this.contents = response.data;
              this.results = response.data.length;
              this.isLoading = false;
              // console.log(response.data)
            },
            (error) => {
              console.log(error);
            }
          );
        const end = performance.now();
        const duration = Math.round(end - start) / 100;
        this.duration = duration;
        this.timeToday();
      }
    },

    //load approved journals all dissertation
    async getDiss() {
      if (this.searchkey == null || this.searchkey == "") {
        this.contents = [];
        this.results = 0;
        this.isLoading = false;
        return false;
      } else {
        const start = performance.now();
        axios
          .get(
            process.env.VUE_APP_API_SERVER +
              "/fileupload/previewDissertation/" +
              this.searchkey
          )
          .then(
            (response) => {
              this.contents = response.data;
              this.results = response.data.length;
              this.isLoading = false;
              // console.log(response.data)
            },
            (error) => {
              console.log(error);
            }
          );
        const end = performance.now();
        const duration = Math.round(end - start) / 100;
        this.duration = duration;
        this.timeToday();
      }
    },

    //load approved journals all dissertation
    async getJournals2() {
      if (this.searchkey == null || this.searchkey == "") {
        this.contents = [];
        this.results = 0;
        this.isLoading = false;
        return false;
      } else {
        const start = performance.now();
        axios
          .get(
            process.env.VUE_APP_API_SERVER +
              "/fileupload/previewJournal/" +
              this.searchkey
          )
          .then(
            (response) => {
              this.contents = response.data;
              this.results = response.data.length;
              this.isLoading = false;
              // console.log(response.data)
            },
            (error) => {
              console.log(error);
            }
          );
        const end = performance.now();
        const duration = Math.round(end - start) / 100;
        this.duration = duration;
        this.timeToday();
      }
    },

    viewJournal(data) {
      // console.log(item);
      this.$emit("viewJournal", data);
    },

    // format date to display
    formatDate(date, year) {
      var mnthArr = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      if (date == null || date == "") {
        return year;
      } else {
        return mnthArr[date] + " " + year;
      }
    },

    // set number of pages to paginate
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(
        parseInt(this.contents.length) / this.perPage
      );
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    // handles the from and to of pagination table
    paginate(contents) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;

      if (to > this.contents.length) {
        this.fromPage = from + 1;
        this.toPage = this.contents.length;
      } else {
        this.fromPage = from + 1;
        this.toPage = to;
      }

      return contents.slice(from, to);
    },
    getRange(from, to) {
      const range = [];

      from = from > 0 ? from : 1;

      for (let i = from; i <= to; i++) {
        range.push(i);
      }

      return range;
    },
  },
  computed: {
    displayedJournals() {
      return this.paginate(this.contents);
    },
    items() {
      const maxLength = 7;
      if (this.pages.length <= maxLength || maxLength < 1) {
        return this.getRange(1, this.pages.length);
      }

      const even = maxLength % 2 === 0 ? 1 : 0;
      const left = Math.floor(maxLength / 2);
      const right = this.pages.length - left + 1 + even;

      if (this.page > left && this.page < right) {
        const start = this.page - left + 2;
        const end = this.page + left - 2 - even;

        return [
          1,
          "...",
          ...this.getRange(start, end),
          "...",
          this.pages.length,
        ];
      }
      if (this.page === left) {
        const end = this.page + left - 1 - even;

        return [...this.getRange(1, end), "...", this.pages.length];
      }
      if (this.page === right) {
        const start = this.page - left + 1;

        return [1, "...", ...this.getRange(start, this.pages.length)];
      } else {
        return [
          ...this.getRange(1, left),
          "...",
          ...this.getRange(right, this.pages.length),
        ];
      }
    },
  },
  watch: {
    contents() {
      this.setPages();
    },

    perPage() {
      this.page = 1;
      this.setPages();
    },
    searchval: {
      handler(value) {
        this.searchkey = value;
        this.getJournals(value);
        this.setPages();
      },
      immediate: true,
    },
  },
  created() {
    this.moment = moment;
    if (this.searchkey) {
      this.getJournals(this.searchkey);
      this.setPages();
    }
  },
};
</script>

<style scoped>
.tbl_header {
  background-color: #105005;
}

.indent {
  text-indent: 0.5rem;
}

/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}

.mustTruncate {
  text-overflow: ellipsis;
}

li > a.active {
  border-color: rgba(16, 80, 5, var(--tw-border-opacity));
  border-bottom-width: 2px;
  color: rgba(16, 80, 5, var(--tw-text-opacity));
}

.truncate-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 60px;
  overflow: hidden;
}
</style>