<template>
  <div class="h-auto" id="bg">
    <div class="pt-44 m-auto h-3/5">
      <div class="flex w-9/12 h-fulll m-auto rounded-md bg-gray-50 px-10 py-10">
        <div class="w-auto md:w-1/2 grid">
          <h1 class="h_1 text-gray-600 mb-4">
            You have successfully registered to
            <span class="text-green-900 font-semibold">ReDMaS</span>
          </h1>
          <h1 class="text-gray-600 mb-6">
            An OTP (One-Time Passcode) is sent to your email address for email
            verification
          </h1>
          <router-link to="/landing/otp" class=" uppercase block text-center bg-yellow-500 font-semibold py-1 rounded-full hover:text-white " >Verify Email Now</router-link >
        </div>
        <div class="w-1/2 hidden md:block lg:block">
          <img
            src="../assets/bg/smiley.png"
            width="400"
            height="400"
            class="absolute -mt-20 ml-10"
          />
        </div>
      </div>
    </div>
    <LandingFooter class="bottom-0" />
  </div>
</template>

<script>
import LandingFooter from "./LandingFooter.vue";
export default {
  name: "RegistrationSuccess",
  components: {
    LandingFooter,
  },
  created() {},

  methods: {},
};
</script>

<style scoped>
.landing-body {
  background: url("../assets/bg/hero_banner2.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 70rem;
  background-color: transparent;
}
#bg {
  background-image: url("../assets/bg/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 70rem;
}
.h_1 {
  font-size: 30px;
}
</style>