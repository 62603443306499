<template>
  <div
    id="defaultModal"
    tabindex="-1"
    aria-hidden="true"
    class="bgModal fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full justify-center items-center"
  >
    <div class="relative mt-10 m-auto w-full h-full max-w-md p-4 md:h-auto">
      <div
        id="divModal"
        class="modal relative bg-white overflow-hidden rounded-lg shadow dark:bg-gray-700 animated"
      >
        <!-- modal header -->
        <div class="flex justify-between py-2 px-2">
          <h1 class="text-gray-800 text-sm"></h1>
          <button
            type="button"
            @click="closeModal"
            class="absolute top-1 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="authentication-modal"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- modal content -->
        <div class="grid">
          <div class="flex justify-center">
            <img
              src="../assets/bg/Thinking-face-bro.png"
              alt="thinking"
              class="w-2/3 h-full"
            />
          </div>

          <h4 class="text-sm text-gray-600 text-center px-7">
            {{ content }}
          </h4>
          <div class="flex justify-center mt-5 mb-5">
            <button
              v-if="mode == 1"
              @click="confirmRequest"
              type="button"
              class="inline-block px-3 py-1 h-8 bgColor text-white border font-medium text-xs leading-tight uppercase rounded shadow-md active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
            >
              Yes</button
            >&nbsp;
            <button
              v-else-if="mode == 2"
              @click="confirmGrant"
              type="button"
              class="inline-block px-3 py-1 h-8 bgColor text-white border font-medium text-xs leading-tight uppercase rounded shadow-md active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
            >
              Yes</button
            >&nbsp;
            <button
              v-else-if="mode == 3"
              @click="confirmRevoke"
              type="button"
              class="inline-block px-3 py-1 h-8 bgColor text-white border font-medium text-xs leading-tight uppercase rounded shadow-md active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
            >
              Yes</button
            >&nbsp;
            <button
              @click="closeModal"
              type="button"
              class="inline-block px-3 py-1 h-8 bg-white-600 text-gray-400 border font-medium text-xs leading-tight uppercase rounded shadow-md active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
            
<script>
import axios from "axios";
export default {
  name: "RequestToEditModal",
  props: {
    content: String,
    mode: Number,
  },
  // props: ["content"],
  data() {
    return {
      confirm: false,
      data: null,
    };
  },
  mounted() {
    document.getElementById("divModal").classList.add("modal_open");
    document.getElementById("divModal").classList.remove("modal_close");
  },

  methods: {
    closeModal() {
      document.getElementById("divModal").classList.remove("modal_open");
      document.getElementById("divModal").classList.add("modal_close");
      setTimeout(() => this.$emit("closeRequestModal"), 400);
    },

    confirmRequest() {
      document.getElementById("divModal").classList.remove("modal_open");
      document.getElementById("divModal").classList.add("modal_close");
      setTimeout(() => {
        this.$emit("confirmRequest");
        this.$emit("closeRequestModal");
      }, 400);
    },
    confirmGrant() {
      document.getElementById("divModal").classList.remove("modal_open");
      document.getElementById("divModal").classList.add("modal_close");
      setTimeout(() => {
        this.$emit("confirmGrant");
        this.$emit("closeRequestModal");
      }, 400);
    },
    confirmRevoke() {
      document.getElementById("divModal").classList.remove("modal_open");
      document.getElementById("divModal").classList.add("modal_close");
      setTimeout(() => {
        this.$emit("confirmRevoke");
        this.$emit("closeRequestModal");
      }, 400);
    },
  },
  watch: {
    unpublishedData: {
      handler(val) {
        this.data = val;
      },
      immediate: true,
    },
  },
};
</script>
            
<style scoped>
.mycursor {
  cursor: pointer;
}
</style>