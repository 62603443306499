<template>
  <div class="w-auto">
    <div
      class="grid md:flex relative items-center sm:xl:justify-between md:justify-between xl:justify-between lg:justify-between bg-gray-100 dark:bg-darkBG mb-2 pt-2"
    >
      <div class="w-1/2">
        <h1
          class="md:text-xl text-xs lg:text-xl font-semibold py-5 pl-6 text-gray-900 dark:text-gray-200"
        >
          My Works - Articles
        </h1>
        <h1
          class="text-sm font-normal ml-6 -mt-4 text-gray-500 dark:text-gray-400 mb-10"
        >
          List of Journals
        </h1>
      </div>
      <div
        class="hidden md:flex md:justify-end lg:flex lg:justify-end xl:flex xl:justify-end sm:hidden mr-3 -mt-6 overflow-hidden"
      >
        <div class="relative sm:w-60 md:w-96 px-4">
          <input
            type="text"
            v-model="searchval"
            @keypress.enter="searchBtn"
            class="block py-1 px-2 w-full z-20 text-sm text-gray-600 bg-gray-50 dark:bg-darkBG dark:text-gray-200 outline-none rounded-lg border-l-gray-50 border-l-2 border border-gray-400 focus:ring-gray-200 focus:border-gray-200"
            placeholder="Search by anything"
            required
          />
          <button
            type="submit"
            @click="searchBtn"
            class="absolute top-0 right-0 py-1 px-2 text-sm font-medium text-white bg-customGreen rounded-tr-lg rounded-br-lg border focus:ring-4 focus:outline-none focus:ring-blue-300"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
            <span class="sr-only">Search</span>
          </button>
        </div>
      </div>
    </div>

    <MyWorksTable
      v-on:toggleAddModal="openAddModal"
      v-on:toggleUpdateModal="openUpdateModal"
      v-on:action="action"
      :refreshtable="refreshData"
      :notifID="notifID"
    />

    <!-- Modal for adding journal -->

    <MyWorksModal
      v-if="showAddModal"
      v-on:closeAddModal="closeAddModal"
      :action="modalAction"
      :itemToUpdate="item"
      v-on:refreshTable="refreshTable"
    />
    <!-- modal for updating article -->
    <MyWorkUpdateModal
      v-if="showUpdateModal"
      v-on:openUpdateModal="openUpdateModal"
      v-on:closeUpdateModal="closeUpdateModal"
      :action="modalAction"
      :itemToUpdate="item"
      v-on:refreshTable="refreshTable"
    />
  </div>
</template>

<script>
import MyWorksTable from "../components/MyWorksTable.vue";
import MyWorksModal from "../modals/MyWorksModal.vue";
import MyWorkUpdateModal from "../modals/MyWorkUpdateModal.vue";
import { useToast } from "vue-toastification";
export default {
  name: "UserMyWork",
  components: {
    MyWorksTable,
    MyWorksModal,
    MyWorkUpdateModal,
  },
  data() {
    return {
      showUpdateModal: false,
      showAddModal: false,
      isLoading: false,
      modalAction: "",
      item: null,
      refreshData: false,
      notifID: null,
      searchval: null,
    };
  },

  mounted() {
    this.notifID = this.$route.params.fileid;
  },
  watch: {
    "$route.params.fileid": {
      handler(value) {
        this.notifID = value;
      },
    },
  },

  methods: {
    searchBtn() {
      if (this.searchval == null || this.searchval == "") {
        return;
      } else {
        this.$router.push({
          name: "UserSearch",
          params: { data_text: this.searchval },
        });
      }
    },
    openAddModal() {
      const toast = useToast();
      if (this.$store.getters.getIsAdminVerified) {
        this.showAddModal = true;
      } else {
        toast.error("You are not verified by the admin yet", {
          position: "top-center",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },

    openUpdateModal() {
      this.showUpdateModal = true;
    },

    closeAddModal() {
      this.showAddModal = false;
    },
    closeUpdateModal() {
      this.showUpdateModal = false;
    },
    refreshTable() {
      this.refreshData = true;
      setTimeout(() => {
        this.refreshData = false;
      }, 100);
    },
    action(act) {
      if (act.action == "add") {
        this.modalAction = "Add New Journal";
        this.item = null;
      } else {
        this.modalAction = "Update Journal";
        Object.assign(act.item, { pendingDetails: act.pendingDetails });
        this.item = act.item;
      }
    },
  },
};
</script>

<style scoped>
.customSearch {
  width: 20rem;
}
.modal_hgt {
  height: 36rem;
}
.form_modal_hgt {
  height: 26rem;
}
</style>
