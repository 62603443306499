<template>
  <div class="w-auto h-auto pb-12">
    <div
      class="filter_by flex flex-col-reverse md:flex-row w-auto md:w-auto overflow-hidden bg-white lg:h-auto md:h-auto py-2 mx-3 m-auto rounded-md"
    >
      <div class="w-full md:w-3/4 md:grid lg:flex px-5 py-2">
        <input
          @keyup="handleSearching()"
          type="text"
          class="indent font-poppins py-2.5 md:w-3/4 w-full text-xs font-normal text-gray-700 bg-gray-100 bg-clip-padding border border-solid border-gray-300 rounded md:rounded-md lg:rounded-md transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
          v-model="searchText"
          placeholder="Search for category"
        />
      </div>
      <div class="w-full py-2">
        <button
          @click="openModal('Add')"
          class="bgColor text-white hover:text-white float-right mr-5 py-2 px-5 md:px-3 text-xs rounded shadow md:w-1/4 text-left"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-5 h-5 inline"
          >
            <path
              d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"
            />
          </svg>
          Add new category
        </button>
      </div>
    </div>

    <div class="rounded-lg bg-white w-auto m-auto mt-4 pb-4 mx-3">
      <div class="md:flex flex-col">
        <div class="overflow-x-auto">
          <div class="inline-block min-w-full">
            <div class="overflow-hidden mx-5">
              <Table
                class="mt-5"
                :labels="table_headers"
                :data="displayedAccounts"
                :idName="'categorytable'"
              >
                <template v-slot:action="{ item }">
                  <button
                    @click="viewCategory(item)"
                    class="bg-white hover:bg-yellow-500 hover:text-white text-yellow-500 font-semibold px-3 py-1 text-xs border border-yellow-500 rounded shadow"
                  >
                    Edit
                  </button>
                  <!-- <button v-if="$store.getters.getUserRole == 1" @click="confirmRemove(item)" class=" bg-white hover:bg-red-500 hover:text-white text-red-500 font-semibold px-3 py-1 text-xs border border-yellow-500 rounded shadow " >
                    Remove
                  </button> -->
                </template>
              </Table>
              <div
                class="grid font-poppins w-full sm:flex md:flex lg:flex flex-row justify-end md:w-full sm:w-full mx-auto items-center border-l border-b border-r border-gray-400 pb-4 rounded-bl-lg rounded-br-lg pt-4"
              >
                <div
                  class="w-auto sm:w-3/6 md:w-3/6 lg:w-3/6 xl:w-3/6 gap-x-4 mx-3 flex"
                >
                  <div
                    class="hidden md:block text-xs text-gray-600 w-1/3 md:w-2/5 items-center"
                  >
                    <label for="" class="w-2/3">Rows per page</label>&nbsp;
                    <select
                      class="w-1/3 border-gray-600 border-b"
                      v-model="perPage"
                    >
                      <option
                        :value="item"
                        v-for="item in selectPerPage"
                        :key="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>

                  <div class="hidden md:block w-1/3 md:w-2/5">
                    <!-- <div class="flex" v-if="results != 0"> -->
                    <span
                      class="text-xs m-auto text-gray-600 dark:text-gray-400"
                    >
                      Showing
                      <span
                        class="font-semibold text-gray-900 dark:text-white"
                        >{{ fromPage }}</span
                      >
                      to
                      <span
                        class="font-semibold text-gray-900 dark:text-white"
                        >{{ toPage }}</span
                      >
                      of
                      <span
                        class="font-semibold text-gray-900 dark:text-white"
                        >{{ results }}</span
                      >
                      Entries
                    </span>
                  </div>

                  <nav class="w-1/3 md:w-1/5">
                    <ul class="flex md:justify-end">
                      <li class="page-item mx-1">
                        <button
                          type="button"
                          class="page-link bg-customGreen text-white rounded-md p-1 text-xs"
                          v-if="page > 1"
                          @click="page--"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>

                        <!--for the disabled button -->
                        <button
                          type="button"
                          class="page-link bg-customGreen rounded-md text-white p-1 text-xs"
                          v-if="page == 1"
                          disabled
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </li>

                      <li class="page-item mx-1">
                        <button
                          type="button"
                          @click="page++"
                          v-if="page < pages.length"
                          class="page-link bg-customGreen rounded-md text-white p-1 text-xs"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>

                        <!--for the disabled button -->
                        <button
                          type="button"
                          disabled
                          v-if="page >= pages.length"
                          class="page-link bg-customGreen rounded-md text-white p-1 text-xs"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AlertConfirmationModal
      v-on:confirmRemove="removeCategory"
      v-on:closeModal="closeModal"
      :category="categoryToRemove"
      v-if="isRemoveModal"
    />
  </div>
</template>

<script>
import axios from "axios";
import Table from "../components/Table.vue";
import AlertConfirmationModal from "../modals/AlertConfirmationModal.vue";
import { useToast } from "vue-toastification";
export default {
  name: "CategoriesTable",
  props: ["isUpdate"],
  components: { Table, AlertConfirmationModal },

  data() {
    return {
      contents: [],
      page: 1,
      isLoading: false,
      modalAction: "Add",
      perPage: 10,
      selectPerPage: [10, 15, 20, 30, 50],
      pages: [],
      fromPage: 1,
      toPage: null,
      results: null,
      searchText: null,
      isRemoveModal: false,
      itemToRemove: null,
      categoryToRemove: "",
      table_headers: [
        { text: "Category", field: "categoryDesc" },
        { text: "Action", field: "action" },
      ],
    };
  },

  methods: {
    init() {
      this.contents = [];
      this.page = 1;
      this.perPage = 10;
      this.pages = [];
      this.fromPage = 1;
      this.toPage = null;
      this.results = null;
    },

    openModal(action) {
      this.modalAction = action;
      this.$emit("toggleCategoryModal", action);
    },

    closeModal() {
      this.isRemoveModal = false;
    },

    viewCategory(item) {
      this.$emit("toggleCategoryModal", "Update");
      this.$emit("viewCategory", item);
    },

    // load all user accounts to verify
    loadCategories() {
      axios
        .get(process.env.VUE_APP_API_SERVER + "/journalcategory/", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            this.contents = response.data;
            this.results = response.data.length;
            // this.user_accounts = response.data;
            // console.log(this.contents);
          },
          (error) => {
            console.log(error);
          }
        );
    },

    removeCategory() {
      const toast = useToast();
      axios
        .delete(
          process.env.VUE_APP_API_SERVER +
            "/journalcategory/" +
            this.itemToRemove.id,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            if (response.data.status == 200) {
              toast.success(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            } else if (response.data.status == 400) {
              toast.error(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            }
            this.loadCategories();
          },
          (error) => {
            console.log(error);
          }
        );
    },

    confirmRemove(category) {
      this.itemToRemove = category;
      this.categoryToRemove = category.categoryDesc;
      this.isRemoveModal = true;
    },

    handleSearching() {
      if (this.searchText == "" || !this.searchText) {
        this.loadCategories();
        this.setPages();
      } else {
        this.contents = this.contents.filter(
          (data) =>
            data.categoryDesc
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) !== -1
        );
        this.results = this.contents.length;
        this.setPages();
      }
    },

    // set number of pages to paginate
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(
        parseInt(this.contents.length) / this.perPage
      );
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    // handles the from and to of pagination table
    paginate(contents) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;

      if (to > this.contents.length) {
        this.fromPage = from + 1;
        this.toPage = this.contents.length;
      } else {
        this.fromPage = from + 1;
        this.toPage = to;
      }

      return contents.slice(from, to);
    },
  },

  computed: {
    displayedAccounts() {
      return this.paginate(this.contents);
    },
  },
  watch: {
    contents() {
      this.setPages();
    },

    perPage() {
      this.page = 1;
      this.setPages();
    },
    isUpdate: {
      handler(val) {
        if (val) {
          this.init();
          this.loadCategories();
          this.setPages();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.loadCategories();
    this.setPages();
  },
};
</script>

<style scoped>
.tbl_header {
  background-color: #105005;
}

.indent {
  text-indent: 0.5rem;
}

/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>