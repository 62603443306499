<template>
  <div
    class="w-full sm:w-full sm:m-auto md:w-7/12 lg:w-7/12 xl:w-7/12 md:m-auto font-poppins"
  >
    <div class="bg-white rounded-xl p-8">
      <div class="flex m-auto">
        <p class="text-md text-gray-500">Sign In</p>
        <p class="text-gray-500 mx-3">|</p>
        <p class="text-md font-semibold text-green-900">Welcome Back !</p>
      </div>
      <hr class="mt-4" />

      <form method="POST" @submit.prevent class="mt-4">
        <div class="mb-3 pt-4">
          <div class="floating-input relative">
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center">
              <svg
                class="h-5 w-5 text-gray-400"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                />
              </svg>
            </div>
            <input
              :class="
                email_err ? 'focus:border-red-500 ' : 'focus:border-green-600 '
              "
              type="email"
              id="email"
              class="border border-gray-200 focus:outline-none rounded-md focus:border-gray-500 focus:shadow-sm w-full text-xs pt-5 pl-3 h-9"
              placeholder="name@example.com"
              autocomplete
              v-model="email"
              @keyup="emailCheck"
            />
            <label
              for="email"
              class="absolute top-2 left-2 bg-white text-xs text-gray-600 py-1 pointer-events-none transform origin-left transition-all duration-500 ease-in-out"
              >Email address</label
            >
          </div>
          <strong
            :class="email_err ? 'text-red-500' : 'hidden'"
            class="flex w-full content-center justify-start text-xs"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
              />
            </svg>

            {{ email_err_msg }}
          </strong>
        </div>

        <div class="mb-3 pt-2">
          <div class="floating-input relative">
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center">
              <!-- eye open -->
              <svg
                v-if="showPassword"
                @click="viewPassword"
                class="h-5 w-5 text-gray-400 cursor-pointer"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>

              <!-- eye slash -->

              <svg
                v-if="!showPassword"
                @click="viewPassword"
                class="h-5 w-5 text-gray-400 cursor-pointer"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                />
              </svg>
            </div>
            <input
              type="password"
              id="password"
              v-model="password"
              :class="
                password_err
                  ? 'focus:border-red-500 '
                  : 'focus:border-green-600 '
              "
              class="border border-gray-200 focus:outline-none rounded-md focus:border-green-600 focus:shadow-sm w-full text-xs pt-5 pl-3 h-9"
              placeholder="password"
              autocomplete="off"
            />
            <label
              for="password"
              class="absolute top-2 left-2 bg-white text-xs text-gray-600 py-1 transform origin-left transition-all duration-500 ease-in-out"
              >Password</label
            >
          </div>
          <strong
            v-if="password_err"
            class="flex text-red-500 w-full content-center justify-start text-xs"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
              />
            </svg>
            This is required</strong
          >
        </div>

        <div class="mb-2" id="btnsContainer">
          <!-- <div class="grid w-full m-auto text-center"> -->
          <button
            @click="signIn"
            class="bg-FFB800 w-full py-2 mt-4 font-normal rounded-md text-white"
          >
            Sign In
          </button>
          <!-- <button id="googleSignInBtn" class="w-full py-2 mt-4 font-normal rounded-md text-white">
              
            </button> -->

          <div id="googleSignInBtn" class="g_id_signin mt-4"></div>
          <!-- <button type="button" id="googleSignInBtn" class="g_id_signin bg-FFB800 w-full py-2 mt-4 font-normal rounded-md text-white"></button> -->
          <!-- <div class=" mt-4 flex justify-center w-full font-normal rounded-md">
            
          </div> -->

          <!-- <div class="g-signin2" data-width="300" data-height="200" data-longtitle="true"></div> -->
          <!-- <div>
              <button
                @click="googleSignIn"
                type="button"
                class="
                  text-black
                  hover:bg-FFB800 hover:text-white
                  border
                  font-medium
                  rounded-lg
                  text-sm
                  w-full
                  py-2
                  mb-2
                  mt-2
                "
              >
                <div class="inline-block items-center text-center w-full">
                  <svg
                    class="mr-2 inline-block m-auto -ml-1 w-4 h-4"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="google"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 488 512"
                  >
                    <path
                      fill="currentColor"
                      d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                    ></path>
                  </svg>
                  Sign in with Google
                </div>
              </button>
            </div> -->
          <div class="mt-5 text-center">
            <a
              class="text-xs font-normal text-bl_001AFF hover:underline"
              href="javascript:void(0)"
              @click="redirect"
            >
              Forgot Password
            </a>
          </div>
          <!-- </div> -->
        </div>

        <hr />

        <div class="mt-3 mb-3">
          <div class="grid w-full m-auto text-center">
            <div>
              <h4 class="text-xs font-normal text-green-900 font-['Poppins']">
                Doesn't have an account yet ?
              </h4>
            </div>
            <div class="mt-5">
              <router-link
                to="/signin/register"
                class="bgGreen block py-2 font-normal rounded-md text-white"
                >Sign up</router-link
              >
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- loading screen -->
    <!-- <LoadingScreen v-if="isLoading" :loading="isLoading" :fullPage="fullPage" /> -->
    <Spinner v-if="isLoading" />
  </div>
</template>

<script>
import axios from "axios";
// import { inject } from "vue";
import { useToast } from "vue-toastification";
// import LoadingScreen from "../components/LoadingScreen.vue";
import Spinner from "../components/Spinner.vue";

export default {
  name: "Login",
  data() {
    return {
      email: null,
      password: null,
      email_err: false,
      email_err_msg: "",
      password_err: false,
      showPassword: false,
      isLoading: false,
      user: null,
      isEmailFound: null,
    };
  },
  components: {
    Spinner,
  },

  mounted() {
    window.addEventListener("resize", this.widthResizeHandler);
    google.accounts.id.initialize({
      client_id: process.env.VUE_APP_GOOGLE_CLIENTID,
      callback: this.handleCredentialResponse,
    });
    // google.accounts.id.prompt();
    const element = document.getElementById("btnsContainer");
    this.renderGoogleButton(element.offsetWidth);
  },

  methods: {
    widthResizeHandler() {
      const element = document.getElementById("btnsContainer");
      this.renderGoogleButton(element.offsetWidth);
    },

    renderGoogleButton(w) {
      const googleLoginWrapper = document.getElementById("googleSignInBtn");
      google.accounts.id.renderButton(googleLoginWrapper, {
        shape: "rectangular",
        width: w,
        text: "continue_with",
        size: "large",
        type: "standard",
        theme: "outline",
        logo_alignment: "center",
      });
      // google.accounts.id.renderButton(
      //   document.getElementById("googleSignInBtn"),
      //   {
      //     // theme: "outline",
      //     // size: "large",
      //     // width: w + 'px',
      //     // width: 550,
      //     height: 50,
      //     text: "continue_with",
      //   } // customization attributes
      // );
    },

    async handleCredentialResponse(response) {
      const toast = useToast();
      const cred = response.credential;
      axios

        .post(process.env.VUE_APP_API_SERVER + "/auth/isEmailExist", {
          token: JSON.stringify(response.credential),
        })
        .then(
          (response) => {
            // console.log(JSON.stringify(cred));
            if (!response.data.isExist.isExist) {
              this.$router.push({
                name: "Position",
                params: {
                  cred: JSON.stringify(cred),
                  gUser: response.data.gUser,
                },
              });
            } else {
              if (response.data.isExist.isGoogleSignIn == 0) {
                toast.error("Email already used.", {
                  position: "top-center",
                  timeout: 2000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
              } else {
                this.GoogleUserRedirect(JSON.stringify(cred));
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },

    GoogleUserRedirect(creds) {
      axios
        .post(process.env.VUE_APP_API_SERVER + "/auth/googleLogin", {
          token: creds,
        })
        .then(
          (response) => {
            if (response.data.status == 202) {
              this.$store.commit("setToken", response.data.token);
              this.$store.commit("setExpiryDate");

              this.$store.commit("setIsValidated", true);
              this.$store.commit(
                "setUserType",
                response.data.user.userdetail.userType
              );
              this.$store.commit(
                "setUserdetailID",
                response.data.user.userdetailID
              );
              this.$store.commit("setUserEmail", response.data.user.email);
              this.$store.commit("setUserID", response.data.user.id);
              this.$store.commit(
                "setUserFirstName",
                response.data.user.userdetail.fname
              );
              this.$store.commit(
                "setUserMidName",
                response.data.user.userdetail.mname
              );
              this.$store.commit(
                "setUserLastName",
                response.data.user.userdetail.lname
              );
              this.$store.commit(
                "setUserRole",
                response.data.user.userdetail.role.id
              );

              this.$store.commit(
                "setIsAdminVerified",
                response.data.user.userdetail.isAdminVerified
              );

              this.$store.commit(
                "setIsGoogleSignIn",
                response.data.user.isGoogleSignIn
              );

              this.isLoading = false;
              this.$router.push("/userspage/dashboard");
            } else if (response.data.status == 400) {
              toast.error(response.data.msg, {
                position: "top-center",
                timeout: 2000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            }

            // start

            // end
          },
          (error) => {
            console.log(error);
          }
        );
    },

    // async googleSignIn() {
    //   const toast = useToast();
    //   try {
    //     const googleUser = await this.$gAuth.signIn();

    //     if (!googleUser) {
    //       return null;
    //     } else {
    //       // console.log(this.Vue3GoogleOauth);
    //       this.user = {
    //         fname: googleUser.getBasicProfile().getGivenName(),
    //         lname: googleUser.getBasicProfile().getFamilyName(),
    //         email: googleUser.getBasicProfile().getEmail(),
    //       };

    //       axios
    //         .post(process.env.VUE_APP_API_SERVER + "/auth/googleLogin", {
    //           token: JSON.stringify(googleUser.Cc.token),
    //           user: this.user,
    //         })
    //         .then(
    //           (response) => {
    //             if (response.data.status == 202) {
    //               this.$store.commit("setExpiryDate");
    //               this.$store.commit("setToken", response.data.token);
    //               this.$store.commit("setIsValidated", true);
    //               this.$store.commit(
    //                 "setUserType",
    //                 response.data.user.userdetail.userType
    //               );
    //               this.$store.commit("setUserEmail", response.data.user.email);
    //               this.$store.commit("setUserID", response.data.user.id);
    //               this.$store.commit(
    //                 "setUserFirstName",
    //                 response.data.user.userdetail.fname
    //               );
    //               this.$store.commit(
    //                 "setUserMidName",
    //                 response.data.user.userdetail.mname
    //               );
    //               this.$store.commit(
    //                 "setUserLastName",
    //                 response.data.user.userdetail.lname
    //               );
    //               this.$store.commit(
    //                 "setUserRole",
    //                 response.data.user.userdetail.role.id
    //               );

    //               this.$store.commit(
    //                 "setIsAdminVerified",
    //                 response.data.user.userdetail.isAdminVerified
    //               );

    //               this.$store.commit(
    //                 "setIsGoogleSignIn",
    //                 response.data.user.isGoogleSignIn
    //               );

    //               this.isLoading = false;
    //               this.$router.push("/userspage/dashboard");
    //             } else if (response.data.status == 400) {
    //               toast.error(response.data.msg, {
    //                 position: "top-center",
    //                 timeout: 2000,
    //                 closeOnClick: true,
    //                 pauseOnFocusLoss: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 draggablePercent: 0.6,
    //                 showCloseButtonOnHover: false,
    //                 hideProgressBar: false,
    //                 closeButton: "button",
    //                 icon: true,
    //                 rtl: false,
    //               });
    //             }
    //           },
    //           (error) => {
    //             console.log(error);
    //           }
    //         );
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },

    viewPassword() {
      this.showPassword = !this.showPassword;
      if (this.showPassword == true) {
        document.getElementById("password").type = "text";
      } else {
        document.getElementById("password").type = "password";
      }
    },

    emailCheck() {
      //  Regex for dnsc email
      // Checking if the inputted email is dnsc email only
      if (this.email == "" || this.email == null) {
        this.email_err = true;
        this.email_err_msg = "This is required";
      } else {
        const email_test = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
        if (email_test.test(this.email)) {
          this.email_err = false;
          this.email_err_msg = "";
        } else {
          this.email_err = true;
          this.email_err_msg = "This is not a valid email address";
        }
      }
    },

    redirect() {
      this.$router.push("/forgotpassword/auth");
    },

    signIn() {
      if (this.password == null || this.password == "") {
        this.password_err = true;
      } else {
        this.password_err = false;
      }

      if (!this.email_err && !this.password_err) {
        const toast = useToast();

        this.isLoading = true;
        const fd = {
          email: this.email,
          password: this.password,
        };

        axios.post(process.env.VUE_APP_API_SERVER + "/auth/login", fd).then(
          (response) => {
            
            if (response.data.status == 202) {
              if (response.data.user.isValidated == 0) {
                this.$store.commit("setExpiryDate");

                this.$store.commit(
                  "setUserType",
                  response.data.user.userdetail.userType
                );
                this.$store.commit(
                  "setUserdetailID",
                  response.data.user.userdetailID
                );
                this.$store.commit("setUserEmail", response.data.user.email);
                this.$store.commit("setUserID", response.data.user.id);
                this.$store.commit(
                  "setUserFirstName",
                  response.data.user.userdetail.fname
                );
                this.$store.commit(
                  "setUserMidName",
                  response.data.user.userdetail.mname
                );
                this.$store.commit(
                  "setUserLastName",
                  response.data.user.userdetail.lname
                );
                this.$store.commit(
                  "setIsAdminVerified",
                  response.data.user.userdetail.isAdminVerified
                );

                this.$router.push("/landing/otp");
              } else {
                //set values to vuex state
 //console.log(response.data);
                this.$store.commit("setExpiryDate");
                this.$store.commit("setToken", response.data.token);
                this.$store.commit("setIsValidated", true);
                this.$store.commit(
                  "setUserType",
                  response.data.user.userdetail.userType
                );
                this.$store.commit("setUserEmail", response.data.user.email);
                this.$store.commit("setUserID", response.data.user.id);
                this.$store.commit(
                  "setUserdetailID",
                  response.data.user.userdetailID
                );
                this.$store.commit(
                  "setUserFirstName",
                  response.data.user.userdetail.fname
                );
                this.$store.commit(
                  "setUserMidName",
                  response.data.user.userdetail.mname
                );
                this.$store.commit(
                  "setUserLastName",
                  response.data.user.userdetail.lname
                );
                this.$store.commit(
                  "setUserRole",
                  response.data.user.userdetail.role.id
                );

                this.$store.commit(
                  "setIsAdminVerified",
                  response.data.user.userdetail.isAdminVerified
                );

                this.isLoading = false;
                this.$router.push("/userspage/dashboard");
              }
            } else if (response.data.status == 400) {
              this.isLoading = false;
              // alert(response.data.response);

              // TOAST ALERT
              toast.error(response.data.response, {
                position: "top-center",
                timeout: 2000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            } else if (response.data.status == 404) {
              this.isLoading = false;
              // alert(response.data.response);

              // TOAST ALERT
              toast.error(response.data.response, {
                position: "top-center",
                timeout: 2000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    },
  },
};
</script>

<style scoped>
.g_id_signin {
  display: inline-flex !important;
}

.bgGreen {
  background-color: #105005;
}

.fontPoppins {
  font-family: "Poppins", sans-serif !important;
}

.zIndex {
  z-index: -1;
}

.floating-input > input::placeholder {
  color: transparent;
}

.floating-input > input:focus,
.floating-input > input:not(:placeholder-shown) {
  @apply pt-1;
}

.floating-input > input:focus ~ label,
.floating-input > input:not(:placeholder-shown) ~ label {
  @apply opacity-75 scale-75 -translate-y-6 translate-x-1;
}
</style>
