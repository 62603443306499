<template>
  <div
    id="defaultModal"
    tabindex="-1"
    aria-hidden="true"
    class="bgModal out fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full justify-center items-center"
  >
    <div
      class="relative mt-5 md:mt-10 lg:mt-10 xl:mt-10 2xl:mt-10 m-auto w-full h-full max-w-xl p-3 md:p-4 lg:p-4 xl:-4 2xl:p-4 md:h-auto"
    >
      <div
        id="thisModal"
        class="modal relative bg-white overflow-hidden rounded-lg shadow dark:bg-gray-700 animated modal_open"
      >
        <!-- modal header -->
        <div class="flex justify-between border-b py-4 bg-105005">
          <h1 v-if="!isUpdate" class="text-sm text-white px-5">
            Add New Announcement
          </h1>
          <h1 v-if="isUpdate" class="text-sm text-white px-5">
            Update an Announcement
          </h1>
          <button
            type="button"
            @click="closeModal"
            class="animated absolute top-2.5 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="authentication-modal"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- modal content -->
        <div
          class="grid items-center w-full px-6 max-h-96 md:max-h-full overflow-y-auto"
        >
          <!-- <button>Undo</button> -->
          <form enctype="multipart/form-data">
            <div class="mt-6">
              <a
                @click="isUploadImage = true"
                v-if="!isUploadImage"
                href="javascript:void(0)"
                class="text-xs text-gray-600 cursor-pointer hover:underline"
                >Click here to upload image/s</a
              >
            </div>
            <div
              class="flex justify-center items-center w-full md:w-full mt-6 mb-6 m-auto"
              :class="imgUrls.length > 0 ? 'hidden' : 'visible'"
              v-if="isUploadImage"
            >
              <label
                for="dropzone-file"
                @drop="dropt"
                @dragenter.prevent
                @dragover.prevent
                @drag.prevent
                @change="onFileChange"
                class="flex flex-col justify-center items-center w-full h-40 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div
                  class="flex flex-col justify-center items-center pt-5 pb-6"
                >
                  <svg
                    aria-hidden="true"
                    class="mb-3 w-10 h-10 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    ></path>
                  </svg>
                  <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span class="font-semibold">Click to upload</span> or drag
                    and drop
                  </p>
                  <p class="text-xs text-gray-500 dark:text-gray-400">
                    SVG, PNG, JPG or GIF (MAX. 800x400px)
                  </p>
                </div>
                <!-- <input id="dropzone-file" type="file" multiple class="hidden" /> -->
                <input id="dropzone-file" type="file" class="hidden" />
              </label>
            </div>

            <div
              v-show="imgUrls.length > 0"
              id="gallery"
              class="flex flex-wrap justify-center border border-gray-300 rounded-md w-full m-auto"
            >
              <input type="button" value="" />
              <div v-for="item in imgUrls" :key="item" class="">
                <span
                  class="inline-flex relative items-center p-3 text-sm font-medium text-center text-white rounded-lg focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  <div>
                    <img class="w-40 h-40 border" :src="item.url" />
                    <div
                      @click="imgToDelete(item.name)"
                      class="cursor-pointer inline-flex absolute top-1 right-1 justify-center items-center w-6 h-6 text-xs font-bold text-white bg-red-500 rounded-full border-2 border-white dark:border-gray-900"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </div>
                  </div>
                </span>
              </div>
            </div>
            <div v-if="!isUpdate">
              <div class="mt-4">
                <label for="" class="text-grey-600 text-sm">Title</label>
                <input
                  v-model="title"
                  :class="title_err ? 'border border-red-500' : ''"
                  id="title"
                  @keyup="hasChanged($event)"
                  class="border w-full rounded md overflow-y-auto text-xs py-2 px-2"
                  type="text"
                  placeholder="Title"
                />
              </div>

              <div class="mt-4 mb-5 md:mb-0">
                <label for="" class="text-grey-600 text-sm">Captions</label>
                <textarea
                  :class="captions_err ? 'border border-red-500' : ''"
                  v-model="captions"
                  id="caption"
                  @keyup="hasChanged($event)"
                  class="border rounded md w-full overflow-y-auto text-xs py-2 px-2 mb-5"
                  rows="20"
                  placeholder="Type captions here..."
                ></textarea>
              </div>
            </div>
            <div v-if="isUpdate">
              <div class="mt-4">
                <label for="" class="text-grey-600 text-sm">Title</label>
                <input
                  v-model="title"
                  :class="title_err ? 'border border-red-500' : ''"
                  id="title1"
                  @keyup="hasChanged1($event)"
                  class="border w-full rounded md overflow-y-auto text-xs py-2 px-2"
                  type="text"
                  placeholder="Title"
                />
              </div>

              <div class="mt-4 mb-5 md:mb-0">
                <label for="" class="text-grey-600 text-sm">Captions</label>
                <textarea
                  :class="captions_err ? 'border border-red-500' : ''"
                  v-model="captions"
                  id="caption1"
                  @keyup="hasChanged1($event)"
                  class="border rounded md w-full overflow-y-auto text-xs py-2 px-2 mb-5"
                  rows="20"
                  placeholder="Type captions here..."
                ></textarea>
              </div>
            </div>
          </form>
        </div>
        <!-- @click="imgToDelete($event, item.name)" -->

        <!-- buttons -->
        <div
          class="flex p-5 space-x-2 rounded-b w-full border-t dark:border-gray-600 items-align-right justify-end"
        >
          <!-- button to toggle approval remarks textarea -->

          <button
            v-if="!isUpdate"
            @click="save()"
            type="button"
            :class="
              title1 == true && caption1 == true ? 'bgColor' : 'bg-gray-500'
            "
            :disabled="title1 == true && caption1 == true ? false : true"
            class="font-normal text-sm px-3 py-1 h-8 rounded-md text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 border text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 inline"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            Save
          </button>

          <button
            v-if="isUpdate"
            @click="update()"
            type="button"
            id="updateBtn"
            class="font-normal text-sm px-5 py-2 rounded-md text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 border bg-gray-500 text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 inline"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            Update
          </button>

          <button
            type="button"
            @click="closeModal"
            class="text-sm text-gray-400 font-normal px-5 py-1 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600 border rounded"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
  name: "AnnouncementModal",
  props: ["announcement", "itemToUpdate"],
  data() {
    return {
      fileData: [],
      title: null,
      title_err: false,
      isUploadImage: false,
      isImgUrlHasOne: false,
      imgUrls: [],
      captions: null,
      captions_err: false,
      imgs: null,
      isUpdate: false,
      itemToDelete: [],
      updateAnnouncement: null,
      userID: null,
      title1: false,
      caption1: false,
      defTitle: null,
      defCaption: null,
      fileData_err: false,
    };
  },

  watch: {
    itemToUpdate: {
      handler(val) {
        // console.log(val);
        if (!val) {
          this.announcementID = null;
          this.captions = null;
          this.title = null;
          this.isUpdate = false;
          this.userID = false;
        } else {
          this.isUpdate = true;

          if (val.filename && val.filename != "" && val.filename != []) {
            var f = JSON.parse(val.filename);
            f.forEach((element) => {
              var a = this.getImage(element);
              var prom = Promise.resolve(a);
              prom.then((val) => {
                var st = JSON.stringify(val);
                var con = JSON.parse(st);
                this.imgUrls.push(con);
                // console.log(con);
              });
            });
          }
          this.isUploadImage = true;
          this.announcementID = val.id;
          this.captions = val.caption;
          this.title = val.title;
          this.userID = val.userID;
          this.defTitle = val.title;
          this.defCaption = val.caption;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    // if (!this.isUpdate) {
    //   let updateBtn = document.getElementById("updateBtn");
    //   updateBtn.disabled = true;
    //   updateBtn.classList.add("bg-gray-500");
    //   updateBtn.classList.remove("bgColor");
    // }
  },

  methods: {
    hasChanged(event) {
      if (event.target.id == "title") {
        if (event.currentTarget.value.length == 0) {
          this.title1 = false;
        }
        if (event.currentTarget.value.length >= 1) {
          this.title1 = true;
        }
      }

      if (event.target.id == "caption") {
        if (event.currentTarget.value.length == 0) {
          this.caption1 = false;
        }
        if (event.currentTarget.value.length >= 1) {
          this.caption1 = true;
        }
      }
    },
    hasChanged1(event) {
      let updateBtn = document.getElementById("updateBtn");
      let defTitle = this.defTitle;
      let defCaption = this.defCaption;
      if (event.target.id == "title1") {
        if (event.currentTarget.value.length == String(defTitle).length) {
          if (
            event.currentTarget.value == defTitle &&
            this.fileData.length == 0 &&
            this.imgUrls.length == 1
          ) {
            updateBtn.disabled = true;
            updateBtn.classList.add("bg-gray-500");
            updateBtn.classList.remove("bgColor");
          } else {
            updateBtn.disabled = false;
            updateBtn.classList.remove("bg-gray-500");
            updateBtn.classList.add("bgColor");
          }
        }
        if (
          event.currentTarget.value.length > String(defTitle).length ||
          event.currentTarget.value.length < String(defTitle).length
        ) {
          if (event.currentTarget.value.length != 0) {
            if (this.fileData.length > 0 || this.imgUrls.length != 0) {
              updateBtn.disabled = false;
              updateBtn.classList.remove("bg-gray-500");
              updateBtn.classList.add("bgColor");
            } else if (this.fileData.length == 0) {
              updateBtn.disabled = true;
              updateBtn.classList.add("bg-gray-500");
              updateBtn.classList.remove("bgColor");
            }
          } else {
            updateBtn.disabled = true;
            updateBtn.classList.add("bg-gray-500");
            updateBtn.classList.remove("bgColor");
          }
        }
      }

      if (event.target.id == "caption1") {
        if (event.currentTarget.value.length == String(defCaption).length) {
          if (
            event.currentTarget.value == defCaption &&
            this.fileData.length == 0 &&
            this.imgUrls.length == 1
          ) {
            updateBtn.disabled = true;
            updateBtn.classList.add("bg-gray-500");
            updateBtn.classList.remove("bgColor");
          } else if (
            event.currentTarget.value != defCaption &&
            this.fileData.length != 0 &&
            this.imgUrls.length != 0
          ) {
            updateBtn.disabled = false;
            updateBtn.classList.remove("bg-gray-500");
            updateBtn.classList.add("bgColor");
          }
        }
        if (
          event.currentTarget.value.length > String(defCaption).length ||
          event.currentTarget.value.length < String(defCaption).length
        ) {
          if (event.currentTarget.value.length != 0) {
            if (this.fileData.length > 0 || this.imgUrls.length != 0) {
              updateBtn.disabled = false;
              updateBtn.classList.remove("bg-gray-500");
              updateBtn.classList.add("bgColor");
            } else if (this.fileData.length == 0) {
              updateBtn.disabled = true;
              updateBtn.classList.add("bg-gray-500");
              updateBtn.classList.remove("bgColor");
            }
          } else {
            updateBtn.disabled = true;
            updateBtn.classList.add("bg-gray-500");
            updateBtn.classList.remove("bgColor");
          }
        }
      }
    },
    async getImage(filename) {
      // console.log(filename);
      var img;
      return await axios
        .get(
          process.env.VUE_APP_API_SERVER +
            "/announcement/stream-file/" +
            filename,
          {
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then((response) => {
          // console.log(response.data);
          const blob = new Blob([response.data]);
          // console.log(blob);
          var objectURL = URL.createObjectURL(blob);
          // console.log(objectURL);
          var n = {
            url: objectURL,
            name: filename,
          };
          return n;
          // console.log(n)
          // this.imgUrls.push(img);
        });
    },

    dropt(ev) {
      ev.preventDefault();

      const dropfls = ev.dataTransfer.files;

      for (let i = 0; i < dropfls.length; i++) {
        var urls = URL.createObjectURL(dropfls[i]);
        var img = {
          url: urls,
          name: dropfls[i].name,
        };
        this.imgUrls.push(img);

        this.fileData.push(dropfls[i]);
      }
    },
    onFileChange(event) {
      const ed = event.target.files;

      for (let i = 0; i < ed.length; i++) {
        var urls = URL.createObjectURL(ed[i]);
        var img = {
          url: urls,
          name: ed[i].name,
        };
        this.imgUrls.push(img);

        this.fileData.push(ed[i]);
      }
      if (ed.length > 0) {
        let updateBtn = document.getElementById("updateBtn");
        updateBtn.disabled = false;
        updateBtn.classList.remove("bg-gray-500");
        updateBtn.classList.add("bgColor");
      } else {
        let updateBtn = document.getElementById("updateBtn");
        updateBtn.disabled = true;
        updateBtn.classList.add("bg-gray-500");
        updateBtn.classList.remove("bgColor");
      }
    },

    imgToDelete(filename) {
      if (this.isUpdate) {
        this.itemToDelete.push(filename);
        this.imgUrls = this.imgUrls.filter(
          (imgurl) => imgurl.name !== filename
        );
        this.fileData = this.fileData.filter((file) => file.name !== filename);
      } else {
        this.imgUrls = this.imgUrls.filter(
          (imgurl) => imgurl.name !== filename
        );
        this.fileData = this.fileData.filter((file) => file.name !== filename);
      }
      document.querySelector("#dropzone-file").value = "";
      let updateBtn = document.getElementById("updateBtn");
      updateBtn.disabled = true;
      updateBtn.classList.add("bg-gray-500");
      updateBtn.classList.remove("bgColor");
    },

    save() {
      const toast = useToast();
      if (!this.captions) {
        this.captions_err = true;
        toast.error("Captions is required.", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (!this.title) {
        this.title_err = true;
        toast.error("Title is required.", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (!this.title_err && !this.captions_err) {
        const fd = new FormData();
        this.fileData.forEach((element) => {
          fd.append("files", element);
        });

        fd.append("caption", this.captions);
        fd.append("title", this.title);

        axios
          .post(
            process.env.VUE_APP_API_SERVER + "/announcement/uploadAnnouncement",
            fd,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
              },
            }
          )
          .then(
            (response) => {
              const bgModal = document.getElementById("thisModal");
              bgModal.classList.remove("modal_open");
              bgModal.classList.add("modal_close");

              setTimeout(() => {
                this.$emit("closeModal");
                bgModal.classList.add("modal_open");
                bgModal.classList.remove("modal_close");
              }, 500);
              this.$emit("refreshTable");
              if (response.data.status == 201 || response.data.status == 200) {
                toast.success("Announcement saved.", {
                  position: "top-right",
                  timeout: 3000,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: false,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
      } else {
        return;
      }
    },

    update() {
      const toast = useToast();
      if (!this.captions) {
        this.captions_err = true;
        toast.error("Captions is required.", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } else if (this.captions) {
        this.captions_err = false;
      }

      if (!this.title) {
        this.title_err = true;
        toast.error("Title is required.", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } else if (this.title) {
        this.title_err = false;
      }

      if (this.fileData.length == 0) {
        if (this.imgUrls.length != 0) {
          this.fileData_err = true;
          let updateBtn = document.getElementById("updateBtn");
          updateBtn.disabled = true;
          updateBtn.classList.add("bg-gray-500");
          updateBtn.classList.remove("bgColor");
          toast.warning("No changes made.", {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        } else if (this.imgUrls.length == 0 && this.itemToDelete.length > 1) {
          this.fileData_err = true;
          let updateBtn = document.getElementById("updateBtn");
          updateBtn.disabled = true;
          updateBtn.classList.add("bg-gray-500");
          updateBtn.classList.remove("bgColor");
          toast.warning("Announcement image is required", {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
      } else if (this.fileData.length > 0) {
        this.fileData_err = false;
      }

      if (!this.title_err && !this.captions_err && !this.fileData_err) {
        const fd = new FormData();
        if (this.fileData) {
          this.fileData.forEach((element) => {
            fd.append("files", element);
          });
        }

        const fdata = {
          caption: this.captions,
          id: this.announcementID,
          title: this.title,
          userID: this.userID,
        };

        fd.append("announcementData", JSON.stringify(fdata));
        fd.append("itemToDelete", JSON.stringify(this.itemToDelete));

        axios
          .post(
            process.env.VUE_APP_API_SERVER + "/announcement/updateAnnouncement",
            fd,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
              },
            }
          )
          .then(
            (response) => {
              if (response.data.status == 201 || response.data.status == 200) {
                toast.success("Update saved.", {
                  position: "top-right",
                  timeout: 3000,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: false,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
                const bgModal = document.getElementById("thisModal");
                bgModal.classList.remove("modal_open");
                bgModal.classList.add("modal_close");

                setTimeout(() => {
                  this.$emit("closeModal");
                  bgModal.classList.add("modal_open");
                  bgModal.classList.remove("modal_close");
                }, 500);
                this.$emit("refreshTable");
              }
            },
            (error) => {
              console.log(error);
            }
          );
      } else {
        return;
      }
    },

    closeModal() {
      const bgModal = document.getElementById("thisModal");
      bgModal.classList.remove("modal_open");
      bgModal.classList.add("modal_close");

      setTimeout(() => {
        this.$emit("closeModal");
        bgModal.classList.add("modal_open");
        bgModal.classList.remove("modal_close");
      }, 500);
    },
  },
};
</script>

<style scoped>
.bgGreen {
  color: #105005;
}

.inputIndent {
  text-indent: 10px;
}

.out {
  transform: scale(1);
  animation: unfoldOut 1s 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  animation: zoomOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@keyframes animateBottom {
  from {
    top: 0px;
    opacity: 1;
  }
  to {
    bottom: 300px;
    opacity: 0;
  }
}

.modal-content-active {
  -webkit-animation-name: animateBottom;
  -webkit-animation-duration: 0.4s;
  animation-name: animateBottom;
  animation-duration: 0.4s;
}
</style>