<template>
  <div>Logging out</div>
</template>

<script>
import axios from "axios";
export default {
  name: "Logout",
  mounted() {
    // this.init();
    this.logout();
    // if (this.$store.getters.getIsGoogleSignIn == 1) {
    //   this.$gAuth.signOut();
    // }
  },
  methods: {
    logout() {
      const fd = {
        userdetailID: this.$store.getters.getUserdetailID
      };
      axios.post(process.env.VUE_APP_API_SERVER + "/auth/logout", fd).then(
        (response) => {
          if (response.data.status == 200 || response.data.status == 201 || response.data.status == 202) {
            this.$store.commit("setToken", null);
            this.$store.commit("setIsValidated", 0);
            this.$store.commit("setUserType", null);
            this.$store.commit("setUserEmail", null);
            this.$store.commit("setUserID", null);
            this.$store.commit("setUserFirstName", null);
            this.$store.commit("setUserMidName", null);
            this.$store.commit("setUserLastName", null);
            this.$store.commit("setUserRole", null);
            this.$store.commit("setUserdetailID", null);
            this.$store.commit("setIsAdminVerified", 0);
            this.$store.commit("setIsGoogleSignIn", 0);
            localStorage.removeItem("vuex");
            this.$router.push("/signin/login");
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
};
</script>

<style></style>