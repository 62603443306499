<template>
  <div class="w-full dark:bg-darkBG">
    <div
      class="grid md:flex items-center sm:xl:justify-between md:justify-between xl:justify-between lg:justify-between bg-gray-100 dark:bg-darkBG mb-2 pt-2"
    >
      <div class="w-1/2">
        <h1
          class="md:text-xl text-xs lg:text-xl font-semibold py-5 pl-6 text-gray-900 dark:text-gray-300"
        >
          Main Menu - Articles
        </h1>
        <h1
          class="text-sm font-normal ml-6 -mt-4 text-gray-500 mb-10 dark:text-gray-400"
        >
          Search Articles
        </h1>
      </div>
      <div
        class="hidden md:flex md:justify-end lg:flex lg:justify-end xl:flex xl:justify-end sm:hidden mr-3 -mt-6 xl:overflow-hidden"
      >
        <div class="relative sm:w-60 md:w-96 px-4">
          <input
            type="text"
            v-model="searchval"
            @keypress.enter="searchBtn"
            class="block py-1 px-2 w-full z-20 text-sm text-gray-600 bg-gray-50 dark:bg-darkBG dark:text-gray-200 outline-none rounded-lg border-l-gray-50 border-l-2 border border-gray-400 focus:ring-gray-200 focus:border-gray-200"
            placeholder="Search by anything"
            required
          />
          <button
            type="submit"
            @click="searchBtn"
            class="absolute top-0 right-0 py-1 px-2 text-sm font-medium text-white bg-customGreen rounded-tr-lg rounded-br-lg border focus:ring-4 focus:outline-none focus:ring-blue-300"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
            <span class="sr-only">Search</span>
          </button>
        </div>
      </div>
    </div>

    <!-- <div class="flex justify-center mt-16" v-if="searchText == null">
      <div class="mb-3 xl:w-96">
        <div class="input-group relative flex flex-wrap w-full mb-4">
          <label class="relative block">
            <span
              class="
                absolute
                inset-y-0
                left-0
                flex
                text-gray-500
                items-center
                pl-2
                pr-2
              "
            >
              <svg
                class="h-5 w-5 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="30"
                height="30"
                viewBox="0 0 30 30"
              >
                <path
                  d="M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z"
                ></path>
              </svg>
            </span>
            <input
              v-model="defaultSearchInp"
              aria-label="Search"
              aria-describedby="button-addon2"
              @keypress.enter="handleSearch"
              class="
                text-gray-500
                bg-gray-100
                customSearchWidth
                placeholder:font-italitc
                border border-gray-200
                rounded-full
                min-w-0
                block
                w-full
                text-sm
                py-1
                pl-10
                pr-4
              "
              placeholder="Enter your keyword to search"
              type="text"
            />
          </label>
        </div>
      </div>
    </div> -->

    <div class="w-full">
      <!-- <JournalTable v-on:viewJournal="ViewJournal" v-on:searchText="storeSearchedText" v-if="!view_journal"
        :searchval="searchText" :isDataShow="true" /> -->
      <JournalResults v-on:viewJournal="ViewJournal" v-if="!view_journal" />
      <JournalView
        v-if="view_journal"
        :isLogged="true"
        :journalData="journal"
        :file="journalFile"
        v-on:back="ViewJournalTable"
      />
    </div>
  </div>
</template>

<script>
import JournalTable from "../components/JournalTable.vue";
import JournalView from "../components/JournalView.vue";
import JournalResults from "../components/JournalResults.vue";
import axios from "axios";
export default {
  name: "UsersJournal",
  components: {
    JournalTable,
    JournalView,
    JournalResults,
  },
  data() {
    return {
      isLoading: false,
      view_journal: false,
      journal: null,
      journalFile: "",
      defaultSearchInp: null,
      searchText: null,
      searchval: null,
    };
  },
  created() {
    this.defaultSearchInp = null;
  },

  methods: {
    searchBtn() {
      if (this.searchval == null || this.searchval == "") {
        return;
      } else {
        this.$router.push({
          name: "UserSearch",
          params: { data_text: this.searchval },
        });
      }
    },
    // handles rendering of pdf files to UI
    ViewJournal(item) {
      // console.log(item);

      const entry = {
        fileID: item.id,
        userID: this.$store.getters.getUserID,
      };
      axios
        .post(process.env.VUE_APP_API_SERVER + "/viewedfiles/addEntry", entry, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            if (response.data.status == 202) {
              this.journalFile = item.filename;
              this.journal = item;
              // console.log(item);
              this.view_journal = true;
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },

    storeSearchedText(text) {
      this.searchText = text;
    },

    //removes PDF embeded div
    //handles viewing of the table
    ViewJournalTable() {
      const el = document.querySelector(".vue-pdf-embed");
      if (el) {
        while (el.hasChildNodes()) {
          el.removeChild(el.firstChild);
        }
        el.remove();
      }
      this.view_journal = false;
      // this.$destroy();
    },

    // handles searchin of journals
    // handleSearch() {
    //   if (this.defaultSearchInp == "" || this.defaultSearchInp == null) {
    //     return;
    //   } else {
    //     this.searchText = this.defaultSearchInp;
    //   }
    // },
  },
};
</script>

<style scoped>
.customSearch {
  width: 20rem;
}

.customSearchWidth {
  width: 20rem;
}
</style>
