import { createApp } from 'vue';
import App from './App.vue';
const app = createApp(App);
import router from './router';
import store from './store';
import "tailwindcss/tailwind.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
 import 'tw-elements';
// import gAuthPlugin from 'vue3-google-oauth2'
// import AOS from 'aos';
import "aos/dist/aos.css"
// app.AOS = new AOS.init()
const options = {
    transition: "Vue-Toastification__bounce",
     maxToasts: 10,
    newestOnTop: true
};

// // google oauth options
// const gAuthOptions = {
 
//     clientId : process.env.VUE_APP_GOOGLE_CLIENTID,
// //    callback : 'http://172.16.20.6:8080',
//     callback: 'http://localhost:8080',
//     plugin_name: 'ReDaMS'
// }



// createApp(App).use(store).use(router).mount('#app')
// app.use(store).use(gAuthPlugin, gAuthOptions).use(router).use(Toast, options).mount('#app')
app.use(store).use(router).use(Toast, options).mount('#app')
