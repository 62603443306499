<template>
  <div class="w-auto">
    <div
      class="grid md:flex relative items-center sm:xl:justify-between md:justify-between xl:justify-between lg:justify-between bg-gray-100 dark:bg-darkBG mb-2 mt-2 rounded-md"
    >
      <div class="w-1/2">
        <h1
          class="md:text-xl text-xs lg:text-xl font-semibold py-5 pl-6 text-gray-900 dark:text-gray-200"
        >
          Account - User Tutorials
        </h1>
        <h1 class="text-sm font-normal ml-6 -mt-4 text-gray-500 mb-10">
          List of Tutorials
        </h1>
      </div>
      <div
        class="hidden md:flex md:justify-end lg:flex lg:justify-end xl:flex xl:justify-end sm:hidden mr-3 -mt-6 overflow-hidden"
      >
        <div class="relative sm:w-60 md:w-96 px-4">
          <input
            v-model="searchval"
            type="text"
            class="block py-1 px-2 w-full z-20 text-sm text-gray-600 bg-gray-50 rounded-lg border-l-gray-50 border-l-2 border border-gray-300"
            placeholder="Search by anything"
            required
            @keypress.enter="searchBtn"
          />
          <button
            type="submit"
            @click="searchBtn"
            class="absolute top-0 right-0 py-1 px-2 text-sm font-medium text-white bg-customGreen rounded-tr-lg rounded-br-lg border focus:ring-4 focus:outline-none focus:ring-blue-300"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
            <span class="sr-only">Search</span>
          </button>
        </div>
      </div>
    </div>

    <div class="w-auto h-auto pb-12">
      <div
        class="filter_by w-auto md:w-auto overflow-hidden flex flex-row-reverse bg-white mx-3 lg:h-auto md:h-auto md:py-2 py-5 md:px-0 px-5 m-auto rounded-md"
      >
        <div
          class="w-full md:flex-wrap md:flex lg:flex md:px-3 px-0 font-poppins"
        >
          <div class="md:grid text-left md:mx-2 md:my-2 w-full">
            <h4
              class="hidden md:flex text-gray-500 font-semibold text-xs md:text-xs mb-2"
            >
              Tutorials
            </h4>
            <div
              class="md:mr-4 lg:mr-4 xl:mr-0 flex md:justify-between"
              id="btns"
            >
              <div class="w-full flex gap-1">
                <input
                  v-model="searchText"
                  @keyup="handleSearching"
                  type="search"
                  class="indent py-2.5 px-1.5 w-full md:w-1/2 flex grow lg:w-1/2 xl:w-1-2 2xl:w-1/2 text-xs font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded md:rounded-md lg:rounded-md transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-500 focus:outline-none"
                  placeholder="Search for tutorials"
                />
                <!-- REFRESHES TABLE -->
                <button
                  @click="getMyJournals"
                  class="text-gray-400 bg-white border grow-0 border-gray-300 px-3 focus:border-green-500 focus:outline-none font-medium rounded-lg text-sm p-2 text-center items-center focus:text-green-500 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isLoading" class="flex justify-center py-8">
        <div class="spinLoader"></div>
      </div>

      <div
        class="journal-tbl bg-white rounded w-auto m-auto mt-4 pb-4 mx-3"
        v-if="!isLoading"
      >
        <div class="flex flex-col mx-5">
          <div class="overflow-x-auto">
            <div class="inline-block min-w-full">
              <div class="overflow-hidden mt-5 flex gap-2">
                <div
                  class="withBGDesign max-w-sm hover:border-yellow-500 w-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
                >
                  <a href="#">
                    <h5
                      class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"
                    >
                      Accessing Website and User Registration
                    </h5>
                  </a>
                  <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                    1:09 <span class="px-1">•</span> Video
                  </p>
                  <a
                    href="#"
                    class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-FFB800 rounded-lg hover:bg-yellow-600 focus:ring-1 focus:outline-none focus:ring-yellow-800"
                    @click="
                      openModal(1, 'Accessing Website and User Registration')
                    "
                  >
                    Learn how
                    <svg
                      class="w-3.5 h-3.5 ml-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M1 5h12m0 0L9 1m4 4L9 9"
                      />
                    </svg>
                  </a>
                </div>
                <div
                  class="max-w-sm hover:border-FFB800 w-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
                >
                  <a href="#">
                    <h5
                      class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"
                    >
                      Accessing the website using Google Sign In
                    </h5>
                  </a>
                  <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                    1:05
                    <span class="px-1">•</span> Video
                  </p>
                  <a
                    href="#"
                    class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-FFB800 rounded-lg hover:bg-yellow-600 focus:ring-1 focus:outline-none focus:ring-yellow-800"
                    @click="
                      openModal(2, 'Accessing the website using Google Sign In')
                    "
                  >
                    Learn how
                    <svg
                      class="w-3.5 h-3.5 ml-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M1 5h12m0 0L9 1m4 4L9 9"
                      />
                    </svg>
                  </a>
                </div>
                <div
                  class="max-w-sm hover:border-FFB800 w-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
                >
                  <a href="#">
                    <h5
                      class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"
                    >
                      How to upload Articles in ReDMaS
                    </h5>
                  </a>
                  <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                    1:28 <span class="px-1">•</span> Video
                  </p>
                  <a
                    href="#"
                    class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-FFB800 rounded-lg hover:bg-yellow-600 focus:ring-1 focus:outline-none focus:ring-yellow-800"
                    @click="openModal(3, 'How to upload Articles in ReDMaS')"
                  >
                    Learn how
                    <svg
                      class="w-3.5 h-3.5 ml-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M1 5h12m0 0L9 1m4 4L9 9"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UserTutorialModal
          v-if="showModal"
          v-on:closeModal="closeModal"
          :video="video"
          :videoName="videoName"
        />
      </div>
    </div>
  </div>
</template>
            
            <script>
import MyWorksTable from "../components/MyWorksTable.vue";
import MyWorksModal from "../modals/MyWorksModal.vue";
import MyWorkUpdateModal from "../modals/MyWorkUpdateModal.vue";
import { useToast } from "vue-toastification";
import UserTutorialModal from "../modals/UserTutorialModal.vue";
export default {
  name: "UserMyWork",
  components: {
    MyWorksTable,
    MyWorksModal,
    MyWorkUpdateModal,
    UserTutorialModal,
  },
  data() {
    return {
      showUpdateModal: false,
      showAddModal: false,
      video: 1,
      videoName: null,
      showModal: false,
      isLoading: false,
      modalAction: "",
      item: null,
      refreshData: false,
      notifID: null,
      searchval: null,
      searchText: null,
    };
  },

  mounted() {
    this.notifID = this.$route.params.fileid;
  },
  watch: {
    "$route.params.fileid": {
      handler(value) {
        this.notifID = value;
      },
    },
  },

  methods: {
    openModal(i, name) {
      this.showModal = true;
      this.videoName = name;
      this.video = i;
    },
    closeModal() {
      this.showModal = false;
      this.videoName = null;
      this.video = 0;
    },
    searchBtn() {
      if (this.searchval == null || this.searchval == "") {
        return;
      } else {
        this.$router.push({
          name: "UserSearch",
          params: { data_text: this.searchval },
        });
      }
    },
    refreshTable() {
      this.refreshData = true;
      setTimeout(() => {
        this.refreshData = false;
      }, 100);
    },
    action(act) {
      if (act.action == "add") {
        this.modalAction = "Add New Journal";
        this.item = null;
      } else {
        this.modalAction = "Update Journal";
        Object.assign(act.item, { pendingDetails: act.pendingDetails });
        this.item = act.item;
      }
    },
  },
};
</script>
<style scoped>
.customSearch {
  width: 20rem;
}
.modal_hgt {
  height: 36rem;
}
.form_modal_hgt {
  height: 26rem;
}
</style>
            