<template>
  <div id="defaultModal" tabindex="-1" aria-hidden="true" class=" bgModal fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full justify-center items-center " >
    <div style="margin-bottom: 100%" class="relative mt-20 m-auto w-full h-full max-w-md max-h-full p-4 md:h-auto">
      <div id="divModal" class=" modal relative bg-white max-h-full overflow-hidden rounded-lg shadow dark:bg-gray-700 animated modal_open" >
        <!-- modal header -->
        <div class="flex justify-between py-2 px-2">
          <h1 class="text-gray-800 text-sm"></h1>
          <button type="button" @click="closeModal" class=" absolute top-1 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white " data-modal-toggle="authentication-modal" >
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- modal content -->
        <div class="grid items-center">
          <div class="m-auto px-2 py-5">
            <img src="../assets/bg/Ok-face-bro.png" class="w-auto h-auto" alt="" />
          </div>
          <p class="m-auto pb-5 text-sm text-gray-800 font-semibold">
            Please
            <span class="text-blue-500 mycursor hover:underline" @click="redirect('login')" >Login</span >
            or
            <span class="text-blue-500 mycursor hover:underline" @click="redirect('register')" >Register</span >
            to continue.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PeaseLoginModal",

  data() {
    return {};
  },
  mounted() {
    document.getElementById("divModal").classList.add("modal_open");
    document.getElementById("divModal").classList.remove("modal_close");
  },

  methods: {
    closeModal() {
      document.getElementById("divModal").classList.remove("modal_open");
      document.getElementById("divModal").classList.add("modal_close");
      setTimeout(() => this.$emit("closeModal"), 400);
    },
    redirect(loc) {
      this.$emit("redirect", loc);
    },
  },
};
</script>

<style scoped>
.mycursor {
  cursor: pointer;
}
</style>