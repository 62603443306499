<template>
  <div class="container1">
    <!-- <LandingNav /> -->
    <div class="landing-body">
      <div class="flex h-screen">
        <div class="m-auto text-center">
          <!-- <div style="background-color: rgba(255, 255, 255, .2); color:green;" class="p-5"><h1 class="text-6xl font-semibold">Davao del Norte State College</h1></div> -->
          <h1 class="text-6xl text-white font-semibold">Research Database</h1>
          <h1 class="text-6xl text-FFB800 font-semibold -mt-3">
            Management System
          </h1>
          <div class="w-10/12 md:w-1/2 m-auto text-white mt-5">
            <p class="text-center text-sm px-4 font-poppins">
              This database management system contains the thesis /
              dissertations / publications of the graduate students, and faculty
              of the
              <span class="font-semibold">Davao del Norte State College</span>
              which is accessible to the academic community.
            </p>
          </div>
          <div class="flex justify-center md:w-1/2 mx-auto mt-8">

            <div class=" relative block w-full sm:w-full md:w-full px-4 mr-4 md:mr-0 ">
              <span class=" absolute w-auto inset-y-0 left-5 flex text-gray-500 items-center pl-3 pr-2 ">
                <svg class="h-5 w-5 fill-current" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30"
                  height="30" viewBox="0 0 30 30">
                  <path
                    d="M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z">
                  </path>
                </svg>
              </span>
              <input type="text" v-model="searchval" @keypress.enter="searchBtn"
                class="outline-none pl-10 block py-3 px-2 w-full z-20 text-sm text-gray-600 bg-gray-50 rounded-full border-l-gray-50 border-l-2 border border-gray-300 focus:ring-green-600 focus:border-green-600 "
                placeholder="Search for Title, Author, or Keywords..." required />
              <button @click="searchBtn" type="submit"
                class=" absolute top-0 right-0 py-3.5 px-2 text-xs font-semibold bg-FFB800 rounded-full text-black md:w-1/5 w-1/5 border focus:ring-4 focus:outline-none focus:ring-blue-300 ">
                SEARCH
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div data-aos="fade-up" data-aos-duration="3000"
      class=" features_div features grid grid-flow-row auto-rows-max mt-40 w-10/12 py-6 px-4 m-auto rounded-lg border dark:bg-gray-800 dark:border-gray-700 ">
      <div class="w-full">
        <div class="w-full px-4 md:w-1/2 text-center m-auto">
          <h1 class="h_3 text-105005 font-semibold">Features</h1>
          <p class="text-sm text-gray-500 text-center">
            <strong>DNSC RedMas</strong> contains theses, dissertations, research,
            and publications shared by registered users and is accessible to the
            academic community.
          </p>
        </div>
      </div>
      <div class="w-full">
        <div class="grid lg:flex md:flex justify-center w-4/5 mb-10 m-auto mt-10">
          <div
            class=" w-full md:w-1/3 lg:w-1/3 xl:w-1/3 px-3 py-3 animate_card feature-card mt-4 mx-1 max-w-sm rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 bg-white">
            <a class="flex justify-center">
              <img class="rounded-t-lg mt-4 mb-4 w-24 h-24" src="../assets/bg/icon1.png" alt="" />
            </a>
            <div class="div-card ml-4 mr-4 mb-5">
              <a class="text-center">
                <h5 class=" mt-4 text-2xl font-medium tracking-tight text-gray-700 dark:text-white ">
                  Upload Researches
                </h5>
              </a>
              <p class=" mt-4 mb-3 text-sm font-normal text-center text-gray-500 dark:text-gray-400 ">
                Registered users from around the world can upload and share
                their research with ease using <strong>ReDMaS</strong>.
              </p>
            </div>
          </div>

          <div
            class=" w-full md:w-1/3 lg:w-1/3 xl:w-1/3 px-3 py-3 animate_card feature-card mt-4 max-w-sm mx-1 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 ">
            <a class="flex justify-center">
              <img class="rounded-t- mt-4 mb-4 w-24 h-24" src="../assets/bg/icon2.png" alt="" />
            </a>
            <div class="div-card ml-4 mr-4 mb-5">
              <a class="text-center">
                <h5 class=" mt-4 text-2xl font-medium tracking-tight text-gray-700 dark:text-white ">
                  Browse Journals
                </h5>
              </a>
              <p class=" mt-4 mb-3 text-sm font-normal text-center text-gray-500 dark:text-gray-400 ">
                Anyone can easily access <strong>ReDMaS</strong> and browse
                journals / researches from any category relevant to the search.
              </p>
            </div>
          </div>

          <div
            class=" w-full md:w-1/3 lg:w-1/3 xl:w-1/3 px-3 py-3 animate_card feature-card mt-4 max-w-sm mx-1 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 ">
            <a class="flex justify-center">
              <img class="rounded-t-lg mt-4 mb-4 w-24 h-24" src="../assets/bg/icon1.png" alt="" />
            </a>
            <div class="div-card ml-4 mr-4 mb-5">
              <a class="text-center">
                <h5 class=" mt-4 text-2xl font-medium tracking-tight text-gray-700 dark:text-white ">
                  Download PDF
                </h5>
              </a>
              <p class=" mt-4 mb-3 text-sm font-normal text-center text-gray-500 dark:text-gray-400 ">
                Registered users can access published journals featured on
                <strong>ReDMaS</strong> and download them from their publisher.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="div3" data-aos="fade-up" data-aos-duration="3000"
      class="div3 grid lg:flex md:flex w-10/12 m-auto mt-60 mb-20 py-4">
      <div class="w-1/2 ml-14">
        <img src="../assets/bg/div3_img.png" class="hidden md:flex" />
      </div>
      <div class="w-full lg:w-1/2 md:w-1/2 px-10 text-left">
        <div class="div3_h1">
          <h1 class="text-2xl md:text-6xl font-semibold">
            <span class="text-FFB800">Everything</span> you need to know about
            <span class="text-105005">ReDMaS</span>
          </h1>
          <p class="text-sm mt-4 text-justify text-gray-600 font-poppins">
            RedMas contains theses, dissertations, research, and publications
            shared by registered users and is accessible to the academic
            community.
          </p>
        </div>
        <div class="hidden md:flex mt-7 m-auto">
          <div class=" mb-2 w-full md:w-1/3 bg-bgYOpacity py-4 rounded-md mx-2 text-center ">
            <h1 class="h_3 font-semibold text-FFB800">
              {{ total_uploads }}
            </h1>
            <h1 class="text-xs font-semibold font-poppins text-gray-600">
              File Uploads
            </h1>
          </div>

          <div class=" mb-2 w-full md:w-1/3 bg-bgYOpacity py-4 rounded-md mx-2 text-center ">
            <h1 class="h_3 font-semibold text-FFB800">{{ siteVisitCount }}</h1>
            <h1 class="text-xs font-semibold font-poppins text-gray-600">
              People Browsing
            </h1>
          </div>

          <div class=" mb-2 w-full md:w-1/3 bg-bgYOpacity py-4 rounded-md mx-2 text-center ">
            <h1 class="h_3 font-semibold text-FFB800">
              {{ explored_count }}
            </h1>
            <h1 class="text-xs font-semibold font-poppins text-gray-600">
              File Explored
            </h1>
          </div>
        </div>
        <div class="grid sm:grid-cols-2 md:grid-cols-1 gap-2 md:hidden lg:hidden xl:hidden 2xl:hidden">
          <div class="w-full mt-8">
          <img src="../assets/bg/div3_img.png" class="flex h-60" />
        </div>
          <div class="grid grid-cols-1 w-full">
          <div class="grid md:flex mt-7 m-auto w-full">
            <div class=" mb-2 w-full md:w-1/3 bg-bgYOpacity py-4 rounded-md mx-2 text-center ">
              <h1 class="h_3 font-semibold text-FFB800">
                {{ total_uploads }}
              </h1>
              <h1 class="text-xs font-semibold font-poppins text-gray-600">
                File Uploads
              </h1>
            </div>

            <div class=" mb-2 w-full md:w-1/3 bg-bgYOpacity py-4 rounded-md mx-2 text-center ">
              <h1 class="h_3 font-semibold text-FFB800">{{ siteVisitCount }}</h1>
              <h1 class="text-xs font-semibold font-poppins text-gray-600">
                People Browsing
              </h1>
            </div>

            <div class=" mb-2 w-full md:w-1/3 bg-bgYOpacity py-4 rounded-md mx-2 text-center ">
              <h1 class="h_3 font-semibold text-FFB800">
                {{ explored_count }}
              </h1>
              <h1 class="text-xs font-semibold font-poppins text-gray-600">
                File Explored
              </h1>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    

    <div class="border border-transparent px-4">
      <div data-aos="fade-up" data-aos-duration="3000" id="div4"
        class=" most-dl-journal grid md:grid-flow-row md:auto-rows-max lg:grid-flow-row lg:auto-rows-max+ mt-40 md:mt-40 mb-20 md:mb-60 md:w-10/12 lg:w-10/12 py-6 m-auto rounded-lg dark:bg-gray-800 dark:border-gray-700 ">
        <div class="w-auto md:w-full mb-10 text-center">
          <h1 class="h_3 mt-4 text-center font-semibold">
            Most <span class="text-green-900">Viewed</span> Journals
          </h1>
          <p class="w-auto text-sm text-gray-500 m-auto px-4">
            Featured here are the most viewed journals in RedMas. These journals
            are ranked based on the number of times the file has been viewed.
            Only registered users can upload and download their journals with
            ease, and they can be ranked here depending on their user
            popularity.
          </p>
        </div>
        <div class="w-20 md:w-full lg:w-full">
          <div class=" grid px-6 md:grid lg:pl-20 lg:grid-rows-3 lg:gap-2 lg:grid-flow-col lg:w-full ">
            <div v-if="mostviewedresults <= 0" class="text-sm ml-10 italic text-green-800">
              No data found
            </div>
            <div v-else
              class=" flex h-12 w-80 sm:w-1/2 md:w-full lg:w-96 mb-6 rounded-tr-3xl rounded-br-3xl rounded-bl-3xl border bg-white "
              v-for="(item, index) in mostvieweddata" :key="index">
              <div class="
                  w-9
                  h-9
                  py-1
                  text-sm text-center text-white
                  bg-yellow-300
                  rounded-br-xl rounded-bl-md
                ">
                # {{ index + 1 }}
              </div>
              <div class="
                  w-full
                  text-xs
                  px-2
                  sm:w-full sm:px-2
                  md:w-full md:px-2
                  lg:w-full lg:px-2
                  ml-2
                  mt-1.5
                  text-gray-600
                  truncate
                  py-2
                ">
                {{ item.title }}
              </div>
              <div class=" max-w-sm w-8 md:w-8 h-8 py-1 text-sm text-center text-white rounded-br-xl "></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div data-aos="fade-up" data-aos-duration="3000"
      class="features grid grid-flow-row auto-rows-max w-10/12 py-4 px-4 m-auto rounded-lg dark:bg-gray-800 dark:border-gray-700 ">
      <Announcement />
    </div>

    <LandingFooter />
  </div>
</template>

<script>
// import LandingNav from '../components/LandingNav.vue'
import countapi from "countapi-js";
import AOS from "aos";
import axios from "axios";
import LandingFooter from "../components/LandingFooter.vue";
import Announcement from "../components/Announcements.vue";
export default {
  name: "Landing",
  data() {
    return {
      searchval: null,
      mostvieweddata: null,
      mostviewedresults: null,
      total_uploads: 0,
      siteVisitCount: 0,
      explored_count: 0,
    };
  },
  components: {
    // LandingNav,
    LandingFooter,
    Announcement,
  },

  created() {
    if (
      !this.$store.getters.isExpired &&
      this.$store.getters.getIsValidated == 1
    ) {
      this.$router.push("/userspage/dashboard");
    }
    // this.local = localStorage.getItem("vuex");
    this.getMostViewedData();
    this.getFileUploadCount();
    this.getExploredCount();
    // this.setVisitInitValue();
    this.incrementVisit();
  },

  mounted() {
    AOS.init();
  },
  methods: {
    searchBtn() {
      if (this.searchval == null || this.searchval == "") {
        return;
      } else {
        this.$router.push({
          name: "Journals",
          params: { data: this.searchval },
        });
      }
    },

    // get most viewed file count
    getMostViewedData() {
      axios
        .get(process.env.VUE_APP_API_SERVER + "/viewedfiles/visitcount")
        .then(
          (response) => {
            this.mostvieweddata = response.data;
            this.mostviewedresults = response.data.length;
          },
          (error) => {
            console.log(error);
          }
        );
    },
    // get file upload count
    getFileUploadCount() {
      axios
        .get(process.env.VUE_APP_API_SERVER + "/fileupload/fileuploadcount")
        .then(
          (response) => {
            // console.log();
            if (
              response.data[0].total_uploads != "" ||
              response.data[0].total_uploads != null
            ) {
              this.total_uploads = response.data[0].total_uploads;
            } else {
              this.total_uploads = 0;
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },

    getExploredCount() {
      axios
        .get(process.env.VUE_APP_API_SERVER + "/viewedfiles/exploreCount")
        .then(
          (response) => {
            if (
              response.data[0].Count != "" ||
              response.data[0].Count != null
            ) {
              this.explored_count = response.data[0].Count;
            } else {
              this.explored_count = 0;
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },

    getTotalVisits() {
      countapi
        .get("default", "9cec55df-c575-43eb-8f2f-8985989ae7b2")
        .then((result) => {
          // console.log(result);
        });
    },

    incrementVisit() {
      countapi.hit("9cec55df-c575-43eb-8f2f-8985989ae7b2").then((result) => {
        this.siteVisitCount = result.value;
      });
    },

    setVisitInitValue() {
      countapi.set("global", 0).then((result) => {
        // console.log(result);
      });
    },

    createCount() {
      countapi.create().then((result) => {
        // console.log(result);
      });
    },
  },
};
</script>

<style scoped>


.customSearchWidth {
  width: 25rem;
}

.h_1,
.h_2 {
  font-size: 60px;
}

.h_3 {
  font-size: 40px;
}

.boxwidth {
  width: 22rem;
}

/*     
    .container1{
        height: 1000px;
    } */
/* .img_icon {
  width: 100px;
} */



#div4,
.features_div {
  background-color: rgba(107, 114, 128, 0.1);
}

.animate_card {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.feature-card:hover {
  /* transform: translateY(20%); */
  -webkit-animation-name: mymove;
  animation-name: mymove;
}


/* xs */
@media only screen and (min-width: 350px) {
  .landing-body {
  background: url("../assets/bg/hero_banner2.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 50rem;
  background-color: transparent;
}
  @keyframes mymove {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(10%);
    }
  }
  .container1 {
  background: url("../assets/bg/Fast_loading-amico_1.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom right;
}
}

/* sm */
@media only screen and (min-width: 640px) {
  .landing-body {
  background: url("../assets/bg/hero_banner2.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 60rem;
  background-color: transparent;
}
  @keyframes mymove {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(10%);
    }
  }
  .container1 {
  background: url("../assets/bg/Fast_loading-amico_1.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom right;
}
}

/* md */
@media only screen and (min-width: 760px) {
  .landing-body {
  background: url("../assets/bg/hero_banner2.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 70rem;
  background-color: transparent;
}
  @keyframes mymove {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(10%);
    }
  }
  .container1 {
  background: url("../assets/bg/Fast_loading-amico_1.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom right;
}
}

/* lg */
@media only screen and (min-width: 1024px) {
  .landing-body {
  background: url("../assets/bg/hero_banner2.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 80rem;
  background-color: transparent;
}
  @keyframes mymove {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(10%);
    }
  }
  .container1 {
  background: url("../assets/bg/Fast_loading-amico_1.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom right;
}
}

/* xl */
@media only screen and (min-width: 1280px) {
  .landing-body {
  background: url("../assets/bg/hero_banner2.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 75rem;
  background-color: transparent;
}
  @keyframes mymove {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(10%);
    }
  }
  .container1 {
  background: url("../assets/bg/Fast_loading-amico_1.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom right;
}
}

/* 2xl */
@media only screen and (min-width: 1536px) {
  .landing-body {
  background: url("../assets/bg/hero_banner2.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 80rem;
  background-color: transparent;
}
  @keyframes mymove {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(10%);
    }
  }
  .container1 {
  background: url("../assets/bg/Fast_loading-amico_1.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom right;
}
}
</style>