<template>
  <div class="w-full">
    <div
      class="grid md:flex items-center sm:xl:justify-between md:justify-between xl:justify-between lg:justify-between bg-gray-100 mb-2 mt-2 rounded-md"
    >
      <div class="md:w-1/2">
        <h1
          class="md:text-xl text-xs lg:text-xl font-semibold py-5 pl-6 text-gray-900"
        >
          My Works - Article Approval
        </h1>
        <h1
          class="md:text-sm text-xs font-normal ml-7 -mt-4 text-gray-500 mb-10"
        >
          List of Journals
        </h1>
      </div>
      <div
        class="hidden md:flex md:justify-end lg:flex lg:justify-end xl:flex xl:justify-end sm:hidden mr-3 -mt-6 overflow-hidden"
      >
        <div class="relative sm:w-60 md:w-96 px-4">
          <input
            type="text"
            v-model="searchval"
            @keypress.enter="searchBtn"
            class="block py-1 px-2 w-full z-20 text-sm text-gray-600 bg-gray-50 rounded-lg border-l-gray-50 border-l-2 border border-gray-300 focus:ring-green-500 focus:border-green-500"
            placeholder="Search by anything"
            required
          />
          <button
            type="submit"
            @click="searchBtn"
            class="absolute top-0 right-0 py-1 px-2 text-sm font-medium text-white bg-customGreen rounded-tr-lg rounded-br-lg border focus:ring-4 focus:outline-none focus:ring-blue-300"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
            <span class="sr-only">Search</span>
          </button>
        </div>
      </div>
    </div>

    <!-- subnav -->
    <div
      class="py-2 mx-3 bg-white rounded-tl-md rounded-tr-md"
      v-show="!viewJournal"
    >
      <ul class="flex font-poppins ml-1" @click="toggleSubMenu($event)">
        <li v-if="this.$store.getters.getUserRole != 1">
          <a
            href="#"
            @click="forApproval"
            class="active px-4 uppercase text-xs font-semibold text-gray-700 tracking-wide"
            >For Verification</a
          >
        </li>
        <li v-if="this.$store.getters.getUserRole != 1">
          <a
            @click="approvedByDean"
            href="#"
            class="px-4 uppercase text-xs font-semibold text-gray-700 tracking-wide"
            >Verified</a
          >
        </li>
        <li v-if="this.$store.getters.getUserRole == 1">
          <a
            @click="approvedByDean"
            href="#"
            class="active px-4 uppercase text-xs font-semibold text-gray-700 tracking-wide"
            >For Publish</a
          >
        </li>
        <!-- <li v-if="this.$store.getters.getUserRole == 1"> <a href="#" @click="endorsed" class=" active px-4 uppercase text-xs font-semibold text-gray-700 tracking-wide ">For Publish</a> </li> -->
        <!-- <li v-if="this.$store.getters.getUserRole != 1"> <a @click="approvedByDean" href="#" class=" px-4 uppercase text-xs font-semibold text-gray-700 tracking-wide ">Published by Librarian</a> </li> -->
        <li v-if="this.$store.getters.getUserRole != 1">
          <a
            @click="approvedByLibrarian"
            href="#"
            class="px-4 uppercase text-xs font-semibold text-gray-700 tracking-wide"
            >Published By Librarian</a
          >
        </li>
        <li v-if="this.$store.getters.getUserRole == 1">
          <a
            @click="approvedByLibrarian"
            href="#"
            class="px-4 uppercase text-xs font-semibold text-gray-700 tracking-wide"
            >Published</a
          >
        </li>
        <!-- <li v-if="this.$store.getters.getUserRole != 1"> <a @click="endorsed" href="#" class=" px-4 uppercase text-xs font-semibold text-gray-700 tracking-wide ">Endorsed to Librarian</a> </li> -->
        <!-- <li v-if="this.$store.getters.getUserRole != 1"> <a @click="endorsed" href="#" class=" px-4 uppercase text-xs font-semibold text-gray-700 tracking-wide ">Endorsed</a> </li> -->

        <!-- <li v-if="this.$store.getters.getUserRole != 1"> <a @click="approvedByDean" href="#" class=" px-4 uppercase text-xs font-semibold text-gray-700 tracking-wide ">Approved by me</a> </li> -->

        <li>
          <a
            @click="pending"
            href="#"
            class="px-4 uppercase text-xs font-semibold text-gray-700 tracking-wide"
            >Pending</a
          >
        </li>
      </ul>
    </div>

    <ApprovalTable
      v-show="!viewJournal"
      :statusType="statusType"
      v-on:toggleView="toggleViewJournal"
      :isUpdatedResult="isUpdatedDataNow"
      :notifID="notifID"
    />

    <ApprovalView
      v-if="viewJournal"
      :journal_item="journal_item"
      v-on:back="toggleBack"
      v-on:updateData1Nowverify="updateData1NowPls"
      v-on:updateData1NowPublication="updateData1NowPls"
    />
  </div>
</template>

<script>
import ApprovalTable from "../components/ApprovalTable.vue";
import ApprovalView from "../components/ApprovalView.vue";
// import DummyTable from "../components/DummyTable.vue";

export default {
  name: "UserJournalApproval",
  components: {
    ApprovalTable,
    ApprovalView,
    // DummyTable,
  },
  data() {
    return {
      isUpdatedDataNow: false,
      isLoading: false,
      showAddModal: false,
      srcPDF: "",
      content: null,
      viewJournal: false,
      viewFile: false,
      journal_item: "",
      showDummy: false,
      statusType: "",
      notifID: null,
      pendingRes: false,
      searchval: null,
    };
  },
  created() {
    this.isUpdatedDataNow = false;
    this.pendingRes = false;
  },

  mounted() {
    // console.log(this.$route.params.fileid);
    this.notifID = this.$route.params.fileid;
    // this.isUpdatedDataNow = false;
    this.pendingRes = false;
    if (this.$store.getters.getUserRole != 1) {
      this.forApproval();
    } else if (this.$store.getters.getUserRole == 1) {
      this.approvedByDean();
    }
  },

  watch: {
    "$route.params.fileid": {
      handler(value) {
        this.notifID = value;
      },
    },
  },

  methods: {
    searchBtn() {
      if (this.searchval == null || this.searchval == "") {
        return;
      } else {
        this.$router.push({
          name: "UserSearch",
          params: { data_text: this.searchval },
        });
      }
    },
    updateData1NowPls() {
      this.isUpdatedDataNow = true;
      setTimeout(() => (this.isUpdatedDataNow = false), 300);
      // this.$emit("isUpdatedResult");
    },
    updateData1NowPlsVer() {
      this.isUpdatedDataNow = true;
      setTimeout(() => (this.isUpdatedDataNow = false), 300);
    },
    // toggling sub menu
    toggleSubMenu(e) {
      var elems = document.querySelector(".active");
      if (elems !== null) {
        elems.classList.remove("active");
      }
      e.target.classList.add("active");
    },

    forApproval() {
      this.viewJournal = false;
      this.isLoading = true;
      this.showDummy = true;
      this.statusType = "forApproval";
    },
    approvedByDeanAndLib() {
      this.viewJournal = false;
      this.isLoading = true;
      this.statusType = "approvedByDeanAndLib";
    },
    approvedByDean() {
      this.viewJournal = false;
      this.isLoading = true;
      this.statusType = "approvedByDean";
    },
    approvedByLibrarian() {
      this.viewJournal = false;
      this.isLoading = true;
      this.statusType = "approvedByLibrarian";
    },
    endorsed() {
      this.viewJournal = false;
      this.isLoading = true;
      this.statusType = "endorsed";
    },
    pending() {
      this.viewJournal = false;
      this.isLoading = true;
      this.statusType = "pending";
    },

    toggleAddModal() {
      this.showAddModal = !this.showAddModal;
    },
    previewPDF() {
      const uploadedpdf = document.getElementById("inputPDF").files[0];
      // #toolbar=0
      // uploadedpdf.name = uploadedpdf.name + "#toolbar=0";
      // console.log(uploadedpdf);
      const pdfUrl = URL.createObjectURL(uploadedpdf);
      // console.log(uploadedpdf);
      if (uploadedpdf.type != "application/pdf") {
        alert("file not accepted");
        document.getElementById("inputPDF").value = null;
      } else {
        this.srcPDF = pdfUrl;
      }
    },

    //toggle the viewing of journals to approve
    toggleViewJournal(data) {
      this.viewJournal = true;
      this.journal_item = data;

      // console.log("this is data"+data);
    },

    //toggle to view pdf file
    toggleViewFile() {
      this.viewFile = true;
    },

    // go back to the journals table
    toggleBack() {
      const el = document.querySelector(".vue-pdf-embed");
      if (el) {
        while (el.hasChildNodes()) {
          el.removeChild(el.firstChild);
        }
        el.remove();
      }

      this.viewJournal = false;

      // this.isUpdatedDataNow = true;
    },
  },
};
</script>

<style scoped>
.modal_hgt {
  height: 36rem;
}

.form_modal_hgt {
  height: 26rem;
}

.customSearch {
  width: 20rem;
}

.active {
  text-decoration: underline;
  color: #43703f;
  text-underline-offset: 7px;
}
</style>
