<template>
  <div class="w-auto h-auto pb-12">
    <div
      class="filter_by w-auto md:w-auto overflow-hidden flex flex-row-reverse bg-white dark:bg-darkBG border dark:border-gray-600 mx-3 lg:h-auto md:h-auto md:py-2 py-5 md:px-0 px-5 m-auto rounded-md"
    >
      <!-- <button @click="toggleAddModal('add', '')" id="addNewArticle"
        class="addNewArticle hidden md:hidden text-white bg-customGreen uppercase h-10 mt-8 md:w-50 w-1/2 md:w-1/6 text-center float-right hover:cursor-pointer hover:bgColor hover:text-white group lg:flex items-center px-3 py-2 md:mr-5 text-xs font-medium rounded-md mb-5 md:mb-0">
        <svg class="fill-current group-hover:text-gray-300 flex-shrink-0 h-6 w-6 pr-2" xmlns="http://www.w3.org/2000/svg"
          fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4" />
        </svg>
        Add New
      </button> -->

      <!-- <h4 class="hidden md:flex ml-5 font-semibold text-gray-500 text-xs font-poppins">
        Filter by
      </h4> -->
      <div
        class="w-full md:flex-wrap md:flex lg:flex md:px-3 px-0 font-poppins"
      >
        <div class="md:grid text-left md:mx-2 md:my-2 w-full">
          <h4
            class="hidden md:flex text-gray-500 font-semibold text-xs md:text-xs mb-2"
          >
            Research Title
          </h4>
          <div
            class="md:mr-4 lg:mr-4 xl:mr-0 flex md:justify-between"
            id="btns"
          >
            <div class="w-full flex gap-1">
              <input
                v-model="searchText"
                @keyup="handleSearching"
                type="search"
                class="indent py-2.5 px-1.5 w-full md:w-1/2 flex grow lg:w-1/2 xl:w-1-2 2xl:w-1/2 text-xs font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 dark:bg-darkBG dark:focus:bg-darkBG rounded md:rounded-md lg:rounded-md transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-600 focus:outline-none"
                placeholder="Search for title"
              />

              <button
                @click="getMyJournals"
                class="text-gray-400 bg-white border grow-0 border-gray-300 px-3 focus:border-green-500 focus:outline-none font-medium rounded-lg text-sm p-2 text-center items-center focus:text-green-500 dark:text-white dark:bg-darkBG dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                type="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                  />
                </svg>
              </button>
              <button
                class="font-medium rounded-lg text-sm px-5 text-center inline-flex items-center border dark:bg-darkBG dark:bg-blue-300 border-blue-300"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-4 h-4 text-blue-400 dark:text-blue-600"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3.792 2.938A49.069 49.069 0 0112 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 011.541 1.836v1.044a3 3 0 01-.879 2.121l-6.182 6.182a1.5 1.5 0 00-.439 1.061v2.927a3 3 0 01-1.658 2.684l-1.757.878A.75.75 0 019.75 21v-5.818a1.5 1.5 0 00-.44-1.06L3.13 7.938a3 3 0 01-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836z"
                    clip-rule="evenodd"
                  />
                </svg>
                <!-- <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-three-dots"
              viewBox="0 0 16 16"
            >
              <path
                d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
              />
            </svg> -->
              </button>
              <ul
                class="dropdown-menu hidden bg-white dark:bg-darkBG border z-10 divide-y divide-gray-100 rounded-lg shadow w-48 dark:bg-gray-700 py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownMenuButton1"
              >
                <li class="pr-5">
                  <select
                    class="p-1.5 w-full mx-2 mb-2 border border-solid border-gray-300 rounded dark:text-gray-800 focus:outline-none"
                    @change="changeField($event)"
                    v-model="tableForFilter"
                  >
                    <option
                      v-for="item in table_filter"
                      :key="item"
                      :value="item.field"
                    >
                      {{ item.tablename }}
                    </option>
                  </select>
                  <select
                    v-if="showTableForFilterVal"
                    id="tableForFilterVal"
                    @change="changeField2($event)"
                    class="p-1.5 w-full mx-2 mb-2 border border-solid border-gray-300 rounded dark:text-gray-800"
                    v-model="tableForFilterVal"
                  >
                    <option
                      v-for="item in filterOption"
                      :key="item"
                      :value="item.val"
                    >
                      {{ item.option }}
                    </option>
                  </select>
                  <div id="inputFieldDiv" class="w-full">
                    <!-- FOR RESEARCH TITLE -->
                    <div v-if="showFilter">
                      <input
                        v-model="filterText"
                        type="text"
                        class="p-1.5 w-full mx-2 border border-solid border-gray-300 rounded dark:text-gray-800"
                        placeholder="Filter..."
                      />
                    </div>
                    <!-- FOR ISPUBLISHED -->
                    <div v-if="showFilterIsPub">
                      <input
                        type="checkbox"
                        id="unpublished"
                        value="Unpublished"
                        class="p-1.5 mx-2 mb-2.5"
                        v-model="filterIsPublished"
                      />
                      <label for="unpublished">Unpublished</label> <br />
                      <input
                        type="checkbox"
                        id="published"
                        value="Published"
                        class="p-1.5 mx-2 mb-2.5"
                        v-model="filterIsPublished"
                      />
                      <label for="published">Published</label>
                    </div>
                    <!-- FOR DATE PUBLISHED -->
                    <div v-if="showFilterDate1">
                      <input
                        v-model="filterDate1"
                        type="date"
                        class="p-1.5 w-full mx-2 border border-solid border-gray-300 rounded dark:text-gray-800"
                      />
                    </div>
                    <div v-if="showFilterDate2">
                      <span class="p-1.5 mb-2 mx-2 text-xs">From</span>
                      <input
                        v-model="filterDate1"
                        type="date"
                        class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded dark:text-gray-800"
                      />
                      <span class="p-1.5 mb-2 mx-2 text-xs">To</span>
                      <input
                        v-model="filterDate2"
                        type="date"
                        class="p-1.5 w-full mx-2 border border-solid border-gray-300 rounded dark:text-gray-800"
                      />
                    </div>
                    <!-- FOR CORRESPONDING AUTHOR, CO AUTHOR, MAIN AUTHOR -->
                    <div v-if="showFilterString">
                      <input
                        v-model="filterFname"
                        type="text"
                        class="p-1.5 w-full mx-2 mb-2 border border-solid border-gray-300 rounded dark:text-gray-800"
                        placeholder="First Name"
                      />
                      <input
                        v-model="filterMname"
                        type="text"
                        class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded dark:text-gray-800"
                        placeholder="Middle Name"
                      />
                      <input
                        v-model="filterLname"
                        type="text"
                        class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded dark:text-gray-800"
                        placeholder="Last Name"
                      />
                      <input
                        v-model="filterEmail"
                        type="text"
                        class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded dark:text-gray-800"
                        placeholder="Email"
                      />
                      <input
                        v-model="filterOrg"
                        type="text"
                        class="p-1.5 w-full mx-2 border border-solid border-gray-300 rounded dark:text-gray-800"
                        placeholder="Organization"
                      />
                    </div>
                    <div v-if="showDropdownPublished">
                      <select
                        class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded dark:text-gray-800"
                        v-model="filterPublishedYear"
                      >
                        <option
                          v-for="yr in publishedYearList"
                          :value="yr"
                          :key="yr"
                        >
                          {{ yr }}
                        </option>
                      </select>
                      <select
                        class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded dark:text-gray-800"
                        v-model="filterPublishedMonth"
                      >
                        <option
                          v-for="mm in publishedMonthList"
                          selected
                          :value="mm.value"
                          :key="mm"
                        >
                          {{ mm.month }}
                        </option>
                      </select>
                    </div>
                    <div
                      v-if="showDropdownPublishedRange"
                      class="grid grid-cols-2 grid-rows-1 w-full gap-2"
                    >
                      <div>
                        <span class="p-1.5 mb-2 mx-2 text-xs">From</span>
                        <select
                          class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded dark:text-gray-800"
                          v-model="filterPublishedYear1"
                        >
                          <option
                            v-for="yr in publishedYearList"
                            :value="yr"
                            :key="yr"
                          >
                            {{ yr }}
                          </option>
                        </select>
                        <select
                          class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded dark:text-gray-800"
                          v-model="filterPublishedMonth1"
                        >
                          <option
                            v-for="mm in publishedMonthList"
                            selected
                            :value="mm.value"
                            :key="mm"
                          >
                            {{ mm.month }}
                          </option>
                        </select>
                      </div>
                      <div>
                        <span class="p-1.5 mb-2 mx-2 text-xs">To</span>
                        <select
                          class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded dark:text-gray-800"
                          v-model="filterPublishedYear2"
                        >
                          <option
                            v-for="yr in publishedYearList"
                            :value="yr"
                            :key="yr"
                          >
                            {{ yr }}
                          </option>
                        </select>
                        <select
                          class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded dark:text-gray-800"
                          v-model="filterPublishedMonth2"
                        >
                          <option
                            v-for="mm in publishedMonthList"
                            selected
                            :value="mm.value"
                            :key="mm"
                          >
                            {{ mm.month }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div v-if="showCategoriesList">
                      <select
                        v-model="filterCategory"
                        class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded dark:text-gray-800"
                      >
                        <option
                          v-for="d in categoriesList"
                          :key="d"
                          :value="d.id"
                        >
                          {{ d.categoryDesc }}
                        </option>
                      </select>
                    </div>
                    <div v-if="showTypeOfArticle">
                      <select
                        v-model="filterTypeOfArticle"
                        class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded dark:text-gray-800"
                      >
                        <option selected value="1">Thesis</option>
                        <option value="2">Capstone Project</option>
                        <option value="3">Dissertation</option>
                        <option value="4">Research</option>
                        <option value="5">Journal</option>
                        <option value="6">Others</option>
                      </select>
                    </div>
                  </div>

                  <input
                    type="button"
                    value="DONE"
                    @click="goFilter"
                    data-bs-toggle="collapse"
                    data-bs-target="#dropdown"
                    class="p-1.5 w-full mx-2 mb-2 text-xs mt-2 bg-blue-500 text-white rounded"
                  />
                </li>
              </ul>
              <!-- <button
                id="dropdownDefaultButton"
                data-bs-toggle="collapse"
                data-bs-target="#dropdown"
                @click="filterDiv = !filterDiv"
                class="text-gray-300 bg-white border border-blue-300 focus:border-blue-600 focus:outline-none font-medium rounded-lg text-sm p-2 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                type="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-4 h-4 text-blue-400"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3.792 2.938A49.069 49.069 0 0112 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 011.541 1.836v1.044a3 3 0 01-.879 2.121l-6.182 6.182a1.5 1.5 0 00-.439 1.061v2.927a3 3 0 01-1.658 2.684l-1.757.878A.75.75 0 019.75 21v-5.818a1.5 1.5 0 00-.44-1.06L3.13 7.938a3 3 0 01-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836z"
                    clip-rule="evenodd"
                  />
                </svg>
                <svg
                  class="w-2.5 h-2.5 ml-2.5"
                  :class="
                    filterDiv
                      ? 'transform rotate-180 duration-150 text-blue-500'
                      : 'transform rotate-0 duration-150 '
                  "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <div
                id="dropdown"
                class="collapse absolute top-10 right-96 mr-32 bg-white text-gray-500 divide-y-2 divide-blue-300 rounded-lg shadow shadow-gray-500 w-48 dark:bg-gray-700"
              >
                <div
                  class="text-center text-gray-200 p-2 grid place-content-center"
                  data-bs-toggle="collapse"
                  data-bs-target="#dropdown"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="w-5 h-5 text-blue-400"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3.792 2.938A49.069 49.069 0 0112 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 011.541 1.836v1.044a3 3 0 01-.879 2.121l-6.182 6.182a1.5 1.5 0 00-.439 1.061v2.927a3 3 0 01-1.658 2.684l-1.757.878A.75.75 0 019.75 21v-5.818a1.5 1.5 0 00-.44-1.06L3.13 7.938a3 3 0 01-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <ul
                  class="py-2 text-xs text-gray-700 dark:text-gray-200"
                  aria-labelledby="dropdownDefaultButton"
                >
                  <li>
                    <select
                      class="p-1.5 w-44 mx-2 mb-2 border border-solid border-gray-300 rounded"
                      @change="changeField($event)"
                      v-model="tableForFilter"
                    >
                      <option
                        v-for="item in table_filter"
                        :key="item"
                        :value="item.field"
                      >
                        {{ item.tablename }}
                      </option>
                    </select>
                    <select
                      v-if="showTableForFilterVal"
                      id="tableForFilterVal"
                      @change="changeField2($event)"
                      class="p-1.5 w-44 mx-2 mb-2 border border-solid border-gray-300 rounded"
                      v-model="tableForFilterVal"
                    >
                      <option
                        v-for="item in filterOption"
                        :key="item"
                        :value="item.val"
                      >
                        {{ item.option }}
                      </option>
                    </select>
                    <div id="inputFieldDiv" class="w-full">
                      <div v-if="showFilter">
                        <input
                          v-model="filterText"
                          type="text"
                          class="p-1.5 w-44 mx-2 border border-solid border-gray-300 rounded"
                          placeholder="Filter..."
                        />
                      </div>
                      <div v-if="showFilterIsPub">
                        <input
                          type="checkbox"
                          id="unpublished"
                          value="Unpublished"
                          class="p-1.5 mx-2 mb-2.5"
                          v-model="filterIsPublished"
                        />
                        <label for="unpublished">Unpublished</label> <br />
                        <input
                          type="checkbox"
                          id="published"
                          value="Published"
                          class="p-1.5 mx-2 mb-2.5"
                          v-model="filterIsPublished"
                        />
                        <label for="published">Published</label>
                      </div>
                      <div v-if="showFilterDate1">
                        <input
                          v-model="filterDate1"
                          type="date"
                          class="p-1.5 w-44 mx-2 border border-solid border-gray-300 rounded"
                        />
                      </div>
                      <div v-if="showFilterDate2">
                        <span class="p-1.5 mb-2 mx-2 text-xs">From</span>
                        <input
                          v-model="filterDate1"
                          type="date"
                          class="p-1.5 w-44 mb-2 mx-2 border border-solid border-gray-300 rounded"
                        />
                        <span class="p-1.5 mb-2 mx-2 text-xs">To</span>
                        <input
                          v-model="filterDate2"
                          type="date"
                          class="p-1.5 w-44 mx-2 border border-solid border-gray-300 rounded"
                        />
                      </div>
                      <div v-if="showFilterString">
                        <input
                          v-model="filterFname"
                          type="text"
                          class="p-1.5 w-44 mx-2 mb-2 border border-solid border-gray-300 rounded"
                          placeholder="First Name"
                        />
                        <input
                          v-model="filterMname"
                          type="text"
                          class="p-1.5 w-44 mb-2 mx-2 border border-solid border-gray-300 rounded"
                          placeholder="Middle Name"
                        />
                        <input
                          v-model="filterLname"
                          type="text"
                          class="p-1.5 w-44 mb-2 mx-2 border border-solid border-gray-300 rounded"
                          placeholder="Last Name"
                        />
                        <input
                          v-model="filterEmail"
                          type="text"
                          class="p-1.5 w-44 mb-2 mx-2 border border-solid border-gray-300 rounded"
                          placeholder="Email"
                        />
                        <input
                          v-model="filterOrg"
                          type="text"
                          class="p-1.5 w-44 mx-2 border border-solid border-gray-300 rounded"
                          placeholder="Organization"
                        />
                      </div>
                      <div v-if="showDropdownPublished">
                        <select
                          class="p-1.5 w-44 mb-2 mx-2 border border-solid border-gray-300 rounded"
                          v-model="filterPublishedYear"
                        >
                          <option
                            v-for="yr in publishedYearList"
                            :value="yr"
                            :key="yr"
                          >
                            {{ yr }}
                          </option>
                        </select>
                        <select
                          class="p-1.5 w-44 mb-2 mx-2 border border-solid border-gray-300 rounded"
                          v-model="filterPublishedMonth"
                        >
                          <option
                            v-for="mm in publishedMonthList"
                            selected
                            :value="mm.value"
                            :key="mm"
                          >
                            {{ mm.month }}
                          </option>
                        </select>
                      </div>
                      <div
                        v-if="showDropdownPublishedRange"
                        class="grid grid-cols-2 grid-rows-1 w-44 gap-2"
                      >
                        <div>
                          <span class="p-1.5 mb-2 mx-2 text-xs">From</span>
                          <select
                            class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded"
                            v-model="filterPublishedYear1"
                          >
                            <option
                              v-for="yr in publishedYearList"
                              :value="yr"
                              :key="yr"
                            >
                              {{ yr }}
                            </option>
                          </select>
                          <select
                            class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded"
                            v-model="filterPublishedMonth1"
                          >
                            <option
                              v-for="mm in publishedMonthList"
                              selected
                              :value="mm.value"
                              :key="mm"
                            >
                              {{ mm.month }}
                            </option>
                          </select>
                        </div>
                        <div>
                          <span class="p-1.5 mb-2 mx-2 text-xs">To</span>
                          <select
                            class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded"
                            v-model="filterPublishedYear2"
                          >
                            <option
                              v-for="yr in publishedYearList"
                              :value="yr"
                              :key="yr"
                            >
                              {{ yr }}
                            </option>
                          </select>
                          <select
                            class="p-1.5 w-full mb-2 mx-2 border border-solid border-gray-300 rounded"
                            v-model="filterPublishedMonth2"
                          >
                            <option
                              v-for="mm in publishedMonthList"
                              selected
                              :value="mm.value"
                              :key="mm"
                            >
                              {{ mm.month }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div v-if="showCategoriesList">
                        <select
                          v-model="filterCategory"
                          class="p-1.5 w-44 mb-2 mx-2 border border-solid border-gray-300 rounded"
                        >
                          <option
                            v-for="d in categoriesList"
                            :key="d"
                            :value="d.id"
                          >
                            {{ d.categoryDesc }}
                          </option>
                        </select>
                      </div>
                      <div v-if="showTypeOfArticle">
                        <select
                          v-model="filterTypeOfArticle"
                          class="p-1.5 w-44 mb-2 mx-2 border border-solid border-gray-300 rounded"
                        >
                          <option selected value="1">Thesis</option>
                          <option value="2">Capstone Project</option>
                          <option value="3">Dissertation</option>
                          <option value="4">Research</option>
                          <option value="5">Journal</option>
                          <option value="6">Others</option>
                        </select>
                      </div>
                    </div>

                    <input
                      type="button"
                      value="DONE"
                      @click="goFilter"
                      data-bs-toggle="collapse"
                      data-bs-target="#dropdown"
                      class="p-1.5 w-20 float-right mx-2 mb-2 text-xs mt-2 bg-blue-500 text-white rounded"
                    />
                    <a href="#"
                    class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</a>
                  </li>
                  <li>
                  <a href="#"
                    class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Settings</a>
                </li>
                <li>
                  <a href="#"
                    class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Earnings</a>
                </li>
                <li>
                  <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Sign
                    out</a>
                </li>
                </ul>
              </div> -->
              <button
                @click="toggleAddModal('add', '')"
                title="Add new article"
                class="addBTN1 text-white bg-customGreen border border-gray-300 px-3 focus:border-white focus:outline-none font-medium rounded-lg text-sm p-2 text-center inline-flex items-center focus:text-white dark:bg-269255 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                type="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                  />
                </svg>
              </button>
            </div>
            <div class="w-auto flex addBTN">
              <button
                @click="toggleAddModal('add', '')"
                title="Add new article"
                class="text-white bg-customGreen border border-gray-300 px-3 focus:border-white focus:outline-none font-medium rounded-lg text-sm p-2 text-center inline-flex items-center focus:text-white dark:bg-105005 dark:hover:bg-269255 dark:focus:ring-105005"
                type="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                  />
                </svg>
              </button>
            </div>

            <!-- Dropdown menu -->
          </div>
        </div>
        <!-- <div class="grid text-left mx-2 my-2">
          <h4 class="text-gray-500 font-semibold text-xs md:text-xs">
            Date Published
          </h4>
          <div class="grid md:flex lg:flex">
            <input type="text" class=" indent flex-auto py-1 block text-xs font-normal text-gray-700 bg-white bg-clip-padding border border-gray-300 rounded md:rounded-l-md lg:rounded-l-md transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none " placeholder="From mm-dd-yyyy" />
            <input type="text" class=" indent flex-auto py-1 block text-xs font-normal text-gray-700 bg-white bg-clip-padding border border-gray-300 rounded md:rounded-l-md lg:rounded-l-md transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none " placeholder="To mm-dd-yyyy" />
          </div>
        </div>
        <div class="grid text-left mx-2 my-2">
          <h4 class="text-gray-500 font-semibold text-xs md:text-xs">
            Area Published
          </h4>

          <input type="search" class=" indent flex-auto py-1 block text-xs font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded md:rounded-md lg:rounded-md transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none " placeholder="Search for by area" />
        </div>
        <div class="grid text-left mx-2 my-2">
          <h4 class="text-gray-500 font-semibold text-xs md:text-xs">Author</h4>
          <input type="search" class=" indent flex-auto py-1 block text-xs font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded md:rounded-md lg:rounded- transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none " placeholder="Search for an author" />
        </div> -->
      </div>
    </div>
    <div v-if="isLoading" class="flex justify-center py-8">
      <div class="spinLoader"></div>
    </div>

    <div
      class="journal-tbl dark:bg-darkBG w-auto m-auto mt-4 pb-4"
      v-if="!isLoading"
    >
      <div class="flex flex-col mx-3">
        <div class="overflow-x-auto">
          <div class="inline-block min-w-full">
            <div class="overflow-hidden">
              <!-- <table class="mx-auto w-11/12 table-fixed mt-8">
                
                <tr class="">
                  <th v-for="item in table_headers" :key="item" class=" bg-customGreen font-poppins text-xs font-medium text-white px-6 py-3 text-left " >
                    {{ item.title }}
                  </th>
                </tr>
               
                <tbody>
                  <tr v-show="results == 0 || results == null">
                    <td colspan="4" class=" text-center text-gray-500 text-xs py-1 font-poppins border-b border-l border-r border-gray-400 " >
                      You have no research / journals uploaded.
                    </td>
                  </tr> <tr class=" bg-grey-100 border-b border-l border-r border-gray-400 transition duration-300 ease-in-out " v-for="item in displayedJournals" :key="item.file_id" :class="item.file_id == notifID ? 'bg-gray-200' : ''" >
                    <td class=" px-6 font-poppins py-1 text-xs font-medium text-gray-600 break-all whitespace-normal " width="40%" >
                      {{ item.file_title }}
                    </td>

                    <td class=" text-xs text-gray-600 font-poppins font-light px-6 py-1 whitespace-nowrap " >
                      {{ formatDate(item.file_createdAt) }}
                    </td>

                    <td class=" text-xs text-gray-600 font-poppins font-light px-6 py-1 whitespace-nowrap " >
                      {{ item.journalstatus_status_desc }}
                    </td>

                    <td class=" text-xs text-gray-600 text-left font-light px-6 py-1 " >
                      <button v-if="item.remarksCount > 0" @click="viewJournal(item)" class=" bg-white hover:bg-FFB800 hover:text-white text-FFB800 font-semibold py-1 px-3 text-xs border border-FFB800 rounded shadow " >
                        View
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table> -->
              <Table
                class="mt-6 md:mt-0 lg:mt-0 xl:mt-0"
                :idName="'myWorksTable'"
                :labels="table_headers"
                :data="displayedJournals"
                :notifField="'file_id'"
                :notifID="notifID"
              >
		<template v-slot:file_mainauthorlname="{ item }">
                  {{
                    item.file_mainauthorlname + ", " + item.file_conferenceDate
                  }}
                </template>
                <template v-slot:file_createdAt="{ item }">
                  {{ timeToday(formatDate(item.file_createdAt)) }}
                </template>
                <template v-slot:js_status_desc="{ item }">
                  <span
                    class="inline-flex items-center rounded-full uppercase m-0.5 px-4 py-1 text-xs"
                    :class="
                      item.js_status_desc == 'For Approval'
                        ? 'bg-red-100 text-red-800 dark:bg-darkBG border dark:border-gray-500'
                        : item.js_status_desc == 'Verified'
                        ? 'bg-blue-100 text-blue-800 dark:bg-darkBG border dark:border-gray-500'
                        : item.js_status_desc == 'Pending'
                        ? 'bg-yellow-100 text-yellow-800 dark:bg-darkBG border dark:border-gray-500'
                        : item.js_status_desc == 'Endorsed'
                        ? 'bg-blue-100 text-blue-800 dark:bg-darkBG border dark:border-gray-500'
                        : item.js_status_desc == 'Published'
                        ? 'bg-green-100 text-green-800 dark:bg-darkBG border dark:border-gray-500'
                        : item.js_status_desc == 'Saved'
                        ? 'bg-yellow-100 text-yellow-800 dark:bg-darkBG border dark:border-gray-500'
                        : ''
                    "
                    >{{ item.js_status_desc }}</span
                  >
                </template>
                <template v-slot:file_isPublished="{ item }">
                  <span
                    class="inline-flex items-center rounded-full uppercase m-0.5 px-4 py-1 text-xs dark:bg-darkBG border dark:border-gray-500"
                    :class="
                      item.file_isPublished == 0
                        ? 'bg-purple-100 text-purple-800'
                        : 'bg-green-100 text-green-800'
                    "
                    >{{
                      item.file_isPublished == 0 ? "Unpublished" : "Published"
                    }}</span
                  >
                </template>
                <template v-slot:action="{ item }">
                  <button
                    v-if="item.remarksCount > 0"
                    @click="viewJournal(item)"
                    class="bg-white hover:bg-FFB800 hover:text-white text-FFB800 font-semibold py-1 px-3 text-xs border border-FFB800 dark:bg-darkBG rounded shadow"
                  >
                    View
                  </button>
                  <button
                    v-if="item.file_journalstatusID == 1"
                    @click="toggleUpdateModal('update', item)"
                    class="bg-white hover:bg-FFB800 hover:text-white text-FFB800 font-semibold py-1 px-3 text-xs border border-FFB800 dark:bg-darkBG rounded shadow"
                  >
                    View
                  </button>
                  <button
                    v-if="item.file_journalstatusID == 6"
                    @click="toggleUpdateModal('update', item)"
                    class="bg-white hover:bg-FFB800 hover:text-white text-FFB800 font-semibold py-1 px-3 text-xs border border-FFB800 dark:bg-darkBG rounded shadow"
                  >
                    Resume edit
                  </button>
                  <button
                    v-if="
                      (item.file_journalstatusID == 2 ||
                        item.file_journalstatusID == 5) &&
                      (item.file_isEdit == null || item.file_isEdit == 0) &&
                      (this.$store.getters.getUserRole == 4 ||
                        this.$store.getters.getUserRole == 5 ||
                        this.$store.getters.getUserRole == 6 ||
                        this.$store.getters.getUserRole == 7)
                    "
                    @click="RequestToEdit(item)"
                    class="bg-white hover:bg-blue-500 hover:text-white text-blue-500 font-semibold py-1 px-3 text-xs border border-blue-500 dark:bg-darkBG rounded shadow"
                  >
                    Request to edit
                  </button>
                  <button
                    v-if="
                      (item.file_journalstatusID == 2 ||
                        item.file_journalstatusID == 5) &&
                      item.file_isEdit == true
                    "
                    @click="toggleUpdateModal('update', item)"
                    class="bg-white hover:bg-blue-500 hover:text-white text-blue-500 font-semibold py-1 px-3 text-xs border border-blue-500 dark:bg-darkBG rounded shadow"
                  >
                    Edit
                  </button>
                  <button
                    v-if="
                      (this.$store.getters.getUserRole == 1 ||
                        this.$store.getters.getUserRole == 2 ||
                        this.$store.getters.getUserRole == 3) &&
                      (item.file_journalstatusID == 2 ||
                        item.file_journalstatusID == 5)
                    "
                    @click="toggleUpdateModal('update', item)"
                    class="bg-white hover:bg-yellow-500 hover:text-white text-yellow-500 font-semibold py-1 px-3 text-xs border border-yellow-500 dark:bg-darkBG rounded shadow"
                  >
                    Edit
                  </button>
                  <button
                    v-if="item.remarksCount > 0"
                    @click="viewJournal(item)"
                    class="bg-white hover:bg-FFB800 hover:text-white text-FFB800 font-semibold py-1 px-3 text-xs border border-FFB800 rounded shadow dark:bg-darkBG"
                  >
                    View
                  </button>
                  <button
                    v-if="item.file_journalstatusID == 3"
                    @click="toggleRemarksModal('update', item)"
                    class="bg-white ml-1 hover:bg-FFB800 hover:text-white text-FFB800 font-semibold py-1 px-3 text-xs border border-FFB800 rounded shadow dark:bg-darkBG"
                  >
                    View Remarks & update
                  </button>
                </template>
              </Table>
              <div
                class="grid bg-white font-poppins w-full sm:flex md:flex lg:flex flex-row justify-end md:w-full sm:w-full mx-auto items-center border-l border-b border-r border-gray-400 rounded-bl-lg rounded-br-lg pt-4 pb-4"
              >
                <div
                  class="w-auto sm:w-3/6 md:w-3/6 lg:w-3/6 xl:w-3/6 gap-x-4 mx-3 flex"
                >
                  <div
                    class="hidden text-xs text-gray-600 md:flex lg:flex xl:flex 2xl:flex w-1/3 md:w-2/5 items-center"
                  >
                    <label for="" class="w-2/3">Rows per page</label>&nbsp;
                    <select
                      class="w-1/3 border-gray-600 border-b dark:bg-darkBG pb-1"
                      v-model="perPage"
                    >
                      <option
                        :value="item"
                        v-for="item in selectPerPage"
                        :key="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>

                  <div
                    class="hidden md:flex lg:flex xl:flex 2xl:flex w-1/3 md:w-2/5"
                  >
                    <!-- <div class="flex" v-if="results != 0"> -->
                    <span
                      class="text-xs m-auto text-gray-600 dark:text-gray-400"
                    >
                      Showing
                      <span
                        class="font-semibold text-gray-900 dark:text-white"
                        >{{ fromPage }}</span
                      >
                      to
                      <span
                        class="font-semibold text-gray-900 dark:text-white"
                        >{{ toPage }}</span
                      >
                      of
                      <span
                        class="font-semibold text-gray-900 dark:text-white"
                        >{{ results }}</span
                      >
                      Entries
                    </span>
                  </div>

                  <nav class="w-1/3 md:w-1/5">
                    <ul class="flex md:justify-end">
                      <li class="page-item mx-1">
                        <button
                          type="button"
                          class="page-link bg-customGreen text-white rounded-md p-1 text-xs"
                          v-if="page > 1"
                          @click="page--"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>

                        <!--for the disabled button -->
                        <button
                          type="button"
                          class="page-link bg-customGreen rounded-md text-white p-1 text-xs"
                          v-if="page == 1"
                          disabled
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </li>

                      <li class="page-item mx-1">
                        <button
                          type="button"
                          @click="page++"
                          v-if="page < pages.length"
                          class="page-link bg-customGreen rounded-md text-white p-1 text-xs"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>

                        <!--for the disabled button -->
                        <button
                          type="button"
                          disabled
                          v-if="page >= pages.length"
                          class="page-link bg-customGreen rounded-md text-white p-1 text-xs"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PendingReasonModal
      v-if="toggleModal"
      :journal="journalID"
      :tableFrom="'myworks'"
      v-on:toUpdate="journalToUpdate"
      v-on:closeModal="toggleModal = false"
    />
    <RequestToEditModal
      v-on:confirmRequest="confirmRequest"
      v-on:closeRequestModal="closeRequestModal"
      :content="RequestToEditContent"
      :mode="1"
      v-if="isCloseRequestModal"
    />
  </div>
</template>

<script>
import PendingReasonModal from "../modals/PendingReasonModal.vue";
import RequestToEditModal from "../modals/RequestToEditModal.vue";
import Table from "../components/Table.vue";
import axios from "axios";
import moment from "moment";
import { useToast } from "vue-toastification";
import RequestToEditModalVue from "../modals/RequestToEditModal.vue";
export default {
  name: "MyWorksTable",
  props: ["refreshtable", "notifID"],
  components: {
    PendingReasonModal,
    RequestToEditModal,
    Table,
  },

  data() {
    return {
      isCloseRequestModal: false,
      RequestToEditContent: "",
      tableForFilterVal: "equal_date",
      tableForFilter: "file_createdAt",
      filterOption: [
        { option: "Equals", val: "equal_date" },
        { option: "Greater than", val: "greater_than_date" },
        { option: "Not equal", val: "not_equal_date" },
        { option: "In range", val: "in_range_date" },
      ],
      publishedYearList: [
        1955, 1956, 1957, 1958, 1959, 1960, 1961, 1962, 1963, 1964, 1965, 1966,
        1967, 1968, 1969, 1970, 1971, 1972, 1973, 1974, 1975, 1976, 1977, 1978,
        1979, 1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990,
        1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002,
        2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014,
        2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025,
      ],
      publishedMonthList: [
        { month: "January", value: 1 },
        { month: "February", value: 2 },
        { month: "March", value: 3 },
        { month: "April", value: 4 },
        { month: "May", value: 5 },
        { month: "June", value: 6 },
        { month: "July", value: 7 },
        { month: "August", value: 8 },
        { month: "September", value: 9 },
        { month: "October", value: 10 },
        { month: "November", value: 11 },
        { month: "December", value: 12 },
      ],
      categoriesList: [],
      filterDate1: null,
      filterDate2: null,
      filterIsPublished: [],
      filterText: null,
      filterFname: null,
      filterMname: null,
      filterLname: null,
      filterEmail: null,
      filterOrg: null,
      filterPublishedMonth: 1,
      filterPublishedYear: 1955,
      filterPublishedYear1: 1955,
      filterPublishedYear2: 2023,
      filterPublishedMonth1: 1,
      filterPublishedMonth2: 1,
      filterCategory: 1,
      filterTypeOfArticle: 1,
      filterDiv: false,
      journalID: null,
      toggleModal: false,
      dataFromRequestToEdit: [],
      showFilterIsPub: false,
      showFilterDate1: true,
      showFilterDate2: false,
      showFilter: false,
      showFilterString: false,
      showTableForFilterVal: true,
      showDropdownPublished: false,
      showDropdownPublishedRange: false,
      showCategoriesList: false,
      showTypeOfArticle: false,
      contents: [],
      searchText: null,
      isViewRemarks: false,
      page: 1,
      isLoading: false,
      perPage: 10,
      selectPerPage: [10, 15, 20, 30, 50, 100, 500],
      pages: [],
      fromPage: 1,
      toPage: null,
      results: null,
      percentage: null,
      pendingToEmit: null,
      table_filter: [
        // { tablename: 'Research Title', field: 'file_title' },
        { tablename: "Date Uploaded", field: "file_createdAt" },
        { tablename: "Corresponding Author", field: "file_corFname" },
        { tablename: "Main Author", field: "file_mainauthorfname" },
        { tablename: "Co-Author", field: "file_coauthor" },
        { tablename: "Date Published", field: "file_publishedDateMonth" },
        { tablename: "Categories", field: "file_categories" },
        { tablename: "Author's keywords", field: "file_authorsKeywords" },
        { tablename: "Type Of Article", field: "file_typeOfArticle" },
        { tablename: "Published", field: "file_isPublished" },
      ],
      table_headers: [
        { text: "Research Title", field: "file_title" },
	{ text: "Author", field: "file_mainauthorlname" },
        { text: "Date Uploaded", field: "file_createdAt" },
        { text: "Journal", field: "file_isPublished" },
        { text: "Status", field: "js_status_desc" },
        { text: "Action", field: "action" },
      ],
    };
  },
  methods: {
    confirmRequest() {
      // console.log(this.dataFromRequestToEdit);
      const toast = useToast();
      const fd = {
        isEdit: true,
        articleID: this.dataFromRequestToEdit.file_id,
        journalstatusID: this.dataFromRequestToEdit.file_journalstatusID,
        userid: this.dataFromRequestToEdit.file_userID,
      };
      axios
        .post(
          process.env.VUE_APP_API_SERVER + "/fileupload/requestToEdit",
          fd,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            if (response.data.status == 200 || response.data.status == 201) {
              toast.success(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
              this.isUploading1 = false;
              // const alert_msg = {
              //   msg: response.data.msg,
              // };
              setTimeout(() => {
                this.closeRequestModal();
                this.$emit("refreshTable");
                // this.$parent.$emit("success_alert", alert_msg);
              }, 500);
            } else if (
              response.data.status != 200 ||
              response.data.status != 201
            ) {
              toast.error(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
              setTimeout(() => {
                this.closeRequestModal();
                this.$emit("refreshTable");
              }, 500);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
    closeRequestModal() {
      this.isCloseRequestModal = false;
    },
    RequestToEdit(i) {
      this.dataFromRequestToEdit = i;
      this.isCloseRequestModal = true;
      this.RequestToEditContent =
        "Are you sure you want to request an edit for this article?";
    },
    searchFilterText() {
      if (this.filterText != null || this.filterText != "") {
        var matchedKeys = [],
          notMatchedKey = [];
        for (var i = 0; i < this.contents.length; i++) {
          // if(this.contents[i].)
        }
      }
    },
    goFilter() {
      // const toast = useToast();
      // toast.error("Please complete your profile to continue.", {
      //   position: "top-center",
      //   timeout: 3000,
      //   closeOnClick: true,
      //   pauseOnFocusLoss: false,
      //   pauseOnHover: false,
      //   draggable: true,
      //   draggablePercent: 0.6,
      //   showCloseButtonOnHover: false,
      //   hideProgressBar: false,
      //   closeButton: "button",
      //   icon: true,
      //   rtl: false,
      // });
      // if (this.filterText) {

      // }
      const fd = {
        tableForFilter: this.tableForFilter,
        tableForFilterVal: this.tableForFilterVal,
        filterText: this.filterText,
        filterDate1: this.filterDate1,
        filterDate2: this.filterDate2,
        filterFname: this.filterFname,
        filterMname: this.filterMname,
        filterLname: this.filterLname,
        filterEmail: this.filterEmail,
        filterOrg: this.filterOrg,
        filterPublishedMonth: this.filterPublishedMonth,
        filterPublishedYear: this.filterPublishedYear,
        filterPublishedMonth1: this.filterPublishedMonth1,
        filterPublishedMonth2: this.filterPublishedMonth2,
        filterPublishedYear1: this.filterPublishedYear1,
        filterPublishedYear2: this.filterPublishedYear2,
        filterIsPublished: this.filterIsPublished,
        filterCategory: this.filterCategory,
        filterTypeOfArticle: this.filterTypeOfArticle,
      };
      axios
        .post(process.env.VUE_APP_API_SERVER + "/fileupload/filteredMy", fd, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            this.contents = response.data;
            // console.log(response.data);
            this.results = response.data.length;
            this.isLoading = true;
            setTimeout(() => (this.isLoading = false), 1000);
          },
          (error) => {
            console.log(error);
          }
        );
    },
    changeField2(event) {
      var val = event.target.value;
      // var template = '';
      if (val == "in_range_date") {
        this.showFilterDate1 = false;
        this.showFilterDate2 = true;
      }

      if (val == "not_equal_date") {
        this.showFilterDate1 = true;
        this.showFilterDate2 = false;
      }

      if (val == "equal_date") {
        this.showFilterDate1 = true;
        this.showFilterDate2 = false;
      }

      if (val == "greater_than_date") {
        this.showFilterDate1 = true;
        this.showFilterDate2 = false;
      }

      if (val == "starts_with_string") {
        this.showFilter = false;
        this.showFilterDate2 = false;
        this.showFilterIsPub = false;
        this.showFilterString = true;
      }

      if (val == "ends_with_string") {
        this.showFilter = false;
        this.showFilterDate2 = false;
        this.showFilterIsPub = false;
        this.showFilterString = true;
      }

      if (val == "contain_string") {
        this.showFilter = false;
        this.showFilterDate2 = false;
        this.showFilterIsPub = false;
        this.showFilterString = true;
      }

      if (val == "not_contain_string") {
        this.showFilter = false;
        this.showFilterDate2 = false;
        this.showFilterIsPub = false;
        this.showFilterString = true;
      }

      if (val == "equal_string") {
        this.showFilter = false;
        this.showFilterDate2 = false;
        this.showFilterIsPub = false;
        this.showFilterString = true;
      }

      if (val == "not_equal_string") {
        this.showFilter = false;
        this.showFilterDate2 = false;
        this.showFilterIsPub = false;
        this.showFilterString = true;
      }

      if (
        val == "equal_date_pub" ||
        val == "greater_than_date_pub" ||
        val == "not_equal_date_pub"
      ) {
        this.showDropdownPublished = true;
        this.showDropdownPublishedRange = false;
      }

      if (val == "in_range_date_pub") {
        this.showDropdownPublishedRange = true;
        this.showDropdownPublished = false;
      }

      if (
        val == "contain_string_ak" ||
        val == "not_contain_string_ak" ||
        val == "equal_string_ak" ||
        val == "not_equal_string_ak" ||
        val == "starts_with_string_ak" ||
        val == "ends_with_string_ak"
      ) {
        this.showFilter = true;
        this.showFilterDate2 = false;
        this.showFilterIsPub = false;
        this.showFilterString = false;
      }
    },
    changeField(event) {
      var val = event.target.value;
      // console.log(val);
      // if (val == "file_title") {
      //   var options = [
      //     { option: 'Contains', val: 'contain_string' },
      //     { option: 'Not contains', val: 'not_contain_string' },
      //     { option: 'Equal', val: 'equal_string' },
      //     { option: 'Not equal', val: 'not_equal_string' },
      //     { option: 'Starts with', val: 'starts_with_string' },
      //     { option: 'Ends with', val: 'ends_with_string' },
      //   ];
      //   this.filterOption = options;
      //   this.tableForFilterVal = 'contain_string';
      //   this.showFilter = true;
      //   this.showFilterIsPub = false;
      //   this.showFilterDate1 = false;
      //   this.showFilterDate2 = false;
      //   this.showFilterString = false;
      //   this.showTableForFilterVal = true;
      // }
      // else
      if (
        val == "file_corFname" ||
        val == "file_mainauthorfname" ||
        val == "file_coauthor"
      ) {
        var options = [
          { option: "Contains", val: "contain_string" },
          { option: "Not contains", val: "not_contain_string" },
          { option: "Equal", val: "equal_string" },
          { option: "Not equal", val: "not_equal_string" },
          { option: "Starts with", val: "starts_with_string" },
          { option: "Ends with", val: "ends_with_string" },
        ];
        this.filterOption = options;
        this.tableForFilterVal = "contain_string";
        this.showFilter = false;
        this.showFilterIsPub = false;
        this.showFilterDate1 = false;
        this.showFilterDate2 = false;
        this.showFilterString = true;
        this.showTableForFilterVal = true;
        this.showDropdownPublished = false;
        this.showDropdownPublishedRange = false;
        this.showCategoriesList = false;
        this.showTypeOfArticle = false;
      } else if (val == "file_authorsKeywords") {
        var options = [
          { option: "Contains", val: "contain_string_ak" },
          { option: "Not contains", val: "not_contain_string_ak" },
          { option: "Equal", val: "equal_string_ak" },
          { option: "Not equal", val: "not_equal_string_ak" },
          { option: "Starts with", val: "starts_with_string_ak" },
          { option: "Ends with", val: "ends_with_string_ak" },
        ];
        this.filterOption = options;
        this.tableForFilterVal = "contain_string_ak";
        this.showFilter = true;
        this.showFilterIsPub = false;
        this.showFilterDate1 = false;
        this.showFilterDate2 = false;
        this.showFilterString = false;
        this.showTableForFilterVal = true;
        this.showDropdownPublished = false;
        this.showDropdownPublishedRange = false;
        this.showCategoriesList = false;
        this.showTypeOfArticle = false;
      } else if (val == "file_createdAt") {
        var options = [
          { option: "Equals", val: "equal_date" },
          { option: "Greater than", val: "greater_than_date" },
          { option: "Not equal", val: "not_equal_date" },
          { option: "In range", val: "in_range_date" },
        ];
        this.filterOption = options;
        this.tableForFilterVal = "equal_date";
        this.showFilter = false;
        this.showFilterIsPub = false;
        this.showFilterDate1 = true;
        this.showFilterDate2 = false;
        this.showFilterString = false;
        this.showTableForFilterVal = true;
        this.showDropdownPublished = false;
        this.showDropdownPublishedRange = false;
        this.showCategoriesList = false;
        this.showTypeOfArticle = false;
      } else if (val == "file_publishedDateMonth") {
        var options = [
          { option: "Equals", val: "equal_date_pub" },
          { option: "Greater than", val: "greater_than_date_pub" },
          { option: "Not equal", val: "not_equal_date_pub" },
          { option: "In range", val: "in_range_date_pub" },
        ];
        this.filterOption = options;
        this.tableForFilterVal = "equal_date_pub";
        this.showFilter = false;
        this.showFilterIsPub = false;
        this.showFilterDate1 = false;
        this.showFilterDate2 = false;
        this.showFilterString = false;
        this.showTableForFilterVal = true;
        this.showDropdownPublished = true;
        this.showDropdownPublishedRange = false;
        this.showCategoriesList = false;
        this.showTypeOfArticle = false;
      } else if (val == "file_categories") {
        axios
          .get(process.env.VUE_APP_API_SERVER + "/journalcategory/", {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          })
          .then(
            (response) => {
              // console.log("categories ", response.data);
              this.categoriesList = response.data;
            },
            (error) => {
              console.log(error);
            }
          );
        this.filterOption = options;
        this.tableForFilterVal = "equal_date_pub";
        this.showFilter = false;
        this.showFilterIsPub = false;
        this.showFilterDate1 = false;
        this.showFilterDate2 = false;
        this.showFilterString = false;
        this.showTableForFilterVal = false;
        this.showDropdownPublished = false;
        this.showDropdownPublishedRange = false;
        this.showCategoriesList = true;
        this.showTypeOfArticle = false;
      } else if (val == "file_typeOfArticle") {
        this.showFilter = false;
        this.showFilterIsPub = false;
        this.showFilterDate1 = false;
        this.showFilterDate2 = false;
        this.showFilterString = false;
        this.showTableForFilterVal = false;
        this.showDropdownPublished = false;
        this.showDropdownPublishedRange = false;
        this.showCategoriesList = false;
        this.showTypeOfArticle = true;
      } else if (val == "file_isPublished") {
        this.showFilter = false;
        this.showFilterIsPub = true;
        this.showFilterDate1 = false;
        this.showFilterDate2 = false;
        this.showFilterString = false;
        this.showTableForFilterVal = false;
        this.showDropdownPublished = false;
        this.showDropdownPublishedRange = false;
        this.showCategoriesList = false;
        this.showTypeOfArticle = false;
      }
    },
    timeToday(d) {
      var today = new Date(d);
      return moment(today).format("ll");
    },
    // initialize data values to default
    init() {
      this.contents = [];
      this.page = 1;
      this.perPage = 10;
      this.pages = [];
      this.fromPage = 1;
      this.toPage = null;
      this.results = null;
    },

    // load all users journals uploaded
    async getMyJournals() {
      this.isLoading = true;
      axios
        .get(process.env.VUE_APP_API_SERVER + "/fileupload/my", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            this.contents = response.data;
            // console.log(response.data);
            this.results = response.data.length;
            this.isLoading = false;
          },
          (error) => {
            console.log(error);
          }
        );
    },

    getProfilePercentage() {
      axios
        .get(process.env.VUE_APP_API_SERVER + "/users-controller", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            var cnt = 0;

            if (
              response.data.userdetail.fname != null &&
              response.data.userdetail.fname != ""
            ) {
              cnt += 1;
            }
            if (
              response.data.userdetail.lname != null &&
              response.data.userdetail.lname != ""
            ) {
              cnt += 1;
            }
            if (
              response.data.userdetail.educ_qual != null &&
              response.data.userdetail.educ_qual != ""
            ) {
              cnt += 1;
            }
            if (
              response.data.userdetail.name_of_school != null &&
              response.data.userdetail.name_of_school != ""
            ) {
              cnt += 1;
            }
            // if (
            //   response.data.position != null &&
            //   response.data.position != ""
            // ) {
            //   cnt += 1;
            // }
            if (
              response.data.userdetail.office != null &&
              response.data.userdetail.office != ""
            ) {
              cnt += 1;
            }
            if (
              response.data.userdetail.designation != null &&
              response.data.userdetail.designation != ""
            ) {
              cnt += 1;
            }

            this.percentage = (parseInt(cnt) / 6) * 100;
            this.percentage = this.percentage.toFixed(2);
          },
          (error) => {
            console.log(error);
          }
        );
    },

    reloadTable() {
      this.getMyJournals();
      this.setPages();
    },
    // toggle modal to view reason for the pending status of journal
    viewJournal(item) {
      this.journalID = item;
      this.toggleModal = true;
    },

    toggleAddModal(action, item) {
      const toast = useToast();
      if (this.checkProfilePercentage) {
        this.$emit("toggleAddModal");
        const toEmit = {
          item: item,
          action: action,
        };
        this.$emit("action", toEmit);
      } else {
        toast.error("Please complete your profile to continue.", {
          position: "top-center",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },

    toggleUpdateModal(action, item) {
      const toast = useToast();
      if (this.checkProfilePercentage) {
        this.$emit("toggleUpdateModal");
        const toEmit = {
          item: item,
          action: action,
        };
        this.$emit("action", toEmit);
      } else {
        toast.error("Please complete your profile to continue.", {
          position: "top-center",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },

    toggleRemarksModal(action, item) {
      this.journalFile = item;
      this.journalID = item.file_id;
      // this.isViewRemarks = true;
      this.toggleModal = true;
      const toEmit = {
        item: this.journalFile,
        action: action,
      };
      this.pendingToEmit = toEmit;
    },

    journalToUpdate(item) {
      // console.log(item)
      this.toggleModal = false;
      Object.assign(this.pendingToEmit, { pendingDetails: item });
      this.$emit("toggleUpdateModal");
      this.$emit("action", this.pendingToEmit);
    },

    // format date to display
    formatDate(date) {
      const d = new Date(date);
      var dd = d.getDate();
      if (dd < 10) {
        dd = "0" + dd;
      }
      var mm = d.getMonth() + 1;
      if (mm < 10) {
        mm = "0" + mm;
      }
      var yy = d.getFullYear();
      return mm + "-" + dd + "-" + yy;
    },

    handleSearching() {
      if (this.searchText == "" || !this.searchText) {
        this.getMyJournals();
        this.setPages();
      } else {
        this.contents = this.contents.filter(
          (data) =>
            data.file_title
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) !== -1 ||
            data.file_createdAt.indexOf(this.searchText) !== -1
        );
        this.results = this.contents.length;
        this.setPages();
      }
    },

    // set number of pages to paginate
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(
        parseInt(this.contents.length) / this.perPage
      );
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    // handles the from and to of pagination table
    paginate(contents) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;

      if (to > this.contents.length) {
        this.fromPage = from + 1;
        this.toPage = this.contents.length;
      } else {
        this.fromPage = from + 1;
        this.toPage = to;
      }

      return contents.slice(from, to);
    },
  },

  computed: {
    displayedJournals() {
      return this.paginate(this.contents);
    },
    computeStatus() {
      return this.init();
    },
    checkProfilePercentage() {
      return this.percentage == 100;
    },
  },
  watch: {
    contents() {
      this.setPages();
    },

    perPage() {
      this.page = 1;
      this.setPages();
    },
    refreshtable: {
      handler(value) {
        if (value == true) {
          this.init();
          this.getMyJournals();
          this.setPages();
        } else {
          return;
        }
      },
      immediate: true,
    },
  },
  created() {
    this.moment = moment;
    this.getMyJournals();
    this.setPages();
    this.getProfilePercentage();
  },
};
</script>

<style scoped>
.tbl_header {
  background-color: #105005;
}

.indent {
  text-indent: 0.5rem;
}

/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}

/* extra small resolution */
@media only screen and (min-width: 150px) and (max-width: 340px) {
  /* #dropdown {
    background: red;
    top: 2.3%;
    left: 20%;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  } */

  .addBTN {
    visibility: hidden;
  }
}

/* small resolution */
@media only screen and (min-width: 340px) and (max-width: 500px) {
  /* #dropdown {
    background: orange;
    top: 0.5%;
    left: 30%;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  } */

  .addBTN {
    visibility: hidden;
  }
}

/* medium resolution */
@media only screen and (min-width: 500px) and (max-width: 768px) {
  /* #dropdown {
    background: yellow;
    top: 0.5%;
    left: 48%;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  } */

  .addNewArticle {
    font-size: 11px;
    word-break: keep-all;
  }

  .addBTN {
    visibility: hidden;
  }
}

/* large resolution */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* #dropdown {
    background: green;
    top: 1.6%;
    left: 60.9%;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  } */

  .addBTN {
    visibility: hidden;
  }
}

/* xtra large resolution */
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  /* #dropdown {
    background: blue;
    top: 2.2%;
    left: 58.5%;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  } */

  .addBTN1 {
    visibility: hidden;
  }
}

/* 2xtra large resolution */
@media only screen and (min-width: 1280px) and (max-width: 1536px) {
  /* #dropdown {
    background: indigo;
    top: 2.3%;
    left: 59%;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  } */

  .addBTN1 {
    visibility: hidden;
  }
}

@media only screen and (min-width: 1536px) and (max-width: 1700px) {
  /* #dropdown {
    background: pink;
    top: 3.8%;
    left: 57%;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  } */

  .addBTN1 {
    visibility: hidden;
  }
}

@media only screen and (min-width: 1700px) and (max-width: 2500px) {
  /* #dropdown {
    background: burlywood;
    top: 9%;
    left: 46.9%;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  } */

  .addBTN1 {
    visibility: hidden;
  }
}
</style>
