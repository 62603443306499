<template>
  <div class="h-screen w-full">
    <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
    <div
      id="mobile_parent_div"
      class="fixed inset-0 z-40 md:hidden animated mobile_nav_close hidden"
    >
      <div
        class="fixed inset-0 bg-gray-600 bg-opacity-75"
        aria-hidden="true"
      ></div>

      <div
        id="mobile_nav"
        class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white dark:bg-darkBG shadow-md"
      >
        <div class="absolute top-0 right-0 -mr-12 pt-2">
          <button
            @click="hideMobileNav"
            type="button"
            class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
          >
            <span class="sr-only">Close sidebar</span>
            <Icons :name="'x-mark'" class="w-6 h-6 text-white"></Icons>
          </button>
        </div>
        <div class="flex-shrink-0 flex items-center px-10 -mt-4 mr-2">
          <img src="../assets/Redams-Logo-v1.png" alt="System Logo" />
        </div>

        <!-- <div class="flex-shrink-0 flex items-center px-4"> <img class="h-10 w-auto rounded-full" v-for="item in imgUrls" :key="item" :src="item.url"  alt="Avatar" /> <h1 class="font-semibold ml-3 text-gray-600"> <h1 class="text-xs font-semibold ml-3 text-gray-600"> {{ username }} </h1> </h1> </div> -->
        <div class="mt-5 flex-1 h-0 overflow-y-auto">
          <nav class="flex-1 px-2 py-4 space-y-1">
            <div
              class="uppercase ml-3 font-semibold text-xs pb-3 text-gray-800"
            >
              Main menu
            </div>
            <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
            <ul>
              <li class="py-0.5">
                <router-link
                  @click="
                    hideMobileNav();
                    refreshNotification();
                  "
                  to="/userspage/dashboard"
                  class="text-gray-500 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-1 text-xs font-medium rounded-md"
                >
                  <Icons :name="'squares-2x2'" class="w-6 h-6 mr-3"></Icons>
                  Dashboard
                </router-link>
              </li>

              <li class="py-0.5">
                <router-link
                  @click="
                    hideMobileNav();
                    refreshNotification();
                  "
                  to="/userspage/journal"
                  class="text-gray-500 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-1 text-xs font-medium rounded-md"
                >
                  <Icons :name="'book-open'" class="w-6 h-6 mr-3"></Icons>
                  Articles
                </router-link>
              </li>

              <li
                v-if="
                  this.$store.getters.getUserRole != 4 &&
                  this.$store.getters.getUserRole != 5 &&
                  this.$store.getters.getUserRole != 6 &&
                  this.$store.getters.getUserRole != 7
                "
                class="py-0.5"
              >
                <router-link
                  @click="
                    hideMobileNav();
                    refreshNotification();
                  "
                  to="/userspage/accverify"
                  class="text-gray-500 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-1 text-xs font-medium rounded-md"
                >
                  <Icons :name="'users'" class="w-6 h-6 mr-3"></Icons>

                  Account Verification
                </router-link>
              </li>
              <li
                v-if="
                  this.$store.getters.getUserRole != 4 &&
                  this.$store.getters.getUserRole != 5 &&
                  this.$store.getters.getUserRole != 6 &&
                  this.$store.getters.getUserRole != 7
                "
                class="py-0.5"
              >
                <router-link
                  @click="
                    hideMobileNav();
                    refreshNotification();
                  "
                  to="/userspage/announcement"
                  class="text-gray-500 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-1 text-xs font-medium rounded-md"
                >
                  <Icons :name="'megaphone'" class="w-6 h-6 mr-3"></Icons>

                  Announcement
                </router-link>
              </li>
            </ul>

            <div
              class="uppercase ml-3 font-semibold text-xs pb-3 pt-3 text-gray-800"
            >
              My works
            </div>
            <ul>
              <li class="py-0.5">
                <router-link
                  @click="
                    hideMobileNav();
                    refreshNotification();
                  "
                  to="/userspage/mywork"
                  class="text-gray-500 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-1 text-xs font-medium rounded-md"
                >
                  <Icons
                    :name="'chat-bubble-bottom'"
                    class="w-6 h-6 mr-3"
                  ></Icons>
                  Upload an article
                </router-link>
              </li>
              <li class="py-0.5">
                <router-link
                  @click="
                    hideMobileNav();
                    refreshNotification();
                  "
                  to="/userspage/requests"
                  class="text-gray-500 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-1 text-xs font-medium rounded-md"
                >
                  <Icons
                    :name="'chat-bubble-bottom'"
                    class="w-6 h-6 mr-3"
                  ></Icons>
                  <!-- <Icons :name="'document'" class="w-6 h-6 mr-3"></Icons> -->
                  Article Requests
                </router-link>
              </li>
              <li v-if="$store.getters.getUserRole == 1" class="py-0.5">
                <router-link
                  to="/userspage/logs"
                  class="mb-0.5 text-gray-500 hover:bg-customGreen hover:text-white group flex items-center px-2 py-1 text-xs font-medium rounded-md"
                  @click="
                    refreshNotification();
                    hideMobileNav();
                  "
                >
                  <Icons
                    :name="'clipboard-document-list'"
                    class="w-6 h-6 mr-3"
                  ></Icons>
                  Logs
                </router-link>
              </li>
            </ul>

            <div
              v-if="
                this.$store.getters.getUserRole != 4 &&
                this.$store.getters.getUserRole != 5 &&
                this.$store.getters.getUserRole != 6 &&
                this.$store.getters.getUserRole != 7
              "
            >
              <div
                class="uppercase ml-3 font-semibold text-xs pb-3 pt-3 text-gray-800"
              >
                Approval
              </div>
              <ul>
                <li>
                  <router-link
                    @click="
                      hideMobileNav();
                      refreshNotification();
                    "
                    to="/userspage/approval"
                    class="text-gray-500 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-1 text-xs font-medium rounded-md"
                  >
                    <Icons
                      :name="'chat-bubble-bottom'"
                      class="w-6 h-6 mr-3"
                    ></Icons>
                    Researches
                  </router-link>
                </li>
              </ul>
            </div>

            <div
              v-if="
                this.$store.getters.getUserRole != 4 &&
                this.$store.getters.getUserRole != 5 &&
                this.$store.getters.getUserRole != 6 &&
                this.$store.getters.getUserRole != 7
              "
            >
              <div
                class="uppercase ml-3 font-semibold text-xs pb-3 pt-3 text-gray-800"
              >
                Category
              </div>
              <ul>
                <li>
                  <router-link
                    to="/userspage/category"
                    class="mb-0.5 text-gray-500 hover:bg-customGreen hover:text-white group flex items-center px-2 py-1 text-xs font-medium rounded-md"
                    @click="
                      refreshNotification();
                      hideMobileNav();
                    "
                  >
                    <Icons
                      :name="'rectangle-group'"
                      class="w-6 h-6 mr-3"
                    ></Icons>
                    Journal Category
                  </router-link>
                </li>
              </ul>
            </div>

            <div v-if="this.$store.getters.getUserRole == 1">
              <div
                class="uppercase ml-3 font-semibold text-xs pb-3 pt-3 text-gray-800"
              >
                Events
              </div>
              <ul>
                <li>
                  <router-link
                    to="/userspage/events"
                    class="mb-0.5 text-gray-500 hover:bg-customGreen hover:text-white group flex items-center px-2 py-1 text-xs font-medium rounded-md"
                    @click="
                      refreshNotification();
                      hideMobileNav();
                    "
                  >
                    <!-- <Icons :name="'rectangle-group'" class="w-6 h-6 mr-3"></Icons> -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6 inline mr-3"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
                      />
                    </svg>

                    List of Events
                  </router-link>
                </li>
              </ul>
            </div>

            <div
              class="uppercase ml-3 font-semibold text-xs pb-3 pt-3 text-gray-800"
            >
              Account
            </div>

            <ul>
              <li class="py-0.5">
                <router-link
                  @click="
                    hideMobileNav();
                    refreshNotification();
                  "
                  to="/userspage/myprofile"
                  class="text-gray-500 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-1 text-xs font-medium rounded-md"
                >
                  <!-- <Icons :name="'user'" class="w-6 h-6 mr-3"></Icons> -->
                  <Icons
                    :name="'adjustments-vertical'"
                    class="w-6 h-6 mr-3"
                  ></Icons>
                  Account Setting
                </router-link>
              </li>

              <!-- <li> <router-link @click="hideMobileNav(); refreshNotification(); " to="/userspage/myprofile/security" class=" text-gray-500 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-1 text-xs font-medium rounded-md "> <Icons :name="'adjustments-vertical'" class="w-6 h-6 mr-3"></Icons> Security Settings </router-link> </li> -->
              <li class="pt-0.5">
                <router-link
                  @click="
                    hideMobileNav();
                    refreshNotification();
                  "
                  to="/logout"
                  class="text-gray-500 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-xs font-medium rounded-md"
                >
                  <Icons
                    :name="'arrow-right-on-rectangle'"
                    class="w-5 h-5 mr-3"
                  ></Icons>
                  Logout
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <!-- end mobile nav here -->
    </div>

    <div
      class="md:hidden lg:hidden xl:hidden 2xl:hidden flex justify-between border-b-2"
      aria-hidden="true"
      id="burger_div"
    >
      <!-- Dummy element to force sidebar to shrink to fit close icon -->
      <!-- burger icon -->
      <div class="pl-4">
        <button class="bg- w-10 h-12" @click="openMobileNav">
          <Icons :name="'burger'" class="w-6 h-6"></Icons>
        </button>
      </div>
      <div class="pt-1">
        <img
          class="h-10 w-18 inline"
          src="../assets/Redams-Logo-v1.png"
          alt="System Logo"
        />
      </div>
      <div class="py-3 pr-5">
        <button
          type="button"
          @click="toggleDropdown('avatar')"
          data-bs-toggle="collapse"
          data-bs-target="#avatarDiv"
          aria-expanded="false"
          aria-controls="avatarDiv"
          class="bg-white dark:bg-black flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-105005"
        >
          <span class="sr-only">Open user menu</span>
          <!-- <img v-for="item in imgUrls" :key="item" :src="item.url" class="w-6 h-6 rounded-full" alt="avatar" /> -->
          <img
            :src="filePath"
            class="w-7 h-7 rounded-full dark:border dark:border-gray-200"
            alt="avatar"
          />
        </button>
      </div>
      <div
        id="avatarDiv"
        class="mt-14 mr-3 collapse multi-collapse origin-top-right absolute right-0 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-darkBG ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <!-- Active: "bg-gray-100", Not Active: "" -->
        <ul class="border">
          <li>
            <router-link
              to="/userspage/myprofile"
              class="text-gray-500 hover:bg-customGreen hover:text-white group flex items-center px-2 py-2 text-xs font-medium rounded-md"
            >
              <Icons
                :name="'adjustments-vertical'"
                class="w-6 h-6 mr-3"
              ></Icons>
              Account Settings
            </router-link>
            <!-- <a href="#" class=" text-gray-500 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-xs font-medium rounded-md " > <Icons :name="'adjustments-vertical'" class="w-6 h-6 mr-3" ></Icons> Settings </a> -->
          </li>

          <li>
            <router-link
              to="/logout"
              class="text-gray-500 hover:bg-customGreen hover:text-white group flex items-center px-2 py-2 text-xs font-medium rounded-md"
            >
              <Icons
                :name="'arrow-right-on-rectangle'"
                class="w-6 h-6 mr-3"
              ></Icons>
              Logout
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <!-- UI FOR DESKTOP VIEW -->
    <!-- Static sidebar for desktop -->
    <div
      class="hidden md:flex dark:bg-darkBG border-r-2 dark:border-gray-800 dark:text-gray-400 dark:shadow-md dark:shadow-white md:w-64 md:fixed md:inset-y-0 overflow-y-auto"
    >
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex-1 flex flex-col min-h-0">
        <div
          class="flex items-center h-16 flex-shrink-0 px-5 pt-7 bg-transparent"
        >
          <img src="../assets/Redams-Logo-v1.png" alt="System Logo" />
        </div>
        <!-- <div class="flex items-center h-16 flex-shrink-0 px-4 pt-6 bg-transparent">
      	<img class="h-10 w-auto rounded-full" v-for="item in imgUrls" :key="item" :src="item.url"  alt="Avatar" />
      	<h1 class=" text-xs font-semibold ml-3 truncate whitespace-nowrap overflow-hidden w-24 text-gray-600 ">
        	{{ username }}
      	</h1>
    	  </div> -->
        <div class="flex-1 flex flex-col pt-6 pr-2">
          <nav class="flex-1 px-2 py-4 space-y-1">
            <div
              @click="mainMenuDivClicked = !mainMenuDivClicked"
              data-bs-toggle="collapse"
              data-bs-target="#mainMenuDiv"
              aria-expanded="true"
              aria-controls="mainMenuDiv"
              class="uppercase flex justify-between ml-3 cursor-pointer font-semibold text-xs pb-3 text-gray-800 dark:text-gray-100"
            >
              <h4>Main menu</h4>
              <div
                :class="
                  mainMenuDivClicked
                    ? 'transform rotate-180 duration-150'
                    : 'transform rotate-0 duration-150'
                "
              >
                <Icons :name="'chevron-down'" class="w-4 h-4"></Icons>
              </div>
            </div>
            <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
            <ul class="collapse show" id="mainMenuDiv">
              <li>
                <router-link
                  to="/userspage/dashboard"
                  class="text-gray-500 dark:text-gray-100 hover:bg-customGreen hover:text-white group flex items-center px-2 py-1 mb-0.5 text-xs font-medium rounded-md"
                  @click="refreshNotification"
                >
                  <Icons :name="'squares-2x2'" class="w-6 h-6 mr-3"></Icons>
                  Dashboard
                </router-link>
              </li>
              <li>
                <router-link
                  to="/userspage/journal"
                  ref="journal"
                  class="mb-0.5 text-gray-500 dark:text-gray-100 hover:bg-customGreen hover:text-white group flex items-center px-2 py-1 text-xs font-medium rounded-md"
                  @click="refreshNotification"
                >
                  <Icons :name="'book-open'" class="w-6 h-6 mr-3"></Icons>
                  Articles
                </router-link>
              </li>

              <li
                v-if="
                  this.$store.getters.getUserRole != 4 &&
                  this.$store.getters.getUserRole != 5 &&
                  this.$store.getters.getUserRole != 6 &&
                  this.$store.getters.getUserRole != 7
                "
              >
                <router-link
                  ref="accountVerify"
                  to="/userspage/accverify"
                  class="mb-0.5 text-gray-500 dark:text-gray-100 hover:bg-customGreen hover:text-white group flex items-center px-2 py-1 text-xs font-medium rounded-md"
                  @click="refreshNotification"
                >
                  <Icons :name="'users'" class="w-6 h-6 mr-3"></Icons>

                  Account Verification
                </router-link>
              </li>

              <li
                v-if="
                  this.$store.getters.getUserRole != 4 &&
                  this.$store.getters.getUserRole != 5 &&
                  this.$store.getters.getUserRole != 6 &&
                  this.$store.getters.getUserRole != 7
                "
              >
                <router-link
                  ref="userAnnouncement"
                  to="/userspage/announcement"
                  class="mb-0.5 text-gray-500 dark:text-gray-100 hover:bg-customGreen hover:text-white group flex items-center px-2 py-1 text-xs font-medium rounded-md"
                  @click="refreshNotification"
                >
                  <Icons :name="'megaphone'" class="w-6 h-6 mr-3"></Icons>

                  Announcement
                </router-link>
              </li>
              <li v-if="this.$store.getters.getUserRole != 1">
                <router-link
                  to="/userspage/events"
                  class="mb-0.5 text-gray-500 dark:text-gray-100 hover:bg-customGreen hover:text-white group flex items-center px-2 py-1 text-xs font-medium rounded-md"
                  @click="refreshNotification"
                >
                  <!-- <Icons :name="'rectangle-group'" class="w-6 h-6 mr-3"></Icons> -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 inline mr-3"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
                    />
                  </svg>
                  List of Events
                </router-link>
              </li>
            </ul>

            <div
              @click="myWorksDivClicked = !myWorksDivClicked"
              v-if="!isGmail"
              data-bs-toggle="collapse"
              data-bs-target="#myWorksDiv"
              aria-expanded="false"
              aria-controls="myWorksDiv"
              class="flex justify-between uppercase cursor-pointer ml-3 font-semibold text-xs pb-3 pt-3 dark:text-gray-100 text-gray-800"
            >
              <h4>My works</h4>
              <div
                :class="
                  myWorksDivClicked
                    ? 'transform rotate-180 duration-150'
                    : 'transform rotate-0 duration-150'
                "
              >
                <Icons :name="'chevron-down'" class="w-4 h-4"></Icons>
              </div>
            </div>
            <ul v-if="!isGmail" class="collapse multi-collapse" id="myWorksDiv">
              <li>
                <router-link
                  ref="myWorks"
                  to="/userspage/mywork"
                  class="mb-0.5 text-gray-500 hover:bg-customGreen hover:text-white dark:text-gray-100 group flex items-center px-2 py-1 text-xs font-medium rounded-md"
                  @click="refreshNotification"
                >
                  <!-- <Icons :name="'document'" class="w-6 h-6 mr-3"></Icons> -->
                  <Icons
                    :name="'chat-bubble-bottom'"
                    class="w-6 h-6 mr-3"
                  ></Icons>
                  Upload an Articles
                </router-link>
              </li>
              <li>
                <router-link
                  ref="requests"
                  to="/userspage/requests"
                  class="mb-0.5 text-gray-500 hover:bg-customGreen hover:text-white dark:text-gray-100 group flex items-center px-2 py-1 text-xs font-medium rounded-md"
                  @click="refreshNotification"
                >
                  <!-- <Icons :name="'document'" class="w-6 h-6 mr-3"></Icons> -->
                  <Icons
                    :name="'chat-bubble-bottom'"
                    class="w-6 h-6 mr-3"
                  ></Icons>
                  Article Requests
                </router-link>
              </li>
              <li v-if="this.$store.getters.getUserRole == 1">
                <router-link
                  to="/userspage/logs"
                  class="mb-0.5 text-gray-500 hover:bg-customGreen hover:text-white dark:text-gray-100 group flex items-center px-2 py-1 text-xs font-medium rounded-md"
                  @click="refreshNotification"
                >
                  <Icons
                    :name="'clipboard-document-list'"
                    class="w-4 h-4 mr-3"
                  ></Icons>
                  Logs
                </router-link>
              </li>
            </ul>

            <div
              v-if="
                this.$store.getters.getUserRole != 4 &&
                this.$store.getters.getUserRole != 5 &&
                this.$store.getters.getUserRole != 6 &&
                this.$store.getters.getUserRole != 7
              "
            >
              <div
                @click="approvalDivClicked = !approvalDivClicked"
                data-bs-toggle="collapse"
                data-bs-target="#approvalDiv"
                aria-expanded="false"
                aria-controls="approvalDiv"
                class="flex justify-between uppercase cursor-pointer ml-3 dark:text-gray-100 font-semibold text-xs pb-3 pt-3 text-gray-800"
              >
                <h4>Approval</h4>
                <div
                  :class="
                    approvalDivClicked
                      ? 'transform rotate-180 duration-150'
                      : 'transform rotate-0 duration-150'
                  "
                >
                  <Icons :name="'chevron-down'" class="w-4 h-4"></Icons>
                </div>
              </div>
              <ul id="approvalDiv" class="collapse multi-collapse">
                <li>
                  <router-link
                    ref="approval"
                    to="/userspage/approval"
                    class="mb-0.5 text-gray-500 hover:bg-customGreen dark:text-gray-100 hover:text-white group flex items-center px-2 py-1 text-xs font-medium rounded-md"
                    @click="refreshNotification"
                  >
                    <Icons
                      :name="'chat-bubble-bottom'"
                      class="w-6 h-6 mr-3"
                    ></Icons>

                    Article Approval
                  </router-link>
                </li>
              </ul>
            </div>

            <div
              v-if="
                this.$store.getters.getUserRole != 4 &&
                this.$store.getters.getUserRole != 5 &&
                this.$store.getters.getUserRole != 6 &&
                this.$store.getters.getUserRole != 7
              "
            >
              <div
                @click="categoryDivClicked = !categoryDivClicked"
                data-bs-toggle="collapse"
                data-bs-target="#categoryDiv"
                aria-expanded="false"
                aria-controls="categoryDiv"
                class="flex justify-between uppercase cursor-pointer dark:text-gray-100 ml-3 font-semibold text-xs pb-3 pt-3 text-gray-800"
              >
                <h4>Category</h4>
                <div
                  :class="
                    categoryDivClicked
                      ? 'transform rotate-180 duration-150'
                      : 'transform rotate-0 duration-150'
                  "
                >
                  <Icons :name="'chevron-down'" class="w-4 h-4"></Icons>
                </div>
              </div>
              <ul id="categoryDiv" class="collapse multi-collapse">
                <li>
                  <router-link
                    to="/userspage/category"
                    class="mb-0.5 text-gray-500 hover:bg-customGreen dark:text-gray-100 hover:text-white group flex items-center px-2 py-1 text-xs font-medium rounded-md"
                    @click="refreshNotification"
                  >
                    <Icons
                      :name="'rectangle-group'"
                      class="w-6 h-6 mr-3"
                    ></Icons>
                    Article Category
                  </router-link>
                </li>

                <!-- <li> <router-link to="/userspage/announcement" class=" mb-0.5 text-gray-500 hover:bg-customGreen hover:text-white group flex items-center px-2 py-1 text-xs font-medium rounded-md " > <svg class=" fill-current group-hover:text-white mr-3 flex-shrink-0 h-6 w-6 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" > <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z" /> </svg> Announcement </router-link> </li> -->
              </ul>
            </div>

            <div v-if="this.$store.getters.getUserRole == 1">
              <div
                @click="eventsDivClicked = !eventsDivClicked"
                data-bs-toggle="collapse"
                data-bs-target="#eventsDiv"
                aria-expanded="false"
                aria-controls="eventsDiv"
                class="flex justify-between uppercase cursor-pointer dark:text-gray-100 ml-3 font-semibold text-xs pb-3 pt-3 text-gray-800"
              >
                <h4>Events</h4>
                <div
                  :class="
                    eventsDivClicked
                      ? 'transform rotate-180 duration-150'
                      : 'transform rotate-0 duration-150'
                  "
                >
                  <Icons :name="'chevron-down'" class="w-4 h-4"></Icons>
                </div>
              </div>
              <ul id="eventsDiv" class="collapse multi-collapse">
                <li>
                  <router-link
                    to="/userspage/events"
                    class="mb-0.5 text-gray-500 hover:bg-customGreen dark:text-gray-100 hover:text-white group flex items-center px-2 py-1 text-xs font-medium rounded-md"
                    @click="refreshNotification"
                  >
                    <!-- <Icons :name="'rectangle-group'" class="w-6 h-6 mr-3"></Icons> -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6 inline mr-3"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
                      />
                    </svg>

                    List of Events
                  </router-link>
                </li>

                <!-- <li> <router-link to="/userspage/announcement" class=" mb-0.5 text-gray-500 hover:bg-customGreen hover:text-white group flex items-center px-2 py-1 text-xs font-medium rounded-md " > <svg class=" fill-current group-hover:text-white mr-3 flex-shrink-0 h-6 w-6 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" > <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z" /> </svg> Announcement </router-link> </li> -->
              </ul>
            </div>

            <div
              @click="accountsDivClicked = !accountsDivClicked"
              data-bs-toggle="collapse"
              data-bs-target="#accountsDiv"
              aria-expanded="false"
              aria-controls="accountsDiv"
              class="flex justify-between uppercase cursor-pointer dark:text-gray-100 ml-3 font-semibold text-xs pb-3 pt-3 text-gray-800"
            >
              <h4>Account</h4>
              <div
                :class="
                  accountsDivClicked
                    ? 'transform rotate-180 duration-150'
                    : 'transform rotate-0 duration-150'
                "
              >
                <Icons :name="'chevron-down'" class="w-4 h-4"></Icons>
              </div>
            </div>

            <ul id="accountsDiv" class="collapse multi-collapse">
              <li>
                <router-link
                  to="/userspage/myprofile"
                  class="mb-0.5 text-gray-500 hover:bg-customGreen dark:text-gray-100 hover:text-white group flex items-center px-2 py-1 text-xs font-medium rounded-md"
                  @click="refreshNotification"
                >
                  <Icons
                    :name="'adjustments-vertical'"
                    class="w-6 h-6 mr-3"
                  ></Icons>
                  <!-- <Icons :name="'user'" class="w-6 h-6 mr-3"></Icons> -->
                  Account Settings
                  <!-- Profile -->
                </router-link>
              </li>
              <li>
                <router-link
                  to="/userspage/tutorials"
                  class="mb-0.5 text-gray-500 hover:bg-customGreen dark:text-gray-100 hover:text-white group flex items-center px-2 py-1 text-xs font-medium rounded-md"
                  @click="refreshNotification"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 inline mr-3"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                    />
                  </svg>

                  Help
                </router-link>
              </li>

              <!-- <li v-if="!$store.getters.getIsGoogleSignIn">
            	<router-link to="/userspage/myprofile/security"
              	class=" mb-0.5 text-gray-500 hover:bg-customGreen hover:text-white group flex items-center px-2 py-1 text-xs font-medium rounded-md "
              	@click="refreshNotification">
              	<Icons :name="'adjustments-vertical'" class="w-6 h-6 mr-3"></Icons>

              	Security Settings
            	</router-link>
          	</li> -->
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <!-- MAIN DIV -->
    <div class="relative md:static lg:static xl:static flex md:pl-60 w-full">
      <div
        :class="
          sideBarOpen
            ? 'w-full transition-all duration-500 translate-x-0'
            : 'w-screen transition  duration-300 ease-in-out'
        "
        class="h-screen overflow-hidden overflow-y-auto md:ml-4 bg-gray-100 dark:bg-darkBG border border-r-2 dark:border-gray-800"
        id="main_div"
      >
        <div>
          <button
            title="Back to top"
            class="absolute right-0 bottom-0 shadow-lg ring-2 dark:text-gray-300 ring-offset-1 ring-105005 bg-105005 p-3 rounded-full mr-20 mb-7"
            :class="
              windowTop > 300
                ? 'ease-in-out duration-300'
                : 'fade ease-in-out block duration-300'
            "
            @click="toTop"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-5 h-5 text-white dark:text-gray-300"
            >
              <path
                fill-rule="evenodd"
                d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
        <router-view
          v-on:success_alert="SuccessMsg"
          v-on:getImage="getImage"
        ></router-view>
      </div>
      <!-- right side bar -->
      <div
        class="hidden sm:hidden md:block lg:block xl:block h-screen overflow-hidden pl-2 bg-white dark:bg-darkBG"
        :class="
          sideBarOpen
            ? 'md:absolute transition duration-700 ease-in-out lg:absolute xl:absolute md:right-0 lg:right-0 xl:right-0 overflow-y-scroll w-2/12 xl:w-64 xl:shadow-2xl border-l-2 border-gray-800'
            : 'xl:pl-2 transition duration-700 ease-in-out'
        "
        id="sidebar_parent_div"
      >
        <div
          class="hidden mt-4"
          :class="
            sideBarOpen
              ? 'md:flex sidebar_r animated transition duration-700 ease-out'
              : 'md:grid '
          "
          id="sidebar_div"
        >
          <!-- sidebar nav -->
          <div
            class="items-center md:mt-2"
            :class="sideBarOpen ? 'md:ml-4' : ''"
          >
            <button
              @click="toggleSideBarOpen"
              v-show="!sideBarOpen"
              type="button"
              class="bg-white dark:bg-darkBG px-1 pr-5 rounded-full text-gray-400 hover:text-gray-500 outline-none"
            >
              <span class="sr-only">View right sidebar</span>
              <!-- left arrow -->
              <Icons :name="'chevron-left'" class="w-5 h-5"></Icons>
            </button>

            <button
              @click="toggleSideBarClose"
              type="button"
              v-show="sideBarOpen"
              class="bg-white dark:bg-darkBG px-1 rounded-full text-gray-400 hover:text-gray-500 outline-none"
            >
              <span class="sr-only">View right sidebar</span>
              <!-- right-arrow -->
              <Icons :name="'chevron-right'" class="w-5 h-5"></Icons>
            </button>
          </div>

          <div>
            <div
              class="items-center md:mt-2"
              :class="sideBarOpen ? 'xl:ml-32 lg:ml-14' : ''"
              id="bell_icon"
            >
              <button
                @click="toggleDropdown('notif')"
                type="button"
                class="relative flex bg-white dark:bg-darkBG px-1 text-gray-400 hover:text-gray-500"
              >
                <Icons :name="'bell'" class="w-6 h-6 flex-1"></Icons>

                <span
                  v-if="notifCount != 0"
                  class="absolute left-0 top-0 rounded-full bg-red-600 w-4 h-4 top left pt-0.5 pr-0.5 m-0 text-white font-mono leading-tight text-center"
                  style="font-size: 9px"
                  >{{ notifCount }}</span
                >
              </button>
            </div>
            <div
              :class="
                notifDropdown
                  ? 'fadeDown duration-[5000ms] '
                  : 'hidden duration-700 origin-top-right'
              "
              class="fixed right-0 mt-2 h-72 overflow-y-auto w-72 rounded-md shadow-2xl py-3 px-3 bg-white dark:bg-darkBG ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu-button"
              tabindex="-1"
            >
              <div class="flex justify-between">
                <div>
                  <h5 class="font-semibold text-sm mb-3">
                    Notifications
                    <span class="align-right text-xs text-gray-400"
                      >({{ notifData.length }})</span
                    >
                  </h5>
                </div>
                <div>
                  <button
                    title="Mark all as read"
                    @click="markAllAsRead"
                    :disabled="notifCount > 0 ? false : true"
                  >
                    <svg
                      :class="notifCount > 0 ? 'text-105005' : 'text-gray-300'"
                      class="h-5 w-5 text-105005 inline -mt-2"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <path d="M7 12l5 5l10 -10" />
                      <path d="M2 12l5 5m5 -5l5 -5" />
                    </svg>
                  </button>
                </div>
              </div>

              <div v-if="notifData.length > 0">
                <div
                  v-for="(item, index) in notifData.filter((m) => m.nn === '0')"
                  :key="item"
                >
                  <div class="separator" v-if="item.nn == '0' && index <= 0">
                    <span class="separator-span text-gray-400">Today</span>
                  </div>
                  <div
                    class="border rounded-lg mt-1 px-2 pt-1 pb-1 shadow-sm hover:bg-green-100"
                    :class="
                      item.notify_isOpened
                        ? 'text-gray-700 hover:border-green-500  hover:text-105005'
                        : 'font-semibold bg-green-100 text-customGreen hover:border-green-500  hover:text-105005'
                    "
                  >
                    <div
                      class="inline-flex"
                      @click="redirectNotif(item.notify_notifType, item)"
                    >
                      <div class="py-2 px-2 rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          class="w-6 h-6"
                          v-if="item.notify_isOpened == true"
                          :class="
                            item.notify_isOpened
                              ? 'text-gray-400'
                              : 'animate-pulse text-yellow-500'
                          "
                        >
                          <path
                            d="M19.5 22.5a3 3 0 003-3v-8.174l-6.879 4.022 3.485 1.876a.75.75 0 01-.712 1.321l-5.683-3.06a1.5 1.5 0 00-1.422 0l-5.683 3.06a.75.75 0 01-.712-1.32l3.485-1.877L1.5 11.326V19.5a3 3 0 003 3h15z"
                          />
                          <path
                            d="M1.5 9.589v-.745a3 3 0 011.578-2.641l7.5-4.039a3 3 0 012.844 0l7.5 4.039A3 3 0 0122.5 8.844v.745l-8.426 4.926-.652-.35a3 3 0 00-2.844 0l-.652.35L1.5 9.59z"
                          />
                        </svg>
                        <!-- UNREAD -->
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          class="w-6 h-6 text-105005"
                          v-if="item.notify_isOpened == false"
                          :class="
                            item.notify_isOpened
                              ? 'animate-none'
                              : 'animate-pulse text-customGreen'
                          "
                        >
                          <path
                            d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z"
                          />
                          <path
                            d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z"
                          />
                        </svg>
                      </div>

                      <p
                        class="font-poppins text-xs align-item-center mt-2 ml-2 cursor-pointer"
                      >
                        {{ item.notify_notifBody }}<br />
                        <!-- <span style="font-size: 9px">{{ stringFormatDate(formatDate(item.createdAt)) }}
                  	</span> -->
                        <span style="font-size: 9px"
                          >{{ moment(item.notify_createdAt).fromNow() }}
                        </span>
                        <!-- <span style="font-size: 9px">{{ timeAgo.format(Date.now() - 60 * 1000) }}
                  	</span> -->
                        <!-- <span style="font-size: 9px">{{ momentTZ(formatDate2(item.createdAt), "Asia/Manila").startOf('hour').fromNow() }}
                  	</span> -->
                      </p>
                    </div>
                    <div>
                      <!-- <p class="font-poppins mt-1 ml-12" style="font-size: 9px">
                	{{ stringFormatDate(formatDate(item.createdAt)) }}
              	</p> -->
                    </div>
                  </div>
                </div>
                <div
                  v-for="(item, index) in notifData.filter((m) => m.nn === '1')"
                  :key="item"
                >
                  <div class="separator" v-if="item.nn == '1' && index <= 0">
                    <span class="separator-span text-gray-400">Yesterday</span>
                  </div>
                  <div
                    class="border rounded-lg mt-1 px-2 pt-1 pb-1 shadow-sm hover:bg-green-100"
                    :class="
                      item.notify_isOpened
                        ? 'text-gray-700 hover:border-green-500  hover:text-105005'
                        : 'font-semibold bg-green-100 text-customGreen hover:border-green-500  hover:text-105005'
                    "
                  >
                    <div
                      class="inline-flex"
                      @click="redirectNotif(item.notify_notifType, item)"
                    >
                      <div class="py-2 px-2 rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          class="w-6 h-6"
                          v-if="item.notify_isOpened == true"
                          :class="
                            item.notify_isOpened
                              ? 'text-gray-400'
                              : 'animate-pulse text-yellow-500'
                          "
                        >
                          <path
                            d="M19.5 22.5a3 3 0 003-3v-8.174l-6.879 4.022 3.485 1.876a.75.75 0 01-.712 1.321l-5.683-3.06a1.5 1.5 0 00-1.422 0l-5.683 3.06a.75.75 0 01-.712-1.32l3.485-1.877L1.5 11.326V19.5a3 3 0 003 3h15z"
                          />
                          <path
                            d="M1.5 9.589v-.745a3 3 0 011.578-2.641l7.5-4.039a3 3 0 012.844 0l7.5 4.039A3 3 0 0122.5 8.844v.745l-8.426 4.926-.652-.35a3 3 0 00-2.844 0l-.652.35L1.5 9.59z"
                          />
                        </svg>
                        <!-- UNREAD -->
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          class="w-6 h-6 text-105005"
                          v-if="item.notify_isOpened == false"
                          :class="
                            item.notify_isOpened
                              ? 'animate-none'
                              : 'animate-pulse text-customGreen'
                          "
                        >
                          <path
                            d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z"
                          />
                          <path
                            d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z"
                          />
                        </svg>
                      </div>

                      <p
                        class="font-poppins text-xs align-item-center mt-2 ml-2 cursor-pointer"
                      >
                        {{ item.notify_notifBody }}<br />
                        <span style="font-size: 9px"
                          >{{ moment(item.notify_createdAt).fromNow() }}
                        </span>
                      </p>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div
                  v-for="(item, index) in notifData.filter((m) => m.nn === '2')"
                  :key="item"
                >
                  <div class="separator" v-if="item.nn == '2' && index <= 0">
                    <span class="separator-span text-gray-400 font-poppins"
                      >This week</span
                    >
                  </div>
                  <div
                    class="border rounded-lg mt-1 px-2 pt-1 pb-1 shadow-sm"
                    :class="
                      item.notify_isOpened
                        ? 'text-gray-700 hover:border-green-500  hover:text-105005'
                        : 'font-semibold bg-green-100 text-customGreen hover:border-green-500  hover:text-105005'
                    "
                  >
                    <div
                      class="inline-flex"
                      @click="redirectNotif(item.notify_notifType, item)"
                    >
                      <div class="py-2 px-2 rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          class="w-6 h-6"
                          v-if="item.notify_isOpened == true"
                          :class="
                            item.notify_isOpened
                              ? 'text-gray-400'
                              : 'animate-pulse text-yellow-500'
                          "
                        >
                          <path
                            d="M19.5 22.5a3 3 0 003-3v-8.174l-6.879 4.022 3.485 1.876a.75.75 0 01-.712 1.321l-5.683-3.06a1.5 1.5 0 00-1.422 0l-5.683 3.06a.75.75 0 01-.712-1.32l3.485-1.877L1.5 11.326V19.5a3 3 0 003 3h15z"
                          />
                          <path
                            d="M1.5 9.589v-.745a3 3 0 011.578-2.641l7.5-4.039a3 3 0 012.844 0l7.5 4.039A3 3 0 0122.5 8.844v.745l-8.426 4.926-.652-.35a3 3 0 00-2.844 0l-.652.35L1.5 9.59z"
                          />
                        </svg>
                        <!-- UNREAD -->
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          class="w-6 h-6 text-105005"
                          v-if="item.notify_isOpened == false"
                          :class="
                            item.notify_isOpened
                              ? 'animate-none'
                              : 'animate-pulse text-customGreen'
                          "
                        >
                          <path
                            d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z"
                          />
                          <path
                            d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z"
                          />
                        </svg>
                      </div>

                      <p
                        class="font-poppins text-xs align-item-center mt-2 ml-2 cursor-pointer"
                      >
                        {{ item.notify_notifBody }}<br />
                        <span style="font-size: 9px"
                          >{{ moment(item.notify_createdAt).fromNow() }}
                        </span>
                      </p>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div
                  v-for="(item, index) in notifData.filter((m) => m.nn === '3')"
                  :key="item"
                >
                  <div class="separator" v-if="item.nn == '3' && index <= 0">
                    <span class="separator-span text-gray-400 font-poppins"
                      >More than a week</span
                    >
                  </div>
                  <div
                    class="border rounded-lg mt-1 px-2 pt-1 pb-1 shadow-sm hover:bg-green-100"
                    :class="
                      item.notify_isOpened
                        ? 'text-gray-700 hover:border-green-500  hover:text-105005'
                        : 'font-semibold bg-green-100 text-customGreen hover:border-green-500  hover:text-105005'
                    "
                  >
                    <div
                      class="inline-flex"
                      @click="redirectNotif(item.notify_notifType, item)"
                    >
                      <div class="py-2 px-2 rounded-full">
                        <!-- READ -->
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          class="w-6 h-6"
                          v-if="item.notify_isOpened == true"
                          :class="
                            item.notify_isOpened
                              ? 'text-gray-400'
                              : 'animate-pulse text-yellow-500'
                          "
                        >
                          <path
                            d="M19.5 22.5a3 3 0 003-3v-8.174l-6.879 4.022 3.485 1.876a.75.75 0 01-.712 1.321l-5.683-3.06a1.5 1.5 0 00-1.422 0l-5.683 3.06a.75.75 0 01-.712-1.32l3.485-1.877L1.5 11.326V19.5a3 3 0 003 3h15z"
                          />
                          <path
                            d="M1.5 9.589v-.745a3 3 0 011.578-2.641l7.5-4.039a3 3 0 012.844 0l7.5 4.039A3 3 0 0122.5 8.844v.745l-8.426 4.926-.652-.35a3 3 0 00-2.844 0l-.652.35L1.5 9.59z"
                          />
                        </svg>
                        <!-- UNREAD -->
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          class="w-6 h-6 text-105005"
                          v-if="item.notify_isOpened == false"
                          :class="
                            item.notify_isOpened
                              ? 'animate-none'
                              : 'animate-pulse text-customGreen'
                          "
                        >
                          <path
                            d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z"
                          />
                          <path
                            d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z"
                          />
                        </svg>
                      </div>

                      <p
                        class="font-poppins text-xs align-item-center mt-2 ml-2 cursor-pointer"
                      >
                        {{ item.notify_notifBody }}<br />
                        <span style="font-size: 9px"
                          >{{ moment(item.notify_createdAt).fromNow() }}
                        </span>
                      </p>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div
                  v-for="(item, index) in notifData.filter((m) => m.nn === '4')"
                  :key="item"
                >
                  <div class="separator" v-if="item.nn === '4' && index <= 0">
                    <span class="separator-span text-gray-400 font-poppins"
                      >A month ago</span
                    >
                  </div>
                  <div
                    class="border rounded-lg mt-1 px-2 pt-1 pb-1 shadow-sm hover:bg-green-100"
                    :class="
                      item.notify_isOpened
                        ? 'text-gray-700 hover:border-green-500  hover:text-105005'
                        : 'font-semibold bg-green-100 text-customGreen hover:border-green-500  hover:text-105005'
                    "
                  >
                    <div
                      class="inline-flex"
                      @click="redirectNotif(item.notify_notifType, item)"
                    >
                      <div class="py-2 px-2 rounded-full">
                        <!-- READ -->
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          class="w-6 h-6"
                          v-if="item.notify_isOpened == true"
                          :class="
                            item.notify_isOpened
                              ? 'text-gray-400'
                              : 'animate-pulse text-yellow-500'
                          "
                        >
                          <path
                            d="M19.5 22.5a3 3 0 003-3v-8.174l-6.879 4.022 3.485 1.876a.75.75 0 01-.712 1.321l-5.683-3.06a1.5 1.5 0 00-1.422 0l-5.683 3.06a.75.75 0 01-.712-1.32l3.485-1.877L1.5 11.326V19.5a3 3 0 003 3h15z"
                          />
                          <path
                            d="M1.5 9.589v-.745a3 3 0 011.578-2.641l7.5-4.039a3 3 0 012.844 0l7.5 4.039A3 3 0 0122.5 8.844v.745l-8.426 4.926-.652-.35a3 3 0 00-2.844 0l-.652.35L1.5 9.59z"
                          />
                        </svg>
                        <!-- UNREAD -->
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          class="w-6 h-6 text-105005"
                          v-if="item.notify_isOpened == false"
                          :class="
                            item.notify_isOpened
                              ? 'animate-none'
                              : 'animate-pulse text-customGreen'
                          "
                        >
                          <path
                            d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z"
                          />
                          <path
                            d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z"
                          />
                        </svg>
                      </div>

                      <p
                        class="font-poppins text-xs align-item-center mt-2 ml-2 cursor-pointer"
                      >
                        {{ item.notify_notifBody }}<br />
                        <!-- <span style="font-size: 9px">{{ stringFormatDate(formatDate(item.createdAt)) }}
                  	</span> -->
                        <span style="font-size: 9px"
                          >{{ moment(item.notify_createdAt).fromNow() }}
                        </span>
                        <!-- <span style="font-size: 9px">{{ timeAgo.format(Date.now() - 60 * 1000) }}
                  	</span> -->
                        <!-- <span style="font-size: 9px">{{ momentTZ(formatDate2(item.createdAt), "Asia/Manila").startOf('hour').fromNow() }}
                  	</span> -->
                      </p>
                    </div>
                    <div>
                      <!-- <p class="font-poppins mt-1 ml-12" style="font-size: 9px">
                	{{ stringFormatDate(formatDate(item.createdAt)) }}
              	</p> -->
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="notifData.length == 0">
                <p class="font-poppins text-xs">No notifications</p>
              </div>
            </div>
          </div>

          <!-- Profile dropdown -->
          <div class="">
            <div class="items-center md:mt-2 md:ml-1">
              <button
                type="button"
                @click="toggleDropdown('avatar')"
                data-bs-toggle="collapse"
                data-bs-target="#avatarDiv"
                aria-expanded="false"
                aria-controls="avatarDiv"
                class="bg-white dark:bg-darkBG flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-105005"
              >
                <span class="sr-only">Open user menu</span>
                <!-- <img v-for="item in imgUrls" :key="item" :src="item.url" class="w-6 h-6 rounded-full" alt="avatar" /> -->
                <img
                  :src="filePath"
                  class="w-6 h-6 rounded-full"
                  alt="avatar"
                />
              </button>
            </div>

            <!-- Dropdown menu, show/hide based on menu state. Entering: "transition ease-out duration-100" From: "transform opacity-0 scale-95" To: "transform opacity-100 scale-100" Leaving: "transition ease-in duration-75" From: "transform opacity-100 scale-100" To: "transform opacity-0 scale-95" -->
            <div
              id="avatarDiv"
              :class="this.avatarDropdown == true ? 'visible' : 'invisible'"
              class="collapse multi-collapse origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-darkBG ring-1 ring-black ring-opacity-5 focus:outline-none transition ease-out duration-100"
            >
              <!-- Active: "bg-gray-100", Not Active: "" -->
              <ul>
                <li>
                  <router-link
                    to="/userspage/myprofile"
                    class="text-gray-500 dark:text-gray-300 hover:bg-customGreen hover:text-white group flex items-center mx-1 my-1 px-2 py-2 text-xs font-medium rounded-md"
                  >
                    <Icons
                      :name="'adjustments-vertical'"
                      class="w-6 h-6 mr-3"
                    ></Icons>
                    Account Settings
                  </router-link>
                  <!-- <a href="#" class=" text-gray-500 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-xs font-medium rounded-md " > <Icons :name="'adjustments-vertical'" class="w-6 h-6 mr-3" ></Icons> Settings </a> -->
                </li>

                <li>
                  <router-link
                    to="/logout"
                    class="text-gray-500 hover:bg-customGreen dark:text-gray-300 hover:text-white group flex items-center mx-1 px-2 py-2 text-xs font-medium rounded-md"
                  >
                    <Icons
                      :name="'arrow-right-on-rectangle'"
                      class="w-6 h-6 mr-3"
                    ></Icons>
                    Logout
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- sidebar content -->
        <div
          v-show="sidebar_content"
          class="px-2 pt-4 w-full xl:w-10/12 md:grid lg:grid"
        >
          <div class="avatar w-auto m-auto mb-2">
            <!-- <img v-for="item in imgUrls" :key="item" :src="item.url" class="h-28 w-28 xl:ml-7 lg:ml-3 md:-ml-2 md:mr-2 rounded-full" alt="avatar" /> -->
            <img
              :src="filePath"
              class="h-28 w-28 xl:ml-8 lg:ml-3 md:-ml-2 md:mr-2 rounded-full ring-1 ring-105005"
              alt="avatar"
            />
            <h1
              class="text-center font-semibold xl:ml-5 xl:mt-2 lg:ml-0 md:-ml-10 text-105005 dark:text-lighterBg2"
            >
              {{ username }}
            </h1>
          </div>

          <!-- <div class="w-full ml-2 xl:ml-1 mb-6">
        	<h1 class="text-gray-600 font-semibold text-left mb-4">My Works</h1>
       	 
        	<div class="hoverable py-3 px-4 bg-gray-100 rounded-md mb-2 w-52 xl:w-48 md:w-48" v-for="item in contents" :key="item" @click="changeRouter('mywork')">
          	<h1 class="text-xs font-semibold">{{ item.file_title }}</h1>
          	<p class="text-xs text-gray-600">Click here to view your {{item.file_typeOfArticle == 1  ? 'Thesis': item.file_typeOfArticle == 2 ? 'Capstone': item.file_typeOfArticle == 3 ? 'Dissertation': item.file_typeOfArticle  == 4 ? 'Research': item.file_typeOfArticle == 5 ? 'Journal': item.file_typeOfArticle == 6 ? 'Others':''}}</p>

          	Thesis, Capstone, Dissertation, Research, Journal, Others
        	</div>

        	<div class="hoverable py-3 px-4 bg-gray-100 rounded-md mb-2 w-52 xl:w-48 md:w-48" @click="changeRouter('mywork')">
          	<h1 class="text-xs font-semibold">Unpublished Researches</h1>
          	<p class="text-xs">A list of your Unpublished Researches</p>
        	</div>
      	</div> -->

          <div class="w-3/4 mt-5 mb-6 xl:w-full xl:ml-5">
            <h1
              class="text-105005 dark:text-lighterBg font-semibold text-left mb-4"
            >
              Uploaded Files
              <span class="text-xs text-gray-500">({{ contentsLen }})</span>
            </h1>
            <div v-if="contentsLen == 0" class="h-auto overflow-y-auto"></div>
            <div v-else class="h-48 overflow-y-auto">
              <div
                class="hoverable py-3 px-4 bg-gray-100 dark:bg-darkBG border dark:border-gray-700 rounded-md mb-3 w-52 max-w-lg xl:w-48 md:w-48"
                @click="changeRouter('mywork')"
                v-for="item in contents"
                :key="item"
              >
                <h1
                  class="ellipsis text-gray-800 dark:text-gray-200 text-sm font-semibold w-32 uppercase"
                >
                  {{ item.file_title }}
                </h1>
                <p class="text-xs text-gray-500 dark:text-gray-400">
                  {{
                    item.file_typeOfArticle == 1
                      ? "Thesis"
                      : item.file_typeOfArticle == 2
                      ? "Capstone"
                      : item.file_typeOfArticle == 3
                      ? "Dissertation"
                      : item.file_typeOfArticle == 4
                      ? "Research"
                      : item.file_typeOfArticle == 5
                      ? "Journal"
                      : item.file_typeOfArticle == 6
                      ? "Others"
                      : ""
                  }}
                  -
                  <span>{{ moment(item.file_createdAt).fromNow() }}</span>
                </p>
              </div>
            </div>

            <!-- <div class=" hoverable py-3 px-4 bg-gray-100 rounded-md mb-3 w-52 max-w-lg xl:w-48 md:w-48" @click="changeRouter('dashboard')"> <h1 class="ellipsis text-gray-800 text-sm font-semibold w-32"> Predicting stocks return Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus fugit ratione quos quod ex provident fugiat asp </h1> <p class="text-xs text-gray-500"> Research Paper - <span>2 days ago</span> </p> </div> -->
            <!-- <div class=" hoverable py-3 px-4 bg-gray-100 rounded-md mb-3 w-52 max-w-lg xl:w-48 md:w-48" @click="changeRouter('dashboard')"> <h1 class="ellipsis text-gray-800 text-sm font-semibold w-32"> Predicting stocks return Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus fugit ratione quos quod ex provident fugiat asp </h1> <p class="text-xs text-gray-500"> Journal - <span>1 week ago</span> </p> </div> <div class=" hoverable py-3 px-4 bg-gray-100 rounded-md mb-3 w-52 max-w-lg xl:w-48 md:w-48" @click="changeRouter('dashboard')"> <h1 class="ellipsis text-gray-800 text-sm font-semibold w-32"> Predicting stocks return Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus fugit ratione quos quod ex provident fugiat asp </h1> <p class="text-xs text-gray-500"> Research Paper - <span>1 month ago</span> </p> </div> -->
          </div>

          <div class="w-3/4 ml-2 mt-5 mb-6 max-w-lg xl:ml-5">
            <h1
              class="text-105005 dark:text-lighterBg font-semibold text-left mb-4"
            >
              List of Journals
            </h1>
            <!-- Thesis, Capstone, Dissertation, Research, Journal, Others -->
            <div
              class="flex bg-gray-200 dark:bg-darkBG border dark:border-gray-400 ml-2 xl:ml-0 mb-4 cursor-pointer rounded-md w-52 xl:w-48 md:w-48"
              @click="changeRouter('dashboard')"
              v-for="data in countData.getCountTypeOfArticle"
              :key="data"
            >
              <div
                id="oneDiv"
                class="bg-yellow-500 dark:bg-yellow-600 dark:rounded-none py-2 px-2 w-11 text-center rounded-l-md font-semibold"
              >
                {{ data.cnt }}
              </div>
              <div
                class="py-2 px-2 text-xs m-auto font-semibold text-gray-900 dark:text-gray-100"
              >
                {{
                  data.file_typeOfArticle == 1
                    ? "Thesis"
                    : data.file_typeOfArticle == 2
                    ? "Capstone"
                    : data.file_typeOfArticle == 3
                    ? "Dissertation"
                    : data.file_typeOfArticle == 4
                    ? "Research"
                    : data.file_typeOfArticle == 5
                    ? "Journal"
                    : "Others"
                }}
              </div>
            </div>

            <div
              @click="changeRouter('dashboard')"
              class="flex bg-gray-200 dark:bg-darkBG border dark:border-gray-400 ml-2 xl:ml-0 mb-4 rounded-md w-52 xl:w-48 md:w-48"
              v-for="data in countData.getCountPublished"
              :key="data"
            >
              <div
                id="twoDiv"
                class="bg-yellow-500 dark:bg-yellow-600 py-2 px-2 w-11 text-center rounded-l-md font-semibold"
              >
                {{ data.cnt }}
              </div>

              <div
                class="py-2 px-2 text-xs m-auto font-semibold text-gray-900 dark:text-gray-100"
              >
                {{ data.file_isPublished == 0 ? "Unpublished" : "Published" }}
              </div>
            </div>

            <!-- <div class="flex bg-gray-200 ml-2 xl:ml-0 mb-4 rounded w-52 xl:w-48 md:w-48">
              <div id="threeDiv" class=" bg-yellow-600 py-2 px-2 w-11 text-center rounded-md font-semibold ">
                80
              </div>
              <div class="py-2 px-5 text-xs m-auto font-semibold text-gray-900">
                Dissertation
              </div>
            </div>

            <div class="flex bg-gray-200 ml-2 xl:ml-0 mb-4 w-52 rounded xl:w-48 md:w-48">
              <div id="fourDiv" class=" bg-yellow-600 py-2 px-2 w-11 text-center rounded-md font-semibold ">
                40
              </div>
              <div class="py-2 px-5 text-xs m-auto font-semibold text-gray-900">
                Research
              </div>
            </div>
            <div class="flex bg-gray-200 ml-2 xl:ml-0 mb-4 w-52 rounded xl:w-48 md:w-48">
              <div id="fiveDiv" class=" bg-yellow-600 py-2 px-2 w-11 text-center rounded-md font-semibold ">
                20
              </div>
              <div class="py-2 px-2 text-xs m-auto font-semibold text-gray-900">
                Journal
              </div>
            </div>

            <div class="flex bg-gray-200 ml-2 xl:ml-0 mb-4 w-52 rounded xl:w-48 md:w-48">
              <div id="sixDiv" class=" bg-yellow-600 py-2 px-2 w-11 text-center rounded-md font-semibold ">
                20
              </div>
              <div class="py-2 px-2 text-xs m-auto font-semibold text-gray-900">
                Other type of article
              </div>
            </div>

            <div class="flex bg-gray-200 ml-2 xl:ml-0 mb-4 w-52 rounded xl:w-48 md:w-48">
              <div id="sevenDiv" class=" bg-yellow-600 py-2 px-2 w-11 text-center rounded-md font-semibold ">
                20
              </div>
              <div class="py-2 px-2 text-xs m-auto font-semibold text-gray-900">
                Unpublished
              </div>
            </div>

            <div class="flex bg-gray-200 ml-2 xl:ml-0 mb-4 w-52 rounded xl:w-48 md:w-48">
              <div id="eightDiv" class=" bg-yellow-600 py-2 px-2 w-11 text-center rounded-md font-semibold ">
                20
              </div>
              <div class="py-2 px-2 text-xs m-auto font-semibold text-gray-900">
                Published
              </div>
            </div> -->
          </div>

          <button
            class="text-gray-400 text-right text-sm hover:underline xl:mb-10"
            @click="see_ = true"
            v-show="!see_"
          >
            See more
          </button>

          <div v-show="see_">
            <div class="w-3/4 mt-5 mb-4 xl:w-full xl:ml-5">
              <h1
                class="text-105005 dark:text-lighterBg font-semibold text-left mb-4"
              >
                Activity Logs
                <span class="text-xs text-gray-500">({{ this.logsLen }})</span>
              </h1>
              <div class="h-80 overflow-y-scroll">
                <div
                  class="hoverable py-3 px-4 bg-gray-100 rounded-md mb-3 w-52 max-w-lg xl:w-48 md:w-48"
                  v-for="item in logsData"
                  :key="item"
                >
                  <h1 class="text-gray-600 text-sm">
                    <span class="font-semibold w-32 uppercase">
                      {{ item.action }}
                    </span>
                    (<span style="font-size: 11px" class="inline">{{
                      moment(item.createdAt).fromNow()
                    }}</span
                    >)
                    <!-- <span class="text-xs"></span> -->
                  </h1>
                  <p class="text-xs text-gray-500">
                    {{ item.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button
            class="text-gray-400 text-right text-sm hover:underline mt-5 mb-5 xl:mr-2"
            @click="see_ = false"
            v-show="see_"
          >
            See less
          </button>
        </div>
      </div>
    </div>
    <NotificationModal
      v-on:closeModal="closeNotifModal"
      :unreadNotif="unreadNotifData"
      v-if="notifModal"
    />
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import timeAgo from "javascript-time-ago";
import moment from "moment";
import momentTZ from "moment-timezone";
import axios from "axios";
import Icons from "../components/Icons.vue";
import NotificationModal from "../modals/NotificationModal.vue";
export default {
  name: "UsersPage",
  components: {
    Icons,
    NotificationModal,
  },
  data() {
    return {
      sideBarOpen: false,
      avatarDropdown: false,
      notifDropdown: false,
      sidebar_content: false,
      mainMenuDivClicked: true,
      myWorksDivClicked: false,
      approvalDivClicked: false,
      categoryDivClicked: false,
      eventsDivClicked: false,
      accountsDivClicked: false,
      totalCount: 0,
      timeAgo: null,
      notifModal: false,
      see_: false,
      username: null,
      err: false,
      success: false,
      alert_msg: null,
      isGmail: false,
      notifData: [],
      unreadNotifData: [],
      notifCount: 0,
      imgUrls: [],
      contents: [],
      contentsLen: null,
      filePath: null,
      timeToday: null,
      timeFromDB: [],
      logsData: [],
      logsLen: null,
      interval2: null,
      nameOfDiv: null,
      interval: null,
      countData: [],
      notifCountDef: 0,
      windowTop: 0,
    };
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
    window.removeEventListener("scroll", this.onScroll);
    clearInterval(this.interval2);
  },
  created() {
    // setTimeout(() => {}, 1000);
    this.interval2 = setInterval(() => {
      // set to refresh after 2 mins
      // 2 mins = 120000 in milliseconds
      this.refreshNotification();
      if (this.notifCount > 0) {
        this.notifModal = true;
      } else if (this.notifCount == 0) {
        this.notifModal = false;
      }
    }, 180000);

    this.interval = setInterval(() => {
      // set to refresh after 2 mins
      // 2 mins = 120000 in milliseconds
      this.loadNotifications();
      this.loadUnOpenNotifCount();
    }, 180000);
    // this.notifCountDef = this.notifCount;

    this.moment = moment;
    if (this.$store.getters.isExpired) {
      this.$router.push("/logout");
    }

    setTimeout(() => {
      this.toggleSideBarClose();
      this.getImage();
      this.getMyJournals();
      // set to refesh every 1 min
    }, 180000);
  },

  mounted() {
  //console.log(this.$store.getters.getToken);
    this.init();
    this.getImage();
    this.loadNotifications();
    this.loadUnOpenNotifCount();
    this.getCount();
    this.getMyJournals();
    this.refreshNotification();
    const mainDiv = document.getElementById("main_div");
    mainDiv.addEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll(e) {
      // let y = mainDiv.scrollTop;
      // console.log(e.target.scrollTop.toFixed());
      this.windowTop = e.target.scrollTop.toFixed();
    },
    toTop() {
      const mainDiv = document.getElementById("main_div");
      mainDiv.scroll({
        behavior: "smooth",
        top: 0,
      });
    },
    closeNotifModal() {
      this.refreshNotification();
      // window.location.reload();
      this.notifModal = false;
    },

    getCount() {
      axios
        .get(process.env.VUE_APP_API_SERVER + "/fileupload/getCount/", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            this.countData = response.data;
          },
          (error) => {
            console.log(error);
          }
        );
    },

    async getMyJournals() {
      this.isLoading = true;
      axios
        .get(process.env.VUE_APP_API_SERVER + "/fileupload/my", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            this.contents = response.data;
            this.contentsLen = response.data.length;
            this.isLoading = false;
          },
          (error) => {
            console.log(error);
          }
        );
    },

    changeRouter(n) {
      if (n == "mywork") {
        this.$router.push("/userspage/mywork");
      } else if (n == "dashboard") {
        this.$router.push("/userspage/dashboard");
      }
    },

    getImage() {
      axios
        .get(
          process.env.VUE_APP_API_SERVER + "/users-controller/stream-file/",
          {
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then((response) => {
          const blob = new Blob([response.data]);
          var objectURL = URL.createObjectURL(blob);
          this.filePath = objectURL;
        });
    },

    async getImage2(filename) {
    console.log(this.$store.getters.getToken);
      var img;
      return await axios
        .get(
          process.env.VUE_APP_API_SERVER +
            "/users-controller/stream-file2/" +
            filename,
          {
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then((response) => {
          const blob = new Blob([response.data]);
          var objectURL = URL.createObjectURL(blob);
          this.filePath = objectURL;
        });
    },

    init() {
      const fname = this.$store.getters.getUserFirstName;
      const lname = this.$store.getters.getUserLastName;
      if (this.$store.getters.getUserFirstName == null) {
        // this.$router.push("/");
      } else {
        this.username = fname.toUpperCase() + ". " + lname[0].toUpperCase();
      }
      this.checkEmail();
    },

    markAllAsRead() {
      if (
        this.$store.getters.getUserRole == 1 ||
        this.$store.getters.getUserRole == 2 ||
        this.$store.getters.getUserRole == 3
      ) {
        axios
          .get(
            process.env.VUE_APP_API_SERVER +
              "/notification/getByInstitute/markAllAsRead",
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
              },
            }
          )
          .then(
            (response) => {
              this.loadNotifications();
              this.loadUnOpenNotifCount();
              // this.timeToday = new Date();
              // this.notifData = response.data;
              // console.log(response.data);
              // this.totalCount = response.data.length;
            },
            (error) => {
              console.log(error);
            }
          );
      } else if (
        this.$store.getters.getUserRole == 4 ||
        this.$store.getters.getUserRole == 5 ||
        this.$store.getters.getUserRole == 6 ||
        this.$store.getters.getUserRole == 7
      ) {
        axios
          .get(
            process.env.VUE_APP_API_SERVER +
              "/notification/getByUser/markAllAsRead",
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
              },
            }
          )
          .then(
            (response) => {
              this.loadNotifications();
              this.loadUnOpenNotifCount();
              // this.timeToday = new Date();
              // this.notifData = response.data;
              // this.totalCount = response.data.length;
            },
            (error) => {
              console.log(error);
            }
          );
      }
    },

    checkEmail() {
      if (/@gmail.com\s*$/.test(this.$store.getters.getUserEmail)) {
        this.isGmail = false;
      } else {
        this.isGmail = false;
      }
    },

    loadNotifications() {
      // console.log(this.$store.getters.getUserdetailID)
      if (
        this.$store.getters.getUserRole == 1 ||
        this.$store.getters.getUserRole == 2 ||
        this.$store.getters.getUserRole == 3
      ) {
        axios
          .get(
            process.env.VUE_APP_API_SERVER + "/notification/getByInstitute",
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
              },
            }
          )
          .then(
            (response) => {
              this.timeToday = new Date();
              this.notifData = response.data;
              // console.log(response.data);
              this.unreadNotifData = response.data;
              this.totalCount = response.data.length;
            },
            (error) => {
              console.log(error);
            }
          );
      } else if (
        this.$store.getters.getUserRole == 5 ||
        this.$store.getters.getUserRole == 4 ||
        this.$store.getters.getUserRole == 6 ||
        this.$store.getters.getUserRole == 7
      ) {
        axios
          .get(process.env.VUE_APP_API_SERVER + "/notification/getByUser", {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          })
          .then(
            (response) => {
              this.timeToday = new Date();
              this.notifData = response.data;
              this.unreadNotifData = response.data;
              // console.log(response.data);
              this.totalCount = response.data.length;
            },
            (error) => {
              console.log(error);
            }
          );
      }
    },

    loadUnOpenNotifCount() {
      if (
        this.$store.getters.getUserRole == 1 ||
        this.$store.getters.getUserRole == 2 ||
        this.$store.getters.getUserRole == 3
      ) {
        axios
          .get(
            process.env.VUE_APP_API_SERVER +
              "/notification/institute/unOpenCount",
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
              },
            }
          )
          .then(
            (response) => {
              // console.log(response.data);
              this.notifCount = response.data;
            },
            (error) => {
              console.log(error);
            }
          );
      } else if (
        this.$store.getters.getUserRole == 5 ||
        this.$store.getters.getUserRole == 4 ||
        this.$store.getters.getUserRole == 6 ||
        this.$store.getters.getUserRole == 7
      ) {
        axios
          .get(
            process.env.VUE_APP_API_SERVER + "/notification/user/unOpenCount",
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
              },
            }
          )
          .then(
            (response) => {
              this.notifCount = response.data;
              this.notifCountDef = this.notifCount;
              // console.log(response.data);
            },
            (error) => {
              console.log(error);
            }
          );
      }
    },

    getLogs(userdetailID) {
      axios
        .get(
          process.env.VUE_APP_API_SERVER +
            "/logs-data/getAllLogsByUserdetailID/" +
            userdetailID,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            this.logsData = response.data;
            this.logsLen = response.data.length;
            // console.log(response.data)
          },
          (error) => {
            console.log(error);
          }
        );
    },

    refreshNotification() {
      this.loadNotifications();
      this.loadUnOpenNotifCount();
      // this.notifDropdown = false;
    },

    redirectNotif(route, notif) {
      axios
        .patch(
          process.env.VUE_APP_API_SERVER +
            "/notification/openned/" +
            notif.notify_id,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            if (response.data.status == 200) {
              this.notifDropdown = !this.notifDropdown;
              this.loadUnOpenNotifCount();
              this.toggleSideBarClose();
              this.loadNotifications();
              // NEW USER NOTIF TO DEAN
              if (route == "acc_approval") {
                setTimeout(() => {
                  this.$router.push({
                    name: "UserVerify",
                    params: {
                      userid: notif.notify_userID,
                    },
                  });
                }, 300);
                setTimeout(() => {
                  this.$refs.accountVerify.$el.classList.add(
                    "router-link-exact-active"
                  );
                }, 300);
              }
              // NEW USER NOTIF TO LIBRARIAN
              else if (route == "acc_approval2") {
                setTimeout(() => {
                  this.$router.push({
                    name: "UserVerify",
                    params: {
                      userid: notif.notify_userID,
                    },
                  });
                }, 300);
                setTimeout(() => {
                  this.$refs.accountVerify.$el.classList.add(
                    "router-link-exact-active"
                  );
                }, 300);
              }
              // JOURNAL APPROVAL NOTIF TO DEAN
              else if (route == "journal_approval") {
                // redirect notif to userRoleId = 4 (GUEST) userRoleId = 5 (Alumni)
                if (
                  this.$store.getters.getUserRole == 4 ||
                  this.$store.getters.getUserRole == 5 ||
                  this.$store.getters.getUserRole == 6 ||
                  this.$store.getters.getUserRole == 7
                ) {
                  setTimeout(() => {
                    this.$router.push({
                      name: "UserMyWork",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$refs.myWorks.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                } else {
                  setTimeout(() => {
                    this.$router.push({
                      name: "UserJournalApproval",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$refs.approval.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                }
              }
              // JOURNAL APPROVAL NOTIF TO LIBRARIAN
              else if (route == "journal_approval2") {
                // redirect notif to userRoleId = 4 (GUEST) userRoleId = 5 (Alumni)
                if (
                  this.$store.getters.getUserRole == 4 ||
                  this.$store.getters.getUserRole == 5 ||
                  this.$store.getters.getUserRole == 6 ||
                  this.$store.getters.getUserRole == 7
                ) {
                  setTimeout(() => {
                    this.$router.push({
                      name: "UserMyWork",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$refs.myWorks.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                } else {
                  setTimeout(() => {
                    this.$router.push({
                      name: "UserJournalApproval",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$refs.approval.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                }
              }
              // JOURNAL APPROVAL NOTIF TO LIBRARIAN
              else if (route == "journal_approved_user") {
                // redirect notif to userRoleId = 4 (GUEST) userRoleId = 5 (Alumni)
                if (
                  this.$store.getters.getUserRole == 4 ||
                  this.$store.getters.getUserRole == 5 ||
                  this.$store.getters.getUserRole == 6 ||
                  this.$store.getters.getUserRole == 7
                ) {
                  setTimeout(() => {
                    this.$router.push({
                      name: "UserMyWork",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$refs.myWorks.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                } else {
                  setTimeout(() => {
                    this.$router.push({
                      name: "UserJournalApproval",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$refs.approval.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                }
              }
              // superadmin
              else if (route == "journal_approved") {
                // redirect notif to userRoleId = 4 (GUEST) userRoleId = 5 (Alumni)
                if (
                  this.$store.getters.getUserRole == 4 ||
                  this.$store.getters.getUserRole == 5 ||
                  this.$store.getters.getUserRole == 6 ||
                  this.$store.getters.getUserRole == 7
                ) {
                  setTimeout(() => {
                    this.$router.push({
                      name: "UserMyWork",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$refs.myWorks.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                } else {
                  setTimeout(() => {
                    this.$router.push({
                      name: "UserJournalApproval",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$refs.approval.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                }
              }
              // ANNOUNCEMENT APPROVAL NOTIF TO LIBRARIAN
              if (route == "ann_approval") {
                setTimeout(() => {
                  this.$router.push({
                    name: "UserAnnouncement",
                    params: {
                      fileID: notif.notify_fileID,
                    },
                  });
                }, 300);
                setTimeout(() => {
                  this.$refs.userAnnouncement.$el.classList.add(
                    "router-link-exact-active"
                  );
                }, 300);
              }
              // ANNOUNCEMENT APPROVED NOTIF TO DEAN
              if (route == "ann_approved") {
                setTimeout(() => {
                  this.$router.push({
                    name: "UserAnnouncement",
                    params: {
                      fileID: notif.notify_fileID,
                    },
                  });
                }, 300);
                setTimeout(() => {
                  this.$refs.userAnnouncement.$el.classList.add(
                    "router-link-exact-active"
                  );
                }, 300);
              }
              // JOURNAL ENDORSED NOTIF TO LIBRARIAN
              else if (route == "journal_endorsed") {
                // redirect notif to userRoleId = 4 (GUEST) userRoleId = 5 (Alumni)
                if (
                  this.$store.getters.getUserRole == 4 ||
                  this.$store.getters.getUserRole == 5 ||
                  this.$store.getters.getUserRole == 6 ||
                  this.$store.getters.getUserRole == 7
                ) {
                  setTimeout(() => {
                    this.$router.push({
                      name: "UserMyWork",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$refs.myWorks.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                } else {
                  setTimeout(() => {
                    this.$router.push({
                      name: "UserJournalApproval",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$refs.approval.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                }
              }
              // JOURNAL ENDORSED NOTIF TO USER
              else if (route == "journal_endorsed_user") {
                // redirect notif to userRoleId = 4 (GUEST) userRoleId = 5 (Alumni)
                if (
                  this.$store.getters.getUserRole == 4 ||
                  this.$store.getters.getUserRole == 5 ||
                  this.$store.getters.getUserRole == 6 ||
                  this.$store.getters.getUserRole == 7
                ) {
                  setTimeout(() => {
                    this.$router.push({
                      name: "UserMyWork",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$refs.myWorks.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                } else {
                  setTimeout(() => {
                    this.$router.push({
                      name: "UserJournalApproval",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$refs.approval.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                }
              }
              // JOURNAL PENDED NOTIF TO USER
              else if (route == "journal_pended_user") {
                // redirect notif to userRoleId = 4 (GUEST) userRoleId = 5 (Alumni)
                if (
                  this.$store.getters.getUserRole == 4 ||
                  this.$store.getters.getUserRole == 5 ||
                  this.$store.getters.getUserRole == 6 ||
                  this.$store.getters.getUserRole == 7
                ) {
                  setTimeout(() => {
                    this.$router.push({
                      name: "UserMyWork",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$refs.myWorks.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                } else {
                  setTimeout(() => {
                    this.$router.push({
                      name: "UserJournalApproval",
                      params: {
                        fileid: notif.notify_fileID,
                      },
                    });
                  }, 300);
                  setTimeout(() => {
                    this.$refs.approval.$el.classList.add(
                      "router-link-exact-active"
                    );
                  }, 300);
                }
              }
              // JOURNAL REQUESTS
              else if (route == "journal_permission_request") {
                // redirect notif to userRoleId = 4 (GUEST) userRoleId = 5 (Alumni)
                setTimeout(() => {
                  this.$router.push({
                    name: "JournalRequests",
                    params: {
                      fileid: notif.notify_fileID,
                    },
                  });
                }, 300);
                setTimeout(() => {
                  this.$refs.requests.$el.classList.add(
                    "router-link-exact-active"
                  );
                }, 300);
              }
              // JOURNAL REQUESTS
              else if (route == "journal_permission_granted") {
                // redirect notif to userRoleId = 4 (GUEST) userRoleId = 5 (Alumni)
                setTimeout(() => {
                  this.$router.push({
                    name: "UserJournals",
                    params: {
                      fileid: notif.notify_fileID,
                    },
                  });
                }, 300);
                setTimeout(() => {
                  this.$refs.journal.$el.classList.add(
                    "router-link-exact-active"
                  );
                }, 300);
              } else if (route == "journal_editrequest") {
                setTimeout(() => {
                  this.$router.push({
                    name: "UserJournalApproval",
                    params: {
                      fileid: notif.notify_fileID,
                    },
                  });
                }, 300);
                setTimeout(() => {
                  this.$refs.approval.$el.classList.add(
                    "router-link-exact-active"
                  );
                }, 300);
              } else if (route == "journal_editrequest_granted") {
                setTimeout(() => {
                  this.$router.push({
                    name: "UserMyWork",
                    params: {
                      fileid: notif.notify_fileID,
                    },
                  });
                }, 300);
                setTimeout(() => {
                  this.$refs.myWorks.$el.classList.add(
                    "router-link-exact-active"
                  );
                }, 300);
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },

    formatNumber(num) {
      if (num <= 9) {
        return "0" + num;
      } else {
        return num;
      }
    },

    stringFormatDate(date) {
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(date).toLocaleDateString("en", options);
    },

    formatDate(date) {
      const d = new Date(date);
      this.timeAgo = d;

      const mn = d.getMonth() + 1;
      var day = d.getDate();
      const yr = d.getFullYear();
      const hh = d.getHours();
      const mm = d.getMinutes();
      const ss = d.getSeconds();

      return (
        this.formatNumber(mn) +
        "-" +
        this.formatNumber(day) +
        "-" +
        yr +
        " " +
        this.formatNumber(hh) +
        ":" +
        this.formatNumber(mm) +
        ":" +
        this.formatNumber(ss)
      );
    },

    formatDate2(date) {
      const timeAgo = new TimeAgo("en-US");

      timeAgo.format(new Date());
      // "just now"

      timeAgo.format(date - 60 * 1000);
      // "1 minute ago"

      timeAgo.format(date - 2 * 60 * 60 * 1000);
      // "2 hours ago"

      timeAgo.format(date - 24 * 60 * 60 * 1000);
    },

    toggleNotif() {
      this.notifDropdown = false;
    },

    toggleSideBarOpen() {
      // method to toggle the right side bar navigation open
      // DOM

      this.sideBarOpen = true;
      this.sidebar_content = true;
      this.getLogs(this.$store.getters.getUserdetailID);
    },

    toggleDropdown(div) {
      // console.log(div)
      if (div == "avatar") {
        this.avatarDropdown = !this.avatarDropdown;
        this.notifDropdown = false;
        // console.log(this.avatarDropdown);
      } else if (div == "notif") {
        this.avatarDropdown = false;
        this.notifDropdown = !this.notifDropdown;
      }
    },

    toggleSideBarClose() {
      // method to toggle the right side bar navigation close
      // DOM
      this.avatarDropdown = false;
      this.notifDropdown = false;

      this.sidebar_content = false;
      this.sideBarOpen = false;
    },

    // close mobile nav
    hideMobileNav() {
      const mob_parent = document.getElementById("mobile_parent_div");

      setTimeout(() => {
        if (mob_parent.classList.contains("mobile_nav_open")) {
          mob_parent.classList.remove("mobile_nav_open");
          mob_parent.classList.add("mobile_nav_close");
        }
      }, 500);

      setTimeout(() => {
        // mob_nav.classList.add("hidden");
        mob_parent.classList.remove("flex");
        mob_parent.classList.add("hidden");
      }, 550);
    },

    // open mobile nav
    openMobileNav() {
      const mob_nav = document.getElementById("mobile_nav");
      const mob_parent = document.getElementById("mobile_parent_div");
      // const burgerDiv = document.getElementById("burger_div");

      mob_nav.classList.remove("hidden");
      mob_parent.classList.add("flex");
      mob_parent.classList.remove("mobile_nav_close");

      setTimeout(() => {
        if (mob_parent.classList.contains("hidden")) {
          // burgerDiv.classList.add("hidden");
          mob_parent.classList.remove("hidden");
          mob_parent.classList.add("mobile_nav_open");
        }
      }, 100);
    },

    // DISPLAYING SUCCESS ALERTS
    SuccessMsg(data) {
      // this.success = true;
      const toast = useToast();

      toast.success(data.msg, {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });

      // this.alert_msg = data.msg;

      this.init();
    },

    // CLOSING THE SUCCESS / ERROR ALERTS
    closeAlert() {
      this.success = false;
      this.err = false;
    },
  },
};
</script>

<style scoped>
.bgColor {
  background-color: #105005;
}

.router-link-exact-active {
  background-color: #43703f;
  color: white;
}

.navHover {
  background-color: #43703f;
  color: white;
}

.avatar {
  height: 10rem;
  width: 10rem;
}

.hoverable:hover {
  cursor: pointer;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.drawer_animate {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

.separator {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #acacac;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.separator,
.separator-span {
  background: white;
  padding: 0 10px;
  font-size: 12px;
  margin-top: 20px;
}

/* .b{
  	border: 1px solid black;
  } */
</style>
