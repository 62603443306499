<template>
  <!-- <nav :class="isTransparent ? 'bg-transparent' : 'nav_bg'" class="absolute w-full border-gray-200 pt-10 pb-4 px-2 sm:px-4  dark:bg-gray-800"> -->
  <nav
    class="fixed h-14 z-10 w-full border-gray-200 pb-4 dark:bg-gray-800"
    :class="{ change_color: scrollPosition > 50 }"
  >
    <div class="flex justify-evenly w-screen h-14">
      <a
        @click="logoRedirect"
        class="hidden md:flex lg:flex items-center justify-center w-1/3 cursor-pointer"
      >
        <img
          src="../assets/Redams-Logo-v1-white.png"
          v-if="scrollPosition <= 50"
          alt="RedMas logo"
          class="w-40 mr-12"
        />
        <img
          src="../assets/Redams-Logo-v1.png"
          v-if="scrollPosition > 50"
          alt="RedMas logo"
          class="w-40 mr-12"
        />
      </a>

      <!-- <button @click="mobile_nav" v-if="!mobile_navs" type="button" class=" inline-flex items-center p-2 ml-1 text-sm text-white rounded-lg md:hidden hover:bg-white hover:text-black focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 " aria-controls="mobile-menu-2" aria-expanded="false" > <span class="sr-only">Open main menu</span> <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" > <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" ></path> </svg> <svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" > <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" ></path> </svg> </button> -->

      <ul
        class="flex justify-center items-center text-right flex-row w-full md:w-1/3 lg:w-1/3 xl:w-1/3 space-x-8 text-sm font-medium"
      >
        <li>
          <router-link
            to="/landing/home"
            @click="isTransparent = true"
            :class="{ change_font_color: scrollPosition > 50 }"
            class="block py-2 font-semibold pr-4 pl-3 text-xs uppercase text-white rounded md:bg-transparent md:text-white md:p-0 dark:text-white"
            >Home</router-link
          >
        </li>
        <li>
          <router-link
            to="/landing/journals"
            @click="isTransparent = false"
            :class="{ change_font_color: scrollPosition > 50 }"
            class="block text-xs hover:text-yellow-500 py-2 pr-4 pl-3 uppercase text-white border-gray-100 md:hover:bg-transparent md:border-0 font-semibold md:hover:text-yellow-400 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
            >Journals</router-link
          >
        </li>

        <li>
          <router-link
            to="/landing/aboutpage"
            @click="isTransparent = false"
            :class="{ change_font_color: scrollPosition > 50 }"
            class="block text-xs hover:text-yellow-500 py-2 pr-4 pl-3 uppercase text-white border-gray-100 md:hover:bg-transparent md:border-0 font-semibold md:hover:text-yellow-400 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
            >About</router-link
          >
        </li>
        <li class="hidden md:block lg:block xl:block 2xl:block">
          <router-link
            to="/landing/announcements"
            @click="isTransparent = false"
            :class="{ change_font_color: scrollPosition > 50 }"
            class="block text-xs hover:text-yellow-500 py-2 pr-4 pl-3 uppercase text-white border-gray-100 md:hover:bg-transparent md:border-0 font-semibold md:hover:text-yellow-400 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
            >Others</router-link
          >
        </li>

        <li class="md:hidden lg:hidden xl:hidden">
          <router-link
            to="/signin/login"
            @click="isTransparent = false"
            :class="{ change_font_color: scrollPosition > 50 }"
            class="block font-medium text-xs hover:text-yellow-500 py-2 pr-4 pl-3 uppercase text-white border-gray-100 md:hover:bg-transparent md:border-0 md:hover:underline md:hover:text-yellow-400 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
            >Login</router-link
          >
        </li>
      </ul>

      <ul class="hidden md:flex w-1/3 items-center justify-center">
        <li class="">
          <router-link
            to="/signin/register"
            class="text-xs text-FFB800 font-bold py-2 px-4 rounded uppercase hover:text-yellow-500"
            >Sign up</router-link
          >
        </li>

        <li>
          <router-link
            to="/signin/login"
            class="bg-FFB800 hover:text-white text-xs font-bold py-2 px-4 rounded uppercase"
            >log in</router-link
          >
        </li>
      </ul>
    </div>

    <div class="flex relative">
      <!-- DESKTOP VIEW NAV -->
      <!-- <div class="hidden md:flex justify-center w-screen">
        <ul id="mobile_nav" class=" flex items-center text-right bg-gray-400 md:bg-transparent py-2 md:h-10 md:ml-8 md:flex-row md:space-x-8 md:text-sm md:font-medium " >
          <li>
            <router-link to="/landing/home" @click="isTransparent = true" class=" block py-2 font-medium pr-4 pl-3 text-xs uppercase text-105005 rounded md:bg-transparent md:text-105005 md:p-0 dark:text-white " >Home</router-link
            >
          </li>
          <li>
            <router-link to="/landing/journals" @click="isTransparent = false" class=" block font-medium text-xs hover:text-yellow-500 py-2 pr-4 pl-3 uppercase text-105005 border-gray-100 md:hover:bg-transparent md:border-0 md:hover:underline md:hover:text-yellow-400 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 " >Journals</router-link >
          </li>

          <li>
            <a href="#" class=" block text-xs hover:text-yellow-500 font-medium py-2 pr-4 pl-3 uppercase text-105005 border-gray-100 md:hover:bg-transparent md:border-0 md:hover:underline md:hover:text-yellow-400 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 " >About</a
            >
          </li>
        </ul>
      </div> -->
      <!-- MOBILE NAV -->
      <div
        class="hidden m-auto w-full md:flex md:w-auto md:order-1 mobile-menu1 animated"
        id="mobile-menu-2"
      >
        <ul
          id="mobile_nav"
          class="flex md:hidden text-right m-auto flex-col -mt-10 bg-gray-400 md:bg-transparent py-2 h-screen md:h-10 md:ml-8 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium"
        >
          <li>
            <router-link
              to="/landing/home"
              @click="
                isTransparent = true;
                mobile_nav();
              "
              class="block py-2 pr-4 pl-3 text-xs hover:text-yellow-500 uppercase text-white rounded md:bg-transparent md:text-white md:p-0 dark:text-white"
              >Home</router-link
            >
            <!-- <a href="#"  class="block py-4 pr-4 pl-3 uppercase text-white bg-blue-700 rounded md:bg-transparent md:text-white md:p-0 dark:text-white active" aria-current="page">Home</a> -->
          </li>
          <li>
            <router-link
              to="/landing/journals"
              @click="
                isTransparent = false;
                mobile_nav();
              "
              class="block text-xs hover:text-yellow-500 py-2 pr-4 pl-3 uppercase text-white border-gray-100 md:hover:bg-transparent md:border-0 md:hover:underline md:hover:text-yellow-400 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >Journals</router-link
            >
            <!-- <a href="#" class="block py-2 pr-4 pl-3 uppercase text-white border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:underline md:hover:text-yellow-400 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Journals</a> -->
          </li>

          <li>
            <router-link
              to="/landing/aboutpage"
              @click="
                isTransparent = false;
                mobile_nav();
              "
              class="block text-xs hover:text-yellow-500 py-2 pr-4 pl-3 uppercase text-white border-gray-100 md:hover:bg-transparent md:border-0 md:hover:underline md:hover:text-yellow-400 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >About</router-link
            >
          </li>

          <li class="md:hidden">
            <router-link
              to="/signin/register"
              class="block hover:text-yellow-500 py-2 pr-4 text-xs pl-3 uppercase text-white border-gray-100 md:hover:bg-transparent md:border-0 md:hover:underline md:hover:text-yellow-400 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >Sign up</router-link
            >
            <!-- <a href="#" class="block hover:text-yellow-500 py-2 pr-4 pl-3 uppercase text-white  border-gray-100  md:hover:bg-transparent md:border-0 md:hover:underline md:hover:text-yellow-400 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Sign in</a> -->
          </li>

          <li class="md:hidden">
            <router-link
              to="/signin/login"
              class="bg-FFB800 hover:text-white text-xs font-bold py-2 px-4 rounded uppercase"
              >log in</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <div>
      <button
        title="Back to top"
        class="absolute shadow-lg ring-2 ring-offset-1 ring-yellow-500 shadow-indigo-500/40 -right-4 mb-4 bg-yellow-500 text-white p-3 rounded-full mr-20 mb-7"
        style="top: 1145%"
        :class="
          windowTop > 300
            ? 'ease-in-out duration-300'
            : 'fade ease-in-out block duration-300'
        "
        @click="toTop"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          class="w-5 h-5"
        >
          <path
            fill-rule="evenodd"
            d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>
  </nav>
</template>

<script>
export default {
  name: "LandingNav",
  data() {
    return {
      isTransparent: true,
      mobile_navs: false,
      scrollPosition: null,
      windowTop: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("scroll", this.updateScroll);
  },
  beforeDestroy() {
    // prevent memory leak
    window.removeEventListener("scroll", this.onScroll);
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    onScroll() {
      this.windowTop = window.scrollY.toFixed();
    },
    toTop() {
      window.scroll({
        behavior: "smooth",
        top: 0,
      });
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    logoRedirect() {
      this.$router.push("/");
    },
    mobile_nav() {
      this.mobile_navs = !this.mobile_navs;
      const burgerNav = document.querySelector("#mobile-menu-2");

      if (burgerNav.classList.contains("hidden")) {
        burgerNav.classList.remove("mobile_nav_close");
        burgerNav.classList.add("mobile_nav_open");
      } else {
        burgerNav.classList.remove("mobile_nav_open");
        burgerNav.classList.add("mobile_nav_close");
      }

      setTimeout(() => {
        burgerNav.classList.toggle("hidden");
      }, 300);
    },
  },
};
</script>

<style scoped>
/* @media only screen and (min-width: 600px) {
  nav{
    background-color: #105005;
    display:flex;
  }
} */

.change_color {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: white;
}

.change_font_color {
  color: #105005;
}

.bg-color-green {
  background-color: #105005;
  /* height: 5rem; */
  /* margin-top: -1.50rem;  */
}

/* .active{
    text-decoration: underline;
    color : goldenrod
    /* margin-top: 5px; */
/* }  */

.router-link-active,
.router-link-exact-active {
  /* text-decoration: underline; */
  color: #ffb800;
  font-weight: bold;
}

.nav_bg {
  background-color: #105005;

  /* background-color: #105005; */
}
</style>