<template>
  <div class="bg h-screen">
    <div class="flex flex-row w-full">
      <div class="w-full md:block lg:block xl:block px-32">
        <h1
          class="text-white text-center md:text-left tracking-widest font-bold text-2xl mb-0 mt-4"
          @click="toJournals()"
        >
          ReDMaS
        </h1>
        <div class="hidden md:block lg:block xl:block mt-10">
          <h1 class="text-white text-xl font-bold md:text-5xl mb-9">
            Research Database
            <span class="text-FFB800">Management System</span>
          </h1>
          <p class="text-white text-sm mb-2 mr-20 font-poppins">
            This database management system contains the thesis / dissertations
            / publications of the graduate students, and faculty of the
            <span class="font-semibold">Davao del Norte State College</span>
            which is accessible to the academic community.
          </p>
          <button
            @click="toJournals"
            class="text-sm bg-FFB800 font-normal w-1/2 text-center py-2 mt-4 rounded-full hover:text-white"
          >
            Browse Journals Now
          </button>
        </div>
      </div>

      <div class="w-full h-screen pt-10 pb-14 overflow-y-auto">
        <!-- <div class="py-20"> -->
        <router-view></router-view>
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Signin",
  created() {
    if (this.$store.getters.getIsValidated == 1) {
      this.$router.push("/userspage/dashboard");
    }
    // else {
    //   this.$router.push("/landing/home");
    // }
  },
  methods: {
    toJournals() {
      this.$router.push("/landing/home");
    },
  },
};
</script>

<style>
.bg {
  background-image: url("../assets/bg/Vector.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.h_1 {
  font-size: 58px;
}
</style>
