<template>
  <div class="w-auto h-auto pb-12">
    <div class="rounded-lg bg-white dark:bg-darkBG">
      <div
        class="filter_by flex flex-col-reverse md:flex-row justify-between w-auto md:w-auto overflow-hidden bg-white dark:bg-darkBG border dark:border-gray-600 lg:h-auto md:h-auto py-2 md:mx-3 m-auto rounded-md"
      >
        <div class="w-full md:w-1/2 md:grid lg:flex px-5 md:px-3 py-2">
          <input
            @keyup="handleSearching()"
            type="text"
            class="indent font-poppins md:py-2.5 py-2.5 md:w-3/4 w-full text-xs font-normal text-gray-700 bg-gray-100 dark:bg-darkBG bg-clip-padding border border-solid border-gray-300 rounded md:rounded-md lg:rounded-md transition ease-in-out m-0 focus:text-gray-700 focus:bg-white dark:focus:bg-darkBG focus:border-green-600 focus:outline-none"
            v-model="searchText"
            placeholder="Search for events"
          />
        </div>
        <div
          class="w-full md:w-1/2 py-2 px-3 md:px-0"
          v-if="$store.getters.getUserRole == 1"
        >
          <button
            @click="openModal('Add')"
            class="bgColor text-white font-semibold float-right mr-2 md:w-1/4 text-left py-2 md:py-1 md:px-3 px-7 text-xs border rounded shadow"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 inline"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
            Add new Events
          </button>
        </div>
      </div>
      <div class="md:flex flex-col">
        <div class="overflow-x-auto">
          <div class="inline-block min-w-full">
            <div class="overflow-hidden mx-4">
              <Table
                :idName="'eventsTable1'"
                v-if="$store.getters.getUserRole == 1"
                :labels="table_headers"
                :data="displayedEvents"
              >
                <template v-slot:eventDate="{ item }">
                  {{ timeToday(item.eventDate) }}
                </template>
                <template
                  v-if="$store.getters.getUserRole == 1"
                  v-slot:action="{ item }"
                >
                  <!-- <button @click="viewEvents2(item)" class=" bg-white hover:bg-green-500 hover:text-white text-green-500 font-semibold px-3 py-1 text-xs border border-green-500 rounded shadow "> View Tagged Journal </button>&nbsp; -->
                  <button
                    @click="viewEvents(item)"
                    title="Update"
                    class="bg-white hover:bg-yellow-500 hover:text-white text-yellow-500 font-semibold px-3 py-1 text-xs border border-yellow-500 rounded shadow"
                  >
                    View Event Details
                  </button>
                  <!-- <button v-if="$store.getters.getUserRole == 1" @click="confirmRemove(item)" class=" bg-white hover:bg-red-500 hover:text-white text-red-500 font-semibold px-3 py-1 text-xs border border-yellow-500 rounded shadow "> Remove </button> -->
                </template>
              </Table>
              <Table
                v-if="$store.getters.getUserRole != 1"
                :idName="'eventsTable'"
                :labels="table_headers2"
                :data="displayedEvents"
              >
                <template v-slot:eventDate="{ item }">
                  {{ timeToday(item.eventDate) }}
                </template>
                <template v-slot:eventDesc="{ item }">
                  <p class="captionText" :id="'captionText' + item.id">
                    {{ item.eventDesc }}
                  </p>

                  <span
                    :class="
                      String(item.eventDesc).length > 50 ? 'block' : 'hidden'
                    "
                    class="text-green-500 cursor-pointer dark:text-gray-600 text-xs"
                    @click="seeMoreFunc(item.id)"
                    :id="'seeMoreText' + item.id"
                  >
                    Read more...
                  </span>
                  <span
                    class="text-green-500 hidden cursor-pointer dark:text-gray-600 text-xs"
                    @click="seeLessFunc(item.id)"
                    :id="'seeLessText' + item.id"
                  >
                    Read less...
                  </span>
                </template>
                <template
                  v-if="$store.getters.getUserRole == 1"
                  v-slot:action="{ item }"
                >
                  <!-- <button @click="viewEvents2(item)" class=" bg-white hover:bg-green-500 hover:text-white text-green-500 font-semibold px-3 py-1 text-xs border border-green-500 rounded shadow "> View Tagged Journal </button>&nbsp; -->
                  <button
                    @click="viewEvents(item)"
                    title="Update"
                    class="bg-white hover:bg-yellow-500 hover:text-white text-yellow-500 font-semibold px-3 py-1 text-xs border border-yellow-500 rounded shadow"
                  >
                    View Event Details
                  </button>
                  <!-- <button v-if="$store.getters.getUserRole == 1" @click="confirmRemove(item)" class=" bg-white hover:bg-red-500 hover:text-white text-red-500 font-semibold px-3 py-1 text-xs border border-yellow-500 rounded shadow "> Remove </button> -->
                </template>
              </Table>
              <div
                class="grid font-poppins w-full sm:flex md:flex lg:flex flex-row justify-end md:w-full sm:w-full mx-auto items-center border-l border-b border-r border-gray-400 rounded-bl-lg rounded-br-lg pt-4 pb-4"
              >
                <div
                  class="w-auto sm:w-3/6 md:w-3/6 lg:w-3/6 xl:w-3/6 gap-x-4 mx-3 flex"
                >
                  <div
                    class="hidden text-xs text-gray-600 md:flex w-1/3 md:w-2/5 items-center"
                  >
                    <label for="" class="w-2/3">Rows per page</label>&nbsp;
                    <select
                      class="w-1/3 border-gray-600 border-b dark:bg-darkBG pb-1"
                      v-model="perPage"
                    >
                      <option
                        :value="item"
                        v-for="item in selectPerPage"
                        :key="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div class="hidden md:flex w-1/3 md:w-2/5">
                    <!-- <div class="flex" v-if="results != 0"> -->
                    <span
                      class="text-xs m-auto text-gray-600 dark:text-gray-400"
                    >
                      Showing
                      <span
                        class="font-semibold text-gray-900 dark:text-white"
                        >{{ fromPage }}</span
                      >
                      to
                      <span class="font-semibold text-gray-900 dark:text-white">
                        {{ toPage }}</span
                      >
                      of
                      <span
                        class="font-semibold text-gray-900 dark:text-white"
                        >{{ results }}</span
                      >
                      Entries
                    </span>
                  </div>
                  <nav class="w-1/3 md:w-1/5">
                    <ul class="flex md:justify-end">
                      <li class="page-item mx-1">
                        <button
                          type="button"
                          class="page-link bg-customGreen text-white rounded-md p-1 text-xs"
                          v-if="page > 1"
                          @click="page--"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                        <!--for the disabled button -->
                        <button
                          type="button"
                          class="page-link bg-customGreen rounded-md text-white p-1 text-xs"
                          v-if="page == 1"
                          disabled
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </li>
                      <li class="page-item mx-1">
                        <button
                          type="button"
                          @click="page++"
                          v-if="page < pages.length"
                          class="page-link bg-customGreen rounded-md text-white p-1 text-xs"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                        <!--for the disabled button -->
                        <button
                          type="button"
                          disabled
                          v-if="page >= pages.length"
                          class="page-link bg-customGreen rounded-md text-white p-1 text-xs"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AlertConfirmationModal
      v-on:confirmRemove="removeEvents"
      v-on:closeModal="closeModal"
      :journalEvent="eventsToRemove"
      v-if="isRemoveModal"
    />
  </div>
</template>

<script>
import axios from "axios";
import Table from "../components/Table.vue";
import moment from "moment";
import AlertConfirmationModal from "../modals/AlertConfirmationModal.vue";
import { useToast } from "vue-toastification";
export default {
  name: "EventsTable",
  props: ["isUpdate"],
  components: { Table, AlertConfirmationModal },

  data() {
    return {
      contents: [],
      eventsData: null,
      page: 1,
      isLoading: false,
      modalAction: "Add",
      perPage: 10,
      selectPerPage: [10, 15, 20, 30, 50],
      pages: [],
      fromPage: 1,
      toPage: null,
      results: null,
      searchText: null,
      isRemoveModal: false,
      isShowModal: false,
      itemToRemove: null,
      eventsToRemove: "",
      eventTargetData: [],
      eventTargetResult: null,
      table_headers: [
        { text: "Event Name", field: "eventName" },
        { text: "Event Date", field: "eventDate" },
        { text: "Action", field: "action" },
      ],
      table_headers2: [
        { text: "Event Name", field: "eventName" },
        { text: "Event Description", field: "eventDesc" },
        { text: "Event Date", field: "eventDate" },
      ],
    };
  },
  created() {
    this.moment = moment;
  },

  methods: {
    timeToday(d) {
      var today = new Date(d);
      return moment(today).format("ll");
    },
    seeLessFunc(i) {
      //   console.log("See less", i);
      const c = document.querySelector("#captionText" + i);
      c.classList.add("captionText");
      const sl = document.querySelector("#seeLessText" + i);
      sl.classList.add("hidden");
      const s = document.querySelector("#seeMoreText" + i);
      s.classList.remove("hidden");
      //   //   const img = document.querySelector("#image" + i);
      //   //   img.classList.remove("hidden");
    },
    seeMoreFunc(i) {
      //   console.log("See more", i);
      const c = document.querySelector("#captionText" + i);
      c.classList.remove("captionText");
      const s = document.querySelector("#seeMoreText" + i);
      s.classList.add("hidden");
      //   //   const img = document.querySelector("#image" + i);
      //   //   img.classList.add("hidden");
      const sl = document.querySelector("#seeLessText" + i);
      sl.classList.remove("hidden");
    },
    init() {
      this.contents = [];
      this.page = 1;
      this.perPage = 10;
      this.pages = [];
      this.fromPage = 1;
      this.toPage = null;
      this.results = null;
    },

    openModal(action) {
      this.modalAction = action;
      this.$emit("toggleEventsModal", action);
    },

    closeModal() {
      this.isRemoveModal = false;
    },

    viewEvents(item) {
      // this.eventID = item.id;
      // console.log(item);
      this.$emit("toggleEventsModal", "Update");
      this.$emit("viewEvents", item);
    },

    // load all user accounts to verify
    loadJournalEvents() {
      axios
        .get(process.env.VUE_APP_API_SERVER + "/journalEvents/getAllEvents", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
        .then(
          (response) => {
            this.contents = response.data;
            // console.log(response);
            this.results = response.data.length;
          },
          (error) => {
            console.log(error);
          }
        );
    },

    loadTaggedResarch() {
      // console.log(this.eventsItem.id);
      axios
        .get(
          process.env.VUE_APP_API_SERVER +
            "/journalEvents/getTaggedJournal/" +
            this.eventsItem.id,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            this.contents = response.data;
            // console.log(response);
            // this.journalTitleData = response.data.journalDetails.title;
            this.results = response.data.length;
          },
          (error) => {
            console.log(error);
          }
        );
    },

    removeEvents() {
      const toast = useToast();
      axios
        .delete(
          process.env.VUE_APP_API_SERVER +
            "/journalEvents/" +
            this.itemToRemove.id,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(
          (response) => {
            if (response.data.status == 200) {
              toast.success(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            } else if (response.data.status == 400) {
              toast.error(response.data.msg, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            }
            this.loadJournalEvents();
          },
          (error) => {
            console.log(error);
          }
        );
    },

    confirmRemove(journalEvent) {
      this.itemToRemove = journalEvent;
      this.eventsToRemove = journalEvent.eventDesc;
      this.isRemoveModal = true;
    },

    handleSearching() {
      if (this.searchText == "" || !this.searchText) {
        this.loadJournalEvents();
        this.setPages();
      } else {
        this.contents = this.contents.filter(
          (data) =>
            data.eventDesc
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) !== -1 ||
            data.eventName
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) !== -1 ||
            data.eventDate
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) !== -1
        );
        this.results = this.contents.length;
        this.setPages();
      }
    },

    // set number of pages to paginate
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(
        parseInt(this.contents.length) / this.perPage
      );
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    // handles the from and to of pagination table
    paginate(contents) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;

      if (to > this.contents.length) {
        this.fromPage = from + 1;
        this.toPage = this.contents.length;
      } else {
        this.fromPage = from + 1;
        this.toPage = to;
      }

      return contents.slice(from, to);
    },
  },

  computed: {
    displayedEvents() {
      return this.paginate(this.contents);
    },
  },
  watch: {
    contents() {
      this.setPages();
    },

    perPage() {
      this.page = 1;
      this.setPages();
    },
    isUpdate: {
      handler(val) {
        if (val) {
          this.init();
          this.loadJournalEvents();
          this.setPages();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.loadJournalEvents();
    this.setPages();
  },
};
</script>

<style scoped>
.tbl_header {
  background-color: #105005;
}

.indent {
  text-indent: 0.5rem;
}

/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}

.captionText {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>