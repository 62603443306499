<template>
  <div class="w-auto">
    <div class=" grid md:flex items-center sm:xl:justify-between md:justify-between xl:justify-between lg:justify-between bg-gray-100 mb-2 mt-2 rounded-md " >
      <div class="w-1/2">
        <h1 class=" md:text-xl text-xs lg:text-xl font-semibold py-5 pl-6 text-gray-900 " >
          Main Menu - Account Verification
        </h1>
        <h1 class="text-sm font-normal ml-7 -mt-4 text-gray-500 mb-10">
          List of Accounts
        </h1>
      </div>
      <div class=" hidden md:flex md:justify-end lg:flex lg:justify-end xl:flex xl:justify-end sm:hidden mr-3 -mt-6 overflow-hidden " >
        <div class="relative sm:w-60 md:w-96 px-4">
          <input type="text" v-model="searchval" @keypress.enter="searchBtn" class=" block py-1 px-2 w-full z-20 text-sm text-gray-600 bg-gray-50 rounded-lg border-l-gray-50 border-l-2 border border-gray-300 focus:ring-green-500 focus:border-green-500 " placeholder="Search by anything" required />
          <button type="submit" @click="searchBtn" class=" absolute top-0 right-0 py-1 px-2 text-sm font-medium text-white bg-customGreen rounded-tr-lg rounded-br-lg border focus:ring-4 focus:outline-none focus:ring-blue-300 " >
            <svg aria-hidden="true" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
            <span class="sr-only">Search</span>
          </button>
        </div>
      </div>
    </div>
    <!-- main content -->
    <div class="w-auto">
      <AccountsTable
        v-on:viewUser="ViewUser"
        :isUpdate="isUpdatedUser"
        :notifID="notifID"
      />
    </div>

    <!-- view account modal -->
    <UserToVerifyModal
      v-if="view_user"
      v-on:closeModal="closeModal"
      v-on:updatedUser="updatedUser"
      :user_account="userToView"
    />
  </div>
</template>

<script>
import AccountsTable from "../components/AccountsTable.vue";
import UserToVerifyModal from "../modals/UserToVerifyModal.vue";

export default {
  name: "UserVerify",
  components: {
    AccountsTable,
    UserToVerifyModal,
  },
  data() {
    return {
      view_user: false,
      user_accounts: null,
      notifID: null,
      userToView: null,
      isUpdatedUser: false,
      searchval: null
    };
  },
  created() {
    this.isUpdatedUser = false;
  },
  mounted() {
    // console.log(this.$route.params.userid);
    
    this.notifID = this.$route.params.userid;
    // console.log(this.notifID);
    this.isUpdatedUser = false;
  },

  watch: {
    "$route.params.userid": {
      handler(value) {
        this.notifID = value;
        // console.log(this.notifID);
      },
    },
  },

  methods: {
    searchBtn() {
      if (this.searchval == null || this.searchval == "") {
        return;
      } else {
        this.$router.push({
          name: "UserSearch",
          params: { data_text: this.searchval },
        });
      }
    },
    ViewUser(account) {
      // console.log(account);
      this.userToView = account;
      this.view_user = true;
      //   console.log("trigger");
    },
    updatedUser() {
      this.isUpdatedUser = true;
      setTimeout(() => (this.isUpdatedUser = false), 300);
    },

    closeModal() {
      this.view_user = false;
    },

    // Next() {
    //   if (this.resLen >= 10) {
    //     const rem = this.resLen - this.paginateNext;

    //     if (rem >= 10) {
    //       this.paginateNext = this.paginateNext + 10;
    //       this.paginatePrev = this.paginateNext - 9;
    //     } else {
    //       this.paginateNext = this.paginateNext + rem;
    //       this.paginatePrev = this.paginateNext - 9;
    //     }
    //   } else {
    //     return;
    //   }
    // },
    // Prev() {
    //   if (this.paginatePrev == 0) {
    //     return;
    //   } else {
    //     const rem = this.paginatePrev - 10;

    //     if (rem >= 10) {
    //       this.paginateNext = this.paginateNext - 10;
    //       this.paginatePrev = this.paginatePrev - 10;
    //     } else {
    //       if (this.resLen >= 10) {
    //         this.paginateNext = 10;
    //         this.paginatePrev = 1;
    //       } else {
    //         this.paginateNext = this.resLen;
    //         this.paginatePrev = 1;
    //       }
    //     }
    //   }
    // },
  },
};
</script>

<style scoped>
.customSearch {
  width: 20rem;
}
</style>