<template>
  <div
    id="defaultModal"
    tabindex="-1"
    aria-hidden="true"
    class="bgModal fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full justify-center items-center"
  >
    <div class="relative mt-10 m-auto w-full h-full max-w-md p-4 md:h-auto">
      <div
        id="divModal"
        class="modal relative bg-white rounded-lg shadow dark:bg-gray-700 animated modal_open"
      >
        <!-- modal header -->
        <div
          class="flex justify-between border-b py-3 px-3 rounded-t bg-105005"
          v-if="this.$store.getters.getUserRole != 1"
        >
          <h1 class="text-white text-sm font-poppins font-semibold">
            Research Verification
          </h1>
          <button
            type="button"
            @click="closeModal"
            class="absolute top-1 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="authentication-modal"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <div
          class="flex justify-between border-b py-3 px-3 bg-105005 rounded-t"
          v-if="this.$store.getters.getUserRole == 1"
        >
          <h1 class="text-white text-sm font-poppins font-semibold">
            Research Publication
          </h1>
          <button
            type="button"
            @click="closeModal"
            class="absolute top-1 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="authentication-modal"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- modal content -->
        <div
          class="grid items-center"
          v-if="this.$store.getters.getUserRole != 1"
        >
          <div class="m-auto px-2 py-5" v-if="!isToApprove">
            <img
              src="../assets/bg/Thinking-face-bro.png"
              class="w-auto h-auto m-auto"
              alt=""
            />
            <p class="m-auto pb-2 pt-2 text-sm text-gray-800 font-semibold">
              Do you really want to verify this document?
            </p>
          </div>

          <!-- approval remarks textarea -->
          <div class="m-auto py-3 px-3" v-if="isToApprove">
            <div>
              <textarea
                v-model="approvalRemark"
                class="border w-full text-xs inputIndent py-1 pl-0.5 rounded-md bg-gray-100"
                cols="61"
                rows="10"
                placeholder="This is optional..."
              ></textarea>
            </div>
          </div>
        </div>
        <div
          class="grid items-center"
          v-if="this.$store.getters.getUserRole == 1"
        >
          <div class="m-auto px-2 py-5" v-if="!isToApprove">
            <img
              src="../assets/bg/Thinking-face-bro.png"
              class="w-auto h-auto m-auto"
              alt=""
            />
            <p class="m-auto pb-2 pt-2 text-sm text-gray-800 font-semibold">
              Do you really want to publish this document?
            </p>
          </div>

          <!-- approval remarks textarea -->
          <div class="m-auto pt-2 pb-1.5 px-5" v-if="isToApprove">
            <div class="pb-2" v-if="this.$store.getters.getUserRole == 1">
              <label class="text-xs font-bold"
                >Accession Number <span class="text-red-500">*</span></label
              >
              <input
                type="text"
                class="w-full border border-gray-200 pl-2 text-xs py-1.5 rounded bg-gray-100"
                placeholder="ex: 1175-5334"
                v-model="accessionNumber"
                :class="err_accessionNumber ? 'border-red-500' : ''"
              />
            </div>
            <div>
              <label class="text-xs font-bold">Remarks/Comments</label>
              {{ journaldoc.accessionNumber }}
              <textarea
                v-model="approvalRemark"
                class="border w-full text-xs inputIndent py-1 pl-0.5 rounded-md bg-gray-100"
                cols="61"
                rows="10"
                placeholder="This is optional..."
              ></textarea>
            </div>
          </div>
        </div>
        <!-- buttons -->
        <div
          class="flex justify-end p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600"
        >
          <!-- button to toggle approval remarks textarea -->

          <button
            v-if="!isToApprove && this.$store.getters.getUserRole != 1"
            @click="isToApprove = true"
            type="button"
            class="bg-105005 text-white rounded font-normal text-sm px-3 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 inline"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>

            Verify
          </button>
          <button
            v-if="!isToApprove && this.$store.getters.getUserRole == 1"
            @click="isToApprove = true"
            type="button"
            class="bg-105005 rounded font-semibold text-white text-sm px-3 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 inline"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>

            Publish
          </button>
          <!-- This button is for publish -->
          <button
            v-if="isToApprove && this.$store.getters.getUserRole == 1"
            @click="Approve(journaldoc)"
            type="button"
            class="bg-105005 text-white rounded font-semibold text-sm px-3 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 inline"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            Save changes
          </button>
          <!--END TAG: This button is for publish -->
          <!-- This button is for verification -->
          <button
            v-if="isToApprove && this.$store.getters.getUserRole != 1"
            @click="Approve2(journaldoc)"
            type="button"
            class="bg-105005 font-semibold text-white rounded text-sm px-3 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 inline"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            Save changes
          </button>
          <!-- This button is for verification -->
          <button
            type="button"
            @click="closeModal"
            class="text-sm text-gray-500 bg-gray-300 font-semibold rounded px-5 py-1 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
          >
            <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-5 h-5 inline">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg> -->
            Close
          </button>

          <!-- button to save without remarks/comments  -->

          <!-- button to save remarks and approve journal -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
export default {
  name: "ApproveDecisionModal",
  props: ["journaldoc"],

  data() {
    return {
      journaldata: null,
      approvalRemark: null,
      isToApprove: false,
      accessionNumber: null,
      err_accessionNumber: false,
    };
  },

  methods: {
    closeModal() {
      document.getElementById("divModal").classList.remove("modal_open");
      document.getElementById("divModal").classList.add("modal_close");
      setTimeout(() => this.$emit("closeModal"), 500);
      // this.$emit("closeModal");
    },

    // approve journal function
    Approve(doc) {
      const toast = useToast();
      // this.$emit("updatedData1");
      if (this.accessionNumber == null) {
        this.err_accessionNumber = true;
        toast.warning("Accession Number is required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } else {
        if (this.approvalRemark != null || this.approvalRemark != "") {
          const data = {
            remarks: this.approvalRemark,
            userNow: this.$store.getters.getUserRole,
            accessionNumber: this.accessionNumber,
          };
          // const final = Object.assign(doc, data);
          const final = [].concat(doc, data);
          // console.log(final);
          axios
            .post(
              process.env.VUE_APP_API_SERVER + "/fileupload/approve",
              final,
              {
                headers: {
                  Authorization: `Bearer ${this.$store.getters.getToken}`,
                },
              }
            )
            .then(
              (response) => {
                // console.log(response);
                if (response.status == 201 || response.status == 200) {
                  this.$emit("approved");
                  this.$emit("isUpdatedData");
                  // emit to ApprovalView
                  // trigger method updateData1 in approvalview
                  // this.$emit("approved"); // trigger method approved in messageModal
                  // this.$emit("updatedUser");
                  // this.closeModal();
                }
              },
              (error) => {
                // console.log(error);
              }
            );
        }

        // APPROVED WITHOUT REMARKS
        if (this.approvalRemark == null || this.approvalRemark == "") {
          // console.log('empty');
          const data = {
            userNow: this.$store.getters.getUserRole,
            accessionNumber: this.accessionNumber,
          };
          // const final = Object.assign(doc, data);
          const final = [].concat(doc, data);
          // console.log(final);
          axios
            .post(
              process.env.VUE_APP_API_SERVER +
                "/fileupload/approvedWithoutRemarks",
              final,
              {
                headers: {
                  Authorization: `Bearer ${this.$store.getters.getToken}`,
                },
              }
            )
            .then(
              (response) => {
                // console.log(response);
                if (response.status == 201 || response.status == 200) {
                  this.$emit("approved");
                  this.$emit("isUpdatedData");
                  // emit to ApprovalView
                  // trigger method updateData1 in approvalview
                  // this.$emit("approved"); // trigger method approved in messageModal
                  // this.$emit("updatedUser");
                  // this.closeModal();
                }
              },
              (error) => {
                // console.log(error);
              }
            );
        }
      }
    },
    Approve2(doc) {
      if (this.approvalRemark != null || this.approvalRemark != "") {
        const data = {
          remarks: this.approvalRemark,
          userNow: this.$store.getters.getUserRole,
        };
        // const final = Object.assign(doc, data);
        const final = [].concat(doc, data);
        // console.log(final);
        axios
          .post(process.env.VUE_APP_API_SERVER + "/fileupload/approve", final, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          })
          .then(
            (response) => {
              if (response.status == 201 || response.status == 200) {
                this.$emit("approvedWithoutRemarks");
                this.$emit("isUpdatedDataVerify");
                // emit to ApprovalView
                // trigger method updateData1 in approvalview
                // this.$emit("approved"); // trigger method approved in messageModal
                // this.$emit("updatedUser");
                // this.closeModal();
              }
            },
            (error) => {
              console.log(error);
            }
          );
      }

      // APPROVED WITHOUT REMARKS
      if (this.approvalRemark == null || this.approvalRemark == "") {
        const data = { userNow: this.$store.getters.getUserRole };
        // const final = Object.assign(doc, data);
        const final = [].concat(doc, data);
        axios
          .post(
            process.env.VUE_APP_API_SERVER +
              "/fileupload/approvedWithoutRemarks",
            final,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
              },
            }
          )
          .then(
            (response) => {
              // console.log(response);
              if (response.status == 201 || response.status == 200) {
                this.$emit("approved");
                this.$emit("isUpdatedDataVerify");
                // emit to ApprovalView
                // trigger method updateData1 in approvalview
                // this.$emit("approved"); // trigger method approved in messageModal
                // this.$emit("updatedUser");
                // this.closeModal();
              }
            },
            (error) => {
              // console.log(error);
            }
          );
      }
    },
  },
  watch: {
    journaldoc: {
      handler(val) {
        if (val) {
          this.accessionNumber = val[1].accessionNumber;
        } else {
          this.accessionNumber = "";
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.bgGreen {
  color: #105005;
}

.inputIndent {
  text-indent: 10px;
}
</style>
